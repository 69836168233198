import { Inject, Injectable } from "@angular/core";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { EnvironmentConfigService } from "./environment-config.service";
import { SESSION_STORAGE, StorageService } from "ngx-webstorage-service";
import { SessionAuthenticationService } from "./session-authentication.service";
import { Portal } from "src/app/models/session-token";
import { Observable } from "rxjs";
@Injectable()
export class CancelInvitationService {
  constructor(
    private http: HttpClient,
    private environmentConfigService: EnvironmentConfigService,
    private sessionAuthenticationService: SessionAuthenticationService,
    @Inject(SESSION_STORAGE) private storage: StorageService,
  ) {}

  cancelInvitation(
    invitationId: string,
    reasonId: number,
    otherReason?: string,
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post(
          `${
            this.environmentConfigService.getConfig().idBridgeApiUrl
          }/invitation/cancel`,
          {
            invitation_id: invitationId,
            reason_id: reasonId,
            cancelled_reason_notes: otherReason,
          },
          {
            headers: this.sessionAuthenticationService.applyAuthorizationHeader(
              Portal.Invitation,
            ),
            observe: "response",
          },
        )
        .subscribe(
          (res) => {
            resolve(res);
          },
          (error) => {
            reject(error);
          },
        );
    });
  }

  cancelMultipleInvitations(
    invitationIds: string[],
    reasonId: number,
    otherReason?: string,
  ): Observable<HttpResponse<string[]>> {
    const payload = {
      invitation_ids: invitationIds,
      reason_id: reasonId,
      cancelled_reason_notes: otherReason,
    };
    return this.http.post<string[]>(
      `${
        this.environmentConfigService.getConfig().idBridgeApiUrl
      }/invitation/cancel-multiple`,
      payload,
      {
        observe: "response",
        headers: this.sessionAuthenticationService.applyAuthorizationHeader(
          Portal.Invitation,
        ),
      },
    );
  }
}
