<div
  [ngClass]="{
    'nhsuk-form-group--error': this.isDateInvalid
  }"
  class="nhsuk-form-group"
>
  <fieldset role="group" class="nhsuk-fieldset" [id]="id">
    <legend *ngIf="label" class="nhsuk-fieldset__legend nhsuk-label">
      {{ label }}
    </legend>

    <span *ngIf="this.isDateInvalid" class="nhsuk-error-message"
      ><strong>{{ this.fieldDOBErrorMessage }}</strong></span
    >

    <div class="nhsuk-date-input">
      <div class="nhsuk-date-input__item">
        <div class="nhsuk-form-group">
          <label class="nhsuk-label nhsuk-date-input__label" [for]="'day-' + id"
            >Day</label
          >
          <input
            #day
            [ngClass]="{ 'nhsuk-input--error': dateErrorHighlight.day }"
            type="text"
            [id]="'day-' + id"
            [name]="'day-' + id"
            class="nhsuk-input nhsuk-date-input__input nhsuk-input--width-2"
            maxlength="2"
            pattern="[0-9]*"
            inputmode="numeric"
            autocomplete="day"
            numbersOnly
            (change)="updateDay($event.target.value)"
          />
        </div>
      </div>
      <div class="nhsuk-date-input__item">
        <div class="nhsuk-form-group">
          <label
            class="nhsuk-label nhsuk-date-input__label"
            [for]="'month-' + id"
            >Month</label
          >
          <input
            #month
            [ngClass]="{
              'nhsuk-input--error': dateErrorHighlight.month
            }"
            type="text"
            [id]="'month-' + id"
            [name]="'month-' + id"
            class="nhsuk-input nhsuk-date-input__input nhsuk-input--width-2"
            maxlength="2"
            pattern="[0-9]*"
            inputmode="numeric"
            autocomplete="month"
            numbersOnly
            (change)="updateMonth($event.target.value)"
          />
        </div>
      </div>
      <div class="nhsuk-date-input__item">
        <div class="nhsuk-form-group">
          <label
            class="nhsuk-label nhsuk-date-input__label"
            [for]="'year-' + id"
            >Year</label
          >
          <input
            #year
            [ngClass]="{ 'nhsuk-input--error': dateErrorHighlight.year }"
            type="text"
            [id]="'year-' + id"
            [name]="'year-' + id"
            class="nhsuk-input nhsuk-date-input__input nhsuk-input--width-4"
            maxlength="4"
            pattern="[0-9]*"
            inputmode="numeric"
            autocomplete="year"
            numbersOnly
            (change)="updateYear($event.target.value)"
          />
        </div>
      </div>
    </div>
  </fieldset>
</div>
