import { DpoaCheckDetails } from "./dpoaCheck";
import { IdCheckFailure } from "./idCheckFailure";
import { IdCheckInternalNotesDetails } from "./idCheckInternalNotesDetails";

// Model to match response from API.
export interface InvitationDetails {
  email: string;
  given_name: string;
  middle_names?: string;
  family_name: string;
  inviter_entered_given_name?: string;
  inviter_entered_family_name?: string;
  preferred_name?: string;
  mobile: string;
  is_personal_email: boolean;
  created_by_user_name: string;
  invitation_id?: string;
  status?: InvitationStatus;
  status_last_updated_utc?: string;
  created_date_utc?: string;
  sender_email: string;
  id_checked_by?: string;
  id_checked_by_user_id?: string;
  id_checked_utc?: string;
  paa: string;
  hold_reason?: IdCheckHeldReason[];
  id_check_failure?: IdCheckFailure[];
  failed_notes?: string;
  date_of_birth?: string;
  photo_id_one_no?: string;
  photo_id_one_issue?: string;
  photo_id_one_date_of_issue?: string;
  photo_id_one_date_of_expiry?: string;
  photo_id_two_no?: string;
  photo_id_two_date_of_issue?: string;
  photo_id_two_date_of_expiry?: string;
  photo_id_two_issue?: string;
  photo_id_one_type?: string;
  photo_id_two_type?: string;
  poa_no?: string;
  poa_date_of_issue?: string;
  poa_issue?: string;
  poa_document_type?: string;
  smart_card?: string;
  review_by?: string;
  review_expiry_utc?: string;
  submitted_id_check_utc?: string;
  cis_uuid?: string;
  issues?: IdCheckIssues[];
  match_results?: IdCheckMatchResults[];
  resent_count?: number;
  completed_date_utc?: string;
  cancelled_reason?: CancelledReason;
  cancelled_reason_notes?: string;
  cancelled_date_utc?: string;
  hold_internal_notes?: IdCheckInternalNotesDetails[];
  capture_delivery_address?: boolean;
  address?: string;
  created_by_user_org_code?: string;
  cis_duplicate_match?: string;
  cis_duplicate_matching_fields?: string;
  approval_note?: string;
  ni_number?: string;
  xydus_invitation_url?: string;
  sender_email_bounced?: boolean;
  dpoa_used?: boolean;
  dpoa_check?: DpoaCheckDetails;
  tu_address?: string;
  done?: boolean;
}

export interface InvitationSearchDetails {
  id: string;
  email: string;
  name: string;
  org: string;
  user: string;
  status?: InvitationStatus;
  date?: string;
}

export interface IdCheckImage {
  data: string;
  displayName?: string;
  name: string;
}

export interface IdCheckIssues {
  code: string;
  description: string;
  type: string;
}

export interface IdCheckMatchResults {
  key: string;
  value: number;
}

export enum InvitationStatus {
  "New" = 0,
  "Sent" = 1,
  "Duplicate check in progress" = 2,
  "Invalid" = 3,
  "Resent" = 4,
  "Complete" = 5,
  "Valid" = 6,
  "Invite email not sent" = 29,
  "Deleted" = 30,
  "Invite resend required" = 31,
  "OtpExpired" = 32,
  "Locked" = 33,
  "Duplicate information" = 34,
  "Awaiting documents" = 35,
  "Otp Request Blocked" = 36,
  "Application expired" = 37,
  "Application cancelled" = 38,
  "ID review" = 50,
  "On hold" = 51,
  "Approved" = 52,
  "Rejected" = 53,
}

export enum IdCheckHeldReason {
  "No Reason" = 0,
  "Potential Fraud" = 1,
  "Spelling Mistake / 1 letter difference" = 2,
  "Automation" = 3,
  "Shortened Name / Nickname" = 4,
  "Potential Safeguarding" = 5,
  "System Issue" = 6,
  "Names switched" = 7,
  "Other" = 8,
  "Potential Duplicate" = 9,
}

export enum IdCheckFailureReason {
  "Duplicate" = 1,
  "Expired" = 2,
  "Not legible" = 3,
  "Missing information" = 4,
  "Suspected fraud" = 5,
  "Not accepted" = 6,
  "Does not match photo ID(s)" = 7,
  "Photo(s) do not meet passport guidelines" = 8,
  "Other" = 9,
  "Image Quality" = 10,
  "Does not meet requirements" = 11,
  "Smoke test - internal use only" = 12,
  "Does not show a live person" = 13,
  "Name and/or date of birth do not match" = 14,
  "Face scan is too blurry" = 15,
  "Not an original document" = 16,
  "Does not have a plain background" = 17,
  "Not facing forwards" = 18,
  "Is a photo of an ID document" = 19,
  "Has been altered by filters" = 20,
  "Does not show both sides" = 21,
}

export enum CancelledReason {
  "Documents not accepted" = 0, // old reason, can be removed after purge period
  "No longer needed" = 1, // old reason, can be removed after purge period
  "Technical issue or bug" = 2, // old reason, can be removed after purge period
  "Other" = 3,
  "Did not respond to invite emails" = 4,
  "Technical issue with mobile app" = 5,
  "Technical issue with online journey" = 6,
  "Wants to change which documents to capture in the app" = 7,
  "Wants to change their mobile number" = 8,
  "Does not have a photo ID" = 9,
  "Does not have a proof of address document" = 10,
  "Does not have access to their ID documents at the moment" = 11,
  "Does not have an National Insurance number" = 12,
  "Does not have a mobile phone" = 13,
  "Is overseas" = 14,
  "Does not need a new Care ID profile" = 15,
  "Created Care ID profile in person" = 16,
}

export interface InvitationRaDetails {
  invitation_id: string;
  address: string;
}
