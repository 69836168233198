import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { EnvironmentConfigService } from "./environment-config.service";

import { SessionAuthenticationService } from "./session-authentication.service";
import { Portal } from "src/app/models/session-token";
import { UserSettings, UserSettingsDetails } from "../models/userSettings";

@Injectable()
export class UserSettingsService {
  constructor(
    private http: HttpClient,
    private environmentConfigService: EnvironmentConfigService,
    private sessionAuthenticationService: SessionAuthenticationService,
  ) {}

  mapUserSettings(usd: UserSettingsDetails): UserSettings {
    return new UserSettings(usd);
  }

  checkOTP(otpEntry: string, category: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post(
          `${
            this.environmentConfigService.getConfig().idBridgeApiUrl
          }/auth/verify-otp`,
          {
            otp: otpEntry,
            category: category,
          },
          {
            headers: this.sessionAuthenticationService.applyAuthorizationHeader(
              Portal.Invitation,
            ),
            observe: "response",
          },
        )
        .subscribe(
          (res) => {
            resolve(res);
          },
          (error) => {
            reject(error);
          },
        );
    });
  }
  useSameEmail(category: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post(
          `${
            this.environmentConfigService.getConfig().idBridgeApiUrl
          }/auth/use-same-email`,
          {
            category: category,
          },
          {
            headers: this.sessionAuthenticationService.applyAuthorizationHeader(
              Portal.Invitation,
            ),
            observe: "response",
          },
        )
        .subscribe(
          (res) => {
            resolve(res);
          },
          (error) => {
            reject(error);
          },
        );
    });
  }
  storeSettings(email: string, category: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post(
          `${
            this.environmentConfigService.getConfig().idBridgeApiUrl
          }/auth/settings`,
          {
            email: email,
            category: category,
          },
          {
            headers: this.sessionAuthenticationService.applyAuthorizationHeader(
              Portal.Invitation,
            ),
            observe: "response",
          },
        )
        .subscribe(
          (res) => {
            resolve(res);
          },
          (error) => {
            reject(error);
          },
        );
    });
  }
  getSettings(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(
          `${
            this.environmentConfigService.getConfig().idBridgeApiUrl
          }/auth/settings`,

          {
            headers: this.sessionAuthenticationService.applyAuthorizationHeader(
              Portal.Invitation,
            ),
            observe: "response",
          },
        )
        .subscribe(
          (res) => {
            resolve(res);
          },
          (error) => {
            reject(error);
          },
        );
    });
  }

  resendEmail(category: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post(
          `${
            this.environmentConfigService.getConfig().idBridgeApiUrl
          }/auth/resend-otp-email`,
          {
            category: category,
          },
          {
            headers: this.sessionAuthenticationService.applyAuthorizationHeader(
              Portal.Invitation,
            ),
            observe: "response",
          },
        )
        .subscribe(
          (res) => {
            resolve(res);
          },
          (error) => {
            reject(error);
          },
        );
    });
  }
}
