import { Pipe, PipeTransform } from "@angular/core";
import { getFailureReasonDescription } from "../../../utils/common/common";

@Pipe({ name: "idFailureCheckReasonPipe" })
export class IdFailureCheckReasonPipe implements PipeTransform {
  transform(value: number, isPoa: boolean = false): string {
    let description = getFailureReasonDescription(value, isPoa);
    if (description != undefined) {
      return description.slice(0, -1);
    } else {
      return "";
    }
  }
}
