import { Pipe, PipeTransform } from "@angular/core";
import { ApplicationStepStatus } from "src/app/models/applicationStepStatus";

@Pipe({ name: "applicationStepStatusClassPipe" })
export class ApplicationStepStatusClassPipe implements PipeTransform {
  private status = ApplicationStepStatus;

  private classMapping = {
    0: "nhsuk-tag--grey",
    1: "nhsuk-tag--white",
    2: "nhsuk-tag--blue",
    3: "nhsuk-tag--grey",
  };

  transform(value: number): string {
    return this.classMapping[value];
  }
}
