<session-check></session-check>
<div class="nhsuk-grid-row">
  <div class="nhsuk-grid-column-two-thirds">
    <breadcrumb-link
      [breadcrumbRoute]="breadcrumbRoute"
      [breadcrumbText]="breadcrumbText"
    ></breadcrumb-link>
    <div
      class="nhsuk-main-wrapper nhsuk-main-wrapper--s"
      id="maincontent"
      role="main"
    >
      <div>
        <div>
          <h1 autofocus tabindex="-1">Settings</h1>

          <h2>Contact email address</h2>

          <p class="nhsuk-u-reading-width">
            The ID checking team might use this to contact you about
            applications you've raised.
          </p>

          <dl class="nhsuk-summary-list">
            <div class="nhsuk-summary-list__row">
              <dt class="nhsuk-summary-list__key">Email address</dt>
              <dd
                class="nhsuk-summary-list__value"
                *ngIf="contactEmailVerified"
              >
                {{ contactEmail }}
              </dd>
              <dd class="nhsuk-summary-list__actions">
                <a href="/settings/update-email/contact">
                  Change<span class="nhsuk-u-visually-hidden"> email</span>
                </a>
              </dd>
            </div>
          </dl>

          <h2>Help for your applicants</h2>

          <p class="nhsuk-u-reading-width">
            This email address will be displayed to your applicants, so they can
            contact you or your team if they need support.
          </p>

          <dl class="nhsuk-summary-list">
            <div class="nhsuk-summary-list__row">
              <dt class="nhsuk-summary-list__key">Email address</dt>
              <dd
                class="nhsuk-summary-list__value"
                *ngIf="supportEmailVerified"
              >
                {{ supportEmail }}
              </dd>
              <dd class="nhsuk-summary-list__actions">
                <a href="/settings/update-email/support">
                  Change<span class="nhsuk-u-visually-hidden"> email</span>
                </a>
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  </div>
</div>
