<div
  *ngIf="displayAlert"
  id="role-changed-success"
  style="display: block"
  class="nhsuk-alert nhsuk-alert--success nhsuk-alert--icon"
  role="alert"
  aria-labelledby="name-changed-message"
>
  <svg
    class="nhsuk-icon nhsuk-icon__tick nhsuk-alert__icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    aria-hidden="true"
  >
    <path
      stroke-width="4"
      stroke-linecap="round"
      d="M18.4 7.8l-8.5 8.4L5.6 12"
    ></path>
  </svg>

  <p id="name-changed-message" class="nhsuk-u-font-weight-bold">
    <span class="nhsuk-u-visually-hidden">Success:</span> Role changed to
    <span class="role-name-label">{{ roleName }}</span
    >,
    <span class="org-name-label">{{ orgName }} ({{ orgCode }})</span>
  </p>
</div>
