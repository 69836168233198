import { Component, OnInit, ViewChild } from "@angular/core";
import {
  IdCheckHeldReason,
  InvitationStatus,
} from "src/app/models/invitationDetails";
import { GetInvitationService } from "src/app/services/get-invitation.service";
import { SendInvitationService } from "src/app/services/send-invitation.service";
import { ActivatedRoute } from "@angular/router";
import { InvitationIdCheck } from "src/store/states/spa.state";
import { CheckboxComponent } from "../../common/form-fields/checkbox/checkbox.component";
import { TextAreaInputComponent } from "../../common/form-fields/text-inputs/text-area-input/text-area-input.component";
import { SessionAuthenticationService } from "src/app/services/session-authentication.service";
import { Location } from "@angular/common";
import { Title } from "@angular/platform-browser";
import { isNotesValid } from "src/app/utils/data-validation/data-validation";
import { ModifiedRecordCheckComponent } from "../../common/modified-record-check/modified-record-check.component";
import { RouterService } from "src/app/services/router.service";
import { scrollToTop } from "src/app/utils/common/common";
import { IdCheckerRejectionSPAState } from "src/store/states/id-checker-spa.state";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import { getIdCheckerRejection } from "src/store/selectors/id-checker-spa.selectors";
import { IdCheckFailure } from "src/app/models/idCheckFailure";

export class HoldApplicationState {
  failedDocument: string;
}

@Component({
  selector: "app-hold-application",
  templateUrl: "./hold-application.component.html",
})
export class HoldApplicationComponent implements OnInit {
  holdReasonProvided: boolean;
  notesValid: boolean = true;
  submitting: boolean = false;
  submitError: string = "";

  systemIssue: boolean;
  other: boolean;
  holdReasonNotes: string = "";
  holdNotesErrorMessage = "";

  invitation: InvitationIdCheck;
  invitationId: string;
  currentUser: string;
  currentUserId: string;
  failedDocument: string;

  @ViewChild("systemIssueInput")
  systemIssueInput: CheckboxComponent;
  @ViewChild("holdReasonNotesInput")
  holdReasonNotesInput: TextAreaInputComponent;
  @ViewChild("modifiedRecordCheck")
  modifiedRecordCheck: ModifiedRecordCheckComponent;

  readonly errorMessages = {
    holdNotes: {
      lengthTooLong:
        "The Additional information must be no more than 300 characters long.",
      unexpectedCharacters:
        "The Additional information must consist of only letters, numbers and punctuation.",
      unknown:
        "Please check you've entered the employee's positions and authenticators correctly.",
    },
  };
  idCheckFailureSubscription: Subscription;
  idCheckFailures: IdCheckFailure[] = [];

  constructor(
    private sendInvitationService: SendInvitationService,
    private getInvitationService: GetInvitationService,
    private route: ActivatedRoute,
    private sessionAuthenticationService: SessionAuthenticationService,
    private routerService: RouterService,
    private location: Location,
    private title: Title,
    private store: Store<IdCheckerRejectionSPAState>,
  ) {}

  async ngOnInit(): Promise<void> {
    this.invitationId = this.route.snapshot.paramMap.get("invitationId");
    await this.getInvitation();
    this.idCheckFailureSubscription = this.store
      .select(getIdCheckerRejection)
      .subscribe((idCheckFailures) => {
        this.idCheckFailures = Object.assign([], idCheckFailures);
      });
    const sessionToken =
      this.sessionAuthenticationService.currentSessionToken();
    this.currentUser = sessionToken.user_name;
    this.currentUserId = sessionToken.user_uuid;
    let rejectApplicationState =
      this.location.getState() as HoldApplicationState;
    this.failedDocument = rejectApplicationState.failedDocument;
    this.title.setTitle("Select hold application reasons");
  }

  async submitAnswers(): Promise<void> {
    this.setReasonsForInvitation();

    if (!this.isHoldReasonProvided() || !this.notesValid) {
      return;
    }

    try {
      this.submitting = true;
      await this.sendInvitationService.putIdCheckInvitation(
        this.invitation.invitationId,
        this.invitation.idCheckedBy,
        this.invitation.idCheckedByUserId,
        this.invitation.status,
        null,
        this.invitation.failedReason,
        this.invitation.holdReason,
        this.invitation.holdNotes,
      );
      this.routerService.redirectToCheckerReviewList();
    } catch (err) {
      this.routerService.handleErrorAuthRoutes(err.status);
      if (err.status == 400) {
        this.submitError = err.error;
      }
    } finally {
      this.submitting = false;
    }
  }

  async getInvitation(): Promise<void> {
    this.getInvitationService.getInvitation(this.invitationId).subscribe({
      next: (response) => {
        this.invitation = new InvitationIdCheck(response["body"]);
        this.setModifiedRecordCheckValues();
      },
      error: () => {
        window.sessionStorage.clear();
      },
    });
  }

  setModifiedRecordCheckValues(): void {
    this.modifiedRecordCheck.enabled = true;
    this.modifiedRecordCheck.invitationId = this.invitation.invitationId;
    this.modifiedRecordCheck.status = this.invitation.status;
    this.modifiedRecordCheck.statusLastUpdatedUtc =
      this.invitation.statusLastUpdatedDateUtc;
  }

  setReasonsForInvitation() {
    this.systemIssue = this.systemIssueInput.isChecked;
    this.invitation.holdReason = [];
    if (this.systemIssue) {
      this.invitation.holdReason.push(IdCheckHeldReason["System Issue"]);
    }
    if (this.holdReasonNotes.trim()) {
      this.invitation.holdReason.push(IdCheckHeldReason["Other"]);
      this.invitation.holdNotes = this.holdReasonNotes.trim();
    }
    this.invitation.idCheckedBy = this.currentUser;
    this.invitation.idCheckedByUserId = this.currentUserId;
    this.invitation.status = InvitationStatus["On hold"];
    this.invitation.failedReason = this.idCheckFailures;
  }

  updateHoldNotes(holdNotes: string): void {
    const validationResult = isNotesValid(holdNotes);
    if (validationResult.validity) {
      this.holdNotesErrorMessage = "";
      this.notesValid = true;
    } else {
      this.holdNotesErrorMessage =
        this.errorMessages.holdNotes[validationResult.errorReason];
      this.notesValid = false;
    }
    this.holdReasonNotes = holdNotes;
  }

  isHoldReasonProvided(): boolean {
    if (
      this.invitation.holdReason.length == 0 &&
      this.idCheckFailures.length == 0
    ) {
      this.holdReasonProvided = false;
      scrollToTop();
      return false;
    } else {
      this.holdReasonProvided = true;
      return true;
    }
  }
}
