import { Component, Inject, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { SESSION_STORAGE, StorageService } from "ngx-webstorage-service";
import {
  BatchDuplicateInvitations,
  BatchFailedInvitations,
  BatchResponse,
} from "src/app/models/batch-failed-invitations";
import { ErrorSummaryMessage } from "src/app/models/error-summary-message";

@Component({
  selector: "app-not-sent-applications",
  templateUrl: "./not-sent-applications.component.html",
  styleUrls: ["./not-sent-applications.component.scss"],
})
export class NotSentApplicationsComponent implements OnInit {
  summaryErrorMessages = [];
  csvDownloadURL: string;
  batchResponse: BatchResponse;
  failedInvitations: BatchFailedInvitations[];
  dataErrorInvitations: BatchFailedInvitations[];
  duplicateErrorInvitations: BatchDuplicateInvitations[] = [];
  totalInvitationsCount: number;
  successInvitationsCount: number;
  failedInvitationsCount: number;

  private readonly csvHeaders = [
    "First Name",
    "Last Name",
    "Email Address",
    "Mobile Number",
    "Notes (optional)",
    "Capture delivery address (True/False)",
  ];

  private readonly csvHeaderProps = [
    "given_name",
    "family_name",
    "email",
    "mobile",
    "paa",
    "capture_delivery_address",
  ];

  constructor(
    @Inject(SESSION_STORAGE) private sessionStorage: StorageService,
    private title: Title,
  ) {}

  ngOnInit(): void {
    this.title.setTitle("Some applications could not be sent");
    this.batchResponse = this.sessionStorage.get("batchResponse");
    this.failedInvitations = this.batchResponse.failed_original_records;
    this.successInvitationsCount = this.batchResponse.success_count;
    this.failedInvitationsCount = this.batchResponse.failed_count;
    this.totalInvitationsCount = this.batchResponse.total_count;
    this.extractDuplicatesFromFailedInvitations(this.failedInvitations);
    this.writeFailedInvitationsToCsv();
    this.setupInvitations();
    this.updateErrorSummary();
  }

  extractDuplicatesFromFailedInvitations(failedInvitations: any): void {
    let i = failedInvitations.length;
    while (i--) {
      let item = failedInvitations[i];
      if (item.error_message.startsWith("DUPLICATE=")) {
        const duplicateDetails = item.error_message.split(",");
        const applicationId = duplicateDetails[0].split("=")[1];
        const email = duplicateDetails[1].split("=")[1];

        const newBatchDuplicate = {
          invitation_id: applicationId,
          email: email,
          record_number: item.record_number,
        } as BatchDuplicateInvitations;
        this.duplicateErrorInvitations.push(newBatchDuplicate);
        failedInvitations.splice(i, 1);
      }
    }

    this.duplicateErrorInvitations.reverse();
  }

  setupInvitations(): void {
    this.setColumns();
    this.dataErrorInvitations = this.failedInvitations;
  }

  mapFieldToColumn(field: string) {
    switch (field) {
      case "given_name": {
        return "A";
      }
      case "family_name": {
        return "B";
      }
      case "email": {
        return "C";
      }
      case "mobile": {
        return "D";
      }
      case "paa": {
        return "E";
      }
      case "capture_delivery_address": {
        return "F";
      }
      default: {
        return "-";
      }
    }
  }

  setColumns(): void {
    if (!this.failedInvitations) {
      return;
    }
    this.failedInvitations.forEach((failedInvitation) => {
      failedInvitation.column = this.mapFieldToColumn(failedInvitation.field);
    });
  }

  // possibly is a better way of doing this, but couldn't find a nice alternative
  downloadCsv(): void {
    let link = document.createElement("a");
    link.setAttribute("type", "hidden");
    link.href = this.csvDownloadURL;
    link.download = "failed-applications.csv";
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  writeFailedInvitationsToCsv(): void {
    if (!this.failedInvitations || this.failedInvitations.length == 0) {
      return;
    }
    const csvContent =
      this.csvHeaders.join(",") +
      "\n" +
      this.failedInvitations
        .map((failedInvitation) => {
          return this.csvHeaderProps
            .map((header) => {
              if (
                failedInvitation.original_data[header] != null ||
                failedInvitation.original_data[header] != undefined
              ) {
                return failedInvitation.original_data[header];
              }
            })
            .join(",");
        })
        .join("\n");

    const blob = new Blob([csvContent], { type: "text/csv; charset=utf-8" });
    this.csvDownloadURL = URL.createObjectURL(blob);
  }

  updateErrorSummary() {
    const updatedErrorSummary = Array<ErrorSummaryMessage>();

    if (this.failedInvitations.length > 0) {
      updatedErrorSummary.push({
        message: "Some applications contain data errors",
        id: "error-table-data",
      });
    }

    if (this.duplicateErrorInvitations.length > 0) {
      updatedErrorSummary.push({
        message: "Some applications are already in progress",
        id: "error-table-in-progress",
      });
    }

    this.summaryErrorMessages = updatedErrorSummary;
  }
}
