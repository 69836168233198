import { HttpClient, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AddressDetails } from "../models/addressDetails";
import { EnvironmentConfigService } from "./environment-config.service";

@Injectable()
export class PostcodeLookupService {
  constructor(
    private http: HttpClient,
    private environmentConfigService: EnvironmentConfigService,
  ) {}

  getAddressByPostcode(
    postcode: string,
  ): Observable<HttpResponse<AddressDetails[]>> {
    return this.http.get<AddressDetails[]>(
      `${
        this.environmentConfigService.getConfig().idBridgeApiUrl
      }/addressLookup?postcode=${postcode}`,
      {
        observe: "response",
      },
    );
  }
}
