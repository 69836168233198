<session-check></session-check>
<modified-record-check #modifiedRecordCheck></modified-record-check>
<section *ngIf="!invitation">
  <progress-indicator spinnerText="Loading application"></progress-indicator>
</section>
<div *ngIf="invitation" class="nhsuk-grid-row">
  <div class="nhsuk-grid-column-full">
    <a
      *ngIf="prevPage"
      [routerLink]="[prevPage]"
      (click)="onPageClose()"
      [state]="{
        currentPage: currentPage,
        filteredInvitations: filteredInvitations,
        allInvitations: allInvitations,
        statuses: statuses,
        searchTerm: searchTerm,
        odsSearchTerm: odsSearchTerm,
        senderOnly: senderOnly,
        tabSelected: tabSelected,
        reviewedBySearchTerm: reviewedBySearchTerm
      }"
      class="nhsuk-back-link__link nhsuk-u-margin-bottom-6"
    >
      <svg
        class="nhsuk-icon nhsuk-icon__chevron-left"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        aria-hidden="true"
        height="24"
        width="24"
      >
        <path
          d="M8.5 12c0-.3.1-.5.3-.7l5-5c.4-.4 1-.4 1.4 0s.4 1 0 1.4L10.9 12l4.3 4.3c.4.4.4 1 0 1.4s-1 .4-1.4 0l-5-5c-.2-.2-.3-.4-.3-.7z"
        ></path>
      </svg>
      Back
    </a>

    <h1
      autofocus
      tabindex="-1"
      id="h1-heading"
      class="nhsuk-heading-xl nhsuk-u-margin-bottom-3"
    >
      Application: {{ invitation.givenName }} {{ invitation.familyName }}
    </h1>
    <p class="nhsuk-body-l nhsuk-u-font-weight-bold grey-text">
      Status: {{ statusDescription }}
    </p>
    <div
      *ngIf="
        invitation.issues === undefined &&
        !faceLikenessLowerThanRequiredThreshold
      "
      class="nhsuk-alert nhsuk-alert--info nhsuk-alert--icon"
    >
      <svg
        class="nhsuk-icon nhsuk-icon__info nhsuk-alert__icon"
        fill="none"
        aria-hidden="true"
        focusable="false"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 25 25"
        height="25"
        width="25"
      >
        <path
          d="M13.7,18.5h-2.4v-2.4h2.4V18.5z M12.5,13.7c-0.7,0-1.2-0.5-1.2-1.2V7.7c0-0.7,0.5-1.2,1.2-1.2s1.2,0.5,1.2,1.2v4.8
        C13.7,13.2,13.2,13.7,12.5,13.7z M12.5,0.5c-6.6,0-12,5.4-12,12s5.4,12,12,12s12-5.4,12-12S19.1,0.5,12.5,0.5z"
        ></path>
      </svg>
      <h3 class="nhsuk-u-visually-hidden">Information:</h3>
      <p class="nhsuk-u-font-weight-bold">This application has no warnings</p>
    </div>
    <error-summary
      [errorSummaryMessages]="summaryErrorMessages"
    ></error-summary>
    <div
      *ngIf="
        invitation.issues !== undefined ||
        faceLikenessLowerThanRequiredThreshold
      "
      class="nhsuk-alert nhsuk-alert--warning nhsuk-alert--icon nhsuk-u-margin-top-4"
    >
      <svg
        class="nhsuk-icon nhsuk-icon__warning nhsuk-alert__icon"
        fill="none"
        aria-hidden="true"
        viewBox="0 0 512 512"
        style="enable-background: new 0 0 512 512"
      >
        <g>
          <g>
            <path
              d="M501.609,384.603L320.543,51.265c-13.666-23.006-37.802-36.746-64.562-36.746c-26.76,0-50.896,13.74-64.562,36.746
              c-0.103,0.176-0.19,0.352-0.293,0.528L10.662,384.076c-13.959,23.491-14.223,51.702-0.719,75.457
              c13.535,23.769,37.919,37.948,65.266,37.948h360.544c27.347,0,52.733-14.179,66.267-37.948
              C515.524,435.779,515.261,407.566,501.609,384.603z M225.951,167.148c0-16.586,13.445-30.03,30.03-30.03
              c16.586,0,30.03,13.445,30.03,30.03v120.121c0,16.584-13.445,30.03-30.03,30.03s-30.03-13.447-30.03-30.03V167.148z
              M255.981,437.421c-24.839,0-45.046-20.206-45.046-45.046c0-24.839,20.206-45.045,45.046-45.045
              c24.839,0,45.045,20.206,45.045,45.045C301.027,417.214,280.821,437.421,255.981,437.421z"
            ></path>
          </g>
        </g>
      </svg>
      <h3 class="nhsuk-u-visually-hidden">Warning:</h3>
      <p class="nhsuk-u-font-weight-bold">
        This application has the following warnings
      </p>
      <div
        *ngIf="invitation.issues !== undefined && invitation.issues.length > 0"
      >
        <div *ngFor="let issue of invitation.issues">
          <p>
            <strong>{{ issue.code }}</strong> {{ issue.description }}
          </p>
        </div>
      </div>
      <p *ngIf="faceLikenessLowerThanRequiredThreshold">
        Face likeness values are lower than 0.85.
      </p>
    </div>
    <section
      *ngIf="
        invitation.status == 50 ||
        invitation.status == 51 ||
        invitation.status == 52 ||
        invitation.status == 53
      "
    >
      <p>
        <a
          href="https://nhsd-confluence.digital.nhs.uk/display/CIS/ID+Checking"
          class="nhsuk-link--no-visited-state"
          target="_blank"
          rel="noopener noreferrer"
          >Review Care ID documents guidance (opens in a new tab)</a
        >
      </p>
      <div class="nhsuk-card">
        <div class="nhsuk-card__content">
          <h2 class="nhsuk-card__heading">Application summary</h2>
          <dl class="nhsuk-summary-list">
            <div class="nhsuk-summary-list__row">
              <dt class="nhsuk-summary-list__key">Application ID</dt>
              <dd class="nhsuk-summary-list__value">
                {{ invitation.invitationId }}
              </dd>
            </div>
            <div class="nhsuk-summary-list__row">
              <dt class="nhsuk-summary-list__key">Raised by</dt>
              <dd class="nhsuk-summary-list__value">
                {{ invitation.createdByUserName }} on
                {{ invitation.createdDateUtc | date: "d MMMM yyyy" }}
              </dd>
            </div>

            <div class="nhsuk-summary-list__row">
              <dt class="nhsuk-summary-list__key">Requestor email address</dt>
              <dd class="nhsuk-summary-list__value">
                {{ invitation.senderEmail }}
              </dd>
            </div>

            <div class="nhsuk-summary-list__row">
              <dt class="nhsuk-summary-list__key">Organisation</dt>
              <dd class="nhsuk-summary-list__value">
                {{ invitation.createdByUserOrgCode }}
              </dd>
            </div>

            <div
              *ngIf="invitation.status == 50 || invitation.status == 51"
              class="nhsuk-summary-list__row"
            >
              <dt class="nhsuk-summary-list__key">Submitted on</dt>
              <dd class="nhsuk-summary-list__value">
                {{
                  invitation.submittedIdCheckUtc | date: "d MMMM yyyy h.mm a"
                }}
              </dd>
            </div>

            <div
              *ngIf="invitation.status == 51"
              class="nhsuk-summary-list__row"
            >
              <dt class="nhsuk-summary-list__key">Added by</dt>
              <dd class="nhsuk-summary-list__value">
                {{ invitation.idCheckedBy }}
              </dd>
            </div>

            <div
              *ngIf="invitation.status == 51"
              class="nhsuk-summary-list__row"
            >
              <dt class="nhsuk-summary-list__key">On hold reasons</dt>
              <dd class="nhsuk-summary-list__value">
                <span *ngFor="let failure of getRejectReasonsPerDocument()">
                  {{ failure }}
                  <br />
                </span>
                <span *ngFor="let holdReason of invitation.holdReason">
                  {{ holdReason | idCheckHeldReasonPipe }}
                  <br />
                </span>
              </dd>
            </div>

            <div
              *ngIf="invitation.status == 52"
              class="nhsuk-summary-list__row"
            >
              <dt class="nhsuk-summary-list__key">Approved by</dt>
              <dd class="nhsuk-summary-list__value">
                {{ invitation.idCheckedBy }} on
                {{ invitation.idCheckedUtc | date: "d MMMM yyyy h.mm a" }}
              </dd>
            </div>

            <div
              *ngIf="invitation.status == 52"
              class="nhsuk-summary-list__row"
            >
              <dt class="nhsuk-summary-list__key">UUID</dt>
              <dd class="nhsuk-summary-list__value">
                <cim-link uuid="{{ invitation.cisUuid }}"></cim-link>
              </dd>
            </div>

            <div
              *ngIf="invitation.status == 52 && invitation.approvalNote"
              class="nhsuk-summary-list__row"
            >
              <dt class="nhsuk-summary-list__key">Notes</dt>
              <dd class="nhsuk-summary-list__value">
                {{ invitation.approvalNote }}
              </dd>
            </div>

            <div
              *ngIf="invitation.status == 53"
              class="nhsuk-summary-list__row"
            >
              <dt class="nhsuk-summary-list__key">Rejected by</dt>
              <dd class="nhsuk-summary-list__value">
                {{ invitation.idCheckedBy }} on
                {{ invitation.idCheckedUtc | date: "d MMMM yyyy h.mm a" }}
              </dd>
            </div>

            <div
              *ngIf="invitation.status == 53"
              class="nhsuk-summary-list__row"
            >
              <dt class="nhsuk-summary-list__key">Rejection reasons</dt>
              <dd class="nhsuk-summary-list__value">
                <p *ngIf="invitation.failedDocument">
                  {{ invitation.failedDocument }}
                </p>
                <p
                  *ngIf="invitation.failedReason"
                  [innerHTML]="
                    invitation.failedReason
                      | IdFailureCheckReasonParagraphPipe
                        : invitation.photoIdOneType
                        : invitation.photoIdTwoType
                        : true
                  "
                ></p>
              </dd>
            </div>
            <div
              *ngIf="invitation.status == 53"
              class="nhsuk-summary-list__row"
            >
              <dt class="nhsuk-summary-list__key">Rejection notes</dt>
              <dd class="nhsuk-summary-list__value">
                {{ invitation.failedNotes }}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </section>

    <section *ngIf="invitation.status == 51">
      <div class="nhsuk-card nhsuk-u-margin-bottom-5">
        <div class="nhsuk-card__content">
          <h2 class="nhsuk-card__heading">Notes</h2>

          <table role="table" class="nhsuk-table-responsive">
            <caption class="nhsuk-table__caption nhsuk-u-visually-hidden">
              Notes
            </caption>
            <thead class="nhsuk-table__head">
              <tr role="row">
                <th role="columnheader" class="" scope="col">Note</th>
                <th role="columnheader" class="" scope="col">Added by</th>
                <th role="columnheader" class="" scope="col">Date</th>
                <th role="columnheader" class="" scope="col">Actions</th>
              </tr>
            </thead>
            <tbody
              *ngIf="invitation.holdInternalNotes"
              class="nhsuk-table__body"
            >
              <tr
                *ngFor="let note of invitation.holdInternalNotes"
                role="row"
                class="nhsuk-table__row"
              >
                <td class="nhsuk-table__cell">
                  <span class="nhsuk-table-responsive__heading">Note</span>
                  {{ note.note }}
                </td>
                <td class="nhsuk-table__cell">
                  <span class="nhsuk-table-responsive__heading">Added by</span
                  >{{ note.noteCreatedByUserName }}
                </td>
                <td class="nhsuk-table__cell">
                  <span class="nhsuk-table-responsive__heading">Date</span
                  >{{ note.noteCreatedByUserName }}
                </td>
                <td class="nhsuk-table__cell">
                  <span class="nhsuk-table-responsive__heading">Actions</span
                  ><a
                    attr.data-cy="link-edit-note-{{ invitationId }}"
                    [state]="{ note: note }"
                    [routerLink]="['/id-checker/edit-note', invitationId]"
                    >Edit</a
                  >
                  |
                  <button class="link" (click)="deleteNote(note.noteId)">
                    Delete
                  </button>
                </td>
              </tr>
            </tbody>
          </table>

          <a
            class="nhsuk-button nhsuk-button--secondary nhsuk-u-margin-top-4"
            [routerLink]="['/id-checker/create-note', invitationId]"
            >Add note</a
          >
        </div>
      </div>
    </section>

    <section
      *ngIf="
        invitation.cis_duplicate_match &&
        invitation.cis_duplicate_matching_fields &&
        (invitation.status == 50 || invitation.status == 51)
      "
    >
      <div class="nhsuk-card nhsuk-u-margin-bottom-5">
        <div class="nhsuk-card__content">
          <h2>Duplicate profile check</h2>
          <p class="nhsuk-u-reading-width">
            You must check Care Identity Management (CIM) to confirm if this
            application is a duplicate.
          </p>
          <h2 class="nhsuk-heading-s">Possible duplicate profile</h2>
          <dl class="nhsuk-summary-list nhsuk-u-width-one-half">
            <div class="nhsuk-summary-list__row">
              <dt class="nhsuk-summary-list__key">UUID</dt>
              <dd class="nhsuk-summary-list__value">
                <a
                  target="_blank"
                  rel="noopener"
                  href="{{ getCimDuplicateLink() }}"
                  >{{ invitation.cis_duplicate_match }}</a
                >
              </dd>
            </div>
          </dl>
          <table
            role="table"
            class="nhsuk-table-responsive nhsuk-u-margin-bottom-5"
          >
            <caption class="nhsuk-table__caption">
              Applicant details
            </caption>
            <thead class="nhsuk-table__head">
              <tr role="row">
                <th role="columnheader" class="" scope="col">Profile photo</th>
                <th role="columnheader" class="" scope="col">Full name</th>
                <th role="columnheader" class="" scope="col">Date of birth</th>
                <th role="columnheader" class="" scope="col">
                  Unique identifier
                </th>
              </tr>
            </thead>
            <tbody class="nhsuk-table__body">
              <tr role="row" class="nhsuk-table__row">
                <td class="nhsuk-table__cell nhsuk-u-width-one-quarter">
                  <span class="nhsuk-table-responsive__heading"
                    >Profile photo</span
                  >
                  <figure
                    class="nhsuk-image nhsuk-u-margin-top-1 nhsuk-u-margin-bottom-3"
                    style="width: 100%"
                  >
                    <img
                      class="nhsuk-image__img"
                      src="{{ imageBase64 }}"
                      alt="Duplicate profile photo"
                    />
                  </figure>
                </td>
                <td class="nhsuk-table__cell">
                  <span class="nhsuk-table-responsive__heading">Full name</span>
                  <p class="nhsuk-u-margin-bottom-1">
                    {{ getFullApplicantEnteredName() }}>
                  </p>
                  <p
                    *ngIf="
                      invitation.cis_duplicate_matching_fields.includes(
                        'givenNameMatch'
                      ) ||
                      invitation.cis_duplicate_matching_fields.includes(
                        'familyNameMatch'
                      )
                    "
                  >
                    (Match found)
                  </p>
                </td>
                <td class="nhsuk-table__cell">
                  <span class="nhsuk-table-responsive__heading"
                    >Date of birth</span
                  >
                  <p class="nhsuk-u-margin-bottom-1">
                    {{ invitation.dateOfBirth | date: "dd/MM/yyyy" }}
                  </p>
                  <p
                    *ngIf="
                      invitation.cis_duplicate_matching_fields.includes(
                        'dateOfBirthMatch'
                      )
                    "
                  >
                    (Match found)
                  </p>
                </td>
                <td class="nhsuk-table__cell">
                  <span class="nhsuk-table-responsive__heading"
                    >Unique identifier</span
                  >
                  <p class="nhsuk-u-margin-bottom-1">
                    {{
                      invitation.niNumber
                        ? "NI Number: " + invitation.niNumber
                        : "NI Number not available"
                    }}
                  </p>
                  <p>
                    {{
                      invitation.cis_duplicate_matching_fields.includes(
                        "niNumberMatch"
                      )
                        ? "(Match found)"
                        : "(No match)"
                    }}
                  </p>
                  <p class="nhsuk-u-margin-bottom-1">
                    {{
                      passportNo
                        ? "Passport number: " + passportNo
                        : "Passport number not available"
                    }}
                  </p>
                  <p *ngIf="passportNo">
                    {{
                      invitation.cis_duplicate_matching_fields.includes(
                        "passportMatch"
                      )
                        ? "(Match found)"
                        : "(No match)"
                    }}
                  </p>
                  <p class="nhsuk-u-margin-bottom-1">
                    {{
                      drivingLicenseNo
                        ? "Driving licence number: " + drivingLicenseNo
                        : "Driving licence number not available"
                    }}
                  </p>
                  <p *ngIf="drivingLicenseNo">
                    {{
                      invitation.cis_duplicate_matching_fields.includes(
                        "drivingLicenseMatch"
                      )
                        ? "(Match found)"
                        : "(No match)"
                    }}
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <id-check-radio-button
            #duplicateProfileInput
            [failedDocument]=""
            [invitation]="invitation"
            (questionAnswer)="updateDuplicateProfileAnswer($event)"
            [id]="duplicateProfile"
            [idCheckFailures]="idCheckFailures"
          ></id-check-radio-button>
        </div>
      </div>
    </section>

    <div
      class="nhsuk-card"
      *ngIf="invitation.dpoaUsed && invitation.dpoaCheck != null"
    >
      <div class="nhsuk-card__content">
        <h2 class="nhsuk-card__heading">UK address check</h2>
        <p class="nhsuk-u-reading-width">
          Applicants must score 90 or more points to pass the address check.
        </p>
        <dl class="nhsuk-summary-list nhsuk-summary-list">
          <div class="nhsuk-summary-list__row">
            <dt class="nhsuk-summary-list__key">Result</dt>
            <dd class="nhsuk-summary-list__value">
              {{ invitation.dpoaCheck.identityResult }}
            </dd>
          </div>
          <div class="nhsuk-summary-list__row">
            <dt class="nhsuk-summary-list__key">Score</dt>
            <dd class="nhsuk-summary-list__value">
              {{ invitation.dpoaCheck.identityScore }}
            </dd>
          </div>
          <div class="nhsuk-summary-list__row">
            <dt class="nhsuk-summary-list__key">Primary checks matched</dt>
            <dd class="nhsuk-summary-list__value">
              {{ invitation.dpoaCheck.numPrimaryChecks }}
            </dd>
          </div>
          <div class="nhsuk-summary-list__row">
            <dt class="nhsuk-summary-list__key">Date of birth confirmed</dt>
            <dd class="nhsuk-summary-list__value">
              {{ invitation.dpoaCheck.confirmatoryDobs }}
            </dd>
          </div>
          <div class="nhsuk-summary-list__row">
            <dt class="nhsuk-summary-list__key">Warnings</dt>
            <dd class="nhsuk-summary-list__value">
              <ul
                class="nhsuk-list nhsuk-list--cross nhsuk-u-margin-bottom-0"
                *ngIf="
                  invitation.dpoaCheck.warnAddressLinks ||
                    invitation.dpoaCheck.ncoaAlertFlag ||
                    invitation.dpoaCheck.warnDeceased;
                  else noWarnings
                "
              >
                <li *ngIf="invitation.dpoaCheck.warnAddressLinks">
                  <icon-cross></icon-cross>
                  Forward address links found
                </li>
                <li *ngIf="invitation.dpoaCheck.ncoaAlertFlag">
                  <icon-cross></icon-cross>
                  NCOA alert found for this address
                </li>
                <li *ngIf="invitation.dpoaCheck.warnDeceased">
                  <icon-cross></icon-cross>
                  Applicant matched with GRO or Halo deceased registers
                </li>
              </ul>

              <ng-template #noWarnings>
                <span> No warnings </span>
              </ng-template>
            </dd>
          </div>
          <div
            class="nhsuk-summary-list__row"
            *ngIf="invitation.dpoaCheck.warnLor"
          >
            <dt class="nhsuk-summary-list__key">Additional information</dt>
            <dd class="nhsuk-summary-list__value">
              <ul class="nhsuk-list nhsuk-list--cross nhsuk-u-margin-bottom-0">
                <li>
                  <svg
                    class="nhsuk-icon nhsuk-icon__info"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 25 25"
                    aria-hidden="true"
                    height="34"
                    width="34"
                  >
                    <path
                      d="M13.7,18.5h-2.4v-2.4h2.4V18.5z M12.5,13.7c-0.7,0-1.2-0.5-1.2-1.2V7.7c0-0.7,0.5-1.2,1.2-1.2s1.2,0.5,1.2,1.2v4.8
                    C13.7,13.2,13.2,13.7,12.5,13.7z M12.5,0.5c-6.6,0-12,5.4-12,12s5.4,12,12,12s12-5.4,12-12S19.1,0.5,12.5,0.5z"
                    ></path>
                  </svg>
                  length of residence is less than 12 months
                </li>
              </ul>
            </dd>
          </div>
          <div class="nhsuk-summary-list__row">
            <dt class="nhsuk-summary-list__key">Address</dt>
            <dd class="nhsuk-summary-list__value">
              {{ invitation.tuAddress }}
            </dd>
          </div>
        </dl>
      </div>
    </div>

    <div class="nhsuk-card">
      <div class="nhsuk-card__content">
        <h2 class="nhsuk-card__heading">
          Photo ID 1: {{ invitation.photoIdOneType | photoIdDocTypePipe }}
        </h2>

        <ul
          class="nhsuk-list nhsuk-list--tick"
          *ngIf="invitation.photoIdOneType == 'Passport' && eChipImg"
        >
          <li>
            <icon-tick></icon-tick>
            eChip read
          </li>
        </ul>

        <div class="nhsuk-grid-row">
          <div
            [ngClass]="
              photoIdOneFrontExpand || photoIdOneBackExpand
                ? 'nhsuk-grid-column-full'
                : 'nhsuk-grid-column-one-half'
            "
          >
            <div class="nhsuk-grid-row">
              <div
                [ngClass]="
                  photoIdOneFrontExpand || !photoIdOneBackExpand
                    ? 'nhsuk-grid-column-full'
                    : 'nhsuk-grid-column-one-half'
                "
              >
                <id-checker-id-photo
                  *ngIf="this.photoIdOneImgFront"
                  [photoUrl]="this.photoIdOneImgFront"
                  [altText]="'Photo ID 1 front'"
                  (isPhotoExpanded)="this.isPhotoIdOneFrontExpanded($event)"
                  [showInstructions]="true"
                ></id-checker-id-photo>
              </div>
              <div
                class="margin-top-20"
                [ngClass]="
                  photoIdOneBackExpand || !photoIdOneFrontExpand
                    ? 'nhsuk-grid-column-full'
                    : 'nhsuk-grid-column-one-half'
                "
              >
                <id-checker-id-photo
                  *ngIf="this.photoIdOneImgBack"
                  [photoUrl]="this.photoIdOneImgBack"
                  [altText]="'Photo ID 1 back'"
                  (isPhotoExpanded)="this.isPhotoIdOneBackExpanded($event)"
                >
                </id-checker-id-photo>
              </div>
            </div>
          </div>

          <dl
            class="nhsuk-summary-list nhsuk-summary-list nhsuk-u-width-one-half"
          >
            <div>
              <div class="nhsuk-summary-list__row">
                <dt class="nhsuk-summary-list__key">Date of Expiry</dt>
                <dd class="nhsuk-summary-list__value">
                  {{
                    invitation.photoIdOneDateOfExpiry
                      ? (invitation.photoIdOneDateOfExpiry
                        | date: "dd MMM yyyy")
                      : "Not available"
                  }}
                </dd>
              </div>
              <div
                class="nhsuk-summary-list__row"
                *ngIf="this.isPhotoIdOneExpired()"
              >
                <dt class="nhsuk-summary-list__key">Warnings</dt>
                <dd class="nhsuk-summary-list__value">
                  <ul
                    class="nhsuk-list nhsuk-list--cross nhsuk-u-margin-bottom-0"
                  >
                    <li>
                      <icon-cross></icon-cross>
                      Photo ID has expired
                    </li>
                  </ul>
                </dd>
              </div>
            </div>
          </dl>

          <div class="nhsuk-grid-column-one-half">
            <div *ngIf="invitation.status == 50 || invitation.status == 51">
              <div class="nhsuk-form-group nhsuk-u-margin-top-5">
                <id-check-radio-button
                  *ngIf="!duplicateProfileAnswer"
                  #photoIdOneInput
                  [failedDocument]="photoIdOne"
                  [invitation]="invitation"
                  (questionAnswer)="updatePhotoIdOneAnswer($event)"
                  [radioError]="
                    searchSummaryErrorMessages('radio-Photo ID 1-yes')
                  "
                  [id]="photoIdOne"
                  [idCheckFailures]="idCheckFailures"
                ></id-check-radio-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="nhsuk-card" *ngIf="poaRouteTaken">
      <div class="nhsuk-card__content">
        <h2 class="nhsuk-card__heading">
          Photo ID 2: {{ invitation.photoIdTwoType | photoIdDocTypePipe }}
        </h2>

        <div class="nhsuk-grid-row">
          <div
            [ngClass]="
              photoIdTwoFrontExpand || photoIdTwoBackExpand
                ? 'nhsuk-grid-column-full'
                : 'nhsuk-grid-column-one-half'
            "
          >
            <div class="nhsuk-grid-row">
              <div
                [ngClass]="
                  photoIdTwoFrontExpand || !photoIdTwoBackExpand
                    ? 'nhsuk-grid-column-full'
                    : 'nhsuk-grid-column-one-half'
                "
              >
                <id-checker-id-photo
                  *ngIf="this.photoIdTwoImgFront"
                  [photoUrl]="this.photoIdTwoImgFront"
                  [altText]="'Photo ID 2 front'"
                  (isPhotoExpanded)="this.isPhotoIdTwoFrontExpanded($event)"
                  [showInstructions]="true"
                ></id-checker-id-photo>
              </div>
              <div
                class="margin-top-20"
                [ngClass]="
                  photoIdTwoBackExpand || !photoIdTwoFrontExpand
                    ? 'nhsuk-grid-column-full'
                    : 'nhsuk-grid-column-one-half'
                "
              >
                <id-checker-id-photo
                  *ngIf="this.photoIdTwoImgBack"
                  [photoUrl]="this.photoIdTwoImgBack"
                  [altText]="'Photo ID 2 back'"
                  (isPhotoExpanded)="this.isPhotoIdTwoBackExpanded($event)"
                >
                </id-checker-id-photo>
              </div>
            </div>
          </div>

          <dl
            class="nhsuk-summary-list nhsuk-summary-list nhsuk-u-width-one-half"
          >
            <div>
              <div class="nhsuk-summary-list__row">
                <dt class="nhsuk-summary-list__key">Date of Expiry</dt>
                <dd class="nhsuk-summary-list__value">
                  {{
                    invitation.photoIdTwoDateOfExpiry
                      ? (invitation.photoIdTwoDateOfExpiry
                        | date: "dd MMM yyyy")
                      : "Not available"
                  }}
                </dd>
              </div>
              <div
                class="nhsuk-summary-list__row"
                *ngIf="this.isPhotoIdTwoExpired()"
              >
                <dt class="nhsuk-summary-list__key">Warnings</dt>
                <dd class="nhsuk-summary-list__value">
                  <ul
                    class="nhsuk-list nhsuk-list--cross nhsuk-u-margin-bottom-0"
                  >
                    <li>
                      <icon-cross></icon-cross>
                      Photo ID has expired
                    </li>
                  </ul>
                </dd>
              </div>
            </div>
          </dl>

          <div class="nhsuk-grid-column-one-half">
            <div *ngIf="invitation.status == 50 || invitation.status == 51">
              <div class="nhsuk-form-group nhsuk-u-margin-top-5">
                <id-check-radio-button
                  *ngIf="!duplicateProfileAnswer"
                  #photoIdTwoInput
                  [failedDocument]="photoIdTwo"
                  [invitation]="invitation"
                  (questionAnswer)="updatePhotoIdTwoAnswer($event)"
                  [radioError]="
                    searchSummaryErrorMessages('radio-Photo ID 2-yes')
                  "
                  [id]="photoIdTwo"
                  [idCheckFailures]="idCheckFailures"
                ></id-check-radio-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="nhsuk-card" *ngIf="poaRouteTaken && !duplicateProfileAnswer">
      <div class="nhsuk-card__content">
        <h2 class="nhsuk-card__heading">Proof of address</h2>

        <div class="nhsuk-grid-row">
          <div
            [ngClass]="
              poaExpand
                ? 'nhsuk-grid-column-full'
                : 'nhsuk-grid-column-one-half'
            "
          >
            <id-checker-id-photo
              *ngIf="this.poaImg"
              [photoUrl]="this.poaImg"
              [altText]="'Proof of address'"
              [showInstructions]="true"
              (isPhotoExpanded)="this.isPoaExpanded($event)"
            ></id-checker-id-photo>
          </div>

          <div class="nhsuk-grid-column-one-half">
            <div *ngIf="invitation.status == 50 || invitation.status == 51">
              <div class="nhsuk-form-group nhsuk-u-margin-top-5">
                <id-check-radio-button
                  #proofOfAddressInput
                  [failedDocument]="proofOfAddress"
                  [invitation]="invitation"
                  (questionAnswer)="updateProofOfAddressAnswer($event)"
                  [radioError]="
                    searchSummaryErrorMessages('radio-Proof of address-yes')
                  "
                  [id]="proofOfAddress"
                  [idCheckFailures]="idCheckFailures"
                ></id-check-radio-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="nhsuk-card">
      <div class="nhsuk-card__content">
        <h2 class="nhsuk-card__heading">Name and date of birth</h2>
        <p class="nhsuk-u-reading-width">
          Compare name and date of birth with photo ids and proof of address.
        </p>
        <div class="nhsuk-grid-row">
          <div class="nhsuk-grid-column-one-half">
            <dl
              *ngIf="invitation"
              class="nhsuk-summary-list nhsuk-summary-list"
            >
              <div
                *ngIf="invitation.inviterEnteredGivenName"
                class="nhsuk-summary-list__row"
              >
                <dt class="nhsuk-summary-list__key key-width">
                  Name entered by requestor
                </dt>
                <dd class="nhsuk-summary-list__value">
                  {{ this.getFullInvitedName() }}
                </dd>
              </div>

              <div class="nhsuk-summary-list__row">
                <dt class="nhsuk-summary-list__key key-width">
                  Applicant name
                </dt>
                <dd class="nhsuk-summary-list__value">
                  {{ this.getFullApplicantEnteredName() }}
                </dd>
              </div>

              <div
                *ngIf="invitation.preferredName"
                class="nhsuk-summary-list__row"
              >
                <dt class="nhsuk-summary-list__key key-width">
                  Smartcard name
                </dt>
                <dd class="nhsuk-summary-list__value">
                  {{ this.invitation.preferredName }}
                </dd>
              </div>

              <div class="nhsuk-summary-list__row">
                <dt class="nhsuk-summary-list__key key-width">Date of birth</dt>
                <dd class="nhsuk-summary-list__value">
                  {{ invitation.dateOfBirth | date: "dd/MM/yyyy" }}
                </dd>
              </div>

              <div class="nhsuk-summary-list__row">
                <dt class="nhsuk-summary-list__key key-width">NI Number</dt>
                <dd class="nhsuk-summary-list__value">
                  {{ invitation.niNumber }}
                </dd>
              </div>
            </dl>
          </div>
          <div class="nhsuk-grid-column-one-half">
            <div class="nhsuk-expander-group">
              <details class="nhsuk-details nhsuk-expander" open>
                <summary class="nhsuk-details__summary">
                  <span class="nhsuk-details__summary-text">
                    Compare to photo ID 1
                  </span>
                </summary>
                <div class="nhsuk-details__text">
                  <div class="container">
                    <img [src]="photoIdOneImgFront" alt="Photo 1 Front" />
                    <img
                      *ngIf="invitation.photoIdOneType == 'ResidencePermit'"
                      [src]="photoIdOneImgBack"
                      alt="Photo 1 Back"
                    />
                  </div>
                </div>
              </details>

              <details
                class="nhsuk-details nhsuk-expander"
                *ngIf="poaRouteTaken"
              >
                <summary class="nhsuk-details__summary">
                  <span class="nhsuk-details__summary-text">
                    Compare to photo ID 2
                  </span>
                </summary>
                <div class="nhsuk-details__text">
                  <div class="container">
                    <img [src]="photoIdTwoImgFront" alt="Photo 2 Front" />
                    <img
                      *ngIf="invitation.photoIdTwoType == 'ResidencePermit'"
                      [src]="photoIdTwoImgBack"
                      alt="Photo 2 Back"
                    />
                  </div>
                </div>
              </details>

              <details
                class="nhsuk-details nhsuk-expander"
                *ngIf="poaRouteTaken"
              >
                <summary class="nhsuk-details__summary">
                  <span class="nhsuk-details__summary-text">
                    Compare to proof of address
                  </span>
                </summary>
                <div class="nhsuk-details__text">
                  <div class="container">
                    <img [src]="poaImg" alt="Proof of address" />
                  </div>
                </div>
              </details>
            </div>
          </div>
        </div>
        <div *ngIf="invitation.status == 50 || invitation.status == 51">
          <div class="nhsuk-form-group nhsuk-u-margin-top-5">
            <id-check-radio-button
              *ngIf="!duplicateProfileAnswer"
              #nameAndDobInput
              [failedDocument]="nameAndDob"
              [invitation]="invitation"
              (questionAnswer)="updateNameAndDobAnswer($event)"
              [radioError]="
                searchSummaryErrorMessages('radio-Name and date of birth-yes')
              "
              [id]="nameAndDob"
              [idCheckFailures]="idCheckFailures"
            ></id-check-radio-button>
          </div>
        </div>
      </div>
    </div>

    <div class="nhsuk-card">
      <div class="nhsuk-card__content">
        <h2 class="nhsuk-card__heading">Face likeness and liveness</h2>
        <div *ngIf="invitation.matchResults != undefined">
          <p class="nhsuk-u-reading-width">
            Compare the face scan with photo IDs to verify a true likenesss.
          </p>
          <p>Likeness values should be higher than 0.85</p>
          <dl
            class="nhsuk-summary-list nhsuk-summary-list nhsuk-u-width-one-half"
          >
            <div
              *ngFor="let result of invitation.matchResults"
              class="nhsuk-summary-list__row"
            >
              <dt class="nhsuk-summary-list__key key-width">
                {{ result.key }}
              </dt>
              <dd class="nhsuk-summary-list__value">{{ result.value }}</dd>
            </div>
          </dl>
        </div>

        <div class="nhsuk-grid-row">
          <div class="nhsuk-grid-column-one-half">
            <div class="container">
              <img [src]="faceScanImg" alt="Face scan" />
            </div>
          </div>
          <div class="nhsuk-grid-column-one-half">
            <div class="nhsuk-expander-group">
              <details
                open
                class="nhsuk-details nhsuk-expander"
                *ngIf="invitation.photoIdOneType == 'Passport' && eChipImg"
              >
                <summary class="nhsuk-details__summary">
                  <span class="nhsuk-details__summary-text">
                    Compare to eChip
                  </span>
                </summary>
                <div class="nhsuk-details__text">
                  <div *ngIf="eChipImg" class="container">
                    <img alt="eChip image" [src]="eChipImg" />
                  </div>
                </div>
              </details>

              <details
                class="nhsuk-details nhsuk-expander"
                open
                *ngIf="
                  !(invitation.photoIdOneType == 'Passport' && eChipImg);
                  else compareClosed
                "
              >
                <summary class="nhsuk-details__summary">
                  <span class="nhsuk-details__summary-text">
                    Compare to photo ID 1
                  </span>
                </summary>
                <div class="nhsuk-details__text">
                  <div class="container">
                    <img
                      *ngIf="extractedPassportImg; else displayImgPhoto1Open"
                      [src]="extractedPassportImg"
                      alt="Photo 1 Front"
                    />
                    <ng-template #displayImgPhoto1Open>
                      <img [src]="photoIdOneImgFront" alt="Photo 1 Front" />
                    </ng-template>
                  </div>
                </div>
              </details>
              <ng-template #compareClosed>
                <details class="nhsuk-details nhsuk-expander">
                  <summary class="nhsuk-details__summary">
                    <span class="nhsuk-details__summary-text">
                      Compare to photo ID 1
                    </span>
                  </summary>
                  <div class="nhsuk-details__text">
                    <div class="container">
                      <img
                        *ngIf="
                          extractedPassportImg;
                          else displayImgPhoto1Closed
                        "
                        [src]="extractedPassportImg"
                        alt="Photo 1 Front"
                      />
                      <ng-template #displayImgPhoto1Closed>
                        <img [src]="photoIdOneImgFront" alt="Photo 1 Front" />
                      </ng-template>
                    </div>
                  </div>
                </details>
              </ng-template>
              <details
                class="nhsuk-details nhsuk-expander"
                *ngIf="poaRouteTaken"
              >
                <summary class="nhsuk-details__summary">
                  <span class="nhsuk-details__summary-text">
                    Compare to photo ID 2
                  </span>
                </summary>
                <div class="nhsuk-details__text">
                  <div class="container">
                    <img [src]="photoIdTwoImgFront" alt="Photo 2 Front" />
                  </div>
                </div>
              </details>

              <details
                class="nhsuk-details nhsuk-expander"
                *ngIf="profilePhotoImg"
              >
                <summary class="nhsuk-details__summary">
                  <span class="nhsuk-details__summary-text">
                    Compare to photo uploaded by applicant
                  </span>
                </summary>
                <div class="nhsuk-details__text">
                  <div class="container">
                    <img
                      [src]="profilePhotoImg"
                      alt="Photo uploaded by applicant"
                    />
                  </div>
                </div>
              </details>
            </div>
          </div>
        </div>
        <div *ngIf="invitation.status == 50 || invitation.status == 51">
          <div class="nhsuk-form-group nhsuk-u-margin-top-5">
            <id-check-radio-button
              *ngIf="!duplicateProfileAnswer"
              #faceScanInput
              [failedDocument]="faceScan"
              [invitation]="invitation"
              (questionAnswer)="updateFaceScanAnswer($event)"
              [radioError]="searchSummaryErrorMessages('radio-Face scan-yes')"
              [id]="faceScan"
              [idCheckFailures]="idCheckFailures"
            ></id-check-radio-button>
          </div>
        </div>
      </div>
    </div>

    <div class="nhsuk-card">
      <div class="nhsuk-card__content">
        <h2 class="nhsuk-card__heading">Smartcard photo</h2>
        <p class="nhsuk-u-width-two-thirds">
          Smartcard photos must meet passport photo rules and be taken against a
          plain background with adequate lighting.
        </p>
        <ul class="nhsuk-u-width-two-thirds nhsuk-u-margin-bottom-6">
          <li>it is clear and in focus</li>
          <li>the person shown is facing forward</li>
          <li>their face is not hidden by hair or other objects</li>
          <li>the photo does not contain any other people or objects</li>
        </ul>
        <div class="nhsuk-grid-row">
          <div
            class="nhsuk-grid-column-one-half"
            *ngIf="selectedSmartcardPhoto"
          >
            <div
              *ngIf="invitation.status == 50 || invitation.status == 51"
              class="container"
            >
              <h3>Original image</h3>

              <afcid-image-cropper
                *ngIf="imageBase64"
                #afcidImageCropper
                [imageBase64]="imageBase64"
                (croppedImage)="updateCroppedImage($event)"
              ></afcid-image-cropper>
            </div>

            <img
              *ngIf="invitation.status == 53"
              [src]="selectedSmartcardPhoto.data"
              alt="Smartcard photo"
              class="container"
            />
            <div *ngIf="invitation.status == 52">
              <img
                *ngIf="profile_photo_cropped_b64; else noCroppedPhoto"
                [src]="profile_photo_cropped_b64"
                alt="Smartcard photo"
              />
              <ng-template #noCroppedPhoto>
                <img
                  [src]="selectedSmartcardPhoto.data"
                  alt="Smartcard photo"
                  class="container"
                />
              </ng-template>
            </div>
          </div>

          <div class="nhsuk-grid-column-one-half">
            <div
              *ngIf="invitation.status == 50 || invitation.status == 51"
              class="nhsuk-form-group nhsuk-u-margin-bottom-3"
            >
              <label
                class="nhsuk-label--m nhsuk-u-margin-bottom-2"
                for="photo-source"
              >
                Select a different photo
              </label>
              <select
                (ngModelChange)="onPhotoChange($event)"
                [(ngModel)]="selectedSmartcardPhoto"
                class="nhsuk-select"
                id="photo-source"
                name="photo-source"
              >
                <option
                  *ngFor="let image of availableSmartcardImages"
                  [ngValue]="image"
                >
                  {{ image.displayName }}
                </option>
              </select>

              <br />
              <br />

              <h3>Preview</h3>
              <div class="nhsuk-hint" id="preferred-name-hint">
                This photo will be used for the smartcard.
              </div>
              <img
                title="Filesize should ideally be 20 kb to 1024 kb. Current estimate: {{
                  fileSizeEstimate
                }}"
                alt="Preview of photo to be used for smartcard."
                [src]="croppedImage"
                [style.border]="croppedImage ? '1px solid black' : 'none'"
              />
            </div>
          </div>
        </div>
        <div *ngIf="invitation.status == 50 || invitation.status == 51">
          <div class="nhsuk-form-group nhsuk-u-margin-top-5">
            <id-check-radio-button
              *ngIf="!duplicateProfileAnswer"
              #smartcardPhotoInput
              [failedDocument]="smartcardPhoto"
              [id]="smartcardPhoto"
              [invitation]="invitation"
              (questionAnswer)="updateSmartcardPhotoAnswer($event)"
              [radioError]="
                searchSummaryErrorMessages('radio-Smartcard photo-yes')
              "
              [idCheckFailures]="idCheckFailures"
            ></id-check-radio-button>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="storeCroppedPhotoErrorMessage">
      <span class="nhsuk-error-message" data-cy="cropped-photo-error-message">
        {{ storeCroppedPhotoErrorMessage }}
      </span>
    </div>

    <div *ngIf="invitation.status == 50 || invitation.status == 51">
      <button
        data-cy="submit-approve"
        [disabled]="rejected"
        class="nhsuk-button nhsuk-u-margin-right-9"
        (click)="approveButtonClicked()"
        [ngClass]="{ 'spinner-button': storeCroppedPhotoInProgress }"
      >
        Approve
      </button>
      <button
        data-cy="submit-reject"
        [disabled]="approved || submitting"
        class="nhsuk-button nhsuk-button--secondary delete-button nhsuk-u-margin-right-9"
        (click)="rejectButtonClicked()"
      >
        Reject
      </button>

      <button
        data-cy="submit-hold"
        class="nhsuk-button nhsuk-button--secondary nhsuk-u-margin-right-9"
        (click)="holdButtonClicked()"
        *ngIf="invitation.status != 51"
      >
        Put on hold
      </button>
    </div>
  </div>
</div>

<div class="modal" role="dialog" *ngIf="reviewModal">
  <div class="nhsuk-card timeout">
    <div class="nhsuk-card__content">
      <h3 class="nhsuk-card__heading">Document review</h3>

      <div *ngIf="reviewInProgressWarning">
        <p class="nhsuk-card__description"></p>
        <p>
          This application is assigned to
          <strong>{{ invitation.reviewBy }}</strong>
        </p>
        <p>
          The assignment will expire on
          {{ invitation.reviewExpiryUtc | date: "d MMMM yyyy HH:mm" }}
        </p>
        <p>Assign this application to yourself by overriding it.</p>

        <button
          id="override-review-submit"
          tabindex="1"
          type="submit"
          (click)="startApplicationReview()"
          class="nhsuk-button"
        >
          Override
        </button>
      </div>

      <p>
        <a
          *ngIf="invitation.status == 50"
          class="nhsuk-link nhsuk-card--clickable"
          tabindex="2"
          href="/id-checker/review-list"
          >Go Back</a
        >
        <a
          *ngIf="invitation.status == 51"
          class="nhsuk-link nhsuk-card--clickable"
          tabindex="2"
          href="/id-checker/holding-list"
          >Go Back</a
        >
      </p>
    </div>
  </div>
</div>
