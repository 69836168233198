import { Component, Inject, OnInit } from "@angular/core";
import { SESSION_STORAGE, StorageService } from "ngx-webstorage-service";

@Component({
  selector: "role-changed",
  templateUrl: "./role-changed.component.html",
})
export class RoleChangedComponent implements OnInit {
  displayAlert = false;
  roleName: string;
  roleCode: string;
  orgName: string;
  orgCode: string;

  constructor(
    @Inject(SESSION_STORAGE) private sessionStorage: StorageService,
  ) {}

  ngOnInit(): void {
    const changeRole = this.sessionStorage.get("changedRoleAlert");
    if (changeRole !== undefined && changeRole) {
      this.displayChangedRoleDetails();
      this.clearChangeRoleFlag();
    }
  }

  displayChangedRoleDetails(): void {
    this.displayAlert = true;
    this.roleName = this.sessionStorage.get("sessionToken").tertiary_role_name;
    this.roleCode = this.sessionStorage.get("sessionToken").tertiary_role_code;
    this.orgName = this.sessionStorage.get("sessionToken").org_name;
    this.orgCode = this.sessionStorage.get("sessionToken").org_code;
  }

  clearChangeRoleFlag(): void {
    this.sessionStorage.remove("changedRoleAlert");
  }
}
