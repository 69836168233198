<div class="nhsuk-grid-row">
  <div class="nhsuk-grid-column-two-thirds">
    <div class="nhsuk-form-group">
      <error-summary
        [errorSummaryMessages]="summaryErrorMessages"
      ></error-summary>

      <fieldset class="nhsuk-fieldset">
        <legend
          class="nhsuk-fieldset__legend nhsuk-fieldset__legend--xl nhsuk-u-margin-bottom-7"
        >
          <h1 autofocus tabindex="-1" class="nhsuk-fieldset__heading">
            How do you want to verify your address?
          </h1>
        </legend>

        <div class="nhsuk-radios" id="dpoa-consent">
          <div class="nhsuk-radios__item">
            <input
              data-cy="verify-address-yes"
              class="nhsuk-radios__input"
              aria-controls="yes"
              aria-expanded="false"
              type="radio"
              (change)="updateDpoaConsentRadioValue(true)"
              id="dpoa-yes"
              name="dpoa"
            />
            <label class="nhsuk-label nhsuk-radios__label" for="dpoa-yes">
              Enter your UK address
            </label>
            <div class="nhsuk-hint nhsuk-radios__hint" id="dpoa-yes-hint">
              TransUnion will check for connections between your address and
              organisations like banks and utility providers
            </div>
          </div>

          <div class="nhsuk-radios__item">
            <input
              data-cy="verify-address-no"
              class="nhsuk-radios__input"
              aria-controls="no"
              aria-expanded="false"
              type="radio"
              (change)="updateDpoaConsentRadioValue(false)"
              id="dpoa-no"
              name="dpoa"
              value="no"
            />
            <label class="nhsuk-label nhsuk-radios__label" for="dpoa-no">
              Provide a proof of address document
            </label>
            <div class="nhsuk-hint nhsuk-radios__hint" id="dpoa-no-hint">
              You will need to capture an image of this document
            </div>
          </div>
        </div>
      </fieldset>
    </div>

    <button
      (click)="onSubmitButtonClicked()"
      type="submit"
      class="nhsuk-button"
      data-cy="continue-button"
    >
      Continue
    </button>

    <p>
      <a href="/invitee/transunion"
        >Get more information about TransUnion and how your data is used</a
      >
    </p>
  </div>
</div>
