<div class="nhsuk-grid-row">
  <div *ngIf="invitationConfirmation" class="nhsuk-grid-column-two-thirds">
    <breadcrumb-link
      [breadcrumbRoute]="breadcrumbRoute"
      [breadcrumbText]="breadcrumbText"
    ></breadcrumb-link>
    <fieldset class="nhsuk-fieldset">
      <legend class="nhsuk-fieldset__legend nhsuk-fieldset__legend--xl">
        <h1
          autofocus
          tabindex="-1"
          class="nhsuk-fieldset__heading nhsuk-u-padding-bottom-3"
        >
          {{ headerText }}
        </h1>
      </legend>

      <div class="nhsuk-form-group">
        <label class="nhsuk-label" for="building-name">
          Building number or name
          <span class="nhsuk-u-visually-hidden">line 1 of 2</span>
        </label>
        <div class="nhsuk-hint" id="building-name-hint-text">
          For example, '56' or 'Flat 9 Millgate House'
        </div>
        <text-input
          #buildingNameInput
          [id]="'building-name'"
          [errorMessage]="buildingNameErrorMessage"
          [uppercase]="false"
          size="nhsuk-u-width-one-third"
          cypressValue="address-building-name"
          (questionAnswer)="updateBuildingNameAnswer($event)"
        >
        </text-input>
      </div>

      <div class="nhsuk-form-group">
        <label class="nhsuk-label" for="address-line-1">
          Street
          <span class="nhsuk-u-visually-hidden">line 2 of 2</span>
        </label>
        <div class="nhsuk-hint" id="address-line-1-hint-text">
          For example, Berwick Avenue
        </div>
        <text-input
          #addressLineOneInput
          [id]="'address-line-1'"
          [errorMessage]="addressLineOneErrorMessage"
          [uppercase]="false"
          cypressValue="address-line-1"
          (questionAnswer)="updateAddressLineOneAnswer($event)"
        >
        </text-input>
      </div>

      <div class="nhsuk-form-group">
        <label class="nhsuk-label" for="address-town"> Town or city </label>
        <text-input
          #townCityInput
          [id]="'address-town'"
          [errorMessage]="townCityErrorMessage"
          [uppercase]="false"
          (questionAnswer)="updateTownCityAnswer($event)"
          cypressValue="address-town"
          size="nhsuk-u-width-two-thirds"
        ></text-input>
      </div>
      <div class="nhsuk-form-group">
        <label class="nhsuk-label" for="address-postcode"> Postcode </label>
        <text-input
          #postcodeInput
          [id]="'address-postcode'"
          [errorMessage]="postcodeErrorMessage"
          [uppercase]="false"
          (questionAnswer)="updatePostcodeAnswer($event)"
          cypressValue="address-postcode"
          size="nhsuk-input--width-10"
        ></text-input>
      </div>
      <div class="nhsuk-u-padding-bottom-3"></div>
      <button
        class="nhsuk-button nhsuk-u-margin-bottom-5"
        (click)="submitAnswer()"
        id="continue"
        data-cy="continue-button"
      >
        Continue
      </button>
    </fieldset>
  </div>
</div>
