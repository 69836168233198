<session-check></session-check>

<div *ngIf="fileErrors" class="nhsuk-grid-row">
  <div class="nhsuk-grid-column-two-thirds">
    <error-summary
      [errorSummaryMessages]="summaryErrorMessages"
    ></error-summary>

    <h1 autofocus tabindex="-1">0 applications sent</h1>

    <p class="nhsuk-body-l">Applications could not be sent because:</p>

    <ul>
      <li>your file contains errors</li>
    </ul>

    <table
      role="table"
      class="nhsuk-table-responsive nhsuk-u-margin-bottom-5 nhsuk-u-margin-top-8"
      id="error-table"
    >
      <caption class="nhsuk-table__caption">
        Errors
      </caption>
      <thead role="rowgroup" class="nhsuk-table__head">
        <tr role="row">
          <th role="columnheader" class="" scope="col">Column</th>
          <th role="columnheader" class="" scope="col">Row</th>
          <th role="columnheader" class="" scope="col">Error</th>
        </tr>
      </thead>
      <tbody class="nhsuk-table__body">
        <tr
          *ngFor="let fileError of fileErrors"
          role="row"
          class="nhsuk-table__row"
        >
          <td role="cell" class="nhsuk-table__cell">
            <span class="nhsuk-table-responsive__heading">Column </span
            >{{ fileError.column }}
          </td>
          <td role="cell" class="nhsuk-table__cell">
            <span class="nhsuk-table-responsive__heading">Row</span
            >{{ fileError.row }}
          </td>
          <td role="cell" class="nhsuk-table__cell">
            <span class="nhsuk-table-responsive__heading">Error </span
            >{{ fileError.error }}
          </td>
        </tr>
      </tbody>
    </table>

    <h2 class="nhsuk-u-margin-top-6">How to resubmit your applications</h2>
    <ol>
      <li>Make sure your file uses the format in the template</li>
      <li>Make sure you complete all required fields</li>
      <li>
        <a [routerLink]="['/invitation/upload-file']">Upload your file</a> and
        submit it again
      </li>
    </ol>

    <div class="nhsuk-inset-text">
      <span class="nhsuk-u-visually-hidden">Information: </span>
      <p>
        Do not include applications that have been successfully sent or which
        are already in progress when you upload your file.
      </p>
    </div>
  </div>
</div>
