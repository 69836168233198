<h1 autofocus tabindex="-1">Logging in</h1>

<div *ngIf="!stateError">
  <progress-indicator spinnerText="Logging in"></progress-indicator>
</div>

<div *ngIf="stateError">
  <div
    class="nhsuk-error-summary"
    aria-labelledby="error-summary-title"
    role="alert"
    tabindex="-1"
  >
    <h2 class="nhsuk-error-summary__title" id="error-summary-title">
      Sorry, there is a problem with the service
    </h2>
    <div class="nhsuk-error-summary__body">Please try again later.</div>
  </div>
  <br />
</div>
