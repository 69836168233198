import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { EnvironmentConfigService } from "./environment-config.service";

import { SessionAuthenticationService } from "./session-authentication.service";
import { Portal } from "src/app/models/session-token";

@Injectable()
export class ServiceManagerService {
  constructor(
    private http: HttpClient,
    private environmentConfigService: EnvironmentConfigService,
    private sessionAuthenticationService: SessionAuthenticationService,
  ) {}

  viewPayload(invitationId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(
          `${
            this.environmentConfigService.getConfig().idBridgeApiUrl
          }/serviceManager/viewPayload?invitation_id=${invitationId}`,
          {
            headers: this.sessionAuthenticationService.applyAuthorizationHeader(
              Portal.Checker,
            ),
            observe: "response",
          },
        )
        .subscribe(
          (res) => {
            resolve(res);
          },
          (error) => {
            reject(error);
          },
        );
    });
  }

  deleteInvitation(
    invitationId: string,
    deleteReason: number,
    deleteMessage: string,
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post(
          `${
            this.environmentConfigService.getConfig().idBridgeApiUrl
          }/serviceManager/deleteInvitation`,
          {
            invitation_id: invitationId,
            delete_reason: deleteReason,
            delete_message: deleteMessage,
          },
          {
            headers: this.sessionAuthenticationService.applyAuthorizationHeader(
              Portal.Checker,
            ),
            observe: "response",
          },
        )
        .subscribe(
          (res) => {
            resolve(res);
          },
          (error) => {
            reject(error);
          },
        );
    });
  }

  ems2CaseApprovalRetry(invitationId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post(
          `${
            this.environmentConfigService.getConfig().idBridgeApiUrl
          }/serviceManager/ems2CaseApprovalRetry`,
          { invitation_id: invitationId },
          {
            headers: this.sessionAuthenticationService.applyAuthorizationHeader(
              Portal.Checker,
            ),
            observe: "response",
          },
        )
        .subscribe(
          (res) => {
            resolve(res);
          },
          (error) => {
            reject(error);
          },
        );
    });
  }

  smsDownloadLinkResend(invitationId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post(
          `${
            this.environmentConfigService.getConfig().idBridgeApiUrl
          }/serviceManager/smsDownloadLinkResend`,
          { invitation_id: invitationId },
          {
            headers: this.sessionAuthenticationService.applyAuthorizationHeader(
              Portal.Checker,
            ),
            observe: "response",
          },
        )
        .subscribe(
          (res) => {
            resolve(res);
          },
          (error) => {
            reject(error);
          },
        );
    });
  }

  ems2CaseEventChange(invitationId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post(
          `${
            this.environmentConfigService.getConfig().idBridgeApiUrl
          }/serviceManager/ems2CaseEventChange`,
          { invitation_id: invitationId },
          {
            headers: this.sessionAuthenticationService.applyAuthorizationHeader(
              Portal.Checker,
            ),
            observe: "response",
          },
        )
        .subscribe(
          (res) => {
            resolve(res);
          },
          (error) => {
            reject(error);
          },
        );
    });
  }
}
