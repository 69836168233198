<div class="nhsuk-grid-row">
  <div class="nhsuk-grid-column-two-thirds">
    <breadcrumb-link
      *ngIf="breadcrumbRoute"
      [breadcrumbRoute]="breadcrumbRoute"
      [breadcrumbText]="breadcrumbText"
    ></breadcrumb-link>

    <h1 autofocus tabindex="-1" data-cy="h1text">
      {{ h1text }}
    </h1>

    <div *ngIf="reason == 'application-invalid'">
      <p data-cy="message-link-invalid">
        Check you have typed or copied and pasted the full link into your
        browser.
      </p>
      <p>
        Contact
        <a href="mailto:{{ supportEmail }}">{{ supportEmail }}</a>
        to continue. Include your application ID in all emails.
      </p>
    </div>

    <div *ngIf="reason == 'application-expired'">
      <p data-cy="message-expired">
        The link to the application was not used within 4 days and has expired.
      </p>
      <p>An email with a new link will be sent to you.</p>
      <p>
        Contact
        <a href="mailto:{{ supportEmail }}">{{ supportEmail }}</a>
        to continue. Include your application ID in all emails.
      </p>
    </div>

    <div *ngIf="reason == 'application-app-link-expired'">
      <p data-cy="message-app-link-expired">
        The link to download the NHS Verify Care ID app was not used within 15
        days and has expired.
      </p>
      <p>You will need to request a new link to restart your application.</p>
      <p>
        Contact
        <a href="mailto:{{ supportEmail }}">{{ supportEmail }}</a>
        to continue. Include your application ID in all emails.
      </p>
    </div>

    <div *ngIf="reason == 'already-submitted'">
      <p data-cy="message-already-submitted">
        You have completed the first part of this process and now need to upload
        your ID documents.
      </p>
      <p>
        Download the Verify Care ID app onto your mobile phone and use the app
        to upload your documents.
      </p>
      <p>
        Use the link that has been sent to you by text message to download the
        Care ID app and continue with your application.
      </p>
      <p>
        If you have not received the link email
        <a href="mailto:{{ supportEmail }}">{{ supportEmail }}</a> with the
        subject line "Verify Care ID app link not received".
      </p>
    </div>

    <div *ngIf="reason == 'dup-failed'">
      <p data-cy="message-dup-failed">
        There is a record in our system that matches the details you have
        submitted.
      </p>

      <email-support
        invitationId="{{ invitationId }}"
        supportEmail="{{ supportEmail }}"
        subjectLine="duplicate profile found"
        nextStep="update your profile."
        description="A Care ID profile that matches my information has been found. I am asking for support adding the permissions and access I need to this profile."
      ></email-support>

      <p>
        The team managing your application will respond with instructions on how
        to get the permissions and access you need added to your existing
        profile.
      </p>
    </div>

    <div *ngIf="reason == 'application-id-review'">
      <p data-cy="message-id-review">
        You will be contacted about the outcome of your application within a few
        working days.
      </p>
      <p>
        If you need further support contact
        <a href="mailto:{{ supportEmail }}">{{ supportEmail }}</a>
      </p>
    </div>

    <div *ngIf="reason == 'application-approved'">
      <p data-cy="message-approved">
        Your NHS Care ID application has been approved and your profile has been
        created.
      </p>
      <p>
        You will be contacted shortly by your organisation to add the
        permissions required for your role to your profile.
      </p>
      <p>
        You will also be issued with an authenticator, which may take the form
        of a smartcard, mobile app or similar device.
      </p>
      <p>
        <strong>You do not need to take further action at this time.</strong>
      </p>
      <p>
        If you need further support contact
        <a href="mailto:{{ supportEmail }}">{{ supportEmail }}</a>
      </p>
    </div>

    <div *ngIf="reason == 'application-rejected'">
      <p data-cy="message-rejected">
        Your NHS Care ID application could not be verified using the online
        service.
      </p>
      <p>
        You must email
        <a href="mailto:{{ supportEmail }}">{{ supportEmail }}</a> to discuss
        alternative ways to verify your identity.
      </p>
    </div>

    <div *ngIf="reason == 'application-cancelled'">
      <p data-cy="message-cancelled">
        Your NHS Care ID application has been cancelled and you will not be able
        to continue using the online service.
      </p>
      <p>
        Contact <a href="mailto:{{ supportEmail }}">{{ supportEmail }}</a> if
        you need to continue with your application. Include your application ID
        in all emails.
      </p>
    </div>

    <div *ngIf="reason == 'no-ni'">
      <email-support
        invitationId="{{ invitationId }}"
        supportEmail="{{ supportEmail }}"
        subjectLine="No National Insurance Number"
        description="I cannot complete my online application because I do not have a National Insurance Number. I am asking for support to continue my application."
      ></email-support>

      <p>
        The team managing your application will respond with instructions on how
        to continue your application.
      </p>
    </div>

    <div *ngIf="reason == 'no-poa'">
      <email-support
        invitationId="{{ invitationId }}"
        supportEmail="{{ supportEmail }}"
        subjectLine="no proof of address"
        description="I cannot complete my online application because I do not have an accepted proof of address document. I am asking for support to continue my application."
      ></email-support>

      <p>
        The team managing your application will respond with instructions on how
        to continue your application.
      </p>
    </div>

    <div *ngIf="reason == 'no-photo-id'">
      <email-support
        invitationId="{{ invitationId }}"
        supportEmail="{{ supportEmail }}"
        subjectLine="no photo ID"
        description=" I cannot complete my online application because I do not have an accepted photo ID. I am asking for support to continue my application."
      ></email-support>

      <p>
        The team managing your application will respond with instructions on how
        to continue your application.
      </p>
    </div>

    <div *ngIf="reason == 'applicant-maintenance'">
      <p>
        This service is currently unavailable. If you are having issues
        accessing our systems, try again later.
      </p>
      <p>We are working to complete maintenance as soon as possible.</p>

      <p>We apologise for any inconvenience caused.</p>
    </div>

    <div *ngIf="reason == 'no-mobile'">
      <email-support
        invitationId="{{ invitationId }}"
        supportEmail="{{ supportEmail }}"
        subjectLine="cannot use a mobile phone"
        description="I cannot use a mobile phone to take photos or scan
        my face. I am asking for support to continue my application"
      ></email-support>

      <p>
        The team managing your application will respond with instructions on how
        to continue your application.
      </p>
    </div>

    <div *ngIf="reason == 'names-not-match'">
      <p>
        If your first name or last name do not match your photo IDs, we may not
        be able to verify your identity using the online service.
      </p>

      <email-support
        invitationId="{{ invitationId }}"
        supportEmail="{{ supportEmail }}"
        subjectLine="my name does not match photo IDs"
        description="I cannot complete my online application because my
        name does not match my photo IDs. I am asking for support to continue
        my application."
      ></email-support>

      <p>
        The person managing your application will respond with instructions on
        how to continue your application.
      </p>

      <ul>
        <li>You may need to attend a face to face meeting</li>
        <li>
          You may be asked to provide other evidence of your name change, like a
          marriage certificate, a deed poll or a gender recognition certificate
        </li>
      </ul>

      <p>
        For more information about how your data is used,
        <a
          rel="noopener"
          href="https://digital.nhs.uk/services/care-identity-service/registration-authority-users/registration-authority-help/privacy-notice"
          >view our terms of use and privacy policy</a
        >.
      </p>
    </div>

    <br />
    <div *ngIf="displayFeedbackLink">
      <feedback-link queryString="{{ this.queryString }}"></feedback-link>
    </div>
  </div>
</div>
