import { Component, OnInit, Inject } from "@angular/core";
import { SESSION_STORAGE, StorageService } from "ngx-webstorage-service";
import { RouterService } from "src/app/services/router.service";
import { InvitationConfirmation } from "./../../../../store/states/invitee-spa.state";
import { Title } from "@angular/platform-browser";
import { PoaValidateService } from "src/app/services/poa-validate.service";
import { combineLatest, timer, Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { scrollToTop } from "src/app/utils/common/common";
import { InviteeDetails } from "src/app/utils/invitee-details";
import { PoaTypePipe } from "src/app/utils/pipes/poa-type/poa-type.pipe";

@Component({
  selector: "app-save-selection",
  templateUrl: "./save-selection.component.html",
})
export class SaveSelectionComponent implements OnInit {
  submitButtonClicked = false;
  invitationConfirmationSubscription: Subscription;
  invitationConfirmation: InvitationConfirmation;
  invitationId: string;
  addressValid: boolean;
  deliveryAddressValid: boolean;
  displayDeliveryAddress: boolean = false;
  dpoaRouteTaken = "";
  idCheckDocuments = "";
  changeLinkIdVerificationRoute = "";
  changeLinkDeliveryAddressRoute = "";
  errorMessage = "";
  docsRequired = "";

  confirmationStatus: string;

  constructor(
    @Inject(SESSION_STORAGE) private sessionStorage: StorageService,
    private routerService: RouterService,
    private title: Title,
    private poaValidateService: PoaValidateService,
    private inviteeDetails: InviteeDetails,
    private poaPipe: PoaTypePipe,
  ) {}

  async ngOnInit(): Promise<void> {
    this.title.setTitle("Check your answers");
    this.invitationId = this.sessionStorage.get("invitationId");
    this.displayDeliveryAddress = this.sessionStorage.get(
      "captureDeliveryAddress",
    );
    if (this.invitationId == undefined || this.invitationId == "") {
      this.routerService.redirectToInvalidRequest();
    }
    this.invitationConfirmation = this.inviteeDetails.getInviteeDetails();
    this.setHeadingAndDocuments();
    this.setDocsRequired();
  }

  setDpoaRouteTaken(): void {
    if (
      this.invitationConfirmation.poaType == "" &&
      this.invitationConfirmation.tuAddress != ""
    ) {
      this.dpoaRouteTaken = "DpoaUsedAndPassed";
    } else if (
      this.invitationConfirmation.poaType != "" &&
      this.invitationConfirmation.tuAddress != ""
    ) {
      this.dpoaRouteTaken = "DpoaUsedAndFailed";
    } else {
      this.dpoaRouteTaken = "DpoaNotUsed";
    }
  }

  setHeadingAndDocuments(): void {
    this.setDpoaRouteTaken();
    if (this.dpoaRouteTaken == "DpoaUsedAndPassed") {
      this.changeLinkIdVerificationRoute = "/invitee/passport";
      this.idCheckDocuments = this.invitationConfirmation.photoOneId;
      this.changeLinkDeliveryAddressRoute = "/invitee/delivery";
    } else {
      this.changeLinkIdVerificationRoute = "/invitee/poa";
      this.idCheckDocuments = this.invitationConfirmation.poaType;
      this.changeLinkDeliveryAddressRoute =
        "/invitee/confirm-postcode/delivery";
    }
  }

  setDocsRequired(): void {
    let docsReqString = "your ";

    if (this.invitationConfirmation.photoOneId != "") {
      docsReqString +=
        this.poaPipe.transform(this.invitationConfirmation.photoOneId) + ", ";
    }

    if (this.invitationConfirmation.photoTwoId != "") {
      docsReqString +=
        this.poaPipe.transform(this.invitationConfirmation.photoTwoId) + ", ";
    }

    if (this.invitationConfirmation.poaType != "") {
      docsReqString += this.poaPipe.transform(
        this.invitationConfirmation.poaType,
      );
    }

    if (docsReqString.endsWith(", ")) {
      docsReqString = docsReqString.slice(0, -2);
    }

    this.docsRequired = docsReqString
      .toLowerCase()
      .replace(" hm ", " HM ")
      .replace("pass ", "PASS ");
  }

  onSubmitButtonClicked(): void {
    if (!this.isFieldsValid()) {
      this.routerService.redirectToInvalidRequest();
      return;
    }

    this.submitButtonClicked = true;

    combineLatest([
      timer(1000),
      this.poaValidateService.postPoaValidate(
        this.invitationId,
        this.invitationConfirmation.title,
        this.invitationConfirmation.firstName,
        this.invitationConfirmation.middleNames,
        this.invitationConfirmation.familyName,
        this.invitationConfirmation.dob,
        this.invitationConfirmation.tuAddress,
        this.invitationConfirmation.deliveryAddress,
        this.invitationConfirmation.preferredName,
        this.invitationConfirmation.poaType,
        this.invitationConfirmation.photoOneId,
        this.invitationConfirmation.photoTwoId,
        this.invitationConfirmation.profilePhotoBase64,
        this.invitationConfirmation.profilePhotoType,
      ),
    ])
      .pipe(map((x) => x[1]))
      .subscribe({
        next: () => {
          this.sessionStorage.set("docsRequired", this.docsRequired);
          this.inviteeDetails.delete();
          this.routerService.redirectToDownloadApp();
        },
        error: (err) => {
          if (err.status === 404) {
            if (err.error == "already_submitted") {
              this.routerService.redirectToAlreadySubmitted();
            } else if (err.error == "not_found") {
              this.errorMessage = "Invalid application reference";
            } else {
              this.routerService.redirectToInvalidRequest();
            }
          } else if (err.status === 422 || err.status == 400) {
            this.routerService.redirectToInvalidRequest();
          } else if (err.status === 500) {
            this.routerService.redirectToInternalServerError();
          }
          this.submitButtonClicked = false;
          scrollToTop();
        },
        complete: () => {
          this.submitButtonClicked = false;
        },
      });
  }

  isFieldsValid(): boolean {
    if (
      this.invitationId != "" &&
      this.invitationConfirmation.title != "" &&
      this.invitationConfirmation.firstName != "" &&
      this.invitationConfirmation.familyName != "" &&
      this.invitationConfirmation.dob != "" &&
      this.invitationConfirmation.photoOneId != ""
    )
      return true;
    else {
      return false;
    }
  }
}
