<p *ngIf="showInstructions" class="small">
  To zoom in either double click on the picture, or position your cursor on the
  image, then hold 'Alt' + scroll with your mouse wheel.
  <a href (click)="resetPanzoom($event)">Reset</a>
</p>

<div class="idcheck-image" tabindex="0">
  <button
    *ngIf="showExpandOption"
    class="nhsuk-button nhsuk-button--reverse idcheck-image-expand"
    [ngClass]="{
      'idcheck-image-expand-button': true,
      expanded: this.photoExpand
    }"
    (click)="togglePhotoExpand($event)"
    aria-label="Expand button"
    title="Expand button"
  ></button>

  <button
    *ngIf="showRotate"
    (click)="rotateImage()"
    class="nhsuk-button nhsuk-button--reverse idcheck-image-rotate-button"
    aria-label="Rotate button"
    title="Rotate button"
  ></button>

  <div
    #imageContainer
    [style.height.px]="imageContainerHeight"
    [style.width.px]="imageContainerWidth"
    class="imageContainer"
  >
    <img
      #image
      [ngClass]="{ 'idcheck-image-img': defaultImageStyle }"
      [style.marginLeft.px]="imageMarginLeft"
      src="{{ this.photoUrl }}"
      id="id-image"
      (dblclick)="zoomImage()"
      [style.transform]="transform"
      alt="{{ this.altText }}"
      data-qa="id-image"
    />
  </div>
</div>
