<div
  role="dialog"
  tabindex="-1"
  class="modal"
  role="dialog"
  *ngIf="warningDisplayed"
>
  <div class="nhsuk-card timeout">
    <div class="nhsuk-card__content">
      <h3 class="nhsuk-card__heading">Application modified</h3>

      <div>
        <p class="nhsuk-card__description"></p>
        <p>
          A change was detected to the status of this applicaiton since the page
          was first loaded.
        </p>
        <p>
          Please check to ensure you have the latest record by navigating back
          to the Review Care ID Application page.
        </p>
        <p>New status: {{ displayNewStatus }}</p>
        <p>
          Status updated:
          {{ displayNewStatusLastUpdatedUtc | date: "d MMMM yyyy HH:mm" }}
        </p>
      </div>

      <p>
        <button
          tabindex="1"
          type="submit"
          (click)="viewDashboard()"
          class="nhsuk-button"
        >
          View Dashboard
        </button>
      </p>
      <p>
        <a
          tabindex="2"
          class="nhsuk-link nhsuk-card--clickable"
          (click)="onCloseClicked()"
          >Close</a
        >
      </p>
    </div>
  </div>
</div>
