<a
  *ngIf="cimEnv && uuid; else noCimEnv"
  target="_blank"
  rel="noopener"
  title="View profile in CIM (must be logged into CIM on the same browser)"
  href="{{ cimEnv }}/#/view-profile/{{ uuid }}"
  >{{ uuid }}</a
>

<ng-template #noCimEnv>
  {{ uuid }}
</ng-template>
