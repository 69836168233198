<skip-link></skip-link>
<app-cookie-banner></app-cookie-banner>

<header class="nhsuk-header nhsuk-header--transactional" role="banner">
  <div class="nhsuk-width-container nhsuk-header__container extended-width">
    <div
      (click)="onLogoClick()"
      class="nhsuk-header__logo nhsuk-header__logo--only header-logo"
      role="presentation"
    >
      <svg
        class="nhsuk-logo nhsuk-logo--white"
        xmlns="http://www.w3.org/2000/svg"
        focusable="false"
        viewBox="0 0 40 16"
      >
        <path fill="#fff" d="M0 0h40v16H0z"></path>
        <path
          fill="#005eb8"
          d="M3.9 1.5h4.4l2.6 9h.1l1.8-9h3.3l-2.8 13H9l-2.7-9h-.1l-1.8 9H1.1M17.3 1.5h3.6l-1 4.9h4L25 1.5h3.5l-2.7 13h-3.5l1.1-5.6h-4.1l-1.2 5.6h-3.4M37.7 4.4c-.7-.3-1.6-.6-2.9-.6-1.4 0-2.5.2-2.5 1.3 0 1.8 5.1 1.2 5.1 5.1 0 3.6-3.3 4.5-6.4 4.5-1.3 0-2.9-.3-4-.7l.8-2.7c.7.4 2.1.7 3.2.7s2.8-.2 2.8-1.5c0-2.1-5.1-1.3-5.1-5 0-3.4 2.9-4.4 5.8-4.4 1.6 0 3.1.2 4 .6"
        ></path>
        <image
          src="assets/images/nhs-logo.png"
          width="88"
          height="36"
          alt="NHS Logo"
        ></image>
      </svg>
    </div>
    <div
      *ngIf="displayLogout"
      class="nhsuk-header__content"
      id="content-header"
    >
      <div class="nhsuk-header__menu">
        <button
          class="nhsuk-header__menu-toggle"
          id="toggle-menu"
          aria-controls="header-navigation"
          aria-expanded="false"
          (click)="toggleMenu()"
        >
          <span id="label-navigation">Menu</span>
        </button>
      </div>
    </div>
    <div class="nhsuk-header__transactional-service-name header-service-name">
      {{ title }}
    </div>
  </div>

  <div class="logged-in-user-banner" *ngIf="displayLogout">
    <div class="nhsuk-width-container extended-width">
      <div class="nhsuk-grid-row">
        <div class="nhsuk-grid-column-full">
          <p>
            <span class="logged-in-as"
              ><strong
                ><span id="ra-name">{{ loggedInFullName }}: </span></strong
              >
              <span class="role-name-label">{{ loggedInRoleName }}</span
              >,
              <span class="org-name-label"
                ><span class="logged-in-as-org">{{ loggedInOrgName }}</span> ({{
                  loggedInOrgCode
                }})</span
              ><span class="change-role-divider"> </span>
              <span *ngIf="loggedInRoleCount > 1"> - </span>
              <a
                *ngIf="loggedInRoleCount > 1"
                class="nhsuk-link change-role"
                tabindex="0"
                (click)="onChangeRoleClick()"
                >Change role</a
              >
              <span class="nhsuk-u-visually-hidden"> | </span></span
            ><span class="logout-link">
              <a *ngIf="displayLogout" href="authenticate/user-logged-out">
                Log out
              </a>
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>
</header>

<div class="nhsuk-width-container extended-width" *ngIf="displayLogout">
  <nav
    class="nhsuk-account-navigation"
    id="header-navigation"
    role="navigation"
    aria-label="Primary navigation"
    aria-labelledby="label-navigation"
  >
    <ul class="nhsuk-account-navigation__main">
      <li>
        <a
          data-cy="nav-manage-applications"
          class="nhsuk-account-navigation__link"
          [routerLink]="['/invitation/dashboard']"
          onclick="setTimeout(() => {this.blur()}, 1000)"
        >
          Home
        </a>
      </li>
      <li>
        <a
          data-cy="nav-search-applications"
          class="nhsuk-account-navigation__link"
          [routerLink]="['/invitation/all-invitations']"
          onclick="setTimeout(() => {this.blur()}, 1000)"
        >
          Search applications
        </a>
      </li>
      <li>
        <a
          *ngIf="isServiceManager"
          data-cy="nav-review-applications"
          class="nhsuk-account-navigation__link"
          [routerLink]="['/id-checker/review-list']"
          onclick="setTimeout(() => {this.blur()}, 1000)"
        >
          Review applications
        </a>
      </li>
      <li>
        <a
          *ngIf="isServiceManager"
          data-cy="nav-service-management"
          class="nhsuk-account-navigation__link"
          [routerLink]="['/service-management/portal']"
          onclick="setTimeout(() => {this.blur()}, 1000)"
        >
          Service management
        </a>
      </li>
      <li>
        <a
          class="nhsuk-account-navigation__link"
          target="_blank"
          onclick="setTimeout(() => {this.blur()}, 1000)"
          [routerLink]="['/help']"
          >Help</a
        >
      </li>
      <li>
        <a
          data-cy="nav-settings"
          class="nhsuk-account-navigation__link"
          [routerLink]="['/settings']"
          onclick="setTimeout(() => {this.blur()}, 1000)"
        >
          Settings
        </a>
      </li>
    </ul>
  </nav>
</div>

<div class="nhsuk-width-container extended-width">
  <main class="nhsuk-main-wrapper" id="main-content" tabindex="-1" role="main">
    <router-outlet></router-outlet>
  </main>
</div>
<footer role="contentinfo">
  <div class="nhsuk-footer" id="nhsuk-footer">
    <div class="nhsuk-width-container">
      <ul class="nhsuk-footer__list">
        <li class="nhsuk-footer__list-item">
          <a
            *ngIf="displayLogout"
            target="_blank"
            class="nhsuk-footer__list-item-link"
            href="/help"
            >Help</a
          >
          <a
            *ngIf="!displayLogout"
            target="_blank"
            rel="noopener"
            class="nhsuk-footer__list-item-link"
            href="https://digital.nhs.uk/services/care-identity-service/applications-and-services/apply-for-care-id/support-with-applying-for-my-care-id"
            >Help</a
          >
        </li>
        <li class="nhsuk-footer__list-item">
          <a class="nhsuk-footer__list-item-link" href="/cookies-preference"
            >Cookie policy</a
          >
        </li>
        <li class="nhsuk-footer__list-item">
          <a
            *ngIf="!displayLogout"
            class="nhsuk-footer__list-item-link"
            target="_blank"
            rel="noopener"
            href="{{ getFeedbackLink() }}"
            >Feedback</a
          >
        </li>
      </ul>
      <p class="nhsuk-footer__copyright">&copy; Crown copyright</p>
    </div>
  </div>
</footer>
