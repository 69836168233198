import { Component, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs";
import { Location } from "@angular/common";
import { AfCIDCookieService } from "src/app/services/acfid-cookie.service";

declare let gtag: Function;

@Component({
  selector: "app-cookie-banner",
  templateUrl: "./cookies-banner.component.html",
  styleUrls: ["./cookies-banner.component.css"],
})
export class CookiesBannerComponent implements OnInit {
  displayBanner = true;
  consentPreferenceSet = false;

  constructor(
    private location: Location,
    private router: Router,
    private afcidCookieService: AfCIDCookieService,
  ) {
    if (location.path() == "/cookies-preference") {
      this.displayBanner = false;
    }
  }

  ngOnInit(): void {
    this.getCookieSettings();
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.consentPreferenceSet = false;
      });
  }

  getCookieSettings(): void {
    const cookie = this.afcidCookieService.getCookieConsent();
    if (cookie != undefined) {
      this.displayBanner = false;
      if (this.afcidCookieService.hasGivenConsent(cookie)) {
        this.afcidCookieService.setUpAnalytics();
      } else {
        this.afcidCookieService.removeAnalytics();
      }
    }
  }

  buttonAcceptClick() {
    this.afcidCookieService.setCookieConsent(true);
    this.afcidCookieService.setUpAnalytics();
    this.displayBanner = false;
    this.consentPreferenceSet = true;
  }

  buttonRejectClick() {
    this.afcidCookieService.setCookieConsent(false);
    this.displayBanner = false;
    this.consentPreferenceSet = true;
  }
}
