<session-check></session-check>
<div class="nhsuk-grid-row">
  <div class="nhsuk-grid-column-two-thirds">
    <div
      class="nhsuk-card nhsuk-card--confirmation nhsuk-u-margin-top-0 nhsuk-u-margin-bottom-4 success-alert"
    >
      <div class="nhsuk-card__content">
        <h1
          autofocus
          tabindex="-1"
          class="nhsuk-card__title nhsuk-u-margin-bottom-4"
          data-cy="application-sent-header"
        >
          Application sent
        </h1>
        <p *ngIf="invitationId" class="nhsuk-u-font-size-24">
          Application ID
          <br />
          <strong data-cy="application-id" class="nhsuk-u-font-size-32">{{
            invitationId
          }}</strong>
        </p>
      </div>
    </div>
    <h2 class="nhsuk-u-margin-top-7">What happens next</h2>

    <p>
      The applicant will be sent a link to an online form to enter their
      personal details.
    </p>

    <p>
      Then they will be sent a link to an NHS app called Verify Care ID to
      upload documents and photograph their face to verify their identity.
    </p>

    <p>
      Automated checks will be carried out on the applications and it may also
      be reviewed manually.
    </p>

    <p>
      The applicant will receive an email that explains what they need to do and
      how to use the mobile app.
    </p>

    <p class="nhsuk-u-margin-bottom-5">
      You will be notified when an ID has been checked and is ready to progress
      to the next stage.
    </p>
    <a href="/invitation/basic-info" class="nhsuk-button"> New application </a>
    <p>
      <a
        data-cy="manage-applications"
        href="/invitation/dashboard"
        class="nhsuk-link"
      >
        Or manage applications
      </a>
    </p>
  </div>
</div>
