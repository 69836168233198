<div
  [ngClass]="{
    'nhsuk-form-group--error': this.isDateInvalid
  }"
  class="nhsuk-form-group"
>
  <fieldset
    aria-describedby="dob-hint"
    role="group"
    class="nhsuk-fieldset"
    [id]="id"
  >
    <legend *ngIf="label" class="nhsuk-fieldset__legend nhsuk-label">
      {{ label }}
    </legend>

    <span
      *ngIf="this.isDateInvalid"
      class="nhsuk-error-message"
      id="enter-your-dob-error-message"
    >
      <span class="nhsuk-u-visually-hidden">Error:</span>
      <strong>{{ this.fieldDOBErrorMessage }}</strong>
    </span>
    <div class="nhsuk-hint" id="dob-hint">For example, 15 03 1984</div>

    <div class="nhsuk-date-input">
      <div class="nhsuk-date-input__item">
        <div class="nhsuk-form-group">
          <label class="nhsuk-label nhsuk-date-input__label" for="dob-day"
            >Day</label
          >
          <input
            aria-label="enter dob day"
            #day
            [ngClass]="{ 'nhsuk-input--error': dateErrorHighlight.day }"
            type="text"
            id="dob-day"
            name="dob-day"
            class="nhsuk-input nhsuk-date-input__input nhsuk-input--width-2"
            maxlength="2"
            pattern="[0-9]*"
            inputmode="numeric"
            autocomplete="bday-day"
            [attr.aria-describedby]="
              this.isDateInvalid
                ? 'dob-hint enter-your-dob-error-message'
                : 'dob-hint'
            "
            numbersOnly
            (change)="updateDay($event.target.value)"
          />
        </div>
      </div>
      <div class="nhsuk-date-input__item">
        <div class="nhsuk-form-group">
          <label class="nhsuk-label nhsuk-date-input__label" for="dob-month"
            >Month</label
          >
          <input
            #month
            [ngClass]="{
              'nhsuk-input--error': dateErrorHighlight.month
            }"
            type="text"
            aria-label="enter dob month"
            id="dob-month"
            name="dob-month"
            class="nhsuk-input nhsuk-date-input__input nhsuk-input--width-2"
            maxlength="2"
            pattern="[0-9]*"
            inputmode="numeric"
            autocomplete="bday-month"
            [attr.aria-describedby]="
              this.isDateInvalid
                ? 'dob-hint enter-your-dob-error-message'
                : 'dob-hint'
            "
            numbersOnly
            (change)="updateMonth($event.target.value)"
          />
        </div>
      </div>
      <div class="nhsuk-date-input__item">
        <div class="nhsuk-form-group">
          <label class="nhsuk-label nhsuk-date-input__label" for="dob-year"
            >Year</label
          >
          <input
            #year
            [ngClass]="{ 'nhsuk-input--error': dateErrorHighlight.year }"
            type="text"
            id="dob-year"
            name="dob-year"
            aria-label="enter dob year"
            class="nhsuk-input nhsuk-date-input__input nhsuk-input--width-4"
            maxlength="4"
            pattern="[0-9]*"
            inputmode="numeric"
            autocomplete="bday-year"
            [attr.aria-describedby]="
              this.isDateInvalid
                ? 'dob-hint enter-your-dob-error-message'
                : 'dob-hint'
            "
            numbersOnly
            (change)="updateYear($event.target.value)"
          />
        </div>
      </div>
    </div>
  </fieldset>
</div>
