<div class="nhsuk-grid-row">
  <div class="nhsuk-grid-column-two-thirds">
    <h1 autofocus tabindex="-1">Security code could not be sent</h1>

    <p><a href="/invitee/send-otp">Send a new security code</a></p>

    <details class="nhsuk-details" open="">
      <summary class="nhsuk-details__summary">
        <span class="nhsuk-details__summary-text">
          Mobile number is incorrect
        </span>
      </summary>
      <div class="nhsuk-details__text">
        <p>
          Contact your manager, Registration Authority agent or application
          sponsor to get your mobile number updated.
        </p>

        <p>
          Use the
          <a
            href="https://digital.nhs.uk/services/registration-authorities-and-smartcards/primary-care-service-provider-contact-details"
            >NHS Trust Registration Authority website</a
          >
          to find your Registration Authority manager or agent.
        </p>

        <p>
          Email <a href="mailto:{{ supportEmail }}">{{ supportEmail }}</a> for
          help and support using the website or app to verify your identity.
        </p>
      </div>
    </details>
  </div>
</div>
