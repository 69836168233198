<div class="nhsuk-do-dont-list nhsuk-u-margin-bottom-4">
  <h3 class="nhsuk-do-dont-list__label">Your photo must:</h3>
  <ul class="nhsuk-list nhsuk-list--tick" role="list">
    <li>
      <icon-tick></icon-tick>
      be clear and in focus
    </li>
    <li>
      <icon-tick></icon-tick>
      be taken against a plain, light coloured background
    </li>
    <li>
      <icon-tick></icon-tick>
      show you facing forwards and looking straight at the camera
    </li>
    <li>
      <icon-tick></icon-tick>
      show you with a plain expression and your mouth closed
    </li>
    <li>
      <icon-tick></icon-tick>
      have your head and shoulders visible
    </li>
    <li>
      <icon-tick></icon-tick>
      only include a head covering for religious or medical reasons
    </li>
    <li>
      <icon-tick></icon-tick>
      be suitable for a work setting
    </li>
  </ul>
</div>

<div class="nhsuk-grid-row" *ngIf="displayExamples">
  <div class="nhsuk-grid-column-three-quarters">
    <div class="nhsuk-grid-row">
      <div class="nhsuk-grid-column-one-half nhsuk-u-one-half">
        <figure class="nhsuk-image guideline-photo nhsuk-u-margin-top-2">
          <img
            class="nhsuk-image__img"
            src="assets/images/correct.png"
            alt="a photo of a person's face that meets guidelines. Photo has a plain background and the person's expression is plain"
          />
          <figcaption class="nhsuk-image__caption">
            <ul
              class="nhsuk-list nhsuk-list--tick nhsuk-u-margin-bottom-0 nhsuk-body-s"
            >
              <li>
                <icon-tick></icon-tick>
                photo that meets guidelines
              </li>
            </ul>
          </figcaption>
        </figure>
      </div>
    </div>
  </div>
</div>

<div class="nhsuk-do-dont-list nhsuk-u-margin-bottom-4">
  <h3 class="nhsuk-do-dont-list__label">Your photo must not:</h3>
  <ul class="nhsuk-list nhsuk-list--cross" role="list">
    <li>
      <icon-cross></icon-cross>
      contain other people or objects in the background
    </li>
    <li>
      <icon-cross></icon-cross>
      have hair or other objects obscuring your face
    </li>
    <li>
      <icon-cross></icon-cross>
      have any shadows on your face or behind you
    </li>
    <li>
      <icon-cross></icon-cross>
      be edited by photo filters
    </li>
    <li>
      <icon-cross></icon-cross>
      contain sunglasses or tinted glasses
    </li>
    <li>
      <icon-cross></icon-cross>
      be a photo of a physical document, like a passport
    </li>
  </ul>
</div>

<div class="nhsuk-grid-row" *ngIf="displayExamples">
  <div class="nhsuk-grid-column-three-quarters">
    <div class="nhsuk-grid-column-one-half nhsuk-u-one-half">
      <figure class="nhsuk-image guideline-photo nhsuk-u-margin-top-2">
        <img
          class="nhsuk-image__img"
          src="assets/images/not-work.png"
          alt="a photo of a person's face that does not meet guidelines. Person has sunglasses on and the photo has objects in the background"
        />
        <figcaption class="nhsuk-image__caption">
          <ul
            class="nhsuk-list nhsuk-list--cross nhsuk-u-margin-bottom-0 nhsuk-body-s"
          >
            <li>
              <icon-cross></icon-cross>
              not suitable for work
            </li>
            <li>
              <icon-cross></icon-cross>
              wearing sunglasses
            </li>
            <li>
              <icon-cross></icon-cross>
              objects in the background
            </li>
          </ul>
        </figcaption>
      </figure>
    </div>
    <div class="nhsuk-grid-column-one-half nhsuk-u-one-half">
      <figure class="nhsuk-image guideline-photo nhsuk-u-margin-top-2">
        <img
          class="nhsuk-image__img"
          src="assets/images/id-doc.png"
          alt="photo of a passport"
        />
        <figcaption class="nhsuk-image__caption">
          <ul
            class="nhsuk-list nhsuk-list--cross nhsuk-u-margin-bottom-0 nhsuk-body-s"
          >
            <li>
              <icon-cross></icon-cross>
              photo of a physical document
            </li>
          </ul>
        </figcaption>
      </figure>
    </div>
  </div>
</div>
