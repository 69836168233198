import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { Title } from "@angular/platform-browser";
import { InvitationConfirmation } from "src/store/states/invitee-spa.state";
import { RouterService } from "src/app/services/router.service";
import { InviteeDetails } from "src/app/utils/invitee-details";
import { SESSION_STORAGE, StorageService } from "ngx-webstorage-service";

@Component({
  selector: "app-profile-photo-review",
  templateUrl: "./profile-photo-review.component.html",
})
export class ProfilePhotoReviewComponent implements OnInit {
  breadcrumbRoute = "/invitee/profile-photo-upload";
  breadcrumbText = "Back";
  invitationConfirmation: InvitationConfirmation;
  imageURL: string;
  captureDeliveryAddress: boolean;

  photoMeetsGuidelines: boolean;
  photoMeetsGuidelinesErrorMessage = "";
  photoMeetsGuidelinesResponseValid: boolean;

  constructor(
    private title: Title,
    public fb: FormBuilder,
    private routerService: RouterService,
    private inviteeDetails: InviteeDetails,
    @Inject(SESSION_STORAGE) private sessionStorage: StorageService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.title.setTitle("Review your photo");
    this.captureDeliveryAddress = this.sessionStorage.get(
      "captureDeliveryAddress",
    );
    this.invitationConfirmation = this.inviteeDetails.getInviteeDetails();
    this.imageURL = this.invitationConfirmation.profilePhotoBase64;
  }

  updatePhotoMeetsGuidelinesAnswer(meetGuidelines: boolean): void {
    if (meetGuidelines !== undefined) {
      this.photoMeetsGuidelinesErrorMessage = "";
      this.photoMeetsGuidelines = meetGuidelines;
      this.photoMeetsGuidelinesResponseValid = true;
    } else {
      this.photoMeetsGuidelinesErrorMessage =
        "Select if photo meets the guidelines";
      this.photoMeetsGuidelines = false;
      this.photoMeetsGuidelinesResponseValid = false;
    }
  }

  validOptionForPhotoMeetsGuidelinesSelected() {
    if (this.photoMeetsGuidelinesResponseValid == undefined) {
      this.photoMeetsGuidelinesErrorMessage =
        "Select if photo meets the guidelines";
    }
  }

  async submitAnswer(): Promise<void> {
    this.validOptionForPhotoMeetsGuidelinesSelected();
    if (!this.photoMeetsGuidelinesResponseValid) {
      return;
    }

    if (!this.photoMeetsGuidelines) {
      this.invitationConfirmation.profilePhotoBase64 = "";
      this.invitationConfirmation.profilePhotoType = "";
      this.inviteeDetails.setInviteeDetails(this.invitationConfirmation);
      this.routerService.redirectToProfilePhotoUpload();
    } else {
      this.goToNextPage();
    }
  }

  goToNextPage(): void {
    if (this.captureDeliveryAddress) {
      if (this.invitationConfirmation.tuAddress != "") {
        this.routerService.redirectToDelivery();
      } else {
        this.routerService.redirectToConfirmPostcode("delivery");
      }
    } else {
      this.routerService.redirectToSaveSelection();
    }
  }
}
