import { Component, OnInit, ViewChild, Inject } from "@angular/core";
import { RouterService } from "src/app/services/router.service";
import { TextInputComponent } from "../../common/form-fields/text-inputs/text-input.component";
import { ErrorSummaryMessage } from "../../../models/error-summary-message";
import { InvitationConfirmation } from "./../../../../store/states/invitee-spa.state";
import {
  isTitleValid,
  isNameValid,
} from "../../../utils/data-validation/data-validation";
import { SelectDropdownComponent } from "../../common/form-fields/select-dropdown/select-dropdown.component";
import { Title } from "@angular/platform-browser";
import { SESSION_STORAGE, StorageService } from "ngx-webstorage-service";
import { InviteeDetails } from "src/app/utils/invitee-details";

@Component({
  selector: "app-confirm-name",
  templateUrl: "./confirm-name.component.html",
})
export class ConfirmNameComponent implements OnInit {
  forwardRoute = "/invitee/confirm-preferred-name";
  invitationConfirmation: InvitationConfirmation;
  supportEmail: string;

  titleValid: boolean = false;
  firstNameValid: boolean;
  middleNamesValid: boolean;
  familyNameValid: boolean;

  titleErrorMessage = "";
  firstNameErrorMessage = "";
  middleNamesErrorMessage = "";
  familyNameErrorMessage = "";
  captureDeliveryAddress: boolean;

  @ViewChild("titleInput") titleInput: SelectDropdownComponent;
  @ViewChild("firstNameInput") firstNameInput: TextInputComponent;
  @ViewChild("middleNamesInput") middleNamesInput: TextInputComponent;
  @ViewChild("familyNameInput") familyNameInput: TextInputComponent;

  summaryErrorMessages = [];

  readonly errorMessages = {
    title: {
      unknown: "Please select a title.",
      empty: "Please select a title.",
    },
    firstName: {
      lengthTooShort: "Given name must be at least 2 characters.",
      lengthTooLong: "Given name must be 35 characters or fewer.",
      missingUppercaseCharacter: "Given name must include a capital letter.",
      unexpectedCharacters:
        "Given name must only contain letters, apostrophes and hyphens.",
      doubleSpace: "Your given name must not contain a double space.",
      empty: "Enter your given name.",
      unknown: "Please check you've entered your first name correctly.",
    },
    middleNames: {
      lengthTooShort: "Middle names must be at least 2 characters.",
      lengthTooLong: "Middle names must be 35 characters or fewer.",
      missingUppercaseCharacter: "Middle name must include a capital letter.",
      unexpectedCharacters:
        "Middles name must only contain letters, apostrophes and hyphens.",
      doubleSpace: "Your middle names must not contain a double space.",
      unknown: "Please check you've entered your middle names correctly.",
    },
    familyName: {
      lengthTooShort: "Family name must be at least 2 characters.",
      lengthTooLong: "Family name must be 35 characters or fewer.",
      missingUppercaseCharacter: "Family name must include a capital letter.",
      unexpectedCharacters:
        "Family name must only contain letters, apostrophes and hyphens.",
      doubleSpace: "Family name must not contain a double space.",
      empty: "Enter your family name.",
      unknown: "Please check you've entered your family name correctly.",
    },
  };

  titleAnswers = [
    {
      label: "Mr",
      value: "1",
    },
    {
      label: "Mrs",
      value: "2",
    },
    {
      label: "Ms",
      value: "3",
    },
    {
      label: "Miss",
      value: "4",
    },
    {
      label: "Dr",
      value: "5",
    },
    {
      label: "Nurse",
      value: "6",
    },
    {
      label: "Sister",
      value: "7",
    },
    {
      label: "Brother",
      value: "8",
    },
    {
      label: "Sir",
      value: "9",
    },
    {
      label: "Cannon",
      value: "10",
    },
    {
      label: "Dame",
      value: "11",
    },
    {
      label: "Father",
      value: "12",
    },
    {
      label: "Imam",
      value: "13",
    },
    {
      label: "Lady",
      value: "14",
    },
    {
      label: "Lord",
      value: "15",
    },
    {
      label: "Major",
      value: "16",
    },
    {
      label: "Rabbi",
      value: "17",
    },
    {
      label: "Right Reverend Monsigneur",
      value: "18",
    },
    {
      label: "Reverend",
      value: "19",
    },
    {
      label: "Professor",
      value: "20",
    },
    {
      label: "Baron",
      value: "21",
    },
    {
      label: "Baroness",
      value: "22",
    },
    {
      label: "Mx",
      value: "23",
    },
  ];

  constructor(
    private routerService: RouterService,
    private title: Title,
    @Inject(SESSION_STORAGE) private sessionStorage: StorageService,
    private inviteeDetails: InviteeDetails,
  ) {}

  async ngOnInit(): Promise<void> {
    this.title.setTitle("Enter your full name");
    this.captureDeliveryAddress = this.sessionStorage.get(
      "captureDeliveryAddress",
    );
    this.supportEmail = this.sessionStorage.get("supportEmail");
    this.invitationConfirmation = this.inviteeDetails.getInviteeDetails();
  }

  submitAnswers(): void {
    if (this.areAnswersValid()) {
      this.inviteeDetails.setInviteeDetails(this.invitationConfirmation);
      this.checkApplicantProgress();
      this.routerService.redirectTo(this.forwardRoute);
    }
  }

  areAnswersValid(): boolean {
    this.getLatestAnswers();
    if (
      this.titleValid &&
      this.firstNameValid &&
      this.middleNamesValid &&
      this.familyNameValid
    ) {
      return true;
    } else {
      return false;
    }
  }

  getLatestAnswers(): void {
    this.titleInput.setAnswer();
    this.firstNameInput.setQuestionAnswer();
    this.middleNamesInput.setQuestionAnswer();
    this.familyNameInput.setQuestionAnswer();
  }

  updateTitleAnswer(title: string): void {
    const validationResult = isTitleValid(title);
    if (validationResult.validity) {
      this.titleErrorMessage = "";
      this.titleValid = true;
    } else {
      this.titleErrorMessage =
        this.errorMessages.title[validationResult.errorReason];
      this.titleValid = false;
    }
    this.invitationConfirmation.title = title;
    this.updateErrorSummary();
  }

  updateFirstNameAnswer(firstName: string): void {
    const validationResult = isNameValid(firstName);
    if (validationResult.validity) {
      this.firstNameErrorMessage = "";
      this.firstNameValid = true;
    } else {
      this.firstNameErrorMessage =
        this.errorMessages.firstName[validationResult.errorReason];
      this.firstNameValid = false;
    }
    this.invitationConfirmation.firstName = firstName;
    this.updateErrorSummary();
  }

  updateMiddleNamesAnswer(middleNames: string): void {
    const validationResult = isNameValid(middleNames, false);
    if (validationResult.validity) {
      this.middleNamesErrorMessage = "";
      this.middleNamesValid = true;
    } else {
      this.middleNamesErrorMessage =
        this.errorMessages.middleNames[validationResult.errorReason];
      this.middleNamesValid = false;
    }
    this.invitationConfirmation.middleNames = middleNames;
    this.updateErrorSummary();
  }

  updateFamilyNameAnswer(familyName: string): void {
    const validationResult = isNameValid(familyName);
    if (validationResult.validity) {
      this.familyNameErrorMessage = "";
      this.familyNameValid = true;
    } else {
      this.familyNameErrorMessage =
        this.errorMessages.familyName[validationResult.errorReason];
      this.familyNameValid = false;
    }
    this.invitationConfirmation.familyName = familyName;
    this.updateErrorSummary();
  }

  updateErrorSummary() {
    const updatedErrorSummary = Array<ErrorSummaryMessage>();
    if (this.titleErrorMessage) {
      updatedErrorSummary.push({
        message: this.errorMessages.title.unknown,
        id: "title-select",
      });
    }
    if (this.firstNameErrorMessage) {
      updatedErrorSummary.push({
        message:
          this.firstNameErrorMessage === this.errorMessages.firstName.empty
            ? this.errorMessages.firstName.empty
            : this.errorMessages.firstName.unknown,
        id: "first-name-input",
      });
    }
    if (this.middleNamesErrorMessage) {
      updatedErrorSummary.push({
        message: this.errorMessages.middleNames.unknown,
        id: "middle-names-input",
      });
    }
    if (this.familyNameErrorMessage) {
      updatedErrorSummary.push({
        message:
          this.familyNameErrorMessage === this.errorMessages.familyName.empty
            ? this.errorMessages.familyName.empty
            : this.errorMessages.familyName.unknown,
        id: "family-name-input",
      });
    }
    this.summaryErrorMessages = updatedErrorSummary;
  }

  checkApplicantProgress(): void {
    if (
      this.invitationConfirmation.niNumber !== "" &&
      this.invitationConfirmation.dob !== ""
    ) {
      this.forwardRoute = "/invitee/check-your-answers";
    }
  }
}
