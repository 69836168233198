import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { RouterService } from "src/app/services/router.service";
import { combineLatest, map, timer } from "rxjs";
import { UserSettings } from "src/app/models/userSettings";
import { UserSettingsService } from "src/app/services/user-settings.service";
import { isOtpNumberValid } from "src/app/utils/data-validation/data-validation";
import { TextInputComponent } from "../../common/form-fields/text-inputs/text-input.component";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "app-verify-email",
  templateUrl: "./verify-email.component.html",
  styleUrls: ["./verify-email.component.css"],
})
export class VerifyEmailComponent implements OnInit {
  category: string;

  settings: UserSettings;
  supportEmail: string;
  supportEmailVerified: boolean;
  contactEmail: string;
  contactEmailVerified: boolean;

  email: string;
  emailVerified: boolean;

  otpEntry: string = "";
  otpErrorMessage: string = "";
  otpValue: string = "";
  otpValid: boolean;
  loading: boolean = true;

  emailResent: boolean = false;
  securityCodeLengthMsg = "Enter a security code with 6 numbers";

  @ViewChild("verifyEmail") otpInput: TextInputComponent;

  constructor(
    private route: ActivatedRoute,
    private userSettings: UserSettingsService,
    private routerService: RouterService,
    private title: Title,
  ) {}

  async ngOnInit(): Promise<void> {
    let category = this.route.snapshot.paramMap.get("category");
    this.category = category;
    this.title.setTitle("Verify " + category + " email");
    await this.getSettings();
  }

  updateOTPAnswer(otp: string): void {
    const validationResult = isOtpNumberValid(otp);
    if (validationResult.validity) {
      this.otpErrorMessage = "";
      this.otpValid = true;
    } else {
      this.otpErrorMessage =
        this.errorMessages.otp[validationResult.errorReason];
      this.otpValid = false;
    }
    this.otpValue = otp;
  }

  readonly errorMessages = {
    otp: {
      lengthTooLong: this.securityCodeLengthMsg,
      lengthTooShort: this.securityCodeLengthMsg,
      empty: "Enter your security code",
      unexpectedCharacters: this.securityCodeLengthMsg,
      incorrect: "Enter the correct security code",
      expired:
        "Your security code has expired please send the security code again.",
      unknown: "Enter the correct security code.",
    },
  };

  isAnswerValid(): boolean {
    this.otpInput.setQuestionAnswer();
    return this.otpValid;
  }

  async submitAnswer() {
    if (this.isAnswerValid()) {
      this.loading = true;
      await this.sendOtp();
    }
  }

  async getSettings() {
    combineLatest([timer(1000), this.userSettings.getSettings()])
      .pipe(map((x) => x[1]))
      .subscribe({
        next: (response) => {
          this.settings = this.userSettings.mapUserSettings(response["body"]);
          this.supportEmail = this.settings.supportEmail;
          this.supportEmailVerified = this.settings.supportEmailVerified;
          this.contactEmail = this.settings.contactEmail;
          this.contactEmailVerified = this.settings.contactEmailVerified;
          if (this.category == "support") {
            this.email = this.settings.supportEmail;
            this.emailVerified = this.settings.supportEmailVerified;
          } else {
            this.email = this.settings.contactEmail;
            this.emailVerified = this.settings.contactEmailVerified;
          }
          // We don't want user to access this page if there is no email
          // to verify or if they have already verified it. In this case,
          // redirect to settings page.
          if (this.email == null || this.email == "") {
            this.routerService.redirectToSettings();
          } else {
            if (this.emailVerified) {
              this.routerService.redirectToSettings();
            }
          }
          this.loading = false;
        },
        error: (error) => {
          this.routerService.handleErrorAuthRoutes(error.status);
          this.loading = false;
        },
      });
  }

  async sendOtp() {
    combineLatest([
      timer(1000),
      this.userSettings.checkOTP(this.otpInput.answer, this.category),
    ])
      .pipe(map((x) => x[1]))
      .subscribe({
        next: (response) => {
          if (response.status == 200) {
            if (this.category == "contact") {
              if (this.supportEmailVerified) {
                this.routerService.redirectToSettings();
              } else {
                this.routerService.redirectToUpdateEmailCategory("support");
              }
            } else {
              if (this.contactEmailVerified) {
                this.routerService.redirectToSettings();
              } else {
                this.routerService.redirectToUpdateEmailCategory("contact");
              }
            }
          }
        },
        error: (error) => {
          this.routerService.handleErrorAuthRoutes(error.status);
          if (error.status === 400) {
            this.otpErrorMessage = this.errorMessages.otp.incorrect;
            this.loading = false;
          } else if (error.status == 404) {
            this.otpErrorMessage = this.errorMessages.otp.expired;
          }
        },
      });
  }

  async resendEmail() {
    combineLatest([timer(1000), this.userSettings.resendEmail(this.category)])
      .pipe(map((x) => x[1]))
      .subscribe({
        next: (response) => {
          if (response.status == 200) {
            this.emailResent = true;
          }
        },
        error: (error) => {
          this.routerService.handleErrorAuthRoutes(error.status);
          if (error.status === 400) {
            this.otpErrorMessage = this.errorMessages.otp.incorrect;
            this.loading = false;
          }
        },
      });
  }
}
