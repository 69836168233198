<div class="nhsuk-grid-row">
  <div class="nhsuk-grid-column-two-thirds">
    <h1 autofocus tabindex="-1">Help using Apply for Care ID</h1>

    <h2>Help an applicant</h2>

    <ol>
      <li>
        <a
          target="_blank"
          rel="noopener"
          href="https://digital.nhs.uk/services/care-identity-service/applications-and-services/apply-for-care-id/support-with-applying-for-my-care-id"
          >View the applicant support page for advice with common issues</a
        >
      </li>
      <li>
        If they are experiencing a technical issue which is preventing them from
        completing their application,
        <a
          target="_blank"
          rel="noopener"
          href="https://www.support.digitalservices.nhs.uk/csm"
          >go to the NHS Digital Customer Service Portal</a
        >. Include the application ID and full details of the issue.
        Alternatively, email
        <a
          target="_blank"
          rel="noopener"
          href="mailto:ssd.nationalservicedesk@nhs.net"
          >ssd.nationalservicedesk@nhs.net</a
        >.
      </li>
    </ol>

    <h2>Get help with Apply for Care ID</h2>
    <p>For support using Apply for Care ID to manage applications:</p>

    <ol>
      <li>
        <a
          target="_blank"
          rel="noopener"
          href="https://digital.nhs.uk/services/identity-and-access-management/nhs-care-identity-service-2/apply-for-care-id/get-help-if-youre-already-using-apply-for-care-id"
          >View the guidance on using the service</a
        >
      </li>
      <li>
        If you are experiencing technical issues,
        <a
          target="_blank"
          rel="noopener"
          href="https://www.support.digitalservices.nhs.uk/csm"
          >go to the NHS Digital Customer Service Portal</a
        >. Include full details of the issue. Alternatively, email
        <a
          target="_blank"
          rel="noopener"
          href="mailto:ssd.nationalservicedesk@nhs.net"
          >ssd.nationalservicedesk@nhs.net</a
        >.
      </li>
    </ol>
  </div>
</div>
