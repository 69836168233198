import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { InvitationIdCheck } from "src/store/states/spa.state";
import { IdCheckFailure } from "src/app/models/idCheckFailure";
import { IdCheckFailureReason } from "src/app/models/invitationDetails";
import { RadioButtonAnswer } from "src/app/models/radioButtonAnswer";
import { Store } from "@ngrx/store";
import { UpdateIdCheckerRejectionAction } from "src/store/actions/id-checker-spa.actions";
import { IdCheckerRejectionSPAState } from "src/store/states/id-checker-spa.state";
import { IdCheckRadioErrorType } from "src/app/models/idCheckRadioErrorType";
import { IdCheckRadioButtonContent } from "src/app/models/idCheckRadioButton";
import {
  CHECKBOX_IDENTIFIERS,
  CONTENT_DICT,
} from "src/app/content/idCheckFailureContent";

@Component({
  selector: "id-check-radio-button",
  templateUrl: "./id-check-radio-button.component.html",
  styleUrls: ["./id-check-radio-button.component.scss"],
})
export class IdCheckRadioButtonComponent implements OnInit {
  @Input() question: string;
  @Input() radioError;
  @Input() id: string;
  @Input() answerInput: RadioButtonAnswer;
  @Input() invitation: InvitationIdCheck;
  @Input() failedDocument: string;
  @Input() idCheckFailures: IdCheckFailure[];

  @Output() questionAnswer = new EventEmitter<RadioButtonAnswer>();
  @Output() isQuestionAnswerValid = new EventEmitter<boolean>();

  RadioButtonAnswer = RadioButtonAnswer;
  answer: RadioButtonAnswer;
  isAnswerValid = false;
  focusOnYes: boolean;
  focusOnNo: boolean;

  idCheckFailure: IdCheckFailure;

  // Object.keys / Object.values are two ways to cycle through an
  // enum's values automatically in JS. Unfortunately, because of how
  // Typescript implements reverse enum mappings, the string and numeric
  // representations are considered separate entries by this method.
  // Hence, the weird filter business.
  MAPPED_CHECKBOXES: Map<IdCheckFailureReason, boolean> = new Map(
    Object.values(IdCheckFailureReason)
      .filter(isNaN)
      .map((reason: string) => [IdCheckFailureReason[`${reason}`], null]),
  );

  constructor(private store: Store<IdCheckerRejectionSPAState>) {}

  ngOnInit(): void {
    this.answer = this.answerInput;
  }

  areRadioUnselected(): boolean {
    return this.radioError?.type === IdCheckRadioErrorType["Not answered"];
  }

  areReasonsUnselected(): boolean {
    return (
      this.radioError?.type === IdCheckRadioErrorType["No reason selected"]
    );
  }

  getContent(id: string) {
    return CONTENT_DICT.get(id);
  }

  getCheckboxId(reason: IdCheckFailureReason): string {
    return CHECKBOX_IDENTIFIERS.get(reason);
  }

  getReasonText(reason: IdCheckFailureReason): string {
    return IdCheckFailureReason[reason.valueOf()];
  }

  updateCheckbox(reason: IdCheckFailureReason): void {
    this.MAPPED_CHECKBOXES.set(reason, !this.MAPPED_CHECKBOXES.get(reason));
    this.setRejectionReasons();
  }

  updateValue(value: RadioButtonAnswer, id: string): void {
    this.answer = value;
    this.setAnswers();
    if (value == RadioButtonAnswer.Yes) {
      this.clearRejectionReasons(id);
      this.resetSections();
    }
  }

  resetSections(): void {
    this.MAPPED_CHECKBOXES.forEach((_, key, map) => map.set(key, false));
  }

  setAnswers(): void {
    this.setQuestionAnswer();
    this.setIsQuestionAnswerValid();
  }

  setQuestionAnswer(): void {
    this.questionAnswer.emit(this.answer);
  }

  setIsQuestionAnswerValid(): void {
    this.isQuestionAnswerValid.emit(this.isAnswerValid);
  }

  setRejectionReasons(): void {
    this.setReasonsForInvitation();
    let failureDocumentExists = this.idCheckFailures.find(
      (x) => x.document == this.idCheckFailure.document,
    );
    let existingIndex = this.idCheckFailures.indexOf(failureDocumentExists, 0);
    if (existingIndex > -1) {
      this.idCheckFailures.splice(existingIndex, 1);
    }
    this.idCheckFailures.push(this.idCheckFailure);
    this.store.dispatch(
      new UpdateIdCheckerRejectionAction(this.idCheckFailures),
    );
  }

  clearRejectionReasons(id: string): void {
    let failureDocumentExists = this.idCheckFailures.find(
      (x) => x.document == id,
    );
    let existingIndex = this.idCheckFailures.indexOf(failureDocumentExists, 0);
    if (existingIndex > -1) {
      this.idCheckFailures.splice(existingIndex, 1);
      this.store.dispatch(
        new UpdateIdCheckerRejectionAction(this.idCheckFailures),
      );
    }
  }

  setFocusOnYes(value: boolean): void {
    this.focusOnYes = value;
  }

  setFocusOnNo(value: boolean): void {
    this.focusOnNo = value;
  }

  onBlur(): void {
    this.setFocusOnNo(false);
    this.setFocusOnYes(false);
  }

  setReasonsForInvitation() {
    this.idCheckFailure = {
      document: this.failedDocument,
      reasons: [],
    } as IdCheckFailure;
    this.MAPPED_CHECKBOXES.forEach((checkbox, reason) => {
      if (checkbox) {
        this.idCheckFailure.reasons.push(reason);
      }
    });
  }
}
