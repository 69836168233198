import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { RouterService } from "../../../services/router.service";
import { ErrorSummaryMessage } from "../../../models/error-summary-message";
import { TextInputComponent } from "../../common/form-fields/text-inputs/text-input.component";
import { timer, combineLatest, firstValueFrom } from "rxjs";
import {
  IdCheckHeldReason,
  IdCheckImage,
  InvitationStatus,
} from "src/app/models/invitationDetails";
import { InvitationIdCheck } from "src/store/states/spa.state";
import { StorageMap } from "@ngx-pwa/local-storage";
import { IdCheckerService } from "src/app/services/id-checker.service";
import { map } from "rxjs/operators";
import { Location } from "@angular/common";
import {
  isUKDrivingLicenseNumberValid,
  isNonUkDocumentNumberValid,
  isNotesValid,
  isPhotoIdNumberValid,
  isUkPassportNumberValid,
} from "src/app/utils/data-validation/data-validation";
import { GrantUserService } from "src/app/services/grant-user.service";
import { DuplicateCheckService } from "src/app/services/duplicate-check.service";
import { IdCheckerDateComponent } from "../../common/id-checker-date/id-checker-date.component";
import {
  DataValidationReasons,
  DataValidationResult,
} from "src/app/models/data-validation-model";
import { ModifiedRecordCheckComponent } from "../../common/modified-record-check/modified-record-check.component";
import { PoaRouteTaken } from "src/app/models/dpoaCheck";
import { SESSION_STORAGE, StorageService } from "ngx-webstorage-service";
import { SendInvitationService } from "src/app/services/send-invitation.service";
import { CheckboxComponent } from "../../common/form-fields/checkbox/checkbox.component";
import {
  ConfirmDetailsEvidence,
  ConfirmDetailsEvidenceFieldType,
} from "src/app/models/confirmDetailsEvidence";

declare type ConfirmDetailsEvidenceType =
  | "PhotoIdOne"
  | "PhotoIdTwo"
  | "ProofOfAddress";

@Component({
  selector: "app-confirm-details",
  templateUrl: "./confirm-details.component.html",
  styleUrls: ["./confirm-details.component.css"],
})
export class ConfirmDetailsComponent implements OnInit {
  invitationId: string;
  invitation: InvitationIdCheck;
  grantUserError = false;
  validationErrorMessage = "";
  poaRouteTaken: PoaRouteTaken;

  finalDuplicateCheckPossibleDuplicate: boolean;
  finalDuplicateCheckInProgress = false;

  photoIdOneImgFront: string;
  photoIdOneImgBack: string;
  photoIdTwoImgFront: string;
  photoIdTwoImgBack: string;
  poaImg: string;

  approvalNoteErrorMessage = "";
  approvalNoteValid: boolean = true;
  approvalNote = "";

  isSubmitted = false;

  images: IdCheckImage[];
  summaryErrorMessages = Array<ErrorSummaryMessage>();

  @ViewChild("photoIdOneNumberInput")
  photoIdOneNumberInput: TextInputComponent;
  @ViewChild("photoIdOneIssueDateInput")
  photoIdOneIssueDateInput: IdCheckerDateComponent;

  @ViewChild("photoIdTwoNumberInput")
  photoIdTwoNumberInput: TextInputComponent;
  @ViewChild("photoIdTwoIssueDateInput")
  photoIdTwoIssueDateInput: IdCheckerDateComponent;

  @ViewChild("poaNumberInput")
  poaNumberInput: TextInputComponent;
  @ViewChild("poaIssueDateInput")
  poaIssueDateInput: IdCheckerDateComponent;

  @ViewChild("acknowledgedNotDuplicateInput")
  acknowledgedNotDuplicateInput: CheckboxComponent;

  @ViewChild("modifiedRecordCheck")
  modifiedRecordCheck: ModifiedRecordCheckComponent;

  constructor(
    private location: Location,
    private storage: StorageMap,
    private title: Title,
    private route: ActivatedRoute,
    private idCheckerService: IdCheckerService,
    private grantUserService: GrantUserService,
    private duplicateCheckService: DuplicateCheckService,
    private routerService: RouterService,
    private sendInvitationService: SendInvitationService,
    @Inject(SESSION_STORAGE) private sessionStorage: StorageService,
  ) {}

  ngOnInit(): void {
    this.title.setTitle("Confirm ID details");
    this.invitationId = this.route.snapshot.paramMap.get("invitationId");
    this.getInvitationState();
    this.getImages();
  }

  photoIdOneFrontExpand = false;
  photoIdOneBackExpand = false;
  photoIdTwoFrontExpand = false;
  photoIdTwoBackExpand = false;
  poaExpand = false;

  getImages(): void {
    combineLatest([
      timer(1000),
      this.idCheckerService.getIdCheckerImages(this.invitationId),
    ])
      .pipe(map((x) => x[1]))
      .subscribe({
        next: (response) => {
          this.images = response["body"];
          this.mapImages();
        },
        error: (err) => {
          this.routerService.handleErrorAuthRoutes(err.status);
        },
      });
  }

  mapImages(): void {
    try {
      this.photoIdOneImgFront = this.images.find(
        (element) => element.name === "photo_id_one_front",
      ).data;
    } catch {
      console.log("No Photo ID One Front");
    }
    try {
      this.photoIdOneImgBack = this.images.find(
        (element) => element.name === "photo_id_one_back",
      ).data;
    } catch {
      console.log("No Photo ID One Back");
    }
    try {
      this.photoIdTwoImgFront = this.images.find(
        (element) => element.name === "photo_id_two_front",
      ).data;
    } catch {
      console.log("No Photo ID Two Front");
    }
    try {
      this.photoIdTwoImgBack = this.images.find(
        (element) => element.name === "photo_id_two_back",
      ).data;
    } catch {
      console.log("No Photo ID Two Back");
    }
    try {
      this.poaImg = this.images.find(
        (element) => element.name === "secondary",
      ).data;
    } catch {
      console.log("No Poa");
    }
  }

  getInvitationState(): void {
    this.storage.has("invitationIdCheck").subscribe((invitationExists) => {
      if (invitationExists) {
        this.storage.get("invitationIdCheck").subscribe((invitationStored) => {
          let fromStorage = invitationStored as InvitationIdCheck;
          let fromRoute = this.location.getState() as InvitationIdCheck;
          if (fromRoute.invitationId == undefined) {
            this.invitation = fromStorage;
            this.setExistingDocumentTypes();
            this.setExistingDocumentData();
            this.setModifiedRecordCheckProperties();
            this.setPoaRouteTaken();
          } else {
            this.setInvitationFromRoute();
          }
        });
      } else {
        this.setInvitationFromRoute();
      }
    });
  }

  setInvitationFromRoute(): void {
    this.invitation = this.location.getState() as InvitationIdCheck;
    this.setExistingDocumentTypes();
    this.setExistingDocumentData();
    this.storage.set("invitationIdCheck", this.invitation).subscribe(() => {});
    this.setModifiedRecordCheckProperties();
    this.setPoaRouteTaken();
  }

  readonly errorMessages = {
    issueCountry: {
      default: "Select an issuing country.",
    },
    documentType: {
      default: "Select a document type.",
    },
    approvalNote: {
      unknown: "Please check you've entered the note correctly.",
      empty: "The note cannot be empty.",
      lengthTooLong: "The note must be no more than 300 characters long.",
      unexpectedCharacters:
        "The note must consist of only letters, numbers and punctuation.",
    },
  };

  evidenceFieldsRecord = {
    PhotoIdOne: new ConfirmDetailsEvidence("photo-id-one"),
    PhotoIdTwo: new ConfirmDetailsEvidence("photo-id-two"),
    ProofOfAddress: new ConfirmDetailsEvidence("poa"),
  };

  updateEvidenceFieldAnswer(
    evidenceKey: ConfirmDetailsEvidenceType,
    fieldName: ConfirmDetailsEvidenceFieldType,
    value: string,
  ): void {
    this.evidenceFieldsRecord[`${evidenceKey}`][`${fieldName}`].value =
      value || "";
  }
  readonly documentSpecificErrors = {
    UKPassport: {
      empty: "Enter an ID number.",
      lengthTooShort: "UK passport number must be 9 digits.",
      lengthTooLong: "UK passport number must be 9 digits.",
      unexpectedCharacters: "UK passport number must only contain numbers.",
    },
    IntlPassport: {
      empty: "Enter an ID number.",
      lengthTooShort: "Passport number must be at least 2 characters.",
      lengthTooLong: "Passport number must be 35 characters or fewer.",
      // unexpectedCharacters: "",
    },
    UKDrivingLicense: {
      empty: "Enter an ID number.",
      lengthTooShort: "UK Driving licence number must be 16 or 19 characters.",
      lengthTooLong: "UK Driving licence number must be 16 or 19 characters.",
      unexpectedCharacters:
        "UK Driving licence number must only contain letters and numbers.",
    },
    IntlDrivingLicense: {
      empty: "Enter an ID number.",
      lengthTooShort:
        "International driving licence number must be at least 2 characters.",
      lengthTooLong:
        "International driving licence number must be 35 characters or fewer.",
      // unexpectedCharacters: "",
    },
    otherIdDocumentNumber: {
      empty: "Enter an ID number.",
      lengthTooShort: "ID number must be at least 2 characters.",
      lengthTooLong: "ID number must be 35 characters or fewer.",
      unexpectedCharacters: "ID number must only contain letters and numbers.",
    },
  };

  getDocumentSpecificError(
    issueCountry: string,
    docType: string,
    errorReason: DataValidationReasons,
  ): string {
    let key: string = "";
    switch (docType) {
      case "Passport":
        key = issueCountry == "GBR" ? "UKPassport" : "IntlPassport";
        break;
      case "DrivingLicence":
        key = issueCountry == "GBR" ? "UKDrivingLicense" : "IntlDrivingLicense";
        break;
      default:
        key = "otherIdDocumentNumber";
        break;
    }
    return this.documentSpecificErrors[key.toString()][errorReason.toString()];
  }
  updateEvidenceNumberValid(evidenceKey: ConfirmDetailsEvidenceType): void {
    let validationResult: DataValidationResult;
    const docNo = this.evidenceFieldsRecord[`${evidenceKey}`].number.value;
    if (evidenceKey != "ProofOfAddress") {
      validationResult = this.validatePhotoIdNo(evidenceKey, docNo);
    } else {
      validationResult = isPhotoIdNumberValid(docNo);
    }
    if (validationResult.validity) {
      this.evidenceFieldsRecord[`${evidenceKey}`].number.errorMessage = "";
      this.evidenceFieldsRecord[`${evidenceKey}`].number.valid = true;
    } else {
      this.evidenceFieldsRecord[`${evidenceKey}`].number.errorMessage =
        this.getDocumentSpecificError(
          this.evidenceFieldsRecord[`${evidenceKey}`].issue.value,
          this.evidenceFieldsRecord[`${evidenceKey}`].docType.value,
          validationResult.errorReason,
        );
      this.evidenceFieldsRecord[`${evidenceKey}`].number.valid = false;
    }
    this.updateEvidenceFieldErrorMessage(evidenceKey, "number");
  }

  validatePhotoIdNo(
    evidenceKey: "PhotoIdOne" | "PhotoIdTwo",
    docNo: string,
  ): DataValidationResult {
    let validationResult: DataValidationResult;
    const issueCountry =
      this.evidenceFieldsRecord[`${evidenceKey}`].issue.value;
    if (issueCountry !== "GBR") {
      validationResult = isNonUkDocumentNumberValid(docNo);
    } else if (
      this.evidenceFieldsRecord[`${evidenceKey}`].docType.value ===
      "DrivingLicence"
    ) {
      validationResult = isUKDrivingLicenseNumberValid(docNo);
    } else if (
      this.evidenceFieldsRecord[`${evidenceKey}`].docType.value === "Passport"
    ) {
      validationResult = isUkPassportNumberValid(docNo);
    } else {
      validationResult = isPhotoIdNumberValid(docNo);
    }
    return validationResult;
  }

  updateEvidenceDocumentTypeValid(
    evidenceKey: ConfirmDetailsEvidenceType,
  ): void {
    const documentType = this.evidenceFieldsRecord[`${evidenceKey}`].docType;
    if (documentType.value == "none" || documentType.value == undefined) {
      documentType.valid = false;
      documentType.errorMessage = this.errorMessages.documentType.default;
    } else {
      documentType.valid = true;
      documentType.errorMessage = "";
      const key = documentType.invitationKey;
      this.invitation[`${key}`] = documentType.value.replace(/\s/g, "");
    }
    this.updateEvidenceFieldErrorMessage(evidenceKey, "docType");
  }

  updateEvidenceIssueCountryValid(
    evidenceKey: ConfirmDetailsEvidenceType,
  ): void {
    const issueCountry = this.evidenceFieldsRecord[`${evidenceKey}`].issue;
    if (!issueCountry.value) {
      issueCountry.valid = false;
      issueCountry.errorMessage = this.errorMessages.issueCountry.default;
    } else {
      issueCountry.valid = true;
      issueCountry.errorMessage = "";
    }
    this.updateEvidenceFieldErrorMessage(evidenceKey, "issue");
  }

  updateEvidenceIssueDateValid(evidenceKey: ConfirmDetailsEvidenceType): void {
    const map: Map<ConfirmDetailsEvidenceType, IdCheckerDateComponent> =
      new Map([
        ["PhotoIdOne", this.photoIdOneIssueDateInput],
        ["PhotoIdTwo", this.photoIdTwoIssueDateInput],
        ["ProofOfAddress", this.poaIssueDateInput],
      ]);
    // actual validation is handled by id checker date component
    // call the validation input just in case
    const mappedIssueDateInputs = {
      PhotoIdOne: this.photoIdOneIssueDateInput,
      PhotoIdTwo: this.photoIdTwoIssueDateInput,
      ProofOfAddress: this.poaIssueDateInput,
    };
    mappedIssueDateInputs[`${evidenceKey}`].validateAnswers();
  }

  updateEvidenceFieldErrorMessage(
    evidenceKey: ConfirmDetailsEvidenceType,
    fieldName: ConfirmDetailsEvidenceFieldType,
  ): void {
    if (
      this.evidenceFieldsRecord[`${evidenceKey}`][`${fieldName}`].errorMessage
    ) {
      this.summaryErrorMessages.push({
        message:
          this.evidenceFieldsRecord[`${evidenceKey}`][`${fieldName}`]
            .errorMessage,
        id: this.evidenceFieldsRecord[`${evidenceKey}`][`${fieldName}`].errorId,
      });
    }
  }

  setEvidenceIssueDateValidFromEvent(
    evidenceKey: ConfirmDetailsEvidenceType,
    value: boolean,
  ) {
    this.evidenceFieldsRecord[`${evidenceKey}`].issueDate.valid = value;
  }

  /**
   * Updates evidenceFieldsRecord in response to error events from
   * id-checker-date component.
   *
   * @param evidenceKey The piece of evidence being verified.
   * @param value The error message to set in evidenceFieldsRecord.
   */
  setEvidenceIssueDateErrorMessageFromEvent(
    evidenceKey: ConfirmDetailsEvidenceType,
    value: string,
  ): void {
    this.evidenceFieldsRecord[`${evidenceKey}`].issueDate.errorMessage = value;
    this.updateEvidenceFieldErrorMessage(evidenceKey, "issueDate");
  }

  setPoaRouteTaken(): void {
    if (
      this.invitation.dpoaUsed &&
      this.invitation.poaDocumentType == undefined
    ) {
      this.poaRouteTaken = PoaRouteTaken.DpoaRoutePassed;
    } else if (
      this.invitation.dpoaUsed &&
      this.invitation.poaDocumentType != undefined
    ) {
      this.poaRouteTaken = PoaRouteTaken.DpoaRouteFailed;
    } else if (!this.invitation.dpoaUsed) {
      this.poaRouteTaken = PoaRouteTaken.PoaRoute;
    }
  }

  isAnswerValid(): boolean {
    this.summaryErrorMessages = Array<ErrorSummaryMessage>();
    const validators = [
      this.updateEvidenceNumberValid,
      this.updateEvidenceDocumentTypeValid,
      this.updateEvidenceIssueDateValid,
      this.updateEvidenceIssueCountryValid,
    ];
    validators.forEach((validator) => validator.call(this, "PhotoIdOne"));
    if (
      this.poaRouteTaken == PoaRouteTaken.DpoaRouteFailed ||
      this.poaRouteTaken == PoaRouteTaken.PoaRoute
    ) {
      validators.forEach((validator) => validator.call(this, "PhotoIdTwo"));
      validators.forEach((validator) => validator.call(this, "ProofOfAddress"));
    }

    if (
      this.acknowledgedNotDuplicateInput &&
      !this.acknowledgedNotDuplicateInput.isChecked
    ) {
      return false;
    }

    if (
      this.poaRouteTaken == PoaRouteTaken.DpoaRoutePassed &&
      this.evidenceFieldsRecord.PhotoIdOne.isValid() &&
      this.approvalNoteValid
    ) {
      return true;
    } else if (
      (this.poaRouteTaken == PoaRouteTaken.DpoaRouteFailed ||
        this.poaRouteTaken == PoaRouteTaken.PoaRoute) &&
      Object.values(this.evidenceFieldsRecord).every((evidence) => {
        return evidence.isValid();
      }) &&
      this.approvalNoteValid
    ) {
      return true;
    } else {
      return false;
    }
  }

  isPhotoIdOneFrontExpanded(isExpanded: boolean) {
    this.photoIdOneFrontExpand = isExpanded;
  }

  isPhotoIdOneBackExpanded(isExpanded: boolean) {
    this.photoIdOneBackExpand = isExpanded;
  }

  isPhotoIdTwoFrontExpanded(isExpanded: boolean) {
    this.photoIdTwoFrontExpand = isExpanded;
  }

  isPhotoIdTwoBackExpanded(isExpanded: boolean) {
    this.photoIdTwoBackExpand = isExpanded;
  }

  isPoaExpanded(isExpanded: boolean) {
    this.poaExpand = isExpanded;
  }

  updateInvitationFromRecord(): void {
    Object.values(this.evidenceFieldsRecord).forEach((evidence) => {
      evidence.getEvidence().forEach((field) => {
        this.invitation[`${field.invitationKey}`] = field.value;
      });
    });
  }

  async submitButtonClicked(): Promise<void> {
    this.isSubmitted = true;
    this.grantUserError = false;
    this.validationErrorMessage = "";
    this.updateInvitationFromRecord();
    if (this.isAnswerValid()) {
      // duplicate check won't have already ran if invitation isn't on hold
      if (this.invitation.status != 51) {
        await this.finalDuplicateCheck();
      }

      try {
        if (
          this.invitation.status != 51 &&
          this.finalDuplicateCheckPossibleDuplicate
        ) {
          const holdReasons = this.invitation.holdReason || [];
          holdReasons.push(IdCheckHeldReason["Potential Duplicate"]);
          await this.sendInvitationService.putIdCheckInvitation(
            this.invitation.invitationId,
            this.invitation.idCheckedBy,
            this.invitation.idCheckedByUserId,
            InvitationStatus["On hold"],
            null,
            null,
            holdReasons,
            this.invitation.holdNotes,
          );
        } else {
          await this.grantUserService.postGrantUser(
            this.invitationId,
            this.invitation.photoIdOneNo,
            this.invitation.photoIdOneDateOfIssue,
            this.invitation.photoIdOneIssue,
            this.invitation.photoIdTwoNo,
            this.invitation.photoIdTwoDateOfIssue,
            this.invitation.photoIdTwoIssue,
            this.invitation.poaNo,
            this.invitation.poaDateOfIssue,
            this.invitation.poaIssue,
            this.invitation.poaDocumentType,
            this.invitation.smartcardPhoto,
            this.invitation.idCheckedBy,
            this.invitation.idCheckedByUserId,
            this.invitation.photoIdOneType,
            this.invitation.photoIdTwoType,
            this.approvalNote,
          );
        }
        this.routerService.redirectToCheckerReviewList();
      } catch (err) {
        if (err.status == 400) {
          this.validationErrorMessage = err.error.detail;
        } else {
          this.grantUserError = true;
        }
      } finally {
        this.isSubmitted = false;
      }
    } else {
      this.scrollToErrorSummaryMessages();
      this.isSubmitted = false;
    }
  }

  scrollToErrorSummaryMessages(): void {
    document
      .querySelector("#error-summary-messages")
      .scrollIntoView({ behavior: "smooth" });
  }

  setExistingDocumentTypes(): void {
    if (!this.invitation) {
      return;
    }
    if (this.invitation.photoIdOneType !== "Unclassified") {
      this.evidenceFieldsRecord.PhotoIdOne.docType.value =
        this.invitation.photoIdOneType;
    }
    if (this.invitation.photoIdTwoType !== "Unclassified") {
      this.evidenceFieldsRecord.PhotoIdTwo.docType.value =
        this.invitation.photoIdTwoType;
    }
    const poaDocTypeFromApplicant = this.invitation.poaDocumentType;
    this.poaDocumentTypes.forEach((entry) => {
      if (entry.value == poaDocTypeFromApplicant) {
        this.evidenceFieldsRecord.ProofOfAddress.docType.value = entry.value;
      }
    });
  }

  setExistingDocumentData(): void {
    if (!this.invitation) {
      return;
    }
    Object.values(this.evidenceFieldsRecord).forEach((evidence) => {
      evidence.number.value =
        this.invitation[`${evidence.number.invitationKey}`] || "";
      evidence.issue.value =
        this.invitation[`${evidence.issue.invitationKey}`] || "";
      evidence.issueDate.value =
        this.invitation[`${evidence.issueDate.invitationKey}`] || "";
    });
  }

  setModifiedRecordCheckProperties(): void {
    this.modifiedRecordCheck.enabled = true;
    this.modifiedRecordCheck.invitationId = this.invitation.invitationId;
    this.modifiedRecordCheck.status = this.invitation.status;
    this.modifiedRecordCheck.statusLastUpdatedUtc =
      this.invitation.statusLastUpdatedDateUtc;
  }

  updateApprovalNoteAnswer(note: string): void {
    if (note === undefined) {
      note = "";
    }

    const validationResult = isNotesValid(note, 300, false);
    if (validationResult.validity) {
      this.approvalNoteErrorMessage = "";
      this.approvalNoteValid = true;
    } else {
      this.approvalNoteErrorMessage =
        this.errorMessages.approvalNote[validationResult.errorReason];
      this.approvalNoteValid = false;
    }
    this.approvalNote = note;
    this.updateApprovalNoteErrorMessage();
  }

  updateApprovalNoteErrorMessage(): void {
    if (this.approvalNoteErrorMessage) {
      this.summaryErrorMessages.push({
        message: this.approvalNoteErrorMessage,
        id: "internal-note",
      });
    }
  }

  async finalDuplicateCheck(): Promise<void> {
    this.finalDuplicateCheckInProgress = true;

    return firstValueFrom(
      this.duplicateCheckService.postFinalDuplicateCheck(
        this.invitationId,
        this.invitation.photoIdOneNo,
        this.invitation.photoIdOneIssue,
        this.invitation.photoIdOneType,
        this.invitation.photoIdTwoNo,
        this.invitation.photoIdTwoIssue,
        this.invitation.photoIdTwoType,
      ),
    )
      .then((matchedUuid) => {
        this.finalDuplicateCheckPossibleDuplicate = !!matchedUuid;
      })
      .catch((_) => {
        // log error?
        // this.finalDuplicateCheckResultText = "Error: " + err.error.detail;
      })
      .finally(() => {
        this.finalDuplicateCheckInProgress = false;
      });
  }

  readonly poaDocumentTypes = [
    { key: "Driving Licence", value: "DrivingLicence" },
    { key: "Utility Bill", value: "UtilityBill" },
    { key: "Financial Statement", value: "BankAccount" },
    { key: "Local Authority Tax Bill", value: "CouncilTax" },
    {
      key: "Mortgage Statement From A Recognized Lender",
      value: "MortgageStatement",
    },
  ];

  readonly photoIdDocumentTypes = [
    { key: "Driving Licence", value: "DrivingLicence" },
    { key: "Passport", value: "Passport" },
    { key: "Residence Permit", value: "ResidencePermit" },
    { key: "HM Armed Forces ID card", value: "ArmedForces" },
    {
      key: "National Identity Cards and PASS cards",
      value: "PassProofOfAgeCard",
    },
  ];

  readonly countryCodes = [
    { key: "GBR", value: "United Kingdom" },
    { key: "ABW", value: "Aruba" },
    { key: "AFG", value: "Afghanistan" },
    { key: "AGO", value: "Angola" },
    { key: "AIA", value: "Anguilla" },
    { key: "ALA", value: "Åland Islands" },
    { key: "ALB", value: "Albania" },
    { key: "AND", value: "Andorra" },
    { key: "ARE", value: "United Arab Emirates" },
    { key: "ARG", value: "Argentina" },
    { key: "ARM", value: "Armenia" },
    { key: "ASM", value: "American Samoa" },
    { key: "ATA", value: "Antarctica" },
    { key: "ATF", value: "French Southern Territories" },
    { key: "ATG", value: "Antigua and Barbuda" },
    { key: "AUS", value: "Australia" },
    { key: "AUT", value: "Austria" },
    { key: "AZE", value: "Azerbaijan" },
    { key: "BDI", value: "Burundi" },
    { key: "BEL", value: "Belgium" },
    { key: "BEN", value: "Benin" },
    { key: "BES", value: "Bonaire, Sint Eustatius and Saba" },
    { key: "BFA", value: "Burkina Faso" },
    { key: "BGD", value: "Bangladesh" },
    { key: "BGR", value: "Bulgaria" },
    { key: "BHR", value: "Bahrain" },
    { key: "BHS", value: "Bahamas" },
    { key: "BIH", value: "Bosnia and Herzegovina" },
    { key: "BLM", value: "Saint Barthélemy" },
    { key: "BLR", value: "Belarus" },
    { key: "BLZ", value: "Belize" },
    { key: "BMU", value: "Bermuda" },
    { key: "BOL", value: "Bolivia, Plurinational State of" },
    { key: "BRA", value: "Brazil" },
    { key: "BRB", value: "Barbados" },
    { key: "BRN", value: "Brunei Darussalam" },
    { key: "BTN", value: "Bhutan" },
    { key: "BVT", value: "Bouvet Island" },
    { key: "BWA", value: "Botswana" },
    { key: "CAF", value: "Central African Republic" },
    { key: "CAN", value: "Canada" },
    { key: "CCK", value: "Cocos (Keeling) Islands" },
    { key: "CHE", value: "Switzerland" },
    { key: "CHL", value: "Chile" },
    { key: "CHN", value: "China" },
    { key: "CIV", value: "Côte d'Ivoire" },
    { key: "CMR", value: "Cameroon" },
    { key: "COD", value: "Congo, the Democratic Republic of the" },
    { key: "COG", value: "Congo" },
    { key: "COK", value: "Cook Islands" },
    { key: "COL", value: "Colombia" },
    { key: "COM", value: "Comoros" },
    { key: "CPV", value: "Cape Verde" },
    { key: "CRI", value: "Costa Rica" },
    { key: "CUB", value: "Cuba" },
    { key: "CUW", value: "Curaçao" },
    { key: "CXR", value: "Christmas Island" },
    { key: "CYM", value: "Cayman Islands" },
    { key: "CYP", value: "Cyprus" },
    { key: "CZE", value: "Czech Republic" },
    { key: "DEU", value: "Germany" },
    { key: "DJI", value: "Djibouti" },
    { key: "DMA", value: "Dominica" },
    { key: "DNK", value: "Denmark" },
    { key: "DOM", value: "Dominican Republic" },
    { key: "DZA", value: "Algeria" },
    { key: "ECU", value: "Ecuador" },
    { key: "EGY", value: "Egypt" },
    { key: "ERI", value: "Eritrea" },
    { key: "ESH", value: "Western Sahara" },
    { key: "ESP", value: "Spain" },
    { key: "EST", value: "Estonia" },
    { key: "ETH", value: "Ethiopia" },
    { key: "FIN", value: "Finland" },
    { key: "FJI", value: "Fiji" },
    { key: "FLK", value: "Falkland Islands (Malvinas)" },
    { key: "FRA", value: "France" },
    { key: "FRO", value: "Faroe Islands" },
    { key: "FSM", value: "Micronesia, Federated States of" },
    { key: "GAB", value: "Gabon" },
    { key: "GEO", value: "Georgia" },
    { key: "GGY", value: "Guernsey" },
    { key: "GHA", value: "Ghana" },
    { key: "GIB", value: "Gibraltar" },
    { key: "GIN", value: "Guinea" },
    { key: "GLP", value: "Guadeloupe" },
    { key: "GMB", value: "Gambia" },
    { key: "GNB", value: "Guinea-Bissau" },
    { key: "GNQ", value: "Equatorial Guinea" },
    { key: "GRC", value: "Greece" },
    { key: "GRD", value: "Grenada" },
    { key: "GRL", value: "Greenland" },
    { key: "GTM", value: "Guatemala" },
    { key: "GUF", value: "French Guiana" },
    { key: "GUM", value: "Guam" },
    { key: "GUY", value: "Guyana" },
    { key: "HKG", value: "Hong Kong" },
    { key: "HMD", value: "Heard Island and McDonald Islands" },
    { key: "HND", value: "Honduras" },
    { key: "HRV", value: "Croatia" },
    { key: "HTI", value: "Haiti" },
    { key: "HUN", value: "Hungary" },
    { key: "IDN", value: "Indonesia" },
    { key: "IMN", value: "Isle of Man" },
    { key: "IND", value: "India" },
    { key: "IOT", value: "British Indian Ocean Territory" },
    { key: "IRL", value: "Ireland" },
    { key: "IRN", value: "Iran, Islamic Republic of" },
    { key: "IRQ", value: "Iraq" },
    { key: "ISL", value: "Iceland" },
    { key: "ISR", value: "Israel" },
    { key: "ITA", value: "Italy" },
    { key: "JAM", value: "Jamaica" },
    { key: "JEY", value: "Jersey" },
    { key: "JOR", value: "Jordan" },
    { key: "JPN", value: "Japan" },
    { key: "KAZ", value: "Kazakhstan" },
    { key: "KEN", value: "Kenya" },
    { key: "KGZ", value: "Kyrgyzstan" },
    { key: "KHM", value: "Cambodia" },
    { key: "KIR", value: "Kiribati" },
    { key: "KNA", value: "Saint Kitts and Nevis" },
    { key: "KOR", value: "Korea, Republic of" },
    { key: "KWT", value: "Kuwait" },
    { key: "LAO", value: "Lao People's Democratic Republic" },
    { key: "LBN", value: "Lebanon" },
    { key: "LBR", value: "Liberia" },
    { key: "LBY", value: "Libya" },
    { key: "LCA", value: "Saint Lucia" },
    { key: "LIE", value: "Liechtenstein" },
    { key: "LKA", value: "Sri Lanka" },
    { key: "LSO", value: "Lesotho" },
    { key: "LTU", value: "Lithuania" },
    { key: "LUX", value: "Luxembourg" },
    { key: "LVA", value: "Latvia" },
    { key: "MAC", value: "Macao" },
    { key: "MAF", value: "Saint Martin (French part)" },
    { key: "MAR", value: "Morocco" },
    { key: "MCO", value: "Monaco" },
    { key: "MDA", value: "Moldova, Republic of" },
    { key: "MDG", value: "Madagascar" },
    { key: "MDV", value: "Maldives" },
    { key: "MEX", value: "Mexico" },
    { key: "MHL", value: "Marshall Islands" },
    { key: "MKD", value: "Macedonia, the former Yugoslav Republic of" },
    { key: "MLI", value: "Mali" },
    { key: "MLT", value: "Malta" },
    { key: "MMR", value: "Myanmar" },
    { key: "MNE", value: "Montenegro" },
    { key: "MNG", value: "Mongolia" },
    { key: "MNP", value: "Northern Mariana Islands" },
    { key: "MOZ", value: "Mozambique" },
    { key: "MRT", value: "Mauritania" },
    { key: "MSR", value: "Montserrat" },
    { key: "MTQ", value: "Martinique" },
    { key: "MUS", value: "Mauritius" },
    { key: "MWI", value: "Malawi" },
    { key: "MYS", value: "Malaysia" },
    { key: "MYT", value: "Mayotte" },
    { key: "NAM", value: "Namibia" },
    { key: "NCL", value: "New Caledonia" },
    { key: "NER", value: "Niger" },
    { key: "NFK", value: "Norfolk Island" },
    { key: "NGA", value: "Nigeria" },
    { key: "NIC", value: "Nicaragua" },
    { key: "NIU", value: "Niue" },
    { key: "NLD", value: "Netherlands" },
    { key: "NOR", value: "Norway" },
    { key: "NPL", value: "Nepal" },
    { key: "NRU", value: "Naura" },
    { key: "NZL", value: "New Zealand" },
    { key: "OMN", value: "Oman" },
    { key: "PAK", value: "Pakistan" },
    { key: "PAN", value: "Panama" },
    { key: "PCN", value: "Pitcairn" },
    { key: "PER", value: "Peru" },
    { key: "PHL", value: "Philippines" },
    { key: "PLW", value: "Palau" },
    { key: "PNG", value: "Papua New Guinea" },
    { key: "POL", value: "Poland" },
    { key: "PRI", value: "Puerto Rico" },
    { key: "PRK", value: "Korea, Democratic People's Republic of" },
    { key: "PRT", value: "Portugal" },
    { key: "PRY", value: "Paraguay" },
    { key: "PSE", value: "Palestine, State of" },
    { key: "PYF", value: "French Polynesia" },
    { key: "QAT", value: "Qatar" },
    { key: "REU", value: "Réunion" },
    { key: "ROU", value: "Romania" },
    { key: "RUS", value: "Russian Federation" },
    { key: "RWA", value: "Rwanda" },
    { key: "SAU", value: "Saudi Arabia" },
    { key: "SDN", value: "Sudan" },
    { key: "SEN", value: "Senegal" },
    { key: "SGP", value: "Singapore" },
    { key: "SGS", value: "South Georgia and the South Sandwich Islands" },
    { key: "SHN", value: "Saint Helena, Ascension and Tristan da Cunha" },
    { key: "SJM", value: "Svalbard and Jan Mayen" },
    { key: "SLB", value: "Solomon Islands" },
    { key: "SLE", value: "Sierra Leone" },
    { key: "SLV", value: "El Salvador" },
    { key: "SMR", value: "San Marino" },
    { key: "SOM", value: "Somalia" },
    { key: "SPM", value: "Saint Pierre and Miquelon" },
    { key: "SRB", value: "Serbia" },
    { key: "SSD", value: "South Sudan" },
    { key: "STP", value: "Sao Tome and Principe" },
    { key: "SUR", value: "Suriname" },
    { key: "SVK", value: "Slovakia" },
    { key: "SVN", value: "Slovenia" },
    { key: "SWE", value: "Sweden" },
    { key: "SWZ", value: "Swaziland" },
    { key: "SXM", value: "Sint Maarten (Dutch part)" },
    { key: "SYC", value: "Seychelles" },
    { key: "SYR", value: "Syrian Arab Republic" },
    { key: "TCA", value: "Turks and Caicos Islands" },
    { key: "TCD", value: "Chad" },
    { key: "TGO", value: "Togo" },
    { key: "THA", value: "Thailand" },
    { key: "TJK", value: "Tajikistan" },
    { key: "TKL", value: "Tokelau" },
    { key: "TKM", value: "Turkmenistan" },
    { key: "TLS", value: "Timor-Leste" },
    { key: "TON", value: "Tonga" },
    { key: "TTO", value: "Trinidad and Tobago" },
    { key: "TUN", value: "Tunisia" },
    { key: "TUR", value: "Turkey" },
    { key: "TUV", value: "Tuvalu" },
    { key: "TWN", value: "Taiwan, Province of China" },
    { key: "TZA", value: "Tanzania, United Republic of" },
    { key: "UGA", value: "Uganda" },
    { key: "UKR", value: "Ukraine" },
    { key: "UMI", value: "United States Minor Outlying Islands" },
    { key: "URY", value: "Uruguay" },
    { key: "USA", value: "United States" },
    { key: "UZB", value: "Uzbekistan" },
    { key: "VAT", value: "Holy See (Vatican City State)" },
    { key: "VCT", value: "Saint Vincent and the Grenadines" },
    { key: "VEN", value: "Venezuela, Bolivarian Republic of" },
    { key: "VGB", value: "Virgin Islands, British" },
    { key: "VIR", value: "Virgin Islands, U.S." },
    { key: "VNM", value: "Viet Nam" },
    { key: "VUT", value: "Vanuatu" },
    { key: "WLF", value: "Wallis and Futuna" },
    { key: "WSM", value: "Somoa" },
    { key: "YEM", value: "Yemen" },
    { key: "ZAF", value: "South Africa" },
    { key: "ZMB", value: "Zambia" },
    { key: "ZWE", value: "Zimbabwe" },
    { key: "ANT", value: "Netherland Antilles" },
    { key: "NIR", value: "Northern Ireland" },
  ];
}
