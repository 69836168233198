<session-check></session-check>

<breadcrumb-link
  [breadcrumbRoute]="breadcrumbRoute"
  [breadcrumbText]="breadcrumbText"
></breadcrumb-link>

<div class="nhsuk-grid-row" *ngIf="note">
  <div class="nhsuk-grid-column-two-thirds">
    <div class="nhsuk-form-group">
      <h1 autofocus tabindex="-1" class="nhsuk-label-wrapper">
        <label class="nhsuk-label nhsuk-label--xl" for="example">
          Edit note
        </label>
      </h1>
      <error-summary
        [errorSummaryMessages]="summaryErrorMessages"
      ></error-summary>
      <text-area-input
        #noteInput
        [cypressValue]="'internal-note'"
        id="internal-note"
        [value]="note.note"
        [errorMessage]="noteErrorMessage"
        (questionAnswer)="updateInternalNoteAnswer($event)"
      >
      </text-area-input>

      <button
        type="submit"
        (click)="onSubmit()"
        class="nhsuk-button nhsuk-u-margin-top-4"
        [ngClass]="{ 'spinner-button': loading }"
        [disabled]="loading"
        data-cy="save-button"
      >
        Save
      </button>
    </div>
  </div>
</div>
