import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
@Component({
  selector: "app-transunion",
  templateUrl: "./transunion.component.html",
})
export class TransunionComponent implements OnInit {
  breadcrumbRoute = "/invitee/step1";
  breadcrumbText = "Back";

  constructor(private title: Title) {}

  ngOnInit(): void {
    this.title.setTitle("How TransUnion verifies your UK address");
  }
}
