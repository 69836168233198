import { Component, OnInit, ViewChild, Inject } from "@angular/core";
import { RouterService } from "src/app/services/router.service";
import { DateOfBirthComponent } from "../../common/form-fields/date-of-birth/date-of-birth.component";
import { ErrorSummaryMessage } from "../../../models/error-summary-message";
import { Title } from "@angular/platform-browser";
import { SESSION_STORAGE, StorageService } from "ngx-webstorage-service";
import { InvitationConfirmation } from "src/store/states/invitee-spa.state";
import { InviteeDetails } from "src/app/utils/invitee-details";

@Component({
  selector: "app-confirm-date-of-birth",
  templateUrl: "./confirm-date-of-birth.component.html",
})
export class ConfirmDateOfBirthComponent implements OnInit {
  breadcrumbRoute = "/invitee/confirm-preferred-name";
  breadcrumbText = "Back";
  forwardRoute = "/invitee/confirm-ni-number";
  captureDeliveryAddress: boolean;
  invitationConfirmation: InvitationConfirmation;

  dateOfBirthValid: boolean;
  summaryErrorMessages = Array<ErrorSummaryMessage>();

  @ViewChild("dateOfBirthInput") dateOfBirthInput: DateOfBirthComponent;
  constructor(
    private routerService: RouterService,
    private title: Title,
    @Inject(SESSION_STORAGE) private sessionStorage: StorageService,
    private inviteeDetails: InviteeDetails,
  ) {}

  async ngOnInit(): Promise<void> {
    this.title.setTitle("Enter your date of birth");
    this.captureDeliveryAddress = this.sessionStorage.get(
      "captureDeliveryAddress",
    );

    this.invitationConfirmation = this.inviteeDetails.getInviteeDetails();
  }

  submitAnswer(): void {
    if (this.isAnswerValid()) {
      this.inviteeDetails.setInviteeDetails(this.invitationConfirmation);
      this.checkApplicantProgress();
      this.routerService.redirectTo(this.forwardRoute);
    }
  }

  isAnswerValid(): boolean {
    this.dateOfBirthInput.setAnswers();
    return this.dateOfBirthValid;
  }

  updateDOBAnswer(dateOfBirth: string): void {
    this.invitationConfirmation.dob = dateOfBirth;
  }

  updateDOBValid(dateOfBirthValid: boolean): void {
    this.dateOfBirthValid = dateOfBirthValid;
    if (dateOfBirthValid) {
      this.updateDOBErrorMessage("");
    }
  }

  updateDOBErrorMessage(dateOfBirthErrorMessage: string): void {
    const updatedErrorSummary = Array<ErrorSummaryMessage>();
    if (dateOfBirthErrorMessage) {
      updatedErrorSummary.push({
        message: dateOfBirthErrorMessage,
        id: "date-of-birth",
      });
    }
    this.summaryErrorMessages = updatedErrorSummary;
  }

  checkApplicantProgress(): void {
    if (
      this.invitationConfirmation.title !== "0" &&
      this.invitationConfirmation.firstName !== "" &&
      this.invitationConfirmation.familyName !== "" &&
      this.invitationConfirmation.niNumber !== ""
    ) {
      this.forwardRoute = "/invitee/check-your-answers";
    }
  }
}
