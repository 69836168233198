export interface SessionToken {
  session_id: string;
  user_uuid: string;
  user_name: string;
  expiry_date_utc: string;
}

export enum Portal {
  Invitation = 1,
  Checker = 2,
}
