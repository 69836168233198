<div class="nhsuk-grid-row">
  <div class="nhsuk-grid-column-two-thirds">
    <breadcrumb-link
      [breadcrumbRoute]="breadcrumbRoute"
      [breadcrumbText]="breadcrumbText"
    ></breadcrumb-link>

    <div class="nhsuk-form-group" z>
      <error-summary
        [errorSummaryMessages]="summaryErrorMessages"
      ></error-summary>
      <fieldset class="nhsuk-fieldset">
        <legend
          class="nhsuk-fieldset__legend nhsuk-fieldset__legend--xl nhsuk-u-margin-bottom-7"
        >
          <h1 autofocus tabindex="-1" class="nhsuk-fieldset__heading">
            Select a delivery address
          </h1>
        </legend>

        <p>
          This address will be used to send a smartcard, fob or other ID device.
        </p>

        <p>This could be your home or work address.</p>

        <div *ngIf="invitationConfirmation" class="nhsuk-radios">
          <div class="nhsuk-radios__item">
            <input
              data-cy="same-address"
              class="nhsuk-radios__input"
              id="delivery-same"
              name="delivery"
              aria-controls="yes"
              aria-expanded="false"
              type="radio"
              (change)="updateDeliveryRadioValue(true)"
            />
            <label class="nhsuk-label nhsuk-radios__label" for="delivery-same">
              {{ invitationConfirmation.tuAddress }}
            </label>
          </div>

          <div class="nhsuk-radios__item">
            <input
              data-cy="different-address"
              class="nhsuk-radios__input"
              id="delivery-different"
              aria-controls="no"
              aria-expanded="false"
              name="delivery"
              type="radio"
              [value]="sameDeliveryRadio"
              (change)="updateDeliveryRadioValue(false)"
            />
            <label
              class="nhsuk-label nhsuk-radios__label"
              for="delivery-different"
            >
              Use a different address
            </label>
          </div>
        </div>
      </fieldset>
    </div>

    <button
      (click)="onSubmitButtonClicked()"
      class="nhsuk-button"
      type="submit"
      data-cy="submit-button"
    >
      Continue
    </button>
  </div>
</div>
