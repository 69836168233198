<div class="nhsuk-grid-row">
  <div class="nhsuk-grid-column-two-thirds">
    <breadcrumb-link
      [breadcrumbRoute]="breadcrumbRoute"
      [breadcrumbText]="breadcrumbText"
    ></breadcrumb-link>
    <h1 class="nhsuk-label-wrapper">
      <label
        autofocus
        tabindex="-1"
        class="nhsuk-label nhsuk-label--xl"
        for="otp-number-input"
      >
        Enter your security code
      </label>
    </h1>
    <p>
      Use the 6 digit security code that has been sent to your mobile phone
      number ending *********<strong>{{ mobile }}</strong
      >.
    </p>
    <p>The security code will expire in 15 minutes from when it was sent.</p>
    <div class="nhsuk-form-group">
      <text-input
        size="nhsuk-input--width-5"
        #otpNumberInput
        inputType="numeric"
        inputMode="numeric"
        [id]="'otp-number-input'"
        hint="The code contains 6 numbers"
        [errorMessage]="otpNumberErrorMessage"
        (questionAnswer)="updateOtpNumberAnswer($event)"
        [disabled]="otpNumberValid"
        autocomplete="off"
        [cypressValue]="'otp-number-input'"
      ></text-input>
    </div>
    <button
      class="nhsuk-button nhsuk-u-margin-bottom-5"
      (click)="submitAnswers()"
      id="continue"
      [ngClass]="{ 'spinner-button': submitButtonClicked }"
      [disabled]="submitButtonClicked"
      data-cy="continue-button"
    >
      Continue
    </button>
    <p>
      <a href="/invitee/send-otp">Send the security code again</a> if you have
      not received it.
    </p>
  </div>
</div>
