<p>
  Email
  <a href="mailto:{{ supportEmail }}?subject={{ subjectLine }}">{{
    supportEmail
  }}</a>
  to {{ nextStep }}
</p>

<p>What to include in your email:</p>

<ul>
  <li>subject line: Apply for Care ID - {{ subjectLine }}</li>
  <li>your application ID ({{ invitationId }})</li>
  <li>this description: {{ description }}</li>
</ul>

<h2 class="nhsuk-heading-m">What happens next</h2>
