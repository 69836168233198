export enum DOBValidationResult {
  SUCCESS = "Success",
  ALL_EMPTY = "AllEmpty",
  ONLY_DAY_COMPLETE = "OnlyDayComplete",
  ONLY_MONTH_COMPLETE = "OnlyMonthComplete",
  ONLY_YEAR_COMPLETE = "OnlyYearComplete",
  ONLY_DAY_EMPTY = "OnlyDayEmpty",
  ONLY_MONTH_EMPTY = "OnlyMonthEmpty",
  ONLY_YEAR_EMPTY = "OnlyYearEmpty",
  FUTURE_DATE = "FutureDate",
  INVALID_DATE = "InvalidDate",
  YEAR_NOT_FOUR_DIGITS = "YearNotFourDigits",
  YEAR_TOO_FAR_PAST = "YearTooFarPast",
  AGE_100_OR_MORE = "Age100OrMore",
  AGE_LESS_THAN_14 = "AgeLessThan14",
}
