<h1 autofocus tabindex="-1">There was a problem displaying your application</h1>
<p>
  Go back to the Apply for Care ID invite email and try selecting the link
  again.
</p>
<email-support
  invitationId="{{ invitationId }}"
  supportEmail="{{ supportEmail }}"
  subjectLine="problem displaying application"
  nextStep="get support if the issue persists."
  description="A detailed description of where you were in your application and what happened before you encountered this error."
></email-support>
<p>
  If you can still access the link from your email, go through the application
  again, ensuring your browser does not close, refresh or timeout before you
  finish. If this does not work, the team managing your application will respond
  with instructions on how to proceed.
</p>
<feedback-link queryString="SessionIssue"></feedback-link>
