import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { SessionAuthenticationService } from "./services/session-authentication.service";
import { IdentityAuthRedirectService } from "./services/identity-auth-redirect.service";
import { AuthUtil } from "./utils/auth/auth";
import { map, Subscription, timer } from "rxjs";
import { RouterService } from "src/app/services/router.service";
import { SESSION_STORAGE, StorageService } from "ngx-webstorage-service";
import { FeedbackService } from "./services/feedback.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit, OnDestroy {
  displayLogout: boolean = false;
  isServiceManager: boolean = false;
  loggedInFullName: string;
  loggedInRoleName: string;
  loggedInRoleCode: string;
  loggedInOrgName: string;
  loggedInOrgCode: string;
  loggedInRoleCount: number;
  timerSubscription: Subscription;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    public sessionAuthenticationService: SessionAuthenticationService,
    private identityAuthRedirectService: IdentityAuthRedirectService,
    private authUtil: AuthUtil,
    private routerService: RouterService,
    private feedbackService: FeedbackService,
    @Inject(SESSION_STORAGE) private sessionStorage: StorageService,
  ) {}

  title = "Apply for Care ID";

  ngOnInit(): void {
    this.document.documentElement.lang = "en";
    this.isLoggedIn();
  }

  ngOnDestroy(): void {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  }

  getFeedbackLink(): string {
    return this.feedbackService.getFeedbackLink();
  }

  isLoggedIn(): void {
    this.timerSubscription = timer(0, 100)
      .pipe(
        map(() => {
          this.displayLogout =
            this.sessionAuthenticationService.hasValidSessionToken();
          this.isServiceManager =
            this.sessionStorage.get("sessionToken") &&
            this.sessionStorage.get("sessionToken").portal == 2;

          if (this.displayLogout) {
            this.loggedInFullName =
              this.sessionStorage.get("sessionToken").full_name;
            this.loggedInRoleName =
              this.sessionStorage.get("sessionToken").tertiary_role_name;
            this.loggedInRoleCode =
              this.sessionStorage.get("sessionToken").tertiary_role_code;
            this.loggedInOrgName =
              this.sessionStorage.get("sessionToken").org_name;
            this.loggedInOrgCode =
              this.sessionStorage.get("sessionToken").org_code;
            this.loggedInRoleCount =
              this.sessionStorage.get("sessionToken").role_count;
          }
        }),
      )
      .subscribe();
  }

  closeMenu(): void {
    let menuItems = document.getElementById("header-navigation");
    if (menuItems.style.display == "block") {
      menuItems.style.display = "none";
    }
  }

  toggleMenu(): void {
    let menuItems = document.getElementById("header-navigation");
    let menuButton = document.getElementById("toggle-menu");
    if (menuItems.style.display == "block") {
      menuItems.style.display = "none";
      menuButton.classList.remove("is-active");
    } else {
      menuItems.style.display = "block";
      menuButton.classList.add("is-active");
    }
  }

  onLogoClick(): void {
    if (this.displayLogout) {
      if (this.sessionStorage.get("sessionToken").portal == 2) {
        this.routerService.redirectToPortalSelect();
      } else {
        this.routerService.redirectToInvitationDashboard();
      }
    } else {
      this.routerService.redirectToStart();
    }
  }

  async onChangeRoleClick(): Promise<void> {
    try {
      const response =
        await this.identityAuthRedirectService.getIdentityChangeRoleUrl();

      let redirectUrl: string;
      let preauthId: string;
      if (response != undefined) {
        redirectUrl = response["redirect_url"];
        preauthId = response["preauth_id"];
        const currentUserUuid =
          this.sessionStorage.get("sessionToken").user_uuid;

        this.sessionStorage.set("preauthId", preauthId);
        this.sessionStorage.set("changeRoleUuid", currentUserUuid);
        this.authUtil.saveAuthStateInLocalStore(redirectUrl);
        this.document.location.href = redirectUrl;
      } else {
        this.routerService.redirectToInternalServerError();
      }
    } catch (err) {
      if (err.status === 401) {
        this.routerService.redirectToLoggedOut();
      } else {
        this.routerService.redirectToInternalServerError();
      }
    }
  }
}
