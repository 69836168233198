import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
@Component({
  selector: "app-otp-expired",
  templateUrl: "./otp-expired.component.html",
})
export class OtpExpiredComponent implements OnInit {
  constructor(private title: Title) {}

  ngOnInit(): void {
    this.title.setTitle("Security code has expired");
  }
}
