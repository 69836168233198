<div class="nhsuk-back-link nhsuk-u-margin-top-4 nhsuk-u-margin-bottom-0">
  <breadcrumb-link
    [breadcrumbRoute]="breadcrumbRoute"
    [breadcrumbText]="breadcrumbText"
  ></breadcrumb-link>
</div>
<div class="nhsuk-grid-row">
  <div class="nhsuk-grid-column-two-thirds">
    <div id="top"></div>
    <div
      class="nhsuk-error-summary"
      id="error-summary-title"
      *ngIf="errorMessage"
    >
      <h2 class="nhsuk-error-summary__title" id="error-summary-title">
        There is a problem
      </h2>
      <div class="nhsuk-error-summary__body">
        <p data-cy="error-message">{{ errorMessage }}</p>
      </div>
    </div>

    <h1 autofocus tabindex="-1" class="nhsuk-heading-xl">
      Upload a professional photo for your smartcard
    </h1>

    <photo-guidelines [displayExamples]="true"></photo-guidelines>

    <div
      [ngClass]="{
        'nhsuk-form-group--error': errorMessage
      }"
      class="nhsuk-form-group"
    >
      <h2>Upload your photo</h2>

      <span class="nhsuk-error-message" id="upload-error" *ngIf="errorMessage">
        <span class="nhsuk-u-visually-hidden">Error:</span> {{ errorMessage }}
      </span>

      <button
        id="photo-button"
        class="nhsuk-button nhsuk-u-margin-bottom-5"
        type="button"
        data-module="govuk-button"
        (click)="fileInput.click()"
      >
        Choose your photo
      </button>

      <label
        for="cameraFileInput"
        class="nhsuk-u-visually-hidden"
        tabindex="-1"
      >
        <span class="nhsuk-button">Choose your photo</span>
      </label>

      <input
        #fileInput
        type="file"
        id="cameraFileInput"
        name="cameraFileInput"
        aria-hidden="true"
        tabindex="-1"
        class="nhsuk-u-visually-hidden"
        accept="image/png,image/jpeg,image/jpg"
        (change)="onFileSelect($event)"
      />
      <div *ngIf="loading">
        <progress-indicator spinnerText="Processing file"></progress-indicator>
      </div>
    </div>

    <details class="nhsuk-details" id="no-photo">
      <summary class="nhsuk-details__summary">
        <span class="nhsuk-details__summary-text">
          I do not have a photo ready to upload
        </span>
      </summary>
      <div class="nhsuk-details__text">
        <p>
          You must take a photo and return to your application to upload it.
        </p>
        <p>
          If you cannot take a photo right now, you can use the link in your
          invite email to return to your application at any time.
        </p>
      </div>
    </details>
  </div>
</div>
