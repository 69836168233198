<session-check></session-check>
<a
  *ngIf="prevPage"
  [state]="{
    currentPage: currentPage,
    filteredInvitations: filteredInvitations,
    allInvitations: allInvitations,
    statuses: statuses,
    searchTerm: searchTerm,
    odsSearchTerm: odsSearchTerm,
    senderOnly: senderOnly,
    tabSelected: tabSelected,
    reviewedBySearchTerm: reviewedBySearchTerm
  }"
  [routerLink]="[prevPage]"
  class="nhsuk-back-link__link"
>
  <svg
    class="nhsuk-icon nhsuk-icon__chevron-left"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    aria-hidden="true"
    height="24"
    width="24"
  >
    <path
      d="M8.5 12c0-.3.1-.5.3-.7l5-5c.4-.4 1-.4 1.4 0s.4 1 0 1.4L10.9 12l4.3 4.3c.4.4.4 1 0 1.4s-1 .4-1.4 0l-5-5c-.2-.2-.3-.4-.3-.7z"
    ></path>
  </svg>
  Back
</a>

<div *ngIf="loading">
  <progress-indicator spinnerText="Loading application"></progress-indicator>
</div>
<div class="nhsuk-form-group">
  <span class="nhsuk-error-message" *ngIf="errorMessage">{{
    errorMessage
  }}</span>
</div>

<div *ngIf="invitation" class="nhsuk-grid-row">
  <div class="nhsuk-grid-column-full">
    <div
      class="nhsuk-alert nhsuk-alert--warning red-warning nhsuk-u-margin-bottom-7"
      *ngIf="invitation.status == 29"
      data-cy="warning-check-email-is-correct"
    >
      <h3 class="nhsuk-u-visually-hidden">Warning:</h3>
      <p class="nhsuk-u-font-weight-bold">
        Invite email could not be sent.
        <a
          [state]="invitation"
          [routerLink]="['/invitation/basic-info', invitation.invitationId]"
          data-cy="link-check-email-is-correct"
          >Check email address</a
        >
        is correct before resending.
      </p>
    </div>

    <div
      class="nhsuk-alert nhsuk-alert--warning red-warning nhsuk-u-margin-bottom-7"
      *ngIf="invitation.senderEmailBounced"
      data-cy="warning-check-sender-email-is-correct"
    >
      <h3 class="nhsuk-u-visually-hidden">Warning:</h3>
      <p class="nhsuk-u-font-weight-bold">
        Outcome email could not be sent.
        <a
          [state]="invitation"
          [routerLink]="['/invitation/basic-info', invitation.invitationId]"
          data-cy="link-check-sender-email-is-correct"
          >Check requester email address</a
        >
        is correct.
      </p>
    </div>

    <h1
      autofocus
      tabindex="-1"
      class="nhsuk-heading-xl nhsuk-u-margin-bottom-3"
    >
      Application: {{ invitation.givenName }}
      {{ invitation.familyName }}
    </h1>
    <h2
      class="nhsuk-body-l nhsuk-u-font-weight-bold grey-text nhsuk-u-margin-bottom-2"
      data-cy="status-header"
    >
      Status:
      {{ invitation.status | invitationStatusPipe }}
    </h2>
    <p class="grey-text nhsuk-u-margin-bottom-5">{{ applicationMessage }}</p>
    <div>
      <button
        *ngIf="invitation.status < canResendThresholdStatus"
        [state]="invitation"
        [routerLink]="['/invitation/basic-info', invitation.invitationId]"
        class="nhsuk-button nhsuk-u-margin-right-4"
        id="button-resend"
        data-cy="resend-invite"
        type="submit"
      >
        Resend invite email
      </button>
      <button
        data-cy="cancel-invite"
        *ngIf="
          invitation.status <= canCancelThresholdStatus ||
          invitation.status == 35
        "
        [state]="invitation"
        [routerLink]="['/invitation/cancel-invite', invitation.invitationId]"
        class="nhsuk-button nhsuk-button--secondary delete-button"
        id="button-cancel"
        type="submit"
      >
        Cancel application
      </button>
    </div>

    <div *ngIf="displayMarkDoneOptions">
      <p *ngIf="invitation.done">
        This application has been <strong>marked as done</strong>.
        <a class="nhsuk-link custom-link" tabindex="0" (click)="markDone(false)"
          >Undo</a
        >
      </p>

      <button
        *ngIf="invitation.done == undefined"
        class="nhsuk-button nhsuk-button--secondary nhsuk-u-margin-right-3"
        [disabled]="processingMarkAsDone"
        [ngClass]="{ 'spinner-button': processingMarkAsDone }"
        (click)="markDone(true)"
      >
        Mark as done
      </button>
    </div>

    <div class="nhsuk-card">
      <div class="nhsuk-card__content">
        <h3 class="nhsuk-card__heading">Application summary</h3>
        <dl class="nhsuk-summary-list">
          <div class="nhsuk-summary-list__row">
            <dt class="nhsuk-summary-list__key">Application number</dt>
            <dd data-cy="invitation-id" class="nhsuk-summary-list__value">
              {{ invitation.invitationId }}
            </dd>
          </div>
          <div class="nhsuk-summary-list__row">
            <dt class="nhsuk-summary-list__key">Raised by</dt>
            <dd class="nhsuk-summary-list__value">
              {{ invitation.createdByUserName }} on {{ createdDateUtc }}
            </dd>
          </div>

          <div *ngIf="isServiceManager" class="nhsuk-summary-list__row">
            <dt class="nhsuk-summary-list__key">Requester email address</dt>
            <dd class="nhsuk-summary-list__value">
              {{ invitation.senderEmail }}
            </dd>
          </div>

          <div class="nhsuk-summary-list__row">
            <dt class="nhsuk-summary-list__key">ODS code</dt>
            <dd class="nhsuk-summary-list__value">
              {{ invitation.createdByUserOrgCode }}
            </dd>
          </div>

          <div *ngIf="invitation.status != 38" class="nhsuk-summary-list__row">
            <dt class="nhsuk-summary-list__key">Last updated</dt>
            <dd class="nhsuk-summary-list__value">
              {{ statusLastUpdatedDateUtc }}
            </dd>
          </div>

          <div *ngIf="invitation.niNumber" class="nhsuk-summary-list__row">
            <dt class="nhsuk-summary-list__key">NI Number</dt>
            <dd class="nhsuk-summary-list__value">
              {{ invitation.niNumber }}
            </dd>
          </div>

          <div *ngIf="invitation.dateOfBirth" class="nhsuk-summary-list__row">
            <dt class="nhsuk-summary-list__key">Date of birth</dt>
            <dd class="nhsuk-summary-list__value">
              {{ invitation.dateOfBirth | date: "dd/MM/yyyy" }}
            </dd>
          </div>
          <div
            *ngIf="invitation.xydusInvitationUrl"
            class="nhsuk-summary-list__row"
          >
            <dt class="nhsuk-summary-list__key">App download URL</dt>
            <dd class="nhsuk-summary-list__value">
              {{ invitation.xydusInvitationUrl }}
            </dd>
          </div>
          <div
            *ngIf="idCheckedUtc && invitation.status == 53"
            class="nhsuk-summary-list__row"
          >
            <dt class="nhsuk-summary-list__key">Rejected on</dt>
            <dd class="nhsuk-summary-list__value">
              {{ idCheckedUtc }}
            </dd>
          </div>
          <div
            *ngIf="
              invitation.failedReason &&
              invitation.failedReason.length != 0 &&
              invitation.status == 53
            "
            class="nhsuk-summary-list__row"
          >
            <dt class="nhsuk-summary-list__key">Rejection reason(s)</dt>
            <dd class="nhsuk-summary-list__value">
              <p *ngIf="invitation.status == 37">
                Application expired. Applicant has not submitted ID documents
                within 15 days of receiving link.
              </p>
              <p *ngIf="invitation.failedDocument">
                {{ invitation.failedDocument }}
              </p>
              <p
                *ngIf="invitation.failedReason"
                [innerHTML]="
                  invitation.failedReason
                    | IdFailureCheckReasonParagraphPipe
                      : invitation.photoIdOneType
                      : invitation.photoIdTwoType
                      : true
                "
              ></p>
            </dd>
          </div>
          <div
            *ngIf="invitation.failedNotes && invitation.status == 53"
            class="nhsuk-summary-list__row"
          >
            <dt class="nhsuk-summary-list__key">Rejection Notes</dt>
            <dd class="nhsuk-summary-list__value">
              {{ invitation.failedNotes }}
            </dd>
          </div>
          <div
            *ngIf="
              invitation.cisDuplicateMatch != undefined &&
              invitation.status == 34
            "
            class="nhsuk-summary-list__row"
          >
            <dt class="nhsuk-summary-list__key">CIS duplicate match</dt>
            <dd class="nhsuk-summary-list__value">
              <cim-link uuid="{{ invitation.cisDuplicateMatch }}"></cim-link>
            </dd>
          </div>
          <div
            *ngIf="
              invitation.cancelledDateUtc != undefined &&
              invitation.status == 38
            "
            class="nhsuk-summary-list__row"
          >
            <dt class="nhsuk-summary-list__key">Cancelled on</dt>
            <dd class="nhsuk-summary-list__value">
              {{ invitation.cancelledDateUtc | date: "d MMM yyyy" }}
            </dd>
          </div>
          <div
            *ngIf="
              invitation.cancelledReason != undefined && invitation.status == 38
            "
            class="nhsuk-summary-list__row"
          >
            <dt class="nhsuk-summary-list__key">Reason for cancelling</dt>
            <dd class="nhsuk-summary-list__value">
              <p *ngIf="invitation.cancelledReason != 3">
                {{ invitation.cancelledReason | CancelledReasonPipe }}
              </p>
              <p *ngIf="invitation.cancelledReason == 3">
                Other; {{ invitation.cancelledReasonNotes }}
              </p>
            </dd>
          </div>
          <div
            *ngIf="completedDateUtc && invitation.status == 52"
            class="nhsuk-summary-list__row"
          >
            <dt class="nhsuk-summary-list__key">Approved on</dt>
            <dd class="nhsuk-summary-list__value">
              {{ completedDateUtc }}
            </dd>
          </div>
          <div *ngIf="invitation.cisUuid" class="nhsuk-summary-list__row">
            <dt class="nhsuk-summary-list__key">UUID</dt>
            <dd class="nhsuk-summary-list__value">
              <cim-link uuid="{{ invitation.cisUuid }}"></cim-link>
            </dd>
          </div>
          <div *ngIf="invitation.approvalNote" class="nhsuk-summary-list__row">
            <dt class="nhsuk-summary-list__key">Notes</dt>
            <dd class="nhsuk-summary-list__value">
              {{ invitation.approvalNote }}
            </dd>
          </div>

          <div *ngIf="invitation.resentCount" class="nhsuk-summary-list__row">
            <dt class="nhsuk-summary-list__key">Invite emails sent</dt>
            <dd data-cy="resent-count" class="nhsuk-summary-list__value">
              {{ invitation.resentCount }}
            </dd>
          </div>
          <div
            *ngIf="mobileAppReminders != null"
            class="nhsuk-summary-list__row"
          >
            <dt class="nhsuk-summary-list__key">Mobile app reminders sent</dt>
            <dd data-cy="resent-count" class="nhsuk-summary-list__value">
              {{ mobileAppReminders }}
            </dd>
          </div>
        </dl>
      </div>
    </div>
    <div class="nhsuk-card">
      <div class="nhsuk-card__content">
        <h3 class="nhsuk-card__heading">Applicant details</h3>

        <dl class="nhsuk-summary-list">
          <div class="nhsuk-summary-list__row">
            <dt id="name" class="nhsuk-summary-list__key">Full name</dt>
            <dd class="nhsuk-summary-list__value">
              {{ invitation.givenName }} {{ invitation.familyName }}
            </dd>
            <dd class="nhsuk-summary-list__actions">
              <a
                aria-describedby="name"
                *ngIf="invitation.status < canResendThresholdStatus"
                class="change-link"
                [state]="invitation"
                [routerLink]="[
                  '/invitation/basic-info',
                  invitation.invitationId
                ]"
              >
                Change
              </a>
            </dd>
          </div>
          <div class="nhsuk-summary-list__row">
            <dt id="email" class="nhsuk-summary-list__key">Email address</dt>
            <dd class="nhsuk-summary-list__value">
              {{ invitation.email }}
            </dd>
            <dd class="nhsuk-summary-list__actions">
              <a
                aria-describedby="email"
                *ngIf="invitation.status < canResendThresholdStatus"
                class="change-link"
                [state]="invitation"
                [routerLink]="[
                  '/invitation/basic-info',
                  invitation.invitationId
                ]"
              >
                Change
              </a>
            </dd>
          </div>
          <div class="nhsuk-summary-list__row">
            <dt id="mobile" class="nhsuk-summary-list__key">Mobile number</dt>
            <dd class="nhsuk-summary-list__value">
              {{ invitation.mobile }}
            </dd>
            <dd class="nhsuk-summary-list__actions">
              <a
                aria-describedby="mobile"
                *ngIf="invitation.status < canResendThresholdStatus"
                class="change-link"
                [state]="invitation"
                [routerLink]="[
                  '/invitation/basic-info',
                  invitation.invitationId
                ]"
              >
                Change
              </a>
            </dd>
          </div>
          <div
            *ngIf="invitation.status == 52 && invitation.address"
            class="nhsuk-summary-list__row"
          >
            <dt class="nhsuk-summary-list__key">Delivery address</dt>
            <dd class="nhsuk-summary-list__value">
              {{ invitation.address }}
            </dd>
          </div>
          <div class="nhsuk-summary-list__row">
            <dt id="notes" class="nhsuk-summary-list__key">Notes</dt>
            <dd class="nhsuk-summary-list__value">
              {{ invitation.paa }}
            </dd>
            <dd class="nhsuk-summary-list__actions">
              <a
                aria-describedby="notes"
                *ngIf="invitation.status < canResendThresholdStatus"
                class="change-link"
                [state]="invitation"
                [routerLink]="[
                  '/invitation/basic-info',
                  invitation.invitationId
                ]"
              >
                Change
              </a>
            </dd>
          </div>
        </dl>
      </div>
    </div>

    <div class="nhsuk-card" *ngIf="invitation.status != 38">
      <div class="nhsuk-card__content">
        <h3 class="nhsuk-card__heading">Application steps</h3>
        <dl class="nhsuk-summary-list">
          <div class="nhsuk-summary-list__row">
            <dt class="nhsuk-summary-list__key">Enter personal details</dt>
            <dd class="nhsuk-summary-list__actions">
              <strong
                [ngClass]="
                  enterPersonalDetailsStatus | applicationStepStatusClassPipe
                "
                class="nhsuk-tag"
              >
                {{ enterPersonalDetailsStatus | applicationStepStatusPipe }}
              </strong>
            </dd>
          </div>
          <div class="nhsuk-summary-list__row">
            <dt class="nhsuk-summary-list__key">Submit ID documents</dt>
            <dd class="nhsuk-summary-list__actions">
              <strong
                [ngClass]="submitIdDocsStatus | applicationStepStatusClassPipe"
                class="nhsuk-tag"
              >
                {{ submitIdDocsStatus | applicationStepStatusPipe }}
              </strong>
            </dd>
          </div>
          <div class="nhsuk-summary-list__row">
            <dt class="nhsuk-summary-list__key">Review by ID checker</dt>
            <dd class="nhsuk-summary-list__actions">
              <strong
                [ngClass]="
                  reviewByIdCheckerStatus | applicationStepStatusClassPipe
                "
                class="nhsuk-tag"
              >
                {{ reviewByIdCheckerStatus | applicationStepStatusPipe }}
              </strong>
            </dd>
          </div>
        </dl>
      </div>
    </div>
    <details class="nhsuk-details" *ngIf="invitation.status != 38">
      <summary class="nhsuk-details__summary">
        <span class="nhsuk-details__summary-text">
          How long does an applicant have to complete their application?
        </span>
      </summary>
      <div class="nhsuk-details__text">
        <p>Applicants must:</p>
        <ul>
          <li>
            complete the duplicate check within <strong>4 days</strong> of
            receiving the invite email
          </li>
          <li>
            submit their ID documents within <strong>15 days</strong> of
            receiving a link to download the app
          </li>
        </ul>
        <p>
          Reminders to submit their ID documents are sent via text and email 2
          days and 7 days after receiving the link to download the app.
        </p>
      </div>
    </details>
  </div>
</div>
