import { ValidationResponse } from "./../../models/validation-response";
import { Injectable } from "@angular/core";
import { DatePipe } from "@angular/common";
import { isValid, parse } from "date-fns";
import { IdCheckerDateValidationResult } from "../../models/id-checker-date-validation-result";
import { DateService } from "./date.service";

@Injectable()
export class IdCheckerDateService {
  static readonly errorMessages = {
    AllEmpty: "Enter a date of issue.",
    OnlyDayComplete: "Date of issue must include a month and year.",
    OnlyMonthComplete: "Date of issue must include a day and year.",
    OnlyYearComplete: "Date of issue must include a day and month.",
    OnlyDayEmpty: "Date of issue must include a day.",
    OnlyMonthEmpty: "Date of issue must include a month.",
    OnlyYearEmpty: "Date of issue must include a year.",
    InvalidDate: "Date of issue must be a real date.",
    YearNotFourDigits: "Year must include 4 numbers.",
    FutureDate: "Date of issue must be today or in the past.",
  };

  constructor(private datePipe: DatePipe) {}

  static getIdCheckerDateValidationResult(
    day: string = "",
    month: string = "",
    year: string = "",
  ): ValidationResponse {
    const validationResponse = new ValidationResponse();

    if (this.isDateEmpty(day, month, year)) {
      validationResponse.result = IdCheckerDateValidationResult.ALL_EMPTY;
      validationResponse.errorSummary = validationResponse.fieldError =
        this.errorMessages.AllEmpty;
    } else if (this.isOnlyDayComplete(day, month, year)) {
      validationResponse.result =
        IdCheckerDateValidationResult.ONLY_DAY_COMPLETE;
      validationResponse.errorSummary = validationResponse.fieldError =
        this.errorMessages.OnlyDayComplete;
    } else if (this.isOnlyMonthComplete(day, month, year)) {
      validationResponse.result =
        IdCheckerDateValidationResult.ONLY_MONTH_COMPLETE;
      validationResponse.errorSummary = validationResponse.fieldError =
        this.errorMessages.OnlyMonthComplete;
    } else if (this.isOnlyYearComplete(day, month, year)) {
      validationResponse.result =
        IdCheckerDateValidationResult.ONLY_YEAR_COMPLETE;
      validationResponse.errorSummary = validationResponse.fieldError =
        this.errorMessages.OnlyYearComplete;
    } else if (this.isOnlyDayEmpty(day, month, year)) {
      validationResponse.result = IdCheckerDateValidationResult.ONLY_DAY_EMPTY;
      validationResponse.errorSummary = validationResponse.fieldError =
        this.errorMessages.OnlyDayEmpty;
    } else if (this.isOnlyMonthEmpty(day, month, year)) {
      validationResponse.result =
        IdCheckerDateValidationResult.ONLY_MONTH_EMPTY;
      validationResponse.errorSummary = validationResponse.fieldError =
        this.errorMessages.OnlyMonthEmpty;
    } else if (this.isOnlyYearEmpty(day, month, year)) {
      validationResponse.result = IdCheckerDateValidationResult.ONLY_YEAR_EMPTY;
      validationResponse.errorSummary = validationResponse.fieldError =
        this.errorMessages.OnlyYearEmpty;
    } else if (this.isInvalidDate(day, month, year)) {
      validationResponse.result = IdCheckerDateValidationResult.INVALID_DATE;
      validationResponse.errorSummary = validationResponse.fieldError =
        this.errorMessages.InvalidDate;
    } else if (this.isYearNotFourDigits(year)) {
      validationResponse.result =
        IdCheckerDateValidationResult.YEAR_NOT_FOUR_DIGITS;
      validationResponse.errorSummary = validationResponse.fieldError =
        this.errorMessages.YearNotFourDigits;
    } else if (DateService.isFutureDate(day, month, year)) {
      validationResponse.result = IdCheckerDateValidationResult.FUTURE_DATE;
      validationResponse.errorSummary = validationResponse.fieldError =
        this.errorMessages.FutureDate;
    } else {
      validationResponse.result = IdCheckerDateValidationResult.SUCCESS;
      validationResponse.sanitisedInput = this.formatDate(day, month, year);
    }

    return validationResponse;
  }

  static isDateEmpty(
    day: string = "",
    month: string = "",
    year: string = "",
  ): boolean {
    return !day && !month && !year;
  }

  static isOnlyDayComplete(
    day: string = "",
    month: string = "",
    year: string = "",
  ): boolean {
    return day !== null && day.length > 0 && !month && !year;
  }

  static isOnlyMonthComplete(
    day: string = "",
    month: string = "",
    year: string = "",
  ): boolean {
    return month !== null && month.length > 0 && !day && !year;
  }

  static isOnlyYearComplete(
    day: string = "",
    month: string = "",
    year: string = "",
  ): boolean {
    return year !== null && year.length > 0 && !day && !month;
  }

  static isOnlyDayEmpty(
    day: string = "",
    month: string = "",
    year: string = "",
  ): boolean {
    return (
      month !== null &&
      year !== null &&
      month.length > 0 &&
      year.length > 0 &&
      !day
    );
  }

  static isOnlyMonthEmpty(
    day: string = "",
    month: string = "",
    year: string = "",
  ): boolean {
    return (
      day !== null &&
      year !== null &&
      day.length > 0 &&
      year.length > 0 &&
      !month
    );
  }

  static isOnlyYearEmpty(
    day: string = "",
    month: string = "",
    year: string = "",
  ): boolean {
    return (
      day !== null &&
      month !== null &&
      day.length > 0 &&
      month.length > 0 &&
      !year
    );
  }

  static isInvalidDate(day: string, month: string, year: string): boolean {
    const isValidDate = isValid(
      parse(`${day}-${month}-${year}`, "d-M-yyyy", new Date()),
    );
    return !isValidDate;
  }

  static isYearNotFourDigits(year: string): boolean {
    const fourDigitRegex = /^\d{4}$/;
    return !fourDigitRegex.test(year);
  }

  // transforms date into standard shortDate yyyy-MM-dd format e.g. 1972-04-01
  static formatDate(day: string, mo: string, yr: string): string {
    const tempDate = `${yr}-${mo}-${day}`;
    const datePipe = new DatePipe("en-US");
    return datePipe.transform(tempDate, "yyyy-MM-dd");
  }

  static formatDateToHumanReadable(dob: string): string {
    const datePipe = new DatePipe("en-US");
    return datePipe.transform(dob, "dd MMM yyyy");
  }

  // transforms date into standard shortDate yyyy-MM-dd format e.g. 1972-04-01
  /**
   * @deprecated We should no longer be using this method. Please try and avoid dependency injection and
   * use formatDate() where possible.
   */
  transformDoB(day: string, mo: string, yr: string): string {
    const tempDate = `${yr}-${mo}-${day}`;
    return this.datePipe.transform(tempDate, "yyyy-MM-dd");
  }

  /**
   * @deprecated We should no longer be using this method. Please use isValidDate() where possible
   */
  isValidDate(day: string, month: string, year: string): boolean {
    const isValidDate = isValid(
      parse(`${day}-${month}-${year}`, "d-M-yyyy", new Date()),
    );

    const yearInRange =
      parseInt(year, 10) >= 1900 &&
      parseInt(year, 10) <= new Date().getFullYear();

    return yearInRange && isValidDate;
  }
}
