import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "checkbox-input",
  templateUrl: "./checkbox.component.html",
  styleUrls: ["./checkbox.component.scss"],
})
export class CheckboxComponent implements OnInit {
  @Input() id: string;
  @Input() name: string = "checkbox-name";
  @Input() value: boolean;
  @Input() requiredAnswer: boolean;
  @Input() checkboxText: string;
  @Input() hintText: string;
  @Input() errorMessage: string;
  @Input() cypressValue: string;
  @Input() exlusiveGroupName: string = "checkbox-list";
  @Input() exclusive: boolean = false;

  @Output() questionAnswer = new EventEmitter<boolean>();

  isChecked = false;
  isAnswerValid = false;
  errorMessageDataCy: string;

  ngOnInit(): void {
    this.isChecked = this.value;
    this.errorMessageDataCy = "error-message-" + this.cypressValue;
  }

  updateValue(): void {
    this.isChecked = !this.isChecked;
    this.setQuestionAnswer();
  }

  setQuestionAnswer(): void {
    this.questionAnswer.emit(this.isChecked);
  }
}
