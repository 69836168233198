import { Component, Inject, OnInit } from "@angular/core";
import { IdentityAuthRedirectService } from "../../services/identity-auth-redirect.service";
import { SiteService } from "../../services/site.service";
import { DOCUMENT } from "@angular/common";
import { Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { AuthUtil } from "../../utils/auth/auth";
import { Title } from "@angular/platform-browser";
import { SESSION_STORAGE, StorageService } from "ngx-webstorage-service";

@Component({
  selector: "app-authenticate",
  templateUrl: "./authenticate.component.html",
})
export class AuthenticateComponent implements OnInit {
  identityLoginButtonClicked = false;
  identityLoginStatus: string;
  successfulLogout = false;
  displayServiceBanner = false;
  state: string;
  route: string;

  constructor(
    private identityAuthRedirectService: IdentityAuthRedirectService,
    private siteService: SiteService,
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private cookieService: CookieService,
    private title: Title,
    private authUtil: AuthUtil,
    @Inject(SESSION_STORAGE) private sessionStorage: StorageService,
  ) {
    this.route = router.url;
  }

  ngOnInit(): void {
    this.title.setTitle("Apply for Care ID - Log in");
    this.checkServiceAvailabilityMode();
    const sessionToken = this.sessionStorage.get("sessionToken");

    if (sessionToken != undefined) {
      this.cookieService.delete(
        "fastapi-csrf-token-" + sessionToken.session_id,
        "/",
        window.location.hostname,
      );
    }
    window.sessionStorage.clear();
    this.displayAuthenticationMessages();
  }

  checkServiceAvailabilityMode(): void {
    this.siteService.checkServiceAvailability().subscribe({
      next: (response) => {
        let serviceStatus = response.body["service_status"];
        if (serviceStatus == "admin_mode") {
          this.displayServiceBanner = true;
        }
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  displayAuthenticationMessages(): void {
    if (this.route.indexOf("/no-auth") > -1) {
      this.identityLoginStatus = "NoAuth";
    } else if (this.route.indexOf("/logged-out") > -1) {
      this.identityLoginStatus = "LoggedOut";
    } else if (this.route.indexOf("/unknown") > -1) {
      this.identityLoginStatus = "Unknown";
    } else if (this.route.indexOf("/user-logged-out") > -1) {
      this.successfulLogout = true;
    } else if (this.route.indexOf("/service-unavailable") > -1) {
      this.identityLoginStatus = "Unavailable";
    }
  }

  async onIdentityLoginButtonClick() {
    this.identityLoginButtonClicked = true;

    this.identityAuthRedirectService.getIdentityAuthRedirectUrl().subscribe({
      next: (response) => {
        let redirectUrl: string;
        let preauthId: string;
        if (response.body != undefined) {
          redirectUrl = response.body["redirect_url"];
          preauthId = response.body["preauth_id"];

          this.sessionStorage.set("preauthId", preauthId);
          this.authUtil.saveAuthStateInLocalStore(redirectUrl);
          this.onRedirectURLReturned(redirectUrl);
        } else {
          this.onErrorResponse();
        }
      },
      error: () => {
        this.onErrorResponse();
      },
    });
  }

  onErrorResponse(): void {
    this.identityLoginStatus = "Unknown";
  }

  onRedirectURLReturned(redirectUrl: string): void {
    this.document.location.href = redirectUrl;
  }
}
