import { Component, OnInit, Inject } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { RouterService } from "src/app/services/router.service";
import { VerifyInvitationService } from "../../../services/verify-invitation.service";
import { SESSION_STORAGE, StorageService } from "ngx-webstorage-service";
import { StorageMap } from "@ngx-pwa/local-storage";
import { Title } from "@angular/platform-browser";
import { InviteeDetails } from "src/app/utils/invitee-details";

@Component({
  selector: "app-invitation-accept",
  templateUrl: "./accept-invitation.component.html",
})
export class AcceptInvitationComponent implements OnInit {
  invitation_id: string;
  invitation_status: string;
  captureDeliveryAddress: boolean;
  mobileLast4: string;
  expiry: string;
  supportEmail: string;

  constructor(
    private routerService: RouterService,
    private route: ActivatedRoute,
    private inviteVerificationService: VerifyInvitationService,
    @Inject(SESSION_STORAGE) private sessionStorage: StorageService,
    private storage: StorageMap,
    private title: Title,
    private inviteeDetails: InviteeDetails,
  ) {}

  ngOnInit(): void {
    this.title.setTitle("Checking your link");
    this.route.queryParams.subscribe((params) => {
      this.inviteeDetails.delete();
      this.storage.delete("invitationConfirmation").subscribe(() => {});
      this.invitation_id = params.invitation;
    });

    this.validateInvite();
  }

  validateInvite(): void {
    if (!this.invitation_id) {
      this.routerService.redirectToApplicationIsInvalid();
      return;
    }

    this.inviteVerificationService
      .postInvitationVerification(this.invitation_id)
      .subscribe({
        next: (response) => {
          const statusString = response["status"].toString();
          this.supportEmail = response["support_email"];
          if (statusString == "DuplicateCheckInProgress") {
            this.captureDeliveryAddress = response["capture_delivery_address"];
            this.mobileLast4 = response["mobile_last_4"];
            this.expiry = response["expiry"];
            this.persistInvitationDetails();
            this.routerService.redirectToInviteeStart();
          } else {
            this.sessionStorage.set("supportEmail", this.supportEmail);
            this.handleOtherStatuses(statusString);
          }
        },
        error: (error) => {
          this.setSupportEmail(error.error["support_email"]);
          if (error.error.status == "ServiceUnavailable") {
            this.routerService.redirectToApplicantMaintenance();
          } else {
            this.routerService.redirectToInternalServerError();
          }
        },
      });
  }

  handleOtherStatuses(statusString: string): void {
    if (statusString == "Invalid") {
      this.routerService.redirectToApplicationIsInvalid();
    } else if (statusString == "Expired") {
      this.routerService.redirectToApplicationIsExpired();
    } else if (statusString == "Cancelled") {
      this.routerService.redirectToApplicationIsCancelled();
    } else if (statusString == "AwaitingDocuments") {
      this.routerService.redirectToAlreadySubmitted();
    } else if (statusString == "IdReview" || statusString == "OnHold") {
      this.routerService.redirectToApplicationIsInReview();
    } else if (statusString == "Approved") {
      this.routerService.redirectToApplicationIsApproved();
    } else if (statusString == "Rejected") {
      this.routerService.redirectToApplicationIsRejected();
    } else if (statusString == "DuplicateInformation") {
      this.routerService.redirectToDupFailed();
    } else if (statusString == "Locked") {
      this.routerService.redirectToOtpLocked();
    } else if (statusString == "AppLinkExpired") {
      this.routerService.redirectToApplicationIsAppLinkExpired();
    } else if (statusString == "ServiceUnavailable") {
      this.routerService.redirectToApplicantMaintenance();
    } else {
      this.invitation_status = "ConnectionProblem";
    }
  }

  setSupportEmail(email: string) {
    if (email == undefined || email == "") {
      this.supportEmail = "ssd.nationalservicedesk@nhs.net";
    } else {
      this.supportEmail = email;
    }
    this.sessionStorage.set("supportEmail", this.supportEmail);
  }

  persistInvitationDetails(): void {
    this.sessionStorage.set("invitationId", this.invitation_id);
    this.sessionStorage.set("mobile", this.mobileLast4);
    this.sessionStorage.set("expiry", this.expiry);
    this.sessionStorage.set(
      "captureDeliveryAddress",
      this.captureDeliveryAddress,
    );
    this.sessionStorage.set("supportEmail", this.supportEmail);
  }
}
