import { Component, Inject, OnInit, OnDestroy } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { SESSION_STORAGE, StorageService } from "ngx-webstorage-service";
import { InviteeDetails } from "src/app/utils/invitee-details";
import { FeedbackService } from "src/app/services/feedback.service";

@Component({
  selector: "app-no-continue",
  templateUrl: "./no-continue.component.html",
})
export class NoContinueComponent implements OnInit, OnDestroy {
  breadcrumbRoute = "";
  breadcrumbText = "Back";
  h1text = "";
  reason = "";
  supportEmail = "";
  invitationId = "";
  displayFeedbackLink = false;
  queryString: string = undefined;

  constructor(
    private title: Title,
    private route: ActivatedRoute,
    @Inject(SESSION_STORAGE) private sessionStorage: StorageService,
    private feedbackService: FeedbackService,
    private inviteeDetails: InviteeDetails,
  ) {}

  ngOnInit(): void {
    this.reason = this.route.snapshot.paramMap.get("reason");
    this.supportEmail = this.sessionStorage.get("supportEmail");
    this.invitationId = this.sessionStorage.get("invitationId");
    this.setHeader();
    this.title.setTitle(this.h1text);
  }

  setHeader(): void {
    switch (this.reason) {
      case "no-ni": {
        this.breadcrumbRoute = "/invitee/confirm-ni-number";
        this.displayFeedbackLink = true;
        this.h1text = "I do not have a National Insurance Number";
        this.updateQueryString("NoNino");
        break;
      }
      case "dup-failed": {
        this.h1text = "You may already have an NHS Care ID profile";
        this.displayFeedbackLink = true;
        this.updateQueryString("DupFound");
        break;
      }
      case "no-poa": {
        this.breadcrumbRoute = "/invitee/poa";
        this.displayFeedbackLink = true;
        this.h1text = "I do not have a proof of address document";
        this.updateQueryString("NoPoa");
        break;
      }
      case "no-photo-id": {
        if (this.inviteeDetails.getInviteeDetails().poaType == "") {
          this.breadcrumbRoute = "/invitee/photo-id-one";
        } else {
          this.breadcrumbRoute = "/invitee/photo-id-two";
        }

        this.displayFeedbackLink = true;
        this.h1text = "I do not have a photo ID";
        this.updateQueryString("NoID");
        break;
      }
      case "already-submitted": {
        this.h1text = "Upload your ID documents";
        this.displayFeedbackLink = true;
        this.updateQueryString("InAwaitingDocs");
        break;
      }
      case "application-id-review": {
        this.h1text = "Your NHS Care ID application has been submitted";
        this.updateQueryString("AlreadySubmitted");
        this.displayFeedbackLink = true;
        break;
      }
      case "application-approved": {
        this.h1text = "Your NHS Care ID application has been approved";
        this.displayFeedbackLink = true;
        this.updateQueryString("AlreadyApproved");
        break;
      }
      case "application-rejected": {
        this.h1text = "There is a problem with your NHS Care ID application";
        this.displayFeedbackLink = true;
        this.updateQueryString("AlreadyRejected");
        break;
      }
      case "application-cancelled": {
        this.h1text = "Your NHS Care ID application has been cancelled";
        this.displayFeedbackLink = true;
        this.updateQueryString("Cancelled");
        break;
      }
      case "application-invalid": {
        this.h1text = "This link is incorrect";
        break;
      }
      case "application-expired": {
        this.h1text = "This link has expired";
        this.displayFeedbackLink = true;
        this.updateQueryString("InviteExpired");
        break;
      }
      case "application-app-link-expired": {
        this.h1text = "The link to download the app has expired";
        this.displayFeedbackLink = true;
        this.updateQueryString("PyiLinkExpired");
        break;
      }
      case "applicant-maintenance": {
        this.h1text = "Service unavailable";
        break;
      }
      case "no-mobile": {
        this.breadcrumbRoute = "/invitee/start";
        this.displayFeedbackLink = true;
        this.h1text =
          "I cannot use a mobile phone to take photos and scan my face";
        this.updateQueryString("CannotTakePhotos");
        break;
      }
      case "names-not-match": {
        this.breadcrumbRoute = "/invitee/confirm-name";
        this.displayFeedbackLink = true;
        this.h1text = "My name does not match my photo IDs";
        this.updateQueryString("NameDoesNotMatch");
        break;
      }
      default: {
        this.h1text = "";
        break;
      }
    }
  }

  updateQueryString(qStr: string) {
    this.queryString = qStr;
    this.feedbackService.setQueryString(qStr);
  }

  ngOnDestroy(): void {
    this.feedbackService.setQueryString(undefined);
  }
}
