<session-check></session-check>
<div class="nhsuk-grid-row">
  <div class="nhsuk-grid-column-two-thirds">
    <breadcrumb-link
      [breadcrumbRoute]="breadcrumbRoute"
      [breadcrumbText]="breadcrumbText"
    ></breadcrumb-link>
    <error-summary
      [errorSummaryMessages]="summaryErrorMessages"
    ></error-summary>
    <fieldset class="nhsuk-fieldset">
      <legend class="nhsuk-fieldset__legend nhsuk-fieldset__legend--xl">
        <h1 class="nhsuk-fieldset__heading">Delete invitation</h1>
      </legend>
      <div class="nhsuk-hint">This tool is used to delete an invitation.</div>
      <div id="lookup_field"></div>
      <div class="nhsuk-form-group">
        <label class="nhsuk-label" for="address-postcode">
          Invitation Id
        </label>
        <text-input
          #invitationIdInput
          [id]="'invitation-id'"
          [errorMessage]="invitationIdErrorMessage"
          [value]="invitationId"
          [uppercase]="false"
          (questionAnswer)="updateInvitationIdAnswer($event)"
          size="nhsuk-input--width-10"
          [cypressValue]="'invitation-id'"
        ></text-input>
      </div>
      <div class="nhsuk-form-group">
        <select-dropdown
          size="nhsuk-u-width-one-quarter"
          #deleteReasonInput
          id="delete-option-input"
          question="Select a reason"
          [errorMessage]="deleteReasonErrorMessage"
          [answers]="deleteReasonOptions"
          [selectedAnswer]="deleteReason"
          (questionAnswer)="updateDeleteReasonAnswer($event)"
        ></select-dropdown>
      </div>
      <div class="nhsuk-form-group">
        <label class="nhsuk-label" for="address-postcode"> Details </label>
        <text-area-input
          #deleteMessageInput
          [cypressValue]="'delete-message'"
          id="delete-message"
          [value]="deleteMessage"
          (questionAnswer)="updateDeleteMessageAnswer($event)"
        >
        </text-area-input>
      </div>
    </fieldset>
    <button
      [disabled]="loading"
      class="nhsuk-button nhsuk-u-margin-bottom-5"
      (click)="submitAnswer()"
      id="continue"
    >
      Delete invitation
    </button>
  </div>
</div>
<div class="nhsuk-grid-row">
  <section *ngIf="loading">
    <progress-indicator spinnerText="Deleting application"></progress-indicator>
  </section>
  <section *ngIf="!loading">
    <div
      class="nhsuk-alert nhsuk-alert--warning nhsuk-alert--icon nhsuk-u-margin-top-4"
      *ngIf="responseMessage.length > 0"
    >
      <svg
        class="nhsuk-icon nhsuk-icon__warning nhsuk-alert__icon"
        fill="none"
        aria-hidden="true"
        viewBox="0 0 512 512"
        style="enable-background: new 0 0 512 512"
      >
        <g>
          <g>
            <path
              d="M501.609,384.603L320.543,51.265c-13.666-23.006-37.802-36.746-64.562-36.746c-26.76,0-50.896,13.74-64.562,36.746
              c-0.103,0.176-0.19,0.352-0.293,0.528L10.662,384.076c-13.959,23.491-14.223,51.702-0.719,75.457
              c13.535,23.769,37.919,37.948,65.266,37.948h360.544c27.347,0,52.733-14.179,66.267-37.948
              C515.524,435.779,515.261,407.566,501.609,384.603z M225.951,167.148c0-16.586,13.445-30.03,30.03-30.03
              c16.586,0,30.03,13.445,30.03,30.03v120.121c0,16.584-13.445,30.03-30.03,30.03s-30.03-13.447-30.03-30.03V167.148z
              M255.981,437.421c-24.839,0-45.046-20.206-45.046-45.046c0-24.839,20.206-45.045,45.046-45.045
              c24.839,0,45.045,20.206,45.045,45.045C301.027,417.214,280.821,437.421,255.981,437.421z"
            ></path>
          </g>
        </g>
      </svg>
      <p class="nhsuk-u-font-weight-bold">{{ responseMessage }}</p>
    </div>

    <div *ngIf="!invitationDeleted && invitationDeleted !== null">
      <p>Failed to delete invitation with invitation ID.</p>
    </div>
  </section>
</div>
