<session-check></session-check>
<div>
  <div class="nhsuk-grid-row">
    <section *ngIf="loading">
      <progress-indicator spinnerText="please wait"></progress-indicator>
    </section>
    <div class="nhsuk-grid-column-full">
      <div *ngIf="settings">
        <div
          *ngIf="
            !settings.contactEmailVerified || !settings.supportEmailVerified
          "
          class="nhsuk-alert nhsuk-alert--info nhsuk-alert--icon"
        >
          <svg
            class="nhsuk-icon nhsuk-icon__info nhsuk-alert__icon"
            fill="none"
            focusable="false"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 25 25"
            height="25"
            width="25"
          >
            <path
              d="M13.7,18.5h-2.4v-2.4h2.4V18.5z M12.5,13.7c-0.7,0-1.2-0.5-1.2-1.2V7.7c0-0.7,0.5-1.2,1.2-1.2s1.2,0.5,1.2,1.2v4.8
        C13.7,13.2,13.2,13.7,12.5,13.7z M12.5,0.5c-6.6,0-12,5.4-12,12s5.4,12,12,12s12-5.4,12-12S19.1,0.5,12.5,0.5z"
            ></path>
          </svg>
          <h3 class="nhsuk-u-visually-hidden">Information:</h3>
          <p class="nhsuk-u-font-weight-bold">
            <a class="nhsuk-link--no-visited-state" href="/settings"
              >Go to settings</a
            >
            to set your contact email address and help for applicants.
          </p>
        </div>
      </div>
      <h1 autofocus tabindex="-1" class="nhsuk-heading-xl">
        Apply for Care ID
      </h1>

      <role-changed></role-changed>

      <ul class="nhsuk-grid-row nhsuk-card-group nhsuk-u-margin-bottom-7">
        <li class="nhsuk-grid-column-one-half nhsuk-card-group__item">
          <div class="nhsuk-card nhsuk-card--clickable">
            <div class="nhsuk-card__content">
              <h2 class="nhsuk-card__heading nhsuk-heading-m">
                <a
                  class="nhsuk-card__link stretched-link"
                  data-cy="new-application"
                  aria-label="new applications dashboard"
                  [routerLink]="[newApplicationLink]"
                  >New application</a
                >
              </h2>
              <p class="nhsuk-card__description">
                Enter applicant details to send an application.
              </p>
            </div>
          </div>
        </li>
        <li class="nhsuk-grid-column-one-half nhsuk-card-group__item">
          <div class="nhsuk-card nhsuk-card--clickable">
            <div class="nhsuk-card__content">
              <h2 class="nhsuk-card__heading nhsuk-heading-m">
                <a
                  class="nhsuk-card__link stretched-link"
                  aria-label="mulitple new applications dashboard"
                  data-cy="review-applications"
                  [routerLink]="[bulkLink]"
                  >Upload multiple applications
                </a>
              </h2>
              <p class="nhsuk-card__description">
                Upload a file to send multiple applications at once.
              </p>
            </div>
          </div>
        </li>
      </ul>
      <div id="resend-status"></div>
      <error-summary
        [errorSummaryMessages]="summaryErrorMessages"
      ></error-summary>

      <success-alert
        *ngIf="actionSuccessful"
        displayText="{{ actionMessage }}"
      ></success-alert>
      <div
        *ngIf="!actionSuccessful && actionErrorMsg !== undefined"
        class="nhsuk-alert nhsuk-alert--success nhsuk-alert--icon"
      >
        <svg
          class="nhsuk-icon nhsuk-icon__warning nhsuk-alert__icon"
          fill="none"
          aria-hidden="true"
          viewBox="0 0 512 512"
          style="enable-background: new 0 0 512 512"
        >
          <g>
            <g>
              <path
                d="M501.609,384.603L320.543,51.265c-13.666-23.006-37.802-36.746-64.562-36.746c-26.76,0-50.896,13.74-64.562,36.746
              c-0.103,0.176-0.19,0.352-0.293,0.528L10.662,384.076c-13.959,23.491-14.223,51.702-0.719,75.457
              c13.535,23.769,37.919,37.948,65.266,37.948h360.544c27.347,0,52.733-14.179,66.267-37.948
              C515.524,435.779,515.261,407.566,501.609,384.603z M225.951,167.148c0-16.586,13.445-30.03,30.03-30.03
              c16.586,0,30.03,13.445,30.03,30.03v120.121c0,16.584-13.445,30.03-30.03,30.03s-30.03-13.447-30.03-30.03V167.148z
              M255.981,437.421c-24.839,0-45.046-20.206-45.046-45.046c0-24.839,20.206-45.045,45.046-45.045
              c24.839,0,45.045,20.206,45.045,45.045C301.027,417.214,280.821,437.421,255.981,437.421z"
              ></path>
            </g>
          </g>
        </svg>
        <h2 class="nhsuk-u-visually-hidden">Failure:</h2>
        <p
          class="nhsuk-u-font-weight-bold"
          innerHTML="{{ actionErrorMsg }}"
        ></p>
      </div>
      <section *ngIf="!loading">
        <div class="tab" id="all-tabs">
          <button
            #tabResend
            class="tablinks active"
            (click)="selectTab($event, 'resendRequired')"
            data-bs-toggle="pill"
            data-bs-target="#tabs-resend"
            data-cy="tab-resend"
            (keydown.arrowleft)="selectTabKeys('cancelledTab', 'cancelled')"
            (keydown.arrowright)="selectTabKeys('inProgressTab', 'inProgress')"
            role="tab"
            tabindex="0"
            id="resendRequiredTab"
            aria-controls="tabs-resend"
          >
            Resend required ({{ resendRequiredInvitations.length }})
          </button>
          <button
            #tabInprogress
            class="tablinks"
            (click)="selectTab($event, 'inProgress')"
            data-bs-toggle="pill"
            data-bs-target="#tabs-overview"
            data-cy="tab-in-progress"
            role="tab"
            aria-controls="tabs-overview"
            tabindex="-1"
            id="inProgressTab"
            (keydown.arrowleft)="
              selectTabKeys('resendRequiredTab', 'resendRequired')
            "
            (keydown.arrowright)="selectTabKeys('approvedTab', 'approved')"
          >
            In progress ({{ inProgressInvitations.length }})
          </button>
          <button
            #tabApproved
            class="tablinks"
            data-cy="tab-approved"
            id="approvedTab"
            (keydown.arrowleft)="selectTabKeys('inProgressTab', 'inProgress')"
            (keydown.arrowright)="selectTabKeys('duplicatesTab', 'rejected')"
            tabindex="-1"
            (click)="selectTab($event, 'approved')"
          >
            Approved ({{ activeApprovedCount }})
          </button>
          <button
            #tabDuplicates
            class="tablinks"
            data-cy="tab-duplicates"
            tabindex="-1"
            id="duplicatesTab"
            (keydown.arrowleft)="selectTabKeys('approvedTab', 'duplicates')"
            (keydown.arrowright)="selectTabKeys('rejectedTab', 'duplicates')"
            (click)="selectTab($event, 'duplicates')"
          >
            Duplicates ({{ activeDuplicatesCount }})
          </button>
          <button
            #tabRejected
            class="tablinks"
            data-cy="tab-rejected"
            tabindex="-1"
            id="rejectedTab"
            (keydown.arrowleft)="selectTabKeys('approvedTab', 'approved')"
            (keydown.arrowright)="selectTabKeys('cancelledTab', 'cancelled')"
            (click)="selectTab($event, 'rejected')"
          >
            Rejected ({{ activeRejectedCount }})
          </button>
          <button
            #tabCancelled
            class="tablinks"
            data-cy="tab-cancelled"
            tabindex="-1"
            id="cancelledTab"
            (keydown.arrowleft)="selectTabKeys('rejectedTab', 'rejected')"
            (keydown.arrowright)="
              selectTabKeys('resendRequiredTab', 'resendRequired')
            "
            (click)="selectTab($event, 'cancelled')"
          >
            Cancelled ({{ cancelledInvitations.length }})
          </button>
        </div>

        <!--Resend required-->
        <div>
          <div
            class="tabcontent active"
            id="resendRequired"
            role="tabpanel"
            style="display: block"
          >
            <div *ngIf="resendRequiredInvitations.length == 0">
              <p>
                Your organisation has no recent resend required applications.
              </p>
            </div>
            <div *ngIf="resendRequiredInvitations.length != 0">
              <div class="nhsuk-grid-row">
                <div class="nhsuk-grid-column-two-thirds">
                  <h3>Resend required applications</h3>
                  <button
                    class="nhsuk-button nhsuk-button--secondary nhsuk-u-margin-right-3"
                    style="padding: 6px 16px; font-weight: normal"
                    [disabled]="processingResends || processingCancels"
                    [ngClass]="{ 'spinner-button': processingResends }"
                    (click)="resendMultipleApplications()"
                  >
                    Resend selected
                  </button>

                  <button
                    class="nhsuk-button nhsuk-button--secondary delete-button"
                    style="padding: 6px 16px; font-weight: normal"
                    [disabled]="processingResends || processingCancels"
                    [ngClass]="{ 'spinner-button': processingCancels }"
                    (click)="cancelMultipleApplications()"
                  >
                    Cancel selected
                  </button>
                </div>
                <div class="nhsuk-grid-column-one-third">
                  <div style="float: right">
                    <div class="nhsuk-form-group">
                      <label
                        class="nhsuk-label"
                        for="select-sort-resend-required"
                      >
                        Sort by
                      </label>
                      <select
                        data-cy="select-sort-resend-required"
                        class="nhsuk-select"
                        id="select-sort-resend-required"
                        name="select-sort-resend-required"
                        (change)="
                          sortChanged('resendRequired', $event.target.value)
                        "
                      >
                        <option value="givenName">Applicant name</option>
                        <option value="createdDateUtc" selected>Newest</option>
                        <option value="createdByUserOrgCode">ODS code</option>
                        <option value="createdDateUtcOldest">Oldest</option>
                        <option value="createdByUserName">Raised by</option>
                        <option value="statusLastUpdatedDateUtc">
                          Last updated
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <table role="table" class="nhsuk-table-responsive">
                <caption class="nhsuk-table__caption nhsuk-u-visually-hidden">
                  Expired list
                </caption>
                <thead role="rowgroup" class="nhsuk-table__head">
                  <tr role="row">
                    <th role="columnheader" scope="col">
                      <div
                        class="nhsuk-checkboxes__item nhsuk-checkboxes--small"
                      >
                        <input
                          id="checkbox-resend-selectall"
                          data-cy="checkbox-resend-selectall"
                          class="nhsuk-checkboxes__input"
                          type="checkbox"
                          name="Select all"
                          (change)="selectAllForResend()"
                          aria-label="Select all"
                          title="Select all"
                        />
                        <label
                          class="nhsuk-label nhsuk-checkboxes__label"
                          for="checkbox-resend-selectall"
                          ><span class="nhsuk-u-visually-hidden"
                            >Select all</span
                          >
                        </label>
                      </div>
                    </th>
                    <th role="columnheader" scope="col">Applicant name</th>
                    <th role="columnheader" scope="col">Raised on</th>
                    <th role="columnheader" scope="col">Raised by</th>
                    <th role="columnheader" scope="col">ODS code</th>
                    <th role="columnheader" scope="col">Last updated</th>
                    <th role="columnheader" scope="col">Reason</th>
                  </tr>
                </thead>
                <tbody class="nhsuk-table__body">
                  <tr
                    *ngFor="let invitation of resendRequiredInvitations"
                    role="row"
                    class="nhsuk-table__row"
                  >
                    <td role="cell" class="nhsuk-table__cell">
                      <span class="nhsuk-table-responsive__heading"
                        >Select item
                      </span>
                      <div
                        class="nhsuk-checkboxes__item nhsuk-checkboxes--small"
                      >
                        <input
                          class="nhsuk-checkboxes__input"
                          type="checkbox"
                          id="Resend-{{ invitation.invitationId }}"
                          name="Resend-{{ invitation.invitationId }}"
                          [(ngModel)]="invitation.selectedForResend"
                          [value]="invitation.selectedForResend"
                          [(checked)]="invitation.selectedForResend"
                          aria-label="Resend/Cancel {{
                            invitation.invitationId
                          }}"
                          title="Resend/Cancel {{ invitation.invitationId }}"
                        />
                        <label
                          class="nhsuk-label nhsuk-checkboxes__label"
                          for="Resend-{{ invitation.invitationId }}"
                        >
                          <span class="nhsuk-u-visually-hidden"
                            >Select all</span
                          >
                        </label>
                      </div>
                    </td>
                    <td role="cell" class="nhsuk-table__cell">
                      <span class="nhsuk-table-responsive__heading"
                        >Full name
                      </span>
                      <a
                        [state]="{
                          invitation: invitation,
                          prevPage: '/invitation/dashboard',
                          tabSelected: 'resendRequired'
                        }"
                        [routerLink]="[
                          '/invitation/invite-details',
                          invitation.invitationId
                        ]"
                        >{{ invitation.givenName }}
                        {{ invitation.familyName }}</a
                      >
                    </td>
                    <td role="cell" class="nhsuk-table__cell">
                      <span class="nhsuk-table-responsive__heading"
                        >Raised on</span
                      >
                      {{ invitation.createdDateUtc | date: "d MMM yyyy" }}
                    </td>
                    <td role="cell" class="nhsuk-table__cell">
                      <span class="nhsuk-table-responsive__heading"
                        >Raised by</span
                      >
                      {{ invitation.createdByUserName }}
                    </td>
                    <td role="cell" class="nhsuk-table__cell">
                      <span class="nhsuk-table-responsive__heading"
                        >ODS Code</span
                      >
                      {{ invitation.createdByUserOrgCode }}
                    </td>
                    <td role="cell" class="nhsuk-table__cell">
                      <span class="nhsuk-table-responsive__heading"
                        >Last updated</span
                      >
                      {{
                        invitation.statusLastUpdatedDateUtc | date: "d MMM yyyy"
                      }}
                    </td>
                    <td role="cell" class="nhsuk-table__cell">
                      <span class="nhsuk-table-responsive__heading"
                        >Reason</span
                      >
                      <p *ngIf="invitation.status == 31">
                        Invite email expired
                      </p>
                      <p *ngIf="invitation.status == 29">
                        Invite email could not be delivered to
                        {{ invitation.email }}
                      </p>
                      <p *ngIf="invitation.status == 33">
                        Submitted the wrong security code too many times or
                        requested too many security codes
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <!--In progress-->
        <div id="inProgress" role="tabpanel" class="tabcontent">
          <div *ngIf="inProgressInvitations.length == 0">
            <p>Your organisation has no recent in progress applications.</p>
          </div>
          <div *ngIf="inProgressInvitations.length != 0">
            <h3 class="nhsuk-u-margin-bottom-0">In progress applications</h3>

            <div class="nhsuk-grid-row">
              <div class="nhsuk-grid-column-two-thirds">&nbsp;</div>
              <div class="nhsuk-grid-column-one-third">
                <div style="float: right">
                  <div class="nhsuk-form-group">
                    <label class="nhsuk-label" for="select-sort-in-progress">
                      Sort by
                    </label>
                    <select
                      data-cy="select-sort-in-progress"
                      class="nhsuk-select"
                      id="select-sort-in-progress"
                      name="select-sort-in-progress"
                      (change)="sortChanged('inProgress', $event.target.value)"
                    >
                      <option value="givenName">Applicant name</option>
                      <option value="createdDateUtc" selected>
                        Raised on (newest)
                      </option>
                      <option value="createdDateUtcOldest">
                        Raised on (oldest)
                      </option>
                      <option value="createdByUserName">Raised by</option>
                      <option value="createdByUserOrgCode">ODS code</option>
                      <option value="statusLastUpdatedDateUtc">
                        Last updated (newest)
                      </option>
                      <option value="statusLastUpdatedDateUtcOldest">
                        Last updated (oldest)
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <table role="table" class="nhsuk-table-responsive">
              <caption class="nhsuk-table__caption nhsuk-u-visually-hidden">
                In progress list
              </caption>
              <thead role="rowgroup" class="nhsuk-table__head">
                <tr role="row">
                  <th role="columnheader" scope="col">Applicant name</th>

                  <th role="columnheader" scope="col">Raised on</th>
                  <th role="columnheader" scope="col">Raised by</th>
                  <th role="columnheader" scope="col">ODS code</th>
                  <th role="columnheader" scope="col">Last updated</th>
                </tr>
              </thead>
              <tbody class="nhsuk-table__body">
                <tr
                  *ngFor="let invitation of inProgressInvitations"
                  role="row"
                  class="nhsuk-table__row"
                >
                  <td role="cell" class="nhsuk-table__cell">
                    <span class="nhsuk-table-responsive__heading"
                      >Full name
                    </span>
                    <a
                      attr.data-cy="{{ invitation.invitationId }}"
                      [state]="{
                        invitation: invitation,
                        prevPage: '/invitation/dashboard',
                        tabSelected: 'inProgress'
                      }"
                      [routerLink]="[
                        '/invitation/invite-details',
                        invitation.invitationId
                      ]"
                    >
                      {{ invitation.givenName }} {{ invitation.familyName }}</a
                    >
                  </td>

                  <td role="cell" class="nhsuk-table__cell">
                    <span class="nhsuk-table-responsive__heading"
                      >Raised on</span
                    >
                    {{ invitation.createdDateUtc | date: "d MMM yyyy" }}
                  </td>
                  <td role="cell" class="nhsuk-table__cell">
                    <span class="nhsuk-table-responsive__heading"
                      >Raised by</span
                    >
                    {{ invitation.createdByUserName }}
                  </td>
                  <td role="cell" class="nhsuk-table__cell">
                    <span class="nhsuk-table-responsive__heading"
                      >ODS Code</span
                    >
                    {{ invitation.createdByUserOrgCode }}
                  </td>
                  <td role="cell" class="nhsuk-table__cell">
                    <span class="nhsuk-table-responsive__heading"
                      >Last updated</span
                    >
                    {{
                      invitation.statusLastUpdatedDateUtc | date: "d MMM yyyy"
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <!-- Approved-->
        <div id="approved" class="tabcontent" role="tabpanel">
          <div *ngIf="activeApprovedCount == 0">
            <p>Your organisation has no recent approved applications.</p>
          </div>
          <div *ngIf="activeApprovedCount > 0">
            <h3 class="nhsuk-u-margin-bottom-0">Approved applications</h3>

            <div class="nhsuk-grid-row">
              <div class="nhsuk-grid-column-two-thirds">
                <button
                  class="nhsuk-button nhsuk-button--secondary nhsuk-u-margin-right-3 nhsuk-u-margin-top-4"
                  data-cy="button-markdone-approved"
                  [disabled]="processingMarkAsDone"
                  [ngClass]="{ 'spinner-button': processingMarkAsDone }"
                  (click)="submitMarkDone(approvedInvitations, true)"
                >
                  Mark as done
                </button>
              </div>
              <div class="nhsuk-grid-column-one-third">
                <div style="float: right">
                  <div class="nhsuk-form-group">
                    <label class="nhsuk-label" for="select-sort-approved">
                      Sort by
                    </label>
                    <select
                      data-cy="select-sort-approved"
                      class="nhsuk-select"
                      id="select-sort-approved"
                      name="select-sort-approved"
                      (change)="sortChanged('approved', $event.target.value)"
                    >
                      <option value="givenName">Applicant name</option>
                      <option value="statusLastUpdatedDateUtc" selected>
                        Newest
                      </option>
                      <option value="createdByUserOrgCode">ODS code</option>
                      <option value="statusLastUpdatedDateUtcOldest">
                        Oldest
                      </option>
                      <option value="createdByUserName">Raised by</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <table role="table" class="nhsuk-table-responsive">
              <caption class="nhsuk-table__caption nhsuk-u-visually-hidden">
                Approved list
              </caption>
              <thead role="rowgroup" class="nhsuk-table__head">
                <tr role="row">
                  <th role="columnheader" scope="col">
                    <div class="nhsuk-checkboxes__item nhsuk-checkboxes--small">
                      <input
                        id="checkbox-markdone-selectall-approved"
                        data-cy="checkbox-markdone-selectall-approved"
                        class="nhsuk-checkboxes__input"
                        type="checkbox"
                        name="checkbox-markdone-selectall-approved"
                        [(ngModel)]="allselectedForMarkAsDone"
                        (change)="
                          selectAllForMarkAsDone(approvedInvitations, false)
                        "
                        aria-label="Select all"
                        title="Select all"
                      />
                      <label
                        class="nhsuk-label nhsuk-checkboxes__label"
                        for="checkbox-markdone-selectall-approved"
                        ><span class="nhsuk-u-visually-hidden">Select all</span>
                      </label>
                    </div>
                  </th>
                  <th role="columnheader" scope="col">Applicant name</th>
                  <th role="columnheader" scope="col">Approved on</th>
                  <th role="columnheader" scope="col">Raised by</th>
                  <th role="columnheader" scope="col">ODS code</th>
                  <th role="columnheader" scope="col">UUID</th>
                  <th role="columnheader" scope="col">ID checker notes</th>
                </tr>
              </thead>
              <tbody
                class="nhsuk-table__body"
                *ngFor="let invitation of approvedInvitations"
              >
                <tr
                  *ngIf="invitation.done == undefined"
                  role="row"
                  class="nhsuk-table__row"
                >
                  <td role="cell" class="nhsuk-table__cell">
                    <span class="nhsuk-table-responsive__heading"
                      >Select item
                    </span>
                    <div class="nhsuk-checkboxes__item nhsuk-checkboxes--small">
                      <input
                        class="nhsuk-checkboxes__input"
                        type="checkbox"
                        id="mark-as-done-{{ invitation.invitationId }}"
                        data-cy="mark-as-done-{{ invitation.invitationId }}"
                        name="mark-as-done-{{ invitation.invitationId }}"
                        [(ngModel)]="invitation.selectedForMarkAsDone"
                        [(checked)]="invitation.selectedForMarkAsDone"
                        aria-label="Mark as done {{
                          invitation.givenName + ' ' + invitation.familyName
                        }}"
                      />
                      <label
                        class="nhsuk-label nhsuk-checkboxes__label"
                        for="mark-as-done-{{ invitation.invitationId }}"
                      >
                        <span class="nhsuk-u-visually-hidden">Select</span>
                      </label>
                    </div>
                  </td>
                  <td role="cell" class="nhsuk-table__cell">
                    <span class="nhsuk-table-responsive__heading"
                      >Full name
                    </span>
                    <a
                      attr.data-cy="link-approved-{{ invitation.invitationId }}"
                      [state]="{
                        invitation: invitation,
                        prevPage: '/invitation/dashboard',
                        tabSelected: 'approved'
                      }"
                      [routerLink]="[
                        '/invitation/invite-details',
                        invitation.invitationId
                      ]"
                    >
                      {{ invitation.givenName }} {{ invitation.familyName }}</a
                    >
                  </td>
                  <td role="cell" class="nhsuk-table__cell">
                    <span class="nhsuk-table-responsive__heading"
                      >Approved on</span
                    >
                    {{ invitation.completedDateUtc | date: "d MMM yyyy" }}
                  </td>
                  <td role="cell" class="nhsuk-table__cell">
                    <span class="nhsuk-table-responsive__heading"
                      >Raised by</span
                    >
                    {{ invitation.createdByUserName }}
                  </td>
                  <td role="cell" class="nhsuk-table__cell">
                    <span class="nhsuk-table-responsive__heading"
                      >ODS Code</span
                    >
                    {{ invitation.createdByUserOrgCode }}
                  </td>
                  <td role="cell" class="nhsuk-table__cell">
                    <span class="nhsuk-table-responsive__heading">UUID</span>
                    <cim-link uuid="{{ invitation.cisUuid }}"></cim-link>
                  </td>
                  <td role="cell" class="nhsuk-table__cell">
                    <span class="nhsuk-table-responsive__heading"
                      >ID checker notes</span
                    >
                    {{ invitation.approvalNote }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <details class="nhsuk-details nhsuk-expander nhsuk-u-margin-top-6">
              <summary class="nhsuk-details__summary">
                <span class="nhsuk-details__summary-text">
                  Marked as done ({{ doneApprovedCount }})
                </span>
              </summary>
              <div class="nhsuk-details__text">
                <p *ngIf="doneApprovedCount == 0; else doneApprovedTable">
                  No applications have been marked as done
                </p>

                <ng-template #doneApprovedTable>
                  <button
                    class="nhsuk-button nhsuk-button--secondary nhsuk-u-margin-right-3 nhsuk-u-margin-top-4"
                    data-cy="button-undo-mark-approved"
                    [disabled]="processingMarkAsDone"
                    [ngClass]="{ 'spinner-button': processingMarkAsDone }"
                    (click)="submitMarkDone(approvedInvitations, false)"
                  >
                    Undo mark as done
                  </button>
                  <!-- Marked as Done table for Approved-->
                  <table role="table" class="nhsuk-table-responsive">
                    <caption
                      class="nhsuk-table__caption nhsuk-u-visually-hidden"
                    >
                      Approved list marked as done
                    </caption>
                    <thead role="rowgroup" class="nhsuk-table__head">
                      <tr role="row">
                        <th role="columnheader" scope="col">
                          <div
                            class="nhsuk-checkboxes__item nhsuk-checkboxes--small"
                          >
                            <input
                              id="checkbox-undo-mark-selectall"
                              data-cy="checkbox-undo-mark-selectall"
                              class="nhsuk-checkboxes__input"
                              type="checkbox"
                              name="checkbox-undo-mark-selectall"
                              [(ngModel)]="allSelectedForMarkAsUndone"
                              (change)="
                                selectAllForMarkAsDone(
                                  approvedInvitations,
                                  true
                                )
                              "
                              aria-label="Select all"
                              title="Select all"
                            />
                            <label
                              class="nhsuk-label nhsuk-checkboxes__label"
                              for="checkbox-resend-selectall"
                              ><span class="nhsuk-u-visually-hidden"
                                >Select all</span
                              >
                            </label>
                          </div>
                        </th>
                        <th role="columnheader" scope="col">Applicant name</th>
                        <th role="columnheader" scope="col">Approved on</th>
                        <th role="columnheader" scope="col">Raised by</th>
                        <th role="columnheader" scope="col">ODS code</th>
                        <th role="columnheader" scope="col">UUID</th>
                        <th role="columnheader" scope="col">
                          ID checker notes
                        </th>
                      </tr>
                    </thead>
                    <tbody
                      class="nhsuk-table__body"
                      *ngFor="let invitation of approvedInvitations"
                    >
                      <tr
                        *ngIf="invitation.done"
                        role="row"
                        class="nhsuk-table__row"
                      >
                        <td role="cell" class="nhsuk-table__cell">
                          <span class="nhsuk-table-responsive__heading"
                            >Select item
                          </span>
                          <div
                            class="nhsuk-checkboxes__item nhsuk-checkboxes--small"
                          >
                            <input
                              class="nhsuk-checkboxes__input"
                              type="checkbox"
                              name="mark-as-done-{{ invitation.invitationId }}"
                              id="mark-as-done-{{ invitation.invitationId }}"
                              [(checked)]="invitation.selectedForMarkAsDone"
                              aria-label="Mark as done {{
                                invitation.givenName +
                                  ' ' +
                                  invitation.familyName
                              }}"
                              [(ngModel)]="invitation.selectedForMarkAsDone"
                            />
                            <label
                              class="nhsuk-label nhsuk-checkboxes__label"
                              for="mark-as-done-{{ invitation.invitationId }}"
                            >
                              <span class="nhsuk-u-visually-hidden"
                                >Select</span
                              >
                            </label>
                          </div>
                        </td>
                        <td role="cell" class="nhsuk-table__cell">
                          <span class="nhsuk-table-responsive__heading"
                            >Full name
                          </span>
                          <a
                            attr.data-cy="link-approved-{{
                              invitation.invitationId
                            }}"
                            [state]="{
                              invitation: invitation,
                              prevPage: '/invitation/dashboard',
                              tabSelected: 'approved'
                            }"
                            [routerLink]="[
                              '/invitation/invite-details',
                              invitation.invitationId
                            ]"
                          >
                            {{ invitation.givenName }}
                            {{ invitation.familyName }}</a
                          >
                        </td>
                        <td role="cell" class="nhsuk-table__cell">
                          <span class="nhsuk-table-responsive__heading"
                            >Approved on</span
                          >
                          {{ invitation.completedDateUtc | date: "d MMM yyyy" }}
                        </td>
                        <td role="cell" class="nhsuk-table__cell">
                          <span class="nhsuk-table-responsive__heading"
                            >Raised by</span
                          >
                          {{ invitation.createdByUserName }}
                        </td>
                        <td role="cell" class="nhsuk-table__cell">
                          <span class="nhsuk-table-responsive__heading"
                            >ODS Code</span
                          >
                          {{ invitation.createdByUserOrgCode }}
                        </td>
                        <td role="cell" class="nhsuk-table__cell">
                          <span class="nhsuk-table-responsive__heading"
                            >UUID</span
                          >
                          <cim-link uuid="{{ invitation.cisUuid }}"></cim-link>
                        </td>
                        <td role="cell" class="nhsuk-table__cell">
                          <span class="nhsuk-table-responsive__heading"
                            >ID checker notes</span
                          >
                          {{ invitation.approvalNote }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </ng-template>
              </div>
            </details>
          </div>
        </div>

        <!--Duplicates-->
        <div id="duplicates" class="tabcontent" role="tabpanel">
          <div *ngIf="activeDuplicatesCount == 0">
            <p>Your organisation has no recent duplicate applications.</p>
          </div>
          <div *ngIf="activeDuplicatesCount > 0">
            <h3 class="nhsuk-u-margin-bottom-0">Duplicate applications</h3>

            <div class="nhsuk-grid-row">
              <div class="nhsuk-grid-column-two-thirds">
                <button
                  class="nhsuk-button nhsuk-button--secondary nhsuk-u-margin-right-3 nhsuk-u-margin-top-4"
                  data-cy="button-markdone-duplicates"
                  [disabled]="processingMarkAsDone"
                  [ngClass]="{ 'spinner-button': processingMarkAsDone }"
                  (click)="submitMarkDone(duplicatesInvitations, true)"
                >
                  Mark as done
                </button>
              </div>
              <div class="nhsuk-grid-column-one-third">
                <div style="float: right">
                  <div class="nhsuk-form-group">
                    <label class="nhsuk-label" for="select-sort-duplicates">
                      Sort by
                    </label>
                    <select
                      data-cy="select-sort-duplicates"
                      class="nhsuk-select"
                      id="select-sort-duplicates"
                      name="select-sort-duplicates"
                      (change)="sortChanged('duplicates', $event.target.value)"
                    >
                      <option value="givenName">Applicant name</option>
                      <option value="statusLastUpdatedDateUtc" selected>
                        Newest
                      </option>
                      <option value="createdByUserOrgCode">ODS code</option>
                      <option value="statusLastUpdatedDateUtcOldest">
                        Oldest
                      </option>
                      <option value="createdByUserName">Raised by</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <table role="table" class="nhsuk-table-responsive">
              <caption class="nhsuk-table__caption nhsuk-u-visually-hidden">
                Duplicates list
              </caption>
              <thead role="rowgroup" class="nhsuk-table__head">
                <tr role="row">
                  <th role="columnheader" scope="col">
                    <div class="nhsuk-checkboxes__item nhsuk-checkboxes--small">
                      <input
                        id="checkbox-markdone-selectall-duplicates"
                        data-cy="checkbox-markdone-selectall-duplicates"
                        class="nhsuk-checkboxes__input"
                        type="checkbox"
                        name="checkbox-markdone-selectall-duplicates"
                        [(ngModel)]="allselectedForMarkAsDone"
                        (change)="
                          selectAllForMarkAsDone(duplicatesInvitations, false)
                        "
                        aria-label="Select all"
                        title="Select all"
                      />
                      <label
                        class="nhsuk-label nhsuk-checkboxes__label"
                        for="checkbox-markdone-selectall-duplicates"
                        ><span class="nhsuk-u-visually-hidden">Select all</span>
                      </label>
                    </div>
                  </th>
                  <th role="columnheader" scope="col">Applicant name</th>
                  <th role="columnheader" scope="col">Last updated</th>
                  <th role="columnheader" scope="col">Raised by</th>
                  <th role="columnheader" scope="col">ODS code</th>
                  <th role="columnheader" scope="col">Duplicate match UUID</th>
                </tr>
              </thead>
              <tbody
                class="nhsuk-table__body"
                *ngFor="let invitation of duplicatesInvitations"
              >
                <tr
                  *ngIf="invitation.done == undefined"
                  role="row"
                  class="nhsuk-table__row"
                >
                  <td role="cell" class="nhsuk-table__cell">
                    <span class="nhsuk-table-responsive__heading"
                      >Select item
                    </span>
                    <div class="nhsuk-checkboxes__item nhsuk-checkboxes--small">
                      <input
                        class="nhsuk-checkboxes__input"
                        type="checkbox"
                        id="mark-as-done-{{ invitation.invitationId }}"
                        name="mark-as-done-{{ invitation.invitationId }}"
                        [(ngModel)]="invitation.selectedForMarkAsDone"
                        [(checked)]="invitation.selectedForMarkAsDone"
                        aria-label="Mark as done {{
                          invitation.givenName + ' ' + invitation.familyName
                        }}"
                      />
                      <label
                        class="nhsuk-label nhsuk-checkboxes__label"
                        for="mark-as-done-{{ invitation.invitationId }}"
                      >
                        <span class="nhsuk-u-visually-hidden">Select</span>
                      </label>
                    </div>
                  </td>
                  <td role="cell" class="nhsuk-table__cell">
                    <span class="nhsuk-table-responsive__heading"
                      >Full name
                    </span>
                    <a
                      attr.data-cy="link-duplicates-{{
                        invitation.invitationId
                      }}"
                      [state]="{
                        invitation: invitation,
                        prevPage: '/invitation/dashboard',
                        tabSelected: 'duplicates'
                      }"
                      [routerLink]="[
                        '/invitation/invite-details',
                        invitation.invitationId
                      ]"
                    >
                      {{ invitation.givenName }} {{ invitation.familyName }}</a
                    >
                  </td>
                  <td role="cell" class="nhsuk-table__cell">
                    <span class="nhsuk-table-responsive__heading"
                      >Last updated</span
                    >
                    {{
                      invitation.statusLastUpdatedDateUtc | date: "d MMM yyyy"
                    }}
                  </td>
                  <td role="cell" class="nhsuk-table__cell">
                    <span class="nhsuk-table-responsive__heading"
                      >Raised by</span
                    >
                    {{ invitation.createdByUserName }}
                  </td>
                  <td role="cell" class="nhsuk-table__cell">
                    <span class="nhsuk-table-responsive__heading"
                      >ODS Code</span
                    >
                    {{ invitation.createdByUserOrgCode }}
                  </td>
                  <td
                    role="cell"
                    class="nhsuk-table__cell"
                    attr.data-cy="cis-uuid-{{ invitation.invitationId }}"
                  >
                    <span class="nhsuk-table-responsive__heading">Reason</span>
                    <cim-link
                      uuid="{{ invitation.cisDuplicateMatch }}"
                    ></cim-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <details class="nhsuk-details nhsuk-expander nhsuk-u-margin-top-6">
              <summary class="nhsuk-details__summary">
                <span class="nhsuk-details__summary-text">
                  Marked as done ({{ doneDuplicatesCount }})
                </span>
              </summary>
              <div class="nhsuk-details__text">
                <p *ngIf="doneDuplicatesCount == 0; else doneDuplicatesTable">
                  No applications have been marked as done
                </p>

                <ng-template #doneDuplicatesTable>
                  <button
                    class="nhsuk-button nhsuk-button--secondary nhsuk-u-margin-right-3 nhsuk-u-margin-top-4"
                    data-cy="button-undo-mark-duplicates"
                    [disabled]="processingMarkAsDone"
                    [ngClass]="{ 'spinner-button': processingMarkAsDone }"
                    (click)="submitMarkDone(duplicatesInvitations, false)"
                  >
                    Undo mark as done
                  </button>
                  <!-- Marked as Done table for Duplicates-->
                  <table role="table" class="nhsuk-table-responsive">
                    <caption
                      class="nhsuk-table__caption nhsuk-u-visually-hidden"
                    >
                      Duplicates list marked as done
                    </caption>
                    <thead role="rowgroup" class="nhsuk-table__head">
                      <tr role="row">
                        <th role="columnheader" scope="col">
                          <div
                            class="nhsuk-checkboxes__item nhsuk-checkboxes--small"
                          >
                            <input
                              id="checkbox-undo-done-selectall-duplicates"
                              data-cy="checkbox-undo-done-selectall-duplicates"
                              class="nhsuk-checkboxes__input"
                              type="checkbox"
                              name="checkbox-undo-done-selectall-duplicates"
                              [(ngModel)]="allSelectedForMarkAsUndone"
                              (change)="
                                selectAllForMarkAsDone(
                                  duplicatesInvitations,
                                  true
                                )
                              "
                              aria-label="Select all"
                              title="Select all"
                            />
                            <label
                              class="nhsuk-label nhsuk-checkboxes__label"
                              for="checkbox-undo-done-selectall-duplicates"
                              ><span class="nhsuk-u-visually-hidden"
                                >Select all</span
                              >
                            </label>
                          </div>
                        </th>
                        <th role="columnheader" scope="col">Applicant name</th>
                        <th role="columnheader" scope="col">Last updated</th>
                        <th role="columnheader" scope="col">Raised by</th>
                        <th role="columnheader" scope="col">ODS code</th>
                        <th role="columnheader" scope="col">
                          Duplicate match UUID
                        </th>
                      </tr>
                    </thead>
                    <tbody
                      class="nhsuk-table__body"
                      *ngFor="let invitation of duplicatesInvitations"
                    >
                      <tr
                        *ngIf="invitation.done"
                        role="row"
                        class="nhsuk-table__row"
                      >
                        <td role="cell" class="nhsuk-table__cell">
                          <span class="nhsuk-table-responsive__heading"
                            >Select item
                          </span>
                          <div
                            class="nhsuk-checkboxes__item nhsuk-checkboxes--small"
                          >
                            <input
                              class="nhsuk-checkboxes__input"
                              type="checkbox"
                              id="mark-as-done-{{ invitation.invitationId }}"
                              name="mark-as-done-{{ invitation.invitationId }}"
                              [(ngModel)]="invitation.selectedForMarkAsDone"
                              [(checked)]="invitation.selectedForMarkAsDone"
                              aria-label="Mark as done {{
                                invitation.givenName +
                                  ' ' +
                                  invitation.familyName
                              }}"
                            />
                            <label
                              class="nhsuk-label nhsuk-checkboxes__label"
                              for="mark-as-done-{{ invitation.invitationId }}"
                            >
                              <span class="nhsuk-u-visually-hidden"
                                >Select</span
                              >
                            </label>
                          </div>
                        </td>
                        <td role="cell" class="nhsuk-table__cell">
                          <span class="nhsuk-table-responsive__heading"
                            >Full name
                          </span>
                          <a
                            attr.data-cy="link-duplicates-{{
                              invitation.invitationId
                            }}"
                            [state]="{
                              invitation: invitation,
                              prevPage: '/invitation/dashboard',
                              tabSelected: 'duplicates'
                            }"
                            [routerLink]="[
                              '/invitation/invite-details',
                              invitation.invitationId
                            ]"
                          >
                            {{ invitation.givenName }}
                            {{ invitation.familyName }}</a
                          >
                        </td>
                        <td role="cell" class="nhsuk-table__cell">
                          <span class="nhsuk-table-responsive__heading"
                            >Last updated</span
                          >
                          {{
                            invitation.statusLastUpdatedDateUtc
                              | date: "d MMM yyyy"
                          }}
                        </td>
                        <td role="cell" class="nhsuk-table__cell">
                          <span class="nhsuk-table-responsive__heading"
                            >Raised by</span
                          >
                          {{ invitation.createdByUserName }}
                        </td>
                        <td role="cell" class="nhsuk-table__cell">
                          <span class="nhsuk-table-responsive__heading"
                            >ODS Code</span
                          >
                          {{ invitation.createdByUserOrgCode }}
                        </td>
                        <td
                          role="cell"
                          class="nhsuk-table__cell"
                          attr.data-cy="cis-uuid-{{ invitation.invitationId }}"
                        >
                          <span class="nhsuk-table-responsive__heading"
                            >Reason</span
                          >
                          <cim-link
                            uuid="{{ invitation.cisDuplicateMatch }}"
                          ></cim-link>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </ng-template>
              </div>
            </details>
          </div>
        </div>

        <!--Rejected-->
        <div id="rejected" class="tabcontent" role="tabpanel">
          <div *ngIf="activeRejectedCount == 0">
            <p>Your organisation has no recent rejected applications.</p>
          </div>
          <div *ngIf="activeRejectedCount > 0">
            <h3 class="nhsuk-u-margin-bottom-0">Rejected applications</h3>
            <details
              class="nhsuk-details nhsuk-u-width-two-thirds nhsuk-u-margin-top-4"
            >
              <summary class="nhsuk-details__summary">
                <span class="nhsuk-details__summary-text">
                  How applicants are notified of rejections
                </span>
              </summary>
              <div class="nhsuk-details__text">
                <p class="nhsuk-u-reading-width">
                  Applicants are emailed when their applications are rejected.
                </p>
                <p class="nhsuk-u-reading-width">The email includes:</p>
                <ul>
                  <li>the reasons for being rejected</li>
                  <li>
                    guidance on how to make sure their next application is
                    approved
                  </li>
                  <li>
                    your contact details so that they can request a new
                    application link or a face to face appointment
                  </li>
                </ul>
              </div>
            </details>

            <div class="nhsuk-grid-row">
              <div class="nhsuk-grid-column-two-thirds">
                <button
                  class="nhsuk-button nhsuk-button--secondary nhsuk-u-margin-right-3 nhsuk-u-margin-top-4"
                  data-cy="button-markdone-rejected"
                  [disabled]="processingMarkAsDone"
                  [ngClass]="{ 'spinner-button': processingMarkAsDone }"
                  (click)="submitMarkDone(rejectedInvitations, true)"
                >
                  Mark as done
                </button>
              </div>
              <div class="nhsuk-grid-column-one-third">
                <div style="float: right">
                  <div class="nhsuk-form-group">
                    <label class="nhsuk-label" for="select-sort-rejected">
                      Sort by
                    </label>
                    <select
                      data-cy="select-sort-rejected"
                      class="nhsuk-select"
                      id="select-sort-rejected"
                      name="select-sort-rejected"
                      (change)="sortChanged('rejected', $event.target.value)"
                    >
                      <option value="givenName">Applicant name</option>
                      <option value="statusLastUpdatedDateUtc" selected>
                        Newest
                      </option>
                      <option value="createdByUserOrgCode">ODS code</option>
                      <option value="statusLastUpdatedDateUtcOldest">
                        Oldest
                      </option>
                      <option value="createdByUserName">Raised by</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <table role="table" class="nhsuk-table-responsive">
              <caption class="nhsuk-table__caption nhsuk-u-visually-hidden">
                Rejected list
              </caption>
              <thead role="rowgroup" class="nhsuk-table__head">
                <tr role="row">
                  <th role="columnheader" scope="col">
                    <div class="nhsuk-checkboxes__item nhsuk-checkboxes--small">
                      <input
                        id="checkbox-markdone-selectall-rejected"
                        data-cy="checkbox-markdone-selectall-rejected"
                        class="nhsuk-checkboxes__input"
                        type="checkbox"
                        name="checkbox-markdone-selectall-rejected"
                        [(ngModel)]="allselectedForMarkAsDone"
                        (change)="
                          selectAllForMarkAsDone(rejectedInvitations, false)
                        "
                        aria-label="Select all"
                        title="Select all"
                      />
                      <label
                        class="nhsuk-label nhsuk-checkboxes__label"
                        for="checkbox-markdone-selectall-rejected"
                        ><span class="nhsuk-u-visually-hidden">Select all</span>
                      </label>
                    </div>
                  </th>
                  <th role="columnheader" scope="col">Applicant name</th>
                  <th role="columnheader" scope="col">Rejected on</th>
                  <th role="columnheader" scope="col">Raised by</th>
                  <th role="columnheader" scope="col">ODS code</th>
                  <th role="columnheader" scope="col">Reason(s)</th>
                </tr>
              </thead>
              <tbody
                class="nhsuk-table__body"
                *ngFor="let invitation of rejectedInvitations"
              >
                <tr
                  *ngIf="invitation.done == undefined"
                  role="row"
                  class="nhsuk-table__row"
                >
                  <td role="cell" class="nhsuk-table__cell">
                    <span class="nhsuk-table-responsive__heading"
                      >Select item
                    </span>
                    <div class="nhsuk-checkboxes__item nhsuk-checkboxes--small">
                      <input
                        class="nhsuk-checkboxes__input"
                        type="checkbox"
                        id="mark-as-done-{{ invitation.invitationId }}"
                        name="mark-as-done-{{ invitation.invitationId }}"
                        [(ngModel)]="invitation.selectedForMarkAsDone"
                        [(checked)]="invitation.selectedForMarkAsDone"
                        aria-label="Mark as done {{
                          invitation.givenName + ' ' + invitation.familyName
                        }}"
                      />
                      <label
                        class="nhsuk-label nhsuk-checkboxes__label"
                        for="mark-as-done-{{ invitation.invitationId }}"
                      >
                        <span class="nhsuk-u-visually-hidden">Select</span>
                      </label>
                    </div>
                  </td>
                  <td role="cell" class="nhsuk-table__cell">
                    <span class="nhsuk-table-responsive__heading"
                      >Full name
                    </span>
                    <a
                      attr.data-cy="link-rejected-{{ invitation.invitationId }}"
                      [state]="{
                        invitation: invitation,
                        prevPage: '/invitation/dashboard',
                        tabSelected: 'rejected'
                      }"
                      [routerLink]="[
                        '/invitation/invite-details',
                        invitation.invitationId
                      ]"
                    >
                      {{ invitation.givenName }} {{ invitation.familyName }}</a
                    >
                  </td>
                  <td role="cell" class="nhsuk-table__cell">
                    <span class="nhsuk-table-responsive__heading"
                      >Rejected on</span
                    >
                    {{
                      invitation.statusLastUpdatedDateUtc | date: "d MMM yyyy"
                    }}
                  </td>
                  <td role="cell" class="nhsuk-table__cell">
                    <span class="nhsuk-table-responsive__heading"
                      >Raised by</span
                    >
                    {{ invitation.createdByUserName }}
                  </td>
                  <td role="cell" class="nhsuk-table__cell">
                    <span class="nhsuk-table-responsive__heading"
                      >ODS Code</span
                    >
                    {{ invitation.createdByUserOrgCode }}
                  </td>
                  <td
                    role="cell"
                    class="nhsuk-table__cell"
                    attr.data-cy="reject-reason-{{ invitation.invitationId }}"
                  >
                    <span class="nhsuk-table-responsive__heading">Reason</span>
                    <p *ngIf="invitation.status == 37">
                      Application expired. Applicant has not submitted ID
                      documents within 15 days of receiving link.
                    </p>
                    <p *ngIf="invitation.failedDocument">
                      {{ invitation.failedDocument }}
                    </p>
                    <p
                      *ngIf="invitation.failedReason"
                      [innerHTML]="
                        invitation.failedReason
                          | IdFailureCheckReasonParagraphPipe
                            : invitation.photoIdOneType
                            : invitation.photoIdTwoType
                            : false
                      "
                    ></p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <details class="nhsuk-details nhsuk-expander nhsuk-u-margin-top-6">
              <summary class="nhsuk-details__summary">
                <span class="nhsuk-details__summary-text">
                  Marked as done ({{ doneRejectedCount }})
                </span>
              </summary>
              <div class="nhsuk-details__text">
                <p *ngIf="doneRejectedCount == 0; else doneRejectedTable">
                  No applications have been marked as done
                </p>

                <ng-template #doneRejectedTable>
                  <button
                    class="nhsuk-button nhsuk-button--secondary nhsuk-u-margin-right-3 nhsuk-u-margin-top-4"
                    data-cy="button-undo-mark-rejected"
                    [disabled]="processingMarkAsDone"
                    [ngClass]="{ 'spinner-button': processingMarkAsDone }"
                    (click)="submitMarkDone(rejectedInvitations, false)"
                  >
                    Undo mark as done
                  </button>
                  <!-- Marked as Done table for Rejected-->
                  <table role="table" class="nhsuk-table-responsive">
                    <caption
                      class="nhsuk-table__caption nhsuk-u-visually-hidden"
                    >
                      Rejected list marked as done
                    </caption>
                    <thead role="rowgroup" class="nhsuk-table__head">
                      <tr role="row">
                        <th role="columnheader" scope="col">
                          <div
                            class="nhsuk-checkboxes__item nhsuk-checkboxes--small"
                          >
                            <input
                              id="checkbox-undo-done-selectall-rejected"
                              data-cy="checkbox-undo-done-selectall-rejected"
                              class="nhsuk-checkboxes__input"
                              type="checkbox"
                              name="checkbox-undo-done-selectall-rejected"
                              [(ngModel)]="allSelectedForMarkAsUndone"
                              (change)="
                                selectAllForMarkAsDone(
                                  rejectedInvitations,
                                  true
                                )
                              "
                              aria-label="Select all"
                              title="Select all"
                            />
                            <label
                              class="nhsuk-label nhsuk-checkboxes__label"
                              for="checkbox-undo-done-selectall-rejected"
                              ><span class="nhsuk-u-visually-hidden"
                                >Select all</span
                              >
                            </label>
                          </div>
                        </th>
                        <th role="columnheader" scope="col">Applicant name</th>
                        <th role="columnheader" scope="col">Rejected on</th>
                        <th role="columnheader" scope="col">Raised by</th>
                        <th role="columnheader" scope="col">ODS code</th>
                        <th role="columnheader" scope="col">Reason(s)</th>
                      </tr>
                    </thead>
                    <tbody
                      class="nhsuk-table__body"
                      *ngFor="let invitation of rejectedInvitations"
                    >
                      <tr
                        *ngIf="invitation.done"
                        role="row"
                        class="nhsuk-table__row"
                      >
                        <td role="cell" class="nhsuk-table__cell">
                          <span class="nhsuk-table-responsive__heading"
                            >Select item
                          </span>
                          <div
                            class="nhsuk-checkboxes__item nhsuk-checkboxes--small"
                          >
                            <input
                              class="nhsuk-checkboxes__input"
                              type="checkbox"
                              id="mark-as-done-{{ invitation.invitationId }}"
                              name="mark-as-done-{{ invitation.invitationId }}"
                              [(ngModel)]="invitation.selectedForMarkAsDone"
                              [(checked)]="invitation.selectedForMarkAsDone"
                              aria-label="Mark as done {{
                                invitation.givenName +
                                  ' ' +
                                  invitation.familyName
                              }}"
                            />
                            <label
                              class="nhsuk-label nhsuk-checkboxes__label"
                              for="mark-as-done-{{ invitation.invitationId }}"
                            >
                              <span class="nhsuk-u-visually-hidden"
                                >Select</span
                              >
                            </label>
                          </div>
                        </td>
                        <td role="cell" class="nhsuk-table__cell">
                          <span class="nhsuk-table-responsive__heading"
                            >Full name
                          </span>
                          <a
                            attr.data-cy="link-rejected-{{
                              invitation.invitationId
                            }}"
                            [state]="{
                              invitation: invitation,
                              prevPage: '/invitation/dashboard',
                              tabSelected: 'rejected'
                            }"
                            [routerLink]="[
                              '/invitation/invite-details',
                              invitation.invitationId
                            ]"
                          >
                            {{ invitation.givenName }}
                            {{ invitation.familyName }}</a
                          >
                        </td>
                        <td role="cell" class="nhsuk-table__cell">
                          <span class="nhsuk-table-responsive__heading"
                            >Rejected on</span
                          >
                          {{
                            invitation.statusLastUpdatedDateUtc
                              | date: "d MMM yyyy"
                          }}
                        </td>
                        <td role="cell" class="nhsuk-table__cell">
                          <span class="nhsuk-table-responsive__heading"
                            >Raised by</span
                          >
                          {{ invitation.createdByUserName }}
                        </td>
                        <td role="cell" class="nhsuk-table__cell">
                          <span class="nhsuk-table-responsive__heading"
                            >ODS Code</span
                          >
                          {{ invitation.createdByUserOrgCode }}
                        </td>
                        <td
                          role="cell"
                          class="nhsuk-table__cell"
                          attr.data-cy="reject-reason-{{
                            invitation.invitationId
                          }}"
                        >
                          <span class="nhsuk-table-responsive__heading"
                            >Reason</span
                          >
                          <p *ngIf="invitation.status == 37">
                            Application expired. Applicant has not submitted ID
                            documents within 15 days of receiving link.
                          </p>
                          <p *ngIf="invitation.failedDocument">
                            {{ invitation.failedDocument }}
                          </p>
                          <p
                            *ngIf="invitation.failedReason"
                            [innerHTML]="
                              invitation.failedReason
                                | IdFailureCheckReasonParagraphPipe
                                  : invitation.photoIdOneType
                                  : invitation.photoIdTwoType
                                  : false
                            "
                          ></p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </ng-template>
              </div>
            </details>
          </div>
        </div>

        <!--Cancelled-->
        <div id="cancelled" class="tabcontent" role="tabpanel">
          <div *ngIf="cancelledInvitations.length == 0">
            <p>Your organisation has no recent cancelled applications.</p>
          </div>
          <div *ngIf="cancelledInvitations.length != 0">
            <h3 class="nhsuk-u-margin-bottom-0">Cancelled applications</h3>

            <div class="nhsuk-grid-row">
              <div class="nhsuk-grid-column-two-thirds">&nbsp;</div>
              <div class="nhsuk-grid-column-one-third">
                <div style="float: right">
                  <div class="nhsuk-form-group">
                    <label class="nhsuk-label" for="select-sort-cancelled">
                      Sort by
                    </label>
                    <select
                      data-cy="select-sort-cancelled"
                      class="nhsuk-select"
                      id="select-sort-cancelled"
                      name="select-sort-cancelled"
                      (change)="sortChanged('cancelled', $event.target.value)"
                    >
                      <option value="givenName">Applicant name</option>
                      <option value="statusLastUpdatedDateUtc" selected>
                        Newest
                      </option>
                      <option value="createdByUserOrgCode">ODS code</option>
                      <option value="statusLastUpdatedDateUtcOldest">
                        Oldest
                      </option>
                      <option value="createdByUserName">Raised by</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <table role="table" class="nhsuk-table-responsive">
              <caption class="nhsuk-table__caption nhsuk-u-visually-hidden">
                Cancelled list
              </caption>
              <thead role="rowgroup" class="nhsuk-table__head">
                <tr role="row">
                  <th role="columnheader" scope="col">Applicant name</th>
                  <th role="columnheader" scope="col">Cancelled on</th>
                  <th role="columnheader" scope="col">Raised by</th>
                  <th role="columnheader" scope="col">ODS code</th>
                  <th role="columnheader" scope="col">Reason</th>
                </tr>
              </thead>
              <tbody class="nhsuk-table__body">
                <tr
                  *ngFor="let invitation of cancelledInvitations"
                  role="row"
                  class="nhsuk-table__row"
                >
                  <td role="cell" class="nhsuk-table__cell">
                    <span class="nhsuk-table-responsive__heading"
                      >Full name
                    </span>
                    <a
                      attr.data-cy="link-cancelled-{{
                        invitation.invitationId
                      }}"
                      [state]="{
                        invitation: invitation,
                        prevPage: '/invitation/dashboard',
                        tabSelected: 'cancelled'
                      }"
                      [routerLink]="[
                        '/invitation/invite-details',
                        invitation.invitationId
                      ]"
                    >
                      {{ invitation.givenName }} {{ invitation.familyName }}</a
                    >
                  </td>
                  <td role="cell" class="nhsuk-table__cell">
                    <span class="nhsuk-table-responsive__heading"
                      >Cancelled on</span
                    >
                    {{ invitation.cancelledDateUtc | date: "d MMM yyyy" }}
                  </td>
                  <td role="cell" class="nhsuk-table__cell">
                    <span class="nhsuk-table-responsive__heading"
                      >Raised by</span
                    >
                    {{ invitation.createdByUserName }}
                  </td>
                  <td role="cell" class="nhsuk-table__cell">
                    <span class="nhsuk-table-responsive__heading"
                      >ODS Code</span
                    >
                    {{ invitation.createdByUserOrgCode }}
                  </td>
                  <td
                    role="cell"
                    class="nhsuk-table__cell"
                    attr.data-cy="cancelled-reason-{{
                      invitation.invitationId
                    }}"
                  >
                    <span class="nhsuk-table-responsive__heading">Reason</span>
                    <p *ngIf="invitation.cancelledReason != 3">
                      {{ invitation.cancelledReason | CancelledReasonPipe }}
                    </p>
                    <p *ngIf="invitation.cancelledReason == 3">
                      Other; {{ invitation.cancelledReasonNotes }}
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
      <p class="nhsuk-u-margin-top-6">
        This is a new service - your
        <a
          target="_blank"
          rel="noopener"
          href="https://feedback.digital.nhs.uk/jfe/form/SV_3LhsNhjgUSYpyaa"
          >feedback</a
        >
        will help us improve it.
      </p>
    </div>
  </div>
</div>
