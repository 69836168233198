import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { RouterService } from "src/app/services/router.service";
import { TextInputComponent } from "../../common/form-fields/text-inputs/text-input.component";
import { ErrorSummaryMessage } from "../../../models/error-summary-message";
import { InvitationConfirmation } from "./../../../../store/states/invitee-spa.state";
import { isOtpNumberValid } from "../../../utils/data-validation/data-validation";
import { DataValidationReasons } from "../../../models/data-validation-model";
import { SESSION_STORAGE, StorageService } from "ngx-webstorage-service";
import { VerifyInvitationService } from "../../../services/verify-invitation.service";
import { Title } from "@angular/platform-browser";
import { InviteeDetails } from "src/app/utils/invitee-details";

@Component({
  selector: "app-one-time-passcode",
  templateUrl: "./one-time-passcode.component.html",
  styleUrls: ["./one-time-passcode.component.css"],
})
export class OneTimePasscodeComponent implements OnInit {
  mobile: string;
  otpNumberValid: boolean;
  otpNumberErrorMessage = "";
  otpStatus: string;
  invitationConfirmation: InvitationConfirmation;
  breadcrumbRoute = "/invitee/send-otp";
  breadcrumbText = "Back";
  submitButtonClicked = false;
  securityCodeLengthMsg = "Enter a security code with 6 numbers.";
  otp = "";

  summaryErrorMessages = [];
  readonly errorMessages = {
    otpNumber: {
      lengthTooShort: this.securityCodeLengthMsg,
      lengthTooLong: this.securityCodeLengthMsg,
      empty: "Enter your security code.",
      invalid: "Invalid request.",
      unknown: "Enter the correct security code.",
      exceeded: "Security code access count exceeded.",
      unexpectedCharacters: this.securityCodeLengthMsg,
      expired:
        "Your security code has expired please send the security code again.",
    },
  };

  @ViewChild("otpNumberInput") otpNumberInput: TextInputComponent;

  constructor(
    private routerService: RouterService,
    private inviteVerificationService: VerifyInvitationService,
    private title: Title,
    @Inject(SESSION_STORAGE) private sessionStorage: StorageService,
    private inviteeDetails: InviteeDetails,
  ) {
    this.title.setTitle("Enter your security code");
  }

  async ngOnInit(): Promise<void> {
    const invitationId = this.sessionStorage.get("invitationId");
    const mobileLast4 = this.sessionStorage.get("mobile");
    if (invitationId == undefined || mobileLast4 == undefined) {
      this.routerService.redirectToInvalidRequest();
    }
    this.mobile = mobileLast4;
    this.invitationConfirmation = this.inviteeDetails.getInviteeDetails();
  }

  submitAnswers(): void {
    this.submitButtonClicked = true;
    this.updateOtpNumberAnswer(this.otpNumberInput.answer);
    this.inviteVerificationService
      .getOtp(
        this.sessionStorage.get("invitationId"),
        this.otpNumberInput.answer,
      )
      .subscribe({
        next: (response) => {
          this.otpStatus = String(response.status);
          this.setOtpErrorMessage();
          if (this.otpStatus == "Valid") {
            this.inviteeDetails.createInviteeDetails();
            this.setSessionOtp();
            this.routerService.redirectToConfirmName();
          }
        },
        error: (err) => {
          if (err.status == 400) {
            this.routerService.redirectToInvalidRequest();
          } else {
            this.routerService.redirectToInternalServerError();
          }
        },
        complete: () => {
          this.submitButtonClicked = false;
        },
      });
  }

  setOtpErrorMessage(): void {
    if (this.otpStatus === "Valid") {
      this.otpNumberErrorMessage = "";
      this.otpNumberValid = true;
    } else if (!this.otpNumberInput.answer) {
      this.otpNumberErrorMessage =
        this.errorMessages.otpNumber[DataValidationReasons.empty];
      this.otpNumberValid = false;
    } else if (this.otpStatus === "Invalid") {
      this.otpNumberErrorMessage =
        this.errorMessages.otpNumber[DataValidationReasons.unknown];
      this.otpNumberValid = false;
    } else if (this.otpStatus === "Locked") {
      this.routerService.redirectToOtpLocked();
      this.otpNumberValid = false;
    } else if (this.otpStatus === "OtpExpired") {
      this.routerService.redirectToOtpExpired();
      this.otpNumberValid = false;
    } else {
      this.otpNumberErrorMessage =
        this.errorMessages.otpNumber[DataValidationReasons.invalid];
      this.otpNumberValid = false;
    }
  }

  updateOtpNumberAnswer(otpNumber: string): void {
    otpNumber = otpNumber.replace(/ /g, "");
    const validationResult = isOtpNumberValid(otpNumber);
    if (!validationResult.validity) {
      this.otpNumberErrorMessage =
        this.errorMessages.otpNumber[validationResult.errorReason];
      this.otpNumberValid = false;
    }
    this.otp = otpNumber;
    this.updateErrorSummary();
  }

  updateErrorSummary() {
    const updatedErrorSummary = Array<ErrorSummaryMessage>();
    if (this.otpNumberErrorMessage) {
      updatedErrorSummary.push({
        message:
          this.otpNumberErrorMessage === this.errorMessages.otpNumber.empty
            ? this.errorMessages.otpNumber.empty
            : this.errorMessages.otpNumber.unknown,
        id: "otp-number-input",
      });
    }
    this.summaryErrorMessages = updatedErrorSummary;
  }

  setSessionOtp(): void {
    let invitationnConfirmation = this.inviteeDetails.getInviteeDetails();
    invitationnConfirmation.otpNumber = this.otp;
    this.inviteeDetails.setInviteeDetails(invitationnConfirmation);
  }
}
