import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Location } from "@angular/common";
import {
  InvitationDetails,
  InvitationStatus,
} from "src/app/models/invitationDetails";
import { GetInvitationService } from "../../../services/get-invitation.service";
import { RouterService } from "src/app/services/router.service";

import { Invitation } from "src/store/states/spa.state";
import { Title } from "@angular/platform-browser";
import { SendInvitationService } from "src/app/services/send-invitation.service";
import { ErrorSummaryMessage } from "src/app/models/error-summary-message";
import { UserSettings } from "src/app/models/userSettings";
import { UserSettingsService } from "src/app/services/user-settings.service";
import { SESSION_STORAGE, StorageService } from "ngx-webstorage-service";

@Component({
  selector: "app-tab-dashboard",
  templateUrl: "./tab-dashboard.component.html",
  styleUrls: ["./tab-dashboard.component.css"],
})
export class TabDashboardComponent implements OnInit {
  resendRequiredInvitations: Invitation[];
  inProgressInvitations: Invitation[];
  approvedInvitations: Invitation[];
  duplicatesInvitations: Invitation[];
  rejectedInvitations: Invitation[];
  cancelledInvitations: Invitation[];
  loading: boolean = true;
  actionSuccessful: boolean = false;
  actionMessage: string;
  canSubmitResend: boolean = false;
  allSelectedForResend: boolean = false;
  allselectedForMarkAsDone: boolean = false;
  allSelectedForMarkAsUndone: boolean = false;
  processingResends: boolean = false;
  processingCancels: boolean = false;
  processingMarkAsDone: boolean = false;
  resendList: string[] = [];
  markAsDoneSubmissionList: string[] = [];
  actionErrorMsg: string;
  settings: UserSettings;

  applicantNameColActive: boolean = false;
  lastUpdatedColActive: boolean = false;
  raisedByColActive: boolean = false;
  approvedOnColActive: boolean = false;
  uuidColActive: boolean = false;
  rejectedOnColActive: boolean = false;
  reasonColActive: boolean = false;
  createdDateUtcColActive: boolean = false;
  cancelledOnColActive: boolean = false;
  cisDuplicateMatchCol: boolean = false;

  // Those not marked as Done
  activeApprovedCount: number = 0;
  activeDuplicatesCount: number = 0;
  activeRejectedCount: number = 0;
  // Marked as done
  doneApprovedCount: number = 0;
  doneDuplicatesCount: number = 0;
  doneRejectedCount: number = 0;

  sortAsc: boolean = false;
  newApplicationLink: string = "/invitation/basic-info";
  bulkLink: string = "/invitation/upload-file";

  summaryErrorMessages = Array<ErrorSummaryMessage>();

  @ViewChild("tabResend") tabResend: ElementRef<HTMLElement>;
  @ViewChild("tabInprogress") tabInprogress: ElementRef<HTMLElement>;
  @ViewChild("tabApproved") tabApproved: ElementRef<HTMLElement>;
  @ViewChild("tabDuplicates") tabDuplicates: ElementRef<HTMLElement>;
  @ViewChild("tabRejected") tabRejected: ElementRef<HTMLElement>;
  @ViewChild("tabCancelled") tabCancelled: ElementRef<HTMLElement>;

  constructor(
    private routerService: RouterService,
    private getInvitationService: GetInvitationService,
    private sendInvitationService: SendInvitationService,
    private title: Title,
    private location: Location,
    private userSettings: UserSettingsService,
    @Inject(SESSION_STORAGE) private sessionStorage: StorageService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.title.setTitle("Manage applications");
    this.sessionStorage.remove("cancelList");

    await this.getOrgInvitations().then(() => {
      this.reopenTab();
    });

    await this.getSettings();
  }

  reopenTab() {
    const tabSelected = this.location.getState()["tabSelected"];
    if (tabSelected != undefined) {
      // wait for getOrgInvitations to run, and for this.loading to be set to false
      setTimeout(() => {
        if (!this.loading) {
          if (tabSelected == "inProgress") {
            this.tabInprogress.nativeElement.click();
          } else if (tabSelected == "approved") {
            this.tabApproved.nativeElement.click();
          } else if (tabSelected == "duplicates") {
            this.tabDuplicates.nativeElement.click();
          } else if (tabSelected == "rejected") {
            this.tabRejected.nativeElement.click();
          } else if (tabSelected == "cancelled") {
            this.tabCancelled.nativeElement.click();
          }
        }
      }, 1000);
    }
  }

  mapInvites(invites: InvitationDetails[]): Invitation[] {
    return invites.map((invite) => new Invitation(invite));
  }

  async getOrgInvitations(): Promise<void> {
    this.loading = true;

    this.getInvitationService.getInvitationsByOrg().subscribe({
      next: (response) => {
        let invitations = this.mapInvites(response["body"]);
        this.approvedInvitations = invitations
          .filter((x) => x.status == InvitationStatus.Approved)
          .sort((a, b) =>
            a.statusLastUpdatedDateUtc < b.statusLastUpdatedDateUtc ? 1 : -1,
          );
        this.rejectedInvitations = invitations
          .filter(
            (x) =>
              x.status == InvitationStatus.Rejected ||
              x.status == InvitationStatus["Application expired"],
          )
          .sort((a, b) =>
            a.statusLastUpdatedDateUtc < b.statusLastUpdatedDateUtc ? 1 : -1,
          );
        this.duplicatesInvitations = invitations
          .filter((x) => x.status == InvitationStatus["Duplicate information"])
          .sort((a, b) =>
            a.statusLastUpdatedDateUtc < b.statusLastUpdatedDateUtc ? 1 : -1,
          );
        this.cancelledInvitations = invitations
          .filter((x) => x.status == InvitationStatus["Application cancelled"])
          .sort((a, b) =>
            a.statusLastUpdatedDateUtc < b.statusLastUpdatedDateUtc ? 1 : -1,
          );
        this.resendRequiredInvitations = invitations
          .filter(
            (x) =>
              x.status == InvitationStatus.Locked ||
              x.status == InvitationStatus["Invite resend required"] ||
              x.status == InvitationStatus["Invite email not sent"],
          )
          .sort((a, b) => (a.createdDateUtc < b.createdDateUtc ? 1 : -1));
        this.inProgressInvitations = invitations
          .filter(
            (x) =>
              x.status == InvitationStatus["Awaiting documents"] ||
              x.status == InvitationStatus["Duplicate check in progress"] ||
              x.status == InvitationStatus.Resent ||
              x.status == InvitationStatus.Sent ||
              x.status == InvitationStatus["ID review"] ||
              x.status == InvitationStatus["On hold"] ||
              x.status == InvitationStatus.OtpExpired,
          )
          .sort((a, b) => (a.createdDateUtc < b.createdDateUtc ? 1 : -1));

        this.setDoneCounts();
      },
      error: (error) => {
        this.routerService.handleErrorAuthRoutes(error.status);
        this.loading = false;
      },
      complete: () => {
        this.loading = false;
      },
    });
  }

  selectTab(event, tab): void {
    this.clearMessages();
    this.unselectAllCheckboxes(this.approvedInvitations);
    this.unselectAllCheckboxes(this.duplicatesInvitations);
    this.unselectAllCheckboxes(this.rejectedInvitations);

    let tabContent: any = document.getElementsByClassName("tabcontent");
    for (let i = 0; i < tabContent.length; i++) {
      tabContent[i].style.display = "none";
    }

    let tabLinks: any = document.getElementsByClassName("tablinks");
    for (let i = 0; i < tabLinks.length; i++) {
      tabLinks[i].className = tabLinks[i].className.replace(" active", "");
    }
    document.getElementById(tab).style.display = "block";
    event.currentTarget.className += " active";
  }

  selectTabKeys(id, tab): void {
    let tabContent: any = document.getElementsByClassName("tabcontent");
    for (let i = 0; i < tabContent.length; i++) {
      tabContent[i].style.display = "none";
    }

    let tabLinks: any = document.getElementsByClassName("tablinks");
    for (let i = 0; i < tabLinks.length; i++) {
      tabLinks[i].className = tabLinks[i].className.replace(" active", "");
      tabLinks[i].tabIndex = -1;
    }
    document.getElementById(tab).style.display = "block";
    document.getElementById(id).className += " active";
    const element = document.getElementById(id);
    element.tabIndex = 0;
    setTimeout(() => element.focus(), 0);
  }

  async resendMultipleApplications(): Promise<void> {
    this.resendList = [];
    this.actionSuccessful = false;
    this.checkIfAnySelectedForResendCancel("resend");
    if (
      this.summaryErrorMessages.find((val) => val.id == "all-tabs") !==
      undefined
    ) {
      return;
    }

    this.resendRequiredInvitations.forEach((x, i) => {
      if (x.selectedForResend) {
        this.resendList.push(x.invitationId);
      }
    });

    this.processingResends = true;
    await this.sendInvitationService
      .resendMultipleInvitations(this.resendList)
      .then(() => {
        this.setActionStatus(true);
        this.actionMessage = "Application(s) resent successfully";
      })
      .catch((exc) => {
        if (exc.status == 400) {
          this.setActionStatus(false, exc.error);
        } else {
          this.actionErrorMsg = "Failed to resend applications.";
        }
      })
      .finally(async () => {
        await this.getOrgInvitations();
        this.processingResends = false;
      });
  }

  cancelMultipleApplications(): void {
    this.resendList = [];
    this.actionSuccessful = false;
    const selected = this.checkIfAnySelectedForResendCancel("cancel");
    if (!selected) {
      return;
    }

    this.processingCancels = true;
    this.resendRequiredInvitations.forEach((x) => {
      if (x.selectedForResend) {
        this.resendList.push(x.invitationId);
      }
    });

    this.sessionStorage.set("cancelList", this.resendList);
    this.routerService.redirectToCancelMultiple();
  }

  async submitMarkDone(
    invitationsList: Invitation[],
    doneFlag: boolean,
  ): Promise<void> {
    this.markAsDoneSubmissionList = [];
    this.clearMessages();

    this.checkIfAnyselectedForMarkAsDone(invitationsList, doneFlag);
    if (
      this.summaryErrorMessages.find((val) => val.id == "all-tabs") !==
      undefined
    ) {
      return;
    }

    this.unselectOppositeCheckboxes(invitationsList, doneFlag);

    invitationsList.forEach((x, i) => {
      if (x.selectedForMarkAsDone) {
        this.markAsDoneSubmissionList.push(x.invitationId);
      }
    });

    this.processingMarkAsDone = true;
    await this.sendInvitationService
      .markAsDoneInvitations(this.markAsDoneSubmissionList, doneFlag)
      .then(() => {
        this.setActionStatus(true);
        let action = "marked as done";
        if (!doneFlag) {
          action = "unmarked as done";
        }
        this.actionMessage = "Application(s) successfully " + action;
        this.unselectAllCheckboxes(invitationsList);
      })
      .catch((exc) => {
        if (exc.status == 400) {
          this.setActionStatus(false, exc.error);
        } else {
          this.actionErrorMsg = "Failed to update applications";
        }
      })
      .finally(() => {
        this.getOrgInvitations();
        this.processingMarkAsDone = false;
      });
  }

  setActionStatus(succeeded: boolean, errorMessage: string = ""): void {
    this.actionSuccessful = succeeded;
    if (!succeeded) {
      this.actionErrorMsg = errorMessage;
    }
    document
      .querySelector("#resend-status")
      .scrollIntoView({ behavior: "smooth" });
  }

  checkIfAnySelectedForResendCancel(action: string): boolean {
    this.clearMessages();
    let anySelectedForResend =
      this.resendRequiredInvitations.find((val) => val.selectedForResend) !==
      undefined;
    if (anySelectedForResend !== true) {
      this.summaryErrorMessages.push({
        message: "Select an application to " + action,
        id: "all-tabs",
      });
      return false;
    } else {
      return true;
    }
  }

  clearMessages(): void {
    this.summaryErrorMessages = Array<ErrorSummaryMessage>();
    this.actionSuccessful = false;
    this.actionMessage = undefined;
  }

  selectAllForResend(): void {
    this.allSelectedForResend = !this.allSelectedForResend;

    this.resendRequiredInvitations.forEach((x) => {
      if (!this.allSelectedForResend && x.selectedForResend) {
        x.selectedForResend = false;
      } else {
        x.selectedForResend = true;
      }
    });
  }

  async getSettings() {
    try {
      await this.userSettings
        .getSettings()
        .then((response) => {
          this.settings = this.userSettings.mapUserSettings(response["body"]);
          if (
            !this.settings.contactEmailVerified ||
            !this.settings.supportEmailVerified
          ) {
            this.newApplicationLink = "/invitation/before-you-start";
            this.bulkLink = "/invitation/before-you-start";
          } else {
            this.sessionStorage.set("contactEmail", this.settings.contactEmail);
          }
        })
        .catch((err) => {
          this.routerService.handleErrorAuthRoutes(err.status);
        });
    } catch (error) {
      this.routerService.handleErrorAuthRoutes(error.status);
    }
  }

  sortChanged(tab: string, columnName: string): void {
    switch (tab) {
      case "resendRequired":
        this.sortList(this.resendRequiredInvitations, columnName);
        break;
      case "inProgress":
        this.sortList(this.inProgressInvitations, columnName);
        break;
      case "duplicates":
        this.sortList(this.duplicatesInvitations, columnName);
        break;
      case "rejected":
        this.sortList(this.rejectedInvitations, columnName);
        break;
      case "approved":
        this.sortList(this.approvedInvitations, columnName);
        break;
      case "cancelled":
        this.sortList(this.cancelledInvitations, columnName);
        break;
    }
  }

  sortList(listToSort: Invitation[], columnName: string) {
    switch (columnName) {
      case "givenName":
        listToSort.sort((a, b) => (a.givenName > b.givenName ? 1 : -1));
        break;
      case "createdDateUtc":
        listToSort.sort((a, b) =>
          a.createdDateUtc < b.createdDateUtc ? 1 : -1,
        );
        break;
      case "createdDateUtcOldest":
        listToSort.sort((a, b) =>
          a.createdDateUtc > b.createdDateUtc ? 1 : -1,
        );
        break;
      case "createdByUserName":
        listToSort.sort((a, b) =>
          a.createdByUserName > b.createdByUserName ? 1 : -1,
        );
        break;
      case "statusLastUpdatedDateUtc":
        listToSort.sort((a, b) =>
          a.statusLastUpdatedDateUtc < b.statusLastUpdatedDateUtc ? 1 : -1,
        );
        break;
      case "createdByUserOrgCode":
        listToSort.sort((a, b) =>
          a.createdByUserOrgCode < b.createdByUserOrgCode ? 1 : -1,
        );
        break;
      case "statusLastUpdatedDateUtcOldest":
        listToSort.sort((a, b) =>
          a.statusLastUpdatedDateUtc > b.statusLastUpdatedDateUtc ? 1 : -1,
        );
        break;
    }
  }

  setDoneCounts(): void {
    this.activeApprovedCount = this.approvedInvitations.filter(
      (x) => x.done == undefined,
    ).length;
    this.activeDuplicatesCount = this.duplicatesInvitations.filter(
      (x) => x.done == undefined,
    ).length;
    this.activeRejectedCount = this.rejectedInvitations.filter(
      (x) => x.done == undefined,
    ).length;
    this.doneApprovedCount = this.approvedInvitations.filter(
      (x) => x.done,
    ).length;
    this.doneDuplicatesCount = this.duplicatesInvitations.filter(
      (x) => x.done,
    ).length;
    this.doneRejectedCount = this.rejectedInvitations.filter(
      (x) => x.done,
    ).length;
  }

  unselectAllCheckboxes(invitationsList: Invitation[]): void {
    invitationsList.forEach((x) => {
      x.selectedForMarkAsDone = false;
    });
    this.allselectedForMarkAsDone = false;
    this.allSelectedForMarkAsUndone = false;
  }

  unselectOppositeCheckboxes(
    invitationsList: Invitation[],
    doneFlag: boolean,
  ): void {
    invitationsList.forEach((x) => {
      if (!doneFlag) {
        if (x.done == undefined) {
          x.selectedForMarkAsDone = false;
        }
      } else if (x.done != undefined) {
        x.selectedForMarkAsDone = false;
      }
    });
  }

  checkIfAnyselectedForMarkAsDone(
    invitationsList: Invitation[],
    doneFlag: boolean,
  ): void {
    this.clearMessages();

    let action = "mark as done";
    if (!doneFlag) {
      action = "undo mark as done";
    }

    let anyselectedForMarkAsDone =
      invitationsList.find((val) => val.selectedForMarkAsDone) !== undefined;
    if (!anyselectedForMarkAsDone) {
      this.summaryErrorMessages.push({
        message: "Select an application or applications to " + action,
        id: "all-tabs",
      });
    }
  }

  selectAllForMarkAsDone(
    invitationsList: Invitation[],
    existingDoneFlag: boolean,
  ): void {
    invitationsList.forEach((x) => {
      if (!existingDoneFlag && x.done == undefined) {
        if (!this.allselectedForMarkAsDone && x.selectedForMarkAsDone) {
          x.selectedForMarkAsDone = false;
        } else {
          x.selectedForMarkAsDone = true;
        }
      } else if (existingDoneFlag && x.done) {
        if (!this.allSelectedForMarkAsUndone && x.selectedForMarkAsDone) {
          x.selectedForMarkAsDone = false;
        } else {
          x.selectedForMarkAsDone = true;
        }
      }
    });
  }
}
