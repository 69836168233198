import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { RouterService } from "src/app/services/router.service";
import { ErrorSummaryMessage } from "../../../models/error-summary-message";
import { InvitationConfirmation } from "../../../../store/states/invitee-spa.state";
import { Subscription } from "rxjs";
import { InviteeDetails } from "src/app/utils/invitee-details";

@Component({
  selector: "app-delivery",
  templateUrl: "./delivery.component.html",
})
export class DeliveryComponent implements OnInit {
  breadcrumbRoute = "/invitee/profile-photo-review";
  breadcrumbText = "Back";

  sameDeliveryAddress: boolean;
  deliveryOptionValid: boolean;

  summaryErrorMessages = [];
  invitationConfirmationSubscription: Subscription;
  invitationConfirmation: InvitationConfirmation;
  constructor(
    private routerService: RouterService,
    private title: Title,
    private inviteeDetails: InviteeDetails,
  ) {}

  async ngOnInit(): Promise<void> {
    this.title.setTitle("Select delivery address");
    this.invitationConfirmation = this.inviteeDetails.getInviteeDetails();
  }

  onSubmitButtonClicked(): void {
    this.validOptionSelected();
    if (this.deliveryOptionValid) {
      if (this.sameDeliveryAddress) {
        this.invitationConfirmation.deliveryAddress =
          this.invitationConfirmation.tuAddress;
        this.inviteeDetails.setInviteeDetails(this.invitationConfirmation);
        this.routerService.redirectToSaveSelection();
      } else {
        this.routerService.redirectToConfirmPostcode("delivery");
      }
    }
  }

  updateDeliveryRadioValue(value) {
    this.sameDeliveryAddress = value;
  }

  validOptionSelected() {
    if (this.sameDeliveryAddress === undefined) {
      this.deliveryOptionValid = false;
    } else {
      this.deliveryOptionValid = true;
    }
    this.updateErrorSummary();
  }

  updateErrorSummary() {
    const updatedErrorSummary = Array<ErrorSummaryMessage>();
    if (!this.deliveryOptionValid) {
      updatedErrorSummary.push({
        message: "Please select one of the options.",
        id: "delivery-same",
      });
    }

    this.summaryErrorMessages = updatedErrorSummary;
  }
}
