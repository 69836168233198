import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { RouterService } from "src/app/services/router.service";
import { ErrorSummaryMessage } from "../../../models/error-summary-message";
import { InvitationConfirmation } from "./../../../../store/states/invitee-spa.state";
import { Title } from "@angular/platform-browser";
import { PostcodeLookupService } from "src/app/services/postcode-lookup.service";
import { AddressDetailsWrapper } from "src/app/models/addressDetails";
import { Address } from "src/app/models/address";
import { stripSpecialCharacters } from "src/app/utils/data-validation/data-validation";
import { InviteeDetails } from "src/app/utils/invitee-details";

@Component({
  selector: "app-select-address",
  templateUrl: "./select-address.component.html",
})
export class SelectAddressComponent implements OnInit {
  breadcrumbRoute = "";
  breadcrumbText = "Back";
  searchPostcode = "";
  forwardRoute = "";
  type = "";
  manualAddressLink = "";
  headerText = "";

  invitationConfirmation: InvitationConfirmation;
  addresses: Address[];
  selectedAddressId: number = -1;
  invitationId: string;
  addressErrorMessage = "";
  summaryErrorMessages = [];
  validAddressSelected: boolean = false;

  readonly errorMessages = {
    address: {
      default: "Please select an address.",
    },
  };

  constructor(
    private route: ActivatedRoute,
    private routerService: RouterService,
    private title: Title,
    private postcodeLookupService: PostcodeLookupService,
    private inviteeDetails: InviteeDetails,
  ) {}

  async ngOnInit(): Promise<void> {
    this.type = this.route.snapshot.paramMap.get("type");
    this.invitationConfirmation = this.inviteeDetails.getInviteeDetails();
    this.setPostcodeDisplay();
    this.getAddresses();
  }

  setPostcodeDisplay(): void {
    if (this.type == "tu") {
      this.searchPostcode = this.invitationConfirmation.tuPostcode;
      this.headerText = "Choose an address";
      this.title.setTitle("Choose an address");
      this.manualAddressLink = "/invitee/confirm-address/tu";
      this.forwardRoute = "/invitee/check-your-address";
      this.breadcrumbRoute = "/invitee/confirm-postcode/tu";
    } else {
      this.headerText = "Choose a delivery address";
      this.title.setTitle("Choose a delivery address");
      this.searchPostcode = this.invitationConfirmation.deliveryPostcode;
      this.manualAddressLink = "/invitee/confirm-address/delivery";
      this.forwardRoute = "/invitee/save-selection";
      this.breadcrumbRoute = "/invitee/confirm-postcode/delivery";
    }
  }

  mapAddress(addresses: AddressDetailsWrapper[]): Address[] {
    return addresses.map((address, index) => new Address(address.DPA, index));
  }

  getAddresses(): void {
    this.postcodeLookupService
      .getAddressByPostcode(this.searchPostcode)
      .subscribe({
        next: (response) => {
          if (response["body"]["results"] == undefined) {
            this.routerService.redirectToConfirmAddress(this.type);
          } else if (response["body"]["results"].length == 0) {
            this.routerService.redirectToConfirmAddress(this.type);
          } else {
            this.addresses = this.mapAddress(response["body"]["results"]);
          }
        },
        error: () => {
          this.routerService.redirectToConfirmAddress(this.type);
        },
      });
  }

  updateErrorSummary(): void {
    const updatedErrorSummary = Array<ErrorSummaryMessage>();
    if (this.addressErrorMessage) {
      updatedErrorSummary.push({
        message: this.errorMessages.address.default,
        id: "select-address",
      });
    }
    this.summaryErrorMessages = updatedErrorSummary;
  }

  setAddress(): void {
    if (this.selectedAddressId === -1) {
      this.addressErrorMessage = this.errorMessages.address.default;
      this.updateErrorSummary();
    } else {
      this.validAddressSelected = true;
      this.addressErrorMessage = "";
      this.updateErrorSummary();
      let selectedAddress = this.addresses.find(
        (element) => element.id === this.selectedAddressId,
      );

      if (this.type == "tu") {
        this.invitationConfirmation.tuBuildingName =
          selectedAddress.buildingName ?? selectedAddress.buildingNumber;
        this.invitationConfirmation.tuAddressLineOne =
          selectedAddress.thoroughfareName;
        this.invitationConfirmation.tuTownCity = selectedAddress.postTown;

        this.invitationConfirmation.tuAddress = stripSpecialCharacters(
          selectedAddress.address.trim(),
        );
      } else {
        this.invitationConfirmation.deliveryBuildingName =
          selectedAddress.buildingName ?? selectedAddress.buildingNumber;
        this.invitationConfirmation.deliveryAddressLineOne =
          selectedAddress.thoroughfareName;
        this.invitationConfirmation.deliveryTownCity = selectedAddress.postTown;
        this.invitationConfirmation.deliveryAddress =
          selectedAddress.address.trim();
      }
    }
  }

  checkAddressSelected(): void {
    if (this.selectedAddressId === -1) {
      this.validAddressSelected = false;
    } else {
      this.validAddressSelected = true;
    }
  }

  submitAnswer(): void {
    if (!this.validAddressSelected) {
      this.addressErrorMessage = this.errorMessages.address.default;
      this.updateErrorSummary();
      return;
    }
    this.setAddress();
    this.inviteeDetails.setInviteeDetails(this.invitationConfirmation);
    this.routerService.redirectTo(this.forwardRoute);
  }
}
