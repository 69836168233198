<session-check></session-check>
<error-summary [errorSummaryMessages]="summaryErrorMessages"></error-summary>
<div class="nhsuk-grid-row">
  <div class="nhsuk-grid-column-two-thirds">
    <div *ngIf="otherEmailVerified">
      <div class="nhsuk-form-group">
        <fieldset class="nhsuk-fieldset" aria-describedby="email-hint">
          <legend class="nhsuk-fieldset__legend nhsuk-fieldset__legend--xl">
            <h1
              autofocus
              tabindex="-1"
              *ngIf="category == 'support'"
              class="nhsuk-fieldset__heading"
            >
              Help for your applicants
            </h1>
            <h1
              autofocus
              tabindex="-1"
              *ngIf="category == 'contact'"
              class="nhsuk-fieldset__heading"
            >
              Enter a contact email address
            </h1>
          </legend>

          <p *ngIf="category == 'support'" class="nhsuk-u-margin-top-5">
            Select an email address that will be displayed to your applicants,
            so they can contact you or your team if they need support.
          </p>
          <p *ngIf="category == 'contact'" class="nhsuk-u-margin-top-5">
            The ID checking team might use this to contact you about
            applications you've raised.
          </p>
          <p *ngIf="category == 'support'" class="nhsuk-hint" id="email-hint">
            This can be a shared mailbox
          </p>

          <div class="nhsuk-radios nhsuk-radios--conditional">
            <div class="nhsuk-radios__item">
              <input
                class="nhsuk-radios__input"
                id="same-1"
                name="email-input"
                type="radio"
                value="same"
                (change)="oldEmailSelected($event)"
              />
              <label class="nhsuk-label nhsuk-radios__label" for="same-1">
                {{ otherEmail }}
              </label>
            </div>

            <div class="nhsuk-radios__item">
              <input
                class="nhsuk-radios__input"
                id="different-2"
                name="email-input"
                type="radio"
                value="different"
                aria-controls="conditional-email-2"
                aria-expanded="false"
                (change)="newEmailSelected($event)"
              />
              <label class="nhsuk-label nhsuk-radios__label" for="different-2">
                Use a different email address
              </label>
            </div>

            <div
              *ngIf="useNewEmail"
              class="nhsuk-radios__conditional nhsuk-radios__conditional--hidden"
              id="conditional-email-2"
            >
              <div class="nhsuk-form-group">
                <label class="nhsuk-label" for="email-input">
                  Email address
                </label>
                <text-input
                  #emailInput
                  [id]="'email-input'"
                  [errorMessage]="emailErrorMessage"
                  [value]="email"
                  [uppercase]="false"
                  (questionAnswer)="updateEmailAnswer($event)"
                  size="nhsuk-input--width-20"
                  autocomplete="off"
                  [cypressValue]="'email-input'"
                ></text-input>
              </div>
            </div>
          </div>
        </fieldset>
      </div>
      <button
        [disabled]="loading"
        (click)="submitChoiceAnswer()"
        id="continue"
        class="nhsuk-button"
        type="submit"
      >
        Continue
      </button>
    </div>
    <div *ngIf="!otherEmailVerified">
      <h1 *ngIf="category == 'support'">Enter support details</h1>
      <h1 *ngIf="category == 'contact'">Enter a contact email address</h1>
      <p *ngIf="category == 'support'">
        Enter an email address that will be displayed to your applicants, so
        they can contact you or your team if they need support.
      </p>
      <p *ngIf="category == 'contact'">
        The ID checking team might use this to contact you about applications
        you've raised.
      </p>
      <div class="nhsuk-form-group">
        <label
          *ngIf="category == 'support'"
          class="nhsuk-label"
          for="email-input"
        >
          Email address
        </label>
        <div
          *ngIf="category == 'support'"
          class="nhsuk-hint"
          id="email-input-hint"
        >
          This can be a shared mailbox
        </div>
        <text-input
          #emailInput
          [id]="'email-input'"
          [errorMessage]="emailErrorMessage"
          [value]="email"
          [uppercase]="false"
          (questionAnswer)="updateEmailAnswer($event)"
          size="nhsuk-input--width-20"
          [cypressValue]="'email-input'"
          autocomplete="off"
        ></text-input>
      </div>

      <button
        [disabled]="loading"
        (click)="submitAnswer()"
        id="continue"
        class="nhsuk-button"
        type="submit"
      >
        Continue
      </button>
    </div>
    <div *ngIf="!settings">
      <progress-indicator spinnerText="Please wait"></progress-indicator>
    </div>
  </div>
</div>
