import {
  Component,
  Input,
  ViewChild,
  Output,
  EventEmitter,
  AfterViewInit,
  HostListener,
} from "@angular/core";
import { DomSanitizer, SafeStyle } from "@angular/platform-browser";
import panzoom from "@panzoom/panzoom";

@Component({
  selector: "id-checker-id-photo",
  templateUrl: "./id-photo.component.html",
  styleUrls: ["./id-photo.component.scss"],
})
export class IdPhotoComponent implements AfterViewInit {
  @ViewChild("image") image: any;
  @ViewChild("imageContainer") imageContainer: any;

  @Input() photoUrl: string;
  @Input() altText = "";
  @Input() showExpandOption = true;
  @Input() showInstructions = false;
  @Output() isPhotoExpanded = new EventEmitter<boolean>();
  @Input() showRotate = true;

  photoExpand = false;
  currentDegreeRotation = 360;
  currentTranslation = 0;
  imageContainerHeight: number;
  imageContainerWidth: number;
  defaultImageStyle = true;

  imageHeight: number;
  imageWidth: number;
  isImageLoadedAsLandscape: boolean;
  imageMarginLeft: number;

  panzoom: any;
  panzoomSettings = {
    contain: "outside",
    startScale: 1,
    maxScale: 5,
    minScale: 1,
    cursor: "zoom-in",
  };

  constructor(private sanitizer: DomSanitizer) {}

  @HostListener("wheel", ["$event"]) onWheelScroll(event: MouseEvent) {
    if (event.altKey) {
      this.panzoom.zoomWithWheel(event);
    }
  }

  @HostListener("window:resize", ["$event"]) onResize() {
    if (this.isImageLoadedAsLandscape) {
      if (
        this.currentDegreeRotation === 90 ||
        this.currentDegreeRotation === 270
      ) {
        this.imageContainerHeight = this.image.nativeElement.width;
        this.setTranslation();
      } else {
        this.imageContainerHeight = this.image.nativeElement.height;
      }
    }
  }

  ngAfterViewInit() {
    this.panzoom = panzoom(
      this.imageContainer.nativeElement,
      this.panzoomSettings,
    );
  }

  zoomImage() {
    this.panzoom.zoomIn();
  }

  resetPanzoom($event: any) {
    $event.preventDefault();
    this.panzoom.reset(this.panzoomSettings);
  }

  togglePhotoExpand($event: any): void {
    $event.preventDefault();
    this.photoExpand = !this.photoExpand;

    if (this.isImageLoadedAsLandscape) {
      if (
        this.currentDegreeRotation === 180 ||
        this.currentDegreeRotation === 360
      ) {
        this.imageContainerHeight = null;
      } else {
        setTimeout(() => {
          this.imageContainerHeight = this.image.nativeElement.width;
          this.setTranslation();
        }, 10);
      }
    } else if (this.isImageLoadedAsLandscape == undefined) {
      if (
        this.currentDegreeRotation === 90 ||
        this.currentDegreeRotation === 270
      ) {
        this.setLandscapeImagePosition();
      }
    } else {
      this.setLandscapeImagePosition();
    }
    this.isPhotoExpanded.emit(this.photoExpand);
  }

  rotateImage(): void {
    if (this.isImageLoadedAsLandscape === undefined) {
      const rect = this.image.nativeElement.getBoundingClientRect();
      this.isImageLoadedAsLandscape = rect.width > rect.height;
    }

    if (this.currentDegreeRotation === 360) {
      this.currentDegreeRotation = 0;
    }
    this.currentDegreeRotation += 90;

    if (this.isImageLoadedAsLandscape) {
      if (
        this.currentDegreeRotation === 90 ||
        this.currentDegreeRotation === 270
      ) {
        this.imageContainerHeight = this.image.nativeElement.width;
      } else {
        this.imageContainerHeight = this.image.nativeElement.height;
      }
      this.setTranslation();
    } else {
      if (
        this.currentDegreeRotation === 90 ||
        this.currentDegreeRotation === 270
      ) {
        this.setLandscapeImagePosition();
      }
    }
  }

  setLandscapeImagePosition() {
    this.defaultImageStyle = false;

    setTimeout(() => {
      const imgContainerWidth = this.imageContainer.nativeElement.offsetWidth;
      this.image.nativeElement.height = imgContainerWidth;
      this.imageMarginLeft =
        (this.image.nativeElement.height - this.image.nativeElement.width) / 2;
    }, 30);
  }

  setTranslation(): void {
    const imageHeight = this.image.nativeElement.height;
    const imageWidth = this.image.nativeElement.width;

    if (this.currentDegreeRotation === 90) {
      this.currentTranslation = (imageWidth - imageHeight) / 2;
    } else if (this.currentDegreeRotation === 270) {
      this.currentTranslation = -(imageWidth - imageHeight) / 2;
    } else {
      this.currentTranslation = 0;
    }
  }

  get transform(): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(
      `rotate( ${this.currentDegreeRotation}deg) translate(${this.currentTranslation}px, 0)`,
    );
  }
}
