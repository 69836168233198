import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "poaTypePipe" })
export class PoaTypePipe implements PipeTransform {
  private classMapping = {
    BankAccount: "Bank account or credit card statement",
    UtilityBill: "Utility bill",
    MortgageStatement: "Mortgage statement",
    CouncilTax: "Council tax statement",
    TenancyAgreement: "Tenancy agreement",
    Passport: "Passport",
    DrivingLicence: "Driving license",
    ResidencePermit: "Residence permit",
    ArmedForces: "HM armed forces card",
    PassProofOfAgeCard: "PASS proof of age card",
  };

  transform(value: string): string {
    return this.classMapping[value];
  }
}
