<session-check></session-check>
<h1 autofocus tabindex="-1">Before you start</h1>

<p>
  Before you raise your first application, you must provide the following
  contact information.
</p>

<p>Contact email address:</p>

<ul>
  <li>
    The ID checking team might use this to contact you about applications you've
    raised.
  </li>
</ul>

<p>Help for your applicants email address:</p>

<ul>
  <li>
    This will be displayed to your applicants, so they can contact you or your
    team if they need support.
  </li>
</ul>

<p>
  You will need to verify your contact information by entering a security code.
</p>

<p>You can change your contact information later by going to Settings.</p>

<a
  *ngIf="!contactEmailVerified"
  [routerLink]="['/settings/update-email/contact']"
  class="nhsuk-button"
>
  Continue
</a>
<a
  *ngIf="contactEmailVerified"
  [routerLink]="['/settings/update-email/support']"
  class="nhsuk-button"
>
  Continue
</a>
