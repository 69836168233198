<session-check></session-check>
<section *ngIf="loading">
  <progress-indicator spinnerText="please wait"></progress-indicator>
</section>
<div *ngIf="!loading" class="nhsuk-grid-row">
  <div class="nhsuk-grid-column-two-thirds">
    <breadcrumb-link
      [breadcrumbRoute]="breadcrumbRoute"
      [breadcrumbText]="breadcrumbText"
    ></breadcrumb-link>
    <error-summary
      [errorSummaryMessages]="summaryErrorMessages"
    ></error-summary>
    <section class="nhsuk-form-group">
      <h1 autofocus tabindex="-1" class="nhsuk-label-wrapper">
        <label class="nhsuk-label nhsuk-label--xl" for="example">
          Add note
        </label>
      </h1>

      <text-area-input
        #noteInput
        cypressValue="internal-note"
        id="internal-note"
        [value]="note"
        aria-describedby="internal note"
        [errorMessage]="noteErrorMessage"
        (questionAnswer)="updateInternalNoteAnswer($event)"
      >
      </text-area-input>

      <button
        (click)="onSubmit()"
        class="nhsuk-button nhsuk-u-margin-top-4"
        [ngClass]="{ 'spinner-button': loading }"
        [disabled]="loading"
      >
        Save
      </button>
    </section>
  </div>
</div>
