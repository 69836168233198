import { Pipe, PipeTransform } from "@angular/core";
import { IdCheckHeldReason } from "../../../models/invitationDetails";

@Pipe({ name: "idCheckHeldReasonPipe" })
export class IdHeldCheckReasonPipe implements PipeTransform {
  transform(value: number): string {
    if (value == IdCheckHeldReason["Potential Duplicate"]) {
      return "Possible duplicate found. Duplicate check in CIM required";
    } else return IdCheckHeldReason[value];
  }
}
