import { Component, Inject, OnInit } from "@angular/core";
import { SESSION_STORAGE, StorageService } from "ngx-webstorage-service";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "app-otp-failed",
  templateUrl: "./otp-failed.component.html",
})
export class OtpFailedComponent implements OnInit {
  supportEmail: string;
  constructor(
    private title: Title,
    @Inject(SESSION_STORAGE) private sessionStorage: StorageService,
  ) {}

  ngOnInit(): void {
    this.title.setTitle("Security code could not be sent");
    this.supportEmail = this.sessionStorage.get("supportEmail");
  }
}
