<div
  class="nhsuk-form-group"
  [ngClass]="{
    'nhsuk-form-group--error': errorMessage
  }"
>
  <label *ngIf="label" class="nhsuk-label" [for]="id">{{ label }}</label>
  <div class="nhsuk-hint" *ngIf="label">{{ hint }}</div>
  <span
    role="alert"
    *ngIf="errorMessage"
    class="nhsuk-error-message"
    [attr.data-cy]="errorMessageDataCy"
    >{{ errorMessage }}</span
  >

  <textarea
    class="nhsuk-textarea"
    [id]="id"
    [name]="id"
    [rows]="rows"
    [value]="value"
    (change)="updateValue($event.target.value)"
    [attr.data-cy]="cypressValue"
  ></textarea>
</div>
