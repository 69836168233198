import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-invitation-success",
  templateUrl: "./invitation-success.component.html",
  styleUrls: ["./invitation-success.component.css"],
})
export class InvitationSuccessComponent implements OnInit {
  invitationId: string;

  constructor(
    private route: ActivatedRoute,
    private title: Title,
  ) {}

  ngOnInit(): void {
    this.title.setTitle("Application sent");
    this.invitationId = this.route.snapshot.paramMap.get("invitationId");
  }
}
