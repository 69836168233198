import { Component, Inject, OnInit } from "@angular/core";
import { RouterService } from "src/app/services/router.service";
import { ErrorSummaryMessage } from "../../../models/error-summary-message";
import { InvitationConfirmation } from "./../../../../store/states/invitee-spa.state";
import {
  isNameValid,
  isNiNumberValid,
  isOtpNumberValid,
} from "../../../utils/data-validation/data-validation";
import { Title } from "@angular/platform-browser";
import { DuplicateCheckService } from "src/app/services/duplicate-check.service";
import { SESSION_STORAGE, StorageService } from "ngx-webstorage-service";
import { InviteeDetails } from "src/app/utils/invitee-details";

@Component({
  selector: "app-check-your-answers",
  templateUrl: "./check-your-answers.component.html",
})
export class CheckYourAnswersComponent implements OnInit {
  submitButtonClicked = false;
  validationErrors = [];
  invitationConfirmation: InvitationConfirmation;
  breadcrumbRoute = "/invitee/confirm-ni-number";
  breadcrumbText = "Back";

  invitationId: string;
  firstNameValid: boolean;
  familyNameValid: boolean;
  dobValid: boolean;
  niNumberValid: boolean;
  otpNumberValid: boolean;
  addressValid: boolean;

  summaryErrorMessages = [];

  constructor(
    private routerService: RouterService,
    private title: Title,
    private duplicateCheckService: DuplicateCheckService,
    @Inject(SESSION_STORAGE) private sessionStorage: StorageService,
    private inviteeDetails: InviteeDetails,
  ) {}

  async ngOnInit(): Promise<void> {
    this.title.setTitle("Check your details");

    if (!this.sessionStorage.has("invitationId")) {
      this.routerService.redirectToInvalidRequest();
    } else {
      this.invitationId = this.sessionStorage.get("invitationId");
    }
    this.invitationConfirmation = this.inviteeDetails.getInviteeDetails();
  }

  onSubmitButtonClicked(): void {
    if (this.requiredValuesExist()) {
      this.submitButtonClicked = true;
      this.duplicateCheckService
        .postDuplicateCheck(
          this.invitationId,
          "",
          this.invitationConfirmation.title,
          this.invitationConfirmation.firstName,
          this.invitationConfirmation.middleNames,
          this.invitationConfirmation.familyName,
          this.invitationConfirmation.dob,
          this.invitationConfirmation.niNumber,
        )
        .subscribe({
          next: (response) => {
            if (response["duplicate_check_status"] == "NoDuplicate") {
              this.routerService.redirectToStep1();
            } else {
              this.routerService.redirectToDupFailed();
            }
          },
          error: (exc) => {
            this.submitButtonClicked = false;
            if (exc.status == 400 && exc.error != undefined) {
              this.validationErrors = exc.error.errors;
              return;
            }
            this.handleErrorCodes(exc);
          },
          complete: () => {
            this.submitButtonClicked = false;
          },
        });
    }
  }

  handleErrorCodes(err: any): void {
    if (err.status == 401 || err.status == 422) {
      // The CSRF token is invalid
      this.routerService.redirectToInvalidRequest();
    } else if (err.status == 404) {
      if (err.error.detail == "AwaitingDocuments") {
        this.routerService.redirectToAlreadySubmitted();
      } else if (err.error.detail == "DuplicateInformation") {
        this.routerService.redirectToDupFailed();
      }
    } else {
      this.routerService.redirectToInternalServerError();
    }
  }

  requiredValuesExist(): boolean {
    this.firstNameValid = isNameValid(
      this.invitationConfirmation.firstName,
    ).validity;
    this.familyNameValid = isNameValid(
      this.invitationConfirmation.familyName,
    ).validity;
    this.dobValid = this.invitationConfirmation.dob !== "" ? true : false;
    this.niNumberValid = isNiNumberValid(
      this.invitationConfirmation.niNumber,
    ).validity;
    this.otpNumberValid = isOtpNumberValid(
      this.invitationConfirmation.otpNumber,
    ).validity;

    if (
      this.firstNameValid &&
      this.familyNameValid &&
      this.dobValid &&
      this.niNumberValid &&
      this.otpNumberValid
    ) {
      return true;
    } else {
      this.updateErrorSummary();
      return false;
    }
  }

  updateErrorSummary() {
    const updatedErrorSummary = Array<ErrorSummaryMessage>();
    if (!this.firstNameValid) {
      updatedErrorSummary.push({
        message: "Please go back and enter your first name.",
        id: "first-name-invalid",
      });
    }
    if (!this.familyNameValid) {
      updatedErrorSummary.push({
        message: "Please go back and enter your last name.",
        id: "last-name-invalid",
      });
    }
    if (!this.dobValid) {
      updatedErrorSummary.push({
        message: "Please go back and enter your dob.",
        id: "dob-invalid",
      });
    }
    if (!this.otpNumberValid) {
      updatedErrorSummary.push({
        message: "Please go back and enter your one time passcode.",
        id: "otp-number-invalid",
      });
    }
    if (!this.niNumberValid) {
      updatedErrorSummary.push({
        message: "Please go back and enter your national insurance number.",
        id: "ni-number-invalid",
      });
    }

    this.summaryErrorMessages = updatedErrorSummary;
  }
}
