import { Component, Input, OnInit } from "@angular/core";

import { TextInputComponent } from "./../text-input.component";

@Component({
  selector: "text-area-input",
  templateUrl: "./text-area-input.component.html",
})
export class TextAreaInputComponent
  extends TextInputComponent
  implements OnInit
{
  @Input() label: string;
  @Input() cypressValue: string;
  @Input() rows: string = "5";

  errorMessageDataCy: string;

  ngOnInit(): void {
    this.errorMessageDataCy = "error-message-" + this.cypressValue;
  }
}
