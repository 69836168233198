import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "app-invite-sent-bulk",
  templateUrl: "./invite-sent-bulk.component.html",
  styleUrls: ["./invite-sent-bulk.component.scss"],
})
export class InviteSentBulkComponent implements OnInit {
  constructor(private title: Title) {}

  ngOnInit(): void {
    this.title.setTitle("Multiple application(s) sent");
  }
}
