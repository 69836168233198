import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Title } from "@angular/platform-browser";
import { RouterService } from "src/app/services/router.service";
import { SESSION_STORAGE, StorageService } from "ngx-webstorage-service";
import { BatchUploadService } from "src/app/services/batch-upload/batch-upload.service";
import { ErrorSummaryMessage } from "../../../../models/error-summary-message";
import { CheckboxComponent } from "src/app/components/common/form-fields/checkbox/checkbox.component";
import { scrollToTop } from "src/app/utils/common/common";

@Component({
  selector: "app-upload-file",
  templateUrl: "./upload-file.component.html",
})
export class UploadFileComponent implements OnInit {
  fileUpload: any;
  fileName: string;
  personalEmailStatementErrorMessage: string;
  uploading = false;
  summaryErrorMessages = Array<ErrorSummaryMessage>();
  statementAcknowledged: boolean;
  pollAttempts = 1;
  pollInterval = 5000;
  avWaitInterval = 15000;

  @ViewChild("personalEmailStatementInput")
  personalEmailStatementInput: CheckboxComponent;

  readonly errorMessages = {
    file: {
      noFileSelected: "Select a file",
      wrongFileType: "The selected file must be in .csv",
      tooBig: "The selected file must be smaller than 5MB",
      empty: "The selected file is empty",
      uploadError: "The selected file could not be uploaded - try again",
      multiple: "You can only select one file",
    },
  };

  @ViewChild("fileInput") fileInput: ElementRef;

  constructor(
    private batchUploadService: BatchUploadService,
    @Inject(SESSION_STORAGE) private sessionStorage: StorageService,
    private routerService: RouterService,
    private title: Title,
  ) {}

  ngOnInit(): void {
    this.title.setTitle("Upload multiple applications - select file");
  }

  pushErrorMessage(message: string): void {
    this.summaryErrorMessages.push({
      message: message,
      id: "file-input",
    });
  }

  removeFile(): void {
    this.fileUpload = null;
    this.fileInput.nativeElement.value = null;
    this.summaryErrorMessages = [];
  }

  updatePersonalEmailStatementAnswer(checked: boolean): void {
    if (checked) {
      this.personalEmailStatementErrorMessage = "";
      this.statementAcknowledged = true;
    } else {
      this.personalEmailStatementErrorMessage =
        "Select if appropriate checks have been completed.";
      this.statementAcknowledged = false;
    }
  }

  submitFile(): void {
    this.sessionStorage.remove("fileErrors");
    this.personalEmailStatementErrorMessage = "";
    this.personalEmailStatementInput.setQuestionAnswer();
    this.validateFile();

    if (!this.statementAcknowledged) {
      return;
    }

    if (this.summaryErrorMessages.length == 0) {
      this.uploading = true;
      const formData: FormData = new FormData();
      formData.append(
        "file",
        this.fileUpload.target.files[0],
        this.fileUpload.target.files[0].name,
      );

      this.title.setTitle("Submitting file");

      this.batchUploadService.uploadFile(formData).subscribe({
        next: (batchId) => {
          this.avWait(batchId);
        },
        error: (err) => {
          if (err.status == 401) {
            this.routerService.redirectToLoggedOut();
          } else {
            this.uploadFailed();
          }
        },
      });
    } else {
      scrollToTop();
    }
  }

  uploadFailed(): void {
    this.routerService.redirectToInternalServerError();
  }

  avWait(batchId: string): void {
    setTimeout(() => {
      this.processBatchId(batchId);
    }, this.avWaitInterval);
  }

  processBatchId(batchId: string): void {
    this.pollAttempts++;
    this.batchUploadService.processBatch(batchId).subscribe({
      next: (response) => {
        this.handleResponse(response);
      },
      error: (err) => {
        if (err.status === 400) {
          this.sessionStorage.set("fileErrors", err.error);
          this.routerService.redirectToFileContainsErrors();
        } else if (err.status === 500) {
          this.uploadFailed();
        }
      },
    });
  }

  pauseAndRepoll(batchId: string): void {
    if (this.pollAttempts <= 10) {
      setTimeout(() => {
        this.processBatchId(batchId);
      }, this.pollInterval);
    } else {
      this.uploadFailed();
    }
  }

  handleResponse(response) {
    const status = response["status"];

    if (status == "1") {
      this.pauseAndRepoll(response["batch_id"]);
    } else if (status == "2") {
      this.routerService.redirectToInviteSentBulk();
    } else {
      this.sessionStorage.set("batchResponse", response);
      this.routerService.redirectToNotSentApplications();
    }
  }

  uploadFile(fileUpload: any) {
    this.fileUpload = fileUpload;
    this.fileName = fileUpload.target.files[0].name;
  }

  validateFile(): void {
    this.summaryErrorMessages = [];
    if (!this.fileInput.nativeElement.value) {
      this.pushErrorMessage(this.errorMessages.file.noFileSelected);
      return;
    }
    const file = this.fileUpload.target.files[0];

    this.validateMultipleFilesSelected();
    this.validateFiletype(file);
    this.validateFilesize(file);

    if (!this.fileUpload.target.validity.valid) {
      this.pushErrorMessage(this.errorMessages.file.uploadError);
    }
  }

  validateFilesize(file): void {
    if (file.size <= 110) {
      this.pushErrorMessage(this.errorMessages.file.empty);
    } else if (
      Math.round(Math.round((file.size / 1024 ** 2) * 100) / 100) > 5
    ) {
      this.pushErrorMessage(this.errorMessages.file.tooBig);
    }
  }

  validateFiletype(file): void {
    if (file.type != "text/csv") {
      this.pushErrorMessage(this.errorMessages.file.wrongFileType);
    }
  }

  validateMultipleFilesSelected(): void {
    if (this.fileUpload.target.files.length > 1) {
      this.pushErrorMessage(this.errorMessages.file.multiple);
    }
  }
}
