import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from "@angular/core";

@Component({
  selector: "text-input",
  templateUrl: "./text-input.component.html",
  styleUrls: ["./text-input.component.scss"],
})
export class TextInputComponent implements OnInit, OnChanges {
  @Input() autocomplete = "";
  @Input() capitalisation = false;
  @Input() characterCount?: number;
  @Input() disabled = false;
  @Input() errorMessage: string;
  @Input() hint: string;
  @Input() hintHtml: HTMLElement;
  @Input() id: string;
  @Input() inputMode = "text";
  @Input() inputType = "text";
  @Input() label: string;
  @Input() removeSpaces = false;
  @Input() removeDoubleSpaces = false;
  @Input() size: string;
  @Input() uppercase = false;
  @Input() value = "";
  @Input() cypressValue: string;

  @Output() questionAnswer = new EventEmitter<string>();

  answer: string;
  characterCountAbsolute: number;
  errorMessageDataCy: string;

  ngOnInit(): void {
    this.answer = this.value;
    this.errorMessageDataCy = "error-message-" + this.cypressValue;
  }

  ngOnChanges(): void {
    if (this.characterCount || this.characterCount == 0) {
      this.characterCountAbsolute = Math.abs(this.characterCount);
    }
  }

  updateValue(value: string): void {
    this.answer = value.trim();
    if (this.capitalisation) {
      this.answer = this.capitaliseInput(this.answer);
    }
    if (this.uppercase) {
      this.answer = this.answer.toUpperCase();
    }
    if (this.removeSpaces) {
      this.answer = this.answer.replace(/ /g, "");
    }
    if (this.removeDoubleSpaces) {
      this.answer = this.answer.replace(/\s+/g, " ");
    }
    this.setQuestionAnswer();
  }

  setQuestionAnswer(): void {
    this.questionAnswer.emit(this.answer);
  }

  capitaliseInput(value): string {
    return value.replace(/(?:^|\s)\S/g, (a) => a.toUpperCase());
  }
}
