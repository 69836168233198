<div class="nhsuk-grid-row">
  <div class="nhsuk-grid-column-two-thirds">
    <div class="nhsuk-form-group">
      <error-summary
        [errorSummaryMessages]="summaryErrorMessages"
      ></error-summary>
      <fieldset class="nhsuk-fieldset">
        <legend
          class="nhsuk-fieldset__legend nhsuk-fieldset__legend--xl nhsuk-u-margin-bottom-7"
          aria-describedby="passport-hint"
        >
          <h1 autofocus tabindex="-1" class="nhsuk-fieldset__heading">
            Which photo ID do you want to use?
          </h1>
        </legend>

        <p>
          You'll need to capture an image of this document when using the mobile
          app.
        </p>

        <div class="nhsuk-radios" id="passport-select">
          <div class="nhsuk-radios__item">
            <input
              #passportYes
              data-cy="passport-yes"
              class="nhsuk-radios__input"
              aria-controls="yes"
              aria-expanded="false"
              type="radio"
              (change)="updatePassportRadioValue(true)"
              id="passport-yes"
              name="passport"
            />
            <label class="nhsuk-label nhsuk-radios__label" for="passport-yes">
              Passport
            </label>
            <div class="nhsuk-hint nhsuk-radios__hint" id="passport-no-hint">
              Passports may be expired by up to 6 months
            </div>
          </div>

          <div class="nhsuk-radios__item">
            <input
              data-cy="passport-no"
              class="nhsuk-radios__input"
              aria-controls="no"
              aria-expanded="false"
              type="radio"
              (change)="updatePassportRadioValue(false)"
              id="passport-no"
              name="passport"
              value="no"
            />
            <label class="nhsuk-label nhsuk-radios__label" for="passport-no">
              Other photo ID
            </label>
          </div>
        </div>
      </fieldset>
    </div>

    <button
      (click)="onContinueButtonClicked()"
      type="submit"
      class="nhsuk-button"
      data-cy="continue-button"
    >
      Continue
    </button>
  </div>
</div>
