import { Injectable } from "@angular/core";
import { CanActivate, CanActivateChild } from "@angular/router";
import { SessionAuthenticationService } from "../services/session-authentication.service";
import { RouterService } from "src/app/services/router.service";

@Injectable()
export class AuthGuardService implements CanActivate, CanActivateChild {
  constructor(
    public sessionAuthenticationService: SessionAuthenticationService,
    private routerService: RouterService,
  ) {}

  canActivate(): boolean {
    if (this.sessionAuthenticationService.hasValidSessionToken()) {
      return true;
    } else if (
      this.sessionAuthenticationService.hasSessionToken() &&
      !this.sessionAuthenticationService.hasValidSessionToken()
    ) {
      this.routerService.redirectToLoggedOut();
      return false;
    } else {
      this.routerService.redirectToStart();
      return false;
    }
  }
  canActivateChild(): boolean {
    return this.canActivate();
  }
}
