import { Component, Inject, OnInit, OnDestroy } from "@angular/core";
import { SESSION_STORAGE, StorageService } from "ngx-webstorage-service";
import { Title } from "@angular/platform-browser";
import { FeedbackLinkComponent } from "../../common/feedback-link/feedback-link.component";
import { FeedbackService } from "src/app/services/feedback.service";

@Component({
  selector: "app-page-not-found",
  templateUrl: "./invalid-request.component.html",
})
export class InvalidRequestComponent implements OnInit, OnDestroy {
  supportEmail: string;
  invitationId = "";
  constructor(
    private title: Title,
    private feedbackService: FeedbackService,
    @Inject(SESSION_STORAGE) private sessionStorage: StorageService,
  ) {}

  ngOnInit(): void {
    this.title.setTitle("There was a problem displaying your application");
    this.supportEmail = this.sessionStorage.get("supportEmail");
    this.invitationId = this.sessionStorage.get("invitationId");
    this.feedbackService.setQueryString("SessionIssue");
  }

  ngOnDestroy(): void {
    this.feedbackService.setQueryString(undefined);
  }
}
