export enum DataValidationReasons {
  lengthTooShort = "lengthTooShort",
  lengthTooLong = "lengthTooLong",
  missingUppercaseCharacter = "missingUppercaseCharacter",
  unexpectedCharacters = "unexpectedCharacters",
  doubleSpace = "doubleSpace",
  unknown = "unknown",
  empty = "empty",
  expired = "expired",
  exceeded = "exceeded",
  invalid = "invalid",
}

export interface Validator {
  regex: RegExp;
  succeedOnMatch: boolean;
  failureReason: DataValidationReasons;
}

export interface DataValidationResult {
  validity: boolean;
  errorReason?: DataValidationReasons;
}
