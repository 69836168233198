<session-check></session-check>
<breadcrumb-link
  [breadcrumbRoute]="breadcrumbRoute"
  [breadcrumbText]="breadcrumbText"
></breadcrumb-link>
<div class="nhsuk-grid-row">
  <div class="nhsuk-grid-column-full">
    <h1 autofocus tabindex="-1">On hold applications</h1>
    <section *ngIf="invitations">
      <div class="nhsuk-grid-row">
        <div class="nhsuk-grid-column-two-thirds">&nbsp;</div>
        <div class="nhsuk-grid-column-one-third">
          <div style="float: right">
            <div class="nhsuk-form-group">
              <label class="nhsuk-label" for="select-sort-holding">
                Sort by
              </label>
              <select
                data-cy="select-sort-holding"
                class="nhsuk-select"
                id="select-sort-holding"
                name="select-sort-holding"
                (change)="sortChanged($event.target.value)"
              >
                <option value="givenName">Applicant name</option>
                <option value="createdByUserOrgCode">ODS code</option>
                <option value="idCheckedUtcNewest">Newest</option>
                <option value="idCheckedUtc" selected>Oldest</option>
                <option value="idCheckedBy">Added by</option>
                <option value="reviewBy">Assigned to</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <table
        role="table"
        class="nhsuk-table-responsive"
        aria-label="On hold applications"
      >
        <thead role="rowgroup" class="nhsuk-table__head">
          <tr role="row">
            <th role="columnheader" scope="col">Applicant name</th>
            <th role="columnheader" scope="col">Application ID</th>
            <th role="columnheader" scope="col">ODS code</th>
            <th role="columnheader" scope="col">Date added</th>
            <th role="columnheader" scope="col">Added by</th>
            <th role="columnheader" scope="col">Reasons</th>
            <th role="columnheader" scope="col">Assigned to</th>
          </tr>
        </thead>
        <tbody class="nhsuk-table__body">
          <tr
            *ngFor="let invitation of invitations | paginate: config"
            role="row"
            class="nhsuk-table__row"
          >
            <td
              role="cell"
              class="nhsuk-table__cell nhsuk-body-s nhsuk-u-margin-bottom-0"
            >
              <span class="nhsuk-table-responsive__heading"
                >Applicant name</span
              >
              <a
                attr.data-cy="open-holding-{{ invitation.invitationId }}"
                [state]="{ prevPage: '/id-checker/holding-list' }"
                [routerLink]="[
                  '/id-checker/review-id',
                  invitation.invitationId
                ]"
                >{{ invitation.givenName }} {{ invitation.familyName }}</a
              >
            </td>
            <td
              role="cell"
              class="nhsuk-table__cell nhsuk-body-s nhsuk-u-margin-bottom-0"
            >
              <span class="nhsuk-table-responsive__heading"
                >Application ID </span
              >{{ invitation.invitationId }}
            </td>
            <td
              role="cell"
              class="nhsuk-table__cell nhsuk-body-s nhsuk-u-margin-bottom-0"
            >
              <span class="nhsuk-table-responsive__heading">ODS code</span
              >{{ invitation.createdByUserOrgCode }}
            </td>
            <td
              role="cell"
              class="nhsuk-table__cell nhsuk-body-s nhsuk-u-margin-bottom-0"
            >
              <span class="nhsuk-table-responsive__heading">Added</span>
              {{ invitation.idCheckedUtc | date: "d MMMM yyyy h.mm a" }}
            </td>
            <td
              role="cell"
              class="nhsuk-table__cell nhsuk-body-s nhsuk-u-margin-bottom-0"
            >
              <span class="nhsuk-table-responsive__heading">Added by</span
              >{{ invitation.idCheckedBy }}
            </td>
            <td
              role="cell"
              class="nhsuk-table__cell nhsuk-body-s nhsuk-u-margin-bottom-0"
            >
              <span class="nhsuk-table-responsive__heading">Reason</span>
              <span
                *ngFor="let failure of getRejectReasonsPerDocument(invitation)"
              >
                {{ failure }}
                <br />
              </span>
              <span *ngFor="let holdReason of invitation.holdReason">
                {{ holdReason | idCheckHeldReasonPipe }}.
              </span>
            </td>
            <td
              role="cell"
              class="nhsuk-u-margin-bottom-0 nhsuk-body-s nhsuk-table__cell"
            >
              <span class="nhsuk-table-responsive__heading">Assigned to</span>
              <span
                title="User: {{ invitation.reviewBy }},  Expiry: {{
                  invitation.reviewExpiryUtc | date: 'dd/MM/yyyy HH:mm:ss'
                }}"
                *ngIf="invitation.reviewBy != ''"
              >
                {{ invitation.reviewBy }}</span
              >
              <span
                title="Unassigned"
                *ngIf="invitation.reviewByDisplay == 'ZZ'"
                >Unassigned</span
              >
            </td>
          </tr>
        </tbody>
      </table>

      <pagination-template
        #p="paginationApi"
        [id]="config.id"
        (pageChange)="config.currentPage = $event"
      >
        <nav class="nhsuk-pagination" role="navigation" aria-label="Pagination">
          <ul class="nhsuk-list nhsuk-pagination__list">
            <li
              [class.disabled]="p.isFirstPage()"
              class="nhsuk-pagination-item--previous"
            >
              <a
                tabindex="0"
                *ngIf="!p.isFirstPage()"
                (click)="p.previous()"
                class="nhsuk-pagination__link nhsuk-pagination__link--prev"
                (keydown.enter)="p.previous()"
              >
                <span class="nhsuk-pagination__title">Previous</span>
                <span class="nhsuk-u-visually-hidden">:</span>
                <span class="nhsuk-pagination__page"
                  >{{ config.currentPage - 1 }} of {{ p.getLastPage() }}</span
                >
                <svg
                  class="nhsuk-icon nhsuk-icon__arrow-left"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                  width="34"
                  height="34"
                >
                  <path
                    d="M4.1 12.3l2.7 3c.2.2.5.2.7 0 .1-.1.1-.2.1-.3v-2h11c.6 0 1-.4 1-1s-.4-1-1-1h-11V9c0-.2-.1-.4-.3-.5h-.2c-.1 0-.3.1-.4.2l-2.7 3c0 .2 0 .4.1.6z"
                  ></path>
                </svg>
              </a>
            </li>
            <li
              [class.disabled]="p.isLastPage()"
              class="nhsuk-pagination-item--next"
            >
              <a
                tabindex="0"
                class="nhsuk-pagination__link nhsuk-pagination__link--next"
                *ngIf="!p.isLastPage()"
                (click)="p.next()"
                (keydown.enter)="p.next()"
              >
                <span class="nhsuk-pagination__title">Next</span>
                <span class="nhsuk-u-visually-hidden">:</span>
                <span class="nhsuk-pagination__page"
                  >{{ config.currentPage + 1 }} of {{ p.getLastPage() }}</span
                >
                <svg
                  class="nhsuk-icon nhsuk-icon__arrow-right"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                  width="34"
                  height="34"
                >
                  <path
                    d="M19.6 11.66l-2.73-3A.51.51 0 0 0 16 9v2H5a1 1 0 0 0 0 2h11v2a.5.5 0 0 0 .32.46.39.39 0 0 0 .18 0 .52.52 0 0 0 .37-.16l2.73-3a.5.5 0 0 0 0-.64z"
                  ></path>
                </svg>
              </a>
            </li>
          </ul>
        </nav>
      </pagination-template>
    </section>
    <section *ngIf="!invitations">
      <p>
        <progress-indicator
          spinnerText="Loading holding list"
        ></progress-indicator>
      </p>
    </section>
  </div>
</div>
