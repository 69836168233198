import { Component, Inject, OnInit } from "@angular/core";
import { RouterService } from "src/app/services/router.service";
import { InvitationConfirmation } from "../../../../store/states/invitee-spa.state";
import { Subscription } from "rxjs";
import { Title } from "@angular/platform-browser";
import { PoaValidateService } from "src/app/services/poa-validate.service";
import { SESSION_STORAGE, StorageService } from "ngx-webstorage-service";
import { InviteeDetails } from "src/app/utils/invitee-details";

@Component({
  selector: "app-check-your-address",
  templateUrl: "./check-your-address.component.html",
})
export class CheckYourAddressComponent implements OnInit {
  submitButtonClicked = false;
  errorMessage: string;
  invitationId: string;
  invitationConfirmationSubscription: Subscription;
  invitationConfirmation: InvitationConfirmation;
  breadcrumbRoute = "/invitee/confirm-postcode/tu";
  breadcrumbText = "Back";

  constructor(
    @Inject(SESSION_STORAGE) private sessionStorage: StorageService,
    private routerService: RouterService,
    private title: Title,
    private poaValidateService: PoaValidateService,
    private inviteeDetails: InviteeDetails,
  ) {}

  async ngOnInit(): Promise<void> {
    this.title.setTitle("Check your address");
    this.invitationId = this.sessionStorage.get("invitationId");
    if (this.invitationId == undefined || this.invitationId == "") {
      this.routerService.redirectToInvalidRequest();
    }

    this.invitationConfirmation = this.inviteeDetails.getInviteeDetails();
  }

  onSubmitButtonClicked(): void {
    if (!this.areFieldsValid()) {
      this.errorMessage =
        "Address information missing. Click 'Change' to input your address.";
      return;
    }

    this.submitButtonClicked = true;

    this.poaValidateService
      .postTuAddressCheck(
        this.invitationId,
        this.invitationConfirmation.tuBuildingName,
        this.invitationConfirmation.tuAddressLineOne,
        this.invitationConfirmation.tuTownCity,
        this.invitationConfirmation.tuPostcode,
        this.invitationConfirmation.tuAddress,
      )
      .subscribe({
        next: (response) => {
          if (response.body.toString().toLowerCase() === "true") {
            this.routerService.redirectToPassport();
          } else {
            this.routerService.redirectToPoa();
          }
        },
        error: (err) => {
          if (err.status === 400) {
            this.routerService.redirectToAlreadySubmitted();
          } else if (err.status === 422 || err.status === 404) {
            this.routerService.redirectToInvalidRequest();
          } else if (err.status === 500) {
            this.routerService.redirectToInternalServerError();
          }
          this.submitButtonClicked = false;
        },
        complete: () => {
          this.submitButtonClicked = false;
        },
      });
  }

  areFieldsValid(): boolean {
    if (
      this.invitationId != "" &&
      (this.invitationConfirmation.tuBuildingName != "" ||
        this.invitationConfirmation.tuAddressLineOne != "") &&
      this.invitationConfirmation.tuTownCity != "" &&
      this.invitationConfirmation.tuPostcode != "" &&
      this.invitationConfirmation.tuAddress != ""
    )
      return true;
    else {
      return false;
    }
  }
}
