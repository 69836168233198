import { Component, OnInit } from "@angular/core";
import { StorageMap } from "@ngx-pwa/local-storage";
import { ActivatedRoute } from "@angular/router";
import { RouterService } from "src/app/services/router.service";
import { CancelInvitationService } from "src/app/services/cancel-invitation.service";
import { Invitation } from "src/store/states/spa.state";
import { Title } from "@angular/platform-browser";
import { cancelReasonList } from "src/app/utils/common/common";
import { ErrorSummaryMessage } from "src/app/models/error-summary-message";

@Component({
  selector: "app-cancel-invite",
  templateUrl: "./cancel-invite.component.html",
})
export class CancelInviteComponent implements OnInit {
  invitation: Invitation;
  invitationId: string;
  forwardRoute = "/invitation/invite-cancelled";
  selectedReasonId: number;
  submitButtonClicked = false;
  readonly reasonList = cancelReasonList;
  otherReason: string;
  summaryErrorMessages = Array<ErrorSummaryMessage>();
  dropdownErrorMessage: string;
  textErrorMessage: string;
  errorMessage: string;

  constructor(
    private storage: StorageMap,
    private route: ActivatedRoute,
    private routerService: RouterService,
    private cancelInvitationService: CancelInvitationService,
    private title: Title,
  ) {}

  ngOnInit(): void {
    this.invitationId = this.route.snapshot.paramMap.get("invitationId");
    this.getInvitationState();
    this.title.setTitle("Select cancel application reason");
  }

  getInvitationState(): void {
    this.storage.has("invitation").subscribe((invitationExists) => {
      if (invitationExists) {
        this.storage.get("invitation").subscribe((invitation) => {
          this.invitation = invitation as Invitation;
        });
      }
    });
  }

  updateReasonAnswer(reasonNumber: number): void {
    this.dropdownErrorMessage = "";
    this.textErrorMessage = "";
    this.selectedReasonId = reasonNumber;
  }

  async onCancelInvitationButtonClicked(): Promise<void> {
    this.dropdownErrorMessage = "";
    this.textErrorMessage = "";
    this.errorMessage = "";
    this.summaryErrorMessages = [];

    if (this.selectedReasonId == undefined) {
      this.summaryErrorMessages.push({
        message: "Select a reason from the options below",
        id: "reason-select-error-description",
      });
      this.dropdownErrorMessage = "Select a reason from the options below";
      return;
    }

    if (this.selectedReasonId == 3 && !this.otherReason) {
      this.summaryErrorMessages.push({
        message: "Please specify a reason for cancelling the application",
        id: "other-reason-notes-error-description",
      });
      this.textErrorMessage =
        "Please specify a reason for cancelling the application";
      return;
    }

    try {
      this.submitButtonClicked = true;
      await this.cancelInvitationService.cancelInvitation(
        this.invitationId,
        this.selectedReasonId,
        this.otherReason,
      );
      this.routerService.redirectToInviteCancelled(this.invitationId);
    } catch (err) {
      this.routerService.handleErrorAuthRoutes(err.status);
      this.summaryErrorMessages.push({
        message: "An error was encountered please retry",
        id: "cancel-button",
      });
      this.errorMessage = "An error was encountered please retry";
      this.submitButtonClicked = false;
    }
  }
}
