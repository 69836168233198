<div class="nhsuk-grid-row">
  <div class="nhsuk-grid-column-two-thirds">
    <breadcrumb-link
      [breadcrumbRoute]="breadcrumbRoute"
      [breadcrumbText]="breadcrumbText"
    ></breadcrumb-link>
    <h1 autofocus class="nhsuk-heading-xl" tabindex="-1">
      Send a security code to your phone
    </h1>
    <p>
      To continue with the application you will need to enter a 6 digit security
      code.
    </p>
    <p>
      Send the security code to your mobile phone number ending ******<strong
        *ngIf="mobile"
        >{{ mobile }}.</strong
      >
    </p>

    <button
      (click)="generateOtp()"
      id="generate-otp"
      data-cy="send-code-button"
      [ngClass]="{ 'spinner-button': buttonClicked }"
      [disabled]="buttonClicked"
      class="nhsuk-button"
    >
      Send code
    </button>

    <div class="nhsuk-error-message" *ngIf="requestError">
      <p>Invalid request</p>
      <p>
        Please reuse the link provided in the email invitation to restart the
        journey. If this problem persists you may need to request a new invite
        your employer.
      </p>
    </div>

    <details class="nhsuk-details">
      <summary class="nhsuk-details__summary">
        <span class="nhsuk-details__summary-text">
          Mobile number is incorrect
        </span>
      </summary>
      <div class="nhsuk-details__text">
        <p>
          Contact <a href="mailto:{{ supportEmail }}">{{ supportEmail }}</a> to
          get your mobile number updated. Include your application ID in all
          emails.
        </p>
      </div>
    </details>
  </div>
</div>
