import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "radio-button",
  templateUrl: "./radio-button.component.html",
})
export class RadioButtonComponent implements OnInit {
  @Input() requiredAnswer: boolean;
  @Input() question: string;
  @Input() id: string;
  @Input() answerInput: boolean;
  @Input() invalidErrorMessage: string;

  @Output() questionAnswer = new EventEmitter<boolean>();
  @Output() isQuestionAnswerValid = new EventEmitter<boolean>();

  answer: boolean;
  isAnswerValid = false;
  focusOnYes: boolean;
  focusOnNo: boolean;
  errorMessage: string;

  ngOnInit(): void {
    this.answer = this.answerInput;
  }

  updateValue(value: boolean): void {
    this.answer = value;
    this.setAnswers();
  }

  setAnswers(): void {
    this.isValid();
    this.setQuestionAnswer();
    this.setIsQuestionAnswerValid();
  }

  setQuestionAnswer(): void {
    this.questionAnswer.emit(this.answer);
  }

  setIsQuestionAnswerValid(): void {
    this.isQuestionAnswerValid.emit(this.isAnswerValid);
  }
  setFocusOnYes(value: boolean): void {
    this.focusOnYes = value;
  }
  setFocusOnNo(value: boolean): void {
    this.focusOnNo = value;
  }

  onBlur(): void {
    this.setFocusOnNo(false);
    this.setFocusOnYes(false);
  }

  isValid(): boolean {
    if (
      this.requiredAnswer === undefined ||
      this.requiredAnswer === this.answer
    ) {
      this.errorMessage = "";
      this.isAnswerValid = true;
      return true;
    } else if (this.requiredAnswer && this.answer === undefined) {
      this.errorMessage = "Please choose an option";
      this.isAnswerValid = false;
      return false;
    } else {
      this.errorMessage = this.invalidErrorMessage;
      this.isAnswerValid = false;
      return false;
    }
  }
}
