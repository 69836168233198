<session-check></session-check>
<div class="nhsuk-grid-row">
  <div class="nhsuk-grid-column-two-thirds">
    <div *ngIf="!uploading" class="nhsuk-back-link nhsuk-u-margin-top-4">
      <a class="nhsuk-back-link__link" [routerLink]="['/invitation/dashboard']">
        <svg
          class="nhsuk-icon nhsuk-icon__chevron-left"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          aria-hidden="true"
          height="24"
          width="24"
        >
          <path
            d="M8.5 12c0-.3.1-.5.3-.7l5-5c.4-.4 1-.4 1.4 0s.4 1 0 1.4L10.9 12l4.3 4.3c.4.4.4 1 0 1.4s-1 .4-1.4 0l-5-5c-.2-.2-.3-.4-.3-.7z"
          ></path>
        </svg>
        Back</a
      >
    </div>

    <h1 autofocus tabindex="-1">Upload multiple applications</h1>

    <div id="top"></div>

    <main
      class="nhsuk-main-wrapper nhsuk-main-wrapper--s"
      id="maincontent"
      role="main"
    >
      <div *ngIf="uploading; else mainContent" class="nhsuk-grid-column-full">
        <progress-indicator
          spinnerText="Uploading and processing file. This may take a few moments."
        ></progress-indicator>
      </div>

      <ng-template #mainContent>
        <div
          *ngIf="summaryErrorMessages.length > 0"
          class="nhsuk-error-summary"
          aria-labelledby="error-summary-title"
          role="alert"
          tabindex="-1"
        >
          <h2 class="nhsuk-error-summary__title" id="error-summary-title">
            There is a problem
          </h2>
          <div class="nhsuk-error-summary__body">
            <ul class="nhsuk-list nhsuk-error-summary__list">
              <li *ngFor="let error of summaryErrorMessages">
                <a
                  href="{{
                    this.routerService.getCurrentUrl().split('#')[0]
                  }}#fileInput"
                  >{{ error.message }}</a
                >
              </li>
            </ul>
          </div>
        </div>

        <div class="nhsuk-warning-callout">
          <h2 class="nhsuk-warning-callout__label">
            <span class="nhsuk-u-visually-hidden">Important: </span>
            Important
          </h2>
          <p>
            The template was updated on 26 September 2023. Make sure you use the
            latest version.
          </p>
        </div>
        <p>
          Download the template and add applicants' details to create multiple
          applications.
        </p>
        <p class="nhsuk-u-margin-bottom-1">
          <a
            href="../../../../assets/files/bulk-upload-file-template.csv"
            download="file-template.csv"
            >Template for uploading multiple applications (csv)</a
          >
        </p>
        <p class="grey-text">Last updated: 26 September 2023</p>

        <p>The template includes an example of how to format your file.</p>

        <p>You must include applicants':</p>

        <ul>
          <li>given and family names</li>
          <li>email address</li>
          <li>
            mobile number. For international numbers include the country code at
            the start of the number. For example, +33123456789
          </li>
        </ul>

        <div
          class="nhsuk-form-group nhsuk-u-margin-bottom-6"
          [ngClass]="{
            'nhsuk-form-group--error': summaryErrorMessages.length > 0
          }"
        >
          <label class="nhsuk-label nhsuk-label--m" for="fileInput">
            Upload a file
          </label>
          <div class="nhsuk-hint" id="bulk-upload-hint">
            File must be .csv format and smaller than 5MB
          </div>
          <span
            *ngFor="let error of summaryErrorMessages"
            class="nhsuk-error-message"
            id="bulk-error"
          >
            <span class="nhsuk-u-visually-hidden">Error:</span>
            {{ error.message }}
          </span>
          <input
            #fileInput
            type="file"
            id="fileInput"
            name="fileInput"
            aria-describedby="bulk-upload-hint"
            accept=".csv"
            (change)="uploadFile($event)"
            [disabled]="fileUpload != null"
            class="batch-upload"
          />
        </div>

        <dl *ngIf="fileUpload" class="nhsuk-summary-list">
          <div class="nhsuk-summary-list__row">
            <dt class="nhsuk-summary-list__key">File</dt>
            <dd class="nhsuk-summary-list__value">{{ fileName }}</dd>
            <dd class="nhsuk-summary-list__actions custom-link">
              <a (click)="removeFile()">
                Remove<span class="nhsuk-u-visually-hidden"> file</span>
              </a>
            </dd>
          </div>
        </dl>

        <h2 class="nhsuk-heading-m">Use of personal emails</h2>

        <p>
          Emails not on the CIS allowlist will be treated as personal emails.
        </p>

        <div class="nhsuk-form-group nhsuk-u-margin-top-5">
          <checkbox-input
            #personalEmailStatementInput
            id="personal-email-statement"
            [requiredAnswer]="true"
            checkboxText="I can confirm that applicants have been through the necessary onboarding and HR processes for their trusted organisation if they do not have an allow-listed email address"
            [errorMessage]="personalEmailStatementErrorMessage"
            (questionAnswer)="updatePersonalEmailStatementAnswer($event)"
            [cypressValue]="'personal-email-statement'"
          >
          </checkbox-input>
        </div>

        <div *ngIf="uploadError"></div>

        <button class="nhsuk-button" type="submit" (click)="submitFile()">
          Submit file
        </button>
      </ng-template>
    </main>
  </div>
</div>
