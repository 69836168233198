import { IdCheckFailure } from "./idCheckFailure";
import { RadioButtonAnswer } from "./radioButtonAnswer";

export interface Selections {
  invitation_id: string;
  duplicateProfileValue?: RadioButtonAnswer;
  photo1value?: RadioButtonAnswer;
  photo2value?: RadioButtonAnswer;
  poaValue?: RadioButtonAnswer;
  nameDobValue?: RadioButtonAnswer;
  faceValue?: RadioButtonAnswer;
  smartcardValue?: RadioButtonAnswer;

  idCheckFailureValue?: IdCheckFailure[];
}

export class IdcheckerSelections {
  invitationId: string;
  duplicateProfileValue?: RadioButtonAnswer;
  photo1value?: RadioButtonAnswer;
  photo2value?: RadioButtonAnswer;
  poaValue?: RadioButtonAnswer;
  nameDobValue?: RadioButtonAnswer;
  faceValue?: RadioButtonAnswer;
  smartcardValue?: RadioButtonAnswer;

  idCheckFailureValue?: IdCheckFailure[];

  constructor(selections: Selections) {
    this.invitationId = selections.invitation_id;
    this.duplicateProfileValue = selections.duplicateProfileValue;
    this.photo1value = selections.photo1value;
    this.photo2value = selections.photo2value;
    this.poaValue = selections.poaValue;
    this.nameDobValue = selections.nameDobValue;
    this.faceValue = selections.faceValue;
    this.smartcardValue = selections.smartcardValue;

    this.idCheckFailureValue = selections.idCheckFailureValue;
  }
}
