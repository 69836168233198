import { Component, OnInit } from "@angular/core";
import {
  InvitationSearchDetails,
  InvitationStatus,
} from "src/app/models/invitationDetails";
import { GetInvitationService } from "../../../services/get-invitation.service";
import { RouterService } from "src/app/services/router.service";
import { SessionAuthenticationService } from "../../../services/session-authentication.service";
import { PaginationInstance } from "ngx-pagination/lib/pagination-instance";
import { InvitationSearch } from "src/store/states/spa.state";
import { Title } from "@angular/platform-browser";
import { Location } from "@angular/common";
import { DashboardCheckbox } from "src/app/models/dashboardCheckbox";
import { removeSpecialChars } from "../../../utils/common/common";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"],
})
export class DashboardComponent implements OnInit {
  allInvitations: InvitationSearch[];
  collectionSize: number;
  currentUser: string;
  filteredInvitations: InvitationSearch[];
  tableCaption = "All Applications";
  searchTerm = "";
  odsSearchTerm = "";
  senderOnly: boolean;
  showingResults = false;
  sortAsc: boolean = true;
  loading: boolean = true;

  fullNameColSortActive: boolean = false;
  createdByUserOrgCodeActive: boolean = false;
  createdByUserNameColActive: boolean = false;
  lastUpdatedDateUtcColActive: boolean = false;
  invitationIdColActive: boolean = false;
  statusColActive: boolean = false;
  emailColActive: boolean = false;

  filterActive: boolean = false;
  anyStatusesChecked: boolean = false;

  statuses: DashboardCheckbox[] = [
    {
      dashboardStatus: "In progress",
      statuses: [
        InvitationStatus["Awaiting documents"],
        InvitationStatus["Duplicate check in progress"],
        InvitationStatus.Resent,
        InvitationStatus.Sent,
        InvitationStatus["ID review"],
        InvitationStatus["On hold"],
        InvitationStatus.OtpExpired,
      ],
      isSelected: false,
    },
    {
      dashboardStatus: "Resend Required",
      statuses: [
        InvitationStatus["Invite email not sent"],
        InvitationStatus["Invite resend required"],
        InvitationStatus.Locked,
      ],
      isSelected: false,
    },
    {
      dashboardStatus: "Approved",
      statuses: [InvitationStatus.Approved],
      isSelected: false,
    },
    {
      dashboardStatus: "Duplicate",
      statuses: [InvitationStatus["Duplicate information"]],
      isSelected: false,
    },
    {
      dashboardStatus: "Rejected",
      statuses: [
        InvitationStatus.Rejected,
        InvitationStatus["Application expired"],
      ],
      isSelected: false,
    },
    {
      dashboardStatus: "Cancelled",
      statuses: [InvitationStatus["Application cancelled"]],
      isSelected: false,
    },
  ];

  public config: PaginationInstance = {
    id: "custom",
    itemsPerPage: 10,
    currentPage: 1,
  };

  constructor(
    private routerService: RouterService,
    private getInvitationService: GetInvitationService,
    private sessionAuthenticationService: SessionAuthenticationService,
    private title: Title,
    private location: Location,
  ) {}

  async ngOnInit(): Promise<void> {
    this.title.setTitle("All applications");
    if (
      this.location.getState()["filteredInvitations"] !== null &&
      this.location.getState()["filteredInvitations"] !== undefined
    ) {
      this.filteredInvitations =
        this.location.getState()["filteredInvitations"];
      this.allInvitations = this.location.getState()["allInvitations"];
      this.searchTerm = this.location.getState()["searchTerm"];
      this.odsSearchTerm = this.location.getState()["odsSearchTerm"];
      this.senderOnly = this.location.getState()["senderOnly"];
      this.statuses = this.location.getState()["statuses"];
      this.filterActive = true;
      this.collectionSize =
        this.location.getState()["filteredInvitations"].length;
      this.updateTableCaption();
      this.checkIfAnyStatusChecked();
      this.loading = false;
    } else {
      await this.showAllInvitations();
    }
    this.currentUser =
      this.sessionAuthenticationService.currentSessionToken().user_name;
    if (
      this.location.getState()["currentPage"] !== null &&
      this.location.getState()["currentPage"] !== undefined
    ) {
      this.config.currentPage = this.location.getState()["currentPage"];
    }
  }

  mapInvites(invites: InvitationSearchDetails[]): InvitationSearch[] {
    return invites.map((invite) => new InvitationSearch(invite));
  }

  async showAllInvitations() {
    this.getInvitationService.getInvitations().subscribe({
      next: (response) => {
        this.filteredInvitations = this.mapInvites(response["body"]).sort(
          (a, b) => (a.date < b.date ? 1 : -1),
        );
        this.allInvitations = this.mapInvites(response["body"]).sort((a, b) =>
          a.date < b.date ? 1 : -1,
        );
        this.collectionSize = this.filteredInvitations.length;
        this.loading = false;
      },
      error: (error) => {
        this.routerService.handleErrorAuthRoutes(error.status);
      },
    });
  }

  senderOnlyInvitationsCheckbox(e: Event) {
    const target = e.target as HTMLInputElement;
    if (target.checked) {
      this.senderOnly = true;
    } else {
      this.senderOnly = false;
    }
  }

  onNewInvitationButtonClicked() {
    this.routerService.redirectToBasicInfo();
  }

  onSearchClicked(): void {
    this.filterActive = true;
    let checkedStatuses = this.filterByStatus();
    this.filteredInvitations = this.allInvitations;
    let applicationsToFilter = this.allInvitations;

    if (this.searchTerm.length !== 0) {
      applicationsToFilter = applicationsToFilter.filter(
        (val) =>
          val.email.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
          val.name.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
          val.id.toLowerCase().includes(this.searchTerm.toLowerCase()),
      );
    }

    if (this.odsSearchTerm.length !== 0) {
      const odsList: string[][] = [];
      const codesEntered = this.odsSearchTerm.toLowerCase().split(" ");
      odsList.push(codesEntered);

      applicationsToFilter = applicationsToFilter.filter((val) =>
        odsList.flat().includes(val.org.toLowerCase()),
      );
    }

    if (checkedStatuses.length !== 0) {
      applicationsToFilter = applicationsToFilter.filter((val) =>
        checkedStatuses.flat().includes(val.status),
      );
    }

    if (this.senderOnly) {
      applicationsToFilter = applicationsToFilter.filter(
        (val) => val.user.toLowerCase() === this.currentUser.toLowerCase(),
      );
    }

    this.filteredInvitations = applicationsToFilter;
    this.collectionSize = this.filteredInvitations.length;

    this.updateTableCaption();
    this.config.currentPage = 1;
    document.querySelector("#results").scrollIntoView({ behavior: "smooth" });
  }

  filterByStatus(): InvitationStatus[][] {
    return this.statuses
      .filter((val) => val.isSelected)
      .map((val) => {
        return val.statuses;
      });
  }

  updateTableCaption(): void {
    if (this.searchTerm.length === 0 && this.odsSearchTerm.length === 0) {
      this.tableCaption = "All Applications";
    } else if (this.searchTerm.length > 0 && this.odsSearchTerm.length > 0) {
      this.tableCaption = `Showing ${this.collectionSize} results for '${this.searchTerm}' with ODS code(s) '${this.odsSearchTerm}'`;
    } else if (this.odsSearchTerm.length > 0) {
      this.tableCaption = `Showing ${this.collectionSize} results for ODS code(s) '${this.odsSearchTerm}'`;
    } else {
      this.tableCaption = `Showing ${this.collectionSize} results for '${this.searchTerm}'`;
    }
    if (this.tableCaption.length > 1000) {
      this.tableCaption = this.tableCaption.substring(0, 1000) + "...'";
    }
  }

  checkIfAnyStatusChecked(): void {
    this.anyStatusesChecked =
      this.statuses.find((val) => val.isSelected) !== undefined;
  }

  sortChanged(columnName: string): void {
    switch (columnName) {
      case "givenName":
        this.filteredInvitations.sort((a, b) => (a.name > b.name ? 1 : -1));
        break;
      case "email":
        this.filteredInvitations.sort((a, b) => (a.email > b.email ? 1 : -1));
        break;
      case "statusLastUpdatedDateUtc":
        this.filteredInvitations.sort((a, b) => (a.date < b.date ? 1 : -1));
        break;
      case "statusLastUpdatedDateUtcOldest":
        this.filteredInvitations.sort((a, b) => (a.date > b.date ? 1 : -1));
        break;
      case "createdByUserName":
        this.filteredInvitations.sort((a, b) => (a.user > b.user ? 1 : -1));
        break;
      case "invitationId":
        this.filteredInvitations.sort((a, b) => (a.id > b.id ? 1 : -1));
        break;
      case "createdByUserOrgCode":
        this.filteredInvitations.sort((a, b) => (a.org < b.org ? 1 : -1));
        break;
      case "status":
        this.filteredInvitations.sort((a, b) => (a.status > b.status ? 1 : -1));
        break;
    }
  }

  clearFilters(): void {
    this.filterActive = false;
    this.filteredInvitations = this.allInvitations;
    this.searchTerm = "";
    this.tableCaption = "All Applications";
    this.odsSearchTerm = "";
    this.anyStatusesChecked = false;
    this.senderOnly = false;
    this.statuses.forEach((val) => (val.isSelected = false));
    this.config.currentPage = 1;
  }

  omitSpecialChar(e): boolean {
    const res = removeSpecialChars(e);
    return res;
  }
}
