import { Directive, HostListener } from "@angular/core";

@Directive({
  selector: "input[numbersOnly]",
})
export class NumbersOnlyDirective {
  @HostListener("keydown", ["$event"])
  onInput(event): void {
    const e = event as KeyboardEvent;
    const regex = new RegExp(/^\D+$/);

    const navigationKeys = [
      "Backspace",
      "Delete",
      "Tab",
      "Escape",
      "Enter",
      "Home",
      "End",
      "ArrowLeft",
      "ArrowRight",
      "Clear",
      "Copy",
    ];
    if (
      navigationKeys.indexOf(e.key) > -1 ||
      (e.key === "a" && e.ctrlKey === true) ||
      (e.key === "c" && e.ctrlKey === true) ||
      (e.key === "x" && e.ctrlKey === true) ||
      (e.key === "a" && e.metaKey === true) ||
      (e.key === "c" && e.metaKey === true) ||
      (e.key === "x" && e.metaKey === true)
    ) {
      return;
    }
    if (regex.test(e.key)) {
      e.preventDefault();
    }
  }
}
