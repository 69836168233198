<session-check></session-check>
<div *ngIf="loading">
  <progress-indicator spinnerText="Loading applications"></progress-indicator>
</div>
<div class="nhsuk-grid-row">
  <div class="nhsuk-grid-column-full" *ngIf="!loading">
    <h1 autofocus tabindex="-1">Search ID Checker</h1>

    <div class="nhsuk-form-group nhsuk-u-margin-top-3">
      <p *ngIf="filterActive">
        <a class="clear-link" (click)="clearFilters()">Clear all filters</a>
      </p>
      <label
        class="nhsuk-label nhsuk-label--s nhsuk-u-margin-bottom-1"
        for="searchTerm"
      >
        Name, email address, application ID or UUID
      </label>
      <input
        class="nhsuk-input nhsuk-input--width-20"
        type="text"
        name="searchTerm"
        id="searchTerm"
        aria-label="search box"
        (keypress)="omitSpecialChar($event)"
        [(ngModel)]="searchTerm"
        (keyup.enter)="onSearchClicked()"
      />
    </div>

    <div class="nhsuk-form-group nhsuk-u-margin-top-3">
      <label
        class="nhsuk-label nhsuk-label--s nhsuk-u-margin-bottom-1"
        for="reviewedBySearchTerm"
      >
        Reviewed by
      </label>
      <input
        class="nhsuk-input nhsuk-input--width-20"
        type="text"
        name="reviewedBySearchTerm"
        id="reviewedBySearchTerm"
        aria-label="search box"
        [(ngModel)]="reviewedBySearchTerm"
        (keyup.enter)="onSearchClicked()"
      />
    </div>

    <div class="nhsuk-form-group nhsuk-u-margin-top-3">
      <label
        class="nhsuk-label nhsuk-label--s nhsuk-u-margin-bottom-1"
        for="odsSearchTerm"
      >
        Org code(s)
      </label>
      <div class="nhsuk-hint nhsuk-u-reading-width" id="ods-search-hint">
        For multiple ODS codes include a space between each one. For example,
        Y54 A3A8R RW4
      </div>
      <input
        class="nhsuk-input nhsuk-u-width-one-half"
        type="text"
        id="odsSearchTerm"
        name="odsSearchTerm"
        aria-label="ods search box"
        aria-describedby="ods-search-hint"
        aria-label="org search box"
        [(ngModel)]="odsSearchTerm"
        (keyup.enter)="onSearchClicked()"
      />
    </div>

    <div class="nhsuk-form-group nhsuk-u-margin-top-3">
      <fieldset class="nhsuk-fieldset">
        <legend class="nhsuk-fieldset__legend nhsuk-fieldset__legend--s">
          <h2 class="nhsuk-fieldset__heading">Status</h2>
        </legend>
        <div class="nhsuk-checkboxes nhsuk-checkboxes--small">
          <div
            *ngFor="let i of statuses; let x = index"
            class="nhsuk-checkboxes__item"
          >
            <input
              class="nhsuk-checkboxes__input"
              type="checkbox"
              name="{{ i.dashboardStatus }}"
              id="{{ i.dashboardStatus }}"
              [(ngModel)]="i.isSelected"
              [value]="i.dashboardStatus"
              (change)="checkIfAnyStatusChecked()"
              [(checked)]="i.isSelected"
              aria-label="{{ i.dashboardStatus }}"
              title="{{ i.dashboardStatus }}"
            />
            <label
              class="nhsuk-label nhsuk-checkboxes__label"
              [for]="i.dashboardStatus"
            >
              {{ i.dashboardStatus }}
            </label>
          </div>
        </div>
      </fieldset>
    </div>

    <button
      class="nhsuk-button nhsuk-button nhsuk-u-margin-bottom-8"
      (click)="onSearchClicked()"
      id="search"
      type="submit"
      title="search box"
    >
      Search
    </button>

    <h3 class="nhsuk-heading-m nhsuk-u-margin-top-5" id="results">
      {{ tableCaption }}
    </h3>

    <ul class="nhsuk-grid-row nhsuk-card-group" id="results">
      <li
        class="nhsuk-grid-column-full nhsuk-card-group__item"
        *ngFor="let invitation of filteredInvitations | paginate: config"
      >
        <div class="nhsuk-card nhsuk-card--clickable">
          <div class="nhsuk-card__content">
            <h2 class="nhsuk-card__heading nhsuk-heading-m">
              <a
                class="nhsuk-card__link"
                attr.data-cy="link-{{ invitation.invitationId }}"
                [state]="{
                  invitation: invitation,
                  currentPage: config.currentPage,
                  filteredInvitations: filteredInvitations,
                  allInvitations: allInvitations,
                  statuses: statuses,
                  searchTerm: searchTerm,
                  reviewedBySearchTerm: reviewedBySearchTerm,
                  odsSearchTerm: odsSearchTerm,
                  prevPage: '/id-checker/search'
                }"
                [routerLink]="[
                  '/id-checker/review-id',
                  invitation.invitationId
                ]"
              >
                {{ invitation.givenName }} {{ invitation.familyName }}
              </a>
            </h2>
            <dl class="nhsuk-summary-list nhsuk-summary-list--no-border">
              <div class="nhsuk-summary-list__row">
                <dt class="nhsuk-summary-list__key">Status</dt>
                <dd class="nhsuk-summary-list__value">
                  <span
                    [attr.data-cy]="invitation.status"
                    [ngClass]="invitation.status | invitationStatusRawClassPipe"
                    class="nhsuk-tag"
                  >
                    {{ invitation.status | invitationStatusRawPipe }}</span
                  >
                </dd>
              </div>
              <div class="nhsuk-summary-list__row">
                <dt class="nhsuk-summary-list__key">Application ID</dt>
                <dd class="nhsuk-summary-list__value">
                  {{ invitation.invitationId }}
                </dd>
              </div>
              <div
                class="nhsuk-summary-list__row"
                *ngIf="invitation.status == 50"
              >
                <dt class="nhsuk-summary-list__key">Date submitted</dt>
                <dd class="nhsuk-summary-list__value">
                  {{ invitation.statusLastUpdatedDateUtc | date: "d MMM yyyy" }}
                </dd>
              </div>
              <div
                class="nhsuk-summary-list__row"
                *ngIf="invitation.status == 50 && invitation.reviewBy != null"
              >
                <dt class="nhsuk-summary-list__key">Assigned to</dt>
                <dd class="nhsuk-summary-list__value">
                  {{ invitation.reviewBy }}
                </dd>
              </div>
              <div
                class="nhsuk-summary-list__row"
                *ngIf="invitation.status == 51"
              >
                <dt class="nhsuk-summary-list__key">Date added</dt>
                <dd class="nhsuk-summary-list__value">
                  {{ invitation.idCheckedUtc | date: "d MMM yyyy" }}
                </dd>
              </div>
              <div
                class="nhsuk-summary-list__row"
                *ngIf="invitation.status == 51"
              >
                <dt class="nhsuk-summary-list__key">Added by</dt>
                <dd class="nhsuk-summary-list__value">
                  {{ invitation.idCheckedBy }}
                </dd>
              </div>
              <div
                class="nhsuk-summary-list__row"
                *ngIf="invitation.status == 51"
              >
                <dt class="nhsuk-summary-list__key">Reasons</dt>
                <dd class="nhsuk-summary-list__value">
                  <span *ngFor="let holdReason of invitation.holdReason">
                    {{ holdReason | idCheckHeldReasonPipe }}.
                  </span>
                </dd>
              </div>
              <div
                class="nhsuk-summary-list__row"
                *ngIf="invitation.status == 51 && invitation.reviewBy != null"
              >
                <dt class="nhsuk-summary-list__key">Assigned to</dt>
                <dd class="nhsuk-summary-list__value">
                  {{ invitation.reviewBy }}
                </dd>
              </div>

              <div
                class="nhsuk-summary-list__row"
                *ngIf="invitation.status == 52"
              >
                <dt class="nhsuk-summary-list__key">UUID</dt>
                <dd class="nhsuk-summary-list__value">
                  <cim-link uuid="{{ invitation.cisUuid }}"></cim-link>
                </dd>
              </div>
              <div
                class="nhsuk-summary-list__row"
                *ngIf="invitation.status == 52"
              >
                <dt class="nhsuk-summary-list__key">Date approved</dt>
                <dd class="nhsuk-summary-list__value">
                  {{ invitation.completedDateUtc | date: "d MMM yyyy" }}
                </dd>
              </div>
              <div
                class="nhsuk-summary-list__row"
                *ngIf="invitation.status == 52"
              >
                <dt class="nhsuk-summary-list__key">Approved by</dt>
                <dd class="nhsuk-summary-list__value">
                  {{ invitation.idCheckedBy }}
                </dd>
              </div>

              <div
                class="nhsuk-summary-list__row"
                *ngIf="invitation.status == 53"
              >
                <dt class="nhsuk-summary-list__key">Rejected by</dt>
                <dd class="nhsuk-summary-list__value">
                  {{ invitation.idCheckedBy }}
                </dd>
              </div>
              <div
                class="nhsuk-summary-list__row"
                *ngIf="invitation.status == 53"
              >
                <dt class="nhsuk-summary-list__key">Date rejected</dt>
                <dd class="nhsuk-summary-list__value">
                  {{ invitation.idCheckedUtc | date: "d MMM yyyy" }}
                </dd>
              </div>
              <div
                class="nhsuk-summary-list__row"
                *ngIf="invitation.status == 53"
              >
                <dt class="nhsuk-summary-list__key">Reasons</dt>
                <dd class="nhsuk-summary-list__value">
                  <ul *ngFor="let failure of invitation.failedReason">
                    <li *ngFor="let reason of failure.reasons">
                      {{ failure.document }}:
                      {{ reason | idFailureCheckReasonPipe }}
                    </li>
                  </ul>
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </li>
    </ul>

    <pagination-template
      #p="paginationApi"
      [id]="config.id"
      (pageChange)="config.currentPage = $event"
    >
      <nav class="nhsuk-pagination" role="navigation" aria-label="Pagination">
        <ul class="nhsuk-list nhsuk-pagination__list">
          <li
            [class.disabled]="p.isFirstPage()"
            class="nhsuk-pagination-item--previous"
          >
            <a
              *ngIf="!p.isFirstPage()"
              (click)="p.previous()"
              class="nhsuk-pagination__link nhsuk-pagination__link--prev"
              (keydown.enter)="p.previous()"
              tabindex="0"
            >
              <span class="nhsuk-pagination__title">Previous</span>
              <span class="nhsuk-u-visually-hidden">:</span>
              <span class="nhsuk-pagination__page"
                >{{ config.currentPage - 1 }} of {{ p.getLastPage() }}</span
              >
              <svg
                class="nhsuk-icon nhsuk-icon__arrow-left"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                aria-hidden="true"
                width="34"
                height="34"
              >
                <path
                  d="M4.1 12.3l2.7 3c.2.2.5.2.7 0 .1-.1.1-.2.1-.3v-2h11c.6 0 1-.4 1-1s-.4-1-1-1h-11V9c0-.2-.1-.4-.3-.5h-.2c-.1 0-.3.1-.4.2l-2.7 3c0 .2 0 .4.1.6z"
                ></path>
              </svg>
            </a>
          </li>
          <li
            [class.disabled]="p.isLastPage()"
            class="nhsuk-pagination-item--next"
          >
            <a
              class="nhsuk-pagination__link nhsuk-pagination__link--next"
              *ngIf="!p.isLastPage()"
              (click)="p.next()"
              (keydown.enter)="p.next()"
              tabindex="0"
            >
              <span class="nhsuk-pagination__title">Next</span>
              <span class="nhsuk-u-visually-hidden">:</span>
              <span class="nhsuk-pagination__page"
                >{{ config.currentPage + 1 }} of {{ p.getLastPage() }}</span
              >
              <svg
                class="nhsuk-icon nhsuk-icon__arrow-right"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                aria-hidden="true"
                width="34"
                height="34"
              >
                <path
                  d="M19.6 11.66l-2.73-3A.51.51 0 0 0 16 9v2H5a1 1 0 0 0 0 2h11v2a.5.5 0 0 0 .32.46.39.39 0 0 0 .18 0 .52.52 0 0 0 .37-.16l2.73-3a.5.5 0 0 0 0-.64z"
                ></path>
              </svg>
            </a>
          </li>
        </ul>
      </nav>
    </pagination-template>
  </div>
</div>
