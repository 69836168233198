import {
  DataValidationResult,
  DataValidationReasons,
  Validator,
} from "../../models/data-validation-model";

export function isEmailAddressValid(
  emailAddress: string,
): DataValidationResult {
  const fullRegex: RegExp = new RegExp(
    /^(?!.{256})[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?/,
  );
  if (fullRegex.test(emailAddress)) {
    return { validity: true };
  } else {
    return getFailureReason(emailAddress, [
      {
        regex: new RegExp(/^$/),
        failureReason: DataValidationReasons.empty,
        succeedOnMatch: false,
      },
      {
        regex: new RegExp(/^(?!.{256})/),
        failureReason: DataValidationReasons.lengthTooLong,
        succeedOnMatch: true,
      },
    ]);
  }
}

export function isMobileValid(mobile: string): DataValidationResult {
  const fullRegex: RegExp = new RegExp(/^\+[\d\s]{10,19}$|^[\d\s]{11,20}$/);
  if (fullRegex.test(mobile)) {
    return { validity: true };
  } else {
    return getFailureReason(mobile, [
      {
        regex: new RegExp(/^$/),
        failureReason: DataValidationReasons.empty,
        succeedOnMatch: false,
      },
      {
        regex: new RegExp(/^.{11,}/),
        failureReason: DataValidationReasons.lengthTooShort,
        succeedOnMatch: true,
      },
      {
        regex: new RegExp(/^(?!.{20})/),
        failureReason: DataValidationReasons.lengthTooLong,
        succeedOnMatch: true,
      },
      {
        regex: new RegExp(/[^\d+\s]/),
        failureReason: DataValidationReasons.unexpectedCharacters,
        succeedOnMatch: false,
      },
    ]);
  }
}

export function isTitleValid(title: any): DataValidationResult {
  if (typeof title === "string" && title !== "0" && title != "") {
    return { validity: true };
  } else {
    return getFailureReason(title, [
      {
        regex: new RegExp(/^$/),
        failureReason: DataValidationReasons.empty,
        succeedOnMatch: false,
      },
    ]);
  }
}

export function isNameValid(
  name: string,
  required = true,
): DataValidationResult {
  const fullRegex: RegExp = new RegExp(
    /^(?!.* {2})(?=.*[A-Z])[ a-zA-Z-']{2,35}$/,
  );
  if (fullRegex.test(name) || (name === "" && !required)) {
    return { validity: true };
  } else {
    return getFailureReason(name, [
      {
        regex: new RegExp(/^$/),
        failureReason: DataValidationReasons.empty,
        succeedOnMatch: false,
      },
      {
        regex: new RegExp(/^.{2,}/),
        failureReason: DataValidationReasons.lengthTooShort,
        succeedOnMatch: true,
      },
      {
        regex: new RegExp(/^(?!.{36})/),
        failureReason: DataValidationReasons.lengthTooLong,
        succeedOnMatch: true,
      },
      {
        regex: new RegExp(/^(.*[A-Z])/),
        failureReason: DataValidationReasons.missingUppercaseCharacter,
        succeedOnMatch: true,
      },
      {
        regex: new RegExp(/^(?!.* {2})/),
        failureReason: DataValidationReasons.doubleSpace,
        succeedOnMatch: true,
      },
      {
        regex: new RegExp(/^[ a-zA-Z-']+$/),
        failureReason: DataValidationReasons.unexpectedCharacters,
        succeedOnMatch: true,
      },
    ]);
  }
}

export function isMiddleNamesValid(
  name: string,
  required = true,
): DataValidationResult {
  const fullRegex: RegExp = new RegExp(
    /^(?!.* {2})(?=.*[A-Z])[ a-zA-Z-']{2,30}$/,
  );
  if (fullRegex.test(name) || ((name === "" || name === null) && !required)) {
    return { validity: true };
  } else {
    return getFailureReason(name, [
      {
        regex: new RegExp(/^(?!.{31})/),
        failureReason: DataValidationReasons.lengthTooLong,
        succeedOnMatch: true,
      },
      {
        regex: new RegExp(/^(?!.* {2})/),
        failureReason: DataValidationReasons.doubleSpace,
        succeedOnMatch: true,
      },
      {
        regex: new RegExp(/^[ a-zA-Z-']+$/),
        failureReason: DataValidationReasons.unexpectedCharacters,
        succeedOnMatch: true,
      },
    ]);
  }
}

export function isNotesValid(
  content: string,
  maxlength = 300,
  required = false,
): DataValidationResult {
  const expression = '^[ a-zA-Z0-9.?!@%&,;:()"-/\n]{1,' + maxlength + "}$";
  const fullRegex: RegExp = new RegExp(expression);

  if (
    fullRegex.test(content) ||
    ((content === "" || content === null) && !required)
  ) {
    return { validity: true };
  } else {
    return getFailureReason(content, [
      {
        regex: new RegExp(/^$/),
        failureReason: DataValidationReasons.empty,
        succeedOnMatch: false,
      },
      {
        regex: new RegExp("^.{0," + maxlength + "}$"),
        failureReason: DataValidationReasons.lengthTooLong,
        succeedOnMatch: true,
      },
      {
        regex: new RegExp('^[ a-zA-Z0-9.?!@%&,;:()"-/\n]+$'),
        failureReason: DataValidationReasons.unexpectedCharacters,
        succeedOnMatch: true,
      },
    ]);
  }
}

export function isAddressLineOneValid(
  name: string,
  required = true,
): DataValidationResult {
  const fullRegex: RegExp = new RegExp(/^(?!.* {})[ ,0-9a-zA-Z-']{1,50}$/);
  if (fullRegex.test(name) || (name === "" && !required)) {
    return { validity: true };
  } else {
    return getFailureReason(name, [
      {
        regex: new RegExp(/^$/),
        failureReason: DataValidationReasons.empty,
        succeedOnMatch: false,
      },
      {
        regex: new RegExp(/^(?!.{50})/),
        failureReason: DataValidationReasons.lengthTooLong,
        succeedOnMatch: true,
      },
      {
        regex: new RegExp(/^[ ,0-9a-zA-Z-']+$/),
        failureReason: DataValidationReasons.unexpectedCharacters,
        succeedOnMatch: true,
      },
    ]);
  }
}

export function isAddressValid(
  name: string,
  required = true,
): DataValidationResult {
  const fullRegex: RegExp = new RegExp(/^(?!.* {2})[ ,0-9a-zA-Z-']{2,249}$/);
  if (fullRegex.test(name) || (name === "" && !required)) {
    return { validity: true };
  } else {
    return getFailureReason(name, [
      {
        regex: new RegExp(/^$/),
        failureReason: DataValidationReasons.empty,
        succeedOnMatch: false,
      },
      {
        regex: new RegExp(/^.{2,}/),
        failureReason: DataValidationReasons.lengthTooShort,
        succeedOnMatch: true,
      },
      {
        regex: new RegExp(/^(?!.{250})/),
        failureReason: DataValidationReasons.lengthTooLong,
        succeedOnMatch: true,
      },
      {
        regex: new RegExp(/^[ ,0-9a-zA-Z-']+$/),
        failureReason: DataValidationReasons.unexpectedCharacters,
        succeedOnMatch: true,
      },
    ]);
  }
}

export function isPhotoIdNumberValid(
  name: string,
  required = true,
): DataValidationResult {
  const fullRegex: RegExp = new RegExp(/^(?!.* {2})[ ,0-9a-zA-Z-']{2,35}$/);
  if (fullRegex.test(name) || (name === "" && !required)) {
    return { validity: true };
  } else {
    return getFailureReason(name, [
      {
        regex: new RegExp(/^$/),
        failureReason: DataValidationReasons.empty,
        succeedOnMatch: false,
      },
      {
        regex: new RegExp(/^.{2,}/),
        failureReason: DataValidationReasons.lengthTooShort,
        succeedOnMatch: true,
      },
      {
        regex: new RegExp(/^(?!.{35})/),
        failureReason: DataValidationReasons.lengthTooLong,
        succeedOnMatch: true,
      },
      {
        regex: new RegExp(/^[ ,0-9a-zA-Z-']+$/),
        failureReason: DataValidationReasons.unexpectedCharacters,
        succeedOnMatch: true,
      },
    ]);
  }
}

export function isUKDrivingLicenseNumberValid(
  name: string,
  required = true,
): DataValidationResult {
  const fullRegex: RegExp = new RegExp(
    /^(?!.* {16})([ ,0-9a-zA-Z-']{16}|[ ,0-9a-zA-Z-']{19})$/,
  );
  if (fullRegex.test(name) || (name === "" && !required)) {
    return { validity: true };
  } else {
    return getFailureReason(name, [
      {
        regex: new RegExp(/^$/),
        failureReason: DataValidationReasons.empty,
        succeedOnMatch: false,
      },
      {
        regex: new RegExp(/^(.{16}|.{19})$/),
        failureReason: DataValidationReasons.lengthTooShort,
        succeedOnMatch: true,
      },
      {
        regex: new RegExp(/^[ ,0-9a-zA-Z-']+$/),
        failureReason: DataValidationReasons.unexpectedCharacters,
        succeedOnMatch: true,
      },
    ]);
  }
}

export function isUkPassportNumberValid(
  name: string,
  required = true,
): DataValidationResult {
  const fullRegex: RegExp = new RegExp(/^\d{9}$/);
  if (fullRegex.test(name) || (name === "" && !required)) {
    return { validity: true };
  } else {
    return getFailureReason(name, [
      {
        regex: new RegExp(/^$/),
        failureReason: DataValidationReasons.empty,
        succeedOnMatch: false,
      },
      {
        regex: new RegExp(/^\d+$/),
        failureReason: DataValidationReasons.unexpectedCharacters,
        succeedOnMatch: true,
      },
      {
        regex: new RegExp(/^.{0,8}$/),
        failureReason: DataValidationReasons.lengthTooShort,
        succeedOnMatch: false,
      },
      {
        regex: new RegExp(/^(?=.{10,})/),
        failureReason: DataValidationReasons.lengthTooLong,
        succeedOnMatch: false,
      },
    ]);
  }
}

export function isNonUkDocumentNumberValid(
  idNumber: string,
  required = true,
): DataValidationResult {
  const fullRegex: RegExp = new RegExp(/^(?!.* {2})[ a-zA-Z0-9;:()-/]{2,35}$/);
  if (fullRegex.test(idNumber) || (idNumber === "" && !required)) {
    return { validity: true };
  } else {
    return getFailureReason(idNumber, [
      {
        regex: new RegExp(/^$/),
        failureReason: DataValidationReasons.empty,
        succeedOnMatch: false,
      },
      {
        regex: new RegExp(/^.{2,}/),
        failureReason: DataValidationReasons.lengthTooShort,
        succeedOnMatch: true,
      },
      {
        regex: new RegExp(/^(?!.{35})/),
        failureReason: DataValidationReasons.lengthTooLong,
        succeedOnMatch: true,
      },
      {
        regex: new RegExp(/^[ a-zA-Z0-9;:()-/]+$/),
        failureReason: DataValidationReasons.unexpectedCharacters,
        succeedOnMatch: true,
      },
    ]);
  }
}

export function isTownCityCountyValid(
  name: string,
  required = true,
): DataValidationResult {
  const fullRegex: RegExp = new RegExp(/^(?!.* {2})[ ,a-zA-Z-']{2,50}$/);
  if (fullRegex.test(name) || (name === "" && !required)) {
    return { validity: true };
  } else {
    return getFailureReason(name, [
      {
        regex: new RegExp(/^$/),
        failureReason: DataValidationReasons.empty,
        succeedOnMatch: false,
      },
      {
        regex: new RegExp(/^.{2,}/),
        failureReason: DataValidationReasons.lengthTooShort,
        succeedOnMatch: true,
      },
      {
        regex: new RegExp(/^(?!.{50})/),
        failureReason: DataValidationReasons.lengthTooLong,
        succeedOnMatch: true,
      },
      {
        regex: new RegExp(/^[ a-zA-Z-']+$/),
        failureReason: DataValidationReasons.unexpectedCharacters,
        succeedOnMatch: true,
      },
    ]);
  }
}

export function isPostcodeValid(
  name: string,
  required = true,
): DataValidationResult {
  const fullRegex: RegExp = new RegExp(
    /^[a-zA-Z]{1,2}(\d{1,2}|\d[a-zA-Z])\s*\d[a-zA-Z]{2}$/,
  );
  if (fullRegex.test(name) || (name === "" && !required)) {
    return { validity: true };
  } else {
    return getFailureReason(name, [
      {
        regex: new RegExp(/^$/),
        failureReason: DataValidationReasons.empty,
        succeedOnMatch: false,
      },
      {
        regex: new RegExp(/^.{5,}/),
        failureReason: DataValidationReasons.lengthTooShort,
        succeedOnMatch: true,
      },
      {
        regex: new RegExp(/^(?!.{7})/),
        failureReason: DataValidationReasons.lengthTooLong,
        succeedOnMatch: true,
      },
      {
        regex: new RegExp(/^[ a-zA-Z-']+$/),
        failureReason: DataValidationReasons.unexpectedCharacters,
        succeedOnMatch: true,
      },

      {
        regex: new RegExp(
          /^[a-zA-Z]{1,2}(\d{1,2}|\d[a-zA-Z])\s*\d[a-zA-Z]{2}$/,
        ),
        failureReason: DataValidationReasons.invalid,
        succeedOnMatch: false,
      },
    ]);
  }
}

export function isPreferredNameValid(name: string): DataValidationResult {
  const fullRegex: RegExp = new RegExp(/^(.){0,20}$/);
  if (fullRegex.test(name)) {
    return isNameValid(name, false);
  } else {
    return getFailureReason(name, [
      {
        regex: new RegExp(/^(?!.{21})/),
        failureReason: DataValidationReasons.lengthTooLong,
        succeedOnMatch: true,
      },
    ]);
  }
}

export function isNiNumberValid(
  niNumber: string,
  required = true,
): DataValidationResult {
  const fullRegex: RegExp = new RegExp(
    /^(?!BG)(?!GB)(?!NK)(?!KN)(?!TN)(?!NT)(?!ZZ)(?:[A-CEGHJ-PR-TW-Z][A-CEGHJ-NPR-TW-Z])(?:\s*\d\s*){6}([A-D]|\s)$/,
  );
  if (fullRegex.test(niNumber) || (niNumber === "" && !required)) {
    return { validity: true };
  } else {
    return getFailureReason(niNumber, [
      {
        regex: new RegExp(/^$/),
        failureReason: DataValidationReasons.empty,
        succeedOnMatch: false,
      },
    ]);
  }
}

export function isOtpNumberValid(otp: string): DataValidationResult {
  const fullRegex: RegExp = new RegExp(/^\d{6}$/);
  if (fullRegex.test(otp)) {
    return { validity: true };
  } else {
    return getFailureReason(otp, [
      {
        regex: new RegExp(/^$/),
        failureReason: DataValidationReasons.empty,
        succeedOnMatch: false,
      },
      {
        regex: new RegExp(/^.{4,}/),
        failureReason: DataValidationReasons.lengthTooShort,
        succeedOnMatch: true,
      },
      {
        regex: new RegExp(/^(?!.{4})/),
        failureReason: DataValidationReasons.lengthTooLong,
        succeedOnMatch: true,
      },
      {
        regex: new RegExp(/^\d+$/),
        failureReason: DataValidationReasons.unexpectedCharacters,
        succeedOnMatch: true,
      },
    ]);
  }
}

export function getFailureReason(
  queryString: string,
  validators: Validator[],
): DataValidationResult {
  for (const validator of validators) {
    if (validator.regex.test(queryString) !== validator.succeedOnMatch) {
      return { validity: false, errorReason: validator.failureReason };
    }
  }
  return { validity: false, errorReason: DataValidationReasons.unknown };
}

export function stripSpecialCharacters(input: string) {
  return input.replace(/[^a-zA-Z0-9, ]/g, "");
}
