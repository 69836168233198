import { Component, OnInit, ViewChild } from "@angular/core";
import { RouterService } from "src/app/services/router.service";
import { ErrorSummaryMessage } from "../../../models/error-summary-message";
import { Subscription } from "rxjs";
import { InvitationConfirmation } from "./../../../../store/states/invitee-spa.state";
import { TextInputComponent } from "../../common/form-fields/text-inputs/text-input.component";
import { isNiNumberValid } from "src/app/utils/data-validation/data-validation";
import { Title } from "@angular/platform-browser";
import { InviteeDetails } from "src/app/utils/invitee-details";

@Component({
  selector: "app-confirm-ni-number",
  templateUrl: "./confirm-ni-number.component.html",
})
export class ConfirmNiNumberComponent implements OnInit {
  breadcrumbRoute = "/invitee/confirm-dob";
  breadcrumbText = "Back";
  invitationConfirmationSubscription: Subscription;
  invitationConfirmation: InvitationConfirmation;
  captureDeliveryAddress: boolean;
  niNumberValid: boolean;
  niNumberErrorMessage = "";
  summaryErrorMessages = Array<ErrorSummaryMessage>();

  @ViewChild("niNumberInput") niNumberInput: TextInputComponent;

  constructor(
    private routerService: RouterService,
    private title: Title,
    private inviteeDetails: InviteeDetails,
  ) {}

  readonly errorMessages = {
    niNumber: {
      empty: "Enter your national insurance number.",
      unknown: "Enter a national insurance number in the correct format.",
    },
  };

  async ngOnInit(): Promise<void> {
    this.title.setTitle("Enter your national insurance number");
    this.invitationConfirmation = this.inviteeDetails.getInviteeDetails();
  }

  submitAnswer(): void {
    if (this.isAnswerValid()) {
      this.inviteeDetails.setInviteeDetails(this.invitationConfirmation);
      this.routerService.redirectToCheckYourAnswers();
    }
  }

  isAnswerValid(): boolean {
    this.niNumberInput.setQuestionAnswer();
    if (this.niNumberValid) {
      return true;
    } else {
      return false;
    }
  }

  updateNiNumberAnswer(niNumber: string): void {
    /* If the last digit is a number, add a space
     ** This is to comply with CIM API accepting a space as last character for ni numbers with no ending letter */
    if (/^\d$/.test(niNumber[niNumber.length - 1])) {
      niNumber = niNumber.concat(" ");
    }
    const validationResult = isNiNumberValid(niNumber);
    if (validationResult.validity) {
      this.niNumberErrorMessage = "";
      this.niNumberValid = true;
    } else {
      this.niNumberErrorMessage =
        this.errorMessages.niNumber[validationResult.errorReason];
      this.niNumberValid = false;
    }
    this.invitationConfirmation.niNumber = niNumber;
    this.updateNiNumberErrorMessage();

    this.invitationConfirmation.niNumber = niNumber;
  }

  updateNiNumberValid(niNumberValid: boolean): void {
    this.niNumberValid = niNumberValid;
    if (niNumberValid) {
      this.updateNiNumberErrorMessage();
    }
  }

  updateNiNumberErrorMessage(): void {
    const updatedErrorSummary = Array<ErrorSummaryMessage>();
    if (this.niNumberErrorMessage) {
      updatedErrorSummary.push({
        message: this.niNumberErrorMessage,
        id: "ni-number-input",
      });
    }
    this.summaryErrorMessages = updatedErrorSummary;
  }
}
