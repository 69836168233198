import { Component, Inject, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { SESSION_STORAGE, StorageService } from "ngx-webstorage-service";

@Component({
  selector: "app-portal-select",
  templateUrl: "./portal-select.component.html",
  styleUrls: ["./portal-select.component.css"],
})
export class PortalSelectComponent implements OnInit {
  constructor(
    @Inject(SESSION_STORAGE) private sessionStorage: StorageService,
    private title: Title,
  ) {}

  isServiceManager: boolean = false;

  ngOnInit(): void {
    this.title.setTitle("Portal select");
    this.isServiceManager =
      this.sessionStorage.get("sessionToken") &&
      this.sessionStorage.get("sessionToken").portal == 2;
  }
}
