<session-check></session-check>
<div *ngIf="!loading" class="nhsuk-grid-row">
  <div class="nhsuk-grid-column-two-thirds">
    <div *ngIf="emailResent" class="nhsuk-alert nhsuk-alert--info">
      <h3 class="nhsuk-u-visually-hidden">Information:</h3>
      <p class="nhsuk-u-font-weight-bold">Security code has been resent</p>
    </div>

    <h1 autofocus tabindex="-1">Verify {{ category }} email address</h1>
    <p>
      Use the 6 digit security code that has been sent to
      <strong>{{ email }}</strong>
      to verify your email address.
    </p>
    <p>The security code will expire in 1 hour from when it was sent.</p>

    <div class="nhsuk-form-group">
      <div class="nhsuk-hint" id="verify-email-hint">
        The code contains 6 numbers
      </div>
      <text-input
        #verifyEmail
        [id]="'verify-email'"
        [errorMessage]="otpErrorMessage"
        [value]="otpValue"
        [uppercase]="false"
        (questionAnswer)="updateOTPAnswer($event)"
        size="nhsuk-input--width-5"
        [cypressValue]="'otp'"
        autocomplete="off"
      ></text-input>
    </div>
    <button
      [disabled]="loading"
      (click)="submitAnswer()"
      id="continue"
      class="nhsuk-button"
      type="submit"
    >
      Continue
    </button>

    <details class="nhsuk-details">
      <summary class="nhsuk-details__summary">
        <span class="nhsuk-details__summary-text">
          Not received a security code?
        </span>
      </summary>
      <div class="nhsuk-details__text">
        <p>Check your spam or junk folder.</p>
        <p>
          <a (click)="resendEmail()" id="resendEmail">Send the link again</a>
          if you have not received it or if it has expired.
        </p>
        <p>
          <a href="/settings/update-email/{{ category }}"
            >Update your email address</a
          >
          to correct a typo or use a different email address.
        </p>
      </div>
    </details>
  </div>
</div>
<section *ngIf="loading">
  <progress-indicator spinnerText="please wait"></progress-indicator>
</section>
