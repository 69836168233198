<div class="nhsuk-grid-row">
  <div *ngIf="addresses" class="nhsuk-grid-column-two-thirds">
    <breadcrumb-link
      [breadcrumbRoute]="breadcrumbRoute"
      [breadcrumbText]="breadcrumbText"
    ></breadcrumb-link>
    <error-summary
      [errorSummaryMessages]="summaryErrorMessages"
    ></error-summary>
    <fieldset class="nhsuk-fieldset">
      <legend class="nhsuk-fieldset__legend nhsuk-fieldset__legend--xl">
        <h1
          autofocus
          tabindex="-1"
          class="nhsuk-fieldset__heading nhsuk-u-padding-bottom-3"
        >
          {{ headerText }}
        </h1>
      </legend>

      <p id="postcode" class="nhsuk-u-margin-bottom-1">Postcode</p>

      <p>
        <strong>{{ searchPostcode }}</strong>
        <a
          aria-describedby="postcode"
          class="nhsuk-u-padding-left-3"
          routerLink="{{ breadcrumbRoute }}"
          >Change
        </a>
      </p>

      <div class="nhsuk-form-group">
        <label class="nhsuk-label nhsuk-heading-m" for="select-address">
          Select an address
        </label>
        <select
          (change)="checkAddressSelected()"
          [(ngModel)]="selectedAddressId"
          class="nhsuk-select"
          id="select-address"
        >
          <option [ngValue]="-1" selected>
            Select from {{ addresses.length }} addresses
          </option>
          <option [ngValue]="address.id" *ngFor="let address of addresses">
            {{ address.address }}
          </option>
        </select>
      </div>
    </fieldset>

    <button
      class="nhsuk-button nhsuk-u-margin-bottom-5"
      (click)="submitAnswer()"
      id="continue"
      data-cy="continue-button"
    >
      Continue
    </button>
    <p>
      <a aria-describedby="postcode" routerLink="{{ manualAddressLink }}"
        >I can't find my address on the list
      </a>
    </p>
  </div>
  <progress-indicator
    *ngIf="!addresses"
    spinnerText="Loading addresses"
  ></progress-indicator>
</div>
