/* istanbul ignore file */
import { Injectable } from "@angular/core";
import { StorageMap } from "@ngx-pwa/local-storage";
import { CryptoService } from "../../services/security/crypto.service";

@Injectable()
export class AuthUtil {
  constructor(
    private storage: StorageMap,
    private cryptoService: CryptoService,
  ) {}

  saveAuthStateInLocalStore(redirectUrl: string): void {
    const url = new URL(redirectUrl);
    const args = new URLSearchParams(url.search);

    if (args.has("state")) {
      this.cryptoService.hash256(args.get("state")).then((hex) => {
        this.storage.set("state", hex).subscribe(() => {});
      });
    }
  }
}
