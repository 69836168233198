import { Component, Inject, OnInit } from "@angular/core";
import { InvitationIdCheck } from "src/store/states/spa.state";
import { PaginationInstance } from "ngx-pagination/lib/pagination-instance";
import { IdCheckerService } from "src/app/services/id-checker.service";
import {
  InvitationDetails,
  InvitationStatus,
} from "src/app/models/invitationDetails";
import { Title } from "@angular/platform-browser";
import { RouterService } from "src/app/services/router.service";
import { idCheckSortChanged } from "../../../utils/common/common";
import { SESSION_STORAGE, StorageService } from "ngx-webstorage-service";
import { IdFailureCheckReasonPipe } from "src/app/utils/pipes/id-check-failure-reason/id-check-failure-reason.pipe";

@Component({
  selector: "app-holding-list",
  templateUrl: "./holding-list.component.html",
})
export class HoldingListComponent implements OnInit {
  breadcrumbRoute = "/id-checker/review-list";
  breadcrumbText = "Back";
  invitations: InvitationIdCheck[];
  collectionSize: number;

  public config: PaginationInstance = {
    id: "custom",
    itemsPerPage: 50,
    currentPage: 1,
  };

  constructor(
    private routerService: RouterService,
    private idCheckerService: IdCheckerService,
    private title: Title,
    private idFailureCheckReasonPipe: IdFailureCheckReasonPipe,
    @Inject(SESSION_STORAGE) private sessionStorage: StorageService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.title.setTitle("On hold applications");
    await this.getHeldInvitations();
    this.clearIdCheckerSelections();
  }

  mapInvites(invites: InvitationDetails[]): InvitationIdCheck[] {
    return invites.map((invite) => new InvitationIdCheck(invite));
  }

  async getHeldInvitations() {
    this.idCheckerService
      .getIdCheckerInvitations(InvitationStatus["On hold"])
      .subscribe({
        next: (response) => {
          this.invitations = this.mapInvites(response["body"]).sort((a, b) =>
            a.idCheckedUtc > b.idCheckedUtc ? 1 : -1,
          );
          this.collectionSize = this.invitations.length;
        },
        error: (error) => {
          this.routerService.handleErrorAuthRoutes(error.status);
        },
      });
  }

  getRejectReasonsPerDocument(invitation: InvitationIdCheck) {
    if (!invitation.failedReason) return [];
    return invitation.failedReason.flatMap((failure) => {
      let newFailure = {
        document: failure.document,
        reasons: failure.reasons.map((reason) =>
          this.idFailureCheckReasonPipe.transform(
            reason,
            failure.document == "Proof of address",
          ),
        ),
      };
      return `${newFailure.document}${newFailure.reasons.join(",")}`;
    });
  }

  sortChanged(columnName: string): void {
    this.invitations = idCheckSortChanged(this.invitations, columnName);
  }

  clearIdCheckerSelections(): void {
    this.sessionStorage.remove("idchecker-selections");
  }
}
