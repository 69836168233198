import { Component, Inject, OnInit } from "@angular/core";
import { SESSION_STORAGE, StorageService } from "ngx-webstorage-service";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "app-device-not-supported",
  templateUrl: "./device-not-supported.component.html",
})
export class DeviceNotSupportedComponent implements OnInit {
  supportEmail: string;

  constructor(
    private title: Title,
    @Inject(SESSION_STORAGE) private sessionStorage: StorageService,
  ) {}

  ngOnInit(): void {
    this.title.setTitle("Device not supported");
    this.supportEmail = this.sessionStorage.get("supportEmail");
  }
}
