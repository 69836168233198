<p class="nhsuk-body-s nhsuk-u-margin-top-2 nhsuk-u-margin-bottom-2">
  Move:
  <a
    id="shift-left"
    class="nhsuk-link shift-link"
    tabindex="0"
    (click)="moveLeft()"
    (keydown.enter)="moveLeft()"
    >Left</a
  >
  |
  <a
    id="shift-right"
    class="nhsuk-link shift-link"
    tabindex="0"
    (click)="moveRight()"
    (keydown.enter)="moveRight()"
    >Right</a
  >
  |
  <a
    id="shift-up"
    class="nhsuk-link shift-link"
    tabindex="0"
    (click)="moveUp()"
    (keydown.enter)="moveUp()"
    >Up</a
  >
  |
  <a
    id="shift-down"
    class="nhsuk-link shift-link"
    tabindex="0"
    (click)="moveDown()"
    (keydown.enter)="moveDown()"
    >Down</a
  >
</p>

<p class="nhsuk-body-s nhsuk-u-margin-top-2 nhsuk-u-margin-bottom-2">
  Rotate:
  <a
    id="rotate-clockwise"
    class="nhsuk-link shift-link"
    tabindex="0"
    (click)="rotateClockwise()"
    (keydown.enter)="rotateClockwise()"
    >Clockwise</a
  >
  |
  <a
    id="rotate-anticlockwise"
    class="nhsuk-link shift-link"
    tabindex="0"
    (click)="rotateAntiClockwise()"
    (keydown.enter)="rotateAntiClockwise()"
    >Anticlockwise</a
  >
</p>
<image-cropper
  *ngIf="imageBase64"
  [imageBase64]="imageBase64"
  [maintainAspectRatio]="true"
  [containWithinAspectRatio]="containWithinAspectRatio"
  [aspectRatio]="10 / 12"
  [resizeToWidth]="225"
  [resizeToHeight]="270"
  [cropperMinWidth]="225"
  [onlyScaleDown]="true"
  [roundCropper]="false"
  [canvasRotation]="canvasRotation"
  [transform]="transform"
  [alignImage]="'left'"
  [style.display]="showCropper ? null : 'none'"
  format="png"
  output="base64"
  (imageCropped)="imageCropped($event)"
  (imageLoaded)="imageLoaded()"
  (cropperReady)="cropperReady($event)"
  (loadImageFailed)="loadImageFailed()"
  [cropper]="cropper"
  imageAltText="Select area to crop"
></image-cropper>
