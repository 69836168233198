import { initialState, SPAState } from "../states/spa.state";
import * as SPAActions from "../actions/spa.actions";

/* istanbul ignore next */
export function reducerSPA(
  state = initialState,
  action: SPAActions.SPAActions,
): SPAState {
  switch (action.type) {
    case SPAActions.UPDATE_INVITATION: {
      return {
        ...state,
        invitation: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}
