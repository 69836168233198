import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { EnvironmentConfigService } from "./environment-config.service";

import {
  IdCheckHeldReason,
  InvitationDetails,
  InvitationStatus,
} from "../models/invitationDetails";
import { SessionAuthenticationService } from "./session-authentication.service";
import { Portal } from "src/app/models/session-token";
import { IdCheckFailure } from "../models/idCheckFailure";

@Injectable()
export class SendInvitationService {
  constructor(
    private http: HttpClient,
    private environmentConfigService: EnvironmentConfigService,
    private sessionAuthenticationService: SessionAuthenticationService,
  ) {}

  private static mapIdCheckInvitation(
    invitationId: string,
    status?: InvitationStatus,
    idCheckedBy?: string,
    idCheckedByUserId?: string,
    failedNotes?: string,
    failedReason?: IdCheckFailure[],
    holdReason?: IdCheckHeldReason[],
    holdNotes?: string,
    smartcardPhoto?: string,
  ) {
    return {
      invitation_id: invitationId,
      status: status,
      id_checked_by: idCheckedBy,
      id_checked_by_user_id: idCheckedByUserId,
      failed_notes: failedNotes,
      id_check_failure: failedReason,
      hold_reason: holdReason,
      hold_notes: holdNotes,
      smart_card: smartcardPhoto,
    };
  }

  private static mapInvitation(
    firstName: string,
    familyName: string,
    workEmail: string,
    mobile: string,
    isPersonalEmail: boolean,
    createdByUserName: string,
    senderEmail: string,
    invitationId: string,
    captureDeliveryAddress: boolean,
    paa?: string,
    resentCount?: number,
  ): InvitationDetails {
    return {
      email: workEmail,
      given_name: firstName,
      family_name: familyName,
      mobile,
      is_personal_email: isPersonalEmail,
      created_by_user_name: createdByUserName,
      sender_email: senderEmail,
      invitation_id: invitationId,
      paa: paa,
      resent_count: resentCount,
      capture_delivery_address: captureDeliveryAddress,
    };
  }

  postInvitation(
    firstName: string,
    familyName: string,
    workEmail: string,
    mobile: string,
    isPersonalEmail: boolean,
    createdByUserName: string,
    senderEmail: string,
    paa: string,
    captureDeliveryAddress: boolean,
  ): Promise<any> {
    const invitationDetails = SendInvitationService.mapInvitation(
      firstName,
      familyName,
      workEmail,
      mobile,
      isPersonalEmail,
      createdByUserName,
      senderEmail,
      null,
      captureDeliveryAddress,
      paa,
      null,
    );

    return new Promise((resolve, reject) => {
      this.http
        .post(
          `${
            this.environmentConfigService.getConfig().idBridgeApiUrl
          }/invitation`,
          invitationDetails,
          {
            headers: this.sessionAuthenticationService.applyAuthorizationHeader(
              Portal.Invitation,
            ),
            observe: "response",
          },
        )
        .subscribe(
          (res) => {
            resolve(res);
          },
          (error) => {
            reject(error);
          },
        );
    });
  }

  putInvitation(
    firstName: string,
    familyName: string,
    workEmail: string,
    mobile: string,
    isPersonalEmail: boolean,
    createdByUserName: string,
    senderEmail: string,
    paa: string,
    invitationId: string,
    resentCount: number,
    captureDeliveryAddress: boolean,
  ): Promise<any> {
    const invitationDetails = SendInvitationService.mapInvitation(
      firstName,
      familyName,
      workEmail,
      mobile,
      isPersonalEmail,
      createdByUserName,
      senderEmail,
      invitationId,
      captureDeliveryAddress,
      paa,
      resentCount,
    );

    return new Promise((resolve, reject) => {
      this.http
        .put(
          `${
            this.environmentConfigService.getConfig().idBridgeApiUrl
          }/invitation`,
          invitationDetails,
          {
            headers: this.sessionAuthenticationService.applyAuthorizationHeader(
              Portal.Invitation,
            ),
            observe: "response",
          },
        )
        .subscribe(
          (res) => {
            resolve(res);
          },
          (error) => {
            reject(error);
          },
        );
    });
  }

  putIdCheckInvitation(
    invitationId: string,
    idCheckedBy: string,
    idCheckedByUserId: string,
    status: InvitationStatus,
    failedNotes?: string,
    failedReason?: IdCheckFailure[],
    holdReason?: IdCheckHeldReason[],
    holdNotes?: string,
    smartcardPhoto?: string,
  ): Promise<any> {
    const invitationDetails = SendInvitationService.mapIdCheckInvitation(
      invitationId,
      status,
      idCheckedBy,
      idCheckedByUserId,
      failedNotes,
      failedReason,
      holdReason,
      holdNotes,
      smartcardPhoto,
    );
    return new Promise((resolve, reject) => {
      this.http
        .put(
          `${
            this.environmentConfigService.getConfig().idBridgeApiUrl
          }/idChecker/idCheckUpdate`,
          invitationDetails,
          {
            headers: this.sessionAuthenticationService.applyAuthorizationHeader(
              Portal.Checker,
            ),
            observe: "response",
          },
        )
        .subscribe(
          (res) => {
            resolve(res);
          },
          (error) => {
            reject(error);
          },
        );
    });
  }

  resendMultipleInvitations(invitationIds: string[]): Promise<any> {
    const payload = {
      invitation_ids: invitationIds,
    };
    return new Promise((resolve, reject) => {
      this.http
        .put(
          `${
            this.environmentConfigService.getConfig().idBridgeApiUrl
          }/invitation/resends`,
          payload,
          {
            headers: this.sessionAuthenticationService.applyAuthorizationHeader(
              Portal.Invitation,
            ),
            observe: "response",
          },
        )
        .subscribe(
          (res) => {
            resolve(res);
          },
          (error) => {
            reject(error);
          },
        );
    });
  }

  getIsEmailDomainValid(email: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(
          `${
            this.environmentConfigService.getConfig().idBridgeApiUrl
          }/invitation/isEmailDomainValid?email=${email}`,
          {
            headers: this.sessionAuthenticationService.applyAuthorizationHeader(
              Portal.Invitation,
            ),
            observe: "body",
            responseType: "json",
          },
        )
        .subscribe(
          (res) => {
            resolve(res);
          },
          (error) => {
            reject(error);
          },
        );
    });
  }

  getIsDuplicateApplication(email: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(
          `${
            this.environmentConfigService.getConfig().idBridgeApiUrl
          }/invitation/isDuplicateApplication?email=${email}`,
          {
            headers: this.sessionAuthenticationService.applyAuthorizationHeader(
              Portal.Invitation,
            ),
            observe: "body",
            responseType: "json",
          },
        )
        .subscribe(
          (res) => {
            resolve(res);
          },
          (error) => {
            reject(error);
          },
        );
    });
  }

  markAsDoneInvitations(invitationIds: string[], done: boolean): Promise<any> {
    const payload = {
      invitation_ids: invitationIds,
      done_flag: done,
    };
    return new Promise((resolve, reject) => {
      this.http
        .put(
          `${
            this.environmentConfigService.getConfig().idBridgeApiUrl
          }/invitation/markDone`,
          payload,
          {
            headers: this.sessionAuthenticationService.applyAuthorizationHeader(
              Portal.Invitation,
            ),
            observe: "response",
          },
        )
        .subscribe(
          (res) => {
            resolve(res);
          },
          (error) => {
            reject(error);
          },
        );
    });
  }
}
