import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

@Injectable()
export class RouterService {
  result: Promise<void>;
  constructor(private router: Router) {}

  getCurrentUrl(): string {
    return this.router.url;
  }

  redirectTo(endpoint: string): void {
    this.router.navigate([endpoint]);
  }

  redirectToWithId(endpoint: string, id): void {
    this.router.navigate([endpoint, id]);
  }

  redirectToWithIdAndExtas(endpoint: string, id, extras): void {
    this.router.navigate([endpoint, id], extras);
  }

  redirectToStart(): void {
    this.redirectTo("/start");
  }

  redirectToInviteeStart(): void {
    this.redirectTo("/invitee/start");
  }

  redirectToPortalSelect(): void {
    this.redirectTo("/portal-select");
  }

  redirectToInvitationDashboard(): void {
    this.redirectTo("/invitation/dashboard");
  }

  redirectToSettings(): void {
    this.redirectTo("/settings");
  }

  redirectToBasicInfo(): void {
    this.redirectTo("/invitation/basic-info");
  }

  redirectToVerifyEmailCategory(category: string): void {
    this.redirectToWithId("/settings/verify-email", category);
  }

  redirectToUpdateEmailCategory(category: string): void {
    this.redirectToWithId("/settings/update-email", category);
  }

  redirectToConfirmPersonalEmail(invitationId: string): void {
    this.redirectToWithId("/invitation/confirm-personal-email", invitationId);
  }

  redirectToCancelMultiple() {
    this.redirectTo("/invitation/cancel-multiple");
  }

  redirectToInviteCancelled(invitationId: string): void {
    this.redirectToWithId("/invitation/invite-cancelled", invitationId);
  }

  redirectToInviteSentBulk(): void {
    this.redirectTo("/invitation/invite-sent-bulk");
  }

  redirectToNotSentApplications(): void {
    this.redirectTo("/invitation/not-sent-applications");
  }

  redirectToFileContainsErrors(): void {
    this.redirectTo("/invitation/file-contains-errors");
  }

  redirectToFinalSummary(): void {
    this.redirectTo("/invitation/final-summary");
  }

  redirectToInvitationSuccess(invitationId: string): void {
    this.redirectToWithId("/invitation/success", invitationId);
  }

  redirectToFinalSummaryWithId(invitationId: string): void {
    this.redirectToWithId("/invitation/final-summary", invitationId);
  }

  redirectToCheckerReviewList(): void {
    this.redirectTo("/id-checker/review-list");
  }

  redirectToConfirmNiNumber(): void {
    this.redirectTo("/invitee/confirm-ni-number");
  }

  redirectToCheckYourAnswers(): void {
    this.redirectTo("/invitee/check-your-answers");
  }

  redirectToConfirmSubmit(): void {
    this.redirectTo("/invitee/confirm-submit");
  }

  redirectToConfirmConfirmDob(): void {
    this.redirectTo("/invitee/confirm-dob");
  }

  redirectToPassport(): void {
    this.redirectTo("/invitee/passport");
  }

  redirectToCheckYourAddress(): void {
    this.redirectTo("/invitee/check-your-address");
  }

  redirectToReviewId(invitationId: string): void {
    this.redirectToWithId("/id-checker/review-id", invitationId);
  }

  redirectToConfirmAddress(type: string): void {
    this.redirectToWithId("/invitee/confirm-address", type);
  }

  redirectToConfirmPostcode(type: string): void {
    this.redirectToWithId("/invitee/confirm-postcode", type);
  }

  redirectToConfirmName(): void {
    this.redirectTo("/invitee/confirm-name");
  }

  redirectToInvalidRequest(): void {
    this.redirectTo("/invitee/invalid-request");
  }

  redirectToDelivery(): void {
    this.redirectTo("/invitee/delivery");
  }

  redirectToPoa(): void {
    this.redirectTo("/invitee/poa");
  }

  redirectToSaveSelection(): void {
    this.redirectTo("/invitee/save-selection");
  }

  redirectToAuthenticate(): void {
    this.redirectTo("/authenticate");
  }

  redirectToNoAuth(): void {
    this.redirectTo("/authenticate/no-auth");
  }

  redirectToLoggedOut(): void {
    this.redirectTo("/authenticate/logged-out");
  }

  redirectToUserLoggedOut(): void {
    this.redirectTo("/authenticate/user-logged-out");
  }

  redirectToServiceUnavailable(): void {
    this.redirectTo("/authenticate/service-unavailable");
  }

  redirectToUnknown(): void {
    this.redirectTo("/authenticate/unknown");
  }

  redirectToInternalServerError(): void {
    this.redirectTo("/internal-server-error");
  }

  redirectToOtpLocked(): void {
    this.redirectTo("/invitee/otp-locked");
  }

  redirectToOtpExpired(): void {
    this.redirectTo("/invitee/otp-expired");
  }

  redirectToPhotoIdOne(): void {
    this.redirectTo("/invitee/photo-id-one");
  }

  redirectToPhotoIdTwo(): void {
    this.redirectTo("/invitee/photo-id-two");
  }

  redirectToConfirmPreferredName(): void {
    this.redirectTo("/invitee/confirm-preferred-name");
  }

  redirectToStep1(): void {
    this.redirectTo("/invitee/step1");
  }

  redirectToProfilePhotoUpload(): void {
    this.redirectTo("/invitee/profile-photo-upload");
  }

  redirectToProfilePhotoReview(): void {
    this.redirectTo("/invitee/profile-photo-review");
  }

  redirectToNoPoa(): void {
    this.redirectTo("/invitee/no-continue/no-poa");
  }

  redirectToAlreadySubmitted(): void {
    this.redirectTo("/invitee/no-continue/already-submitted");
  }

  redirectToApplicationIsInReview(): void {
    this.redirectTo("/invitee/no-continue/application-id-review");
  }

  redirectToApplicationIsApproved(): void {
    this.redirectTo("/invitee/no-continue/application-approved");
  }

  redirectToApplicationIsRejected(): void {
    this.redirectTo("/invitee/no-continue/application-rejected");
  }

  redirectToApplicationIsCancelled(): void {
    this.redirectTo("/invitee/no-continue/application-cancelled");
  }

  redirectToApplicationIsInvalid(): void {
    this.redirectTo("/invitee/no-continue/application-invalid");
  }

  redirectToApplicationIsExpired(): void {
    this.redirectTo("/invitee/no-continue/application-expired");
  }

  redirectToApplicationIsAppLinkExpired(): void {
    this.redirectTo("/invitee/no-continue/application-app-link-expired");
  }

  redirectToNoPhotoId(): void {
    this.redirectTo("/invitee/no-continue/no-photo-id");
  }

  redirectToDupFailed(): void {
    this.redirectTo("/invitee/no-continue/dup-failed");
  }

  redirectToApplicantMaintenance(): void {
    this.redirectTo("/invitee/no-continue/applicant-maintenance");
  }

  redirectToOtpMaxSends(): void {
    this.redirectTo("/invitee/otp-max-sends");
  }

  redirectToOtpFailed(): void {
    this.redirectTo("/invitee/otp-failed");
  }

  redirectToOneTimePasscode(): void {
    this.redirectTo("/invitee/one-time-passcode");
  }

  redirectToDownloadApp(): void {
    this.redirectTo("/invitee/download-app");
  }

  handleErrorAuthRoutes(status: number): void {
    if (status === 401) {
      this.redirectToLoggedOut();
    } else if (status === 403) {
      this.redirectToNoAuth();
    } else if (status === 500) {
      this.redirectToInternalServerError();
    }
  }
}
