import { Component, OnInit, ViewChild } from "@angular/core";
import { RouterService } from "src/app/services/router.service";
import { ServiceManagerService } from "src/app/services/service-manager.service";
import { Title } from "@angular/platform-browser";
import { TextInputComponent } from "../../../common/form-fields/text-inputs/text-input.component";
import { ErrorSummaryMessage } from "../../../../models/error-summary-message";
@Component({
  selector: "app-ems-case-approval-retry",
  templateUrl: "./ems2-case-approval-retry.component.html",
})
export class Ems2CaseApprovalRetryComponent implements OnInit {
  breadcrumbRoute = "/service-management/portal";
  breadcrumbText = "Back";
  errorMessage: string;
  submitButtonClicked: boolean;
  retrySuccessMessage: string;
  invitationId: string = "";

  constructor(
    private serviceManagerService: ServiceManagerService,
    private routerService: RouterService,
    private title: Title,
  ) {}

  invitationIdErrorMessage = "";
  summaryErrorMessages = Array<ErrorSummaryMessage>();

  @ViewChild("invitationIdInput") invitationIdInput: TextInputComponent;

  ngOnInit(): void {
    this.title.setTitle("Xydus EMS2 case approval retry");
    this.retrySuccessMessage = "";
  }

  async onSubmitButtonClicked(): Promise<void> {
    this.submitButtonClicked = true;
    this.errorMessage = null;
    this.retrySuccessMessage = null;

    const invitationId = this.invitationId;
    if (invitationId.length === 0) {
      this.errorMessage = "Please enter an invitation id";
      this.submitButtonClicked = false;
      return;
    }

    try {
      await this.serviceManagerService.ems2CaseApprovalRetry(invitationId);
      this.invitationIdInput.value = "";
      this.invitationId = "";
      this.retrySuccessMessage =
        "Application successfully processed to ID review for : " + invitationId;
    } catch (err) {
      console.log(err);
      this.routerService.handleErrorAuthRoutes(err.status);

      if (err.status === 404) {
        this.errorMessage = "Invitation not found";
      } else if (err.status === 422) {
        this.errorMessage =
          "The request has expired, please reload the page and try again";
      } else if (err.status == 400) {
        this.errorMessage = err.error.detail;
      } else {
        this.errorMessage = "Error. Please contact the system administrator.";
      }
    } finally {
      this.submitButtonClicked = false;
    }
  }

  updateValue(value: string) {
    this.invitationId = value;
  }
}
