import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { RouterService } from "src/app/services/router.service";
import { ErrorSummaryMessage } from "../../../models/error-summary-message";
import { InvitationConfirmation } from "./../../../../store/states/invitee-spa.state";
import { TextInputComponent } from "../../common/form-fields/text-inputs/text-input.component";
import {
  isAddressLineOneValid,
  isPostcodeValid,
  isTownCityCountyValid,
} from "src/app/utils/data-validation/data-validation";
import { Title } from "@angular/platform-browser";
import { InviteeDetails } from "src/app/utils/invitee-details";

@Component({
  selector: "app-confirm-address",
  templateUrl: "./confirm-address.component.html",
})
export class ConfirmAddressComponent implements OnInit {
  breadcrumbRoute = "";
  breadcrumbText = "Back";
  type = "";
  headerText = "";

  invitationConfirmation: InvitationConfirmation;

  buildingNameValid: boolean;
  buildingNameErrorMessage = "";
  addressLineOneValid: boolean;
  addressLineOneErrorMessage = "";
  townCityValid: boolean;
  townCityErrorMessage = "";
  postcodeValid: boolean;
  postcodeErrorMessage = "";

  summaryErrorMessages = Array<ErrorSummaryMessage>();

  @ViewChild("buildingNameInput")
  buildingNameInput: TextInputComponent;
  @ViewChild("addressLineOneInput")
  addressLineOneInput: TextInputComponent;
  @ViewChild("townCityInput") townCityInput: TextInputComponent;
  @ViewChild("postcodeInput") postcodeInput: TextInputComponent;

  constructor(
    private route: ActivatedRoute,
    private routerService: RouterService,
    private title: Title,
    private inviteeDetails: InviteeDetails,
  ) {}

  async ngOnInit(): Promise<void> {
    this.type = this.route.snapshot.paramMap.get("type");
    this.breadcrumbRoute = "/invitee/confirm-postcode" + "/" + this.type;
    this.invitationConfirmation = this.inviteeDetails.getInviteeDetails();
    this.setHeader();
  }

  setHeader(): void {
    if (this.type == "delivery") {
      this.headerText = "Enter a delivery address";
      this.title.setTitle("Enter a delivery address");
    } else {
      this.headerText = "Enter your UK address";
      this.title.setTitle("Enter your UK address");
    }
  }

  submitAnswer(): void {
    if (this.isAnswerValid()) {
      if (this.type == "delivery") {
        this.invitationConfirmation.deliveryAddress = this.parseAddress();
        this.inviteeDetails.setInviteeDetails(this.invitationConfirmation);
        this.routerService.redirectToSaveSelection();
      } else {
        this.invitationConfirmation.tuAddress = this.parseAddress();
        this.inviteeDetails.setInviteeDetails(this.invitationConfirmation);
        this.routerService.redirectToCheckYourAddress();
      }
    }
  }

  parseBuildingNameAndAddressLineOne(lineOne, lineTwo) {
    if (lineTwo.length !== 0) {
      return lineOne + ", " + lineTwo;
    } else {
      return lineOne;
    }
  }

  parseAddress(): string {
    if (this.type == "tu") {
      return `${this.parseBuildingNameAndAddressLineOne(
        this.invitationConfirmation.tuBuildingName,
        this.invitationConfirmation.tuAddressLineOne,
      )}, ${this.invitationConfirmation.tuTownCity}, ${
        this.invitationConfirmation.tuPostcode
      }`;
    } else {
      return `${this.parseBuildingNameAndAddressLineOne(
        this.invitationConfirmation.deliveryBuildingName,
        this.invitationConfirmation.deliveryAddressLineOne,
      )}, ${this.invitationConfirmation.deliveryTownCity}, ${
        this.invitationConfirmation.deliveryPostcode
      }`;
    }
  }

  isAnswerValid(): boolean {
    this.getLatestAnswers();
    if (
      this.buildingNameValid &&
      this.addressLineOneValid &&
      this.postcodeValid &&
      this.townCityValid
    ) {
      return true;
    } else {
      return false;
    }
  }

  getLatestAnswers(): void {
    this.buildingNameInput.setQuestionAnswer();
    this.addressLineOneInput.setQuestionAnswer();
    this.postcodeInput.setQuestionAnswer();
    this.townCityInput.setQuestionAnswer();
  }

  readonly errorMessages = {
    buildingNameNumber: {
      empty: "Enter the building name or number",
      lengthTooLong: "The building name or number you've entered is too long.",
      unexpectedCharacters:
        "The Building name must consist of only numbers, letters, apostrophes, spaces and hyphens.",
      unknown: "Please check you've entered your Building name correctly.",
    },
    addressLineOne: {
      empty: "Enter the first line of your address.",
      lengthTooShort:
        "The first line of your address you've entered is too short.",
      lengthTooLong:
        "The first line of your address you've entered is too long.",
      unknown:
        "Please check you've entered the first line of your address correctly.",
      unexpectedCharacters:
        "The Building and street must consist of only numbers, letters, apostrophes, spaces and hyphens.",
    },
    townCity: {
      empty: "Enter your Town or City.",
      lengthTooShort: "The Town or City you've entered is too short.",
      lengthTooLong: "The Town or City you've entered is too long.",
      unknown: "Please check you've entered your Town or City correctly.",
      unexpectedCharacters:
        "The town and city must consist of only numbers, letters, apostrophes, spaces and hyphens.",
    },
    postcode: {
      empty: "Enter your postcode.",
      unknown: "Please check you've entered your postcode correctly.",
      invalid: "Please check you've entered your postcode correctly.",
      lengthTooLong: "The postcode you've entered is too long.",
      lengthTooShort: "The postcode you've entered is too short.",
      unexpectedCharacters:
        "Please check you've entered your postcode correctly.",
    },
  };

  updateAddressLineOneAnswer(addressLineOne: string): void {
    const validationResult = isAddressLineOneValid(addressLineOne.trim());

    if (validationResult.validity) {
      this.addressLineOneErrorMessage = "";
      this.addressLineOneValid = true;
    } else {
      this.addressLineOneErrorMessage =
        this.errorMessages.addressLineOne[validationResult.errorReason];
      this.addressLineOneValid = false;
    }
    this.updateAddressLineOneErrorMessage();

    if (this.type == "tu") {
      this.invitationConfirmation.tuAddressLineOne = addressLineOne;
    } else {
      this.invitationConfirmation.deliveryAddressLineOne = addressLineOne;
    }
  }

  updateAddressLineOneValid(addressLineOneValid: boolean): void {
    this.addressLineOneValid = addressLineOneValid;
    if (addressLineOneValid) {
      this.updateAddressLineOneErrorMessage();
    }
  }

  updateAddressLineOneErrorMessage(): void {
    const updatedErrorSummary = Array<ErrorSummaryMessage>();
    if (this.addressLineOneErrorMessage) {
      updatedErrorSummary.push({
        message: this.addressLineOneErrorMessage,
        id: "address-line-1",
      });
    }
    this.summaryErrorMessages = updatedErrorSummary;
  }

  updateBuildingNameAnswer(buildingName: string): void {
    const validationResult = isAddressLineOneValid(buildingName.trim(), true);
    if (validationResult.validity) {
      this.buildingNameErrorMessage = "";
      this.buildingNameValid = true;
    } else {
      this.buildingNameErrorMessage =
        this.errorMessages.buildingNameNumber[validationResult.errorReason] ??
        this.errorMessages.buildingNameNumber.unknown;
      this.buildingNameValid = false;
    }
    this.updateBuildingNameErrorMessage();

    if (this.type == "tu") {
      this.invitationConfirmation.tuBuildingName = buildingName;
    } else {
      this.invitationConfirmation.deliveryBuildingName = buildingName;
    }
  }

  updateBuildingNameValid(buildingNameValid: boolean): void {
    this.buildingNameValid = buildingNameValid;
    if (buildingNameValid) {
      this.updateBuildingNameErrorMessage();
    }
  }

  updateBuildingNameErrorMessage(): void {
    const updatedErrorSummary = Array<ErrorSummaryMessage>();
    if (this.buildingNameErrorMessage) {
      updatedErrorSummary.push({
        message: this.buildingNameErrorMessage,
        id: "building-name",
      });
    }
    this.summaryErrorMessages = updatedErrorSummary;
  }

  updateTownCityAnswer(townCity: string): void {
    const validationResult = isTownCityCountyValid(townCity.trim(), true);
    if (validationResult.validity) {
      this.townCityErrorMessage = "";
      this.townCityValid = true;
    } else {
      this.townCityErrorMessage =
        this.errorMessages.townCity[validationResult.errorReason];
      this.townCityValid = false;
    }

    this.updateTownCityErrorMessage();

    if (this.type == "tu") {
      this.invitationConfirmation.tuTownCity = townCity;
    } else {
      this.invitationConfirmation.deliveryTownCity = townCity;
    }
  }

  updateTownCityValid(townCityValid: boolean): void {
    this.townCityValid = townCityValid;
    if (townCityValid) {
      this.updateTownCityErrorMessage();
    }
  }

  updateTownCityErrorMessage(): void {
    const updatedErrorSummary = Array<ErrorSummaryMessage>();
    if (this.townCityErrorMessage) {
      updatedErrorSummary.push({
        message: this.townCityErrorMessage,
        id: "address-town",
      });
    }
    this.summaryErrorMessages = updatedErrorSummary;
  }

  updatePostcodeAnswer(postcode: string): void {
    postcode = postcode.trim().replace(/\s+/g, " ");
    const validationResult = isPostcodeValid(postcode);
    if (validationResult.validity) {
      this.postcodeErrorMessage = "";
      this.postcodeValid = true;
    } else {
      this.postcodeErrorMessage =
        this.errorMessages.postcode[validationResult.errorReason];

      this.postcodeValid = false;
    }

    this.updatePostcodeErrorMessage();

    if (this.type == "tu") {
      this.invitationConfirmation.tuPostcode = postcode;
    } else {
      this.invitationConfirmation.deliveryPostcode = postcode;
    }
  }

  updatePostcodeValid(postcodeValid: boolean): void {
    this.postcodeValid = postcodeValid;
    if (postcodeValid) {
      this.updatePostcodeErrorMessage();
    }
  }

  updatePostcodeErrorMessage(): void {
    const updatedErrorSummary = Array<ErrorSummaryMessage>();
    if (this.postcodeErrorMessage) {
      updatedErrorSummary.push({
        message: this.postcodeErrorMessage,
        id: "address-postcode",
      });
    }
    this.summaryErrorMessages = updatedErrorSummary;
  }
}
