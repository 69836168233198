/* istanbul ignore file */
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { BasicInfoComponent } from "./components/invitation/basic-info/basic-info.component";
import { FinalSummaryComponent } from "./components/invitation/final-summary/final-summary.component";
import { DashboardComponent } from "./components/invitation/dashboard/dashboard.component";
import { InvitationSuccessComponent } from "./components/invitation/invitation-success/invitation-success.component";
import { HelpComponent } from "./components/invitee/help/help.component";
import { TransunionComponent } from "./components/invitee/transunion/transunion.component";
import { PrivacyComponent } from "./components/privacy/privacy.component";
import { AuthReturnComponent } from "./components/auth-return/auth-return.component";
import { AuthenticateComponent } from "./components/authenticate/authenticate.component";
import { AuthGuardService } from "./guards/auth.guard";
import { AcceptInvitationComponent } from "./components/invitee/accept-invitation/accept-invitation.component";
import { ConfirmNameComponent } from "./components/invitee/confirm-name/confirm-name.component";
import { ConfirmDateOfBirthComponent } from "./components/invitee/confirm-date-of-birth/confirm-date-of-birth.component";
import { InviteDetailsComponent } from "./components/invitation/invite-details/invite-details.component";
import { InviteeStartComponent } from "./components/invitee/invitee-start/invitee-start.component";
import { OneTimePasscodeComponent } from "./components/invitee/one-time-passcode/one-time-passcode.component";
import { StartComponent } from "./components/start/start.component";
import { DownloadAppComponent } from "./components/invitee/download-app/download-app.component";
import { SendOtpComponent } from "./components/invitee/send-otp/send-otp.component";
import { ConfirmNiNumberComponent } from "./components/invitee/confirm-ni-number/confirm-ni-number.component";
import { CheckYourAnswersComponent } from "./components/invitee/check-your-answers/check-your-answers.component";
import { CheckYourAddressComponent } from "./components/invitee/check-your-address/check-your-address.component";
import { ReviewIdComponent } from "./components/id-checker/review-id/review-id.component";
import { CancelInviteComponent } from "./components/invitation/cancel-invite/cancel-invite.component";
import { CancelMultipleComponent } from "./components/invitation/cancel-multiple/cancel-multiple.component";
import { ConfirmPersonalEmailComponent } from "./components/invitation/confirm-personal-email/confirm-personal-email.component";
import { InviteCancelledComponent } from "./components/invitation/invite-cancelled/invite-cancelled.component";
import { IdStartComponent } from "./components/id-checker/start/id-start.component";
import { HoldingListComponent } from "./components/id-checker/holding-list/holding-list.component";
import { RejectedListComponent } from "./components/id-checker/rejected-list/rejected-list.component";
import { HoldApplicationComponent } from "./components/id-checker/hold-application/hold-application.component";
import { PassedListComponent } from "./components/id-checker/passed-list/passed-list.component";
import { RejectApplicationComponent } from "./components/id-checker/reject-application/reject-application.component";
import { ConfirmAddressComponent } from "./components/invitee/confirm-address/confirm-address.component";
import { ConfirmPostcodeComponent } from "./components/invitee/confirm-postcode/confirm-postcode.component";
import { SelectAddressComponent } from "./components/invitee/select-address/select-address.component";
import { ConfirmDetailsComponent } from "./components/id-checker/confirm-details/confirm-details.component";
import { PortalSelectComponent } from "./components/portal-select/portal-select.component";
import { ConfirmPreferredNameComponent } from "./components/invitee/confirm-preferred-name/confirm-preferred-name.component";
import { ReviewListComponent } from "./components/id-checker/review-list/review-list.component";
import { OtpLockedComponent } from "./components/errors/otp-locked/otp-locked.component";
import { OtpExpiredComponent } from "./components/errors/otp-expired/otp-expired.component";
import { OtpFailedComponent } from "./components/errors/otp-failed/otp-failed.component";
import { OtpMaxSendsComponent } from "./components/errors/otp-max-sends/otp-max-sends.component";
import { UploadFileComponent } from "./components/invitation/batch-invitation-upload/upload-file/upload-file.component";
import { InviteSentBulkComponent } from "./components/invitation/batch-invitation-upload/invite-sent-bulk/invite-sent-bulk.component";
import { FileContainsErrorsComponent } from "./components/invitation/batch-invitation-upload/file-contains-errors/file-contains-errors.component";
import { NotSentApplicationsComponent } from "./components/invitation/batch-invitation-upload/not-sent-applications/not-sent-applications.component";
import { PageNotFoundComponent } from "./components/errors/page-not-found/page-not-found.component";
import { DeviceNotSupportedComponent } from "./components/errors/device-not-supported/device-not-supported.component";
import { InternalServerErrorComponent } from "./components/errors/internal-server-error/internal-server-error.component";
import { InvalidRequestComponent } from "./components/errors/invalid-request/invalid-request.component";
import { TabDashboardComponent } from "./components/invitation/tab-dashboard/tab-dashboard.component";
import { NoContinueComponent } from "./components/invitee/no-continue/no-continue.component";
import { ProfilePhotoUploadComponent } from "./components/invitee/profile-photo-upload/profile-photo-upload.component";
import { ProfilePhotoReviewComponent } from "./components/invitee/profile-photo-review/profile-photo-review.component";
import { Step1Component } from "./components/invitee/step1/step1.component";
import { PoaComponent } from "./components/invitee/poa/poa.component";
import { PassportComponent } from "./components/invitee/passport/passport.component";
import { DeliveryComponent } from "./components/invitee/delivery/delivery.component";
import { SaveSelectionComponent } from "./components/invitee/save-selection/save-selection.component";
import { ServiceManagerPortalComponent } from "./components/service-management/service-manager-portal/service-manager-portal.component";
import { ViewPayloadComponent } from "./components/service-management/view-payload/view-payload.component";
import { UpdateInternalNoteComponent } from "./components/id-checker/update-internal-note/update-internal-note.component";
import { CreateInternalNoteComponent } from "./components/id-checker/create-internal-note/create-internal-note.component";
import { SettingsComponent } from "./components/user-settings/settings/settings.component";
import { DeleteInvitationComponent } from "./components/service-management/delete-invitation/delete-invitation.component";
import { GlobalHelpComponent } from "./components/global-help/global-help.component";
import { CookiesPreferenceComponent } from "./components/cookies/cookies-preference/cookies-preference.component";
import { CookiesSavedComponent } from "./components/cookies/cookies-saved/cookies-saved.component";
import { BeforeYouStartComponent } from "./components/invitation/before-you-start/before-you-start.component";
import { VerifyEmailComponent } from "./components/user-settings/verify-email/verify-email.component";
import { UpdateEmailComponent } from "./components/user-settings/update-email/update-email.component";
import { PhotoIdOneComponent } from "./components/invitee/photo-id-one/photo-id-one.component";
import { PhotoIdTwoComponent } from "./components/invitee/photo-id-two/photo-id-two.component";
import { Ems2CaseApprovalRetryComponent } from "./components/service-management/ems2/case-approval-retry/ems2-case-approval-retry.component";
import { Ems2CaseEventChangeComponent } from "./components/service-management/ems2/case-event-change/case-event-change.component";
import { SmsDownloadLinkResendComponent } from "./components/service-management/sms-download-link-resend/sms-download-link-resend.component";
import { SearchComponent } from "./components/id-checker/search/search.component";

const routes: Routes = [
  { path: "", redirectTo: "/invitation/dashboard", pathMatch: "full" },
  { path: "start", component: StartComponent },
  { path: "device-not-supported", component: DeviceNotSupportedComponent },
  { path: "internal-server-error", component: InternalServerErrorComponent },
  { path: "settings", component: SettingsComponent },
  { path: "invitation/before-you-start", component: BeforeYouStartComponent },
  {
    path: "settings/verify-email/:category",
    component: VerifyEmailComponent,
  },
  {
    path: "settings/update-email/:category",
    component: UpdateEmailComponent,
  },
  { path: "auth-return", component: AuthReturnComponent },
  { path: "authenticate", component: AuthenticateComponent },
  { path: "authenticate/logged-out", component: AuthenticateComponent },
  { path: "authenticate/user-logged-out", component: AuthenticateComponent },
  { path: "authenticate/no-auth", component: AuthenticateComponent },
  { path: "authenticate/unknown", component: AuthenticateComponent },
  {
    path: "authenticate/service-unavailable",
    component: AuthenticateComponent,
  },
  {
    path: "portal-select",
    canActivate: [AuthGuardService],
    component: PortalSelectComponent,
  },

  {
    path: "id-checker",
    children: [
      { path: "start", component: IdStartComponent },
      { path: "holding-list", component: HoldingListComponent },
      {
        path: "confirm-details/:invitationId",
        component: ConfirmDetailsComponent,
      },
      { path: "rejected-list", component: RejectedListComponent },
      { path: "review-list", component: ReviewListComponent },
      {
        path: "edit-note/:invitationId",
        component: UpdateInternalNoteComponent,
      },
      {
        path: "create-note/:invitationId",
        component: CreateInternalNoteComponent,
      },
      { path: "review-list", component: ReviewListComponent },
      { path: "passed-list", component: PassedListComponent },
      {
        path: "review-id/:invitationId",
        component: ReviewIdComponent,
      },
      {
        path: "reject-application/:invitationId",
        component: RejectApplicationComponent,
      },
      {
        path: "hold-application/:invitationId",
        component: HoldApplicationComponent,
      },
      { path: "search", component: SearchComponent },
    ],
  },
  {
    path: "invitation",
    canActivate: [AuthGuardService],
    children: [
      { path: "", redirectTo: "dashboard", pathMatch: "full" },
      { path: "dashboard", component: TabDashboardComponent },
      { path: "all-invitations", component: DashboardComponent },
      {
        path: "confirm-personal-email",
        component: ConfirmPersonalEmailComponent,
      },
      {
        path: "confirm-personal-email/:invitationId",
        component: ConfirmPersonalEmailComponent,
      },
      { path: "basic-info", component: BasicInfoComponent },
      {
        path: "basic-info/:invitationId",
        component: BasicInfoComponent,
      },
      {
        path: "invite-details/:invitationId",
        component: InviteDetailsComponent,
      },
      { path: "final-summary/:invitationId", component: FinalSummaryComponent },
      {
        path: "cancel-invite/:invitationId",
        component: CancelInviteComponent,
      },
      {
        path: "cancel-multiple",
        component: CancelMultipleComponent,
      },
      {
        path: "invite-cancelled/:invitationId",
        component: InviteCancelledComponent,
      },
      { path: "final-summary", component: FinalSummaryComponent },
      { path: "success/:invitationId", component: InvitationSuccessComponent },
      { path: "upload-file", component: UploadFileComponent },
      { path: "invite-sent-bulk", component: InviteSentBulkComponent },
      { path: "file-contains-errors", component: FileContainsErrorsComponent },
      {
        path: "not-sent-applications",
        component: NotSentApplicationsComponent,
      },
    ],
  },
  {
    path: "invitee",
    children: [
      { path: "", redirectTo: "accept", pathMatch: "full" },
      { path: "accept", component: AcceptInvitationComponent },
      { path: "send-otp", component: SendOtpComponent },
      { path: "one-time-passcode", component: OneTimePasscodeComponent },
      { path: "start", component: InviteeStartComponent },
      { path: "save-selection", component: SaveSelectionComponent },
      { path: "poa", component: PoaComponent },
      { path: "passport", component: PassportComponent },
      { path: "photo-id-one", component: PhotoIdOneComponent },
      { path: "photo-id-two", component: PhotoIdTwoComponent },
      { path: "no-continue/:reason", component: NoContinueComponent },
      { path: "step1", component: Step1Component },
      {
        path: "profile-photo-upload",
        component: ProfilePhotoUploadComponent,
      },
      {
        path: "profile-photo-review",
        component: ProfilePhotoReviewComponent,
      },
      { path: "confirm-name", component: ConfirmNameComponent },
      {
        path: "confirm-preferred-name",
        component: ConfirmPreferredNameComponent,
      },
      { path: "confirm-dob", component: ConfirmDateOfBirthComponent },
      {
        path: "delivery",
        component: DeliveryComponent,
      },
      { path: "confirm-postcode/:type", component: ConfirmPostcodeComponent },
      { path: "select-address/:type", component: SelectAddressComponent },
      { path: "confirm-address/:type", component: ConfirmAddressComponent },
      { path: "confirm-ni-number", component: ConfirmNiNumberComponent },
      { path: "check-your-answers", component: CheckYourAnswersComponent },
      { path: "check-your-address", component: CheckYourAddressComponent },
      { path: "download-app", component: DownloadAppComponent },
      { path: "otp-locked", component: OtpLockedComponent },
      { path: "otp-expired", component: OtpExpiredComponent },
      { path: "otp-failed", component: OtpFailedComponent },
      { path: "otp-max-sends", component: OtpMaxSendsComponent },
      { path: "invalid-request", component: InvalidRequestComponent },
      { path: "help", component: HelpComponent },
      { path: "transunion", component: TransunionComponent },
    ],
  },
  {
    path: "service-management",
    children: [
      { path: "view-payload", component: ViewPayloadComponent },
      { path: "delete-invitation", component: DeleteInvitationComponent },
      {
        path: "ems2-case-approval-retry",
        component: Ems2CaseApprovalRetryComponent,
      },
      {
        path: "ems2-case-event-change",
        component: Ems2CaseEventChangeComponent,
      },
      {
        path: "sms-download-link-resend",
        component: SmsDownloadLinkResendComponent,
      },
      {
        path: "portal",
        canActivate: [AuthGuardService],
        component: ServiceManagerPortalComponent,
      },
    ],
  },
  { path: "help", component: GlobalHelpComponent },
  { path: "cookies-preference", component: CookiesPreferenceComponent },
  { path: "cookies-saved", component: CookiesSavedComponent },
  { path: "privacy", component: PrivacyComponent },
  { path: "**", pathMatch: "full", component: PageNotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "enabled",
      anchorScrolling: "enabled",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
