<div class="nhsuk-grid-row">
  <div *ngIf="invitationConfirmation" class="nhsuk-grid-column-two-thirds">
    <breadcrumb-link
      [breadcrumbRoute]="breadcrumbRoute"
      [breadcrumbText]="breadcrumbText"
    ></breadcrumb-link>
    <error-summary
      [errorSummaryMessages]="summaryErrorMessages"
    ></error-summary>

    <div class="form-group">
      <h1 autofocus tabindex="-1" class="nhsuk-label-wrapper">
        <label class="nhsuk-label nhsuk-label--xl" for="address-postcode">
          {{ pageHeader }}
        </label>
      </h1>

      <div class="nhsuk-hint" *ngIf="displayHint">{{ displayHint }}</div>
      <div class="nhsuk-hint" *ngIf="displayNotes">
        <p>{{ displayNotes }}</p>
      </div>

      <div *ngIf="displayStudentNote">
        <details class="nhsuk-details">
          <summary class="nhsuk-details__summary">
            <span class="nhsuk-details__summary-text">
              I am a student living away from home, have more than 1 address or
              have recently moved
            </span>
          </summary>
          <div class="nhsuk-details__text">
            <p>
              Enter the address with the most connections to your name. For
              example:
            </p>
            <ul>
              <li>banks</li>
              <li>utility providers</li>
              <li>electoral register</li>
            </ul>
          </div>
        </details>
      </div>

      <label *ngIf="type == 'delivery'" class="nhsuk-label"> Postcode </label>
      <text-input
        #postcodeInput
        [id]="'address-postcode'"
        [errorMessage]="postcodeErrorMessage"
        [value]="invitationConfirmation.tuPostcode"
        [uppercase]="false"
        (questionAnswer)="updatePostcodeAnswer($event)"
        size="nhsuk-input--width-10"
        cypressValue="postcode"
      ></text-input>
    </div>

    <button
      class="nhsuk-button nhsuk-u-margin-bottom-5"
      (click)="submitAnswer()"
      id="continue"
      data-cy="find-address-button"
    >
      Find address
    </button>
  </div>
</div>
