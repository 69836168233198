<div class="nhsuk-grid-row">
  <div class="nhsuk-grid-column-two-thirds">
    <h1 autofocus tabindex="-1">Too many security code requests</h1>

    <p>
      A security code has been sent to ******<strong>{{ mobile }}</strong> too
      many times.
    </p>

    <p>
      A new email with a link to continue your application will be sent to you
      shortly.
    </p>

    <p>
      If you do not receive an email contact
      <a href="mailto:{{ supportEmail }}">{{ supportEmail }}</a
      >. Include your application ID and a detailed description of the problem.
      This will help us to support you.
    </p>

    <details class="nhsuk-details">
      <summary class="nhsuk-details__summary">
        <span class="nhsuk-details__summary-text">
          Mobile number is incorrect
        </span>
      </summary>
      <div class="nhsuk-details__text">
        <p>
          Contact <a href="mailto:{{ supportEmail }}">{{ supportEmail }}</a> to
          get your mobile number updated. Include your application ID in all
          emails.
        </p>
      </div>
    </details>
  </div>
</div>
