import { Component, Inject, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { filter } from "rxjs/operators";
import { SendAuthcodeService } from "../../services/send-authcode.service";
import { RouterService } from "../../services/router.service";
import { CryptoService } from "../../services/security/crypto.service";
import { SESSION_STORAGE, StorageService } from "ngx-webstorage-service";
import { StorageMap } from "@ngx-pwa/local-storage";
import { Portal } from "src/app/models/session-token";
import { UserSettingsService } from "src/app/services/user-settings.service";
import { UserSettings } from "src/app/models/userSettings";
import { SiteService } from "src/app/services/site.service";

@Component({
  selector: "app-auth-redirect",
  templateUrl: "./auth-return.component.html",
})
export class AuthReturnComponent implements OnInit {
  authCode: string;
  returnedState: string;
  stateError: string;
  settings: UserSettings;

  constructor(
    private route: ActivatedRoute,
    private sendAuthcodeService: SendAuthcodeService,
    private routerService: RouterService,
    private cryptoService: CryptoService,
    @Inject(SESSION_STORAGE) private sessionStorage: StorageService,
    private localStorage: StorageMap,
    private userSettings: UserSettingsService,
    private siteService: SiteService,
  ) {}

  ngOnInit(): void {
    this.route.queryParams
      .pipe(filter((params) => params.hasOwnProperty("code")))
      .subscribe((params) => {
        this.authCode = params.code;
        this.returnedState = params.state;
      });

    this.validateState();
  }

  validateState(): void {
    this.localStorage.get("state").subscribe({
      next: (localState) => this.handleStateChecking(localState),
      error: () => this.onErrorResponse(),
    });
  }

  async sendAuthCode(): Promise<any> {
    if (!this.authCode) {
      this.routerService.redirectToAuthenticate();
    }

    try {
      let changeRoleSessionId = undefined;
      if (this.sessionStorage.get("sessionToken") !== undefined) {
        changeRoleSessionId =
          this.sessionStorage.get("sessionToken").session_id;
      }

      const preauthId = this.sessionStorage.get("preauthId");
      const changeRoleUuid = this.sessionStorage.get("changeRoleUuid");
      const result = await this.sendAuthcodeService.postAuthcode(
        this.authCode,
        this.returnedState,
        preauthId,
        changeRoleUuid,
        changeRoleSessionId,
      );

      this.persistSessionToken(result);
      this.getCimEnv();

      if (changeRoleUuid !== undefined) {
        this.sessionStorage.set("changedRoleAlert", true);
      }

      await this.getSettings(result["sessionToken"]["portal"]).then();
    } catch (error) {
      switch (error.status) {
        case 403:
          this.routerService.redirectToNoAuth();
          break;
        case 401:
          this.routerService.redirectToLoggedOut();
          break;
        case 503:
          this.routerService.redirectToServiceUnavailable();
          break;
        default:
          this.routerService.redirectToUnknown();
          break;
      }
    } finally {
      this.clearChangeRoleToken();
    }
  }

  async getSettings(portal: string) {
    const response = await this.userSettings.getSettings();
    this.settings = this.userSettings.mapUserSettings(response["body"]);
    if (
      this.settings.contactEmailVerified &&
      this.settings.supportEmailVerified
    ) {
      this.sessionStorage.set("contactEmail", this.settings.contactEmail);
    }

    if (portal === Portal.Checker.toString()) {
      this.routerService.redirectToPortalSelect();
    } else {
      this.routerService.redirectToInvitationDashboard();
    }
  }

  getCimEnv(): void {
    this.siteService.getCimUrl().subscribe({
      next: (response) => {
        this.sessionStorage.set("cimEnv", response["url"]);
      },
      error: () => {
        // uuids will simply display as text
      },
    });
  }

  handleStateChecking(localState): void {
    this.cryptoService.hash256(this.returnedState).then((hex) => {
      if (localState === hex) {
        this.sendAuthCode();
      } else {
        this.routerService.redirectToAuthenticate();
      }
    });
  }

  persistSessionToken(result): void {
    this.sessionStorage.set("sessionToken", result.sessionToken);
  }

  clearChangeRoleToken(): void {
    this.sessionStorage.remove("changeRoleUuid");
  }

  onErrorResponse(): void {
    this.stateError = "An error has occurred, please try again later";
  }
}
