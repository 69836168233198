<div
  [ngClass]="{
    'nhsuk-form-group--error': errorMessage
  }"
  class="nhsuk-form-group"
>
  <label class="nhsuk-label" for="{{ questionIdentifier }}">{{
    question
  }}</label>
  <span
    *ngIf="errorMessage"
    id="{{ questionIdentifier }}-error-description"
    attr.data-cy="{{ questionIdentifier }}-error-description"
    class="nhsuk-error-message"
    >{{ errorMessage }}</span
  >
  <div>
    <select
      [ngClass]="size"
      [(ngModel)]="selectedAnswer"
      class="nhsuk-select nhsuk-u-width-three-quarters"
      id="{{ questionIdentifier }}"
      (change)="setAnswer()"
      attr.data-cy="{{ questionIdentifier }}"
      [attr.aria-describedby]="
        errorMessage ? questionIdentifier + '-error-description' : null
      "
    >
      <option *ngFor="let answer of answers" [ngValue]="answer.value">
        {{ answer.label }}
      </option>
    </select>
  </div>
</div>
