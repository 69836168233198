<div
  [ngClass]="{
    'nhsuk-form-group--error': errorMessage
  }"
  class="nhsuk-form-group"
>
  <label *ngIf="label" class="nhsuk-label" [for]="id">{{ label }}</label>
  <span
    *ngIf="errorMessage"
    class="nhsuk-error-message"
    id="{{ id }}-error-description"
    [attr.data-cy]="errorMessageDataCy"
    >{{ errorMessage }}</span
  >
  <div *ngIf="hint" class="nhsuk-hint">
    {{ hint }}
  </div>
  <div
    *ngIf="hintHtml"
    [innerHTML]="hintHtml | sanitizeHtml"
    class="nhsuk-hint"
  ></div>
  <input
    class="nhsuk-input nhsuk-u-width-three-quarters"
    [type]="inputType"
    [attr.inputmode]="inputMode"
    [autocomplete]="autocomplete"
    [id]="id"
    [disabled]="disabled"
    [name]="id"
    [ngClass]="size"
    [value]="value"
    (change)="updateValue($event.target.value)"
    [attr.data-cy]="cypressValue"
    [attr.aria-describedby]="errorMessage ? id + '-error-description' : null"
  />
  <div
    *ngIf="characterCount || characterCount == 0"
    class="nhsuk-hint character-count"
    [ngClass]="{ 'character-count-over': characterCount < 0 }"
  >
    You have {{ characterCount == 0 ? characterCount : characterCountAbsolute }}
    {{ characterCountAbsolute == 1 ? "character" : "characters" }}
    {{ characterCount >= 0 ? "remaining" : "too many" }}
  </div>
</div>
