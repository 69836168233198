<div
  class="nhsuk-form-group"
  [ngClass]="{ 'nhsuk-form-group--error': errorMessage }"
>
  <div class="nhsuk-checkboxes">
    <span
      role="alert"
      *ngIf="errorMessage"
      class="nhsuk-error-message"
      [attr.data-cy]="errorMessageDataCy"
      >{{ errorMessage }}</span
    >
    <div class="nhsuk-checkboxes__item">
      <input
        class="nhsuk-checkboxes__input"
        id="check-{{ id }}"
        [name]="name"
        type="checkbox"
        [checked]="value == true"
        (click)="updateValue()"
        [attr.data-cy]="cypressValue"
        [attr.data-checkbox-exclusive-group]="exlusiveGroupName"
        [attr.data-checkbox-exclusive]="exclusive ? '' : null"
      />
      <label class="nhsuk-label nhsuk-checkboxes__label" for="check-{{ id }}">
        {{ checkboxText }}
      </label>
      <div class="nhsuk-hint nhsuk-checkboxes__hint">
        {{ hintText }}
      </div>
    </div>
  </div>
</div>
