<session-check></session-check>
<div *ngIf="invitation">
  <a
    [state]="invitation"
    [routerLink]="['/invitation/invite-details', invitationId]"
    class="nhsuk-back-link__link nhsuk-u-margin-bottom-6"
  >
    <svg
      class="nhsuk-icon nhsuk-icon__chevron-left"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      aria-hidden="true"
      height="24"
      width="24"
    >
      <path
        d="M8.5 12c0-.3.1-.5.3-.7l5-5c.4-.4 1-.4 1.4 0s.4 1 0 1.4L10.9 12l4.3 4.3c.4.4.4 1 0 1.4s-1 .4-1.4 0l-5-5c-.2-.2-.3-.4-.3-.7z"
      ></path>
    </svg>
    Back to application
  </a>
  <div class="nhsuk-grid-row">
    <error-summary
      [errorSummaryMessages]="summaryErrorMessages"
    ></error-summary>
    <div class="nhsuk-grid-column-two-thirds">
      <h1 autofocus tabindex="-1">
        Cancel application for {{ invitation.givenName }}
        {{ invitation.familyName }}
      </h1>
      <error-summary
        id="error-summary-messages"
        [errorSummaryMessages]="summaryErrorMessages"
      ></error-summary>
      <p>Applicant will no longer be able to continue their application.</p>

      <div class="nhsuk-form-group nhsuk-u-margin-top-5">
        <select-dropdown
          #reasonInput
          questionIdentifier="reason-select"
          question="Select a reason for cancelling this application"
          [errorMessage]="dropdownErrorMessage"
          [answers]="reasonList"
          (questionAnswer)="updateReasonAnswer($event)"
        ></select-dropdown>
      </div>
      <div
        class="nhsuk-form-group nhsuk-u-margin-top-5"
        *ngIf="selectedReasonId == 3"
      >
        <text-input
          id="other-reason-notes"
          [label]="'Please specify'"
          [errorMessage]="textErrorMessage"
          (questionAnswer)="otherReason = $event"
        ></text-input>
      </div>
      <button
        id="cancel-button"
        data-cy="cancel-button"
        class="nhsuk-button nhsuk-button--secondary delete-button"
        type="submit"
        [ngClass]="{ 'spinner-button': submitButtonClicked }"
        [disabled]="submitButtonClicked"
        (click)="onCancelInvitationButtonClicked()"
      >
        Cancel application
      </button>
    </div>
  </div>
</div>
