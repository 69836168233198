import { Component, Inject, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { SESSION_STORAGE, StorageService } from "ngx-webstorage-service";
import { RouterService } from "src/app/services/router.service";

@Component({
  selector: "app-invitee-start",
  templateUrl: "./invitee-start.component.html",
})
export class InviteeStartComponent implements OnInit {
  expiry = undefined;

  constructor(
    private title: Title,
    @Inject(SESSION_STORAGE) private sessionStorage: StorageService,
    private routerService: RouterService,
  ) {}

  ngOnInit(): void {
    this.title.setTitle("Apply for an NHS Care ID");
    this.checkSessionDetailsExist();
  }

  checkSessionDetailsExist(): void {
    this.expiry = this.sessionStorage.get("expiry");
    const invitationId = this.sessionStorage.get("invitationId");

    if (this.expiry == undefined || invitationId == undefined) {
      this.routerService.redirectToInvalidRequest();
    }
  }
}
