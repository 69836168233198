<session-check></session-check>
<modified-record-check #modifiedRecordCheck></modified-record-check>
<section *ngIf="!invitation">
  <p>
    <progress-indicator
      spinnerText="Loading on hold application"
    ></progress-indicator>
  </p>
</section>
<div *ngIf="invitation">
  <div *ngIf="invitation.invitationId">
    <a
      [routerLink]="['/id-checker/review-id', invitation.invitationId]"
      class="nhsuk-back-link__link nhsuk-u-margin-bottom-6"
    >
      <svg
        class="nhsuk-icon nhsuk-icon__chevron-left"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        aria-hidden="true"
        height="24"
        width="24"
      >
        <path
          d="M8.5 12c0-.3.1-.5.3-.7l5-5c.4-.4 1-.4 1.4 0s.4 1 0 1.4L10.9 12l4.3 4.3c.4.4.4 1 0 1.4s-1 .4-1.4 0l-5-5c-.2-.2-.3-.4-.3-.7z"
        ></path>
      </svg>
      Back
    </a>
  </div>
  <div class="nhsuk-grid-row">
    <div class="nhsuk-grid-column-two-thirds">
      <div id="top"></div>
      <div *ngIf="holdReasonProvided == false">
        <div
          class="nhsuk-error-summary"
          aria-labelledby="error-summary-title"
          role="alert"
          tabindex="-1"
        >
          <h2 class="nhsuk-error-summary__title" id="error-summary-title">
            There is a problem
          </h2>
          <div class="nhsuk-error-summary__body">
            <p>
              Please select a reason for moving this application to on hold.
            </p>
          </div>
        </div>
      </div>

      <form class="form">
        <fieldset class="nhsuk-fieldset nhsuk-u-margin-bottom-2">
          <legend class="nhsuk-fieldset__legend nhsuk-fieldset__legend--l">
            <h1 autofocus tabindex="-1" class="nhsuk-fieldset__heading">
              Put application on hold
            </h1>
          </legend>

          <div *ngIf="idCheckFailures">
            <p>
              This application is being put on hold for the following reasons
            </p>
            <dl
              *ngFor="let failure of idCheckFailures"
              class="nhsuk-summary-list"
            >
              <div class="nhsuk-summary-list__row">
                <dt class="nhsuk-summary-list__key">
                  {{ failure.document }}
                </dt>
                <dd class="nhsuk-summary-list__value">
                  <p *ngFor="let reason of failure.reasons">
                    {{ reason | idFailureCheckReasonPipe }}
                  </p>
                </dd>
              </div>
            </dl>
          </div>
          <div
            [ngClass]="{
              'nhsuk-form-group--error': holdReasonProvided == false
            }"
          >
            <div class="nhsuk-checkboxes nhsuk-checkboxes--conditional">
              <checkbox-input
                #systemIssueInput
                cypressValue="system-issue"
                id="system-issue"
                checkboxText="System issue"
              >
              </checkbox-input>
            </div>
            <div class="nhsuk-form-group">
              <label class="nhsuk-label" for="other"> Notes (optional) </label>
              <text-area-input
                #holdReasonNotesInput
                cypressValue="hold-reason-notes"
                id="hold-reason-notes"
                [value]="holdReasonNotes"
                aria-describedby="hold notes"
                [errorMessage]="holdNotesErrorMessage"
                (questionAnswer)="updateHoldNotes($event)"
              >
              </text-area-input>
            </div>
          </div>
        </fieldset>

        <button
          data-cy="submit-hold"
          (click)="submitAnswers()"
          [ngClass]="{ 'spinner-button': submitting }"
          [disabled]="submitting"
          class="nhsuk-button nhsuk-button--secondary nhsuk-u-margin-top-5"
          type="submit"
        >
          Put on hold
        </button>

        <span
          class="nhsuk-error-message"
          data-cy="submit-error-message"
          *ngIf="submitError"
          >{{ submitError }}</span
        >
      </form>
    </div>
  </div>
</div>
