import { Component, Inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { RouterService } from "src/app/services/router.service";
import { Subscription } from "rxjs";
import { SendInvitationService } from "./../../../services/send-invitation.service";
import { Invitation } from "./../../../../store/states/spa.state";
import { StorageMap } from "@ngx-pwa/local-storage";
import { isNotesValid } from "src/app/utils/data-validation/data-validation";
import { SESSION_STORAGE, StorageService } from "ngx-webstorage-service";
import { TextAreaInputComponent } from "../../common/form-fields/text-inputs/text-area-input/text-area-input.component";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "app-final-summary",
  templateUrl: "./final-summary.component.html",
})
export class FinalSummaryComponent implements OnInit, OnDestroy {
  invitationSubscription: Subscription;
  invitation: Invitation;

  detailsError: string;
  submitButtonClicked = false;
  existingInvitationId: string;
  validationErrors = [];
  paaErrorMessage = "";
  paaValid: boolean;
  contactEmailValid: boolean = true;

  @ViewChild("paaInput") paaInput: TextAreaInputComponent;

  readonly errorMessages = {
    paa: {
      lengthTooLong: "Notes must be 300 characters or fewer",
      unexpectedCharacters:
        "What applicant needs must consist of only letters, numbers and punctuation.",
      unknown:
        "An unknown error occurred, please check the content and try again",
    },
  };

  constructor(
    private routerService: RouterService,
    private invitationService: SendInvitationService,
    private storage: StorageMap,
    private route: ActivatedRoute,
    private title: Title,
    @Inject(SESSION_STORAGE) private sessionStorage: StorageService,
  ) {}

  ngOnInit(): void {
    this.title.setTitle("Check applicant details");
    this.checkForContactEmail();
    this.existingInvitationId =
      this.route.snapshot.paramMap.get("invitationId");
    this.getInvitationFromStorage();
  }

  checkForContactEmail(): void {
    const contactEmail = this.sessionStorage.get("contactEmail");
    if (contactEmail == undefined) {
      this.contactEmailValid = false;
      this.detailsError = "Please set up a contact email address to proceed";
    }
  }

  ngOnDestroy(): void {
    if (this.invitationSubscription === undefined) {
      return;
    }
    this.invitationSubscription.unsubscribe();
  }

  async onSubmitButtonClicked(): Promise<void> {
    this.resetErrorState();
    this.submitButtonClicked = true;
    this.invitation.senderEmail = this.sessionStorage
      .get("contactEmail")
      .toLowerCase();

    if (!this.areAnswersValid()) {
      this.submitButtonClicked = false;
      return;
    }

    const validDetails = this.validateDetails();
    if (!validDetails) {
      this.submitButtonClicked = false;
      return;
    }

    if (this.isSenderEmailSameAsApplicant()) {
      this.detailsError =
        "Applicant email address cannot be the same as your contact email address";
      this.submitButtonClicked = false;
      return;
    }

    try {
      if (this.invitation.invitationId.length != 0) {
        await this.invitationService.putInvitation(
          this.invitation.givenName,
          this.invitation.familyName,
          this.invitation.email,
          this.invitation.mobile,
          this.invitation.isPersonalEmail,
          this.invitation.createdByUserName,
          this.invitation.senderEmail,
          this.invitation.paa,
          this.invitation.invitationId,
          this.invitation.resentCount,
          this.invitation.captureDeliveryAddress,
        );
      } else {
        let response = await this.invitationService.postInvitation(
          this.invitation.givenName,
          this.invitation.familyName,
          this.invitation.email,
          this.invitation.mobile,
          this.invitation.isPersonalEmail,
          this.invitation.createdByUserName,
          this.invitation.senderEmail,
          this.invitation.paa,
          this.invitation.captureDeliveryAddress,
        );
        this.invitation.invitationId = response.body;
      }
      this.storage.delete("invitation").subscribe(() => {});
      this.routerService.redirectToInvitationSuccess(
        this.invitation.invitationId,
      );
    } catch (exc) {
      if (exc.status == 400) {
        if (exc.error != undefined && exc.error.errors != undefined) {
          // dto validation
          this.validationErrors = exc.error.errors;
        } else {
          // other server side validation
          this.detailsError = exc.error;
        }
      } else if (exc.status === 401) {
        this.routerService.redirectToLoggedOut();
      } else {
        this.detailsError = "An error was encountered please retry the journey";
      }
      this.submitButtonClicked = false;
    }
  }

  getLatestAnswers(): void {
    this.paaInput.setQuestionAnswer();
  }

  areAnswersValid(): boolean {
    this.getLatestAnswers();
    return this.paaValid;
  }

  getInvitationFromStorage(): void {
    this.storage.has("invitation").subscribe((invitationExists) => {
      if (invitationExists) {
        this.storage.get("invitation").subscribe((invitation) => {
          this.invitation = invitation as Invitation;
        });
      } else {
        this.routerService.redirectToInvitationDashboard();
      }
    });
  }

  validateDetails(): boolean {
    if (
      !(
        this.invitation.givenName &&
        this.invitation.familyName &&
        this.invitation.mobile &&
        this.invitation.email
      )
    ) {
      this.detailsError =
        "Not all details have been supplied. Retry the journey.";
      return false;
    }
    return true;
  }

  isSenderEmailSameAsApplicant(): boolean {
    return this.invitation.email.toLowerCase() == this.getContactEmailAddress();
  }

  getContactEmailAddress(): string {
    return this.sessionStorage.get("contactEmail").toLowerCase();
  }

  resetErrorState(): void {
    this.detailsError = null;
    this.validationErrors = [];
  }

  updatePaaAnswer(paa: string): void {
    const validationResult = isNotesValid(paa);
    if (validationResult.validity) {
      this.paaErrorMessage = "";
      this.paaValid = true;
    } else {
      this.paaErrorMessage =
        this.errorMessages.paa[validationResult.errorReason];
      this.paaValid = false;
    }
    if (paa != undefined) {
      this.invitation.paa = paa;
    }
  }
}
