import { Component, Input } from "@angular/core";

@Component({
  selector: "breadcrumb-link",
  templateUrl: "./breadcrumb-link.component.html",
})
export class BreadcrumbLinkComponent {
  @Input() breadcrumbRoute: string;
  @Input() breadcrumbText: string;
}
