import { Input, Component } from "@angular/core";
import { ErrorSummaryMessage } from "../../../models/error-summary-message";

@Component({
  selector: "error-summary",
  templateUrl: "./error-summary.component.html",
})
export class ErrorSummaryComponent {
  errorMessages = [];

  @Input() set errorSummaryMessages(errors: ErrorSummaryMessage[]) {
    this.errorMessages = errors;
  }
}
