import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Title } from "@angular/platform-browser";
import { RouterService } from "src/app/services/router.service";
import { InviteeDetails } from "src/app/utils/invitee-details";
import { InvitationConfirmation } from "src/store/states/invitee-spa.state";
import { scrollToTop } from "src/app/utils/common/common";

@Component({
  selector: "app-profile-photo-upload",
  templateUrl: "./profile-photo-upload.component.html",
})
export class ProfilePhotoUploadComponent implements OnInit {
  breadcrumbRoute = "";
  breadcrumbText = "Back";
  invitationConfirmation: InvitationConfirmation;
  errorMessage: string;
  minFileSize = 100000;
  maxFileSize = 3499999;
  resizeFactor = 0.25;

  loading = false;
  imageURL: string;
  uploadForm: FormGroup;
  imageUuid: string;
  failMessage: string;

  constructor(
    private title: Title,
    private routerService: RouterService,
    public fb: FormBuilder,
    private inviteeDetails: InviteeDetails,
  ) {
    // Reactive Form
    this.uploadForm = this.fb.group({
      avatar: [null],
      name: [""],
    });
  }

  ngOnInit(): void {
    this.title.setTitle("Upload a professional photo for your smartcard");

    this.invitationConfirmation = this.inviteeDetails.getInviteeDetails();
    this.setLinks();
  }

  validFileExtension(file: File): boolean {
    const allowedExtensions = ["jpg", "jpeg", "png"];
    const fileExtension = file.name.split(".").pop().toLowerCase();
    return allowedExtensions.includes(fileExtension);
  }

  onFileSelect(event) {
    this.errorMessage = "";
    this.loading = true;

    const file = (event.target as HTMLInputElement).files[0];

    if (file == undefined) {
      this.loading = false;
      return;
    }

    if (file.size == 0) {
      this.displayValidationError("Empty file detected. Select a valid photo.");
      return;
    }

    if (file.size < this.minFileSize) {
      const fileSizeInKb = Math.round(file.size / 1000).toString() + " kb";
      this.displayValidationError(
        "The file selected is " +
          fileSizeInKb +
          ". File size must over 100 kb.",
      );
      return;
    }

    if (!this.validFileExtension(file)) {
      this.displayValidationError(
        "Your photo must be a .jpeg, .jpg, or a .png",
      );
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (this.isFileSizeTooLarge(file.size)) {
        // reduce file size
        let originalImage = new Image();
        originalImage.src = reader.result as string;

        originalImage.onload = async () => {
          await this.resizeImage(
            reader.result as string,
            originalImage.width,
            originalImage.height,
          ).then((resizedImage: any) => {
            if (this.isFileSizeTooLarge(resizedImage.length)) {
              this.displayValidationError(
                "Your photo must be smaller than 4MB",
              );
              return;
            }

            this.invitationConfirmation.profilePhotoType = "jpeg";
            this.goPreviewTheImage(resizedImage);
          });
        };
      } else {
        this.invitationConfirmation.profilePhotoType = file.name
          .split(".")
          .pop();
        this.goPreviewTheImage(reader.result as string);
      }
    };
  }

  goPreviewTheImage(imageUrl: string): void {
    this.invitationConfirmation.profilePhotoBase64 = imageUrl;
    this.inviteeDetails.setInviteeDetails(this.invitationConfirmation);

    this.routerService.redirectToProfilePhotoReview();
    this.loading = false;
  }

  displayValidationError(message: string) {
    this.errorMessage = message;
    this.loading = false;
    scrollToTop();
  }

  resizeImage(
    imageURL: any,
    originalWidth: number,
    originalHeight: number,
  ): Promise<any> {
    return new Promise((resolve) => {
      const image = new Image();

      const newWidth = originalWidth * this.resizeFactor;
      const newHeight = originalHeight * this.resizeFactor;

      image.onload = function () {
        const canvas = document.createElement("canvas");
        canvas.width = newWidth;
        canvas.height = newHeight;
        const ctx = canvas.getContext("2d");
        if (ctx != null) {
          ctx.drawImage(image, 0, 0, newWidth, newHeight);
        }
        const data = canvas.toDataURL("image/jpeg", 1);
        resolve(data);
      };
      image.src = imageURL;
    });
  }

  isFileSizeTooLarge(size: number): boolean {
    return size > this.maxFileSize;
  }

  setLinks(): void {
    if (this.invitationConfirmation.tuAddress != "") {
      // Could have used DPOA, but failed check
      if (
        this.invitationConfirmation.photoOneId != "" &&
        this.invitationConfirmation.photoTwoId != ""
      ) {
        this.breadcrumbRoute = "/invitee/photo-id-two";
        return;
      }

      if (this.invitationConfirmation.photoOneId == "Passport") {
        this.breadcrumbRoute = "/invitee/passport";
      } else {
        this.breadcrumbRoute = "/invitee/photo-id-one";
      }
    } else {
      this.breadcrumbRoute = "/invitee/photo-id-two";
    }
  }
}
