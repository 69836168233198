import { Component, OnInit, ViewChild } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { IdCheckInternalNotes } from "src/app/models/idCheckInternalNotes";
import { IdCheckerService } from "src/app/services/id-checker.service";
import { isNotesValid } from "src/app/utils/data-validation/data-validation";
import { TextAreaInputComponent } from "../../common/form-fields/text-inputs/text-area-input/text-area-input.component";
import { ErrorSummaryMessage } from "src/app/models/error-summary-message";
import { Location } from "@angular/common";
import { RouterService } from "src/app/services/router.service";

@Component({
  selector: "app-update-internal-note",
  templateUrl: "./update-internal-note.component.html",
})
export class UpdateInternalNoteComponent implements OnInit {
  breadcrumbRoute = "/id-checker/review-id";
  breadcrumbText = "Back";
  constructor(
    private title: Title,
    private idCheckerService: IdCheckerService,
    private route: ActivatedRoute,
    private routerService: RouterService,
    private location: Location,
  ) {}
  note: IdCheckInternalNotes;
  invitationId: string;
  noteErrorMessage = "";
  noteValid: boolean;
  summaryErrorMessages = Array<ErrorSummaryMessage>();
  loading: boolean;

  @ViewChild("noteInput")
  noteInput: TextAreaInputComponent;

  ngOnInit(): void {
    this.title.setTitle("Edit note");
    this.invitationId = this.route.snapshot.paramMap.get("invitationId");
    this.note = this.location.getState()["note"] as IdCheckInternalNotes;

    this.breadcrumbRoute = `${this.breadcrumbRoute}/${this.invitationId}`;
  }

  async onSubmit(): Promise<void> {
    this.noteInput.setQuestionAnswer();
    if (this.noteValid) {
      this.loading = true;
      this.idCheckerService
        .postIdCheckInternalNotes(
          this.invitationId,
          this.note.note,
          this.note.noteId,
        )
        .subscribe({
          next: () => {
            this.loading = false;
            this.routerService.redirectToReviewId(this.invitationId);
          },
          error: (err) => {
            this.loading = false;
            this.routerService.handleErrorAuthRoutes(err.status);
          },
        });
    }
  }

  readonly errorMessages = {
    internalNote: {
      empty: "The note cannot be empty.",
      lengthTooLong: "The note must be no more than 300 characters long.",
      lengthTooShort: "The note must be no less than 2 characters long.",
      unexpectedCharacters:
        "The note must consist of only letters, numbers and punctuation.",
      unknown: "Please check you've entered the note correctly.",
    },
  };

  updateInternalNoteAnswer(internalNote: string): void {
    if (internalNote === undefined) {
      internalNote = "";
    }

    const validationResult = isNotesValid(internalNote, 300, true);
    if (validationResult.validity) {
      this.noteErrorMessage = "";
      this.noteValid = true;
    } else {
      this.noteErrorMessage =
        this.errorMessages.internalNote[validationResult.errorReason];
      this.noteValid = false;
    }
    this.note.note = internalNote;
    this.updateInternalNoteErrorMessage();
  }

  updateInternalNoteErrorMessage(): void {
    const updatedErrorSummary = Array<ErrorSummaryMessage>();
    if (this.noteErrorMessage) {
      updatedErrorSummary.push({
        message: this.noteErrorMessage,
        id: "internal-note",
      });
    }
    this.summaryErrorMessages = updatedErrorSummary;
  }
}
