import { HttpClient, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { EnvironmentConfigService } from "./environment-config.service";
import { SessionAuthenticationService } from "./session-authentication.service";
import {
  InvitationDetails,
  InvitationRaDetails,
  InvitationSearchDetails,
} from "../models/invitationDetails";
import { Portal } from "src/app/models/session-token";

@Injectable()
export class GetInvitationService {
  constructor(
    private http: HttpClient,
    private environmentConfigService: EnvironmentConfigService,
    private sessionAuthenticationService: SessionAuthenticationService,
  ) {}

  getInvitations(): Observable<HttpResponse<InvitationSearchDetails[]>> {
    return this.http.get<InvitationSearchDetails[]>(
      `${this.environmentConfigService.getConfig().idBridgeApiUrl}/invitation`,
      {
        observe: "response",
        headers: this.sessionAuthenticationService.applyAuthorizationHeader(
          Portal.Invitation,
        ),
      },
    );
  }

  getInvitation(
    invitationId: string,
  ): Observable<HttpResponse<InvitationDetails>> {
    return this.http.get<InvitationDetails>(
      `${
        this.environmentConfigService.getConfig().idBridgeApiUrl
      }/invitation?invitation_id=${invitationId}`,
      {
        observe: "response",
        headers: this.sessionAuthenticationService.applyAuthorizationHeader(
          Portal.Invitation,
        ),
      },
    );
  }

  getInvitationsByOrg(): Observable<HttpResponse<InvitationDetails[]>> {
    return this.http.get<InvitationDetails[]>(
      `${
        this.environmentConfigService.getConfig().idBridgeApiUrl
      }/invitationsByOrg`,
      {
        observe: "response",
        headers: this.sessionAuthenticationService.applyAuthorizationHeader(
          Portal.Invitation,
        ),
      },
    );
  }

  getInvitationRaProperties(
    invitationId: string,
  ): Observable<HttpResponse<InvitationRaDetails>> {
    return this.http.get<InvitationRaDetails>(
      `${
        this.environmentConfigService.getConfig().idBridgeApiUrl
      }/invitation/raAccess?invitation_id=${invitationId}`,
      {
        observe: "response",
        headers: this.sessionAuthenticationService.applyAuthorizationHeader(
          Portal.Invitation,
        ),
      },
    );
  }
}
