import { Injectable } from "@angular/core";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { AuthReturnResponse } from "../models/auth-return-response";
import { EnvironmentConfigService } from "./environment-config.service";
import { SessionAuthenticationService } from "./session-authentication.service";

@Injectable()
export class IdentityAuthRedirectService {
  constructor(
    private http: HttpClient,
    private environmentConfigService: EnvironmentConfigService,
    private sessionAuthenticationService: SessionAuthenticationService,
  ) {}

  getIdentityAuthRedirectUrl(): Observable<HttpResponse<AuthReturnResponse>> {
    return this.http.get<AuthReturnResponse>(
      `${
        this.environmentConfigService.getConfig().idBridgeApiUrl
      }/auth/redirect`,
      { observe: "response" },
    );
  }

  getIdentityChangeRoleUrl(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(
          `${
            this.environmentConfigService.getConfig().idBridgeApiUrl
          }/auth/change_role`,
          {
            headers:
              this.sessionAuthenticationService.applyAuthorizationHeader(),
            observe: "body",
            responseType: "json",
          },
        )
        .subscribe(
          (res) => {
            resolve(res);
          },
          (error) => {
            reject(error);
          },
        );
    });
  }
}
