import { initialState, InviteeSPAState } from "../states/invitee-spa.state";
import * as InviteeSPAActions from "../actions/invitee-spa.actions";

/* istanbul ignore next */
export function reducerInviteeSPA(
  state = initialState,
  action: InviteeSPAActions.InviteeSPAActions,
): InviteeSPAState {
  switch (action.type) {
    case InviteeSPAActions.UPDATE_INVITATION_CONFIRMATION: {
      return {
        ...state,
        invitationConfirmation: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}
