import { Component, ElementRef, ViewChild, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { RouterService } from "src/app/services/router.service";
import { ErrorSummaryMessage } from "../../../models/error-summary-message";
import { InvitationConfirmation } from "src/store/states/invitee-spa.state";
import { InviteeDetails } from "src/app/utils/invitee-details";

@Component({
  selector: "app-poa",
  templateUrl: "./poa.component.html",
})
export class PoaComponent implements OnInit {
  displayBackLink = true;
  breadcrumbRoute = "/invitee/step1";
  breadcrumbText = "Back";
  invitationConfirmation: InvitationConfirmation;
  @ViewChild("bankAccount", { static: true }) bankAccount: ElementRef;
  @ViewChild("utilityBill", { static: true }) utilityBill: ElementRef;
  @ViewChild("mortgageStatement", { static: true })
  mortgageStatement: ElementRef;
  @ViewChild("councilTax", { static: true }) councilTax: ElementRef;
  @ViewChild("tenancyAgreement", { static: true }) tenancyAgreement: ElementRef;
  @ViewChild("drivingLicence", { static: true }) drivingLicence: ElementRef;
  @ViewChild("none", { static: true }) none: ElementRef;

  constructor(
    private title: Title,
    private routerService: RouterService,
    private inviteeDetails: InviteeDetails,
  ) {}
  poaType: string = undefined;

  summaryErrorMessages = [];

  async ngOnInit(): Promise<void> {
    this.title.setTitle(" Which proof of address document do you want to use?");
    this.invitationConfirmation = this.inviteeDetails.getInviteeDetails();
    this.setPreviousValues(this.invitationConfirmation.poaType);

    if (this.invitationConfirmation.dpoaRouteUsed) {
      this.displayBackLink = false;
    }
  }

  setPreviousValues(value: string): void {
    if (value == "BankAccount") {
      this.bankAccount.nativeElement.click();
    } else if (value == "UtilityBill") {
      this.utilityBill.nativeElement.click();
    } else if (value == "MortgageStatement") {
      this.mortgageStatement.nativeElement.click();
    } else if (value == "CouncilTax") {
      this.councilTax.nativeElement.click();
    } else if (value == "TenancyAgreement") {
      this.tenancyAgreement.nativeElement.click();
    } else if (value == "DrivingLicence") {
      this.drivingLicence.nativeElement.click();
    } else if (value == "None") {
      this.none.nativeElement.click();
    }
  }

  updateErrorSummary() {
    const updatedErrorSummary = Array<ErrorSummaryMessage>();

    if (this.poaType === undefined) {
      updatedErrorSummary.push({
        message: "Select a proof of address document.",
        id: "poa-type",
      });
    }

    this.summaryErrorMessages = updatedErrorSummary;
  }

  updatePoaRadioValue(value: string) {
    this.poaType = value;
    this.invitationConfirmation.poaType = value;
    this.invitationConfirmation.photoOneId = "";
    this.invitationConfirmation.photoTwoId = "";
    this.invitationConfirmation.tuBuildingName = "";
    this.invitationConfirmation.tuAddressLineOne = "";
    this.invitationConfirmation.tuTownCity = "";
    this.invitationConfirmation.tuPostcode = "";
    this.updateErrorSummary();

    if (!this.invitationConfirmation.dpoaRouteUsed) {
      this.invitationConfirmation.tuAddress = "";
    }
  }

  onContinueButtonClicked(): void {
    this.updateErrorSummary();

    if (this.poaType != undefined) {
      if (this.poaType == "None") {
        this.routerService.redirectToNoPoa();
      } else {
        this.inviteeDetails.setInviteeDetails(this.invitationConfirmation);
        this.routerService.redirectToPhotoIdTwo();
      }
    }
  }
}
