import { Component, Inject, OnInit } from "@angular/core";
import {
  IdCheckerOverview,
  Invitation,
  InvitationIdCheck,
} from "src/store/states/spa.state";
import { IdCheckerMetrics } from "src/app/models/idCheckerMetrics";
import { IdCheckerService } from "src/app/services/id-checker.service";
import {
  InvitationDetails,
  InvitationStatus,
} from "src/app/models/invitationDetails";
import { RouterService } from "src/app/services/router.service";
import { Title } from "@angular/platform-browser";
import { idCheckSortChanged } from "../../../utils/common/common";
import { SESSION_STORAGE, StorageService } from "ngx-webstorage-service";

@Component({
  selector: "app-review-list",
  templateUrl: "./review-list.component.html",
})
export class ReviewListComponent implements OnInit {
  invitations: InvitationIdCheck[];
  collectionSize: number;
  noNewApplication: boolean = false;
  invitation: Invitation;
  metrics: IdCheckerOverview;

  constructor(
    private routerService: RouterService,
    private idCheckerService: IdCheckerService,
    private title: Title,
    @Inject(SESSION_STORAGE) private sessionStorage: StorageService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.title.setTitle("ID Checker");
    await this.showAppMetrics();
    await this.postInvitationsInProgressReset();
    await this.getWaitingForReviewInvitations();
    this.clearIdCheckerSelections();
  }

  mapInvites(invites: InvitationDetails[]): InvitationIdCheck[] {
    return invites.map((invite) => new InvitationIdCheck(invite));
  }

  mapInvitation(invitationDetails: InvitationDetails): Invitation {
    return new Invitation(invitationDetails);
  }

  mapMetrics(idCheckerMetrics: IdCheckerMetrics): IdCheckerOverview {
    if (idCheckerMetrics != undefined && idCheckerMetrics != null) {
      return new IdCheckerOverview(idCheckerMetrics);
    }
    return new IdCheckerOverview({
      verification_queue: 0,
      verification_held: 0,
      verification_passed: 0,
      verification_failed: 0,
    } as IdCheckerMetrics);
  }

  onNextVerificationClicked() {
    this.idCheckerService.getNextHeldApplication().subscribe({
      next: (response) => {
        this.invitation = this.mapInvitation(response["body"]);
        this.routerService.redirectToReviewId(this.invitation.invitationId);
      },
      error: (error) => {
        this.routerService.handleErrorAuthRoutes(error.status);
        if (error.status === 404) {
          this.noNewApplication = true;
        }
      },
    });
  }

  async showAppMetrics() {
    this.idCheckerService.getIdCheckerMetrics().subscribe({
      next: (response) => {
        this.metrics = this.mapMetrics(response["body"]);
        if (this.metrics.verificationQueue === 0) {
          this.noNewApplication = true;
        }
      },
      error: (error) => {
        this.routerService.handleErrorAuthRoutes(error.status);
      },
    });
  }

  async getWaitingForReviewInvitations() {
    this.idCheckerService
      .getIdCheckerInvitations(InvitationStatus["ID review"])
      .subscribe({
        next: (response) => {
          this.invitations = this.mapInvites(response["body"]).sort((a, b) =>
            a.submittedIdCheckUtc > b.submittedIdCheckUtc ? 1 : -1,
          );
          this.collectionSize = this.invitations.length;
        },
        error: (error) => {
          this.routerService.handleErrorAuthRoutes(error.status);
          this.invitations = [];
        },
      });
  }

  async postInvitationsInProgressReset() {
    this.idCheckerService.postIdCheckInProgressReset().subscribe({
      next: (response) => {
        console.log(response.statusText);
      },
      error: (error) => {
        this.routerService.handleErrorAuthRoutes(error.status);
      },
    });
  }

  sortChanged(columnName: string): void {
    this.invitations = idCheckSortChanged(this.invitations, columnName);
  }

  clearIdCheckerSelections(): void {
    this.sessionStorage.remove("idchecker-selections");
  }
}
