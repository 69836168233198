import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { RouterService } from "src/app/services/router.service";
import { ErrorSummaryMessage } from "../../../models/error-summary-message";
import { InviteeDetails } from "src/app/utils/invitee-details";
import { InvitationConfirmation } from "src/store/states/invitee-spa.state";

@Component({
  selector: "app-start-verify-address",
  templateUrl: "./step1.component.html",
})
export class Step1Component implements OnInit {
  breadcrumbRoute = "/invitee/start-application";
  breadcrumbText = "Back";
  invitationConfirmation: InvitationConfirmation;

  constructor(
    private inviteeDetails: InviteeDetails,
    private title: Title,
    private routerService: RouterService,
  ) {}

  dpoaConsentGiven: boolean;
  dpoaConsentResponseValid: boolean;

  summaryErrorMessages = [];

  ngOnInit(): void {
    this.title.setTitle("How do you want to verify your address?");
    this.invitationConfirmation = this.inviteeDetails.getInviteeDetails();
  }

  updateDpoaConsentRadioValue(value) {
    this.dpoaConsentGiven = value;
  }

  validOptionSelected() {
    if (this.dpoaConsentGiven === undefined) {
      this.dpoaConsentResponseValid = false;
    } else {
      this.dpoaConsentResponseValid = true;
    }
    this.updateErrorSummary();
  }

  updateErrorSummary() {
    const updatedErrorSummary = Array<ErrorSummaryMessage>();
    if (!this.dpoaConsentResponseValid) {
      updatedErrorSummary.push({
        message: "Select if you agree to verify your address with TransUnion.",
        id: "dpoa-consent",
      });
    }

    this.summaryErrorMessages = updatedErrorSummary;
  }

  onSubmitButtonClicked(): void {
    this.validOptionSelected();
    if (this.dpoaConsentResponseValid) {
      if (this.dpoaConsentGiven) {
        this.setDpoaRouteUsed(true);
        this.routerService.redirectToConfirmPostcode("tu");
      } else {
        this.setDpoaRouteUsed(false);
        this.routerService.redirectToPoa();
      }
    }
  }

  setDpoaRouteUsed(value: boolean): void {
    this.invitationConfirmation.dpoaRouteUsed = value;
    if (!value) {
      this.invitationConfirmation.tuAddress = "";
    } else {
      this.invitationConfirmation.poaType = "";
    }
    this.inviteeDetails.setInviteeDetails(this.invitationConfirmation);
  }
}
