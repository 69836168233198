/* istanbul ignore file */
import { BrowserModule, Title } from "@angular/platform-browser";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { StoreModule } from "@ngrx/store";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { HttpClientModule } from "@angular/common/http";
import { BasicInfoComponent } from "./components/invitation/basic-info/basic-info.component";
import { InvitationSuccessComponent } from "./components/invitation/invitation-success/invitation-success.component";
import { FinalSummaryComponent } from "./components/invitation/final-summary/final-summary.component";
import { SelectDropdownComponent } from "./components/common/form-fields/select-dropdown/select-dropdown.component";
import { RadioButtonComponent } from "./components/common/form-fields/radio-button/radio-button.component";
import { TextInputComponent } from "./components/common/form-fields/text-inputs/text-input.component";
import { TextAreaInputComponent } from "./components/common/form-fields/text-inputs/text-area-input/text-area-input.component";
import { DateOfBirthComponent } from "./components/common/form-fields/date-of-birth/date-of-birth.component";
import { SendInvitationService } from "./services/send-invitation.service";
import { IdentityAuthRedirectService } from "./services/identity-auth-redirect.service";
import { SiteService } from "./services/site.service";
import { reducers } from "src/store/reducers/index";
import { EnvironmentConfigService } from "./services/environment-config.service";
import { AuthReturnComponent } from "./components/auth-return/auth-return.component";
import { SendAuthcodeService } from "./services/send-authcode.service";
import { NumbersOnlyDirective } from "./utils/numbers-only.directive";
import { AuthUtil } from "./utils/auth/auth";
import { InviteeDetails } from "./utils/invitee-details";
import { HelpComponent } from "./components/invitee/help/help.component";
import { TransunionComponent } from "./components/invitee/transunion/transunion.component";
import { PrivacyComponent } from "./components/privacy/privacy.component";
import { BreadcrumbLinkComponent } from "./components/common/breadcrumb-link/breadcrumb-link.component";
import { SessionCheckComponent } from "./components/common/session-check/session-check.component";
import { ModifiedRecordCheckComponent } from "./components/common/modified-record-check/modified-record-check.component";
import { RoleChangedComponent } from "./components/common/role-changed/role-changed.component";
import { ProgressIndicatorComponent } from "./components/common/progress-indicator/progress-indicator.component";
import { AuthenticateComponent } from "./components/authenticate/authenticate.component";
import { SessionAuthenticationService } from "./services/session-authentication.service";
import { AuthGuardService } from "./guards/auth.guard";
import { ConfirmNameComponent } from "./components/invitee/confirm-name/confirm-name.component";
import { ConfirmDateOfBirthComponent } from "./components/invitee/confirm-date-of-birth/confirm-date-of-birth.component";
import { ErrorSummaryComponent } from "./components/common/error-summary/error-summary.component";
import { FeedbackLinkComponent } from "./components/common/feedback-link/feedback-link.component";
import { PhotoGuidelinesComponent } from "./components/common/photo-guidelines/photo-guidelines.component";
import { CimLinkComponent } from "./components/common/cim-link/cim-link.component";
import { EmailSupportComponent } from "./components/common/email-support/email-support.component";
import { AfcidImageCropperComponent } from "./components/common/afcid-image-cropper/afcid-image-cropper.component";
import { SuccessComponent } from "./components/common/alerts/success/success.component";
import { AcceptInvitationComponent } from "./components/invitee/accept-invitation/accept-invitation.component";
import { VerifyInvitationService } from "./services/verify-invitation.service";
import { IdCheckerService } from "./services/id-checker.service";
import { DuplicateCheckService } from "./services/duplicate-check.service";
import { DashboardComponent } from "./components/invitation/dashboard/dashboard.component";
import { GetInvitationService } from "./services/get-invitation.service";
import { AfCIDCookieService } from "./services/acfid-cookie.service";
import { CheckboxComponent } from "./components/common/form-fields/checkbox/checkbox.component";
import {
  InvitationStatusPipe,
  InvitationStatusRawPipe,
} from "./utils/pipes/invitation-status/invitation-status.pipe";
import {
  InvitationStatusClassPipe,
  InvitationStatusRawClassPipe,
} from "./utils/pipes/invitation-status-class/invitation-status-class.pipe";
import { NgxPaginationModule } from "ngx-pagination";
import { InviteDetailsComponent } from "./components/invitation/invite-details/invite-details.component";
import { SanitizeHtmlPipe } from "./utils/pipes/sanitize-html/sanitize-html.pipe";
import { SendOtpComponent } from "./components/invitee/send-otp/send-otp.component";
import { InviteeStartComponent } from "./components/invitee/invitee-start/invitee-start.component";
import { OneTimePasscodeComponent } from "./components/invitee/one-time-passcode/one-time-passcode.component";
import { StartComponent } from "./components/start/start.component";
import { DownloadAppComponent } from "./components/invitee/download-app/download-app.component";
import { ConfirmNiNumberComponent } from "./components/invitee/confirm-ni-number/confirm-ni-number.component";
import { CheckYourAnswersComponent } from "./components/invitee/check-your-answers/check-your-answers.component";
import { CheckYourAddressComponent } from "./components/invitee/check-your-address/check-your-address.component";
import { ReviewIdComponent } from "./components/id-checker/review-id/review-id.component";
import { GrantUserService } from "./services/grant-user.service";
import { SkipLinkComponent } from "./components/common/skip-link/skip-link.component";
import { HoldingListComponent } from "./components/id-checker/holding-list/holding-list.component";
import { IdHeldCheckReasonPipe } from "./utils/pipes/id-check-held-reason/id-check-held-reason.pipe";
import { CancelInviteComponent } from "./components/invitation/cancel-invite/cancel-invite.component";
import { CancelMultipleComponent } from "./components/invitation/cancel-multiple/cancel-multiple.component";
import { InviteCancelledComponent } from "./components/invitation/invite-cancelled/invite-cancelled.component";
import { ConfirmPersonalEmailComponent } from "./components/invitation/confirm-personal-email/confirm-personal-email.component";
import { CancelInvitationService } from "./services/cancel-invitation.service";
import { CryptoService } from "./services/security/crypto.service";
import { CookieService } from "ngx-cookie-service";
import { IdCheckRadioButtonComponent } from "./components/common/form-fields/id-check-radio-button/id-check-radio-button.component";
import { RejectedListComponent } from "./components/id-checker/rejected-list/rejected-list.component";
import { IdFailureCheckReasonPipe } from "./utils/pipes/id-check-failure-reason/id-check-failure-reason.pipe";
import { HoldApplicationComponent } from "./components/id-checker/hold-application/hold-application.component";
import { PassedListComponent } from "./components/id-checker/passed-list/passed-list.component";
import { RejectApplicationComponent } from "./components/id-checker/reject-application/reject-application.component";
import { ConfirmAddressComponent } from "./components/invitee/confirm-address/confirm-address.component";
import { ConfirmPostcodeComponent } from "./components/invitee/confirm-postcode/confirm-postcode.component";
import { PostcodeLookupService } from "./services/postcode-lookup.service";
import { SelectAddressComponent } from "./components/invitee/select-address/select-address.component";
import { ConfirmDetailsComponent } from "./components/id-checker/confirm-details/confirm-details.component";
import { IdCheckerDateComponent } from "./components/common/id-checker-date/id-checker-date.component";
import { IdPhotoComponent } from "./components/common/id-photo/id-photo.component";
import { PortalSelectComponent } from "./components/portal-select/portal-select.component";
import { ConfirmPreferredNameComponent } from "./components/invitee/confirm-preferred-name/confirm-preferred-name.component";
import { ReviewListComponent } from "./components/id-checker/review-list/review-list.component";
import { PageNotFoundComponent } from "./components/errors/page-not-found/page-not-found.component";
import { DeviceNotSupportedComponent } from "./components/errors/device-not-supported/device-not-supported.component";
import { InternalServerErrorComponent } from "./components/errors/internal-server-error/internal-server-error.component";
import { InvalidRequestComponent } from "./components/errors/invalid-request/invalid-request.component";
import { DatePipe } from "@angular/common";
import { OtpLockedComponent } from "./components/errors/otp-locked/otp-locked.component";
import { OtpExpiredComponent } from "./components/errors/otp-expired/otp-expired.component";
import { OtpFailedComponent } from "./components/errors/otp-failed/otp-failed.component";
import { OtpMaxSendsComponent } from "./components/errors/otp-max-sends/otp-max-sends.component";
import { UploadFileComponent } from "./components/invitation/batch-invitation-upload/upload-file/upload-file.component";
import { InviteSentBulkComponent } from "./components/invitation/batch-invitation-upload/invite-sent-bulk/invite-sent-bulk.component";
import { FileContainsErrorsComponent } from "./components/invitation/batch-invitation-upload/file-contains-errors/file-contains-errors.component";
import { NotSentApplicationsComponent } from "./components/invitation/batch-invitation-upload/not-sent-applications/not-sent-applications.component";
import { BatchUploadService } from "./services/batch-upload/batch-upload.service";
import { PhotoIdDocTypePipe } from "./utils/pipes/photo-id-doc-type/photo-id-doc-type.pipe";
import { PoaTypePipe } from "./utils/pipes/poa-type/poa-type.pipe";
import { BooleanValuePipe } from "./utils/pipes/boolean-value/boolean-value.pipe";
import { ApplicationStepStatusPipe } from "./utils/pipes/application-step-status/application-step-status.pipe";
import { ApplicationStepStatusClassPipe } from "./utils/pipes/application-step-status-class/application-step-status-class.pipe";
import { TabDashboardComponent } from "./components/invitation/tab-dashboard/tab-dashboard.component";
import { NoContinueComponent } from "./components/invitee/no-continue/no-continue.component";
import { PassportComponent } from "./components/invitee/passport/passport.component";
import { PhotoIdOneComponent } from "./components/invitee/photo-id-one/photo-id-one.component";
import { PhotoIdTwoComponent } from "./components/invitee/photo-id-two/photo-id-two.component";
import { Step1Component } from "./components/invitee/step1/step1.component";
import { ProfilePhotoUploadComponent } from "./components/invitee/profile-photo-upload/profile-photo-upload.component";
import { ProfilePhotoReviewComponent } from "./components/invitee/profile-photo-review/profile-photo-review.component";
import { PoaComponent } from "./components/invitee/poa/poa.component";
import { DeliveryComponent } from "./components/invitee/delivery/delivery.component";
import { SaveSelectionComponent } from "./components/invitee/save-selection/save-selection.component";
import { PoaValidateService } from "./services/poa-validate.service";
import { RouterService } from "./services/router.service";
import { IdFailureCheckReasonParagraphPipe } from "./utils/pipes/id-check-failure-reason-paragraph/id-check-failure-reason-paragraph.pipe";
import { ServiceManagerPortalComponent } from "./components/service-management/service-manager-portal/service-manager-portal.component";
import { ViewPayloadComponent } from "./components/service-management/view-payload/view-payload.component";
import { ServiceManagerService } from "./services/service-manager.service";
import { DeleteInvitationComponent } from "./components/service-management/delete-invitation/delete-invitation.component";
import { Ems2CaseApprovalRetryComponent } from "./components/service-management/ems2/case-approval-retry/ems2-case-approval-retry.component";
import { Ems2CaseEventChangeComponent } from "./components/service-management/ems2/case-event-change/case-event-change.component";
import { CancelledReasonPipe } from "./utils/pipes/cancelled-reason/cancelled-reason.pipe";
import { UpdateInternalNoteComponent } from "./components/id-checker/update-internal-note/update-internal-note.component";
import { CreateInternalNoteComponent } from "./components/id-checker/create-internal-note/create-internal-note.component";
import { SettingsComponent } from "./components/user-settings/settings/settings.component";
import { UserSettingsService } from "./services/user-settings.service";
import { GlobalHelpComponent } from "./components/global-help/global-help.component";
import { CookiesPreferenceComponent } from "./components/cookies/cookies-preference/cookies-preference.component";
import { CookiesSavedComponent } from "./components/cookies/cookies-saved/cookies-saved.component";
import { CookiesBannerComponent } from "./components/cookies/cookies-banner/cookies-banner.component";
import { BeforeYouStartComponent } from "./components/invitation/before-you-start/before-you-start.component";
import { VerifyEmailComponent } from "./components/user-settings/verify-email/verify-email.component";
import { UpdateEmailComponent } from "./components/user-settings/update-email/update-email.component";
import { SearchComponent } from "./components/id-checker/search/search.component";
import { SmsDownloadLinkResendComponent } from "./components/service-management/sms-download-link-resend/sms-download-link-resend.component";
import { ImageCropperModule } from "ngx-image-cropper";
import { AutoFocusDirective } from "./services/auto-focus.directive";
import { IconCrossComponent } from "./components/common/svgs/icon-cross/icon-cross.component";
import { IconTickComponent } from "./components/common/svgs/icon-tick/icon-tick.component";
import { ManagedOrgsService } from "./services/managed-orgs.service";
import { FeedbackService } from "./services/feedback.service";

export function loadConfigService(
  configService: EnvironmentConfigService,
): () => void {
  return () => {
    return configService.load();
  };
}

@NgModule({
  declarations: [
    AppComponent,
    AutoFocusDirective,
    DateOfBirthComponent,
    RadioButtonComponent,
    BasicInfoComponent,
    FinalSummaryComponent,
    InvitationSuccessComponent,
    SelectDropdownComponent,
    TextInputComponent,
    TextAreaInputComponent,
    NumbersOnlyDirective,
    HelpComponent,
    TransunionComponent,
    BreadcrumbLinkComponent,
    PrivacyComponent,
    AuthReturnComponent,
    ProgressIndicatorComponent,
    SessionCheckComponent,
    ModifiedRecordCheckComponent,
    RoleChangedComponent,
    AuthenticateComponent,
    ConfirmNameComponent,
    ConfirmPreferredNameComponent,
    ConfirmDateOfBirthComponent,
    ErrorSummaryComponent,
    FeedbackLinkComponent,
    PhotoGuidelinesComponent,
    CimLinkComponent,
    EmailSupportComponent,
    AfcidImageCropperComponent,
    SuccessComponent,
    IconCrossComponent,
    IconTickComponent,
    AcceptInvitationComponent,
    DashboardComponent,
    CheckboxComponent,
    InvitationStatusPipe,
    InvitationStatusRawPipe,
    InvitationStatusClassPipe,
    InvitationStatusRawClassPipe,
    IdHeldCheckReasonPipe,
    InviteDetailsComponent,
    SanitizeHtmlPipe,
    StartComponent,
    SendOtpComponent,
    IdPhotoComponent,
    InviteeStartComponent,
    OneTimePasscodeComponent,
    DownloadAppComponent,
    ConfirmNiNumberComponent,
    CheckYourAnswersComponent,
    CheckYourAddressComponent,
    StartComponent,
    ReviewIdComponent,
    SkipLinkComponent,
    HoldingListComponent,
    CancelInviteComponent,
    CancelMultipleComponent,
    InviteCancelledComponent,
    ConfirmPersonalEmailComponent,
    IdCheckerDateComponent,
    IdCheckRadioButtonComponent,
    RejectedListComponent,
    IdFailureCheckReasonPipe,
    RejectApplicationComponent,
    HoldApplicationComponent,
    PassedListComponent,
    RejectApplicationComponent,
    ConfirmAddressComponent,
    ConfirmPostcodeComponent,
    SelectAddressComponent,
    ConfirmDetailsComponent,
    PortalSelectComponent,
    ReviewListComponent,
    UploadFileComponent,
    InviteSentBulkComponent,
    FileContainsErrorsComponent,
    NotSentApplicationsComponent,
    PageNotFoundComponent,
    InvalidRequestComponent,
    DeviceNotSupportedComponent,
    InternalServerErrorComponent,
    OtpLockedComponent,
    OtpExpiredComponent,
    OtpFailedComponent,
    OtpMaxSendsComponent,
    ApplicationStepStatusPipe,
    ApplicationStepStatusClassPipe,
    TabDashboardComponent,
    NoContinueComponent,
    PassportComponent,
    PhotoIdOneComponent,
    PhotoIdTwoComponent,
    Step1Component,
    ProfilePhotoUploadComponent,
    ProfilePhotoReviewComponent,
    PoaComponent,
    DeliveryComponent,
    SaveSelectionComponent,
    PhotoIdDocTypePipe,
    PoaTypePipe,
    BooleanValuePipe,
    IdFailureCheckReasonParagraphPipe,
    ServiceManagerPortalComponent,
    ViewPayloadComponent,
    DeleteInvitationComponent,
    Ems2CaseApprovalRetryComponent,
    Ems2CaseEventChangeComponent,
    CancelledReasonPipe,
    UpdateInternalNoteComponent,
    CreateInternalNoteComponent,
    GlobalHelpComponent,
    SettingsComponent,
    CookiesBannerComponent,
    CookiesPreferenceComponent,
    CookiesSavedComponent,
    BeforeYouStartComponent,
    VerifyEmailComponent,
    UpdateEmailComponent,
    SearchComponent,
    SmsDownloadLinkResendComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    StoreModule.forRoot({}),
    StoreModule.forFeature("SPA", reducers),
    NgxPaginationModule,
    ImageCropperModule,
  ],
  providers: [
    IdCheckerService,
    AfCIDCookieService,
    GetInvitationService,
    SendInvitationService,
    GrantUserService,
    CancelInvitationService,
    EnvironmentConfigService,
    SendAuthcodeService,
    SessionAuthenticationService,
    AuthGuardService,
    IdentityAuthRedirectService,
    SiteService,
    VerifyInvitationService,
    DuplicateCheckService,
    PostcodeLookupService,
    PoaValidateService,
    ServiceManagerService,
    ManagedOrgsService,
    Title,
    CookieService,
    CryptoService,
    DatePipe,
    PoaTypePipe,
    PhotoIdDocTypePipe,
    IdFailureCheckReasonPipe,
    AuthUtil,
    InviteeDetails,
    BatchUploadService,
    RouterService,
    UserSettingsService,
    FeedbackService,
    {
      provide: APP_INITIALIZER,
      useFactory: loadConfigService,
      deps: [EnvironmentConfigService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
