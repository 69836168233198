<div class="nhsuk-grid-row">
  <div class="nhsuk-grid-column-two-thirds">
    <breadcrumb-link
      [breadcrumbRoute]="breadcrumbRoute"
      [breadcrumbText]="breadcrumbText"
    ></breadcrumb-link>

    <div class="nhsuk-form-group">
      <error-summary
        [errorSummaryMessages]="summaryErrorMessages"
      ></error-summary>
      <fieldset class="nhsuk-fieldset">
        <legend
          class="nhsuk-fieldset__legend nhsuk-fieldset__legend--xl nhsuk-u-margin-bottom-7"
          aria-describedby="photo-ids-hint"
        >
          <h1 autofocus tabindex="-1" class="nhsuk-fieldset__heading">
            Which photo ID do you want to use?
          </h1>
        </legend>

        <p>
          You'll need to capture an image of this document when using the mobile
          app.
        </p>

        <div class="nhsuk-hint" id="photo-ids-hint">
          Photo IDs must not be expired
        </div>

        <div class="nhsuk-radios" id="photo-one-select">
          <div class="nhsuk-radios__item">
            <input
              #drivingLicence
              data-cy="driving-licence"
              class="nhsuk-radios__input"
              aria-controls="yes"
              aria-expanded="false"
              type="radio"
              (change)="updatePhotoIdOneRadioValue('DrivingLicence')"
              id="driving-licence"
              name="photo-id-one"
            />
            <label
              class="nhsuk-label nhsuk-radios__label"
              for="driving-licence"
            >
              Driving licence (full or provisional)
            </label>
          </div>

          <div class="nhsuk-radios__item">
            <input
              #residencePermit
              data-cy="residence-permit"
              class="nhsuk-radios__input"
              aria-controls="yes"
              aria-expanded="false"
              type="radio"
              (change)="updatePhotoIdOneRadioValue('ResidencePermit')"
              id="residence-permit"
              name="photo-id-one"
            />
            <label
              class="nhsuk-label nhsuk-radios__label"
              for="residence-permit"
            >
              UK Biometric residence permit card
            </label>
          </div>

          <div class="nhsuk-radios__item">
            <input
              #armedForces
              data-cy="armed-forces"
              class="nhsuk-radios__input"
              aria-controls="yes"
              aria-expanded="false"
              type="radio"
              (change)="updatePhotoIdOneRadioValue('ArmedForces')"
              id="armed-forces"
              name="photo-id-one"
            />
            <label class="nhsuk-label nhsuk-radios__label" for="armed-forces">
              HM armed forces card
            </label>
          </div>

          <div class="nhsuk-radios__item">
            <input
              #passProofOfAgeCard
              data-cy="pass-proof-of-age-card"
              class="nhsuk-radios__input"
              aria-controls="yes"
              aria-expanded="false"
              type="radio"
              (change)="updatePhotoIdOneRadioValue('PassProofOfAgeCard')"
              id="pass-proof-of-age-card"
              name="photo-id-one"
            />
            <label
              class="nhsuk-label nhsuk-radios__label"
              for="pass-proof-of-age-card"
            >
              PASS proof of age card
            </label>
          </div>

          <div class="nhsuk-radios__divider">or</div>

          <div class="nhsuk-radios__item">
            <input
              #none
              data-cy="none"
              class="nhsuk-radios__input"
              aria-controls="yes"
              aria-expanded="false"
              type="radio"
              (change)="updatePhotoIdOneRadioValue('None')"
              id="none"
              name="photo-id-one"
            />
            <label class="nhsuk-label nhsuk-radios__label" for="none">
              I do not have any of these photo ID documents
            </label>
          </div>
        </div>
      </fieldset>
    </div>

    <button
      (click)="onContinueButtonClicked()"
      type="submit"
      class="nhsuk-button"
      data-cy="continue-button"
    >
      Continue
    </button>
  </div>
</div>
