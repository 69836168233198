import { Component, OnInit, Inject } from "@angular/core";
import { SESSION_STORAGE, StorageService } from "ngx-webstorage-service";
import { Title } from "@angular/platform-browser";
@Component({
  selector: "app-otp-max-sends",
  templateUrl: "./otp-max-sends.component.html",
})
export class OtpMaxSendsComponent implements OnInit {
  mobile: string;
  supportEmail: string;
  constructor(
    private title: Title,
    @Inject(SESSION_STORAGE) private sessionStorage: StorageService,
  ) {}

  ngOnInit(): void {
    this.title.setTitle("Too many security code requests");
    this.supportEmail = this.sessionStorage.get("supportEmail");
  }
}
