import { Component, OnInit, ViewChild } from "@angular/core";
import { RouterService } from "src/app/services/router.service";
import { Title } from "@angular/platform-browser";
import { ServiceManagerService } from "src/app/services/service-manager.service";
import { TextInputComponent } from "../../../common/form-fields/text-inputs/text-input.component";
@Component({
  selector: "app-case-event-change",
  templateUrl: "./case-event-change.component.html",
})
export class Ems2CaseEventChangeComponent implements OnInit {
  breadcrumbRoute = "/service-management/portal";
  breadcrumbText = "Back";
  submitButtonClicked: boolean;
  invitationId: string = "";

  errorMessage: string;
  successMessage: string;

  constructor(
    private serviceManagerService: ServiceManagerService,
    private routerService: RouterService,
    private title: Title,
  ) {}

  @ViewChild("invitationIdInput") invitationIdInput: TextInputComponent;

  ngOnInit(): void {
    this.title.setTitle("Send a new app link");
    this.successMessage = "";
  }

  async onSubmitButtonClicked(): Promise<void> {
    this.submitButtonClicked = true;
    this.errorMessage = null;
    this.successMessage = null;

    const invitationId = this.invitationId;
    if (invitationId.length === 0) {
      this.errorMessage = "Please enter an invitation id";
      this.submitButtonClicked = false;
      return;
    }

    try {
      await this.serviceManagerService.ems2CaseEventChange(invitationId);
      this.invitationIdInput.value = "";
      this.invitationId = "";
      this.successMessage = "Successfully processed for : " + invitationId;
    } catch (err) {
      this.routerService.handleErrorAuthRoutes(err.status);
      this.setErrorMessage(err.status);
    } finally {
      this.submitButtonClicked = false;
    }
  }

  setErrorMessage(status: number): void {
    if (status === 400) {
      this.errorMessage = "This invitation is not in Awaiting Documents status";
    } else if (status === 422) {
      this.errorMessage =
        "The request has expired, please reload the page and try again";
    } else if (status == 404) {
      this.errorMessage = "Invitation not found";
    } else {
      this.errorMessage = "Error. Please contact the system administrator.";
    }
  }

  updateValue(value: string) {
    this.invitationId = value;
  }
}
