<session-check></session-check>
<div *ngIf="invitation" class="nhsuk-grid-row">
  <div class="nhsuk-grid-column-two-thirds">
    <h1 autofocus tabindex="-1" class="nhsuk-heading-l">Check details</h1>

    <div
      *ngIf="!contactEmailValid"
      class="nhsuk-alert nhsuk-alert--warning nhsuk-alert--icon"
    >
      <svg
        class="nhsuk-icon nhsuk-icon__warning nhsuk-alert__icon"
        fill="none"
        focusable="false"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 25 25"
        height="25"
        width="25"
      >
        <path
          d="M13.7,18.5h-2.4v-2.4h2.4V18.5z M12.5,13.7c-0.7,0-1.2-0.5-1.2-1.2V7.7c0-0.7,0.5-1.2,1.2-1.2s1.2,0.5,1.2,1.2v4.8
        C13.7,13.2,13.2,13.7,12.5,13.7z M12.5,0.5c-6.6,0-12,5.4-12,12s5.4,12,12,12s12-5.4,12-12S19.1,0.5,12.5,0.5z"
        ></path>
      </svg>
      <h3 class="nhsuk-u-visually-hidden">Information:</h3>
      <p class="nhsuk-u-font-weight-bold">
        <a class="nhsuk-link--no-visited-state" href="/settings"
          >Go to settings</a
        >
        to set your contact email address which is required for all
        applications.
      </p>
    </div>

    <dl class="nhsuk-summary-list">
      <div class="nhsuk-summary-list__row">
        <dt id="name" class="nhsuk-summary-list__key">Full name</dt>
        <dd class="nhsuk-summary-list__value">
          {{ invitation.givenName }}
          {{ invitation.familyName }}
        </dd>
        <dd class="nhsuk-summary-list__actions">
          <div *ngIf="invitation.invitationId">
            <a
              aria-describedby="name"
              [state]="invitation"
              [routerLink]="[
                '/invitation/invite-details',
                invitation.invitationId
              ]"
            >
              Change
            </a>
          </div>
          <div *ngIf="!invitation.invitationId">
            <a
              aria-describedby="name"
              [state]="invitation"
              [routerLink]="['/invitation/basic-info', 'new-application']"
            >
              Change
            </a>
          </div>
        </dd>
      </div>
      <div class="nhsuk-summary-list__row">
        <dt id="email" class="nhsuk-summary-list__key">Email address</dt>
        <dd class="nhsuk-summary-list__value">
          {{ invitation.email }}
        </dd>
        <dd class="nhsuk-summary-list__actions">
          <div *ngIf="invitation.invitationId">
            <a
              aria-describedby="email"
              [state]="invitation"
              [routerLink]="[
                '/invitation/invite-details',
                invitation.invitationId
              ]"
            >
              Change
            </a>
          </div>
          <div *ngIf="!invitation.invitationId">
            <a
              aria-describedby="email"
              [state]="invitation"
              [routerLink]="['/invitation/basic-info', 'new-application']"
            >
              Change
            </a>
          </div>
        </dd>
      </div>
      <div class="nhsuk-summary-list__row">
        <dt id="mobile" class="nhsuk-summary-list__key">Mobile number</dt>
        <dd class="nhsuk-summary-list__value">
          {{ invitation.mobile }}
        </dd>
        <dd class="nhsuk-summary-list__actions">
          <div *ngIf="invitation.invitationId">
            <a
              aria-describedby="mobile"
              [state]="invitation"
              [routerLink]="[
                '/invitation/invite-details',
                invitation.invitationId
              ]"
            >
              Change
            </a>
          </div>
          <div *ngIf="!invitation.invitationId">
            <a
              aria-describedby="mobile"
              [state]="invitation"
              [routerLink]="['/invitation/basic-info', 'new-application']"
            >
              Change
            </a>
          </div>
        </dd>
      </div>
      <div class="nhsuk-summary-list__row">
        <dt id="da" class="nhsuk-summary-list__key">Delivery address</dt>
        <dd class="nhsuk-summary-list__value">
          {{ invitation.captureDeliveryAddress ? "Yes" : "No" }}
        </dd>
        <dd class="nhsuk-summary-list__actions">
          <div *ngIf="invitation.invitationId">
            <a
              aria-describedby="da"
              [state]="invitation"
              [routerLink]="[
                '/invitation/invite-details',
                invitation.invitationId
              ]"
            >
              Change
            </a>
          </div>
          <div *ngIf="!invitation.invitationId">
            <a
              aria-describedby="da"
              [state]="invitation"
              [routerLink]="['/invitation/basic-info', 'new-application']"
            >
              Change
            </a>
          </div>
        </dd>
      </div>
    </dl>
    <div class="nhsuk-form-group">
      <legend
        class="nhsuk-fieldset__legend nhsuk-fieldset__legend--m nhsuk-u-margin-top-6"
      ></legend>
      <label class="nhsuk-label" for="paa-input">Notes (optional)</label>
      <div class="nhsuk-hint">
        Applicants cannot see these notes. Include notes that may be useful for
        you and your team, they will be displayed in the application details
        page.
      </div>
      <text-area-input
        #paaInput
        id="paa-input"
        [cypressValue]="'paa-textarea'"
        [value]="invitation.paa"
        [errorMessage]="paaErrorMessage"
        (questionAnswer)="updatePaaAnswer($event)"
      >
      </text-area-input>
    </div>
    <div class="nhsuk-form-group">
      <ul>
        <li class="nhsuk-error-message" *ngFor="let err of validationErrors">
          {{ err.msg }}
        </li>
      </ul>
      <span
        class="nhsuk-error-message"
        data-cy="submit-error-message"
        *ngIf="detailsError"
        >{{ detailsError }}</span
      >

      <button
        data-cy="final-submit-button"
        type="submit"
        [ngClass]="{ 'spinner-button': submitButtonClicked }"
        [disabled]="submitButtonClicked || !contactEmailValid"
        (click)="onSubmitButtonClicked()"
        class="nhsuk-button"
      >
        Submit
      </button>
    </div>
  </div>
</div>
