import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "app-global-help",
  templateUrl: "./global-help.component.html",
})
export class GlobalHelpComponent implements OnInit {
  constructor(private title: Title) {}

  ngOnInit(): void {
    this.title.setTitle("Help");
  }
}
