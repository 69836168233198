<div class="nhsuk-grid-row">
  <div class="nhsuk-grid-column-two-thirds">
    <h1 autofocus tabindex="-1">Apply for Care ID</h1>
    <p>
      Apply for Care ID is a secure online ID verification service for NHS users
      to submit and verify their documents online.
    </p>

    <p>
      Once their identity has been verified, a profile will be created for them
      in Care Identity Service (CIS).
    </p>

    <p>Use the service to:</p>
    <ul>
      <li>enter applicant details to invite them to apply</li>
      <li>update Care ID applications you have submitted</li>
      <li>check the progress of Care ID applications</li>
    </ul>

    <a
      id="start-button"
      href="/authenticate"
      class="nhsuk-button nhsuk-u-margin-bottom-7"
    >
      Start
    </a>
    <h2>Before you start</h2>
    <p>
      You will need Windows Hello, a Security Key or smartcard with one of these
      roles assigned to use Apply for Care ID:
    </p>
    <ul>
      <li>R5080: Registration Authority manager</li>
      <li>R5090: Registration Authority agent</li>
      <li>B1300: Sponsor</li>
    </ul>
    <p>
      For support using Apply for Care ID
      <a
        target="_blank"
        rel="noopener"
        href="https://digital.nhs.uk/services/identity-and-access-management/nhs-care-identity-service-2/apply-for-care-id/get-help-if-youre-already-using-apply-for-care-id"
        >view the Apply for Care ID guidance</a
      >.
    </p>
  </div>
</div>
