import { Injectable } from "@angular/core";
import { HttpClient, HttpParams, HttpResponse } from "@angular/common/http";
import { EnvironmentConfigService } from "./environment-config.service";
import { InvitationVerificationDetails } from "../models/invitation-verification-details";
import { Observable } from "rxjs";
import { InvitationVerificationResponse } from "../models/invitation-verification-response";

@Injectable()
export class VerifyInvitationService {
  constructor(
    private http: HttpClient,
    private environmentConfigService: EnvironmentConfigService,
  ) {}

  postInvitationVerification(
    invitation_id: string,
  ): Observable<HttpResponse<InvitationVerificationResponse>> {
    const authcodeDetails = this.mapInvitationVerficationDetails(invitation_id);

    return this.http.post<HttpResponse<InvitationVerificationResponse>>(
      `${
        this.environmentConfigService.getConfig().idBridgeApiUrl
      }/invitation/verification`,
      authcodeDetails,
      { observe: "body", responseType: "json" },
    );
  }

  postOtp(
    invitation_id: any,
  ): Observable<HttpResponse<InvitationVerificationResponse>> {
    const otpDetails = this.mapInvitationVerficationDetails(invitation_id);

    return this.http.post<HttpResponse<InvitationVerificationResponse>>(
      `${
        this.environmentConfigService.getConfig().idBridgeApiUrl
      }/invitation/otp`,
      otpDetails,
      { observe: "body", responseType: "json" },
    );
  }

  getOtp(
    invitation_id: any,
    otp_number: any,
  ): Observable<HttpResponse<InvitationVerificationResponse>> {
    const params = new HttpParams()
      .set("invitation_id", invitation_id)
      .set("otp_number", otp_number);

    return this.http.get<HttpResponse<InvitationVerificationResponse>>(
      `${
        this.environmentConfigService.getConfig().idBridgeApiUrl
      }/invitation/otp`,
      { observe: "body", responseType: "json", params, withCredentials: true },
    );
  }

  private mapInvitationVerficationDetails(
    invitation_id: string,
  ): InvitationVerificationDetails {
    return {
      invitation_id,
    };
  }
}
