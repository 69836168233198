import { Component, Inject, OnInit } from "@angular/core";
import { RouterService } from "src/app/services/router.service";
import { VerifyInvitationService } from "../../../services/verify-invitation.service";
import { SESSION_STORAGE, StorageService } from "ngx-webstorage-service";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "app-send-otp",
  templateUrl: "./send-otp.component.html",
})
export class SendOtpComponent implements OnInit {
  mobile: string;
  breadcrumbRoute = "/invitee/start";
  breadcrumbText = "Back";
  otpStatus: string;
  requestError = false;
  supportEmail: string;
  buttonClicked: boolean;

  constructor(
    private inviteVerificationService: VerifyInvitationService,
    private routerService: RouterService,
    private title: Title,
    @Inject(SESSION_STORAGE) private sessionStorage: StorageService,
  ) {}

  ngOnInit(): void {
    const invitationId = this.sessionStorage.get("invitationId");
    const mobileLast4 = this.sessionStorage.get("mobile");
    this.supportEmail = this.sessionStorage.get("supportEmail");
    if (invitationId == undefined || mobileLast4 == undefined) {
      this.routerService.redirectToInvalidRequest();
    }
    this.mobile = mobileLast4;
    this.title.setTitle("Send a security code to your phone");
  }

  generateOtp(): void {
    this.buttonClicked = true;
    this.requestError = false;

    this.inviteVerificationService
      .postOtp(this.sessionStorage.get("invitationId"))
      .subscribe({
        next: (response) => {
          this.otpStatus = response["status"].toString();
          if (this.otpStatus === "OtpRequestBlocked") {
            this.routerService.redirectToOtpMaxSends();
          } else {
            this.routerService.redirectToOneTimePasscode();
          }
          this.buttonClicked = false;
        },
        error: (err) => {
          if (err.status === 400) {
            this.handle400error(err.error.status);
          } else if (err.status === 500) {
            this.routerService.redirectToOtpFailed();
          }
          this.buttonClicked = false;
        },
      });
  }

  handle400error(status: string): void {
    if (status == "AwaitingDocuments") {
      this.routerService.redirectToAlreadySubmitted();
    } else if (status == "DuplicateInformation") {
      this.routerService.redirectToDupFailed();
    } else {
      this.requestError = true;
    }
  }
}
