import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { UserSettingsService } from "src/app/services/user-settings.service";
import { timer, combineLatest } from "rxjs";
import { UserSettings } from "src/app/models/userSettings";
import { ActivatedRoute } from "@angular/router";
import { RouterService } from "src/app/services/router.service";
import { map } from "rxjs/operators";
import { TextInputComponent } from "../../common/form-fields/text-inputs/text-input.component";
import { ErrorSummaryMessage } from "src/app/models/error-summary-message";
import { isEmailAddressValid } from "src/app/utils/data-validation/data-validation";
import { SESSION_STORAGE, StorageService } from "ngx-webstorage-service";

@Component({
  selector: "app-update-email",
  templateUrl: "./update-email.component.html",
  styleUrls: [],
})
export class UpdateEmailComponent implements OnInit {
  @ViewChild("emailInput") emailInput: TextInputComponent;

  constructor(
    @Inject(SESSION_STORAGE) private sessionStorage: StorageService,
    private routerService: RouterService,
    private title: Title,
    private userSettings: UserSettingsService,
    private route: ActivatedRoute,
  ) {}

  settings: UserSettings;
  category: string;
  email = "";

  emailValid: boolean;
  otherEmail: string;
  otherEmailVerified: boolean;
  loading: boolean = true;
  emailErrorMessage = "";
  summaryErrorMessages = Array<ErrorSummaryMessage>();
  useNewEmail: boolean = false;

  async ngOnInit(): Promise<void> {
    this.setCategory();
    this.title.setTitle("Enter " + this.category + " details");
    await this.getSettings();
  }

  setCategory(): void {
    let category = this.route.snapshot.paramMap.get("category");
    if (category == "contact" || category == "support") {
      this.category = category;
    } else {
      this.routerService.redirectToInternalServerError();
    }
  }

  prefillVerifiedEmail(email: string, emailVerifed: boolean): void {
    if (emailVerifed != null && emailVerifed) {
      this.email = email;
    }
  }

  async getSettings() {
    try {
      let response = await this.userSettings.getSettings();
      this.settings = this.userSettings.mapUserSettings(response.body);
      if (this.category == "support") {
        this.email = this.settings.supportEmail;
        this.otherEmail = this.settings.contactEmail;
        this.otherEmailVerified = this.settings.contactEmailVerified;
      } else {
        this.email = this.settings.contactEmail;
        this.otherEmail = this.settings.supportEmail;
        this.otherEmailVerified = this.settings.supportEmailVerified;
      }
      this.loading = false;
    } catch (exc) {
      this.routerService.handleErrorAuthRoutes(exc.status);
      if (exc.status === 404) {
        this.loading = false;
      }
    }
  }

  async submitChoiceAnswer() {
    if (this.useNewEmail) {
      await this.submitAnswer();
    } else {
      await this.useSameEmail();
    }
  }

  async submitAnswer() {
    this.loading = true;
    if (this.isAnswerValid()) {
      await this.storeSettings();
    } else {
      this.loading = false;
    }
  }

  isAnswerValid(): boolean {
    this.updateEmailAnswer(this.emailInput.value);
    if (this.emailValid) {
      return true;
    } else {
      return false;
    }
  }

  async useSameEmail() {
    let otherEmail = "";
    if (this.category == "support") {
      otherEmail = "use-contact";
    } else {
      otherEmail = "use-support";
    }
    this.loading = true;
    combineLatest([timer(1000), this.userSettings.useSameEmail(otherEmail)])
      .pipe(map((x) => x[1]))
      .subscribe({
        next: (response) => {
          this.loading = false;
          if (response.status == 200) this.routerService.redirectToSettings();
        },
        error: (error) => {
          this.routerService.handleErrorAuthRoutes(error.status);
          if (error.status === 400) {
            this.routerService.redirectToInternalServerError();
          }
        },
      });
  }

  async storeSettings() {
    combineLatest([
      timer(1000),
      this.userSettings.storeSettings(this.email, this.category),
    ])
      .pipe(map((x) => x[1]))
      .subscribe({
        next: (response) => {
          this.loading = false;
          if (response.status == 200)
            this.routerService.redirectToVerifyEmailCategory(this.category);
        },
        error: (error) => {
          if (error.status === 400) {
            this.emailErrorMessage = this.errorMessages.email.domainNotAllowed;
            this.loading = false;
          }
          this.routerService.handleErrorAuthRoutes(error.status);
          if (error.status === 404) {
            this.loading = false;
          }
        },
      });
  }

  readonly errorMessages = {
    email: {
      lengthTooLong: "Email address must be 255 characters or fewer",
      empty: "Enter an email address",
      duplicate: "Enter a different email address",
      domainNotAllowed:
        "This email address is not on the allow list. Enter a different email address",
      unknown:
        "Enter an email address in the correct format, like name@example.com",
    },
  };

  updateEmailAnswer(email: string): void {
    const validationResult = isEmailAddressValid(email);
    if (validationResult.validity) {
      this.emailErrorMessage = "";
      this.emailValid = true;
    } else {
      this.emailErrorMessage =
        this.errorMessages.email[validationResult.errorReason];
      this.emailValid = false;
    }
    this.email = email;
  }

  newEmailSelected(e) {
    if (e.target.checked) this.useNewEmail = true;
  }

  oldEmailSelected(e) {
    if (e.target.checked) this.useNewEmail = false;
  }
}
