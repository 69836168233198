<div class="nhsuk-grid-row">
  <div *ngIf="invitationConfirmation" class="nhsuk-grid-column-two-thirds">
    <error-summary
      [errorSummaryMessages]="summaryErrorMessages"
    ></error-summary>
    <h1 autofocus tabindex="-1" class="nhsuk-heading-xl">
      Enter your full name
    </h1>
    <div class="nhsuk-u-padding-bottom-3"></div>
    <p>The name you enter must be the same as it appears on your photo IDs.</p>
    <p>Your name will be stored on your NHS Care ID profile.</p>
    <div>
      <select-dropdown
        size="nhsuk-u-width-one-quarter"
        #titleInput
        questionIdentifier="title-select"
        question="Title"
        [errorMessage]="titleErrorMessage"
        [answers]="titleAnswers"
        [selectedAnswer]="invitationConfirmation.title"
        (questionAnswer)="updateTitleAnswer($event)"
      ></select-dropdown>
      <text-input
        #firstNameInput
        [id]="'first-name-input'"
        label="Given name"
        [errorMessage]="firstNameErrorMessage"
        [value]="invitationConfirmation.firstName"
        [capitalisation]="true"
        (questionAnswer)="updateFirstNameAnswer($event)"
        cypressValue="first-name-input"
      >
      </text-input>
      <text-input
        #middleNamesInput
        [id]="'middle-names-input'"
        label="Middle name (if you have one)"
        [errorMessage]="middleNamesErrorMessage"
        [value]="invitationConfirmation.middleNames"
        [capitalisation]="true"
        (questionAnswer)="updateMiddleNamesAnswer($event)"
        cypressValue="middle-name-input"
      ></text-input>
      <text-input
        #familyNameInput
        [id]="'family-name-input'"
        label="Family name"
        [errorMessage]="familyNameErrorMessage"
        [value]="invitationConfirmation.familyName"
        [capitalisation]="true"
        (questionAnswer)="updateFamilyNameAnswer($event)"
        cypressValue="family-name-input"
      ></text-input>
    </div>

    <div class="nhsuk-u-padding-bottom-3"></div>
    <button
      class="nhsuk-button nhsuk-u-margin-bottom-5"
      (click)="submitAnswers()"
      id="continue"
      data-cy="continue-button"
    >
      Continue
    </button>

    <p>
      <a href="/invitee/no-continue/names-not-match"
        >My name does not match my photo IDs</a
      >
    </p>
  </div>
</div>
