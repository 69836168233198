<div class="nhsuk-back-link nhsuk-u-margin-top-4 nhsuk-u-margin-bottom-0">
  <breadcrumb-link
    [breadcrumbRoute]="breadcrumbRoute"
    [breadcrumbText]="breadcrumbText"
  ></breadcrumb-link>
</div>
<div class="nhsuk-grid-row">
  <div class="nhsuk-grid-column-three-quarters">
    <h1 autofocus tabindex="-1">Review your photo</h1>

    <div class="container nhsuk-u-width-one-half nhsuk-u-margin-top-4">
      <img
        [src]="imageURL"
        class="nhsuk-image__img"
        alt="Your passport style photo"
      />
    </div>

    <photo-guidelines></photo-guidelines>

    <div class="nhsuk-form-group">
      <fieldset class="nhsuk-fieldset">
        <legend class="nhsuk-fieldset__legend nhsuk-fieldset__legend--m">
          <h2 class="nhsuk-fieldset__heading">
            Does your photo meet the guidelines?
          </h2>
        </legend>

        <p class="grey-text">
          If your photo does not meet the guidelines you may need to complete
          your application again.
        </p>

        <div
          class="nhsuk-radios"
          [ngClass]="{
            'nhsuk-form-group--error': photoMeetsGuidelinesErrorMessage
          }"
        >
          <span
            role="alert"
            *ngIf="photoMeetsGuidelinesErrorMessage"
            class="nhsuk-error-message"
            data-cy="error-message-delivery-address"
            >{{ photoMeetsGuidelinesErrorMessage }}</span
          >
          <div class="nhsuk-radios__item">
            <input
              class="nhsuk-radios__input"
              id="profile-yes"
              name="profile"
              type="radio"
              data-cy="profile-yes"
              value="yes"
              (change)="updatePhotoMeetsGuidelinesAnswer(true)"
            />
            <label class="nhsuk-label nhsuk-radios__label" for="profile-yes">
              Yes, this photo meets guidelines
            </label>
          </div>

          <div class="nhsuk-radios__item">
            <input
              class="nhsuk-radios__input"
              id="profile-no"
              name="profile"
              type="radio"
              data-cy="profile-no"
              value="no"
              (change)="updatePhotoMeetsGuidelinesAnswer(false)"
            />
            <label class="nhsuk-label nhsuk-radios__label" for="profile-no">
              No, upload a different photo
            </label>
          </div>
        </div>
      </fieldset>
    </div>

    <button
      (click)="submitAnswer()"
      type="submit"
      class="nhsuk-button"
      data-cy="continue-button"
    >
      Continue
    </button>
  </div>
</div>
