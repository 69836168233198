import { Component, Inject, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { SESSION_STORAGE, StorageService } from "ngx-webstorage-service";

@Component({
  selector: "app-service-manager-portal",
  templateUrl: "./service-manager-portal.component.html",
  styleUrls: ["./service-manager-portal.component.css"],
})
export class ServiceManagerPortalComponent implements OnInit {
  constructor(
    @Inject(SESSION_STORAGE) private sessionStorage: StorageService,
    private title: Title,
  ) {}

  isServiceManager: boolean = false;

  ngOnInit(): void {
    this.title.setTitle("Service manager portal");
    this.isServiceManager =
      this.sessionStorage.get("sessionToken") &&
      this.sessionStorage.get("sessionToken").portal == 2;
  }
}
