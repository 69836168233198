<div class="modal" *ngIf="warningDisplayed">
  <div class="nhsuk-card timeout">
    <div class="nhsuk-card__content">
      <h3 class="nhsuk-card__heading">You are about to be logged out</h3>

      <p class="nhsuk-card__description">
        To keep the system and data secure, you will be logged out in
        <strong>{{ countdown }} seconds</strong>.
      </p>
      <button
        id="stay-logged-in-submit"
        tabindex="1"
        type="submit"
        [ngClass]="{ 'spinner-button': submitButtonClicked }"
        [disabled]="buttonClicked"
        (click)="onStayLoggedInClicked()"
        class="nhsuk-button"
      >
        Stay logged in
      </button>
      <p>
        <a
          class="nhsuk-link nhsuk-card--clickable"
          tabindex="2"
          (click)="onLogOutClicked()"
          >Log out now</a
        >
      </p>
    </div>
  </div>
</div>
