export interface UserSettingsDetails {
  support_email: string;
  support_email_verified: boolean;
  contact_email: string;
  contact_email_verified: boolean;
}

export class UserSettings {
  supportEmail: string;
  supportEmailVerified: boolean;
  contactEmail: string;
  contactEmailVerified: boolean;

  constructor(settings: UserSettingsDetails) {
    this.supportEmail = settings.support_email;
    this.supportEmailVerified = settings.support_email_verified;
    this.contactEmail = settings.contact_email;
    this.contactEmailVerified = settings.contact_email_verified;
  }
}
