import { Component, Input } from "@angular/core";

@Component({
  selector: "email-support",
  templateUrl: "./email-support.component.html",
})
export class EmailSupportComponent {
  @Input() invitationId: string;
  @Input() subjectLine: string;
  @Input() supportEmail: string;
  @Input() description: string;
  @Input() nextStep = " continue.";
}
