import { Component, OnInit, Inject } from "@angular/core";
import { SESSION_STORAGE, StorageService } from "ngx-webstorage-service";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "app-download-app",
  templateUrl: "./download-app.component.html",
})
export class DownloadAppComponent implements OnInit {
  emailFirstCharacter: string;
  emailDomain: string;
  isMobile: boolean;
  mobile: string;
  docsRequired: string;

  constructor(
    private title: Title,
    @Inject(SESSION_STORAGE) private sessionStorage: StorageService,
  ) {
    this.title.setTitle("Download the NHS Verify Care ID app");
  }

  ngOnInit(): void {
    this.displayMobile();
    this.getDocsRequired();
  }

  getDocsRequired(): void {
    this.docsRequired = this.sessionStorage.get("docsRequired");
  }

  displayMobile(): void {
    this.mobile = this.sessionStorage.get("mobile");
    if (this.mobile !== undefined) {
      this.mobile = this.mobile.substring(
        this.mobile.length - 4,
        this.mobile.length,
      );
    }
  }
}

declare global {
  interface Window {
    opera: any;
  }
}
