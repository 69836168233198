import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { EnvironmentConfigService } from "./environment-config.service";
import { AuthcodeDetails } from "../models/authcodeDetails";

@Injectable()
export class SendAuthcodeService {
  constructor(
    private http: HttpClient,
    private environmentConfigService: EnvironmentConfigService,
  ) {}

  postAuthcode(
    authcode: string,
    state: string,
    preauthId: string,
    changeRoleUuid: string,
    changeRoleSessionId: string,
  ): Promise<any> {
    const authDetails = this.mapAuthcodeDetails(
      authcode,
      state,
      preauthId,
      changeRoleUuid,
      changeRoleSessionId,
    );

    return new Promise((resolve, reject) => {
      this.http
        .post(
          `${
            this.environmentConfigService.getConfig().idBridgeApiUrl
          }/auth/authcode`,
          authDetails,
          { observe: "body", responseType: "json", withCredentials: true },
        )
        .subscribe(
          (res) => {
            resolve(res);
          },
          (error) => {
            reject(error);
          },
        );
    });
  }

  private mapAuthcodeDetails(
    authcode: string,
    state: string,
    preauthId: string,
    changeRoleUuid: string,
    changeRoleSessionId: string,
  ): AuthcodeDetails {
    return {
      authcode: authcode,
      state: state,
      preauth_id: preauthId,
      change_role_uuid: changeRoleUuid,
      change_role_session_id: changeRoleSessionId,
    };
  }
}
