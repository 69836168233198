import {
  CancelledReason,
  IdCheckFailureReason,
} from "src/app/models/invitationDetails";
import { InvitationIdCheck } from "src/store/states/spa.state";

export function removeSpecialChars(e): boolean {
  const regex = new RegExp(/^[ ,0-9a-zA-Z-'@.?!]+$/);
  if (regex.test(e.key)) {
    return true;
  }

  e.preventDefault();
  return false;
}

export function idCheckSortChanged(
  arrayToSort: InvitationIdCheck[],
  columnName: string,
): InvitationIdCheck[] {
  switch (columnName) {
    case "givenName":
      arrayToSort.sort((a, b) => (a.givenName > b.givenName ? 1 : -1));
      break;
    case "createdByUserOrgCode":
      arrayToSort.sort((a, b) =>
        a.createdByUserOrgCode > b.createdByUserOrgCode ? 1 : -1,
      );
      break;
    case "submittedIdCheckUtc":
      arrayToSort.sort((a, b) =>
        a.submittedIdCheckUtc > b.submittedIdCheckUtc ? 1 : -1,
      );
      break;
    case "submittedIdCheckUtcNewest":
      arrayToSort.sort((a, b) =>
        a.submittedIdCheckUtc < b.submittedIdCheckUtc ? 1 : -1,
      );
      break;
    case "idCheckedUtc":
      arrayToSort.sort((a, b) => (a.idCheckedUtc > b.idCheckedUtc ? 1 : -1));
      break;
    case "idCheckedUtcNewest":
      arrayToSort.sort((a, b) => (a.idCheckedUtc < b.idCheckedUtc ? 1 : -1));
      break;
    case "idCheckedBy":
      arrayToSort.sort((a, b) => (a.idCheckedBy > b.idCheckedBy ? 1 : -1));
      break;
    case "reviewBy":
      arrayToSort.sort((a, b) =>
        a.reviewByDisplay > b.reviewByDisplay ? 1 : -1,
      );
      break;
  }

  return arrayToSort;
}

export function getFailureReasonDescription(
  value: number,
  isPoa: boolean,
): string {
  if (value == IdCheckFailureReason.Expired) {
    if (isPoa) {
      return " is out of date,";
    } else {
      return " has expired,";
    }
  }

  const descriptionLookup = {
    1: " is a duplicate (applicant has submitted the same document more than once),",
    3: " could not be read (this may be because the image is blurry or handwritten),",
    4: " is missing information,",
    5: " is under investigation,",
    6: " is not accepted,",
    7: " does not match photo ID(s),",
    8: " does not meet guidelines,",
    9: " could not be verified,",
    10: "Application auto-rejected too many times. Photo ID could not be read (this may be because the image is blurry or photo ID is not accepted),",
    11: " address does not meet requirements (this may be because the address could not be matched to enough activity from organisations like banks and utility providers),",
    12: " smoke test - internal use only,",
    13: " does not show a live person (this may be because the applicant has taken a photo of a photo),",
    14: " do not match,",
    15: " is too blurry,",
    16: " is not a photo of an original document,",
    17: " does not have a plain background,",
    18: " does not show the applicant facing forwards,",
    19: " is a photo of an ID document,",
    20: " appears to have been altered by filters,",
    21: " does not show both sides of the ID document,",
  };

  return descriptionLookup[`${value}`];
}

export function scrollToTop(): void {
  document.querySelector("#top").scrollIntoView({ behavior: "smooth" });
}

export const cancelReasonList = [
  ...Object.keys(CancelledReason)
    .filter((item) => {
      // Getting numeric enum keys and filtering out old unused reasons and "Other"
      return !isNaN(Number(item)) && ![0, 1, 2, 3].includes(Number(item));
    })
    .map((key) => {
      return { value: key, label: CancelledReason[key] };
    }),
  { value: "3", label: CancelledReason["3"] },
];
