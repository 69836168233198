import { Component, Inject, OnInit } from "@angular/core";
import { DatePipe, Location } from "@angular/common";
import { StorageMap } from "@ngx-pwa/local-storage";
import { ActivatedRoute } from "@angular/router";
import { RouterService } from "src/app/services/router.service";
import { Invitation } from "src/store/states/spa.state";
import { Title } from "@angular/platform-browser";
import { InvitationStatus } from "src/app/models/invitationDetails";
import { GetInvitationService } from "src/app/services/get-invitation.service";
import { DashboardCheckbox } from "src/app/models/dashboardCheckbox";
import { ApplicationStepStatus } from "src/app/models/applicationStepStatus";
import { SESSION_STORAGE, StorageService } from "ngx-webstorage-service";
import { SendInvitationService } from "src/app/services/send-invitation.service";

@Component({
  selector: "app-invite-details",
  templateUrl: "./invite-details.component.html",
  styleUrls: ["./invite-details.component.css"],
})
export class InviteDetailsComponent implements OnInit {
  loading: boolean = true;
  createdDateUtc: string;
  statusLastUpdatedDateUtc: string;
  idCheckedUtc?: string;
  completedDateUtc?: string;
  invitation: Invitation;
  invitationId: string;
  errorMessage: string;
  canResendThresholdStatus = InvitationStatus["Duplicate information"];
  canCancelThresholdStatus = InvitationStatus["Locked"];
  currentPage: number = 1;
  filteredInvitations: Invitation[];
  allInvitations: Invitation[];
  searchTerm = "";
  odsSearchTerm = "";
  reviewedBySearchTerm = "";
  statuses: DashboardCheckbox[];
  senderOnly: boolean;
  prevPage: "";
  tabSelected: "";
  isServiceManager: boolean = false;
  displayMarkDoneOptions: boolean = false;
  processingMarkAsDone: boolean = false;

  mobileAppReminders: number = null;

  enterPersonalDetailsStatus: ApplicationStepStatus =
    ApplicationStepStatus["Not Started"];
  submitIdDocsStatus: ApplicationStepStatus =
    ApplicationStepStatus["Not Started"];
  reviewByIdCheckerStatus: ApplicationStepStatus =
    ApplicationStepStatus["Not Started"];
  applicationMessage: string;

  constructor(
    private location: Location,
    private storage: StorageMap,
    private route: ActivatedRoute,
    private title: Title,
    private getInvitationService: GetInvitationService,
    private sendInvitationService: SendInvitationService,
    private routerService: RouterService,
    private datePipe: DatePipe,
    @Inject(SESSION_STORAGE) private sessionStorage: StorageService,
  ) {}

  ngOnInit(): void {
    this.invitationId = this.route.snapshot.paramMap.get("invitationId");
    this.currentPage = this.location.getState()["currentPage"];
    this.filteredInvitations = this.location.getState()["filteredInvitations"];
    this.allInvitations = this.location.getState()["allInvitations"];
    this.statuses = this.location.getState()["statuses"];
    this.searchTerm = this.location.getState()["searchTerm"];
    this.odsSearchTerm = this.location.getState()["odsSearchTerm"];
    this.senderOnly = this.location.getState()["senderOnly"];
    this.prevPage = this.location.getState()["prevPage"];
    this.tabSelected = this.location.getState()["tabSelected"];
    this.reviewedBySearchTerm =
      this.location.getState()["reviewedBySearchTerm"];
    this.getInvitationDetails();
    this.setIsServiceManager();
  }

  setIsServiceManager(): void {
    this.isServiceManager =
      this.sessionStorage.get("sessionToken") &&
      this.sessionStorage.get("sessionToken").portal == 2;
  }

  getInvitationDetails(): void {
    this.getInvitationService.getInvitation(this.invitationId).subscribe({
      next: (response) => {
        if (response) {
          this.invitation = new Invitation(response["body"]);
          this.storage.set("invitation", this.invitation).subscribe(() => {});
          this.setUpStrings();
          this.setApplicationStepStatuses();
          this.calculateMobileAppReminders();
          this.setDisplayMarkDoneOptions();
        }
        this.loading = false;
      },
      error: (error) => {
        this.routerService.handleErrorAuthRoutes(error.status);
        if (error.status === 400) {
          this.errorMessage = "Application " + this.invitationId + " not found";
        }
        this.loading = false;
      },
    });
  }

  formatDate(date: string): string {
    return (
      this.datePipe.transform(date, "d MMMM yyyy h:mm") +
      this.datePipe.transform(date, "a").toLocaleLowerCase()
    );
  }

  calculateMobileAppReminders(): void {
    if (this.invitation.status != InvitationStatus["Awaiting documents"]) {
      return;
    }
    let lastUpdatedUtc = this.invitation.statusLastUpdatedDateUtc;
    let currentDateTimeUtc = new Date().toUTCString();
    let timeInCurrentStatus =
      (Date.parse(currentDateTimeUtc) - Date.parse(lastUpdatedUtc)) /
      1000 /
      3600 /
      24;

    if (timeInCurrentStatus >= 7) {
      this.mobileAppReminders = 2;
    } else if (timeInCurrentStatus >= 2) {
      this.mobileAppReminders = 1;
    } else {
      this.mobileAppReminders = 0;
    }
  }

  setUpStrings(): void {
    this.title.setTitle("Application details");
    this.createdDateUtc = this.formatDate(this.invitation.createdDateUtc);
    this.statusLastUpdatedDateUtc = this.formatDate(
      this.invitation.statusLastUpdatedDateUtc,
    );
    if (this.invitation.idCheckedUtc !== null) {
      this.idCheckedUtc = this.formatDate(this.invitation.idCheckedUtc);
    }
    if (this.invitation.completedDateUtc !== null) {
      this.completedDateUtc = this.formatDate(this.invitation.completedDateUtc);
    }
  }

  setApplicationStepStatuses(): void {
    let status = this.invitation.status;
    if (status == InvitationStatus["Duplicate check in progress"]) {
      this.enterPersonalDetailsStatus = ApplicationStepStatus["In Progress"];
    }
    if (status == InvitationStatus["Awaiting documents"]) {
      this.enterPersonalDetailsStatus = ApplicationStepStatus.Completed;
      this.submitIdDocsStatus = ApplicationStepStatus["In Progress"];
    }
    if (
      status == InvitationStatus["ID review"] ||
      status == InvitationStatus["On hold"]
    ) {
      this.enterPersonalDetailsStatus = ApplicationStepStatus.Completed;
      this.submitIdDocsStatus = ApplicationStepStatus.Completed;
      this.reviewByIdCheckerStatus = ApplicationStepStatus["In Progress"];
    }
    if (status == InvitationStatus.Approved) {
      this.enterPersonalDetailsStatus = ApplicationStepStatus.Completed;
      this.submitIdDocsStatus = ApplicationStepStatus.Completed;
      this.reviewByIdCheckerStatus = ApplicationStepStatus.Completed;
    }
    if (status == InvitationStatus["Duplicate information"]) {
      this.enterPersonalDetailsStatus = ApplicationStepStatus.Completed;
      this.submitIdDocsStatus = ApplicationStepStatus["Not applicable"];
      this.reviewByIdCheckerStatus = ApplicationStepStatus["Not applicable"];
    }
    if (status == InvitationStatus.Rejected) {
      this.enterPersonalDetailsStatus = ApplicationStepStatus.Completed;
      this.submitIdDocsStatus = ApplicationStepStatus.Completed;
      this.reviewByIdCheckerStatus = ApplicationStepStatus.Completed;

      if (
        this.invitation.failedNotes &&
        this.invitation.failedNotes == "Exceeded Maximum Attempts"
      ) {
        this.applicationMessage = "(Auto-rejected too many times)";
        this.reviewByIdCheckerStatus = ApplicationStepStatus["Not applicable"];
      }
    }
    if (status == InvitationStatus.OtpExpired) {
      this.enterPersonalDetailsStatus = ApplicationStepStatus["In Progress"];
      this.applicationMessage = "(Security code expired)";
    }
    if (status == InvitationStatus.Locked) {
      this.enterPersonalDetailsStatus = ApplicationStepStatus["In Progress"];
      this.applicationMessage = "(Security code issue)";
    }
    if (status == InvitationStatus["Invite resend required"]) {
      this.enterPersonalDetailsStatus = ApplicationStepStatus["Not Started"];
      this.submitIdDocsStatus = ApplicationStepStatus["Not Started"];
      this.reviewByIdCheckerStatus = ApplicationStepStatus["Not Started"];
      this.applicationMessage = "(Invite email expired)";
    }

    if (status == InvitationStatus["Application expired"]) {
      this.enterPersonalDetailsStatus = ApplicationStepStatus.Completed;
      this.submitIdDocsStatus = ApplicationStepStatus["Not Started"];
      this.reviewByIdCheckerStatus = ApplicationStepStatus["Not Started"];
      this.applicationMessage = "(Application Expired)";
    }

    if (status == InvitationStatus["Invite email not sent"]) {
      this.enterPersonalDetailsStatus = ApplicationStepStatus["Not Started"];
      this.submitIdDocsStatus = ApplicationStepStatus["Not Started"];
      this.reviewByIdCheckerStatus = ApplicationStepStatus["Not Started"];
      this.applicationMessage = "(Invite email not sent)";
    }
  }

  async markDone(doneFlag: boolean): Promise<void> {
    this.processingMarkAsDone = true;
    this.errorMessage = null;
    await this.sendInvitationService
      .markAsDoneInvitations([this.invitation.invitationId], doneFlag)
      .then(() => {
        this.processingMarkAsDone = false;
        if (doneFlag) {
          this.invitation.done = true;
        } else {
          this.invitation.done = undefined;
        }
      })
      .catch((exc) => {
        this.routerService.handleErrorAuthRoutes(exc.status);
        if (exc.status == 400) {
          this.errorMessage = exc.error;
        } else {
          this.errorMessage = "Failed to mark application";
        }
        this.processingMarkAsDone = false;
      });
  }

  setDisplayMarkDoneOptions(): void {
    if (
      this.invitation.status == InvitationStatus.Approved ||
      this.invitation.status == InvitationStatus.Rejected ||
      this.invitation.status == InvitationStatus["Application expired"] ||
      this.invitation.status == InvitationStatus["Duplicate information"]
    ) {
      this.displayMarkDoneOptions = true;
    }
  }
}
