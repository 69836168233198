import { Component, Inject, OnInit } from "@angular/core";
import { RouterService } from "src/app/services/router.service";
import { CancelInvitationService } from "src/app/services/cancel-invitation.service";
import { Invitation } from "src/store/states/spa.state";
import { Title } from "@angular/platform-browser";
import { SESSION_STORAGE, StorageService } from "ngx-webstorage-service";
import { cancelReasonList } from "src/app/utils/common/common";
import { ErrorSummaryMessage } from "src/app/models/error-summary-message";

@Component({
  selector: "app-cancel-invite",
  templateUrl: "./cancel-multiple.component.html",
})
export class CancelMultipleComponent implements OnInit {
  breadcrumbRoute = "/invitation/dashboard";
  breadcrumbText = "Back";
  invitation: Invitation;
  invitationId: string;
  forwardRoute = "/invitation/invite-cancelled";
  selectedReasonId: number;
  submitButtonClicked = false;
  cancelList = [];
  readonly reasonList = cancelReasonList;
  otherReason: string;
  summaryErrorMessages = Array<ErrorSummaryMessage>();
  errorMessage: string;
  dropdownErrorMessage: string;
  textErrorMessage: string;

  constructor(
    private routerService: RouterService,
    private cancelInvitationService: CancelInvitationService,
    private title: Title,
    @Inject(SESSION_STORAGE) private sessionStorage: StorageService,
  ) {}

  ngOnInit(): void {
    this.cancelList = this.sessionStorage.get("cancelList");
    if (this.cancelList == null) {
      this.routerService.redirectToInvitationDashboard();
    }
    this.title.setTitle("Select cancel application reason");
  }

  updateReasonAnswer(reasonNumber: number): void {
    this.errorMessage = "";
    this.dropdownErrorMessage = "";
    this.textErrorMessage = "";
    this.selectedReasonId = reasonNumber;
  }

  onCancelInvitationButtonClicked(): void {
    this.errorMessage = "";
    this.dropdownErrorMessage = "";
    this.textErrorMessage = "";
    this.summaryErrorMessages = [];

    if (this.selectedReasonId == undefined) {
      this.summaryErrorMessages.push({
        message: "Select a reason from the options below",
        id: "reason-select-error-description",
      });
      this.dropdownErrorMessage = "Select a reason from the options below";
      return;
    }

    if (this.selectedReasonId == 3 && !this.otherReason) {
      this.summaryErrorMessages.push({
        message: "Please specify a reason for cancelling the application",
        id: "other-reason-notes-error-description",
      });
      this.textErrorMessage =
        "Please specify a reason for cancelling the application";
      return;
    }

    this.submitButtonClicked = true;
    this.cancelInvitationService
      .cancelMultipleInvitations(
        this.cancelList,
        this.selectedReasonId,
        this.otherReason,
      )
      .subscribe({
        next: (response) => {
          const errs = response["body"];
          if (errs.length > 0) {
            this.displayFailures(errs);
          } else {
            this.sessionStorage.remove("cancelList");
            this.routerService.redirectToInviteCancelled("multi");
          }
          this.submitButtonClicked = false;
        },
        error: (err) => {
          this.routerService.handleErrorAuthRoutes(err.status);
          this.summaryErrorMessages.push({
            message: "An error was encountered please retry",
            id: "cancel-button",
          });
          this.errorMessage = "An error was encountered please retry";
          this.summaryErrorMessages.push({
            id: "reason-select",
            message: this.errorMessage,
          });
          this.submitButtonClicked = false;
        },
      });
  }

  displayFailures(items: string[]) {
    let msg = "";
    items.forEach((item) => {
      msg += item + "<br />";
    });
    this.errorMessage = msg;
  }
}
