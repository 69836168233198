import {
  initialState,
  IdCheckerRejectionSPAState,
} from "../states/id-checker-spa.state";
import * as IdCheckerSPAActions from "../actions/id-checker-spa.actions";

/* istanbul ignore next */
export function reducerIdCheckerSPA(
  state = initialState,
  action: IdCheckerSPAActions.UpdateIdCheckerRejectionAction,
): IdCheckerRejectionSPAState {
  switch (action.type) {
    case IdCheckerSPAActions.UPDATE_ID_CHECKER_REJECTION: {
      return {
        ...state,
        rejection: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}
