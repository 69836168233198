<session-check></session-check>
<div class="nhsuk-grid-row">
  <div class="nhsuk-grid-column-two-thirds">
    <breadcrumb-link
      [breadcrumbRoute]="breadcrumbRoute"
      [breadcrumbText]="breadcrumbText"
    ></breadcrumb-link>
    <fieldset class="nhsuk-fieldset">
      <legend class="nhsuk-fieldset__legend nhsuk-fieldset__legend--xl">
        <h1 autofocus tabindex="-1" class="nhsuk-fieldset__heading">
          Resend original app link
        </h1>
      </legend>
      <div class="nhsuk-warning-callout">
        <h2 class="nhsuk-warning-callout__label">
          Important<span class="nhsuk-u-visually-hidden">:</span>
        </h2>
        <p>
          This should ONLY be internal Service Management or ID Checkers, if an
          applicant did not receive the app download link. This can only be used
          for applications in the Awaiting Documents status.
        </p>
      </div>
      <div class="nhsuk-hint">
        The service is used to internally resend the SMS download link if the
        first message was not received.
      </div>
      <div class="nhsuk-form-group">
        <label class="nhsuk-label" for="invitation-id">Invitation Id</label>
        <input
          #invitationIdInput
          type="text"
          id="invitation-id"
          autocomplete="off"
          class="nhsuk-input nhsuk-u-width-three-quarters"
          [value]="invitationId"
          (change)="updateValue($event.target.value)"
          data-cy="invitation-id-input"
        />
      </div>
    </fieldset>
    <div *ngIf="errorMessage">
      <div
        class="nhsuk-error-summary"
        aria-labelledby="error-summary-title"
        role="alert"
        tabindex="-1"
      >
        <h3 class="nhsuk-error-summary__title" id="error-summary-title">
          Sorry, there is a problem.
        </h3>
        <div
          class="nhsuk-error-summary__body"
          data-cy="approval-retry-error-message"
        >
          {{ errorMessage }}
        </div>
      </div>
    </div>
    <button
      [disabled]="submitButtonClicked"
      class="nhsuk-button nhsuk-u-margin-bottom-5"
      (click)="onSubmitButtonClicked()"
      data-cy="approval-retry-submit-button"
    >
      Resend SMS Download Link
    </button>

    <div class="nhsuk-grid-row">
      <section *ngIf="submitButtonClicked">
        <progress-indicator
          spinnerText="Resending SMS Download Link"
        ></progress-indicator>
      </section>
    </div>
    <div
      class="nhsuk-alert nhsuk-alert--success nhsuk-alert--icon"
      *ngIf="resendSuccessMessage"
    >
      <svg
        class="nhsuk-icon nhsuk-icon__tick"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        aria-hidden="true"
      >
        <path
          fill="none"
          stroke="#000"
          stroke-width="4"
          d="M17.4 7.8l-8.1 8.2-4.1-4.1"
        ></path>
      </svg>
      <h1 class="nhsuk-u-visually-hidden">Success:</h1>
      <p
        class="nhsuk-u-font-weight-bold"
        data-cy="ems2-case-approval-retry-feedback"
      >
        {{ resendSuccessMessage }}
      </p>
    </div>
  </div>
</div>
