import { HttpHeaders, HttpClient } from "@angular/common/http";
import { Injectable, Inject } from "@angular/core";
import { SESSION_STORAGE, StorageService } from "ngx-webstorage-service";
import { SessionToken, Portal } from "../models/session-token";
import { EnvironmentConfigService } from "./environment-config.service";
import { CookieService } from "ngx-cookie-service";

@Injectable()
export class SessionAuthenticationService {
  constructor(
    @Inject(SESSION_STORAGE) private sessionStorage: StorageService,
    private http: HttpClient,
    private environmentConfigService: EnvironmentConfigService,
    private cookieService: CookieService,
  ) {}

  hasSessionToken(): boolean {
    return this.sessionStorage.has("sessionToken");
  }

  hasValidSessionToken(): boolean {
    if (this.hasSessionToken()) {
      const sessionToken = this.sessionStorage.get("sessionToken");
      return this.isSessionTokenValid(sessionToken);
    } else {
      return false;
    }
  }

  currentSessionToken(): SessionToken {
    return this.sessionStorage.get("sessionToken");
  }

  isSessionTokenValid(sessionToken: SessionToken): boolean {
    if (Date.now() < Date.parse(sessionToken.expiry_date_utc)) {
      return true;
    } else {
      return false;
    }
  }

  applyAuthorizationHeader(portal?: Portal): HttpHeaders {
    if (this.hasValidSessionToken()) {
      let tokenName =
        "fastapi-csrf-token-" +
        this.sessionStorage.get("sessionToken").session_id;

      let portalId = "";
      if (portal == undefined) {
        portalId = this.sessionStorage.get("sessionToken").portal;
      } else {
        portalId = portal.toString();
      }
      return new HttpHeaders({
        Authorization:
          "Bearer " + this.sessionStorage.get("sessionToken").session_id,
        Portal: portalId,
        "X-CSRF-Token": this.cookieService.get(tokenName),
      });
    }
    return new HttpHeaders();
  }

  applyCsrfHeader(): HttpHeaders {
    return new HttpHeaders({
      "X-CSRF-Token": this.cookieService.get("fastapi-csrf-token-invitee"),
    });
  }

  sessionCheck(): Promise<any> {
    const sessionToken = this.sessionStorage.get("sessionToken");

    return new Promise((resolve, reject) => {
      this.http
        .post(
          `${
            this.environmentConfigService.getConfig().idBridgeApiUrl
          }/auth/session_check`,
          sessionToken,
          {
            headers: this.applyAuthorizationHeader(),
            observe: "body",
            responseType: "json",
            withCredentials: true,
          },
        )
        .subscribe(
          (res) => {
            resolve(res);
          },
          (error) => {
            reject(error);
          },
        );
    });
  }

  sessionExtend(): Promise<any> {
    const sessionToken = this.sessionStorage.get("sessionToken");

    return new Promise((resolve, reject) => {
      this.http
        .post(
          `${
            this.environmentConfigService.getConfig().idBridgeApiUrl
          }/auth/session_extend`,
          sessionToken,
          {
            headers: this.applyAuthorizationHeader(),
            observe: "body",
            responseType: "json",
            withCredentials: true,
          },
        )
        .subscribe(
          (res) => {
            resolve(res);
          },
          (error) => {
            reject(error);
          },
        );
    });
  }

  sessionEnd(): Promise<any> {
    const sessionToken = this.sessionStorage.get("sessionToken");

    return new Promise((resolve, reject) => {
      this.http
        .post(
          `${
            this.environmentConfigService.getConfig().idBridgeApiUrl
          }/auth/session_end`,
          sessionToken,
          {
            headers: this.applyAuthorizationHeader(),
            observe: "body",
            responseType: "json",
          },
        )
        .subscribe(
          (res) => {
            resolve(res);
          },
          (error) => {
            reject(error);
          },
        );
    });
  }
}
