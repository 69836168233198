import {
  Component,
  ElementRef,
  Inject,
  ViewChild,
  OnInit,
} from "@angular/core";
import { Title } from "@angular/platform-browser";
import { RouterService } from "src/app/services/router.service";
import { ErrorSummaryMessage } from "../../../models/error-summary-message";
import { InvitationConfirmation } from "src/store/states/invitee-spa.state";
import { SESSION_STORAGE, StorageService } from "ngx-webstorage-service";
import { InviteeDetails } from "src/app/utils/invitee-details";

@Component({
  selector: "app-photo-id-one",
  templateUrl: "./photo-id-one.component.html",
})
export class PhotoIdOneComponent implements OnInit {
  breadcrumbRoute = "/invitee/passport";
  breadcrumbText = "Back";
  invitationConfirmation: InvitationConfirmation;
  @ViewChild("drivingLicence", { static: true }) drivingLicence: ElementRef;
  @ViewChild("residencePermit", { static: true }) residencePermit: ElementRef;
  @ViewChild("armedForces", { static: true }) armedForces: ElementRef;
  @ViewChild("passProofOfAgeCard", { static: true })
  passProofOfAgeCard: ElementRef;
  @ViewChild("none", { static: true }) none: ElementRef;

  constructor(
    private title: Title,
    private routerService: RouterService,
    private inviteeDetails: InviteeDetails,
    @Inject(SESSION_STORAGE) private sessionStorage: StorageService,
  ) {}
  photoIdOne: string = undefined;

  summaryErrorMessages = [];

  async ngOnInit(): Promise<void> {
    this.title.setTitle("Which photo ID do you want to use?");
    this.invitationConfirmation = this.inviteeDetails.getInviteeDetails();
    this.setPreviousValues(this.invitationConfirmation.photoOneId);
  }

  setPreviousValues(value: string): void {
    if (value == "DrivingLicence") {
      this.drivingLicence.nativeElement.click();
    } else if (value == "ResidencePermit") {
      this.residencePermit.nativeElement.click();
    } else if (value == "ArmedForces") {
      this.armedForces.nativeElement.click();
    } else if (value == "PassProofOfAgeCard") {
      this.passProofOfAgeCard.nativeElement.click();
    } else if (value == "None") {
      this.none.nativeElement.click();
    }
  }

  updateErrorSummary() {
    const updatedErrorSummary = Array<ErrorSummaryMessage>();

    if (this.photoIdOne === undefined) {
      updatedErrorSummary.push({
        message: "Select a photo ID.",
        id: "photo-one-select",
      });
    }

    this.summaryErrorMessages = updatedErrorSummary;
  }

  updatePhotoIdOneRadioValue(value) {
    this.photoIdOne = value;
    this.invitationConfirmation.photoOneId = value;
    this.updateErrorSummary();
  }

  onContinueButtonClicked(): void {
    this.updateErrorSummary();
    if (this.photoIdOne != undefined) {
      this.inviteeDetails.setInviteeDetails(this.invitationConfirmation);
      if (this.photoIdOne == "None") {
        this.routerService.redirectToNoPhotoId();
      } else {
        this.routerService.redirectToProfilePhotoUpload();
      }
    }
  }
}
