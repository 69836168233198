<div class="nhsuk-grid-row">
  <div class="nhsuk-grid-column-two-thirds">
    <breadcrumb-link
      [breadcrumbRoute]="breadcrumbRoute"
      [breadcrumbText]="breadcrumbText"
    ></breadcrumb-link>

    <div class="nhsuk-form-group">
      <error-summary
        [errorSummaryMessages]="summaryErrorMessages"
      ></error-summary>
      <fieldset class="nhsuk-fieldset">
        <legend
          class="nhsuk-fieldset__legend nhsuk-fieldset__legend--xl nhsuk-u-margin-bottom-7"
          aria-describedby="photo-ids-hint"
        >
          <h1 autofocus tabindex="-1" class="nhsuk-fieldset__heading">
            Which 2 photo IDs do you want to use to verify your identity?
          </h1>
        </legend>

        <div id="top"></div>
        <div *ngIf="validationErrorMessage">
          <div
            class="nhsuk-error-summary"
            aria-labelledby="error-summary-title"
            role="alert"
            tabindex="-1"
          >
            <h2 class="nhsuk-error-summary__title" id="error-summary-title">
              There is a problem
            </h2>
            <div class="nhsuk-error-summary__body">
              <p>
                {{ validationErrorMessage }}
              </p>
            </div>
          </div>
        </div>

        <p>
          You'll need to capture an image of this document when using the mobile
          app.
        </p>

        <p>Passports may be expired for up to 6 months.</p>

        <p>All other photo IDs must not be expired.</p>

        <div class="nhsuk-hint" id="photo-id-two-hint">Select 2 options</div>

        <div class="nhsuk-checkboxes nhsuk-checkboxes--conditional">
          <checkbox-input
            cypressValue="passport"
            #passport
            id="passport"
            checkboxText="UK or international passport"
          >
          </checkbox-input>
          <checkbox-input
            *ngIf="this.invitationConfirmation.poaType != 'DrivingLicence'"
            #drivingLicence
            id="driving-licence"
            cypressValue="driving-licence"
            checkboxText="UK or international driving licence (full or provisional)"
          >
          </checkbox-input>
          <checkbox-input
            #residencePermit
            id="residence-permit"
            cypressValue="residence-permit"
            checkboxText="UK Biometric residence permit card"
          >
          </checkbox-input>

          <checkbox-input
            #armedForces
            cypressValue="armed-forces"
            id="armed-forces"
            checkboxText="HM Armed Forces Card"
          >
          </checkbox-input>

          <checkbox-input
            #passProofOfAgeCard
            cypressValue="pass-proof-of-age-card"
            id="pass-proof-of-age-card"
            checkboxText="PASS proof of age card"
          >
          </checkbox-input>

          <div class="nhsuk-checkboxes__divider">or</div>

          <checkbox-input
            #noDocs
            cypressValue="no-docs"
            id="no-docs"
            exclusive="true"
            checkboxText="I do not have 2 of these photo ID documents"
          >
          </checkbox-input>
        </div>
      </fieldset>
    </div>

    <button
      (click)="onContinueButtonClicked()"
      type="submit"
      class="nhsuk-button"
      data-cy="continue-button"
    >
      Continue
    </button>
  </div>
</div>
