import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import {
  IdCheckHeldReason,
  InvitationStatus,
} from "src/app/models/invitationDetails";
import { SendInvitationService } from "src/app/services/send-invitation.service";
import { Subscription } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import { RouterService } from "src/app/services/router.service";
import { InvitationIdCheck } from "src/store/states/spa.state";
import { Store } from "@ngrx/store";
import { CheckboxComponent } from "../../common/form-fields/checkbox/checkbox.component";
import { TextAreaInputComponent } from "../../common/form-fields/text-inputs/text-area-input/text-area-input.component";
import { SessionAuthenticationService } from "src/app/services/session-authentication.service";
import { Location } from "@angular/common";
import { StorageMap } from "@ngx-pwa/local-storage";
import { Title } from "@angular/platform-browser";
import { isNotesValid } from "src/app/utils/data-validation/data-validation";
import { IdCheckerRejectionSPAState } from "src/store/states/id-checker-spa.state";
import { getIdCheckerRejection } from "src/store/selectors/id-checker-spa.selectors";
import { IdCheckFailure } from "src/app/models/idCheckFailure";
import { ModifiedRecordCheckComponent } from "../../common/modified-record-check/modified-record-check.component";
import { SESSION_STORAGE, StorageService } from "ngx-webstorage-service";

@Component({
  selector: "app-reject-application",
  templateUrl: "./reject-application.component.html",
})
export class RejectApplicationComponent implements OnInit {
  duplicateEvidence: boolean;
  expiredEvidence: boolean;
  illegibleEvidence: boolean;
  incompleteEvidence: boolean;
  suspectedFraud: boolean;
  unacceptableEvidence: boolean;
  failedLivenessLikenessCheck: boolean;
  nonConformantPhoto: boolean;
  other: boolean;
  photoIdOne: string = "Photo ID 1";
  photoIdTwo: string = "Photo ID 2";
  proofOfAddress: string = "Proof of address";
  nameAndDob: string = "Name and date of birth";
  faceScan: string = "Face scan";
  smartcardPhoto: string = "Smartcard photo";
  submitError: string = "";

  failureReasonNotes: string = "";
  failureReasonNotesValid: boolean = true;
  failureReasonNotesErrorMessage = "";
  failureDocumentsErrorMessage = "";
  invitation: InvitationIdCheck;
  invitationId: string;
  currentUser: string;
  currentUserId: string;

  idCheckFailures: IdCheckFailure[];
  validSubmission: boolean = true;
  submitting: boolean = false;

  idCheckFailureSubscription: Subscription;

  @ViewChild("duplicateEvidenceInput")
  duplicateEvidenceInput: CheckboxComponent;
  @ViewChild("expiredEvidenceInput") expiredEvidenceInput: CheckboxComponent;
  @ViewChild("illegibleEvidenceInput")
  illegibleEvidenceInput: CheckboxComponent;
  @ViewChild("incompleteEvidenceInput")
  incompleteEvidenceInput: CheckboxComponent;
  @ViewChild("suspectedFraudInput") suspectedFraudInput: CheckboxComponent;
  @ViewChild("unacceptableEvidenceInput")
  unacceptableEvidenceInput: CheckboxComponent;
  @ViewChild("failedLivenessLikenessCheckInput")
  failedLivenessLikenessCheckInput: CheckboxComponent;
  @ViewChild("nonConformantPhotoInput")
  nonConformantPhotoInput: CheckboxComponent;
  @ViewChild("otherInput")
  otherInput: CheckboxComponent;

  @ViewChild("failureReasonNotesInput")
  failureReasonNotesInput: TextAreaInputComponent;

  @ViewChild("modifiedRecordCheck")
  modifiedRecordCheck: ModifiedRecordCheckComponent;

  readonly errorMessages = {
    failureReason: {
      empty: "Please go back and select failure reasons.",
      lengthTooLong:
        "The Additional information must be no more than 300 characters long.",
      unexpectedCharacters:
        "The Additional information must consist of only letters, numbers and punctuation.",
      unknown:
        "An unknown error occurred, please check the content and try again",
    },
    failures: {
      empty: "Please go back and select failure reasons.",
    },
  };

  constructor(
    private sendInvitationService: SendInvitationService,
    private store: Store<IdCheckerRejectionSPAState>,
    private route: ActivatedRoute,
    private sessionAuthenticationService: SessionAuthenticationService,
    private routerService: RouterService,
    private location: Location,
    @Inject(SESSION_STORAGE) private sessionStorage: StorageService,
    private storage: StorageMap,
    private title: Title,
  ) {}

  ngOnInit(): void {
    this.invitationId = this.route.snapshot.paramMap.get("invitationId");
    this.idCheckFailureSubscription = this.store
      .select(getIdCheckerRejection)
      .subscribe((idCheckFailures) => {
        this.idCheckFailures = Object.assign([], idCheckFailures);
      });

    this.title.setTitle("Select rejection application reasons");
    this.getInvitationState();
    const sessionToken =
      this.sessionAuthenticationService.currentSessionToken();
    this.currentUserId = sessionToken.user_uuid;
    this.currentUser = sessionToken.user_name;
  }

  async submitAnswers(): Promise<void> {
    if (!this.checkFailureReasonsValid()) {
      return;
    }
    this.invitation.idCheckedBy = this.currentUser;
    this.invitation.status = this.isMarkedDuplicate()
      ? InvitationStatus["Duplicate information"]
      : InvitationStatus.Rejected;
    this.invitation.failedReason = this.idCheckFailures;
    this.invitation.failedNotes = this.failureReasonNotes;

    try {
      this.submitting = true;
      await this.sendInvitationService.putIdCheckInvitation(
        this.invitationId,
        this.invitation.idCheckedBy,
        this.invitation.idCheckedByUserId,
        this.invitation.status,
        this.invitation.failedNotes,
        this.invitation.failedReason,
      );
      this.storage.delete("idCheckFailures").subscribe(() => {});
      this.routerService.redirectToCheckerReviewList();
    } catch (err) {
      if (err.status == 400) {
        this.submitError = err.error;
      } else {
        this.routerService.redirectToInternalServerError();
      }
    } finally {
      this.submitting = false;
    }
  }

  updateFailureNotes(failureNotes: string): void {
    const validationResult = isNotesValid(failureNotes);
    if (validationResult.validity) {
      this.failureReasonNotesErrorMessage = "";
      this.failureReasonNotesValid = true;
    } else {
      this.failureReasonNotesErrorMessage =
        this.errorMessages.failureReason[validationResult.errorReason];
      this.failureReasonNotesValid = false;
    }
    this.failureReasonNotes = failureNotes;
  }

  checkFailureReasonsValid(): boolean {
    if (this.idCheckFailures.length === 0 && !this.isMarkedDuplicate()) {
      this.failureDocumentsErrorMessage = this.errorMessages.failures["empty"];
      this.validSubmission = false;
      return false;
    } else {
      this.validSubmission = true;
      return true;
    }
  }

  getInvitationState(): void {
    this.storage.has("invitation").subscribe((invitationExists) => {
      if (invitationExists) {
        this.storage.get("invitation").subscribe((invitation) => {
          this.invitation = invitation as InvitationIdCheck;

          if (this.invitation.invitationId !== this.invitationId) {
            this.setInvitationFromRoute();
          } else {
            this.setModifiedRecordCheckValues();
          }
        });
      } else {
        this.setInvitationFromRoute();
      }
    });
  }

  getIdCheckFailureState(): void {
    this.storage.has("idCheckFailures").subscribe((idCheckFailureExists) => {
      if (idCheckFailureExists) {
        this.storage.get("idCheckFailures").subscribe((idCheckFailures) => {
          this.idCheckFailures = idCheckFailures as IdCheckFailure[];
        });
      } else {
        this.idCheckFailureSubscription = this.store
          .select(getIdCheckerRejection)
          .subscribe((idCheckFailures) => {
            this.idCheckFailures = Object.assign([], idCheckFailures);
          });
      }
    });
  }

  setInvitationFromRoute(): void {
    this.invitation = this.location.getState() as InvitationIdCheck;
    this.storage.set("invitation", this.invitation).subscribe();
    this.setModifiedRecordCheckValues();
  }

  setModifiedRecordCheckValues(): void {
    this.modifiedRecordCheck.enabled = true;
    this.modifiedRecordCheck.invitationId = this.invitation.invitationId;
    this.modifiedRecordCheck.status = this.invitation.status;
    this.modifiedRecordCheck.statusLastUpdatedUtc =
      this.invitation.statusLastUpdatedDateUtc;
  }

  isMarkedDuplicate(): boolean {
    return this.invitation.holdReason?.includes(
      IdCheckHeldReason["Potential Duplicate"],
    );
  }

  getCimDuplicateLink() {
    const cimEnv = this.sessionStorage.get("cimEnv");
    return `${cimEnv}/#/view-profile/${this.invitation.cis_duplicate_match}`;
  }
}
