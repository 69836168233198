import { Injectable } from "@angular/core";

@Injectable()
export class FeedbackService {
  private queryString: string;
  readonly BASE_FEEDBACK_URL =
    "https://feedback.digital.nhs.uk/jfe/form/SV_0ewXr8LWPGbca5U";

  getFeedbackLink(): string {
    return `${this.BASE_FEEDBACK_URL}?Page=${this.queryString || "Footer"}`;
  }

  setQueryString(string: string): void {
    this.queryString = string;
  }
}
