import { Component, OnInit } from "@angular/core";
import { DashboardCheckbox } from "src/app/models/dashboardCheckbox";
import {
  InvitationDetails,
  InvitationStatus,
} from "src/app/models/invitationDetails";
import { InvitationIdCheck } from "src/store/states/spa.state";
import { PaginationInstance } from "ngx-pagination/lib/pagination-instance";
import { IdCheckerService } from "src/app/services/id-checker.service";
import { Title } from "@angular/platform-browser";
import { Location } from "@angular/common";
import { RouterService } from "src/app/services/router.service";
import { removeSpecialChars } from "../../../utils/common/common";

@Component({
  selector: "app-idchecker-search",
  templateUrl: "./search.component.html",
})
export class SearchComponent implements OnInit {
  allInvitations: InvitationIdCheck[];
  collectionSize: number;
  filteredInvitations: InvitationIdCheck[];
  forwardRoute = "/invitation/basic-info";
  tableCaption = "All Applications";
  searchTerm = "";
  odsSearchTerm = "";
  reviewedBySearchTerm = "";
  showingResults = false;
  loading: boolean = true;

  statusMap = {
    review: InvitationStatus["On hold"],
    approve: InvitationStatus.Approved,
    reject: InvitationStatus.Rejected,
  };

  filterActive: boolean = false;

  statuses: DashboardCheckbox[] = [
    {
      dashboardStatus: "Waiting for review",
      statuses: [InvitationStatus["ID review"]],
      isSelected: false,
    },
    {
      dashboardStatus: "On hold",
      statuses: [InvitationStatus["On hold"]],
      isSelected: false,
    },
    {
      dashboardStatus: "Approved",
      statuses: [InvitationStatus.Approved],
      isSelected: false,
    },
    {
      dashboardStatus: "Rejected",
      statuses: [InvitationStatus.Rejected],
      isSelected: false,
    },
  ];

  public config: PaginationInstance = {
    id: "custom",
    itemsPerPage: 50,
    currentPage: 1,
  };

  constructor(
    private routerService: RouterService,
    private idCheckerService: IdCheckerService,
    private title: Title,
    private location: Location,
  ) {}

  async ngOnInit(): Promise<void> {
    this.title.setTitle("Search ID Checker applications");
    if (
      this.location.getState()["filteredInvitations"] !== null &&
      this.location.getState()["filteredInvitations"] !== undefined
    ) {
      this.setValuesFromLocationState();
      this.filterActive = true;
    } else {
      await this.showAllInvitations();
    }

    this.setCurrentPage();
  }

  setCurrentPage(): void {
    if (
      this.location.getState()["currentPage"] !== null &&
      this.location.getState()["currentPage"] !== undefined
    ) {
      this.config.currentPage = this.location.getState()["currentPage"];
    }
  }

  setValuesFromLocationState(): void {
    this.filteredInvitations = this.location.getState()["filteredInvitations"];
    this.allInvitations = this.location.getState()["allInvitations"];
    this.searchTerm = this.location.getState()["searchTerm"];
    this.odsSearchTerm = this.location.getState()["odsSearchTerm"];
    this.reviewedBySearchTerm =
      this.location.getState()["reviewedBySearchTerm"];
    this.statuses = this.location.getState()["statuses"];

    this.collectionSize =
      this.location.getState()["filteredInvitations"].length;
    this.updateTableCaption();
    this.loading = false;
  }

  mapInvites(invites: InvitationDetails[]): InvitationIdCheck[] {
    return invites.map((invite) => new InvitationIdCheck(invite));
  }

  async showAllInvitations() {
    this.idCheckerService.searchIdCheckerInvitations().subscribe({
      next: (response) => {
        this.allInvitations = this.mapInvites(response["body"]).sort((a, b) =>
          a.statusLastUpdatedDateUtc < b.statusLastUpdatedDateUtc ? 1 : -1,
        );
        this.filteredInvitations = this.allInvitations;

        this.setCollectionSize();
        this.loading = false;
      },
      error: (error) => {
        this.routerService.handleErrorAuthRoutes(error.status);
        this.loading = false;
      },
    });
  }

  setCollectionSize(): void {
    this.collectionSize = this.filteredInvitations.length;
  }

  onSearchClicked(): void {
    this.filterActive = true;
    let checkedStatuses = this.filterByStatus();
    let applicationsToFilter = this.allInvitations;

    if (this.searchTerm.length !== 0) {
      applicationsToFilter = this.filterBySearchTerm(applicationsToFilter);
    }

    if (this.odsSearchTerm.length !== 0) {
      applicationsToFilter = this.filterByOdsSearchTerm(applicationsToFilter);
    }

    if (this.reviewedBySearchTerm.length !== 0) {
      applicationsToFilter =
        this.filterByReviewedBySearchTerm(applicationsToFilter);
    }

    if (checkedStatuses != undefined && checkedStatuses.length !== 0) {
      applicationsToFilter = applicationsToFilter.filter((val) =>
        checkedStatuses.flat().includes(val.status),
      );
    }

    this.filteredInvitations = applicationsToFilter;
    this.setCollectionSize();

    this.updateTableCaption();
    this.config.currentPage = 1;
    document.querySelector("#results").scrollIntoView({ behavior: "smooth" });
  }

  filterBySearchTerm(
    applicationsToFilter: InvitationIdCheck[],
  ): InvitationIdCheck[] {
    applicationsToFilter = applicationsToFilter.filter(
      (val) =>
        val.familyName.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
        val.givenName.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
        (val.cisUuid || "")
          .toLowerCase()
          .includes(this.searchTerm.toLowerCase()) ||
        val.invitationId
          .toLowerCase()
          .includes(this.searchTerm.toLowerCase()) ||
        val.email.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
        val.senderEmail.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
        (
          val.givenName.toLowerCase() +
          " " +
          val.familyName.toLowerCase()
        ).includes(this.searchTerm.toLowerCase()),
    );

    return applicationsToFilter;
  }

  filterByOdsSearchTerm(
    applicationsToFilter: InvitationIdCheck[],
  ): InvitationIdCheck[] {
    const odsList: string[][] = [];
    const codesEntered = this.odsSearchTerm.toLowerCase().split(" ");
    odsList.push(codesEntered);

    applicationsToFilter = applicationsToFilter.filter((val) =>
      odsList.flat().includes(val.createdByUserOrgCode.toLowerCase()),
    );

    return applicationsToFilter;
  }

  filterByReviewedBySearchTerm(
    applicationsToFilter: InvitationIdCheck[],
  ): InvitationIdCheck[] {
    applicationsToFilter = applicationsToFilter.filter(
      (val) =>
        (val.idCheckedBy || "")
          .toLowerCase()
          .includes(this.reviewedBySearchTerm.toLowerCase()) ||
        (val.reviewBy || "")
          .toLowerCase()
          .includes(this.reviewedBySearchTerm.toLowerCase()),
    );

    return applicationsToFilter;
  }

  filterByStatus(): InvitationStatus[][] {
    return this.statuses
      .filter((val) => val.isSelected)
      .map((val) => {
        return val.statuses;
      });
  }

  clearFilters(): void {
    this.filterActive = false;
    this.searchTerm = "";
    this.odsSearchTerm = "";
    this.reviewedBySearchTerm = "";
    this.statuses.forEach((val) => (val.isSelected = false));
    this.filteredInvitations = this.allInvitations;
    this.setCollectionSize();
    this.updateTableCaption();
    this.config.currentPage = 1;
  }

  updateTableCaption(): void {
    let textList = [];

    if (this.searchTerm.length > 0) {
      textList.push(`for '${this.searchTerm}'`);
    }
    if (this.reviewedBySearchTerm.length > 0) {
      textList.push(`reviewed by '${this.reviewedBySearchTerm}'`);
    }
    if (this.odsSearchTerm.length > 0) {
      textList.push(`with org code(s) '${this.odsSearchTerm}'`);
    }
    if (textList.length > 0) {
      this.tableCaption =
        `Showing ${this.collectionSize} result${
          this.collectionSize == 1 ? "" : "s"
        } ` + textList.join(", ");
    } else {
      this.tableCaption = "All Applications";
    }
  }

  omitSpecialChar(e): boolean {
    return removeSpecialChars(e);
  }
}
