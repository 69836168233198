<session-check></session-check>
<div class="nhsuk-grid-row">
  <div class="nhsuk-grid-column-two-thirds">
    <error-summary
      [errorSummaryMessages]="summaryErrorMessages"
    ></error-summary>

    <h1 autofocus tabindex="-1">
      {{ successInvitationsCount }} of {{ totalInvitationsCount }} applications
      sent
    </h1>

    <p class="nhsuk-body-l">
      {{ failedInvitationsCount }} application(s) could not be sent because:
    </p>

    <ul>
      <li *ngIf="dataErrorInvitations.length > 0">
        {{ dataErrorInvitations.length }} application(s) contain data errors
      </li>
      <li *ngIf="duplicateErrorInvitations.length > 0">
        {{ duplicateErrorInvitations.length }} application(s) with the same
        email address is already in progress
      </li>
    </ul>

    <p *ngIf="totalInvitationsCount > failedInvitationsCount">
      All other applications have been sent successfully.
    </p>

    <table
      role="table"
      class="nhsuk-table-responsive nhsuk-u-margin-bottom-5 nhsuk-u-margin-top-8"
      id="error-table-data"
    >
      <caption class="nhsuk-table__caption">
        Errors
      </caption>
      <thead role="rowgroup" class="nhsuk-table__head">
        <tr role="row">
          <th role="columnheader" class="" scope="col">Column</th>
          <th role="columnheader" class="" scope="col">Row</th>
          <th role="columnheader" class="" scope="col">Error</th>
        </tr>
      </thead>
      <tbody class="nhsuk-table__body">
        <tr
          *ngFor="let failedInvitation of dataErrorInvitations"
          role="row"
          class="nhsuk-table__row"
        >
          <td role="cell" class="nhsuk-table__cell">
            <span class="nhsuk-table-responsive__heading">Column </span
            >{{ failedInvitation.column }}
          </td>
          <td role="cell" class="nhsuk-table__cell">
            <span class="nhsuk-table-responsive__heading">Row</span
            >{{ failedInvitation.record_number ?? "-" }}
          </td>
          <td
            role="cell"
            class="nhsuk-table__cell nhsuk-body-s"
            [innerHtml]="failedInvitation.error_message"
          >
            <span class="nhsuk-table-responsive__heading">Error </span>
          </td>
        </tr>
      </tbody>
    </table>

    <table
      *ngIf="duplicateErrorInvitations.length > 0"
      role="table"
      class="nhsuk-table-responsive nhsuk-u-margin-bottom-5 nhsuk-u-margin-top-8"
      id="error-table-in-progress"
    >
      <caption class="nhsuk-table__caption">
        Applications already in progress
      </caption>
      <thead role="rowgroup" class="nhsuk-table__head">
        <tr role="row">
          <th role="columnheader" class="" scope="col">Row</th>
          <th role="columnheader" class="" scope="col">Error</th>
        </tr>
      </thead>
      <tbody class="nhsuk-table__body">
        <tr
          *ngFor="let item of duplicateErrorInvitations"
          role="row"
          class="nhsuk-table__row"
        >
          <td role="cell" class="nhsuk-table__cell">
            <span class="nhsuk-table-responsive__heading">Row</span
            >{{ item.record_number }}
          </td>
          <td role="cell" class="nhsuk-table__cell">
            <span class="nhsuk-table-responsive__heading">Error </span>
            An application for the email address
            <a href="/invitation/invite-details/{{ item.invitation_id }}">{{
              item.email
            }}</a>
            is already in progress.
          </td>
        </tr>
      </tbody>
    </table>

    <div *ngIf="dataErrorInvitations.length > 0">
      <h2 class="nhsuk-u-margin-top-6">How to resubmit your applications</h2>

      <ol>
        <li>
          Download the
          <a
            (click)="downloadCsv()"
            download="failed-applications.csv"
            id="failed-applications-download-link"
          >
            applications with errors CSV</a
          >. This file contains the
          {{ dataErrorInvitations.length }} applications with errors.
        </li>
        <li>Correct all errors</li>
        <li>
          <a [routerLink]="['/invitation/upload-file']">Upload your file</a>
          and submit it again
        </li>
      </ol>

      <div class="nhsuk-inset-text">
        <span class="nhsuk-u-visually-hidden">Information: </span>
        <p>
          Do not include applications that have been successfully sent or which
          are already in progress when you upload your file.
        </p>
      </div>
    </div>
  </div>
</div>
