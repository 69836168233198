<session-check></session-check>

<div>
  <div class="nhsuk-grid-row">
    <div class="nhsuk-grid-column-full">
      <h1 autofocus tabindex="-1">Apply for Care ID</h1>

      <role-changed></role-changed>

      <ul class="nhsuk-grid-row nhsuk-card-group">
        <li class="nhsuk-grid-column-one-half nhsuk-card-group__item">
          <div class="nhsuk-card nhsuk-card--clickable">
            <div class="nhsuk-card__content">
              <h2 class="nhsuk-card__heading nhsuk-heading-m">
                <a
                  class="nhsuk-card__link stretched-link"
                  [routerLink]="['/invitation/all-invitations']"
                  aria-label="all applications dashboard"
                  data-cy="all-applications"
                  >Search applications</a
                >
              </h2>
              <p class="nhsuk-card__description">
                Find applications by name, email address, application ID.
              </p>
            </div>
          </div>
        </li>

        <li
          class="nhsuk-grid-column-one-half nhsuk-card-group__item"
          *ngIf="isServiceManager"
        >
          <div class="nhsuk-card nhsuk-card--clickable">
            <div class="nhsuk-card__content">
              <h2 class="nhsuk-card__heading nhsuk-heading-m">
                <a
                  class="nhsuk-card__link stretched-link"
                  [routerLink]="['/id-checker/review-list']"
                  aria-label="review applications dashboard"
                  data-cy="review-applications"
                  >Review applications</a
                >
              </h2>
              <p class="nhsuk-card__description">
                Check and approve, reject or place applications on a holding
                list for further review by another ID checker.
              </p>
            </div>
          </div>
        </li>

        <li class="nhsuk-grid-column-one-half nhsuk-card-group__item">
          <div class="nhsuk-card nhsuk-card--clickable">
            <div class="nhsuk-card__content">
              <h2 class="nhsuk-card__heading nhsuk-heading-m">
                <a
                  class="nhsuk-card__link stretched-link"
                  aria-label="manage applications dashboard"
                  data-cy="manage-applications"
                  [routerLink]="['/invitation/dashboard']"
                  >Manage my organisation's applications</a
                >
              </h2>
              <p class="nhsuk-card__description">
                Start a new application or manage current applications.
              </p>
            </div>
          </div>
        </li>

        <li
          class="nhsuk-grid-column-one-half nhsuk-card-group__item"
          *ngIf="isServiceManager"
        >
          <div class="nhsuk-card nhsuk-card--clickable">
            <div class="nhsuk-card__content">
              <h2 class="nhsuk-card__heading nhsuk-heading-m">
                <a
                  class="nhsuk-card__link stretched-link"
                  [routerLink]="['/service-management/portal']"
                  aria-label="service manager portal"
                  data-cy="service-manager-portal"
                  >Service management</a
                >
              </h2>
              <p class="nhsuk-card__description">
                Manage the service, view raw payload, change configurable
                options.
              </p>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>
