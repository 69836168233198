<session-check></session-check>
<div>
  <div class="nhsuk-grid-row">
    <div class="nhsuk-grid-column-full">
      <h1 autofocus tabindex="-1">Service Management</h1>

      <h2>Internal</h2>
      <ul *ngIf="isServiceManager" class="nhsuk-grid-row nhsuk-card-group">
        <li class="nhsuk-grid-column-one-half nhsuk-card-group__item">
          <div class="nhsuk-card nhsuk-card--clickable">
            <div class="nhsuk-card__content">
              <h2 class="nhsuk-card__heading nhsuk-heading-m">
                <a
                  class="nhsuk-card__link stretched-link"
                  [routerLink]="['/service-management/view-payload']"
                  aria-label="service manager portal"
                  data-cy="link-view-payload"
                  >View vendor payload
                </a>
              </h2>
              <p class="nhsuk-card__description">
                View the raw json for the applicant verified payload.
              </p>
            </div>
          </div>
        </li>
        <li class="nhsuk-grid-column-one-half nhsuk-card-group__item">
          <div class="nhsuk-card nhsuk-card--clickable">
            <div class="nhsuk-card__content">
              <h2 class="nhsuk-card__heading nhsuk-heading-m">
                <a
                  class="nhsuk-card__link stretched-link"
                  [routerLink]="[
                    '/service-management/sms-download-link-resend'
                  ]"
                  aria-label="service manager portal"
                  data-cy="link-sms-download-link-resend"
                  >Resend original app link
                </a>
              </h2>
              <p class="nhsuk-card__description">
                Resend the SMS download link to the applicant if they didn't
                receive it for some reason.
              </p>
            </div>
          </div>
        </li>
      </ul>

      <h2>Xydus</h2>

      <ul *ngIf="isServiceManager" class="nhsuk-grid-row nhsuk-card-group">
        <li class="nhsuk-grid-column-one-half nhsuk-card-group__item">
          <div class="nhsuk-card nhsuk-card--clickable">
            <div class="nhsuk-card__content">
              <h2 class="nhsuk-card__heading nhsuk-heading-m">
                <a
                  class="nhsuk-card__link stretched-link"
                  [routerLink]="[
                    '/service-management/ems2-case-approval-retry'
                  ]"
                  aria-label="service manager portal"
                  data-cy="link-ems2-case-approval-retry"
                  >Xydus case approval retry
                </a>
              </h2>
              <p class="nhsuk-card__description">
                Only to be used if there is an issue with an application
                approval from Xydus and the process needs reattempting.
              </p>
            </div>
          </div>
        </li>
        <li class="nhsuk-grid-column-one-half nhsuk-card-group__item">
          <div class="nhsuk-card nhsuk-card--clickable">
            <div class="nhsuk-card__content">
              <h2 class="nhsuk-card__heading nhsuk-heading-m">
                <a
                  class="nhsuk-card__link stretched-link"
                  [routerLink]="['/service-management/ems2-case-event-change']"
                  aria-label="service manager portal"
                  data-cy="link-ems2-case-event-change"
                  >Send a new app link
                </a>
              </h2>
              <p class="nhsuk-card__description">
                Regenerate a new mobile app link for users who are experiencing
                issues with their current app link.
              </p>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>
