import {
  InvitationConfirmation,
  initialState,
} from "../../store/states/invitee-spa.state";
import { Inject, Injectable } from "@angular/core";
import { SESSION_STORAGE, StorageService } from "ngx-webstorage-service";

@Injectable()
export class InviteeDetails {
  constructor(
    @Inject(SESSION_STORAGE) private sessionStorage: StorageService,
  ) {}

  createInviteeDetails(): InvitationConfirmation {
    let details = initialState.invitationConfirmation;
    this.sessionStorage.set("inviteeDetails", details);
    return details;
  }

  getInviteeDetails(): InvitationConfirmation {
    return this.sessionStorage.get("inviteeDetails");
  }

  setInviteeDetails(invitationConfirmation: InvitationConfirmation): void {
    this.sessionStorage.set("inviteeDetails", invitationConfirmation);
  }

  delete(): void {
    this.sessionStorage.remove("inviteeDetails");
  }
}
