<div *ngIf="invitationConfirmation" class="nhsuk-grid-row">
  <div class="nhsuk-grid-column-two-thirds">
    <div
      *ngIf="submitButtonClicked; else mainContent"
      class="nhsuk-grid-column-full"
    >
      <h1 autofocus tabindex="-1">Submitting address</h1>

      <progress-indicator spinnerText="Submitting..."></progress-indicator>
    </div>

    <ng-template #mainContent>
      <breadcrumb-link
        [breadcrumbRoute]="breadcrumbRoute"
        [breadcrumbText]="breadcrumbText"
      ></breadcrumb-link>
      <h1 autofocus tabindex="-1">Check your address</h1>
      <span
        class="nhsuk-error-message"
        data-cy="submit-error-message"
        *ngIf="errorMessage"
        >{{ errorMessage }}</span
      >
      <dl class="nhsuk-summary-list">
        <div class="nhsuk-summary-list__row">
          <dt id="address" class="nhsuk-summary-list__key">UK address</dt>
          <dd class="nhsuk-summary-list__value">
            {{ invitationConfirmation.tuAddress }}
          </dd>
          <dd class="nhsuk-summary-list__actions">
            <a
              aria-describedby="address"
              [state]="invitationConfirmation"
              [routerLink]="['/invitee/confirm-postcode/tu']"
            >
              Change
            </a>
          </dd>
        </div>
      </dl>
      <p>
        Your address will be checked by TransUnion when you submit your details.
      </p>
      <button
        data-cy="submit-address-check"
        type="submit"
        [ngClass]="{ 'spinner-button': submitButtonClicked }"
        [disabled]="submitButtonClicked || confirmationError"
        (click)="onSubmitButtonClicked()"
        class="nhsuk-button"
      >
        Submit
      </button>
    </ng-template>
  </div>
</div>
