import {
  Component,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { IdCheckRadioButtonComponent } from "../../common/form-fields/id-check-radio-button/id-check-radio-button.component";
import { ModifiedRecordCheckComponent } from "../../common/modified-record-check/modified-record-check.component";
import { GetInvitationService } from "src/app/services/get-invitation.service";
import { timer, combineLatest, interval, Subscription } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import { RouterService } from "src/app/services/router.service";
import { Invitation, InvitationIdCheck } from "src/store/states/spa.state";
import { map } from "rxjs/operators";
import { SessionAuthenticationService } from "src/app/services/session-authentication.service";
import {
  IdCheckFailureReason,
  IdCheckImage,
  InvitationStatus,
} from "src/app/models/invitationDetails";
import { IdCheckerService } from "src/app/services/id-checker.service";
import { Title } from "@angular/platform-browser";
import { IdCheckerRejectionSPAState } from "src/store/states/id-checker-spa.state";
import { getIdCheckerRejection } from "src/store/selectors/id-checker-spa.selectors";
import { IdCheckFailure } from "src/app/models/idCheckFailure";
import { Store } from "@ngrx/store";
import { StorageMap } from "@ngx-pwa/local-storage";
import { ErrorSummaryMessage } from "../../../models/error-summary-message";
import { IdCheckInternalNotes } from "src/app/models/idCheckInternalNotes";
import { UpdateIdCheckerRejectionAction } from "src/store/actions/id-checker-spa.actions";
import { Location } from "@angular/common";
import { DashboardCheckbox } from "src/app/models/dashboardCheckbox";
import { AfcidImageCropperComponent } from "../../common/afcid-image-cropper/afcid-image-cropper.component";
import {
  IdcheckerSelections,
  Selections,
} from "src/app/models/idCheckSelections";
import { IdCheckRadioErrorType } from "src/app/models/idCheckRadioErrorType";
import { SESSION_STORAGE, StorageService } from "ngx-webstorage-service";
import { IdFailureCheckReasonPipe } from "src/app/utils/pipes/id-check-failure-reason/id-check-failure-reason.pipe";

@Component({
  selector: "app-review-id",
  templateUrl: "./review-id.component.html",
  styleUrls: ["./review-id.component.css"],
})
export class ReviewIdComponent implements OnInit, OnDestroy {
  invitation: InvitationIdCheck;
  invitationId: string;
  currentUser: string;
  currentUserId: string;
  dpoaWarningsNumber: number;
  poaRouteTaken: boolean;
  statusDescription: string;

  duplicateProfile: string = "Duplicate profile";
  photoIdOne: string = "Photo ID 1";
  photoIdTwo: string = "Photo ID 2";
  proofOfAddress: string = "Proof of address";
  digitalProofOfAddress: string = "Digital proof of address";
  nameAndDob: string = "Name and date of birth";
  faceScan: string = "Face scan";
  smartcardPhoto: string = "Smartcard photo";
  eChipPhoto: string = "eChip";
  extractedPassportPhoto: string = "Extracted photo";
  profilePhoto: string = "Uploaded by applicant";

  duplicateProfileAnswer: number;
  photoIdOneAnswer: number;
  photoIdTwoAnswer: number;
  proofOfAddressAnswer: number;
  nameAndDobAnswer: number;
  faceScanAnswer: number;
  smartcardPhotoAnswer: number;

  images: IdCheckImage[];
  profile_photo_cropped_b64: string;
  availableSmartcardImages: IdCheckImage[] = [];
  selectedSmartcardPhoto: IdCheckImage;

  photoIdOneImgFront: string;
  photoIdOneImgBack: string;
  photoIdTwoImgFront: string;
  photoIdTwoImgBack: string;
  poaImg: string;
  faceScanImg: string;
  eChipImg: string;
  extractedPassportImg: string;
  profilePhotoImg: string;
  passportNo?: string;
  drivingLicenseNo?: string;

  approved: boolean = false;
  submitting: boolean = false;
  rejected: boolean = false;

  photoIdOneFrontExpand = false;
  photoIdOneBackExpand = false;
  photoIdTwoFrontExpand = false;
  photoIdTwoBackExpand = false;
  poaExpand = false;

  duplicateProfileValid = false;
  photoIdOneRadioValid = false;
  photoIdTwoRadioValid = false;
  poaRadioValid = false;
  nameAndDobRadioValid = false;
  faceScanRadioValid = false;
  smartcardPhotoRadioValid = false;

  activeCheckFrequency = interval(20000);
  reviewModal = false;
  reviewInProgressWarning = false;
  subscription: Subscription;
  faceLikenessLowerThanRequiredThreshold: boolean = false;

  idCheckFailures: IdCheckFailure[];
  idCheckFailureSubscription: Subscription;
  idcheckerSelections: IdcheckerSelections;
  potentialDuplicate: boolean = false;

  summaryErrorMessages = [];
  prevPage: "";
  currentPage: number = 1;
  filteredInvitations: Invitation[];
  allInvitations: Invitation[];
  searchTerm = "";
  odsSearchTerm = "";
  reviewedBySearchTerm = "";
  statuses: DashboardCheckbox[];

  // Cropper
  storeCroppedPhotoInProgress = false;
  storeCroppedPhotoErrorMessage: string;
  fileSizeEstimate: string;
  croppedImage: any = "";
  imageBase64: any = "";

  @ViewChild("duplicateProfileInput")
  duplicateProfileInput: IdCheckRadioButtonComponent;
  @ViewChild("photoIdOneInput") photoIdOneInput: IdCheckRadioButtonComponent;
  @ViewChild("photoIdTwoInput") photoIdTwoInput: IdCheckRadioButtonComponent;
  @ViewChild("proofOfAddressInput")
  proofOfAddressInput: IdCheckRadioButtonComponent;
  @ViewChild("nameAndDobInput") nameAndDobInput: IdCheckRadioButtonComponent;
  @ViewChild("faceScanInput") faceScanInput: IdCheckRadioButtonComponent;
  @ViewChild("smartcardPhotoInput")
  smartcardPhotoInput: IdCheckRadioButtonComponent;
  @ViewChild("modifiedRecordCheck")
  modifiedRecordCheck: ModifiedRecordCheckComponent;
  @ViewChild("afcidImageCropper") afcidImageCropper: AfcidImageCropperComponent;

  constructor(
    private route: ActivatedRoute,
    private getInvitationService: GetInvitationService,
    private sessionAuthenticationService: SessionAuthenticationService,
    private title: Title,
    private idCheckerService: IdCheckerService,
    private store: Store<IdCheckerRejectionSPAState>,
    private storage: StorageMap,
    private routerService: RouterService,
    private location: Location,
    private idFailureCheckReasonPipe: IdFailureCheckReasonPipe,
    @Inject(SESSION_STORAGE) private sessionStorage: StorageService,
  ) {}

  ngOnInit(): void {
    this.title.setTitle("Review documents");

    this.invitationId = this.route.snapshot.paramMap.get("invitationId");
    this.setPreviousPageMeta();
    this.resetRejectionReasonState();
    this.getRejectionReasonsState();
    this.getInvitation();
    const sessionToken =
      this.sessionAuthenticationService.currentSessionToken();
    this.currentUser = sessionToken.user_name;
    this.currentUserId = sessionToken.user_uuid;
    this.getImages();
  }

  setPreviousPageMeta(): void {
    this.prevPage = this.location.getState()["prevPage"];
    this.currentPage = this.location.getState()["currentPage"];
    this.filteredInvitations = this.location.getState()["filteredInvitations"];
    this.allInvitations = this.location.getState()["allInvitations"];
    this.statuses = this.location.getState()["statuses"];
    this.searchTerm = this.location.getState()["searchTerm"];
    this.odsSearchTerm = this.location.getState()["odsSearchTerm"];
    this.reviewedBySearchTerm =
      this.location.getState()["reviewedBySearchTerm"];
  }

  /* istanbul ignore next */
  setTitle(): void {
    if (this.invitation.status == 52) {
      this.title.setTitle("Approved application");
    } else if (this.invitation.status == 53) {
      this.title.setTitle("Rejected application");
    }
  }

  setStatusDisplay(): void {
    switch (this.invitation.status) {
      case 51:
        this.statusDescription = "On hold";
        break;
      case 52:
        this.statusDescription = "Approved";
        break;
      case 53:
        this.statusDescription = "Rejected";
        break;
      default:
        this.statusDescription = "In review";
        break;
    }
  }

  setModifiedRecordCheckProperties(): void {
    if (
      this.invitation.status != InvitationStatus["ID review"] &&
      this.invitation.status != InvitationStatus["On hold"]
    ) {
      return;
    }

    this.modifiedRecordCheck.enabled = true;
    this.modifiedRecordCheck.invitationId = this.invitation.invitationId;
    this.modifiedRecordCheck.status = this.invitation.status;
    this.modifiedRecordCheck.statusLastUpdatedUtc =
      this.invitation.statusLastUpdatedDateUtc;
  }

  getRejectReasonsPerDocument() {
    if (!this.invitation.failedReason) return [];
    return this.invitation.failedReason.flatMap((failure) => {
      let newFailure = {
        document: failure.document,
        reasons: failure.reasons.map((reason) =>
          this.idFailureCheckReasonPipe.transform(
            reason,
            failure.document == "Proof of address",
          ),
        ),
      };
      return `${newFailure.document}${newFailure.reasons.join(",")}`;
    });
  }

  checkApplicationInReview() {
    if (
      this.invitation.status != InvitationStatus["ID review"] &&
      this.invitation.status != InvitationStatus["On hold"]
    ) {
      return;
    }

    if (this.invitation.reviewBy != null) {
      this.reviewModal = true;
      this.reviewInProgressWarning = true;
    } else {
      this.startApplicationReview();
    }
  }

  startApplicationReview(): void {
    this.reviewModal = false;
    this.reviewInProgressWarning = false;
    this.applicationInReview(true);
    this.subscription = this.activeCheckFrequency.subscribe(() => {
      const seconds = this.getSecondsTillExpiry(
        this.invitation.reviewExpiryUtc,
      );

      if (this.checkIfExpired(seconds)) {
        this.applicationInReview(false);
        this.subscription.unsubscribe();
      }
    });
  }

  stopApplicationReview() {
    if (!this.reviewInProgressWarning) {
      this.applicationInReview(false);

      if (this.subscription != undefined) {
        this.subscription.unsubscribe();
      }
    }
  }

  windowReload() {
    window.location.reload();
  }

  deleteNote(noteId: string): void {
    this.idCheckerService
      .deleteIdCheckInternalNote(this.invitationId, noteId)
      .subscribe({
        next: (response) => {
          if (response.status == 200) {
            this.windowReload();
          }
        },
        error: (error) => {
          this.routerService.handleErrorAuthRoutes(error.status);
        },
      });
  }

  @HostListener("window:beforeunload", ["$event"])
  onPageClose(): void {
    this.stopApplicationReview();
  }

  ngOnDestroy(): void {
    this.stopApplicationReview();
  }

  updateDuplicateProfileAnswer(answer: number): void {
    this.duplicateProfileAnswer = answer;
    this.validateAnswers();
  }

  updatePhotoIdOneAnswer(answer: number): void {
    this.photoIdOneAnswer = answer;
    this.validateAnswers();
  }

  updatePhotoIdTwoAnswer(answer: number): void {
    this.photoIdTwoAnswer = answer;
    this.validateAnswers();
  }

  updateProofOfAddressAnswer(answer: number): void {
    this.proofOfAddressAnswer = answer;
    this.validateAnswers();
  }

  updateNameAndDobAnswer(answer: number): void {
    this.nameAndDobAnswer = answer;
    this.validateAnswers();
  }

  updateFaceScanAnswer(answer: number): void {
    this.faceScanAnswer = answer;
    this.validateAnswers();
  }

  updateSmartcardPhotoAnswer(answer: number): void {
    this.smartcardPhotoAnswer = answer;
    this.validateAnswers();
  }

  getInvitation(): void {
    combineLatest([
      timer(1000),
      this.getInvitationService.getInvitation(this.invitationId),
    ])
      .pipe(map((x) => x[1]))
      .subscribe({
        next: (response) => {
          this.invitation = new InvitationIdCheck(response["body"]);
          this.faceLikenessLowerThanThreshold();
          this.checkApplicationInReview();
          this.hideDeletedNotes();
          this.setTitle();
          this.setModifiedRecordCheckProperties();
          this.setDpoaWarningsNumber();
          this.setPoaRouteTaken();
          this.setStatusDisplay();
          this.getIdcheckerSelections();
          this.passportNo = this.getIdNoByType("Passport");
          this.drivingLicenseNo = this.getIdNoByType("Driving license");
        },
        error: (error) => {
          this.routerService.handleErrorAuthRoutes(error.status);
        },
      });
  }

  getIdcheckerSelections(): void {
    const selections = this.sessionStorage.get(
      "idchecker-selections",
    ) as IdcheckerSelections;
    if (selections == undefined) {
      return;
    }
    const selectionKeys = {
      "Duplicate profile": "duplicateProfileValue",
      "Photo ID 1": "photo1value",
      "Photo ID 2": "photo2value",
      "Proof of address": "poaValue",
      "Name and date of birth": "nameDobValue",
      "Face scan": "faceValue",
      "Smartcard photo": "smartcardValue",
    };

    Object.entries(selectionKeys).forEach((entry) => {
      const radioName = entry[0];
      const selectionKey = entry[1];
      if (selections[selectionKey] != undefined)
        this.selectRadio(radioName, selections[selectionKey]);
    });
    if (selections.idCheckFailureValue != undefined) {
      this.selectPreviousRejectReasons(selections.idCheckFailureValue);
    }
  }

  selectRadio(docType: string, selectedValue: number): void {
    setTimeout(() => {
      const yesOrNo = selectedValue === 1 ? "yes" : "no";
      const elementId = "radio-" + docType + "-" + yesOrNo;
      document.getElementById(elementId).click();
    }, 1000);
  }

  selectPreviousRejectReasons(idCheckFailure: IdCheckFailure[]): void {
    setTimeout(() => {
      idCheckFailure.forEach((element) => {
        this.selectCheckboxes(element.document, element.reasons);
      });
    }, 1000);
  }

  selectCheckboxes(docType: string, reasons: IdCheckFailureReason[]): void {
    reasons.forEach((reasonNumber) => {
      const elementId =
        docType + "-" + this.convertReasonNumberToFieldIdentifier(reasonNumber);
      this.selectCheckbox(elementId);
    });
  }

  selectCheckbox(elementId: string): void {
    setTimeout(() => {
      document.getElementById(elementId).click();
    }, 500);
  }

  convertReasonNumberToFieldIdentifier(reason: number): string {
    switch (reason) {
      case 1:
        return "duplicate-evidence";
      case 2:
        return "expired-evidence";
      case 3:
        return "illegible-evidence";
      case 4:
        return "incomplete-evidence";
      case 5:
        return "suspected-fraud";
      case 6:
        return "not-accepted";
      case 7:
        return "failed-liveness-likeness-check";
      case 8:
        return "non-conformant-photo";
      case 9:
        return "other";
      case 10:
        return "image-quality";
      case 11:
        return "does-not-meet-requirements";
      case 12:
        return "smoketest";
      case 13:
        return "failed-liveness-check";
      case 14:
        return "name-and-dob-do-not-match";
      case 15:
        return "facescan-photo-quality";
    }
  }

  setPoaRouteTaken(): void {
    this.poaRouteTaken =
      !this.invitation.dpoaUsed ||
      (this.invitation.dpoaUsed &&
        this.invitation.poaDocumentType != undefined);
  }

  setDpoaWarningsNumber(): void {
    this.dpoaWarningsNumber = 0;

    if (this.invitation.dpoaUsed && this.invitation.dpoaCheck != undefined) {
      if (this.invitation.dpoaCheck.warnAddressLinks) {
        this.dpoaWarningsNumber += 1;
      }
      if (this.invitation.dpoaCheck.warnDeceased) {
        this.dpoaWarningsNumber += 1;
      }
      if (this.invitation.dpoaCheck.ncoaAlertFlag) {
        this.dpoaWarningsNumber += 1;
      }
    }
  }

  hideDeletedNotes(): void {
    let result: IdCheckInternalNotes[] = [];
    let _ = this.invitation.holdInternalNotes.filter((item) => {
      if (item.noteVisible !== false) {
        result.push(item);
      }
    });
    this.invitation.holdInternalNotes = result;
  }

  getImages(): void {
    combineLatest([
      timer(1000),
      this.idCheckerService.getIdCheckerImages(this.invitationId),
    ])
      .pipe(map((x) => x[1]))
      .subscribe({
        next: (response) => {
          this.images = response["body"];

          this.idCheckerService.getProfilePhoto(this.invitationId).subscribe({
            next: (response) => {
              this.images.push(response["body"][0]);
              this.setImages();
              this.profile_photo_cropped_b64 = response["body"][1].data;
            },
            error: () => {
              console.log("No profile photo image");
              this.setImages();
            },
          });
        },
        error: (error) => {
          this.routerService.handleErrorAuthRoutes(error.status);
        },
      });
  }

  setImages(): void {
    this.mapImages();
    this.selectApprovedSmartcardPhoto();
  }

  checkDuplicateProfileValid(updatedErrorSummary) {
    if (this.duplicateProfileAnswer === undefined) {
      updatedErrorSummary.push({
        message: "Confirm whether duplicate profile exists",
        id: "radio-Duplicate check-yes",
      });
      this.duplicateProfileValid = false;
      return;
    }

    this.duplicateProfileValid = true;
  }

  checkPhotoIdOneValid(updatedErrorSummary) {
    if (this.photoIdOneAnswer === undefined) {
      updatedErrorSummary.push({
        message: "Select if you approve or reject this photo ID",
        id: "radio-Photo ID 1-yes",
        type: IdCheckRadioErrorType["Not answered"],
      });
      this.photoIdOneRadioValid = false;
      return;
    }

    if (
      this.photoIdOneAnswer === 0 &&
      this.idCheckFailures.findIndex((x) => x.document === this.photoIdOne) ===
        -1
    ) {
      updatedErrorSummary.push({
        message: "Select reason(s) for rejecting",
        id: "radio-Photo ID 1-yes",
        type: IdCheckRadioErrorType["No reason selected"],
      });
      this.photoIdOneRadioValid = false;
      return;
    }

    this.photoIdOneRadioValid = true;
  }

  checkPhotoIdTwoValid(updatedErrorSummary) {
    if (this.poaRouteTaken && this.photoIdTwoAnswer === undefined) {
      updatedErrorSummary.push({
        message: "Select if you approve or reject this photo ID",
        id: "radio-Photo ID 2-yes",
        type: IdCheckRadioErrorType["Not answered"],
      });
      this.photoIdTwoRadioValid = false;
      return;
    }

    if (
      this.photoIdTwoAnswer === 0 &&
      this.idCheckFailures.findIndex((x) => x.document === this.photoIdTwo) ===
        -1
    ) {
      updatedErrorSummary.push({
        message: `Select reason(s) for rejecting`,
        id: "radio-Photo ID 2-yes",
        type: IdCheckRadioErrorType["No reason selected"],
      });
      this.photoIdTwoRadioValid = false;
      return;
    }

    this.photoIdTwoRadioValid = true;
  }

  checkPoaIsValid(updatedErrorSummary) {
    if (this.poaRouteTaken) {
      if (this.proofOfAddressAnswer === undefined) {
        updatedErrorSummary.push({
          message: "Select if you approve or reject this address check",
          id: "radio-Proof of address-yes",
          type: IdCheckRadioErrorType["Not answered"],
        });
        this.poaRadioValid = false;
        return;
      }

      if (
        this.proofOfAddressAnswer === 0 &&
        this.idCheckFailures.findIndex(
          (x) => x.document === this.proofOfAddress,
        ) === -1
      ) {
        updatedErrorSummary.push({
          message: "Select reason(s) for rejecting",
          id: "radio-Proof of address-yes",
          type: IdCheckRadioErrorType["No reason selected"],
        });
        this.poaRadioValid = false;
        return;
      }

      this.poaRadioValid = true;
    }

    return updatedErrorSummary;
  }

  checkNameAndDobIsValid(updatedErrorSummary) {
    if (this.nameAndDobAnswer === undefined) {
      updatedErrorSummary.push({
        message: "Select if you approve or reject this name and dob",
        id: "radio-Name and date of birth-yes",
        type: IdCheckRadioErrorType["Not answered"],
      });
      this.nameAndDobRadioValid = false;
      return;
    }

    if (
      this.nameAndDobAnswer === 0 &&
      this.idCheckFailures.findIndex((x) => x.document === this.nameAndDob) ===
        -1
    ) {
      updatedErrorSummary.push({
        message: "Select reason(s) for rejecting",
        id: "radio-Name and date of birth-yes",
        type: IdCheckRadioErrorType["No reason selected"],
      });
      this.nameAndDobRadioValid = false;
      return;
    }

    this.nameAndDobRadioValid = true;
    return updatedErrorSummary;
  }

  checkFaceScanIsValid(updatedErrorSummary) {
    if (this.faceScanAnswer === undefined) {
      updatedErrorSummary.push({
        message: "Select if you approve or reject this face likeness",
        id: "radio-Face scan-yes",
        type: IdCheckRadioErrorType["Not answered"],
      });
      this.faceScanRadioValid = false;
      return;
    }

    if (
      this.faceScanAnswer === 0 &&
      this.idCheckFailures.findIndex((x) => x.document === this.faceScan) === -1
    ) {
      updatedErrorSummary.push({
        message: "Select reason(s) for rejecting",
        id: "radio-Face scan-yes",
        type: IdCheckRadioErrorType["No reason selected"],
      });
      this.faceScanRadioValid = false;
      return;
    }

    this.faceScanRadioValid = true;
    return updatedErrorSummary;
  }

  checkSmartcardPhotoIsValid(updatedErrorSummary) {
    if (this.smartcardPhotoAnswer === undefined) {
      updatedErrorSummary.push({
        message: "Select if you approve or reject this smartcard photo",
        id: "radio-Smartcard photo-yes",
        type: IdCheckRadioErrorType["Not answered"],
      });
      this.smartcardPhotoRadioValid = false;
      return;
    }

    if (
      this.smartcardPhotoAnswer === 0 &&
      this.idCheckFailures.findIndex(
        (x) => x.document === this.smartcardPhoto,
      ) === -1
    ) {
      updatedErrorSummary.push({
        message: "Select reason(s) for rejecting",
        id: "radio-Smartcard photo-yes",
        type: IdCheckRadioErrorType["No reason selected"],
      });
      this.smartcardPhotoRadioValid = false;
      return;
    }

    this.smartcardPhotoRadioValid = true;
    return updatedErrorSummary;
  }

  checkForRadioUnselected(): void {
    const updatedErrorSummary = this.summaryErrorMessages;

    if (
      this.invitation.cis_duplicate_match &&
      this.invitation.cis_duplicate_matching_fields &&
      (this.invitation.status == 50 || this.invitation.status == 51)
    ) {
      this.checkDuplicateProfileValid(updatedErrorSummary);
    }
    if (this.duplicateProfileAnswer === 1) return;
    this.checkPhotoIdOneValid(updatedErrorSummary);
    this.checkPhotoIdTwoValid(updatedErrorSummary);
    this.checkPoaIsValid(updatedErrorSummary);
    this.checkNameAndDobIsValid(updatedErrorSummary);
    this.checkFaceScanIsValid(updatedErrorSummary);
    this.checkSmartcardPhotoIsValid(updatedErrorSummary);
  }

  searchSummaryErrorMessages(id: string): string {
    let errorForId = this.summaryErrorMessages.find((x) => x.id === id);
    if (errorForId == undefined) {
      return "";
    } else {
      return errorForId;
    }
  }

  mapProfilePhoto(): void {
    try {
      let profilePhoto = this.images.find(
        (element) => element.name === "profile_photo",
      );
      // if no profile photo
      if (profilePhoto.data == null) {
        console.log("No profile photo image");
        return;
      }
      profilePhoto.displayName = this.profilePhoto;
      if (
        this.invitation.smartcardPhoto == null ||
        this.invitation.smartcardPhoto === "profile_photo"
      ) {
        this.selectedSmartcardPhoto = profilePhoto;
      }
      this.profilePhotoImg = profilePhoto.data;
      if (this.profilePhotoImg !== null) {
        this.availableSmartcardImages.push(profilePhoto);
      }
      this.imageBase64 = profilePhoto.data;
      this.afcidImageCropper.imageBase64 = profilePhoto.data;
    } catch {
      console.log("No profile photo image");
    }
  }

  mapEchip(): void {
    try {
      let eChip = this.images.find((element) => element.name === "echip");
      if (eChip.data == null) {
        console.log("No eChip image");
        return;
      }

      eChip.displayName = this.eChipPhoto;
      if (this.selectedSmartcardPhoto == undefined) {
        this.selectedSmartcardPhoto = eChip;
      }

      this.eChipImg = eChip.data;
      if (this.eChipImg !== null) {
        this.availableSmartcardImages.push(eChip);
      }
    } catch {
      console.log("No eChip image");
    }
  }

  mapFaceScan(): void {
    try {
      let faceScan = this.images.find(
        (element) => element.name === "face_scan",
      );
      faceScan.displayName = this.faceScan;
      if (this.invitation.smartcardPhoto === "face_scan") {
        this.selectedSmartcardPhoto = faceScan;
      }
      this.faceScanImg = faceScan.data;
      if (this.availableSmartcardImages.length === 0) {
        this.selectedSmartcardPhoto = faceScan;
      }
      if (this.faceScanImg !== null) {
        this.availableSmartcardImages.push(faceScan);
      }
    } catch {
      console.log("No Face scan image");
    }
  }

  mapExtractedPassport(): void {
    try {
      let extractedPassportPhoto = this.images.find(
        (element) => element.name === "extracted_passport",
      );
      extractedPassportPhoto.displayName = this.extractedPassportPhoto;
      this.extractedPassportImg = extractedPassportPhoto.data;
      if (this.extractedPassportImg !== null) {
        this.availableSmartcardImages.push(extractedPassportPhoto);
      }
    } catch {
      console.log("No extracted passport photo image");
    }
  }

  mapPoa(): void {
    try {
      this.poaImg = this.images.find(
        (element) => element.name === "secondary",
      ).data;
    } catch {
      console.log("No POA image");
    }
  }

  mapPhotoIdOneFrontAndBack(): void {
    try {
      this.photoIdOneImgFront = this.images.find(
        (element) => element.name === "photo_id_one_front",
      ).data;
    } catch {
      console.log("No Photo ID One Front");
    }
    try {
      this.photoIdOneImgBack = this.images.find(
        (element) => element.name === "photo_id_one_back",
      ).data;
    } catch {
      console.log("No Photo ID One Back");
    }
  }

  mapPhotoIdTwoFrontAndBack(): void {
    try {
      this.photoIdTwoImgFront = this.images.find(
        (element) => element.name === "photo_id_two_front",
      ).data;
    } catch {
      console.log("No Photo ID Two Front");
    }
    try {
      this.photoIdTwoImgBack = this.images.find(
        (element) => element.name === "photo_id_two_back",
      ).data;
    } catch {
      console.log("No Photo ID Two Back");
    }
  }

  mapImages(): void {
    this.mapProfilePhoto();
    this.mapEchip();
    this.mapFaceScan();
    this.mapExtractedPassport();
    this.mapPoa();
    this.mapPhotoIdOneFrontAndBack();
    this.mapPhotoIdTwoFrontAndBack();
  }

  validateAnswers(): void {
    const visibleControls = [
      this.photoIdOneAnswer,
      this.nameAndDobAnswer,
      this.faceScanAnswer,
      this.smartcardPhotoAnswer,
    ];
    if (this.poaRouteTaken)
      visibleControls.push(this.photoIdTwoAnswer, this.proofOfAddressAnswer);
    const photoIdTwoApproved =
      (this.poaRouteTaken && this.photoIdTwoAnswer === 1) ||
      (this.invitation.dpoaUsed && this.photoIdTwoAnswer === undefined);
    const proofOfAddressApproved =
      (this.poaRouteTaken && this.proofOfAddressAnswer === 1) ||
      (this.invitation.dpoaUsed && this.proofOfAddressAnswer === undefined);
    const duplicateProfileApproved =
      !this.invitation.cis_duplicate_match || this.duplicateProfileAnswer === 0;
    if (
      [
        this.photoIdOneAnswer === 1,
        photoIdTwoApproved,
        proofOfAddressApproved,
        this.nameAndDobAnswer === 1,
        this.faceScanAnswer === 1,
        this.smartcardPhotoAnswer === 1,
        duplicateProfileApproved,
      ].every(Boolean)
    ) {
      this.approved = true;
      this.rejected = false;
      this.invitation.idCheckedBy = this.currentUser;
      this.invitation.idCheckedByUserId = this.currentUserId;
    } else {
      this.approved = false;
      if (
        visibleControls.filter((x) => x === 0).length ||
        this.duplicateProfileAnswer === 1
      ) {
        this.rejected = true;
      } else {
        this.rejected = false;
      }
    }
  }

  isPhotoIdOneFrontExpanded(isExpanded: boolean) {
    this.photoIdOneFrontExpand = isExpanded;
  }

  isPhotoIdOneBackExpanded(isExpanded: boolean) {
    this.photoIdOneBackExpand = isExpanded;
  }

  isPhotoIdTwoFrontExpanded(isExpanded: boolean) {
    this.photoIdTwoFrontExpand = isExpanded;
  }

  isPhotoIdTwoBackExpanded(isExpanded: boolean) {
    this.photoIdTwoBackExpand = isExpanded;
  }

  isPoaExpanded(isExpanded: boolean) {
    this.poaExpand = isExpanded;
  }

  saveSelections(): void {
    let selections: Selections = {
      invitation_id: this.invitationId,
    };
    selections.duplicateProfileValue = this.duplicateProfileInput?.answer;
    selections.photo1value = this.photoIdOneInput?.answer;
    selections.photo2value = this.photoIdTwoInput?.answer;
    selections.poaValue = this.proofOfAddressInput?.answer;
    selections.nameDobValue = this.nameAndDobInput?.answer;
    selections.faceValue = this.faceScanInput?.answer;
    selections.smartcardValue = this.smartcardPhotoInput?.answer;
    selections.idCheckFailureValue = this.idCheckFailures;
    this.idcheckerSelections = new IdcheckerSelections(selections);

    this.setSessionIdCheckerSelections();
  }

  setSessionIdCheckerSelections(): void {
    this.sessionStorage.set("idchecker-selections", this.idcheckerSelections);
  }

  approveButtonClicked(): void {
    this.summaryErrorMessages = Array<ErrorSummaryMessage>();
    this.checkForRadioUnselected();

    if (
      this.photoIdOneRadioValid &&
      this.smartcardPhotoRadioValid &&
      (this.poaRadioValid || !this.poaRouteTaken) &&
      this.faceScanRadioValid &&
      this.nameAndDobRadioValid &&
      this.photoIdTwoRadioValid
    ) {
      this.saveSelections();
      this.invitation.smartcardPhoto = this.selectedSmartcardPhoto.name;

      this.storeCroppedPhotoErrorMessage = "";
      this.storeCroppedPhotoInProgress = true;
      const base64 = this.croppedImage.toString().split(",")[1];

      this.idCheckerService
        .postBase64CroppedPhoto(this.invitationId, base64)
        .subscribe({
          next: () => {
            this.routerService.redirectToWithIdAndExtas(
              "id-checker/confirm-details",
              this.invitation.invitationId,
              { state: this.invitation },
            );
          },
          error: (err) => {
            this.storeCroppedPhotoInProgress = false;
            this.storeCroppedPhotoErrorMessage = err.error;
          },
          complete: () => {
            this.storeCroppedPhotoInProgress = false;
          },
        });
    } else {
      this.scrollToTop();
    }
  }

  async rejectButtonClicked(): Promise<void> {
    this.summaryErrorMessages = Array<ErrorSummaryMessage>();
    this.checkForRadioUnselected();

    if (this.duplicateProfileAnswer === 1) {
      this.invitation.failedReason = [];
      this.routerService.redirectToWithIdAndExtas(
        "id-checker/reject-application",
        this.invitation.invitationId,
        { state: this.invitation },
      );
    } else if (
      this.photoIdOneRadioValid &&
      this.smartcardPhotoRadioValid &&
      (this.poaRadioValid || !this.poaRouteTaken) &&
      this.faceScanRadioValid &&
      this.nameAndDobRadioValid &&
      this.photoIdTwoRadioValid
    ) {
      this.saveSelections();
      this.routerService.redirectToWithIdAndExtas(
        "id-checker/reject-application",
        this.invitation.invitationId,
        { state: this.invitation },
      );
    } else {
      this.scrollToTop();
    }
  }

  holdButtonClicked(): void {
    if (this.invitation.status === 51 || this.approved === true) {
      this.summaryErrorMessages = Array<ErrorSummaryMessage>();
      if (this.invitation.status === 51) {
        this.summaryErrorMessages.push({
          message: "Invitation is already on hold",
          id: "invitation on hold",
        });
      }
      if (this.approved) {
        this.summaryErrorMessages.push({
          message: "Reject relevant documents before holding the application",
          id: "Reject before hold",
        });
      }
      this.scrollToTop();
    } else {
      this.saveSelections();
      this.routerService.redirectToWithId(
        "id-checker/hold-application",
        this.invitation.invitationId,
      );
    }
  }

  scrollToTop(): void {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }

  getSecondsTillExpiry(expiryTime: string): number {
    let now = Date.now();
    let expiry = Date.parse(expiryTime);
    return Math.floor((expiry - now) / 1000);
  }

  checkIfExpired(secondsTillExpired: number): boolean {
    return secondsTillExpired <= 0 ? true : false;
  }

  applicationInReview(reviewInProgress: boolean) {
    combineLatest([
      timer(1000),
      this.idCheckerService.postIdCheckInProgressCheck(
        this.invitationId,
        reviewInProgress,
        this.currentUser,
      ),
    ])
      .pipe(map((x) => x[1]))
      .subscribe(
        (response) => {
          if (response.body != null) {
            this.invitation.reviewExpiryUtc =
              response.body["review_expiry_utc"].toString();
          }
        },
        (error) => {
          this.routerService.handleErrorAuthRoutes(error.status);
        },
      );
  }

  getRejectionReasonsState(): void {
    this.idCheckFailureSubscription = this.store
      .select(getIdCheckerRejection)
      .subscribe((idCheckFailures) => {
        this.idCheckFailures = Object.assign([], idCheckFailures);
        this.storage
          .set("idCheckFailures", this.idCheckFailures)
          .subscribe(() => {});
      });
  }

  resetRejectionReasonState(): void {
    this.store.dispatch(new UpdateIdCheckerRejectionAction([]));
  }

  faceLikenessLowerThanThreshold(): void {
    if (
      this.invitation.matchResults === undefined ||
      this.invitation.matchResults.length === 0
    ) {
      this.faceLikenessLowerThanRequiredThreshold = false;
      return;
    }

    this.invitation.matchResults.forEach((result) => {
      if (result.value < 0.85) {
        this.faceLikenessLowerThanRequiredThreshold = true;
      }
    });
  }

  onStartReview() {
    this.reviewModal = false;
  }

  isPhotoIdOneExpired(): boolean {
    if (this.invitation.photoIdOneDateOfExpiry == null) {
      return false;
    } else {
      return new Date(this.invitation.photoIdOneDateOfExpiry) < new Date();
    }
  }

  isPhotoIdTwoExpired(): boolean {
    if (this.invitation.photoIdTwoDateOfExpiry == null) {
      return false;
    } else {
      return new Date(this.invitation.photoIdTwoDateOfExpiry) < new Date();
    }
  }

  selectApprovedSmartcardPhoto() {
    if (this.invitation && this.invitation.status == 52) {
      for (const item of this.availableSmartcardImages) {
        if (item.name == this.invitation.smartcardPhoto) {
          this.selectedSmartcardPhoto = item;
          break;
        }
      }
    }
  }

  onPhotoChange(event) {
    this.imageBase64 = event.data;
  }

  updateCroppedImage(base64: string): void {
    this.croppedImage = base64;
    const filesize = (base64.length / 1000).toFixed(2);
    this.fileSizeEstimate = filesize + " kb";
  }

  getFullInvitedName() {
    return [
      this.invitation.inviterEnteredGivenName,
      this.invitation.inviterEnteredFamilyName,
    ].join(" ");
  }

  getFullApplicantEnteredName() {
    return [
      this.invitation.givenName,
      this.invitation.middleNames,
      this.invitation.familyName,
    ].join(" ");
  }

  getCimDuplicateLink() {
    const cimEnv = this.sessionStorage.get("cimEnv");
    return `${cimEnv}/#/view-profile/${this.invitation.cis_duplicate_match}`;
  }

  getIdNoByType(type: "Passport" | "Driving license") {
    if (this.invitation.photoIdOneType === type) {
      return this.invitation.photoIdOneNo;
    }
    if (this.invitation.photoIdTwoType === type) {
      return this.invitation.photoIdTwoNo;
    }
  }
}
