<breadcrumb-link
  [breadcrumbRoute]="breadcrumbRoute"
  [breadcrumbText]="breadcrumbText"
></breadcrumb-link>

<div class="nhsuk-grid-row">
  <div *ngIf="invitationConfirmation" class="nhsuk-grid-column-two-thirds">
    <error-summary
      [errorSummaryMessages]="summaryErrorMessages"
    ></error-summary>
    <h1 autofocus tabindex="-1" class="nhsuk-heading-xl">
      Enter name on smartcard (optional)
    </h1>
    <label
      class="nhsuk-label nhsuk-u-padding-bottom-3"
      for="preferred-name-input"
      >You can choose a name to appear on your smartcard (if applicable).</label
    >
    <div>
      <text-input
        #preferredNameInput
        [id]="'preferred-name-input'"
        hint="For example, if your name is Mathew Smith you can choose Matt Smith."
        size="nhsuk-input--width-20"
        [capitalisation]="true"
        [characterCount]="charactersRemaining"
        [errorMessage]="preferredNameErrorMessage"
        [value]="invitationConfirmation.preferredName"
        (questionAnswer)="updatePreferredNameAnswer($event)"
        cypressValue="preffered-name-input"
      ></text-input>
    </div>

    <div class="nhsuk-u-padding-bottom-3"></div>
    <button
      class="nhsuk-button nhsuk-u-margin-bottom-5"
      (click)="submitAnswers()"
      id="continue"
      data-cy="continue-button"
    >
      Continue
    </button>
  </div>
</div>
