import { Action } from "@ngrx/store";
import { Invitation } from "../states/spa.state";

export const UPDATE_INVITATION = "Update Invitation";

export class UpdateInvitationAction implements Action {
  readonly type = UPDATE_INVITATION;
  payload: Invitation;

  constructor(payload: Invitation) {
    this.payload = payload;
  }
}

export type SPAActions = UpdateInvitationAction;
