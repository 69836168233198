import { AddressDetails } from "./addressDetails";

export class Address {
  address: string;
  countryCode: string;
  thoroughfareName: string;
  buildingName?: string;
  buildingNumber?: string;
  postTown: string;

  id: number;

  constructor(address: AddressDetails, index) {
    this.address = address.ADDRESS;
    this.countryCode = address.COUNTRY_CODE;
    this.buildingNumber = address.BUILDING_NUMBER;
    this.thoroughfareName = address.THOROUGHFARE_NAME;
    this.buildingName = address.BUILDING_NAME;
    this.postTown = address.POST_TOWN;
    this.id = index;
  }
}
