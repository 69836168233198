<div class="nhsuk-grid-row">
  <div class="nhsuk-grid-column-two-thirds">
    <h1 autofocus tabindex="-1">Incorrect security code</h1>

    <p>An incorrect security code has been entered too many times.</p>

    <p>
      You will need a new email with a new security code link to continue. This
      will be sent to you shortly.
    </p>

    <p>
      If you do not receive an email. contact
      <a target="_blank" href="mailto:{{ supportEmail }}" rel="noopener">{{
        supportEmail
      }}</a
      >. Include your application ID and a detailed description of the problem.
      This will help us to support you.
    </p>
  </div>
</div>
