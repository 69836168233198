import { ActionReducerMap, createFeatureSelector } from "@ngrx/store";
import { SPAState } from "./../states/spa.state";
import { InviteeSPAState } from "./../states/invitee-spa.state";
import { reducerSPA } from "./spa.reducers";
import { reducerInviteeSPA } from "./invitee-spa.reducers";
import { reducerIdCheckerSPA } from "./id-checker-spa.reducers";
import { IdCheckerRejectionSPAState } from "../states/id-checker-spa.state";

export interface SpaState {
  spa: SPAState;
  inviteeSpa: InviteeSPAState;
  idCheckerSpa: IdCheckerRejectionSPAState;
}

export const reducers: ActionReducerMap<SpaState> = {
  spa: reducerSPA,
  inviteeSpa: reducerInviteeSPA,
  idCheckerSpa: reducerIdCheckerSPA,
};

export const getSPAState = createFeatureSelector<SpaState>("SPA");
