import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { RouterService } from "src/app/services/router.service";
import { InvitationConfirmation } from "src/store/states/invitee-spa.state";
import { ErrorSummaryMessage } from "../../../models/error-summary-message";
import { InviteeDetails } from "src/app/utils/invitee-details";

@Component({
  selector: "app-passport",
  templateUrl: "./passport.component.html",
})
export class PassportComponent implements OnInit {
  hasPassport: boolean;
  invitationConfirmation: InvitationConfirmation;
  @ViewChild("passportYes", { static: true }) passportYes: ElementRef;

  constructor(
    private title: Title,
    private routerService: RouterService,
    private inviteeDetails: InviteeDetails,
  ) {}

  summaryErrorMessages = [];

  async ngOnInit(): Promise<void> {
    this.title.setTitle("Which photo ID do you want to use? - passport");
    this.invitationConfirmation = this.inviteeDetails.getInviteeDetails();

    if (this.invitationConfirmation.photoOneId == "Passport") {
      this.passportYes.nativeElement.click();
    }
  }

  updateErrorSummary() {
    const updatedErrorSummary = Array<ErrorSummaryMessage>();

    if (this.hasPassport === undefined) {
      updatedErrorSummary.push({
        message: "Select if you have a passport.",
        id: "passport-select",
      });
    }

    this.summaryErrorMessages = updatedErrorSummary;
  }

  updatePassportRadioValue(value: boolean) {
    this.hasPassport = value;
    if (value) {
      this.invitationConfirmation.photoOneId = "Passport";
    } else {
      this.invitationConfirmation.photoOneId = "";
    }
    this.invitationConfirmation.poaType = "";
    this.invitationConfirmation.photoTwoId = "";
    this.updateErrorSummary();
  }

  onContinueButtonClicked(): void {
    this.updateErrorSummary();
    if (this.hasPassport != undefined) {
      this.inviteeDetails.setInviteeDetails(this.invitationConfirmation);
      if (this.hasPassport) {
        this.routerService.redirectToProfilePhotoUpload();
      } else {
        this.routerService.redirectToPhotoIdOne();
      }
    }
  }
}
