import { HttpClient, HttpResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { IdCheckerMetrics } from "../models/idCheckerMetrics";
import { EnvironmentConfigService } from "./environment-config.service";
import { SESSION_STORAGE, StorageService } from "ngx-webstorage-service";
import { SessionAuthenticationService } from "./session-authentication.service";
import {
  InvitationDetails,
  InvitationStatus,
  IdCheckImage,
} from "../models/invitationDetails";
import { Portal } from "src/app/models/session-token";
import { IdCheckInProgressCheck } from "../models/idCheckInProgresCheck";

@Injectable()
export class IdCheckerService {
  constructor(
    private http: HttpClient,
    private environmentConfigService: EnvironmentConfigService,
    private sessionAuthenticationService: SessionAuthenticationService,
    @Inject(SESSION_STORAGE) private storage: StorageService,
  ) {}

  getIdCheckerMetrics(): Observable<HttpResponse<IdCheckerMetrics>> {
    return this.http.get<IdCheckerMetrics>(
      `${
        this.environmentConfigService.getConfig().idBridgeApiUrl
      }/idChecker/idCheckerMetrics`,
      {
        observe: "response",
        responseType: "json",
        headers: this.sessionAuthenticationService.applyAuthorizationHeader(
          Portal.Checker,
        ),
      },
    );
  }

  getNextHeldApplication(): Observable<HttpResponse<InvitationDetails>> {
    return this.http.get<InvitationDetails>(
      `${
        this.environmentConfigService.getConfig().idBridgeApiUrl
      }/idChecker/nextHeldApplication`,
      {
        observe: "response",
        responseType: "json",
        headers: this.sessionAuthenticationService.applyAuthorizationHeader(
          Portal.Checker,
        ),
      },
    );
  }

  getIdCheckerImages(
    invitationId: string,
  ): Observable<HttpResponse<IdCheckImage[]>> {
    return this.http.get<IdCheckImage[]>(
      `${
        this.environmentConfigService.getConfig().idBridgeApiUrl
      }/idChecker/idCheckerImages?invitation_id=${invitationId}`,
      {
        observe: "response",
        responseType: "json",
        headers: this.sessionAuthenticationService.applyAuthorizationHeader(
          Portal.Checker,
        ),
      },
    );
  }

  getProfilePhoto(
    invitationId: string,
  ): Observable<HttpResponse<IdCheckImage[]>> {
    return this.http.get<IdCheckImage[]>(
      `${
        this.environmentConfigService.getConfig().idBridgeApiUrl
      }/idChecker/idCheckerProfilePhoto?invitation_id=${invitationId}`,
      {
        observe: "response",
        responseType: "json",
        headers: this.sessionAuthenticationService.applyAuthorizationHeader(
          Portal.Checker,
        ),
      },
    );
  }

  getIdCheckerInvitations(
    status: InvitationStatus,
  ): Observable<HttpResponse<InvitationDetails[]>> {
    return this.http.get<InvitationDetails[]>(
      `${
        this.environmentConfigService.getConfig().idBridgeApiUrl
      }/invitation?invitation_status=${status}`,
      {
        observe: "response",
        responseType: "json",
        headers: this.sessionAuthenticationService.applyAuthorizationHeader(
          Portal.Checker,
        ),
      },
    );
  }

  searchIdCheckerInvitations(): Observable<HttpResponse<InvitationDetails[]>> {
    return this.http.get<InvitationDetails[]>(
      `${
        this.environmentConfigService.getConfig().idBridgeApiUrl
      }/idChecker/search`,
      {
        observe: "response",
        headers: this.sessionAuthenticationService.applyAuthorizationHeader(
          Portal.Checker,
        ),
      },
    );
  }

  postIdCheckInProgressCheck(
    invitationId: string,
    reviewInProgress: boolean,
    currentUser: string,
  ): Observable<HttpResponse<IdCheckInProgressCheck>> {
    return this.http.post<IdCheckInProgressCheck>(
      `${
        this.environmentConfigService.getConfig().idBridgeApiUrl
      }/idChecker/idCheckInProgressCheck`,
      {
        invitation_id: invitationId,
        review_in_progress: reviewInProgress,
        current_user: currentUser,
      },
      {
        observe: "response",
        responseType: "json",
        headers: this.sessionAuthenticationService.applyAuthorizationHeader(
          Portal.Checker,
        ),
      },
    );
  }

  postIdCheckInProgressReset(): Observable<HttpResponse<boolean>> {
    return this.http.post<boolean>(
      `${
        this.environmentConfigService.getConfig().idBridgeApiUrl
      }/idChecker/resetInReviewExpired`,
      {},
      {
        observe: "response",
        headers: this.sessionAuthenticationService.applyAuthorizationHeader(
          Portal.Checker,
        ),
      },
    );
  }

  postIdCheckInternalNotes(
    invitationId: string,
    note: string,
    noteId?: string,
  ): Observable<HttpResponse<boolean>> {
    return this.http.post<boolean>(
      `${
        this.environmentConfigService.getConfig().idBridgeApiUrl
      }/idChecker/internalNotes`,
      { invitation_id: invitationId, note: note, note_id: noteId },
      {
        observe: "response",
        headers: this.sessionAuthenticationService.applyAuthorizationHeader(
          Portal.Checker,
        ),
      },
    );
  }

  deleteIdCheckInternalNote(
    invitationId: string,
    noteId: string,
  ): Observable<HttpResponse<boolean>> {
    return this.http.post<boolean>(
      `${
        this.environmentConfigService.getConfig().idBridgeApiUrl
      }/idChecker/deleteInternalNote`,
      { invitation_id: invitationId, note_id: noteId },
      {
        observe: "response",
        headers: this.sessionAuthenticationService.applyAuthorizationHeader(
          Portal.Checker,
        ),
      },
    );
  }

  modifiedRecordCheck(
    invitationId: string,
    status: InvitationStatus,
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post(
          `${
            this.environmentConfigService.getConfig().idBridgeApiUrl
          }/idChecker/modifiedRecordCheck`,
          {
            invitation_id: invitationId,
            status: status,
          },
          {
            headers: this.sessionAuthenticationService.applyAuthorizationHeader(
              Portal.Checker,
            ),
            observe: "body",
            responseType: "json",
            withCredentials: true,
          },
        )
        .subscribe(
          (res) => {
            resolve(res);
          },
          (error) => {
            reject(error);
          },
        );
    });
  }

  postBase64CroppedPhoto(
    invitationId: string,
    base64: string,
  ): Observable<HttpResponse<boolean>> {
    return this.http.post<boolean>(
      `${
        this.environmentConfigService.getConfig().idBridgeApiUrl
      }/idChecker/setBase64CroppedPhoto`,
      { invitation_id: invitationId, base_64: base64 },
      {
        observe: "response",
        headers: this.sessionAuthenticationService.applyAuthorizationHeader(
          Portal.Checker,
        ),
      },
    );
  }
}
