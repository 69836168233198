<div *ngIf="invitationConfirmation" class="nhsuk-grid-row">
  <div class="nhsuk-grid-column-two-thirds">
    <breadcrumb-link
      [breadcrumbRoute]="breadcrumbRoute"
      [breadcrumbText]="breadcrumbText"
    ></breadcrumb-link>
    <h1 autofocus tabindex="-1">Check your details</h1>
    <error-summary
      [errorSummaryMessages]="summaryErrorMessages"
    ></error-summary>
    <dl class="nhsuk-summary-list">
      <div class="nhsuk-summary-list__row">
        <dt id="name" class="nhsuk-summary-list__key">Full name</dt>
        <dd class="nhsuk-summary-list__value" data-cy="name-display">
          {{ invitationConfirmation.firstName }}
          {{ invitationConfirmation.middleNames }}
          {{ invitationConfirmation.familyName }}
        </dd>
        <dd class="nhsuk-summary-list__actions">
          <a
            aria-describedby="name"
            [state]="invitationConfirmation"
            [routerLink]="['/invitee/confirm-name']"
          >
            Change
          </a>
        </dd>
      </div>

      <div class="nhsuk-summary-list__row">
        <dt id="preferred-name" class="nhsuk-summary-list__key">
          Name on smartcard
        </dt>
        <dd class="nhsuk-summary-list__value" data-cy="preferred-name-display">
          {{ invitationConfirmation.preferredName }}
        </dd>
        <dd class="nhsuk-summary-list__actions">
          <a
            aria-describedby="preferred-name"
            [state]="invitationConfirmation"
            [routerLink]="['/invitee/confirm-preferred-name']"
          >
            Change
          </a>
        </dd>
      </div>

      <div class="nhsuk-summary-list__row">
        <dt id="dob" class="nhsuk-summary-list__key">Date of birth</dt>
        <dd class="nhsuk-summary-list__value">
          {{ invitationConfirmation.dob | date: "dd/MM/yyyy" }}
        </dd>
        <dd class="nhsuk-summary-list__actions">
          <a
            aria-describedby="dob"
            [state]="invitationConfirmation"
            [routerLink]="['/invitee/confirm-dob']"
          >
            Change
          </a>
        </dd>
      </div>
      <div class="nhsuk-summary-list__row">
        <dt id="ni" class="nhsuk-summary-list__key">
          National Insurance number
        </dt>
        <dd class="nhsuk-summary-list__value" data-cy="nino-display">
          {{ invitationConfirmation.niNumber }}
        </dd>
        <dd class="nhsuk-summary-list__actions">
          <a
            aria-describedby="ni"
            [state]="invitationConfirmation"
            [routerLink]="['/invitee/confirm-ni-number']"
          >
            Change
          </a>
        </dd>
      </div>
    </dl>
    <div class="nhsuk-form-group">
      <p>
        Check and confirm the information you have provided is correct before
        you submit it.
      </p>
      <button
        data-cy="submit-duplicate-check"
        type="submit"
        [ngClass]="{ 'spinner-button': submitButtonClicked }"
        [disabled]="submitButtonClicked"
        (click)="onSubmitButtonClicked()"
        class="nhsuk-button"
      >
        Submit now
      </button>

      <div>
        <ul>
          <li class="nhsuk-error-message" *ngFor="let err of validationErrors">
            {{ err.msg }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
