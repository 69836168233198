<div class="nhsuk-grid-row">
  <div class="nhsuk-grid-column-two-thirds">
    <h1 autofocus tabindex="-1" data-cy="download-title">
      Download the NHS Verify Care ID app
    </h1>
    <p>
      A link for the Verify Care ID app has been sent to you by text message
      <span *ngIf="mobile != undefined"
        >to *********<strong>{{ mobile }}</strong></span
      >
    </p>
    <p>Use the link to download the app.</p>

    <p>The app will photograph your ID document(s) and scan your face.</p>

    <p>You will need:</p>

    <ul>
      <li>{{ docsRequired }}</li>
    </ul>

    <p>You can now close this page.</p>

    <details class="nhsuk-details">
      <summary class="nhsuk-details__summary">
        <span class="nhsuk-details__summary-text">
          What to do if you have not received the link
        </span>
      </summary>
      <div class="nhsuk-details__text">
        <p>The link should be sent to you within 5 minutes.</p>
        <p>
          If you have not received a text message with the link, contact
          <a
            href="mailto:ssd.nationalservicedesk@nhs.net?subject=Verify Care ID app link not received"
            >ssd.nationalservicedesk&#64;nhs.net</a
          >
          with the subject line "Verify Care ID app link not received".
        </p>
      </div>
    </details>
  </div>
</div>
