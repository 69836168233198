import { Component, Inject, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { UserSettingsService } from "src/app/services/user-settings.service";
import { combineLatest } from "rxjs";
import { UserSettings } from "src/app/models/userSettings";
import { RouterService } from "src/app/services/router.service";
import { map } from "rxjs/operators";
import { SESSION_STORAGE, StorageService } from "ngx-webstorage-service";

@Component({
  selector: "app-settings",
  templateUrl: "./settings.component.html",
  styleUrls: [],
})
export class SettingsComponent implements OnInit {
  breadcrumbRoute = "/portal-select";
  breadcrumbText = "Back";
  constructor(
    private routerService: RouterService,
    private title: Title,
    private userSettings: UserSettingsService,
    @Inject(SESSION_STORAGE) private sessionStorage: StorageService,
  ) {}
  loading: boolean;
  settings: UserSettings;
  supportEmail: string = "";
  contactEmail: string = "";
  supportEmailVerified: boolean = false;
  contactEmailVerified: boolean = false;

  async ngOnInit(): Promise<void> {
    this.title.setTitle("User settings");
    await this.getSettings();
  }

  async getSettings() {
    combineLatest([this.userSettings.getSettings()])
      .pipe(map((x) => x[0]))
      .subscribe({
        next: (response) => {
          this.settings = this.userSettings.mapUserSettings(response["body"]);
          this.supportEmail = this.settings.supportEmail;
          this.contactEmail = this.settings.contactEmail;
          this.supportEmailVerified = this.settings.supportEmailVerified;
          this.contactEmailVerified = this.settings.contactEmailVerified;

          if (this.contactEmailVerified && this.supportEmailVerified) {
            this.sessionStorage.set("contactEmail", this.settings.contactEmail);
          }

          this.loading = false;
        },
        error: (error) => {
          this.routerService.handleErrorAuthRoutes(error.status);
        },
      });
  }
}
