import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { EnvironmentConfigService } from "../environment-config.service";
import { SessionAuthenticationService } from "../session-authentication.service";
import { Portal } from "src/app/models/session-token";
import { Observable } from "rxjs";

@Injectable()
export class BatchUploadService {
  constructor(
    private http: HttpClient,
    private environmentConfigService: EnvironmentConfigService,
    private sessionAuthenticationService: SessionAuthenticationService,
  ) {}

  uploadFile(file: FormData): Observable<string> {
    return this.http.post<string>(
      `${
        this.environmentConfigService.getConfig().idBridgeApiUrl
      }/batchFileUpload`,
      file,
      {
        headers: this.sessionAuthenticationService.applyAuthorizationHeader(
          Portal.Invitation,
        ),
        observe: "body",
        responseType: "json",
      },
    );
  }

  processBatch(batchId: string): Observable<string> {
    return this.http.post<string>(
      `${
        this.environmentConfigService.getConfig().idBridgeApiUrl
      }/processBatch`,
      { batch_id: batchId },
      {
        headers: this.sessionAuthenticationService.applyAuthorizationHeader(
          Portal.Invitation,
        ),
        observe: "body",
        responseType: "json",
      },
    );
  }
}
