<div class="nhsuk-grid-row">
  <div class="nhsuk-grid-column-two-thirds">
    <h1 autofocus tabindex="-1">Your cookie settings have been saved</h1>

    <p>We'll save your settings for a year.</p>

    <p>We'll ask you if you're still OK with us using cookies when either:</p>

    <ul>
      <li>it's been a year since you last saved your settings</li>
      <li>we add any new cookies or change the cookies we use</li>
    </ul>

    <p>
      You can also
      <a href="/cookies-preference">choose which cookies we use</a> at any time.
    </p>
  </div>
</div>
