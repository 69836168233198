<div class="cookie-banner" *ngIf="displayBanner">
  <div class="nhsuk-width-container">
    <div class="nhsuk-grid-row">
      <div class="nhsuk-grid-column-full">
        <h2 class="nhsuk-heading-m nhsuk-u-padding-top-4">
          Cookies on Apply for Care ID
        </h2>
        <p>
          We've put some small files called cookies on your device to make our
          site work.
        </p>
        <p>
          We'd also like to use analytics cookies. These send anonymous
          information about how our site is used to a service called Adobe
          Analytics. We use this information to improve our site.
        </p>
        <p>
          Let us know if this is OK. We'll use a cookie to save your choice. You
          can
          <a href="/cookies-preference">read more about our cookies</a> before
          you choose.
        </p>
        <a
          class="nhsuk-button nhsuk-u-margin-right-3 nhsuk-u-margin-bottom-4"
          data-cy="analytics-yes"
          (click)="buttonAcceptClick()"
          >I'm OK with analytics cookies</a
        >
        <a
          class="nhsuk-button"
          (click)="buttonRejectClick()"
          data-cy="analytics-no"
          >Do not use analytics cookies</a
        >
      </div>
    </div>
  </div>
</div>

<div
  class="cookie-success-banner"
  *ngIf="!displayBanner && consentPreferenceSet"
>
  <div class="nhsuk-width-container">
    <div class="nhsuk-grid-row">
      <div class="nhsuk-grid-column-three-quarters">
        <p
          class="nhsuk-u-padding-top-3 nhsuk-u-padding-bottom-3 nhsuk-u-margin-bottom-0"
        >
          You can change your cookie settings at any time using our
          <a
            class="nhsuk-link nhsuk-link--no-visited-state white-link"
            href="/cookies-preference"
            >cookies page</a
          >
        </p>
      </div>
    </div>
  </div>
</div>
