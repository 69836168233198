export class DpoaCheck {
  matchLevel: string;
  identityResult: string;
  identityScore: string;
  ncoaAlertFlag: boolean;
  numPrimaryChecks: number;
  confirmatoryDobs: number;
  warnAddressLinks?: boolean;
  warnDeceased?: boolean;
  warnLor?: boolean;

  constructor(dpoaCheck: DpoaCheckDetails) {
    this.matchLevel = dpoaCheck.match_level;
    this.identityResult = dpoaCheck.identity_result;
    this.identityScore = dpoaCheck.identity_score;
    this.ncoaAlertFlag = dpoaCheck.ncoa_alert_flag;
    this.numPrimaryChecks = dpoaCheck.num_primary_checks;
    this.confirmatoryDobs = dpoaCheck.confirmatory_dobs;
    this.warnAddressLinks = dpoaCheck.warn_addresslinks;
    this.warnDeceased = dpoaCheck.warn_deceased;
    this.warnLor = dpoaCheck.warn_lor;
  }
}

export interface DpoaCheckDetails {
  match_level: string;
  identity_result: string;
  identity_score: string;
  ncoa_alert_flag: boolean;
  num_primary_checks: number;
  confirmatory_dobs: number;
  warn_addresslinks?: boolean;
  warn_deceased?: boolean;
  warn_lor?: boolean;
}

export enum PoaRouteTaken {
  DpoaRoutePassed = 1,
  DpoaRouteFailed = 2,
  PoaRoute = 3,
}
