import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";

@Component({
  selector: "feedback-link",
  templateUrl: "./feedback-link.component.html",
})
export class FeedbackLinkComponent implements OnChanges {
  readonly APP_FEEDBACK_LINK: string =
    "https://feedback.digital.nhs.uk/jfe/form/SV_0ewXr8LWPGbca5U";
  @Input() queryString: string;
  feedbackLink: string;

  ngOnChanges(changes: SimpleChanges) {
    this.queryString = changes.queryString.currentValue;
    this.updateFeedbackLink();
  }

  updateFeedbackLink() {
    this.feedbackLink =
      this.APP_FEEDBACK_LINK +
      (this.queryString ? `?Page=${this.queryString}` : "");
  }
}
