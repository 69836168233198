import { Pipe, PipeTransform } from "@angular/core";
import { IdCheckFailure } from "../../../models/idCheckFailure";
import { getFailureReasonDescription } from "../../../utils/common/common";

@Pipe({ name: "IdFailureCheckReasonParagraphPipe" })
export class IdFailureCheckReasonParagraphPipe implements PipeTransform {
  transform(
    idCheckFailure: IdCheckFailure[],
    photoOneDocType: string,
    photoTwoDocType: string,
    singleParagraph: boolean = true,
  ): string {
    let output = "";
    idCheckFailure.forEach((element) => {
      // add opening <p>
      if (singleParagraph) {
        output += "<p>";
      }

      if (element.document == "Photo ID 1") {
        const docTypeOne = this.getDocumentType(photoOneDocType);
        output = output + element.document + docTypeOne;
      } else if (element.document == "Photo ID 2") {
        const docTypeTwo = this.getDocumentType(photoTwoDocType);
        output = output + element.document + docTypeTwo;
      } else {
        output = output + element.document;
      }

      const isPoa = element.document == "Proof of address";

      element.reasons.forEach((reason) => {
        output += getFailureReasonDescription(reason, isPoa);
      });

      // Remove last comma
      output = output.slice(0, -1);

      // add closing </p>
      if (singleParagraph) {
        output += ".</p>";
      } else {
        output += ". ";
      }
    });
    return output;
  }

  getDocumentType(docType: string): string {
    if (docType == "Passport") {
      return " (passport)";
    }
    if (docType == "DrivingLicence") {
      return " (driving licence)";
    }
    if (docType == "ResidencePermit") {
      return " (residence permit)";
    }
    return "";
  }
}
