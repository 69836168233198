import { Component, OnDestroy, OnInit, ViewChild, Inject } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { RouterService } from "src/app/services/router.service";
import { UpdateInvitationAction } from "../../../../store/actions/spa.actions";
import { newInvitation } from "../../../../store/selectors/spa.selectors";
import { TextInputComponent } from "../../common/form-fields/text-inputs/text-input.component";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import { Invitation, SPAState } from "./../../../../store/states/spa.state";
import { Location } from "@angular/common";
import { StorageMap } from "@ngx-pwa/local-storage";
import { SendInvitationService } from "./../../../services/send-invitation.service";
import { SESSION_STORAGE, StorageService } from "ngx-webstorage-service";

import {
  isNameValid,
  isEmailAddressValid,
  isMobileValid,
} from "../../../utils/data-validation/data-validation";
import { Title } from "@angular/platform-browser";
import { scrollToTop } from "src/app/utils/common/common";
import { ErrorSummaryMessage } from "src/app/models/error-summary-message";

@Component({
  selector: "app-basic-info",
  templateUrl: "./basic-info.component.html",
  styleUrls: ["./basic-info.component.css"],
})
export class BasicInfoComponent implements OnInit, OnDestroy {
  forwardRoute = "/invitation/final-summary";
  backRoute = "/invitation/dashboard";

  emailValidationError: boolean;
  existingInvitation: Invitation;
  existingInvitationId: string;
  invitationId: string;
  givenNameValid: boolean;
  familyNameValid: boolean;
  emailValid: boolean;
  mobileValid: boolean;
  captureDeliveryAddressResponseValid: boolean;
  submitButtonClicked = false;

  orgName: string;
  orgCode: string;

  invitationSubscription: Subscription;
  invitation: Invitation;

  @ViewChild("givenNameInput") givenNameInput: TextInputComponent;

  @ViewChild("familyNameInput") familyNameInput: TextInputComponent;
  @ViewChild("emailInput") emailInput: TextInputComponent;
  @ViewChild("mobileInput") mobileInput: TextInputComponent;

  readonly errorMessages = {
    givenName: {
      lengthTooShort: "First name must be at least 2 characters",
      lengthTooLong: "First name must be 35 characters or fewer",
      missingUppercaseCharacter: "First name must include a capital letter",
      unexpectedCharacters:
        "First name must only contain letters, apostrophes and hyphens",
      empty: "Enter their first name",
      unknown: "Please check you've entered the first name correctly.",
    },
    familyName: {
      lengthTooShort: "Last name must be at least 2 characters",
      lengthTooLong: "Last name must be 35 characters or fewer",
      missingUppercaseCharacter: "Last name must include a capital letter",
      unexpectedCharacters:
        "Last name must only contain letters, apostrophes and hyphens",
      empty: "Enter their last name",
      unknown: "Please check you've entered the last name correctly",
    },
    email: {
      lengthTooLong: "Email address must be 255 characters or fewer",
      empty: "Enter their email address",
      duplicate: "Enter a different email address",
      domainNotAllowed:
        "This email address is not on the allow list. Enter a different email address",
      unknown:
        "Enter an email address in the correct format, like name@example.com",
    },
    mobile: {
      lengthTooShort: "Mobile number must be at least 11 characters",
      lengthTooLong: "Mobile number must be 20 characters or fewer",
      unexpectedCharacters:
        "Mobile number must only contain numbers and plus signs",
      empty: "Enter their mobile number",
      unknown: "Enter a mobile number like or +447123456789 or 07123456789",
    },
    captureDeliveryAddress: {
      empty: "Select whether the applicant needs to provide a delivery address",
    },
  };

  summaryErrorMessages = Array<ErrorSummaryMessage>();
  givenNameErrorMessage = "";
  familyNameErrorMessage = "";
  emailErrorMessage = "";
  mobileErrorMessage = "";
  captureDeliveryAddressErrorMessage = "";

  constructor(
    private store: Store<SPAState>,
    private routerService: RouterService,
    private location: Location,
    private route: ActivatedRoute,
    private storage: StorageMap,
    private title: Title,
    private invitationService: SendInvitationService,
    @Inject(SESSION_STORAGE) private sessionStorage: StorageService,
  ) {}

  ngOnInit(): void {
    this.title.setTitle("Enter applicant's personal details");
    this.existingInvitationId =
      this.route.snapshot.paramMap.get("invitationId");
    this.orgName = this.sessionStorage.get("sessionToken").org_name;
    this.orgCode = this.sessionStorage.get("sessionToken").org_code;
    if (this.existingInvitationId == null) {
      this.invitationSubscription = this.store
        .select(newInvitation)
        .subscribe((invitation) => {
          this.invitation = Object.assign({}, invitation);
        });
    } else {
      this.getInvitationState();
    }
  }

  ngOnDestroy(): void {
    if (this.invitationSubscription !== undefined) {
      this.invitationSubscription.unsubscribe();
    }
  }

  getExistingEmailFromStore(): string {
    let invitation = this.location.getState() as Invitation;
    return invitation.email;
  }

  getInvitationState(): void {
    this.storage.has("invitation").subscribe((invitationExists) => {
      if (invitationExists) {
        this.storage.get("invitation").subscribe((invitation) => {
          this.invitation = invitation as Invitation;
        });
      }
    });
  }

  async submitAnswers(): Promise<void> {
    if (!this.areAnswersValid()) {
      return;
    }

    if (!(await this.isValidEmail())) {
      return;
    }

    if (await this.isDuplicateApplication()) {
      return;
    }

    this.store.dispatch(new UpdateInvitationAction(this.invitation));
    this.storage.set("invitation", this.invitation).subscribe(() => {});

    if (this.invitation.isPersonalEmail) {
      this.routerService.redirectToConfirmPersonalEmail(
        this.invitation.invitationId,
      );
    } else {
      this.routerService.redirectToFinalSummaryWithId(
        this.invitation.invitationId,
      );
    }
  }

  getLatestAnswers(): void {
    this.givenNameInput.setQuestionAnswer();
    this.familyNameInput.setQuestionAnswer();
    this.emailInput.setQuestionAnswer();
    this.mobileInput.setQuestionAnswer();
    this.validOptionForCaptureDeliveryAddressSelected();
  }

  updateValidities(): void {
    this.summaryErrorMessages = Array<ErrorSummaryMessage>();
    this.updateFamilyNameValidation();
    this.updateGivenNameValidation();
    this.updateEmailValidation();
    this.updateMobileValidation();
    this.validOptionForCaptureDeliveryAddressSelected();
  }

  areAnswersValid(): boolean {
    this.getLatestAnswers();
    this.updateValidities();
    if (this.summaryErrorMessages.length > 0) {
      scrollToTop();
    }
    return (
      this.givenNameValid &&
      this.familyNameValid &&
      this.emailValid &&
      this.mobileValid &&
      this.captureDeliveryAddressResponseValid
    );
  }

  async isValidEmail(): Promise<boolean> {
    this.submitButtonClicked = true;

    return await this.invitationService
      .getIsEmailDomainValid(this.invitation.email)
      .then((resp) => {
        if (!resp.accepted) {
          this.displayEmailValidationError("domainNotAllowed");
        }
        this.invitation.isPersonalEmail = resp.is_personal_email;
        return resp.accepted;
      })
      .catch((err) => {
        if (err.status === 401) {
          this.routerService.redirectToLoggedOut();
        } else {
          this.emailErrorMessage = "An error was encountered please retry";
        }
        return false;
      })
      .finally(() => {
        this.submitButtonClicked = false;
      });
  }

  displayDuplicateInvitationError(duplicateInvitationId: string): void {
    this.existingInvitation = {
      invitationId: duplicateInvitationId,
      email: this.invitation.email,
    } as Invitation;
    this.emailValid = false;
    this.emailErrorMessage = this.errorMessages.email["duplicate"];
    scrollToTop();
  }

  async isDuplicateApplication(): Promise<boolean> {
    this.submitButtonClicked = true;

    return await this.invitationService
      .getIsDuplicateApplication(this.invitation.email)
      .then((resp) => {
        let duplicateFound = resp.is_duplicate_application;

        if (duplicateFound) {
          if (this.existingInvitationId != null) {
            if (this.existingInvitationId == resp.duplicate_application_id) {
              this.emailValid = true;
              duplicateFound = false;
            } else {
              this.displayDuplicateInvitationError(
                resp.duplicate_application_id,
              );
            }
          } else {
            this.displayDuplicateInvitationError(resp.duplicate_application_id);
          }
        }
        return duplicateFound;
      })
      .catch((err) => {
        this.routerService.handleErrorAuthRoutes(err.status);
        this.emailErrorMessage = "An error was encountered please retry";
        this.emailValid = false;
        return true;
      })
      .finally(() => {
        this.submitButtonClicked = false;
      });
  }

  displayEmailValidationError(msg: string): void {
    this.emailValid = false;
    this.emailErrorMessage = this.errorMessages.email[msg];
    scrollToTop();
  }

  isServerValidationErrorForEmail(): boolean {
    return (
      this.emailErrorMessage == this.errorMessages.email["duplicate"] ||
      this.emailErrorMessage == this.errorMessages.email["domainNotAllowed"]
    );
  }

  updateGivenNameAnswer(givenName: string): void {
    this.invitation.givenName = givenName;
  }

  updateGivenNameValidation(): void {
    const validationResult = isNameValid(this.invitation.givenName);
    if (validationResult.validity) {
      this.givenNameErrorMessage = "";
      this.givenNameValid = true;
    } else {
      this.givenNameErrorMessage =
        this.errorMessages.givenName[validationResult.errorReason];
      this.givenNameValid = false;
      this.summaryErrorMessages.push({
        id: "first-name-input",
        message: this.givenNameErrorMessage,
      });
    }
  }

  updateFamilyNameAnswer(familyName: string): void {
    this.invitation.familyName = familyName;
  }

  updateFamilyNameValidation(): void {
    const validationResult = isNameValid(this.invitation.familyName);
    if (validationResult.validity) {
      this.familyNameErrorMessage = "";
      this.familyNameValid = true;
    } else {
      this.familyNameErrorMessage =
        this.errorMessages.familyName[validationResult.errorReason];
      this.familyNameValid = false;
      this.summaryErrorMessages.push({
        id: "family-name-input",
        message: this.familyNameErrorMessage,
      });
    }
  }

  updateEmailAnswer(email: string): void {
    this.invitation.email = email;
  }

  updateEmailValidation(): void {
    const validationResult = isEmailAddressValid(this.invitation.email);
    if (validationResult.validity) {
      this.emailErrorMessage = "";
      this.emailValid = true;
    } else {
      this.emailErrorMessage =
        this.errorMessages.email[validationResult.errorReason];
      this.emailValid = false;
      this.summaryErrorMessages.push({
        id: "work-email-input",
        message: this.emailErrorMessage,
      });
    }
  }

  updateMobileAnswer(mobile: string): void {
    this.invitation.mobile = mobile;
  }

  updateMobileValidation(): void {
    const validationResult = isMobileValid(this.invitation.mobile);
    if (validationResult.validity) {
      this.mobileErrorMessage = "";
      this.mobileValid = true;
    } else {
      this.mobileErrorMessage =
        this.errorMessages.mobile[validationResult.errorReason];
      this.mobileValid = false;
      this.summaryErrorMessages.push({
        id: "mobile-input",
        message: this.mobileErrorMessage,
      });
    }
  }

  updateDeliveryAddressAnswer(deliveryAddress: boolean): void {
    if (deliveryAddress !== undefined) {
      this.captureDeliveryAddressErrorMessage = "";
      this.captureDeliveryAddressResponseValid = true;
    } else {
      this.captureDeliveryAddressErrorMessage =
        this.errorMessages.captureDeliveryAddress.empty;
      this.captureDeliveryAddressResponseValid = false;
      this.summaryErrorMessages.push({
        id: "require-delivery-yes",
        message: this.captureDeliveryAddressErrorMessage,
      });
    }
    if (deliveryAddress != undefined) {
      this.invitation.captureDeliveryAddress = deliveryAddress;
    }
  }

  validOptionForCaptureDeliveryAddressSelected() {
    if (this.invitation.captureDeliveryAddress === undefined) {
      this.captureDeliveryAddressResponseValid = false;
      this.captureDeliveryAddressErrorMessage =
        this.errorMessages.captureDeliveryAddress.empty;
      this.summaryErrorMessages.push({
        id: "require-delivery-yes",
        message: this.captureDeliveryAddressErrorMessage,
      });
    } else {
      this.captureDeliveryAddressResponseValid = true;
    }
  }
}
