<div
  *ngIf="errorMessages.length > 0"
  #errorSummary
  id="error-summary-container"
  class="nhsuk-error-summary"
  role="alert"
  tabindex="-1"
  aria-labelledby="error-summary-title"
>
  <h2 tabindex="0" class="nhsuk-error-summary__title" id="error-summary-title">
    There is a problem
  </h2>

  <div class="nhsuk-error-summary__body">
    <ul
      role="list"
      id="list-group"
      class="nhsuk-list nhsuk-error-summary__list"
    >
      <li *ngFor="let error of errorMessages" class="has-error list-item">
        <a
          role="link"
          class="has-error link-text"
          routerLink="./"
          [fragment]="error.id"
          >{{ error.message }}</a
        >
      </li>
    </ul>
  </div>
</div>
