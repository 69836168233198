import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { IdCheckerDateValidationResult } from "src/app/models/id-checker-date-validation-result";
import { ValidationResponse } from "src/app/models/validation-response";
import { IdCheckerDateService } from "src/app/services/date/id-checker-date.service";

@Component({
  selector: "id-checker-date",
  templateUrl: "./id-checker-date.component.html",
  styleUrls: ["./id-checker-date.component.scss"],
})
export class IdCheckerDateComponent implements OnInit {
  isDateInvalid: boolean;
  dateErrorHighlight = {
    day: false,
    month: false,
    year: false,
  };

  fieldDOBErrorMessage: string;
  @ViewChild("day", { static: true }) dayInput: ElementRef;
  @ViewChild("month", { static: true }) monthInput: ElementRef;
  @ViewChild("year", { static: true }) yearInput: ElementRef;

  day: string;
  month: string;
  year: string;

  @Input() idCheckerDateInput: string;
  @Input() label: string;
  @Input() id: string;
  @Output() idCheckerDateOutput = new EventEmitter<string>();
  @Output() idCheckerDateValidOutput = new EventEmitter<boolean>();
  @Output() idCheckerDateErrorMessageOutput = new EventEmitter<string>();

  ngOnInit(): void {
    if (this.idCheckerDateInput) {
      this.dayInput.nativeElement.value = this.idCheckerDateInput.substring(
        8,
        10,
      );
      this.day = this.dayInput.nativeElement.value;
      this.monthInput.nativeElement.value = this.idCheckerDateInput.substring(
        5,
        7,
      );
      this.month = this.monthInput.nativeElement.value;
      this.yearInput.nativeElement.value = this.idCheckerDateInput.substring(
        0,
        4,
      );
      this.year = this.yearInput.nativeElement.value;
    }
  }

  updateDay(value: string): void {
    this.day = value;
    if (!this.areAnyFieldsEmpty()) {
      this.setAnswers();
    }
  }

  updateMonth(value: string): void {
    this.month = value;
    if (!this.areAnyFieldsEmpty()) {
      this.setAnswers();
    }
  }

  updateYear(value: string): void {
    this.year = value;
    if (!this.areAnyFieldsEmpty()) {
      this.setAnswers();
    }
  }

  setAnswers(): void {
    this.resetValidation();
    const validationResult: ValidationResponse =
      IdCheckerDateService.getIdCheckerDateValidationResult(
        this.day,
        this.month,
        this.year,
      );
    this.idCheckerDateOutput.emit(validationResult.sanitisedInput || "");
  }

  validateAnswers(): void {
    const validationResult: ValidationResponse =
      IdCheckerDateService.getIdCheckerDateValidationResult(
        this.day,
        this.month,
        this.year,
      );

    if (validationResult.result === IdCheckerDateValidationResult.SUCCESS) {
      this.isDateInvalid = false;
      this.idCheckerDateValidOutput.emit(true);
      this.idCheckerDateOutput.emit(validationResult.sanitisedInput);
    } else {
      this.isDateInvalid = true;
      this.idCheckerDateValidOutput.emit(false);
      this.setDOBErrorMessages(validationResult);
      this.highlightErrorFields(validationResult.result);
    }
  }

  resetValidation(): void {
    this.dateErrorHighlight.day = false;
    this.dateErrorHighlight.month = false;
    this.dateErrorHighlight.year = false;
  }

  setDOBErrorMessages(validationResult: ValidationResponse): void {
    this.fieldDOBErrorMessage = validationResult.fieldError;
    this.idCheckerDateErrorMessageOutput.emit(validationResult.fieldError);
  }

  areAnyFieldsEmpty(): boolean {
    if (!this.day || !this.month || !this.year) {
      return true;
    } else {
      return false;
    }
  }

  highlightErrorFields(validationResult: string): void {
    switch (validationResult) {
      case IdCheckerDateValidationResult.ALL_EMPTY:
      case IdCheckerDateValidationResult.INVALID_DATE:
        this.setHighlightErrorFields(true, true, true);
        break;
      case IdCheckerDateValidationResult.ONLY_DAY_COMPLETE:
        this.setHighlightErrorFields(false, true, true);
        break;
      case IdCheckerDateValidationResult.ONLY_MONTH_COMPLETE:
        this.setHighlightErrorFields(true, false, true);
        break;
      case IdCheckerDateValidationResult.ONLY_YEAR_COMPLETE:
        this.setHighlightErrorFields(true, true, false);
        break;
      case IdCheckerDateValidationResult.ONLY_DAY_EMPTY:
        this.setHighlightErrorFields(true, false, false);
        break;
      case IdCheckerDateValidationResult.ONLY_MONTH_EMPTY:
        this.setHighlightErrorFields(false, true, false);
        break;
      case IdCheckerDateValidationResult.ONLY_YEAR_EMPTY:
      case IdCheckerDateValidationResult.YEAR_NOT_FOUR_DIGITS:
        this.setHighlightErrorFields(false, false, true);
        break;
      default:
        break;
    }
  }

  setHighlightErrorFields(
    isDayError: boolean,
    isMonthError: boolean,
    isYearError: boolean,
  ): void {
    this.dateErrorHighlight.day = isDayError;
    this.dateErrorHighlight.month = isMonthError;
    this.dateErrorHighlight.year = isYearError;
  }
}
