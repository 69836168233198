<div
  class="nhsuk-form-group"
  [ngClass]="{ 'nhsuk-form-group--error': errorMessage }"
>
  <fieldset class="nhsuk-fieldset">
    <legend class="nhsuk-fieldset__legend nhsuk-fieldset__legend--s">
      <h1 class="nhsuk-fieldset__heading">{{ question }}</h1>
    </legend>
    <span class="nhsuk-error-message" *ngIf="errorMessage">
      {{ errorMessage }}
    </span>
    <div class="nhsuk-radios nhsuk-radios--inline">
      <div class="nhsuk-radios__item">
        <input
          class="nhsuk-radios__input"
          id="radio-{{ id }}-yes"
          [name]="id"
          type="radio"
          [value]="true"
          [ngClass]="{
            selected: answer,
            focused: focusOnYes
          }"
          (click)="updateValue(true)"
          (focus)="setFocusOnYes(true)"
          (blur)="onBlur()"
        />

        <label class="nhsuk-label nhsuk-radios__label" for="radio-{{ id }}-yes">
          Yes
        </label>
      </div>

      <div class="nhsuk-radios__item">
        <input
          class="nhsuk-radios__input"
          id="radio-{{ id }}-no"
          [name]="id"
          type="radio"
          [value]="false"
          [ngClass]="{
            selected: !answer,
            focused: focusOnNo
          }"
          (click)="updateValue(false)"
          (focus)="setFocusOnNo(true)"
          (blur)="onBlur()"
        />
        <label class="nhsuk-label nhsuk-radios__label" for="radio-{{ id }}-no">
          No
        </label>
      </div>
    </div>
  </fieldset>
</div>
