import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "select-dropdown",
  templateUrl: "./select-dropdown.component.html",
  styleUrls: ["./select-dropdown.component.scss"],
})
export class SelectDropdownComponent {
  answer: boolean;
  isYesFocused: string;

  @Input() question: string;
  @Input() answers: any[];
  @Input() selectedAnswer: any;
  @Input() questionIdentifier: string;
  @Input() errorMessage: string;
  @Output() questionAnswer = new EventEmitter<boolean>();
  @Input() size: string;

  setAnswer(): void {
    this.questionAnswer.emit(this.selectedAnswer);
  }
}
