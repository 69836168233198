import { Component, OnInit, ViewChild } from "@angular/core";
import { RouterService } from "src/app/services/router.service";
import { ServiceManagerService } from "src/app/services/service-manager.service";
import { Title } from "@angular/platform-browser";
import { TextInputComponent } from "../../common/form-fields/text-inputs/text-input.component";
import { ErrorSummaryMessage } from "../../../models/error-summary-message";
import { SelectDropdownComponent } from "../../common/form-fields/select-dropdown/select-dropdown.component";
import { TextAreaInputComponent } from "../../common/form-fields/text-inputs/text-area-input/text-area-input.component";

@Component({
  selector: "app-delete-invitation",
  templateUrl: "./delete-invitation.component.html",
})
export class DeleteInvitationComponent implements OnInit {
  breadcrumbRoute = "/service-management/portal";
  breadcrumbText = "Back";
  loading: boolean;
  invitationId: string = "";
  invitationIdValid: boolean;
  invitationDeleted: boolean = null;
  deleteReason: number;
  deleteMessage: string = "";
  invitationIdErrorMessage = "";
  deleteReasonErrorMessage = "";
  deleteReasonValid: boolean;
  summaryErrorMessages = Array<ErrorSummaryMessage>();
  responseMessage: string = "";
  deleteReasonOptions = [
    {
      label: "System Issue",
      value: 0,
    },
    {
      label: "Duplicate",
      value: 1,
    },
    {
      label: "Other",
      value: 2,
    },
  ];

  constructor(
    private serviceManagerService: ServiceManagerService,
    private routerService: RouterService,
    private title: Title,
  ) {}

  @ViewChild("invitationIdInput") invitationIdInput: TextInputComponent;
  @ViewChild("deleteMessageInput") deleteMessageInput: TextAreaInputComponent;
  @ViewChild("deleteReasonInput") deleteReasonInput: SelectDropdownComponent;
  ngOnInit(): void {
    this.title.setTitle("Delete invitation");
  }

  updateInvitationIdAnswer(invitationId: string): void {
    invitationId = invitationId.trim();
    if (invitationId.length === 0) {
      this.invitationIdErrorMessage = "Please enter a invitation id.";
      this.invitationIdValid = false;
    } else {
      this.invitationIdErrorMessage = "";
      this.invitationIdValid = true;
      this.invitationId = invitationId;
    }
    this.updateErrorSummary();
  }

  updateDeleteMessageAnswer(deleteMessage: string): void {
    deleteMessage = deleteMessage.trim();
    if (deleteMessage.length !== 0) {
      this.deleteMessage = deleteMessage;
    }
    this.updateErrorSummary();
  }

  async submitAnswer(): Promise<void> {
    this.invitationIdInput.setQuestionAnswer();
    this.deleteReasonInput.setAnswer();
    if (this.invitationIdValid && this.deleteReasonValid) {
      this.loading = true;
      try {
        const response = await this.serviceManagerService.deleteInvitation(
          this.invitationId,
          this.deleteReason,
          this.deleteMessage,
        );
        if (response.status == 200) {
          this.responseMessage = "Invitation deleted";
        } else {
          this.responseMessage = "Failed to delete invitation";
        }
        this.loading = false;
        this.invitationId = "";
        this.deleteReason = -1;
        this.deleteMessage = "";
      } catch (error) {
        this.routerService.handleErrorAuthRoutes(error.status);
        if (error.status === 400) {
          this.loading = false;
          this.responseMessage =
            "Invitation can not be delete as it is in status 'Approved' or 'Deleted'";
        } else if (error.status === 404) {
          this.invitationIdErrorMessage = "Invitation not found.";
          this.loading = false;
          this.updateErrorSummary();
        }
      }
    }
  }

  updateDeleteReasonAnswer(deleteReason: number): void {
    if (deleteReason != -1 && deleteReason != undefined) {
      this.deleteReasonErrorMessage = "";
      this.deleteReasonValid = true;
    } else {
      this.deleteReasonErrorMessage = "Please select a delete reason.";
      this.deleteReasonValid = false;
    }
    this.deleteReason = deleteReason;
    this.updateErrorSummary();
  }

  updateErrorSummary(): void {
    const updatedErrorSummary = Array<ErrorSummaryMessage>();
    if (this.deleteReasonErrorMessage) {
      updatedErrorSummary.push({
        message: this.deleteReasonErrorMessage,
        id: "delete-option-input",
      });
    }
    if (this.invitationIdErrorMessage) {
      updatedErrorSummary.push({
        message: this.invitationIdErrorMessage,
        id: "invitation-id",
      });
    }
    this.summaryErrorMessages = updatedErrorSummary;
  }
}
