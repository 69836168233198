import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "app-cookies-saved",
  templateUrl: "./cookies-saved.component.html",
})
export class CookiesSavedComponent implements OnInit {
  cookiesConsentGiven: boolean = false;

  constructor(private title: Title) {}

  ngOnInit(): void {
    this.title.setTitle("Your cookie settings have been saved");
  }
}
