import { Component, EventEmitter, Input, Output } from "@angular/core";
import {
  CropperPosition,
  Dimensions,
  ImageCroppedEvent,
  ImageTransform,
} from "ngx-image-cropper";

@Component({
  selector: "afcid-image-cropper",
  templateUrl: "./afcid-image-cropper.component.html",
  styleUrls: ["./afcid-image-cropper.component.scss"],
})
export class AfcidImageCropperComponent {
  // Cropper
  imageChangedEvent: any = "";
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};
  cropper = {};
  sourceImageDimensions: Dimensions;
  movePixelLength = 10;

  @Input() imageBase64: any = "";
  @Output() croppedImage = new EventEmitter<string>();

  moveLeft(): void {
    let oldCropperPosition = this.cropper;
    let x1 = oldCropperPosition["x1"] - this.movePixelLength;
    let y1 = oldCropperPosition["y1"];
    let x2 = oldCropperPosition["x2"] - this.movePixelLength;
    let y2 = oldCropperPosition["y2"];
    if (x1 <= 0) {
      return;
    }
    this.cropper = this.createCropperPosition(x1, y1, x2, y2);
  }

  moveRight(): void {
    let oldCropperPosition = this.cropper;
    let x1 = oldCropperPosition["x1"] + this.movePixelLength;
    let y1 = oldCropperPosition["y1"];
    let x2 = oldCropperPosition["x2"] + this.movePixelLength;
    let y2 = oldCropperPosition["y2"];
    if (x2 >= this.sourceImageDimensions.width) {
      return;
    }
    this.cropper = this.createCropperPosition(x1, y1, x2, y2);
  }

  moveUp(): void {
    let oldCropperPosition = this.cropper;
    let x1 = oldCropperPosition["x1"];
    let y1 = oldCropperPosition["y1"] - this.movePixelLength;
    let x2 = oldCropperPosition["x2"];
    let y2 = oldCropperPosition["y2"] - this.movePixelLength;
    if (y1 <= 0) {
      return;
    }
    this.cropper = this.createCropperPosition(x1, y1, x2, y2);
  }

  moveDown(): void {
    let oldCropperPosition = this.cropper;
    let x1 = oldCropperPosition["x1"];
    let y1 = oldCropperPosition["y1"] + this.movePixelLength;
    let x2 = oldCropperPosition["x2"];
    let y2 = oldCropperPosition["y2"] + this.movePixelLength;
    if (y2 >= this.sourceImageDimensions.height) {
      return;
    }
    this.cropper = this.createCropperPosition(x1, y1, x2, y2);
  }

  createCropperPosition(
    x1: number,
    y1: number,
    x2: number,
    y2: number,
  ): CropperPosition {
    const cropperPosition: CropperPosition = {
      x1: x1,
      y1: y1,
      x2: x2,
      y2: y2,
    };
    return cropperPosition;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage.emit(event.base64);
  }

  imageLoaded() {
    this.showCropper = true;
  }

  cropperReady(sourceImageDimensions: Dimensions) {
    this.sourceImageDimensions = sourceImageDimensions;
  }

  loadImageFailed() {
    console.log("Load failed");
  }

  rotateAntiClockwise() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }

  rotateClockwise() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH,
    };
  }

  flipHorizontal() {
    this.transform = {
      ...this.transform,
      flipH: !this.transform.flipH,
    };
  }

  flipVertical() {
    this.transform = {
      ...this.transform,
      flipV: !this.transform.flipV,
    };
  }

  resetImage() {
    this.scale = 1;
    this.rotation = 0;
    this.canvasRotation = 0;
    this.transform = {};
  }

  toggleContainWithinAspectRatio() {
    this.containWithinAspectRatio = !this.containWithinAspectRatio;
  }

  updateRotation() {
    this.transform = {
      ...this.transform,
      rotate: this.rotation,
    };
  }
}
