import { HttpClient, HttpResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { EnvironmentConfigService } from "./environment-config.service";
import { ManagedOrgs } from "src/app/models/managedOrgs";
import { SESSION_STORAGE, StorageService } from "ngx-webstorage-service";
import { SessionAuthenticationService } from "./session-authentication.service";
import { Portal } from "src/app/models/session-token";

@Injectable()
export class ManagedOrgsService {
  constructor(
    private http: HttpClient,
    private environmentConfigService: EnvironmentConfigService,
    private sessionAuthenticationService: SessionAuthenticationService,
    @Inject(SESSION_STORAGE) private storage: StorageService,
  ) {}

  getManagedOrgs(): Observable<HttpResponse<ManagedOrgs[]>> {
    return this.http.get<ManagedOrgs[]>(
      `${
        this.environmentConfigService.getConfig().idBridgeApiUrl
      }/managed_orgs/?uuid=${this.storage.get("sessionToken").user_uuid}`,
      {
        observe: "response",
        responseType: "json",
        headers: this.sessionAuthenticationService.applyAuthorizationHeader(
          Portal.Checker,
        ),
      },
    );
  }
}
