<session-check></session-check>
<div class="nhsuk-grid-row">
  <div class="nhsuk-grid-column-full">
    <h1 autofocus tabindex="-1">ID Checker</h1>

    <button
      data-cy="review-next-application"
      [disabled]="noNewApplication"
      (click)="onNextVerificationClicked()"
      class="nhsuk-button nhsuk-u-margin-bottom-9"
    >
      Review next application
    </button>

    <ul *ngIf="metrics" class="nhsuk-grid-row nhsuk-card-group">
      <li class="nhsuk-grid-column-one-quarter nhsuk-card-group__item">
        <div class="nhsuk-card nhsuk-card--clickable">
          <div class="nhsuk-card__content">
            <h5 class="nhsuk-card__heading nhsuk-heading-xs">
              <a
                class="nhsuk-card__link"
                aria-label="hold list"
                data-cy="holding-list"
                href="id-checker/holding-list"
                >On hold ({{ metrics.verificationHeld || 0 }})</a
              >
            </h5>
          </div>
        </div>
      </li>
      <li class="nhsuk-grid-column-one-quarter nhsuk-card-group__item">
        <div class="nhsuk-card nhsuk-card--clickable">
          <div class="nhsuk-card__content">
            <h5 class="nhsuk-card__heading nhsuk-heading-xs">
              <a
                class="nhsuk-card__link"
                aria-label="passed list"
                data-cy="passed-list"
                href="id-checker/passed-list"
                >Approved ({{ metrics.verificationPassed || 0 }})</a
              >
            </h5>
          </div>
        </div>
      </li>
      <li class="nhsuk-grid-column-one-quarter nhsuk-card-group__item">
        <div class="nhsuk-card nhsuk-card--clickable">
          <div class="nhsuk-card__content">
            <h5 class="nhsuk-card__heading nhsuk-heading-xs">
              <a
                class="nhsuk-card__link"
                aria-label="rejected list"
                data-cy="rejected-list"
                href="id-checker/rejected-list"
                >Rejected ({{ metrics.verificationFailed || 0 }})</a
              >
            </h5>
          </div>
        </div>
      </li>
      <li class="nhsuk-grid-column-one-quarter nhsuk-card-group__item">
        <div class="nhsuk-card nhsuk-card--clickable">
          <div class="nhsuk-card__content">
            <h5 class="nhsuk-card__heading nhsuk-heading-xs">
              <a
                class="nhsuk-card__link"
                data-cy="search-id-checker-link"
                href="id-checker/search"
                >Search ID checker</a
              >
            </h5>
          </div>
        </div>
      </li>
    </ul>

    <h2 class="nhsuk-u-margin-bottom-0">
      Waiting for review ({{ collectionSize }})
    </h2>

    <section *ngIf="invitations">
      <div class="nhsuk-grid-row">
        <div class="nhsuk-grid-column-two-thirds">&nbsp;</div>
        <div class="nhsuk-grid-column-one-third">
          <div style="float: right">
            <div class="nhsuk-form-group">
              <label class="nhsuk-label" for="select-sort-review">
                Sort by
              </label>
              <select
                data-cy="select-sort-review"
                class="nhsuk-select"
                id="select-sort-review"
                name="select-sort-review"
                (change)="sortChanged($event.target.value)"
              >
                <option value="givenName">Applicant name</option>
                <option value="createdByUserOrgCode">ODS code</option>
                <option value="submittedIdCheckUtcNewest">Newest</option>
                <option value="submittedIdCheckUtc" selected>Oldest</option>
                <option value="reviewBy">Assigned to</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <table
        role="table"
        class="nhsuk-table-responsive"
        aria-label="Waiting for review applications"
      >
        <thead role="rowgroup" class="nhsuk-table__head">
          <tr role="row">
            <th role="columnheader" scope="col">Applicant name</th>
            <th role="columnheader" scope="col">Application ID</th>
            <th role="columnheader" scope="col">ODS code</th>
            <th role="columnheader" scope="col">Date submitted</th>
            <th role="columnheader" scope="col">Assigned to</th>
          </tr>
        </thead>
        <tbody class="nhsuk-table__body">
          <tr
            *ngFor="let invitation of invitations"
            role="row"
            class="nhsuk-table__row"
          >
            <td
              role="cell"
              class="nhsuk-table__cell nhsuk-body-s nhsuk-u-margin-bottom-0"
            >
              <span class="nhsuk-table-responsive__heading"
                >Applicant name</span
              >
              <a
                attr.data-cy="open-review-{{ invitation.invitationId }}"
                [routerLink]="[
                  '/id-checker/review-id',
                  invitation.invitationId
                ]"
                [state]="{ prevPage: '/id-checker/review-list' }"
                >{{ invitation.givenName }} {{ invitation.familyName }}</a
              >
            </td>
            <td
              role="cell"
              class="nhsuk-table__cell nhsuk-body-s nhsuk-u-margin-bottom-0"
            >
              <span class="nhsuk-table-responsive__heading">Application ID</span
              >{{ invitation.invitationId }}
            </td>
            <td
              role="cell"
              class="nhsuk-table__cell nhsuk-body-s nhsuk-u-margin-bottom-0"
            >
              <span class="nhsuk-table-responsive__heading">ODS code</span
              >{{ invitation.createdByUserOrgCode }}
            </td>
            <td
              role="cell"
              class="nhsuk-table__cell nhsuk-body-s nhsuk-u-margin-bottom-0"
            >
              <span class="nhsuk-table-responsive__heading"
                >Date submitted</span
              >
              {{ invitation.submittedIdCheckUtc | date: "d MMMM yyyy h.mm a" }}
            </td>
            <td
              role="cell"
              class="nhsuk-table__cell nhsuk-body-s nhsuk-u-margin-bottom-0"
            >
              <span class="nhsuk-table-responsive__heading">Assigned to</span>
              <span
                *ngIf="invitation.reviewBy != ''"
                title="User: {{ invitation.reviewBy }},  Expiry: {{
                  invitation.reviewExpiryUtc | date: 'dd/MM/yyyy HH:mm:ss'
                }}"
              >
                {{ invitation.reviewBy }}</span
              >
              <span *ngIf="invitation.reviewByDisplay == 'ZZ'">Unassigned</span>
            </td>
          </tr>
        </tbody>
      </table>
    </section>
    <section *ngIf="!invitations">
      <p>
        <progress-indicator
          spinnerText="Loading review list"
        ></progress-indicator>
      </p>
    </section>
  </div>
</div>
