<session-check></session-check>
<modified-record-check #modifiedRecordCheck></modified-record-check>
<section *ngIf="!invitation">
  <p>
    <progress-indicator spinnerText="Loading application"></progress-indicator>
  </p>
</section>
<div *ngIf="invitation">
  <a
    [routerLink]="['/id-checker/review-id', invitationId]"
    class="nhsuk-back-link__link nhsuk-u-margin-bottom-6"
  >
    <svg
      class="nhsuk-icon nhsuk-icon__chevron-left"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      aria-hidden="true"
      height="24"
      width="24"
    >
      <path
        d="M8.5 12c0-.3.1-.5.3-.7l5-5c.4-.4 1-.4 1.4 0s.4 1 0 1.4L10.9 12l4.3 4.3c.4.4.4 1 0 1.4s-1 .4-1.4 0l-5-5c-.2-.2-.3-.4-.3-.7z"
      ></path>
    </svg>
    Back
  </a>
  <div class="nhsuk-grid-row">
    <div class="nhsuk-grid-column-two-thirds">
      <h1 autofocus tabindex="-1">Reject application</h1>
      <span role="alert" *ngIf="!validSubmission" class="nhsuk-error-message">{{
        failureDocumentsErrorMessage
      }}</span>

      <p *ngIf="idCheckFailures.length > 0">
        This application is being rejected for the following reasons
      </p>
      <dl class="nhsuk-summary-list">
        <div
          *ngFor="let failure of idCheckFailures"
          class="nhsuk-summary-list__row"
        >
          <dt class="nhsuk-summary-list__key">{{ failure.document }}</dt>
          <dd class="nhsuk-summary-list__value">
            <p *ngFor="let reason of failure.reasons">
              {{
                reason
                  | idFailureCheckReasonPipe
                    : failure.document == "Proof of address"
              }}
            </p>
          </dd>
        </div>
      </dl>
      <p *ngIf="isMarkedDuplicate()">
        This application is being rejected because
        <a href="{{ getCimDuplicateLink() }}" target="_blank"
          >a duplicate profile already exists in CIM</a
        >
      </p>
      <div class="nhsuk-form-group">
        <label class="nhsuk-label" for="other"> Notes (optional) </label>
        <div class="nhsuk-hint" id="example-hint">
          Notes are visible to inviters and ID checkers
        </div>

        <text-area-input
          #failureReasonNotesInput
          id="failure-notes"
          cypressValue="failure-notes"
          [value]="failureReasonNotes"
          aria-describedby="failure notes"
          [errorMessage]="failureReasonNotesErrorMessage"
          (questionAnswer)="updateFailureNotes($event)"
        >
        </text-area-input>
      </div>

      <button
        data-cy="submit-reject-confirm"
        (click)="submitAnswers()"
        class="nhsuk-button nhsuk-button--secondary delete-button nhsuk-u-margin-top-5"
        type="submit"
        [ngClass]="{ 'spinner-button': submitting }"
        [disabled]="submitting || !failureReasonNotesValid"
      >
        Reject application
      </button>

      <span
        class="nhsuk-error-message"
        data-cy="submit-error-message"
        *ngIf="submitError"
        >{{ submitError }}</span
      >
    </div>
  </div>
</div>
