<div class="nhsuk-grid-row">
  <div class="nhsuk-grid-column-two-thirds">
    <h1 autofocus tabindex="-1">Review Care ID applications</h1>
    <p>Use this service to:</p>
    <ul>
      <li>review Care ID applications</li>
      <li>approve or reject applications</li>
      <li>submit applications for further review by another ID checker</li>
    </ul>
    <a
      id="start-button"
      href="/authenticate"
      class="nhsuk-button nhsuk-u-margin-bottom-7"
    >
      Start
    </a>
  </div>
</div>
