import { IdCheckRadioButtonContent } from "../models/idCheckRadioButton";
import { IdCheckFailureReason } from "../models/invitationDetails";

const PHYSICAL_PROOF_REASONS: IdCheckFailureReason[] = [
  IdCheckFailureReason.Expired,
  IdCheckFailureReason["Missing information"],
  IdCheckFailureReason["Not accepted"],
  IdCheckFailureReason["Not an original document"],
  IdCheckFailureReason["Not legible"],
];
const DEFAULT_REASONS: IdCheckFailureReason[] = [
  IdCheckFailureReason["Suspected fraud"],
  IdCheckFailureReason["Smoke test - internal use only"],
  IdCheckFailureReason.Other,
];
export const CONTENT_DICT: ReadonlyMap<string, IdCheckRadioButtonContent> =
  new Map([
    [
      "Duplicate profile",
      {
        question: "Is this application a duplicate?",
        yesAnswer: "Yes, this is a duplicate",
        noAnswer: "No, this is not a duplicate",
        noReasons: null,
      },
    ],
    [
      "Photo ID 1",
      {
        question: "Does this ID meet NHS ID checking standards?",
        yesAnswer: "Yes, approve this photo ID",
        noAnswer: "No, reject this photo ID",
        noReasons: [
          IdCheckFailureReason["Does not show both sides"],
          ...PHYSICAL_PROOF_REASONS,
          ...DEFAULT_REASONS,
        ],
      },
    ],
    [
      "Photo ID 2",
      {
        question: "Does this ID meet NHS ID checking standards?",
        yesAnswer: "Yes, approve this photo ID",
        noAnswer: "No, reject this photo ID",
        noReasons: [
          IdCheckFailureReason["Does not show both sides"],
          IdCheckFailureReason.Duplicate,
          ...PHYSICAL_PROOF_REASONS,
          ...DEFAULT_REASONS,
        ],
      },
    ],
    [
      "Smartcard photo",
      {
        question: "Does the smartcard photo meet NHS ID checking standards?",
        yesAnswer: "Yes, approve this smartcard photo",
        noAnswer: "No, reject this smartcard photo",
        noReasons: [
          IdCheckFailureReason["Photo(s) do not meet passport guidelines"],
          IdCheckFailureReason["Does not have a plain background"],
          IdCheckFailureReason["Has been altered by filters"],
          IdCheckFailureReason["Is a photo of an ID document"],
          IdCheckFailureReason["Not facing forwards"],
          ...DEFAULT_REASONS,
        ],
      },
    ],
    [
      "Proof of address",
      {
        question: "Does this address check meet NHS ID checking standards?",
        yesAnswer: "Yes, approve this proof of address",
        noAnswer: "No, reject this proof of address",
        noReasons: [
          IdCheckFailureReason.Duplicate,
          ...PHYSICAL_PROOF_REASONS,
          ...DEFAULT_REASONS,
        ],
      },
    ],
    [
      "Digital proof of address",
      {
        question: "Does this address check meet NHS ID checking standards?",
        yesAnswer: "Yes, approve this address check",
        noAnswer: "No, reject this address check",
        noReasons: [
          IdCheckFailureReason["Does not meet requirements"],
          ...DEFAULT_REASONS,
        ],
      },
    ],
    [
      "Name and date of birth",
      {
        question:
          "Do the name and date of birth match the photo IDs and proof of address?",
        yesAnswer: "Yes, approve name and date of birth",
        noAnswer: "No, reject name and date of birth",
        noReasons: [
          IdCheckFailureReason["Name and/or date of birth do not match"],
          ...DEFAULT_REASONS,
        ],
      },
    ],
    [
      "Face scan",
      {
        question: "Does the face scan match the photo IDs?",
        yesAnswer: "Yes, approve face scan",
        noAnswer: "No, reject face scan",
        noReasons: [
          IdCheckFailureReason["Does not match photo ID(s)"],
          IdCheckFailureReason["Does not show a live person"],
          IdCheckFailureReason["Face scan is too blurry"],
          ...DEFAULT_REASONS,
        ],
      },
    ],
  ]);
export const CHECKBOX_IDENTIFIERS: ReadonlyMap<IdCheckFailureReason, string> =
  new Map([
    [IdCheckFailureReason.Duplicate, "duplicate-evidence"],
    [IdCheckFailureReason.Expired, "expired-evidence"],
    [IdCheckFailureReason["Not legible"], "illegible-evidence"],
    [IdCheckFailureReason["Missing information"], "incomplete-evidence"],
    [IdCheckFailureReason["Suspected fraud"], "suspected-fraud"],
    [IdCheckFailureReason["Not accepted"], "not-accepted"],
    [
      IdCheckFailureReason["Does not match photo ID(s)"],
      "failed-liveness-likeness-check",
    ],
    [
      IdCheckFailureReason["Photo(s) do not meet passport guidelines"],
      "non-conformant-photo",
    ],
    [IdCheckFailureReason.Other, "other"],
    // IdCheckFailureReason["Image Quality"], not used
    [
      IdCheckFailureReason["Does not meet requirements"],
      "does-not-meet-requirements",
    ],
    [IdCheckFailureReason["Smoke test - internal use only"], "smoketest"],
    [
      IdCheckFailureReason["Does not show a live person"],
      "failed-liveness-check",
    ],
    [
      IdCheckFailureReason["Name and/or date of birth do not match"],
      "name-and-dob-do-not-match",
    ],
    [IdCheckFailureReason["Face scan is too blurry"], "facescan-photo-quality"],
    [IdCheckFailureReason["Not an original document"], "not-original-document"],
    [
      IdCheckFailureReason["Does not have a plain background"],
      "objects-in-background",
    ],
    [IdCheckFailureReason["Not facing forwards"], "not-facing-forwards"],
    [IdCheckFailureReason["Is a photo of an ID document"], "photo-of-document"],
    [IdCheckFailureReason["Has been altered by filters"], "filters-applied"],
    [IdCheckFailureReason["Does not show both sides"], "back-not-shown"],
  ]);
