import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { EnvironmentConfig } from "./environment_config";

@Injectable()
export class EnvironmentConfigService {
  private ENVIRONMENT_URL = "/assets/environments/config.json";

  private config: EnvironmentConfig;

  constructor(private http: HttpClient) {}

  public async load(): Promise<void> {
    return this.http
      .get(this.ENVIRONMENT_URL)
      .toPromise()
      .then((envResponse: any) => {
        const t = new EnvironmentConfig();
        this.config = Object.assign(t, envResponse);
      })
      .catch(() => {});
  }

  public getConfig(): EnvironmentConfig {
    return this.config;
  }
}
