/* istanbul ignore file */
import { initialState, SPAState } from "../states/spa.state";
import * as fromFeature from "../reducers";
import { createSelector } from "@ngrx/store";

export const getSPAState = createSelector(
  fromFeature.getSPAState,
  (state: fromFeature.SpaState) => state.spa,
);

export const getInvitation = createSelector(getSPAState, (state: SPAState) =>
  state ? state.invitation : initialState.invitation,
);

export const newInvitation = createSelector(
  getSPAState,
  (state: SPAState) => initialState.invitation,
);
