import { Action } from "@ngrx/store";
import { InvitationConfirmation } from "../states/invitee-spa.state";

export const UPDATE_INVITATION_CONFIRMATION = "Update Invitation Confirmation";

export class UpdateInvitationConfirmationAction implements Action {
  readonly type = UPDATE_INVITATION_CONFIRMATION;
  payload: InvitationConfirmation;

  constructor(payload: InvitationConfirmation) {
    this.payload = payload;
  }
}

export type InviteeSPAActions = UpdateInvitationConfirmationAction;
