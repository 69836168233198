import { Component, OnInit, ViewChild } from "@angular/core";
import { StorageMap } from "@ngx-pwa/local-storage";
import { UpdateInvitationAction } from "../../../../store/actions/spa.actions";
import { Invitation, SPAState } from "src/store/states/spa.state";
import { Title } from "@angular/platform-browser";
import { Subscription } from "rxjs";
import { Store } from "@ngrx/store";
import { RouterService } from "src/app/services/router.service";
import { CheckboxComponent } from "../../common/form-fields/checkbox/checkbox.component";

@Component({
  selector: "app-confirm-personal-email",
  templateUrl: "./confirm-personal-email.component.html",
})
export class ConfirmPersonalEmailComponent implements OnInit {
  invitationSubscription: Subscription;
  invitation: Invitation;
  confirmPersonalEmailErrorMessage: string;
  selectedReasonId: number;
  valid: boolean;

  @ViewChild("confirmPersonalEmailInput")
  confirmPersonalEmailInput: CheckboxComponent;

  constructor(
    private store: Store<SPAState>,
    private storage: StorageMap,
    private routerService: RouterService,
    private title: Title,
  ) {}

  ngOnInit(): void {
    this.title.setTitle("Confirm this personal email address");
    this.getInvitationFromStorage();
  }

  getInvitationFromStorage(): void {
    this.storage.has("invitation").subscribe((invitationExists) => {
      if (invitationExists) {
        this.storage.get("invitation").subscribe((invitation) => {
          this.invitation = invitation as Invitation;
        });
      } else {
        this.routerService.redirectToInvitationDashboard();
      }
    });
  }

  updateConfirmPersonalEmailAnswer(checked: boolean): void {
    if (checked) {
      this.confirmPersonalEmailErrorMessage = "";
      this.valid = true;
    } else {
      this.confirmPersonalEmailErrorMessage =
        "Select if appropriate checks have been completed";
      this.valid = false;
    }
    this.invitation.isPersonalEmail = checked;
  }

  async onContinueButtonClicked(): Promise<void> {
    this.confirmPersonalEmailErrorMessage = "";

    this.confirmPersonalEmailInput.setQuestionAnswer();

    if (!this.valid) {
      return;
    }

    this.store.dispatch(new UpdateInvitationAction(this.invitation));
    this.storage.set("invitation", this.invitation).subscribe(() => {});
    this.routerService.redirectToFinalSummaryWithId(
      this.invitation.invitationId,
    );
  }
}
