<session-check></session-check>
<div *ngIf="invitation">
  <div>
    <a
      [state]="invitation"
      [routerLink]="['/invitation/basic-info', invitation.invitationId]"
      class="nhsuk-back-link__link nhsuk-u-margin-bottom-6"
    >
      <svg
        class="nhsuk-icon nhsuk-icon__chevron-left"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        aria-hidden="true"
        height="24"
        width="24"
      >
        <path
          d="M8.5 12c0-.3.1-.5.3-.7l5-5c.4-.4 1-.4 1.4 0s.4 1 0 1.4L10.9 12l4.3 4.3c.4.4.4 1 0 1.4s-1 .4-1.4 0l-5-5c-.2-.2-.3-.4-.3-.7z"
        ></path>
      </svg>
      Back
    </a>
  </div>
  <div class="nhsuk-grid-row">
    <div class="nhsuk-grid-column-two-thirds">
      <fieldset class="nhsuk-fieldset">
        <legend class="nhsuk-fieldset__legend nhsuk-fieldset__legend--xl">
          <h1
            autofocus
            tabindex="-1"
            class="nhsuk-fieldset__heading"
            data-cy="confirm-personal-email-header"
          >
            Confirm this email address
          </h1>
        </legend>

        <p>
          <strong>{{ this.invitation.email }}</strong> is not on the CIS
          allowlist and will be treated as a personal email address.
        </p>

        <div class="nhsuk-form-group nhsuk-u-margin-top-5">
          <checkbox-input
            #confirmPersonalEmailInput
            id="confirm-personal-email"
            [requiredAnswer]="true"
            checkboxText="I can confirm that this applicant has been through the necessary onboarding and HR processes for their trusted organisation and does not have an allow-listed email address"
            [errorMessage]="confirmPersonalEmailErrorMessage"
            (questionAnswer)="updateConfirmPersonalEmailAnswer($event)"
            [cypressValue]="'confirm-personal-email'"
          >
          </checkbox-input>
        </div>

        <div class="nhsuk-form-group">
          <button
            data-cy="continue-button"
            class="nhsuk-button"
            type="submit"
            [ngClass]="{ 'spinner-button': submitButtonClicked }"
            (click)="onContinueButtonClicked()"
          >
            Continue
          </button>
        </div>
      </fieldset>
    </div>
  </div>
</div>
