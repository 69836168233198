<div *ngIf="invitationConfirmation" class="nhsuk-grid-row">
  <div class="nhsuk-grid-column-two-thirds">
    <div id="top"></div>

    <h1 autofocus tabindex="-1">Check your details</h1>

    <div *ngIf="errorMessage">
      <div
        class="nhsuk-error-summary"
        aria-labelledby="error-summary-title"
        role="alert"
        tabindex="-1"
      >
        <h2 class="nhsuk-error-summary__title" id="error-summary-title">
          There is a problem
        </h2>
        <div class="nhsuk-error-summary__body">
          <p>{{ errorMessage }}</p>
        </div>
      </div>
    </div>

    <dl class="nhsuk-summary-list">
      <div
        class="nhsuk-summary-list__row"
        *ngIf="dpoaRouteTaken == 'DpoaUsedAndPassed'"
      >
        <dt class="nhsuk-summary-list__key">Photo ID selected</dt>
        <dd class="nhsuk-summary-list__value" data-cy="dpoa-pass-photo-id">
          {{ idCheckDocuments | poaTypePipe }}
        </dd>
        <dd class="nhsuk-summary-list__actions">
          <a
            [state]="invitationConfirmation"
            [routerLink]="[changeLinkIdVerificationRoute]"
          >
            Change
            <span class="nhsuk-u-visually-hidden">photo ID selected</span>
          </a>
        </dd>
      </div>

      <div
        class="nhsuk-summary-list__row"
        *ngIf="
          dpoaRouteTaken == 'DpoaUsedAndFailed' ||
          dpoaRouteTaken == 'DpoaNotUsed'
        "
      >
        <dt class="nhsuk-summary-list__key">Proof of address selected</dt>
        <dd class="nhsuk-summary-list__value" data-cy="poa-type">
          {{ idCheckDocuments | poaTypePipe }}
        </dd>
        <dd class="nhsuk-summary-list__actions">
          <a
            [state]="invitationConfirmation"
            [routerLink]="[changeLinkIdVerificationRoute]"
          >
            Change
            <span class="nhsuk-u-visually-hidden">address verification</span>
          </a>
        </dd>
      </div>

      <div
        class="nhsuk-summary-list__row"
        *ngIf="
          dpoaRouteTaken == 'DpoaUsedAndFailed' ||
          dpoaRouteTaken == 'DpoaNotUsed'
        "
      >
        <dt class="nhsuk-summary-list__key">Photo ID selected</dt>
        <dd class="nhsuk-summary-list__value">
          <span data-cy="photo-id-one-display">{{
            invitationConfirmation.photoOneId | poaTypePipe
          }}</span>
          <br />
          <span data-cy="photo-id-two-display">{{
            invitationConfirmation.photoTwoId | poaTypePipe
          }}</span>
        </dd>
        <dd class="nhsuk-summary-list__actions">
          <a
            [state]="invitationConfirmation"
            [routerLink]="['/invitee/photo-id-two']"
          >
            Change<span class="nhsuk-u-visually-hidden"
              >your Photo ID documents</span
            >
          </a>
        </dd>
      </div>
    </dl>
    <dl class="nhsuk-summary-list">
      <div class="nhsuk-summary-list__row">
        <dt class="nhsuk-summary-list__key">Photo for your smartcard</dt>
        <dd class="nhsuk-summary-list__value" data-cy="profile-photo-display">
          <img
            [src]="invitationConfirmation.profilePhotoBase64"
            alt="Your passport style photo"
            class="nhsuk-image__img"
          />
        </dd>
        <dd class="nhsuk-summary-list__actions">
          <a
            [state]="invitationConfirmation"
            [routerLink]="['/invitee/profile-photo-upload']"
          >
            Change
            <span class="nhsuk-u-visually-hidden">profile photo</span>
          </a>
        </dd>
      </div>

      <div class="nhsuk-summary-list__row" *ngIf="displayDeliveryAddress">
        <dt class="nhsuk-summary-list__key">Delivery address</dt>
        <dd class="nhsuk-summary-list__value">
          {{ invitationConfirmation.deliveryAddress }}
        </dd>
        <dd class="nhsuk-summary-list__actions">
          <a
            [state]="invitationConfirmation"
            [routerLink]="changeLinkDeliveryAddressRoute"
          >
            Change<span class="nhsuk-u-visually-hidden">delivery address</span>
          </a>
        </dd>
      </div>
    </dl>
    <div>
      <p>
        After you submit your details, you will need to download our secure
        mobile app to verify your identity.
      </p>
    </div>
    <div class="nhsuk-form-group">
      <button
        data-cy="submit-invitee"
        type="submit"
        [ngClass]="{ 'spinner-button': submitButtonClicked }"
        [disabled]="submitButtonClicked"
        (click)="onSubmitButtonClicked()"
        class="nhsuk-button"
      >
        Submit now
      </button>
    </div>
  </div>
</div>
