import { Injectable } from "@angular/core";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { EnvironmentConfigService } from "./environment-config.service";
import { SeviceAvailabilityResponse } from "../models/serviceAvailability";
import { CimUrlResponse } from "../models/cim-url";

@Injectable()
export class SiteService {
  constructor(
    private http: HttpClient,
    private environmentConfigService: EnvironmentConfigService,
  ) {}

  checkServiceAvailability(): Observable<
    HttpResponse<SeviceAvailabilityResponse>
  > {
    return this.http.get<SeviceAvailabilityResponse>(
      `${
        this.environmentConfigService.getConfig().idBridgeApiUrl
      }/auth/availability`,
      { observe: "response" },
    );
  }

  getCimUrl(): Observable<HttpResponse<CimUrlResponse>> {
    return this.http.get<HttpResponse<CimUrlResponse>>(
      `${
        this.environmentConfigService.getConfig().idBridgeApiUrl
      }/auth/cim_link`,
      { observe: "body", responseType: "json" },
    );
  }
}
