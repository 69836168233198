<session-check></session-check>

<div class="nhsuk-grid-row">
  <div class="nhsuk-grid-column-two-thirds">
    <breadcrumb-link
      [breadcrumbRoute]="breadcrumbRoute"
      [breadcrumbText]="breadcrumbText"
    ></breadcrumb-link>

    <error-summary
      [errorSummaryMessages]="summaryErrorMessages"
    ></error-summary>
    <h1 autofocus tabindex="-1">Cancel applications</h1>

    <error-summary
      id="error-summary-messages"
      [errorSummaryMessages]="summaryErrorMessages"
    ></error-summary>

    <p>Applicants will no longer be able to continue their applications.</p>

    <div class="nhsuk-form-group nhsuk-u-margin-top-5">
      <select-dropdown
        #reasonInput
        questionIdentifier="reason-select"
        question="Select a reason for cancelling these applications"
        [errorMessage]="dropdownErrorMessage"
        [answers]="reasonList"
        (questionAnswer)="updateReasonAnswer($event)"
      ></select-dropdown>
    </div>
    <div
      class="nhsuk-form-group nhsuk-u-margin-top-5"
      *ngIf="selectedReasonId == 3"
    >
      <text-input
        id="other-reason-notes"
        [label]="'Please specify'"
        [errorMessage]="textErrorMessage"
        (questionAnswer)="otherReason = $event"
      ></text-input>
    </div>

    <button
      id="cancel-button"
      data-cy="cancel-button"
      class="nhsuk-button nhsuk-button--secondary delete-button"
      type="submit"
      [ngClass]="{ 'spinner-button': submitButtonClicked }"
      [disabled]="submitButtonClicked"
      (click)="onCancelInvitationButtonClicked()"
    >
      Cancel applications
    </button>
  </div>
</div>
