<session-check></session-check>

<div *ngIf="loading">
  <progress-indicator spinnerText="Loading applications"></progress-indicator>
</div>
<div class="nhsuk-grid-row" *ngIf="!loading">
  <div class="nhsuk-grid-column-full">
    <h1 autofocus tabindex="-1" class="nhsuk-heading-xl">
      Search all applications
    </h1>

    <form action="#" method="post" class="form">
      <p *ngIf="filterActive">
        <a class="clear-link" (click)="clearFilters()">Clear all filters</a>
      </p>

      <div class="nhsuk-form-group">
        <label
          class="nhsuk-label nhsuk-label--s nhsuk-u-margin-bottom-1"
          for="searchTerm"
        >
          Name, email address or application ID
        </label>
        <input
          class="nhsuk-input nhsuk-input--width-20"
          type="text"
          id="searchTerm"
          name="searchTerm"
          aria-label="search box"
          (keypress)="omitSpecialChar($event)"
          [(ngModel)]="searchTerm"
          (keyup.enter)="onSearchClicked()"
        />
      </div>

      <div class="nhsuk-form-group nhsuk-u-margin-top-3">
        <label
          class="nhsuk-label nhsuk-label--s nhsuk-u-margin-bottom-1"
          for="odsSearchTerm"
        >
          Org code(s)
        </label>
        <div class="nhsuk-hint nhsuk-u-reading-width" id="ods-search-hint">
          For multiple ODS codes include a space between each one. For example,
          Y54 A3A8R RW4
        </div>
        <textarea
          class="nhsuk-textarea nhsuk-u-width-one-half"
          rows="5"
          id="odsSearchTerm"
          name="odsSearchTerm"
          aria-describedby="ods-search-hint"
          [(ngModel)]="odsSearchTerm"
          (keyup.enter)="onSearchClicked()"
        ></textarea>
      </div>

      <div class="nhsuk-form-group nhsuk-u-margin-top-3">
        <fieldset class="nhsuk-fieldset">
          <legend class="nhsuk-fieldset__legend nhsuk-fieldset__legend--s">
            <h2 class="nhsuk-fieldset__heading">Raised by</h2>
          </legend>

          <div class="nhsuk-checkboxes nhsuk-checkboxes--small">
            <div class="nhsuk-checkboxes__item">
              <input
                class="nhsuk-checkboxes__input"
                id="senderOnlyInvitationsCheckbox"
                name="senderOnlyCheckbox"
                type="checkbox"
                [(ngModel)]="senderOnly"
                aria-label="sender only checkbox"
                title="sender only checkbox"
              />
              <label
                class="nhsuk-label nhsuk-checkboxes__label"
                for="senderOnlyInvitationsCheckbox"
              >
                Applications I have raised
              </label>
            </div>
          </div>
        </fieldset>
      </div>

      <div class="nhsuk-form-group nhsuk-u-margin-top-3">
        <fieldset class="nhsuk-fieldset">
          <legend class="nhsuk-fieldset__legend nhsuk-fieldset__legend--s">
            <h2 class="nhsuk-fieldset__heading">Status</h2>
          </legend>
          <div class="nhsuk-checkboxes nhsuk-checkboxes--small">
            <div *ngFor="let i of statuses; let x = index">
              <div class="nhsuk-checkboxes__item">
                <input
                  class="nhsuk-checkboxes__input"
                  type="checkbox"
                  name="{{ i.dashboardStatus }}"
                  id="{{ i.dashboardStatus }}"
                  [(ngModel)]="i.isSelected"
                  [value]="i.dashboardStatus"
                  (change)="checkIfAnyStatusChecked()"
                  [(checked)]="i.isSelected"
                  aria-label="{{ i.dashboardStatus }}"
                  title="{{ i.dashboardStatus }}"
                />
                <label
                  class="nhsuk-label nhsuk-checkboxes__label"
                  [for]="i.dashboardStatus"
                >
                  {{ i.dashboardStatus }}
                </label>
              </div>
            </div>
          </div>
        </fieldset>
      </div>

      <button
        class="nhsuk-button nhsuk-button nhsuk-u-margin-bottom-8"
        (click)="onSearchClicked()"
        id="search"
        type="submit"
        title="search box"
      >
        Search
      </button>
    </form>
  </div>

  <div style="float: right">
    <div class="nhsuk-form-group">
      <label class="nhsuk-label" for="sort-by"> Sort by </label>
      <select
        class="nhsuk-select"
        id="sort-by"
        name="sort-by"
        (change)="sortChanged($event.target.value)"
      >
        <option value="givenName">Applicant name</option>
        <option value="email">Email address</option>
        <option value="statusLastUpdatedDateUtc" selected>Newest</option>
        <option value="statusLastUpdatedDateUtcOldest">Oldest</option>
        <option value="createdByUserName">Raised by</option>
        <option value="createdByUserOrgCode">Org Code</option>
        <option value="invitationId">Application ID</option>
        <option value="status">Status</option>
      </select>
    </div>
  </div>
  <div id="results" class="nhsuk-grid-column-full">
    <table role="table" class="nhsuk-table-responsive nhsuk-u-margin-top-4">
      <caption class="nhsuk-table__caption">
        {{
          tableCaption
        }}
      </caption>
      <thead role="rowgroup" class="nhsuk-table__head">
        <tr role="row">
          <th role="columnheader" class="" scope="col">Applicant name</th>
          <th role="columnheader" class="" scope="col">Email address</th>
          <th role="columnheader" class="" scope="col">Last updated on</th>
          <th role="columnheader" class="" scope="col">Org code</th>
          <th role="columnheader" class="" scope="col">Raised by</th>
          <th role="columnheader" class="" scope="col">App ID</th>
          <th role="columnheader" class="" scope="col">Status</th>
        </tr>
      </thead>
      <tbody class="nhsuk-table__body">
        <tr
          role="row"
          class="nhsuk-table__row"
          *ngFor="let invitation of filteredInvitations | paginate: config"
        >
          <td class="nhsuk-table__cell">
            <span class="nhsuk-table-responsive__heading">Applicant name</span>
            <a
              attr.data-cy="link-{{ invitation.id }}"
              [state]="{
                currentPage: config.currentPage,
                filteredInvitations: filteredInvitations,
                allInvitations: allInvitations,
                statuses: statuses,
                searchTerm: searchTerm,
                odsSearchTerm: odsSearchTerm,
                senderOnly: senderOnly,
                prevPage: '/invitation/all-invitations'
              }"
              [routerLink]="['/invitation/invite-details', invitation.id]"
            >
              {{ invitation.name }}
            </a>
          </td>
          <td class="nhsuk-table__cell">
            <span class="nhsuk-table-responsive__heading">Email</span>
            {{ invitation.email }}
          </td>
          <td class="nhsuk-table__cell">
            <span class="nhsuk-table-responsive__heading">Raised on</span>
            {{ invitation.date | date: "d MMM yyyy" }}
          </td>
          <td class="nhsuk-table__cell">
            <span class="nhsuk-table-responsive__heading">Org code</span
            >{{ invitation.org }}
          </td>
          <td class="nhsuk-table__cell">
            <span class="nhsuk-table-responsive__heading">Raised by</span
            >{{ invitation.user }}
          </td>

          <td [attr.data-cy]="invitation.id" class="nhsuk-table__cell">
            <span class="nhsuk-table-responsive__heading">App. ID</span>
            {{ invitation.id }}
          </td>
          <td class="nhsuk-table__cell">
            <span class="nhsuk-table-responsive__heading">Status</span>
            <span
              [attr.data-cy]="invitation.status"
              [ngClass]="invitation.status | invitationStatusClassPipe"
              class="nhsuk-tag"
            >
              {{ invitation.status | invitationStatusPipe }}
            </span>
          </td>
        </tr>
      </tbody>
    </table>
    <pagination-template
      #p="paginationApi"
      [id]="config.id"
      (pageChange)="config.currentPage = $event"
    >
      <nav class="nhsuk-pagination" role="navigation" aria-label="Pagination">
        <ul class="nhsuk-list nhsuk-pagination__list">
          <li
            [class.disabled]="p.isFirstPage()"
            class="nhsuk-pagination-item--previous"
          >
            <a
              *ngIf="!p.isFirstPage()"
              (click)="p.previous()"
              (keydown.enter)="p.previous()"
              tabindex="0"
              class="nhsuk-pagination__link nhsuk-pagination__link--prev"
            >
              <span class="nhsuk-pagination__title">Previous</span>
              <span class="nhsuk-u-visually-hidden">:</span>
              <span class="nhsuk-pagination__page"
                >{{ config.currentPage - 1 }} of {{ p.getLastPage() }}</span
              >
              <svg
                class="nhsuk-icon nhsuk-icon__arrow-left"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                aria-hidden="true"
                width="34"
                height="34"
              >
                <path
                  d="M4.1 12.3l2.7 3c.2.2.5.2.7 0 .1-.1.1-.2.1-.3v-2h11c.6 0 1-.4 1-1s-.4-1-1-1h-11V9c0-.2-.1-.4-.3-.5h-.2c-.1 0-.3.1-.4.2l-2.7 3c0 .2 0 .4.1.6z"
                ></path>
              </svg>
            </a>
          </li>
          <li
            [class.disabled]="p.isLastPage()"
            class="nhsuk-pagination-item--next"
          >
            <a
              class="nhsuk-pagination__link nhsuk-pagination__link--next"
              (keydown.enter)="p.next()"
              tabindex="0"
              *ngIf="!p.isLastPage()"
              (click)="p.next()"
            >
              <span class="nhsuk-pagination__title">Next</span>
              <span class="nhsuk-u-visually-hidden">:</span>
              <span class="nhsuk-pagination__page"
                >{{ config.currentPage + 1 }} of {{ p.getLastPage() }}</span
              >
              <svg
                class="nhsuk-icon nhsuk-icon__arrow-right"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                aria-hidden="true"
                width="34"
                height="34"
              >
                <path
                  d="M19.6 11.66l-2.73-3A.51.51 0 0 0 16 9v2H5a1 1 0 0 0 0 2h11v2a.5.5 0 0 0 .32.46.39.39 0 0 0 .18 0 .52.52 0 0 0 .37-.16l2.73-3a.5.5 0 0 0 0-.64z"
                ></path>
              </svg>
            </a>
          </li>
        </ul>
      </nav>
    </pagination-template>
  </div>
</div>
