import { Action } from "@ngrx/store";
import { IdCheckFailure } from "src/app/models/idCheckFailure";

export const UPDATE_ID_CHECKER_REJECTION = "Update Id Checker Rejection";

export class UpdateIdCheckerRejectionAction implements Action {
  readonly type = UPDATE_ID_CHECKER_REJECTION;
  payload: IdCheckFailure[];

  constructor(payload: IdCheckFailure[]) {
    this.payload = payload;
  }
}

export type IdCheckerActions = UpdateIdCheckerRejectionAction;
