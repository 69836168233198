<session-check></session-check>
<modified-record-check #modifiedRecordCheck></modified-record-check>
<section *ngIf="!invitation">
  <progress-indicator spinnerText="Loading application"></progress-indicator>
</section>
<div *ngIf="invitation" class="nhsuk-grid-row">
  <div class="nhsuk-grid-column-full">
    <a
      [routerLink]="['/id-checker/review-id', invitationId]"
      class="nhsuk-back-link__link nhsuk-u-margin-bottom-6"
    >
      <svg
        class="nhsuk-icon nhsuk-icon__chevron-left"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        aria-hidden="true"
        height="24"
        width="24"
      >
        <path
          d="M8.5 12c0-.3.1-.5.3-.7l5-5c.4-.4 1-.4 1.4 0s.4 1 0 1.4L10.9 12l4.3 4.3c.4.4.4 1 0 1.4s-1 .4-1.4 0l-5-5c-.2-.2-.3-.4-.3-.7z"
        ></path>
      </svg>
      Back
    </a>
    <h1 autofocus tabindex="-1" class="nhsuk-u-margin-bottom-5">
      Check ID details
    </h1>

    <p class="nhsuk-u-width-two-thirds">
      Check photo IDs and proof of address documents match the ID number, date
      of issue and issuing country in the system. Amend any details that are
      incorrect or missing.
    </p>

    <p class="nhsuk-u-width-two-thirds">
      Submit these details to add them to the applicant’s Care ID.
    </p>

    <error-summary
      id="error-summary-messages"
      [errorSummaryMessages]="summaryErrorMessages"
    ></error-summary>

    <div class="nhsuk-card nhsuk-u-margin-bottom-5">
      <div class="nhsuk-card__content">
        <h2 class="nhsuk-card__heading">Photo ID 1</h2>

        <div class="nhsuk-grid-row">
          <div
            [ngClass]="
              photoIdOneFrontExpand || photoIdOneBackExpand
                ? 'nhsuk-grid-column-full'
                : 'nhsuk-grid-column-one-half'
            "
          >
            <div class="nhsuk-grid-row">
              <div
                [ngClass]="
                  photoIdOneFrontExpand || !photoIdOneBackExpand
                    ? 'nhsuk-grid-column-full'
                    : 'nhsuk-grid-column-one-half'
                "
              >
                <id-checker-id-photo
                  *ngIf="this.photoIdOneImgFront"
                  [photoUrl]="this.photoIdOneImgFront"
                  [altText]="'Id Photo One Front'"
                  (isPhotoExpanded)="this.isPhotoIdOneFrontExpanded($event)"
                  [showInstructions]="true"
                ></id-checker-id-photo>
              </div>
              <div
                class="margin-top-20"
                [ngClass]="
                  photoIdOneBackExpand || !photoIdOneFrontExpand
                    ? 'nhsuk-grid-column-full'
                    : 'nhsuk-grid-column-one-half'
                "
              >
                <id-checker-id-photo
                  *ngIf="this.photoIdOneImgBack"
                  [photoUrl]="this.photoIdOneImgBack"
                  [altText]="'Id Photo One Back'"
                  (isPhotoExpanded)="this.isPhotoIdOneBackExpanded($event)"
                ></id-checker-id-photo>
              </div>
            </div>
          </div>

          <div class="nhsuk-grid-column-one-half nhsuk-u-padding-left-5">
            <div id="photo-one-inputs" class="photo-one-inputs"></div>
            <div class="nhsuk-form-group">
              <label class="nhsuk-label" for="photo-id-one-number">
                ID number
              </label>
              <text-input
                #photoIdOneNumberInput
                [id]="'photo-id-one-number'"
                [errorMessage]="
                  evidenceFieldsRecord.PhotoIdOne.number.errorMessage
                "
                [value]="evidenceFieldsRecord.PhotoIdOne.number.value"
                [uppercase]="false"
                type="numeric"
                (questionAnswer)="
                  updateEvidenceFieldAnswer('PhotoIdOne', 'number', $event)
                "
              >
              </text-input>
            </div>

            <p class="nhsuk-u-margin-bottom-2">Issue date</p>
            <id-checker-date
              aria-label="photo id one issue date"
              #photoIdOneIssueDateInput
              id="photo-id-one-issue-date"
              [idCheckerDateInput]="
                evidenceFieldsRecord.PhotoIdOne.issueDate.value
              "
              (idCheckerDateOutput)="
                updateEvidenceFieldAnswer('PhotoIdOne', 'issueDate', $event)
              "
              (idCheckerDateValidOutput)="
                setEvidenceIssueDateValidFromEvent('PhotoIdOne', $event)
              "
              (idCheckerDateErrorMessageOutput)="
                setEvidenceIssueDateErrorMessageFromEvent('PhotoIdOne', $event)
              "
            ></id-checker-date>
            <div
              class="nhsuk-form-group"
              [ngClass]="{
                'nhsuk-form-group--error':
                  evidenceFieldsRecord.PhotoIdOne.issue.errorMessage
              }"
            >
              <label class="nhsuk-label" for="photo-id-one-country">
                Issuing country
              </label>
              <span
                *ngIf="evidenceFieldsRecord.PhotoIdOne.issue.errorMessage"
                role="alert"
                class="nhsuk-error-message"
                >{{ evidenceFieldsRecord.PhotoIdOne.issue.errorMessage }}</span
              >
              <select
                [(ngModel)]="evidenceFieldsRecord.PhotoIdOne.issue.value"
                class="nhsuk-select"
                id="photo-id-one-country"
              >
                <option [ngValue]="code.key" *ngFor="let code of countryCodes">
                  {{ code.key }} - {{ code.value }}
                </option>
              </select>
            </div>
            <div class="nhsuk-form-group">
              <label class="nhsuk-label" for="photo-id-one-document-type">
                Document Type
              </label>
              <span
                *ngIf="evidenceFieldsRecord.PhotoIdOne.docType.errorMessage"
                role="alert"
                class="nhsuk-error-message"
                >{{
                  evidenceFieldsRecord.PhotoIdOne.docType.errorMessage
                }}</span
              >
              <select
                [(ngModel)]="evidenceFieldsRecord.PhotoIdOne.docType.value"
                class="nhsuk-select"
                id="photo-id-one-document-type"
              >
                <option ngValue="none" selected>Select Document Type</option>
                <option
                  [ngValue]="documentType.value"
                  *ngFor="let documentType of photoIdDocumentTypes"
                >
                  {{ documentType.key }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="nhsuk-card nhsuk-u-margin-bottom-5"
      *ngIf="poaRouteTaken == 2 || poaRouteTaken == 3"
    >
      <div class="nhsuk-card__content">
        <h2 class="nhsuk-card__heading">Photo ID 2</h2>

        <div class="nhsuk-grid-row">
          <div
            [ngClass]="
              photoIdTwoFrontExpand || photoIdTwoBackExpand
                ? 'nhsuk-grid-column-full'
                : 'nhsuk-grid-column-one-half'
            "
          >
            <div class="nhsuk-grid-row">
              <div
                [ngClass]="
                  photoIdTwoFrontExpand || !photoIdTwoBackExpand
                    ? 'nhsuk-grid-column-full'
                    : 'nhsuk-grid-column-one-half'
                "
              >
                <id-checker-id-photo
                  *ngIf="this.photoIdTwoImgFront"
                  [photoUrl]="this.photoIdTwoImgFront"
                  [altText]="'Id Photo Two Front'"
                  (isPhotoExpanded)="this.isPhotoIdTwoFrontExpanded($event)"
                  [showInstructions]="true"
                ></id-checker-id-photo>
              </div>
              <div
                class="margin-top-20"
                [ngClass]="
                  photoIdTwoBackExpand || !photoIdTwoFrontExpand
                    ? 'nhsuk-grid-column-full'
                    : 'nhsuk-grid-column-one-half'
                "
              >
                <id-checker-id-photo
                  *ngIf="this.photoIdTwoImgBack"
                  [photoUrl]="this.photoIdTwoImgBack"
                  [altText]="'Id Photo Two Back'"
                  (isPhotoExpanded)="this.isPhotoIdTwoBackExpanded($event)"
                ></id-checker-id-photo>
              </div>
            </div>
          </div>

          <div class="nhsuk-grid-column-one-half nhsuk-u-padding-left-5">
            <div id="photo-two-inputs" class="photo-two-inputs"></div>
            <div class="nhsuk-form-group">
              <label class="nhsuk-label" for="photo-id-two-number">
                ID number
              </label>
              <text-input
                #photoIdTwoNumberInput
                [id]="'photo-id-two-number'"
                [errorMessage]="
                  evidenceFieldsRecord.PhotoIdTwo.number.errorMessage
                "
                [value]="evidenceFieldsRecord.PhotoIdTwo.number.value"
                [uppercase]="false"
                type="numeric"
                (questionAnswer)="
                  updateEvidenceFieldAnswer('PhotoIdTwo', 'number', $event)
                "
              >
              </text-input>
            </div>

            <p class="nhsuk-u-margin-bottom-2">Issue date</p>

            <id-checker-date
              aria-label="photo id two issue date"
              #photoIdTwoIssueDateInput
              id="photo-id-two-issue-date"
              [idCheckerDateInput]="
                evidenceFieldsRecord.PhotoIdTwo.issueDate.value
              "
              (idCheckerDateOutput)="
                updateEvidenceFieldAnswer('PhotoIdTwo', 'issueDate', $event)
              "
              (idCheckerDateValidOutput)="
                setEvidenceIssueDateValidFromEvent('PhotoIdTwo', $event)
              "
              (idCheckerDateErrorMessageOutput)="
                setEvidenceIssueDateErrorMessageFromEvent('PhotoIdTwo', $event)
              "
            ></id-checker-date>

            <div
              class="nhsuk-form-group"
              [ngClass]="{
                'nhsuk-form-group--error':
                  evidenceFieldsRecord.PhotoIdTwo.issue.errorMessage
              }"
            >
              <label class="nhsuk-label" for="photo-id-two-country">
                Issuing country
              </label>
              <span
                *ngIf="evidenceFieldsRecord.PhotoIdTwo.issue.errorMessage"
                role="alert"
                class="nhsuk-error-message"
                >{{ evidenceFieldsRecord.PhotoIdTwo.issue.errorMessage }}</span
              >
              <select
                [(ngModel)]="evidenceFieldsRecord.PhotoIdTwo.issue.value"
                class="nhsuk-select"
                id="photo-id-two-country"
              >
                <option [ngValue]="code.key" *ngFor="let code of countryCodes">
                  {{ code.key }} - {{ code.value }}
                </option>
              </select>
            </div>
            <div class="nhsuk-form-group">
              <label class="nhsuk-label" for="photo-id-two-document-type">
                Document Type
              </label>
              <span
                *ngIf="evidenceFieldsRecord.PhotoIdTwo.docType.errorMessage"
                role="alert"
                class="nhsuk-error-message"
                >{{
                  evidenceFieldsRecord.PhotoIdTwo.docType.errorMessage
                }}</span
              >
              <select
                [(ngModel)]="evidenceFieldsRecord.PhotoIdTwo.docType.value"
                class="nhsuk-select"
                id="photo-id-two-document-type"
              >
                <option ngValue="none" selected>Select Document Type</option>
                <option
                  [ngValue]="documentType.value"
                  *ngFor="let documentType of photoIdDocumentTypes"
                >
                  {{ documentType.key }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="nhsuk-card nhsuk-u-margin-bottom-5"
      *ngIf="poaRouteTaken == 2 || poaRouteTaken == 3"
    >
      <div class="nhsuk-card__content">
        <h2 class="nhsuk-card__heading">Proof of address</h2>

        <div class="nhsuk-grid-row">
          <div
            [ngClass]="
              poaExpand
                ? 'nhsuk-grid-column-full'
                : 'nhsuk-grid-column-one-half'
            "
          >
            <id-checker-id-photo
              *ngIf="this.poaImg"
              [photoUrl]="this.poaImg"
              [showInstructions]="true"
              [altText]="'Poa image'"
              (isPhotoExpanded)="this.isPoaExpanded($event)"
            ></id-checker-id-photo>
          </div>

          <div class="nhsuk-grid-column-one-half nhsuk-u-padding-left-5">
            <div id="poa-inputs" class="poa-inputs"></div>
            <div class="nhsuk-form-group">
              <label class="nhsuk-label" for="poa-number"> ID number </label>
              <text-input
                #poaNumberInput
                [id]="'poa-number'"
                [errorMessage]="
                  evidenceFieldsRecord.ProofOfAddress.number.errorMessage
                "
                [value]="evidenceFieldsRecord.ProofOfAddress.number.value"
                [uppercase]="false"
                type="numeric"
                (questionAnswer)="
                  updateEvidenceFieldAnswer('ProofOfAddress', 'number', $event)
                "
              >
              </text-input>
            </div>

            <p class="nhsuk-u-margin-bottom-2">Issue date</p>

            <id-checker-date
              aria-label="photo id two issue date"
              #poaIssueDateInput
              id="poa-issue-date"
              [idCheckerDateInput]="
                evidenceFieldsRecord.ProofOfAddress.issueDate.value
              "
              (idCheckerDateOutput)="
                updateEvidenceFieldAnswer('ProofOfAddress', 'issueDate', $event)
              "
              (idCheckerDateValidOutput)="
                setEvidenceIssueDateValidFromEvent('ProofOfAddress', $event)
              "
              (idCheckerDateErrorMessageOutput)="
                setEvidenceIssueDateErrorMessageFromEvent(
                  'ProofOfAddress',
                  $event
                )
              "
            ></id-checker-date>

            <div
              class="nhsuk-form-group"
              [ngClass]="{
                'nhsuk-form-group--error':
                  evidenceFieldsRecord.ProofOfAddress.issue.errorMessage
              }"
            >
              <label class="nhsuk-label" for="poa-country">
                Issuing country
              </label>
              <span
                *ngIf="evidenceFieldsRecord.ProofOfAddress.issue.errorMessage"
                role="alert"
                class="nhsuk-error-message"
                >{{
                  evidenceFieldsRecord.ProofOfAddress.issue.errorMessage
                }}</span
              >
              <select
                [(ngModel)]="evidenceFieldsRecord.ProofOfAddress.issue.value"
                class="nhsuk-select"
                id="poa-country"
              >
                <option [ngValue]="code.key" *ngFor="let code of countryCodes">
                  {{ code.key }} - {{ code.value }}
                </option>
              </select>
            </div>

            <div class="nhsuk-form-group">
              <label class="nhsuk-label" for="poa-document-type">
                Document Type
              </label>
              <span
                *ngIf="evidenceFieldsRecord.ProofOfAddress.docType.errorMessage"
                role="alert"
                class="nhsuk-error-message"
                >{{
                  evidenceFieldsRecord.ProofOfAddress.docType.errorMessage
                }}</span
              >
              <select
                data-cy="poa-select"
                [(ngModel)]="evidenceFieldsRecord.ProofOfAddress.docType.value"
                class="nhsuk-select"
                id="poa-document-type"
              >
                <option ngValue="none" selected>Select Document Type</option>
                <option
                  [ngValue]="documentType.value"
                  *ngFor="let documentType of poaDocumentTypes"
                >
                  {{ documentType.key }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="nhsuk-card nhsuk-u-margin-bottom-5">
      <div class="nhsuk-card__content">
        <div class="nhsuk-form-group nhsuk-u-width-two-thirds">
          <h2 class="nhsuk-label-wrapper">
            <label
              class="nhsuk-label nhsuk-card__heading nhsuk-label--l"
              for="approval-note"
            >
              Notes (optional)
            </label>
          </h2>
          <div class="nhsuk-hint">
            Notes are visible to inviters and ID checkers
          </div>
          <text-area-input
            #approvalNoteInput
            cypressValue="approval-note"
            id="approval-note"
            [value]="approvalNote"
            [rows]="3"
            [errorMessage]="noteErrorMessage"
            (questionAnswer)="updateApprovalNoteAnswer($event)"
          >
          </text-area-input>
          <p class="nhsuk-error-message" *ngIf="approvalNoteErrorMessage">
            {{ approvalNoteErrorMessage }}
          </p>
        </div>
      </div>
    </div>

    <div *ngIf="grantUserError">
      <div
        class="nhsuk-error-summary"
        aria-labelledby="error-summary-title"
        role="alert"
        tabindex="-1"
      >
        <h2 class="nhsuk-error-summary__title" id="error-summary-title">
          Sorry, there is a problem with the service.
        </h2>
        <div class="nhsuk-error-summary__body">
          Please contact your administrator.
        </div>
      </div>
      <br />
    </div>
    <div *ngIf="validationErrorMessage != ''">
      <div
        class="nhsuk-error-summary"
        aria-labelledby="error-summary-title"
        role="alert"
        tabindex="-1"
      >
        <h2 class="nhsuk-error-summary__title" id="error-summary-title">
          Sorry, there is a problem with the data submitted.
        </h2>
        <div class="nhsuk-error-summary__body">
          {{ validationErrorMessage }}
        </div>
      </div>
    </div>

    <button
      data-cy="submit-approved"
      [disabled]="
        isSubmitted ||
        (invitation.status == 51 && this.finalDuplicateCheckInProgress)
      "
      class="nhsuk-button"
      (click)="submitButtonClicked()"
      [ngClass]="{ 'spinner-button': isSubmitted }"
    >
      Create Care ID
    </button>
  </div>
</div>
