<session-check></session-check>
<div class="nhsuk-grid-row">
  <div class="nhsuk-grid-column-two-thirds">
    <breadcrumb-link
      [breadcrumbRoute]="breadcrumbRoute"
      [breadcrumbText]="breadcrumbText"
    ></breadcrumb-link>
    <error-summary
      [errorSummaryMessages]="summaryErrorMessages"
    ></error-summary>
    <fieldset class="nhsuk-fieldset">
      <legend class="nhsuk-fieldset__legend nhsuk-fieldset__legend--xl">
        <h1 autofocus tabindex="-1" class="nhsuk-fieldset__heading">
          View payload
        </h1>
      </legend>
      <div class="nhsuk-hint">
        This tool is used to display the raw JSON payload provided by the
        vendor. (Please note the Base64 encoded images are stripped from this
        payload).
      </div>
      <div id="lookup_field"></div>
      <div class="nhsuk-form-group">
        <label class="nhsuk-label" for="address-postcode">
          Invitation Id
        </label>
        <text-input
          #invitationIdInput
          [id]="'invitation-id'"
          [errorMessage]="invitationIdErrorMessage"
          [value]="invitationId"
          [uppercase]="false"
          (questionAnswer)="updateInvitationIdAnswer($event)"
          size="nhsuk-input--width-10"
          [cypressValue]="'invitation-id'"
        ></text-input>
      </div>
    </fieldset>
    <button
      [disabled]="loading"
      class="nhsuk-button nhsuk-u-margin-bottom-5 nhsuk-u-margin-right-3"
      (click)="submitAnswer()"
      id="continue"
    >
      View payload
    </button>
    <button
      [disabled]="loading"
      class="nhsuk-button nhsuk-button--secondary nhsuk-u-margin-bottom-5"
      (click)="clear()"
      id="clear"
    >
      Reset
    </button>
  </div>
</div>
<div class="nhsuk-grid-row">
  <section *ngIf="loading">
    <progress-indicator spinnerText="Loading payload"></progress-indicator>
  </section>
  <section *ngIf="!loading">
    <div *ngIf="payload">
      <div class="payload">{{ payload | json }}</div>
    </div>
  </section>
</div>
