import { Pipe, PipeTransform } from "@angular/core";
import { InvitationStatus } from "../../../models/invitationDetails";

@Pipe({ name: "invitationStatusPipe" })
export class InvitationStatusPipe implements PipeTransform {
  transform(value: number): string {
    if (
      value == InvitationStatus["Invite resend required"] ||
      value == InvitationStatus["Invite email not sent"] ||
      value == InvitationStatus.Locked
    ) {
      return "Resend Required";
    }

    if (
      value == InvitationStatus.Sent ||
      value == InvitationStatus.Resent ||
      value == InvitationStatus["Duplicate check in progress"] ||
      value == InvitationStatus["Awaiting documents"] ||
      value == InvitationStatus["ID review"] ||
      value == InvitationStatus["On hold"] ||
      value == InvitationStatus.OtpExpired
    ) {
      return "In Progress";
    }

    if (
      value == InvitationStatus.Rejected ||
      value == InvitationStatus["Application expired"]
    ) {
      return "Rejected";
    }

    if (value == InvitationStatus["Duplicate information"]) {
      return "Duplicate";
    }

    if (value == InvitationStatus["Application cancelled"]) {
      return "Cancelled";
    }

    return InvitationStatus[value];
  }
}

@Pipe({ name: "invitationStatusRawPipe" })
export class InvitationStatusRawPipe implements PipeTransform {
  private classMapping = {
    1: "Sent",
    2: "In Progress",
    3: "Invalid",
    4: "Resent",
    6: "Valid",
    29: "Email bounced",
    31: "Expired",
    32: "One time passcode expired",
    33: "Locked",
    34: "Duplicate found",
    35: "Awaiting documents",
    36: "OTP request blocked",
    37: "App link expired",
    38: "Cancelled",
    50: "Waiting for review",
    51: "On hold",
    52: "Approved",
    53: "Rejected",
  };

  transform(value: number): string {
    return this.classMapping[value];
  }
}
