import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-invite-cancelled",
  templateUrl: "./invite-cancelled.component.html",
})
export class InviteCancelledComponent implements OnInit {
  invitationId: string;
  applicationText = "Application";

  constructor(
    private title: Title,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.invitationId = this.route.snapshot.paramMap.get("invitationId");
    if (this.invitationId == "multi") {
      this.applicationText += "s";
    }
    this.title.setTitle("Application cancelled");
  }
}
