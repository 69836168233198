import { Component } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";

@Component({
  selector: "skip-link",
  templateUrl: "./skip-link.component.html",
})
export class SkipLinkComponent {
  currentUrl: any = "";

  /* istanbul ignore next */
  constructor(router: Router) {
    router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        if (e.url != "") {
          this.currentUrl = e.url.replace("#main-content", "");
        } else {
          this.currentUrl = "";
        }
      }
    });
  }
}
