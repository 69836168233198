import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { RouterService } from "src/app/services/router.service";
import { interval, Subscription } from "rxjs";
import { InvitationStatus } from "src/app/models/invitationDetails";
import { IdCheckerService } from "../../../services/id-checker.service";

@Component({
  selector: "modified-record-check",
  templateUrl: "./modified-record-check.component.html",
})
export class ModifiedRecordCheckComponent implements OnInit, OnDestroy {
  checkFrequency = interval(20000);
  subscription: Subscription;
  warningDisplayed = false;
  displayNewStatus: string;
  displayNewStatusLastUpdatedUtc: string;
  timerId;

  @Input() enabled: boolean = false;
  @Input() invitationId: string;
  @Input() status: number;
  @Input() statusLastUpdatedUtc: string;

  constructor(
    private routerService: RouterService,
    private idCheckerService: IdCheckerService,
  ) {}

  ngOnInit(): void {
    this.subscription = this.checkFrequency.subscribe(async () => {
      await this.checkRecord();
    });
  }

  async checkRecord(): Promise<void> {
    if (!this.enabled) {
      return;
    }

    let response = await this.idCheckerService.modifiedRecordCheck(
      this.invitationId,
      this.status,
    );
    if (response["changed"]) {
      this.displayNewStatus = InvitationStatus[response["status"]].toString();
      this.displayNewStatusLastUpdatedUtc =
        response["status_last_updated_utc"].toString();
      this.displayRecordModifiedWarning();
    }
  }

  displayRecordModifiedWarning(): void {
    if (!this.warningDisplayed) {
      this.warningDisplayed = true;
      this.subscription.unsubscribe();
    }
  }

  viewDashboard(): void {
    this.routerService.redirectToCheckerReviewList();
  }

  onCloseClicked(): void {
    this.warningDisplayed = false;
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
