<div
  class="nhsuk-form-group"
  [ngClass]="{ 'nhsuk-form-group--error': radioError }"
>
  <fieldset class="nhsuk-fieldset">
    <legend class="nhsuk-fieldset__legend nhsuk-fieldset__legend--m">
      <h3 class="nhsuk-fieldset__heading">
        {{ getContent(id).question }}
      </h3>
    </legend>
    <span
      *ngIf="areRadioUnselected()"
      class="nhsuk-error-message"
      id="{{ id }}-error-description"
      [attr.data-cy]="errorMessageDataCy"
      >{{ radioError.message }}</span
    >
    <div class="nhsuk-radios nhsuk-radios--conditional">
      <div class="nhsuk-radios__item">
        <input
          attr.data-cy="{{ id }}-yes"
          class="nhsuk-radios__input"
          aria-expanded="false"
          id="radio-{{ id }}-yes"
          [name]="id"
          type="radio"
          [value]="true"
          [ngClass]="{
            selected: answer,
            focused: focusOnYes
          }"
          (click)="updateValue(RadioButtonAnswer.Yes, id)"
          (keypress)="updateValue(RadioButtonAnswer.Yes, id)"
          (focus)="setFocusOnYes(true)"
          (blur)="onBlur()"
        />
        <label class="nhsuk-label nhsuk-radios__label" for="radio-{{ id }}-yes">
          {{ getContent(id).yesAnswer }}
        </label>
      </div>

      <div class="nhsuk-radios__item">
        <input
          attr.data-cy="{{ id }}-no"
          class="nhsuk-radios__input"
          aria-expanded="false"
          id="radio-{{ id }}-no"
          [name]="id"
          type="radio"
          [value]="false"
          [ngClass]="{
            selected: !answer,
            focused: focusOnNo
          }"
          (click)="updateValue(RadioButtonAnswer.No, id)"
          (keypress)="updateValue(RadioButtonAnswer.No, id)"
          (focus)="setFocusOnNo(true)"
          (blur)="onBlur()"
        />
        <label class="nhsuk-label nhsuk-radios__label" for="radio-{{ id }}-no">
          {{ getContent(id).noAnswer }}
        </label>
      </div>

      <div *ngIf="answer == 0 && getContent(id).noReasons">
        <div class="nhsuk-checkboxes__conditional">
          <div class="nhsuk-hint" id="options-hint">
            Select all options that apply
          </div>
          <span
            *ngIf="areReasonsUnselected()"
            class="nhsuk-error-message"
            id="{{ id }}-error-description"
            [attr.data-cy]="errorMessageDataCy"
            >{{ radioError.message }}</span
          >
          <div
            *ngFor="let reason of getContent(id).noReasons"
            class="nhsuk-checkboxes__item"
          >
            <input
              class="nhsuk-checkboxes__input"
              id="{{ id }}-{{ getCheckboxId(reason) }}"
              attr.data-cy="{{ id }}-{{ getCheckboxId(reason) }}"
              [name]="id"
              type="checkbox"
              [checked]="value == true"
              (click)="updateCheckbox(reason)"
              (keypress)="updateCheckbox(reason)"
            />
            <label
              class="nhsuk-label nhsuk-checkboxes__label"
              for="{{ id }}-{{ getCheckboxId(reason) }}"
            >
              {{ getReasonText(reason) }}
            </label>
          </div>
        </div>
      </div>
    </div>
  </fieldset>
</div>
