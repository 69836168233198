import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "booleanValuePipe" })
export class BooleanValuePipe implements PipeTransform {
  private classMapping = {
    true: "Yes",
    false: "No",
    null: "No",
  };

  transform(value: string): string {
    return this.classMapping[value];
  }
}
