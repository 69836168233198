import {
  Component,
  HostListener,
  OnInit,
  ViewChild,
  Inject,
} from "@angular/core";
import { RouterService } from "src/app/services/router.service";
import { TextInputComponent } from "../../common/form-fields/text-inputs/text-input.component";
import { ErrorSummaryMessage } from "../../../models/error-summary-message";
import { InvitationConfirmation } from "../../../../store/states/invitee-spa.state";
import { isPreferredNameValid } from "../../../utils/data-validation/data-validation";
import { Title } from "@angular/platform-browser";
import { SESSION_STORAGE, StorageService } from "ngx-webstorage-service";
import { InviteeDetails } from "src/app/utils/invitee-details";

@Component({
  selector: "app-confirm-preferred-name",
  templateUrl: "./confirm-preferred-name.component.html",
})
export class ConfirmPreferredNameComponent implements OnInit {
  breadcrumbRoute = "/invitee/confirm-name";
  breadcrumbText = "Back";
  forwardRoute = "/invitee/confirm-dob";
  invitationConfirmation: InvitationConfirmation;
  captureDeliveryAddress: boolean;

  readonly maxInputLength = 20;
  charactersRemaining = this.maxInputLength;

  preferredNameValid: boolean;

  preferredNameErrorMessage = "";

  @ViewChild("preferredNameInput") preferredNameInput: TextInputComponent;

  summaryErrorMessages = [];

  readonly errorMessages = {
    preferredName: {
      lengthTooShort: "Preferred name must be at least 2 characters.",
      lengthTooLong: "Preferred name must be 20 characters or fewer.",
      missingUppercaseCharacter:
        "Preferred name must include a capital letter.",
      unexpectedCharacters:
        "Preferred name must only contain letters, apostrophes and hyphens.",
      doubleSpace: "Preferred name must not contain a double space.",
      unknown: "Please check you've entered your preferred name correctly.",
    },
  };

  constructor(
    private routerService: RouterService,
    private title: Title,
    @Inject(SESSION_STORAGE) private sessionStorage: StorageService,
    private inviteeDetails: InviteeDetails,
  ) {}

  @HostListener("input", ["$event"]) onInput(event: any) {
    this.charactersRemaining =
      this.maxInputLength - event.srcElement.value.length;
  }

  async ngOnInit(): Promise<void> {
    this.title.setTitle("Enter name on smartcard (optional)");
    this.invitationConfirmation = this.inviteeDetails.getInviteeDetails();
    this.setPreferredNameDefault(
      this.invitationConfirmation.firstName,
      this.invitationConfirmation.familyName,
      this.invitationConfirmation.preferredName,
    );
    this.captureDeliveryAddress = this.sessionStorage.get(
      "captureDeliveryAddress",
    );
  }

  setPreferredNameDefault(
    firstName: string,
    familyName: string,
    preferredName: string,
  ): void {
    if (preferredName == "") {
      let joinNames = firstName + " " + familyName;
      const joinNames20Chars = joinNames.substring(0, 20);
      this.invitationConfirmation.preferredName = joinNames20Chars;
      this.updatePreferredNameAnswer(joinNames20Chars);
      this.charactersRemaining = this.maxInputLength - joinNames20Chars.length;
    } else {
      this.charactersRemaining = this.maxInputLength - preferredName.length;
    }
  }

  submitAnswers(): void {
    if (this.areAnswersValid()) {
      this.inviteeDetails.setInviteeDetails(this.invitationConfirmation);
      this.checkApplicantProgress();
      this.routerService.redirectTo(this.forwardRoute);
    }
  }

  areAnswersValid(): boolean {
    this.getLatestAnswers();
    if (this.preferredNameValid) {
      return true;
    } else {
      return false;
    }
  }

  getLatestAnswers(): void {
    this.preferredNameInput.setQuestionAnswer();
  }

  updatePreferredNameAnswer(preferredName: string): void {
    const validationResult = isPreferredNameValid(preferredName);
    if (validationResult.validity) {
      this.preferredNameErrorMessage = "";
      this.preferredNameValid = true;
    } else {
      this.preferredNameErrorMessage =
        this.errorMessages.preferredName[validationResult.errorReason];
      this.preferredNameValid = false;
    }
    this.invitationConfirmation.preferredName = preferredName;
    this.updateErrorSummary();
  }

  updateErrorSummary() {
    const updatedErrorSummary = Array<ErrorSummaryMessage>();
    if (this.preferredNameErrorMessage) {
      updatedErrorSummary.push({
        message: this.errorMessages.preferredName.unknown,
        id: "preferred-name-input",
      });
    }
    this.summaryErrorMessages = updatedErrorSummary;
  }

  checkApplicantProgress(): void {
    if (
      this.invitationConfirmation.niNumber !== "" &&
      this.invitationConfirmation.dob !== ""
    ) {
      this.forwardRoute = "/invitee/check-your-answers";
    }
  }
}
