import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "app-start",
  templateUrl: "./start.component.html",
})
export class StartComponent implements OnInit {
  constructor(private title: Title) {}

  ngOnInit(): void {
    this.title.setTitle("Apply for Care ID - NHS");
  }
}
