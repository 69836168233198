<session-check></session-check>
<div *ngIf="invitation">
  <div *ngIf="invitation.invitationId">
    <a
      [state]="invitation"
      [routerLink]="['/invitation/invite-details', invitation.invitationId]"
      class="nhsuk-back-link__link nhsuk-u-margin-bottom-6"
    >
      <svg
        class="nhsuk-icon nhsuk-icon__chevron-left"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        aria-hidden="true"
        height="24"
        width="24"
      >
        <path
          d="M8.5 12c0-.3.1-.5.3-.7l5-5c.4-.4 1-.4 1.4 0s.4 1 0 1.4L10.9 12l4.3 4.3c.4.4.4 1 0 1.4s-1 .4-1.4 0l-5-5c-.2-.2-.3-.4-.3-.7z"
        ></path>
      </svg>
      Back
    </a>
  </div>
  <div *ngIf="!invitation.invitationId">
    <a
      href="/invitation/dashboard"
      class="nhsuk-back-link__link nhsuk-u-margin-bottom-6"
    >
      <svg
        class="nhsuk-icon nhsuk-icon__chevron-left"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        aria-hidden="true"
        height="24"
        width="24"
      >
        <path
          d="M8.5 12c0-.3.1-.5.3-.7l5-5c.4-.4 1-.4 1.4 0s.4 1 0 1.4L10.9 12l4.3 4.3c.4.4.4 1 0 1.4s-1 .4-1.4 0l-5-5c-.2-.2-.3-.4-.3-.7z"
        ></path>
      </svg>
      Back
    </a>
  </div>

  <div class="nhsuk-grid-row">
    <div class="nhsuk-grid-column-two-thirds">
      <div id="top"></div>
      <div
        *ngIf="isServerValidationErrorForEmail()"
        id="divServerValidationErrorForEmail"
        class="nhsuk-error-summary"
        aria-labelledby="error-summary-title"
        role="alert"
        tabindex="-1"
      >
        <h2 class="nhsuk-error-summary__title" id="error-summary-title">
          There is a problem
        </h2>
        <div
          class="nhsuk-error-summary__body"
          *ngIf="
            emailErrorMessage == errorMessages.email['duplicate'] &&
            existingInvitation
          "
        >
          <p>
            An application already exists for the email address
            <a
              [routerLink]="[
                '/invitation/invite-details',
                existingInvitation.invitationId
              ]"
            >
              {{ existingInvitation.email }}
            </a>
          </p>
          <ul class="nhsuk-list nhsuk-error-summary__list">
            <li>
              <a
                href="{{
                  this.routerService.getCurrentUrl().split('#')[0]
                }}#work-email-input"
                >{{ this.errorMessages.email["duplicate"] }}</a
              >
            </li>
          </ul>
        </div>
        <div
          class="nhsuk-error-summary__body"
          *ngIf="emailErrorMessage == errorMessages.email['domainNotAllowed']"
        >
          <ul class="nhsuk-list nhsuk-error-summary__list">
            <li>
              <a
                href="{{
                  this.routerService.getCurrentUrl().split('#')[0]
                }}#work-email-input"
                >{{ this.errorMessages.email["domainNotAllowed"] }}</a
              >
            </li>
          </ul>
        </div>
      </div>

      <div
        class="nhsuk-alert nhsuk-alert--info nhsuk-alert--icon"
        *ngIf="!existingInvitationId"
      >
        <svg
          class="nhsuk-icon nhsuk-icon__info nhsuk-alert__icon"
          fill="none"
          focusable="false"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 25 25"
          height="25"
          width="25"
        >
          <path
            d="M13.7,18.5h-2.4v-2.4h2.4V18.5z M12.5,13.7c-0.7,0-1.2-0.5-1.2-1.2V7.7c0-0.7,0.5-1.2,1.2-1.2s1.2,0.5,1.2,1.2v4.8
          C13.7,13.2,13.2,13.7,12.5,13.7z M12.5,0.5c-6.6,0-12,5.4-12,12s5.4,12,12,12s12-5.4,12-12S19.1,0.5,12.5,0.5z"
          ></path>
        </svg>
        <h3 class="nhsuk-u-visually-hidden">Information:</h3>
        <p class="nhsuk-u-font-weight-bold">
          This application will be raised under {{ orgName }} ({{ orgCode }}).
        </p>
      </div>
      <error-summary
        [errorSummaryMessages]="summaryErrorMessages"
      ></error-summary>
      <fieldset class="nhsuk-fieldset">
        <legend class="nhsuk-fieldset__legend nhsuk-fieldset__legend--xl">
          <h1 autofocus tabindex="-1" class="nhsuk-fieldset__heading">
            Enter applicant's personal details
          </h1>
        </legend>

        <div class="form-group">
          <text-input
            #givenNameInput
            [id]="'first-name-input'"
            label="First name"
            [errorMessage]="givenNameErrorMessage"
            [value]="invitation.givenName"
            (questionAnswer)="updateGivenNameAnswer($event)"
            [capitalisation]="true"
            [removeDoubleSpaces]="true"
            autocomplete="off"
            [cypressValue]="'given-name'"
          >
          </text-input>
          <text-input
            #familyNameInput
            [id]="'family-name-input'"
            label="Last name"
            [errorMessage]="familyNameErrorMessage"
            [value]="invitation.familyName"
            [capitalisation]="true"
            [removeDoubleSpaces]="true"
            autocomplete="off"
            (questionAnswer)="updateFamilyNameAnswer($event)"
            [cypressValue]="'family-name'"
          >
          </text-input>
          <text-input
            #emailInput
            inputType="email"
            inputMode="email"
            [id]="'work-email-input'"
            label="Email address"
            [errorMessage]="emailErrorMessage"
            [value]="invitation.email"
            autocomplete="off"
            (questionAnswer)="updateEmailAnswer($event)"
            [cypressValue]="'email'"
          ></text-input>
          <text-input
            hint="For international numbers include the country code at the start of the number. For example, +33123456789"
            #mobileInput
            inputType="tel"
            inputMode="tel"
            [id]="'mobile-input'"
            label="Mobile number"
            [errorMessage]="mobileErrorMessage"
            [value]="invitation.mobile"
            [removeSpaces]="true"
            autocomplete="off"
            (questionAnswer)="updateMobileAnswer($event)"
            [cypressValue]="'mobile'"
          >
          </text-input>
        </div>
      </fieldset>
      <div
        class="nhsuk-form-group nhsuk-u-margin-top-5"
        [ngClass]="{
          'nhsuk-form-group--error': captureDeliveryAddressErrorMessage
        }"
      >
        <fieldset class="nhsuk-fieldset">
          <legend
            class="nhsuk-fieldset__legend nhsuk-label nhsuk-u-margin-bottom-3"
          >
            <h2 class="nhsuk-fieldset__heading">
              Does the applicant need to provide a delivery address?
            </h2>
          </legend>
          <div class="nhsuk-hint">
            This address can be used to send a smartcard, fob or other ID
            device.
          </div>
          <span
            role="alert"
            *ngIf="captureDeliveryAddressErrorMessage"
            class="nhsuk-error-message"
            data-cy="error-message-delivery-address"
            >{{ captureDeliveryAddressErrorMessage }}</span
          >
          <div class="nhsuk-radios nhsuk-radios--inline">
            <div class="nhsuk-radios__item">
              <input
                class="nhsuk-radios__input"
                id="require-delivery-yes"
                name="require-delivery"
                type="radio"
                data-cy="yes-capture-address"
                aria-controls="yes"
                aria-expanded="false"
                [checked]="invitation.captureDeliveryAddress == true"
                (change)="updateDeliveryAddressAnswer(true)"
              />
              <label
                class="nhsuk-label nhsuk-radios__label"
                for="require-delivery-yes"
              >
                Yes
              </label>
            </div>

            <div class="nhsuk-radios__item">
              <input
                class="nhsuk-radios__input"
                id="require-delivery-no"
                name="require-delivery"
                type="radio"
                data-cy="no-capture-address"
                aria-controls="no"
                aria-expanded="false"
                [checked]="invitation.captureDeliveryAddress == false"
                (change)="updateDeliveryAddressAnswer(false)"
              />
              <label
                class="nhsuk-label nhsuk-radios__label"
                for="require-delivery-no"
              >
                No
              </label>
            </div>
          </div>
        </fieldset>
      </div>
      <div class="nhsuk-form-group">
        <button
          class="nhsuk-button"
          (click)="submitAnswers()"
          id="continue"
          [ngClass]="{ 'spinner-button': submitButtonClicked }"
          [disabled]="submitButtonClicked"
          data-cy="submit-invitation-button"
        >
          Continue
        </button>
      </div>
      <details class="nhsuk-details">
        <summary class="nhsuk-details__summary">
          <span class="nhsuk-details__summary-text">
            What to do if you don’t have the information required to complete
            this form
          </span>
        </summary>
        <div class="nhsuk-details__text">
          <p>
            Contact
            <a href="mailto:ssd.nationalservicedesk@nhs.net"
              >ssd.nationalservicedesk@nhs.net</a
            >
            for help and support.
          </p>
        </div>
      </details>
    </div>
  </div>
</div>
