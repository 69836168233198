import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { RouterService } from "src/app/services/router.service";
import { ErrorSummaryMessage } from "../../../models/error-summary-message";
import { InvitationConfirmation } from "./../../../../store/states/invitee-spa.state";
import { TextInputComponent } from "../../common/form-fields/text-inputs/text-input.component";
import { isPostcodeValid } from "src/app/utils/data-validation/data-validation";
import { Title } from "@angular/platform-browser";
import { InviteeDetails } from "src/app/utils/invitee-details";

@Component({
  selector: "app-confirm-postcode",
  templateUrl: "./confirm-postcode.component.html",
})
export class ConfirmPostcodeComponent implements OnInit {
  breadcrumbRoute = "/invitee/step1";
  breadcrumbText = "Back";
  forwardRoute = "";
  type = "";
  pageHeader = "";
  displayHint = "";
  displayNotes = "";
  invitationConfirmation: InvitationConfirmation;
  displayStudentNote = false;

  postcodeValid: boolean;
  postcodeErrorMessage = "";
  summaryErrorMessages = Array<ErrorSummaryMessage>();

  @ViewChild("postcodeInput") postcodeInput: TextInputComponent;

  constructor(
    private route: ActivatedRoute,
    private routerService: RouterService,
    private title: Title,
    private inviteeDetails: InviteeDetails,
  ) {}

  async ngOnInit(): Promise<void> {
    this.type = this.route.snapshot.paramMap.get("type");
    this.invitationConfirmation = this.inviteeDetails.getInviteeDetails();
    this.setHeaderAndContent();
  }

  setHeaderAndContent(): void {
    if (this.type == "tu") {
      this.pageHeader = "Enter your UK postcode";
      this.title.setTitle("Enter your UK postcode");
      this.displayStudentNote = true;
      this.forwardRoute = "/invitee/select-address/tu";
    } else {
      this.pageHeader = "Enter a delivery address";
      this.title.setTitle("Enter a delivery address");
      this.displayHint =
        "This address will be used to send a smartcard, fob or other ID device.";
      this.displayNotes = "This could be your home or work address.";
      this.breadcrumbRoute = "/invitee/profile-photo-review";
      this.forwardRoute = "/invitee/select-address/delivery";
    }
  }

  submitAnswer(): void {
    if (this.isAnswerValid()) {
      this.inviteeDetails.setInviteeDetails(this.invitationConfirmation);
      this.routerService.redirectTo(this.forwardRoute);
    }
  }

  isAnswerValid(): boolean {
    this.postcodeInput.setQuestionAnswer();
    if (this.postcodeValid) {
      return true;
    } else {
      return false;
    }
  }

  readonly errorMessages = {
    postcode: {
      empty: "Enter your postcode.",
      unknown: "Please check you've entered your postcode correctly.",
      invalid: "Enter a real postcode.",
      lengthTooLong: "The postcode you've entered is too long.",
      lengthTooShort: "The postcode you've entered is too short.",
      unexpectedCharacters:
        "Please check you've entered your postcode correctly.",
    },
  };

  updatePostcodeAnswer(postcode: string): void {
    postcode = postcode.trim().replace(/\s+/g, " ");
    const validationResult = isPostcodeValid(postcode);
    if (validationResult.validity) {
      this.postcodeErrorMessage = "";
      this.postcodeValid = true;
    } else {
      this.postcodeErrorMessage =
        this.errorMessages.postcode[validationResult.errorReason];
      this.postcodeValid = false;
    }
    this.updatePostcodeErrorMessage();

    if (this.type == "tu") {
      this.invitationConfirmation.tuPostcode = postcode;
    } else {
      this.invitationConfirmation.deliveryPostcode = postcode;
    }
  }

  updatePostcodeValid(postcodeValid: boolean): void {
    this.postcodeValid = postcodeValid;
    if (postcodeValid) {
      this.updatePostcodeErrorMessage();
    }
  }

  updatePostcodeErrorMessage(): void {
    const updatedErrorSummary = Array<ErrorSummaryMessage>();
    if (this.postcodeErrorMessage) {
      updatedErrorSummary.push({
        message: this.postcodeErrorMessage,
        id: "postcode-input",
      });
    }
    this.summaryErrorMessages = updatedErrorSummary;
  }
}
