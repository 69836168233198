import { Component, OnInit, ViewChild } from "@angular/core";
import { RouterService } from "src/app/services/router.service";
import { ServiceManagerService } from "src/app/services/service-manager.service";
import { Title } from "@angular/platform-browser";
import { TextInputComponent } from "../../common/form-fields/text-inputs/text-input.component";
import { ErrorSummaryMessage } from "../../../models/error-summary-message";
@Component({
  selector: "app-view-payload",
  templateUrl: "./view-payload.component.html",
  styleUrls: ["./view-payload.component.css"],
})
export class ViewPayloadComponent implements OnInit {
  breadcrumbRoute = "/service-management/portal";
  breadcrumbText = "Back";
  payload: any = null;
  payloadAsJson: any = null;
  documentImages: string;
  loading: boolean;
  invitationId: string = "";
  invitationIdValid: boolean;
  constructor(
    private serviceManagerService: ServiceManagerService,
    private routerService: RouterService,
    private title: Title,
  ) {}

  invitationIdErrorMessage = "";
  summaryErrorMessages = Array<ErrorSummaryMessage>();

  @ViewChild("invitationIdInput") invitationIdInput: TextInputComponent;

  ngOnInit(): void {
    this.title.setTitle("View payload");
  }

  async getPayload(): Promise<void> {
    try {
      let response = await this.serviceManagerService.viewPayload(
        this.invitationId,
      );
      this.payloadAsJson = JSON.parse(response["body"]);
      this.payload = JSON.stringify(this.payloadAsJson, null, 2);
      this.loading = false;
    } catch (error) {
      this.routerService.handleErrorAuthRoutes(error.status);
      if (error.status === 404) {
        this.invitationIdErrorMessage = "Invitation not found.";
        this.loading = false;
        this.updateInvitationIdErrorMessage();
      }
    }
  }

  async submitAnswer(): Promise<void> {
    this.loading = true;
    this.payload = "";
    this.invitationIdInput.setQuestionAnswer();
    if (this.invitationIdValid) {
      await this.getPayload();
    }
  }

  clear(): void {
    this.payload = "";
    this.invitationId = "";
    this.summaryErrorMessages = [];
    this.invitationIdErrorMessage = "";
  }

  updateInvitationIdAnswer(invitationId: string): void {
    invitationId = invitationId.trim();
    if (invitationId.length === 0) {
      this.invitationIdErrorMessage = "Please enter a invitation id.";
      this.invitationIdValid = false;
      this.loading = false;
    } else {
      this.invitationIdErrorMessage = "";
      this.invitationIdValid = true;
      this.invitationId = invitationId;
    }
    this.updateInvitationIdErrorMessage();
  }

  updateInvitationIdErrorMessage(): void {
    const updatedErrorSummary = Array<ErrorSummaryMessage>();
    if (this.invitationIdErrorMessage) {
      updatedErrorSummary.push({
        message: this.invitationIdErrorMessage,
        id: "invitation-id",
      });
    }
    this.summaryErrorMessages = updatedErrorSummary;
  }
}
