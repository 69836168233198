import { DpoaCheck } from "src/app/models/dpoaCheck";
import { IdCheckerMetrics } from "src/app/models/idCheckerMetrics";
import { IdCheckFailure } from "src/app/models/idCheckFailure";
import { IdCheckInternalNotes } from "src/app/models/idCheckInternalNotes";
import {
  InvitationStatus,
  InvitationDetails,
  IdCheckHeldReason,
  InvitationRaDetails,
  IdCheckIssues,
  IdCheckMatchResults,
  CancelledReason,
  InvitationSearchDetails,
} from "src/app/models/invitationDetails";

export interface SPAState {
  invitation: Invitation;
}
export class InvitationRa {
  invitationId: string;
  address: string;
  constructor(invite: InvitationRaDetails) {
    this.invitationId = invite.invitation_id;
    this.address = invite.address;
  }
}

export const initialState: SPAState = {
  invitation: {
    givenName: "",
    middleNames: "",
    familyName: "",
    dob: "",
    mobile: "",
    email: "",
    isPersonalEmail: false,
    createdByUserName: "",
    invitationId: "",
    status: InvitationStatus.New,
    createdDateUtc: "",
    senderEmail: "",
    createdByUserOrgCode: "",
    paa: "",
  },
};

export class Invitation {
  givenName: string;
  middleNames: string;
  familyName: string;
  inviterEnteredGivenName?: string;
  inviterEnteredFamilyName?: string;
  dob: string;
  email: string;
  mobile: string;
  isPersonalEmail: boolean;
  createdByUserName: string;
  invitationId?: string;
  status?: InvitationStatus;
  statusLastUpdatedDateUtc?: string;
  createdDateUtc?: string;
  senderEmail: string;
  paa?: string;
  cisUuid?: string;
  approvalNote?: string;
  resentCount?: number;
  idCheckedUtc?: string;
  failedReason?: IdCheckFailure[];
  failedNotes?: string;
  completedDateUtc?: string;
  selectedForResend?: boolean;
  selectedForMarkAsDone?: boolean;
  cancelledReason?: CancelledReason;
  cancelledReasonNotes?: string;
  cancelledDateUtc?: string;
  captureDeliveryAddress?: boolean;
  createdByUserOrgCode: string;
  address?: string;
  cisDuplicateMatch?: string;
  senderEmailBounced?: boolean;
  dateOfBirth?: string;
  niNumber?: string;
  xydusInvitationUrl?: string;
  photoIdOneType?: string;
  photoIdTwoType?: string;
  done?: boolean;

  constructor(invite: InvitationDetails) {
    this.givenName = invite.given_name;
    this.middleNames = invite.middle_names;
    this.familyName = invite.family_name;
    this.email = invite.email;
    this.mobile = invite.mobile;
    this.createdByUserOrgCode = invite.created_by_user_org_code;
    this.isPersonalEmail = invite.is_personal_email;
    this.createdByUserName = invite.created_by_user_name;
    this.invitationId = invite.invitation_id;
    this.status = invite.status;
    this.statusLastUpdatedDateUtc = invite.status_last_updated_utc;
    this.createdDateUtc = invite.created_date_utc;
    this.senderEmail = invite.sender_email;
    this.paa = invite.paa;
    this.cisUuid = invite.cis_uuid;
    this.approvalNote = invite.approval_note;
    this.resentCount = invite.resent_count;
    this.idCheckedUtc = invite.id_checked_utc;
    this.failedReason = invite.id_check_failure;
    this.failedNotes = invite.failed_notes;
    this.completedDateUtc = invite.completed_date_utc;
    this.selectedForResend = false;
    this.selectedForMarkAsDone = false;
    this.cancelledReason = invite.cancelled_reason;
    this.cancelledReasonNotes = invite.cancelled_reason_notes;
    this.cancelledDateUtc = invite.cancelled_date_utc;
    this.captureDeliveryAddress = invite.capture_delivery_address;
    this.address = invite.address;
    this.cisDuplicateMatch = invite.cis_duplicate_match;
    this.senderEmailBounced = invite.sender_email_bounced;
    this.dateOfBirth = invite.date_of_birth;
    this.niNumber = invite.ni_number;
    this.xydusInvitationUrl = invite.xydus_invitation_url;
    this.photoIdOneType = invite.photo_id_one_type;
    this.photoIdTwoType = invite.photo_id_two_type;
    this.done = invite.done;
  }
}

export class InvitationSearch {
  id: string;
  email: string;
  name: string;
  org: string;
  user: string;
  status?: InvitationStatus;
  date?: string;

  constructor(invite: InvitationSearchDetails) {
    this.id = invite.id;
    this.email = invite.email;
    this.name = invite.name;
    this.org = invite.org;
    this.user = invite.user;
    this.status = invite.status;
    this.date = invite.date;
  }
}

export class IdCheckerOverview {
  verificationQueue: number;
  verificationHeld: number;
  verificationPassed: number;
  verificationFailed: number;

  constructor(metrics: IdCheckerMetrics) {
    this.verificationQueue = metrics.verification_queue;
    this.verificationHeld = metrics.verification_held;
    this.verificationPassed = metrics.verification_passed;
    this.verificationFailed = metrics.verification_failed;
  }
}

function mapMatchResultsKey(key: string) {
  if (key === "echipFaceToLiveFace") {
    return "eChip vs face scan";
  }
  if (key === "documentFaceToLiveFace") {
    return "Photo ID vs face scan";
  }
  if (key === "echipFaceToDocumentFace") {
    return "eChip vs photo ID";
  } else {
    return key;
  }
}

export class InvitationIdCheck {
  isPersonalEmail: boolean;
  dateOfBirth?: string;
  niNumber?: string;
  email: string;
  familyName: string;
  givenName: string;
  invitationId?: string;
  middleNames: string;
  inviterEnteredGivenName?: string;
  inviterEnteredFamilyName?: string;
  preferredName?: string;
  mobile: string;
  createdByUserOrgCode: string;

  createdByUserName: string;
  createdDateUtc?: string;
  status?: InvitationStatus;
  statusLastUpdatedDateUtc?: string;

  failedNotes?: string;
  failedReason?: IdCheckFailure[];
  holdNotes?: string;
  holdReason?: IdCheckHeldReason[];
  idCheckedBy?: string;
  idCheckedByUserId?: string;
  idCheckedUtc?: string;
  senderEmail: string;

  photoIdOneNo?: string;
  photoIdOneIssue?: string;
  photoIdOneDateOfIssue?: string;
  photoIdOneDateOfExpiry?: string;
  photoIdTwoNo?: string;
  photoIdTwoDateOfIssue?: string;
  photoIdTwoDateOfExpiry?: string;
  photoIdTwoIssue?: string;
  photoIdOneType?: string;
  photoIdTwoType?: string;
  poaNo?: string;
  poaDateOfIssue?: string;
  poaIssue?: string;
  smartcardPhoto?: string;
  reviewByDisplay?: string;
  reviewBy?: string;
  reviewExpiryUtc?: string;
  submittedIdCheckUtc?: string;
  poaDocumentType?: string;
  cisUuid?: string;
  approvalNote?: string;
  completedDateUtc?: string;
  holdInternalNotes?: IdCheckInternalNotes[];
  dpoaCheck?: DpoaCheck;
  dpoaUsed?: boolean;
  tuAddress?: string;

  issues?: IdCheckIssues[];
  matchResults?: IdCheckMatchResults[];

  cis_duplicate_match?: string;
  cis_duplicate_matching_fields?: string;

  constructor(invite: InvitationDetails) {
    this.isPersonalEmail = invite.is_personal_email;
    this.dateOfBirth = invite.date_of_birth;
    this.niNumber = invite.ni_number;
    this.email = invite.email;
    this.familyName = invite.family_name;
    this.givenName = invite.given_name;
    this.invitationId = invite.invitation_id;
    this.middleNames = invite.middle_names;
    this.inviterEnteredGivenName = invite.inviter_entered_given_name;
    this.inviterEnteredFamilyName = invite.inviter_entered_family_name;
    this.preferredName = invite.preferred_name;
    this.mobile = invite.mobile;

    this.createdByUserName = invite.created_by_user_name;
    this.createdDateUtc = invite.created_date_utc;
    this.status = invite.status;
    this.statusLastUpdatedDateUtc = invite.status_last_updated_utc;

    this.failedNotes = invite.failed_notes;
    this.failedReason = invite.id_check_failure;
    this.holdReason = invite.hold_reason;
    this.idCheckedBy = invite.id_checked_by;
    this.idCheckedByUserId = invite.id_checked_by_user_id;
    this.idCheckedUtc = invite.id_checked_utc;
    this.senderEmail = invite.sender_email;
    this.smartcardPhoto = invite.smart_card;

    this.photoIdOneNo = invite.photo_id_one_no ?? "";
    this.photoIdOneIssue = invite.photo_id_one_issue;
    this.photoIdOneDateOfIssue = invite.photo_id_one_date_of_issue;
    this.photoIdOneDateOfExpiry = invite.photo_id_one_date_of_expiry;
    this.photoIdTwoNo = invite.photo_id_two_no ?? "";
    this.photoIdTwoDateOfIssue = invite.photo_id_two_date_of_issue;
    this.photoIdTwoDateOfExpiry = invite.photo_id_two_date_of_expiry;
    this.photoIdTwoIssue = invite.photo_id_two_issue;
    this.photoIdOneType = invite.photo_id_one_type;
    this.photoIdTwoType = invite.photo_id_two_type;
    this.poaNo = invite.poa_no ?? "";
    this.poaDateOfIssue = invite.poa_date_of_issue;
    this.poaIssue = invite.poa_issue;
    this.smartcardPhoto = invite.smart_card;
    this.createdByUserOrgCode = invite.created_by_user_org_code;

    this.reviewByDisplay = invite.review_by ?? "ZZ"; // for review list component sorting since null values wont sort!
    this.reviewBy = invite.review_by;
    this.reviewExpiryUtc = invite.review_expiry_utc;
    this.submittedIdCheckUtc = invite.submitted_id_check_utc;
    this.poaDocumentType = invite.poa_document_type;
    this.cisUuid = invite.cis_uuid;
    this.approvalNote = invite.approval_note;
    this.completedDateUtc = invite.completed_date_utc;
    this.issues = invite.issues;
    this.cis_duplicate_match = invite.cis_duplicate_match;
    this.cis_duplicate_matching_fields = invite.cis_duplicate_matching_fields;

    this.matchResults = invite.match_results
      ? invite.match_results.map(
          (x) =>
            ({
              key: mapMatchResultsKey(x.key),
              value: x.value,
            }) as IdCheckMatchResults,
        )
      : [];
    this.holdInternalNotes = invite.hold_internal_notes
      ? invite.hold_internal_notes.map((x) => new IdCheckInternalNotes(x))
      : [];

    this.dpoaUsed = invite.dpoa_used;
    this.dpoaCheck = invite.dpoa_check
      ? new DpoaCheck({
          match_level: invite.dpoa_check.match_level,
          identity_result: invite.dpoa_check.identity_result,
          identity_score: invite.dpoa_check.identity_score,
          ncoa_alert_flag: invite.dpoa_check.ncoa_alert_flag,
          num_primary_checks: invite.dpoa_check.num_primary_checks,
          confirmatory_dobs: invite.dpoa_check.confirmatory_dobs,
          warn_addresslinks: invite.dpoa_check.warn_addresslinks,
          warn_deceased: invite.dpoa_check.warn_deceased,
          warn_lor: invite.dpoa_check.warn_lor,
        })
      : null;

    this.tuAddress = invite.tu_address;
  }
}
