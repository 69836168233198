export interface InviteeSPAState {
  invitationConfirmation: InvitationConfirmation;
}

export const initialState: InviteeSPAState = {
  invitationConfirmation: {
    title: "",
    firstName: "",
    middleNames: "",
    familyName: "",
    preferredName: "",
    niNumber: "",
    otpNumber: "",
    dob: "",
    tuPostcode: "",
    tuAddressLineOne: "",
    tuBuildingName: "",
    tuTownCity: "",
    tuAddress: "",

    deliveryBuildingName: "",
    deliveryAddressLineOne: "",
    deliveryTownCity: "",
    deliveryPostcode: "",
    deliveryAddress: "",

    photoOneId: "",
    photoTwoId: "",
    poaType: "",
    profilePhotoBase64: "",
    profilePhotoType: "",

    dpoaRouteUsed: false,
  },
};

export interface InvitationConfirmation {
  title: string;
  firstName: string;
  middleNames: string;
  familyName: string;

  otpNumber: string;
  dob: string;

  niNumber: string;

  tuBuildingName: string;
  tuAddressLineOne: string;
  tuTownCity: string;
  tuPostcode: string;
  tuAddress: string;

  deliveryBuildingName: string;
  deliveryAddressLineOne: string;
  deliveryTownCity: string;
  deliveryPostcode: string;
  deliveryAddress: string;

  photoOneId: string;
  photoTwoId: string;
  poaType: string;
  preferredName: string;

  profilePhotoBase64: string;
  profilePhotoType: string;

  dpoaRouteUsed: boolean;
}
