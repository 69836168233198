<div class="nhsuk-grid-row">
  <div class="nhsuk-grid-column-two-thirds">
    <h1 autofocus tabindex="-1">Device not supported</h1>

    <p>
      The device you are using cannot support the mobile app and you cannot
      continue.
    </p>

    <p>
      If you are using a laptop, tablet or desktop device you will not be able
      to run the mobile app.
    </p>

    <p>Try using the link with a different device.</p>

    <p>
      If you cannot access another device, contact your manager. Include your
      application ID and a detailed description of the problem, this will help
      us to support you.
    </p>

    <feedback-link></feedback-link>
  </div>
</div>
