import { Component, Inject, Input } from "@angular/core";
import { SESSION_STORAGE, StorageService } from "ngx-webstorage-service";

@Component({
  selector: "cim-link",
  templateUrl: "./cim-link.component.html",
})
export class CimLinkComponent {
  cimEnv: string;
  @Input() uuid;

  constructor(@Inject(SESSION_STORAGE) private sessionStorage: StorageService) {
    this.cimEnv = this.sessionStorage.get("cimEnv");
  }
}
