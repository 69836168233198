<div class="nhsuk-alert nhsuk-alert--success nhsuk-alert--icon">
  <svg
    class="nhsuk-icon nhsuk-icon__tick nhsuk-alert__icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    aria-hidden="true"
  >
    <path
      stroke-width="4"
      stroke-linecap="round"
      d="M18.4 7.8l-8.5 8.4L5.6 12"
    ></path>
  </svg>
  <h2 class="nhsuk-u-visually-hidden">Success:</h2>
  <p class="nhsuk-u-font-weight-bold" innerHTML="{{ displayText }}"></p>
</div>
