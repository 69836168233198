import { ValidationResponse } from "./../../models/validation-response";
import { Injectable } from "@angular/core";
import { DatePipe } from "@angular/common";
import { isFuture, isValid, parse } from "date-fns";
import { DOBValidationResult } from "../../models/dob-validation-result";

@Injectable()
export class DateService {
  static readonly errorMessages = {
    AllEmpty: "Enter your date of birth.",
    OnlyDayComplete: "Date of birth must include a month and year.",
    OnlyMonthComplete: "Date of birth must include a day and year.",
    OnlyYearComplete: "Date of birth must include a day and month.",
    OnlyDayEmpty: "Date of birth must include a day.",
    OnlyMonthEmpty: "Date of birth must include a month.",
    OnlyYearEmpty: "Date of birth must include a year.",
    InvalidDate: "Date of birth must be a real date.",
    FutureDate: "Date of birth must be in the past.",
    YearNotFourDigits: "Birth year must include 4 numbers.",
    Age100OrMore: "Date of birth must be less than 100 years old.",
    AgeLessThan14: "Date of birth must be over 14 years old.",
  };

  constructor(private datePipe: DatePipe) {}

  static getDOBValidationResult(
    day: string = "",
    month: string = "",
    year: string = "",
  ): ValidationResponse {
    const validationResponse = new ValidationResponse();

    if (this.isDOBEmpty(day, month, year)) {
      validationResponse.result = DOBValidationResult.ALL_EMPTY;
      validationResponse.errorSummary = validationResponse.fieldError =
        this.errorMessages.AllEmpty;
    } else if (this.isOnlyDayComplete(day, month, year)) {
      validationResponse.result = DOBValidationResult.ONLY_DAY_COMPLETE;
      validationResponse.errorSummary = validationResponse.fieldError =
        this.errorMessages.OnlyDayComplete;
    } else if (this.isOnlyMonthComplete(day, month, year)) {
      validationResponse.result = DOBValidationResult.ONLY_MONTH_COMPLETE;
      validationResponse.errorSummary = validationResponse.fieldError =
        this.errorMessages.OnlyMonthComplete;
    } else if (this.isOnlyYearComplete(day, month, year)) {
      validationResponse.result = DOBValidationResult.ONLY_YEAR_COMPLETE;
      validationResponse.errorSummary = validationResponse.fieldError =
        this.errorMessages.OnlyYearComplete;
    } else if (this.isOnlyDayEmpty(day, month, year)) {
      validationResponse.result = DOBValidationResult.ONLY_DAY_EMPTY;
      validationResponse.errorSummary = validationResponse.fieldError =
        this.errorMessages.OnlyDayEmpty;
    } else if (this.isOnlyMonthEmpty(day, month, year)) {
      validationResponse.result = DOBValidationResult.ONLY_MONTH_EMPTY;
      validationResponse.errorSummary = validationResponse.fieldError =
        this.errorMessages.OnlyMonthEmpty;
    } else if (this.isOnlyYearEmpty(day, month, year)) {
      validationResponse.result = DOBValidationResult.ONLY_YEAR_EMPTY;
      validationResponse.errorSummary = validationResponse.fieldError =
        this.errorMessages.OnlyYearEmpty;
    } else if (this.isInvalidDate(day, month, year)) {
      validationResponse.result = DOBValidationResult.INVALID_DATE;
      validationResponse.errorSummary = validationResponse.fieldError =
        this.errorMessages.InvalidDate;
    } else if (this.isFutureDate(day, month, year)) {
      validationResponse.result = DOBValidationResult.FUTURE_DATE;
      validationResponse.errorSummary = validationResponse.fieldError =
        this.errorMessages.FutureDate;
    } else if (this.isYearNotFourDigits(year)) {
      validationResponse.result = DOBValidationResult.YEAR_NOT_FOUR_DIGITS;
      validationResponse.errorSummary = validationResponse.fieldError =
        this.errorMessages.YearNotFourDigits;
    } else if (this.isAge100OrMore(day, month, year)) {
      validationResponse.result = DOBValidationResult.AGE_100_OR_MORE;
      validationResponse.errorSummary = validationResponse.fieldError =
        this.errorMessages.Age100OrMore;
    } else if (this.isAgeLessThan14(day, month, year)) {
      validationResponse.result = DOBValidationResult.AGE_LESS_THAN_14;
      validationResponse.errorSummary = validationResponse.fieldError =
        this.errorMessages.AgeLessThan14;
    } else {
      validationResponse.result = DOBValidationResult.SUCCESS;
      validationResponse.sanitisedInput = this.formatDOB(day, month, year);
    }

    return validationResponse;
  }

  static isDOBEmpty(
    day: string = "",
    month: string = "",
    year: string = "",
  ): boolean {
    return !day && !month && !year;
  }

  static isOnlyDayComplete(
    day: string = "",
    month: string = "",
    year: string = "",
  ): boolean {
    return day !== null && day.length > 0 && !month && !year;
  }

  static isOnlyMonthComplete(
    day: string = "",
    month: string = "",
    year: string = "",
  ): boolean {
    return month !== null && month.length > 0 && !day && !year;
  }

  static isOnlyYearComplete(
    day: string = "",
    month: string = "",
    year: string = "",
  ): boolean {
    return year !== null && year.length > 0 && !day && !month;
  }

  static isOnlyDayEmpty(
    day: string = "",
    month: string = "",
    year: string = "",
  ): boolean {
    return (
      month !== null &&
      year !== null &&
      month.length > 0 &&
      year.length > 0 &&
      !day
    );
  }

  static isOnlyMonthEmpty(
    day: string = "",
    month: string = "",
    year: string = "",
  ): boolean {
    return (
      day !== null &&
      year !== null &&
      day.length > 0 &&
      year.length > 0 &&
      !month
    );
  }

  static isOnlyYearEmpty(
    day: string = "",
    month: string = "",
    year: string = "",
  ): boolean {
    return (
      day !== null &&
      month !== null &&
      day.length > 0 &&
      month.length > 0 &&
      !year
    );
  }

  static isInvalidDate(day: string, month: string, year: string): boolean {
    const isValidDate = isValid(
      parse(`${day}-${month}-${year}`, "d-M-yyyy", new Date()),
    );
    return !isValidDate;
  }

  static isFutureDate(day: string, month: string, year: string): boolean {
    const isFutureDate = isFuture(
      parse(`${day}-${month}-${year}`, "d-M-yyyy", new Date()),
    );
    return isFutureDate;
  }

  static isYearNotFourDigits(year: string): boolean {
    const fourDigitRegex = /^\d{4}$/;
    return !fourDigitRegex.test(year);
  }

  static isAge100OrMore(day: string, month: string, year: string): boolean {
    // Using 365.25 days (0.25 because of leap years) which are 3.15576e+10 milliseconds (365.25 * 24 * 60 * 60 * 1000)
    return (
      Math.floor(
        (new Date().valueOf() -
          new Date(year + "-" + month + "-" + day).getTime().valueOf()) /
          3.15576e10,
      ) >= 100
    );
  }

  static isAgeLessThan14(day: string, month: string, year: string): boolean {
    // Using 365.25 days (0.25 because of leap years) which are 3.15576e+10 milliseconds (365.25 * 24 * 60 * 60 * 1000)
    return (
      Math.floor(
        (new Date().valueOf() -
          new Date(year + "-" + month + "-" + day).getTime().valueOf()) /
          3.15576e10,
      ) < 14
    );
  }

  // transforms date into standard shortDate yyyy-MM-dd format e.g. 1972-04-01
  static formatDOB(day: string, mo: string, yr: string): string {
    const tempDate = `${yr}-${mo}-${day}`;
    const datePipe = new DatePipe("en-US");
    return datePipe.transform(tempDate, "yyyy-MM-dd");
  }

  static formatDOBToHumanReadable(dob: string): string {
    const datePipe = new DatePipe("en-US");
    return datePipe.transform(dob, "dd MMM yyyy");
  }
}
