import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "photoIdDocTypePipe" })
export class PhotoIdDocTypePipe implements PipeTransform {
  private classMapping = {
    Passport: "passport",
    DrivingLicence: "driving license",
    ResidencePermit: "residence permit",
    ArmedForces: "HM armed forces card",
    PassProofOfAgeCard: "PASS proof of age card",
    Unclassified: "unclassified",
  };

  transform(value: string): string {
    return this.classMapping[value];
  }
}
