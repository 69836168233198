<breadcrumb-link
  [breadcrumbRoute]="breadcrumbRoute"
  [breadcrumbText]="breadcrumbText"
></breadcrumb-link>

<h1 autofocus tabindex="-1" class="nhsuk-heading-l">Help</h1>

<details class="nhsuk-details">
  <summary class="nhsuk-details__summary">
    <span class="nhsuk-details__summary-text">
      I don't have the right documents
    </span>
  </summary>
  <div class="nhsuk-details__text">
    <p class="nhsuk-body">
      Contact your employer and ask for a face-to-face appointment with your
      Registration Authority.
    </p>
  </div>
</details>

<details class="nhsuk-details">
  <summary class="nhsuk-details__summary">
    <span class="nhsuk-details__summary-text">
      I don't have a smartphone, tablet or desktop computer
    </span>
  </summary>
  <div class="nhsuk-details__text">
    <p class="nhsuk-body">
      Contact your employer and ask for a face-to-face appointment with your
      Registration Authority.
    </p>
  </div>
</details>

<details class="nhsuk-details">
  <summary class="nhsuk-details__summary">
    <span class="nhsuk-details__summary-text">
      Flashing lights make me feel ill
    </span>
  </summary>
  <div class="nhsuk-details__text">
    <p class="nhsuk-body">
      Contact your employer and ask for a face-to-face appointment with your
      Registration Authority.
    </p>
  </div>
</details>

<details class="nhsuk-details">
  <summary class="nhsuk-details__summary">
    <span class="nhsuk-details__summary-text">
      The name on my documents does not match the name on the email invite.
    </span>
  </summary>
  <div class="nhsuk-details__text">
    <p class="nhsuk-body">
      Contact your employer and ask for a face-to-face appointment with your
      Registration Authority.
    </p>
  </div>
</details>
