import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { RouterService } from "src/app/services/router.service";
import { SESSION_STORAGE, StorageService } from "ngx-webstorage-service";
import { interval, Subscription } from "rxjs";
import { SessionAuthenticationService } from "../../../services/session-authentication.service";

@Component({
  selector: "session-check",
  templateUrl: "./session-check.component.html",
})
export class SessionCheckComponent implements OnInit, OnDestroy {
  sessionCheckFrequency = interval(20000);
  warningDisplayFromSeconds = 60;
  subscription: Subscription;
  warningDisplayed = false;
  countdown: string;
  secondsTillExpiry: number;
  timerId;
  callCount = 0;

  constructor(
    @Inject(SESSION_STORAGE) private sessionStorage: StorageService,
    private routerService: RouterService,
    private sessionAuthenticationService: SessionAuthenticationService,
  ) {}

  ngOnInit(): void {
    this.subscription = this.sessionCheckFrequency.subscribe(() => {
      this.sessionCheck();
      this.checkExistingCounters();
    });
  }

  async sessionCheck() {
    try {
      let result = await this.sessionAuthenticationService.sessionCheck();
      this.persistSessionToken(result);
      this.secondsTillExpiry = this.getSecondsTillExpiry(
        result.sessionToken.expiry_date_utc,
      );
      if (this.secondsTillExpiry < this.warningDisplayFromSeconds) {
        this.displayExpiryWarning(this.secondsTillExpiry);
      }
    } catch (error) {
      this.clearWarningDisplay();
      if (error.status === 401 || error.status === 500) {
        this.redirectToLoggedOut();
      }
    }
  }

  async sessionExtend() {
    try {
      let result = await this.sessionAuthenticationService.sessionExtend();
      this.persistSessionToken(result);
    } catch (error) {
      if (error.status === 401 || error.status === 500) {
        this.redirectToLoggedOut();
      }
    }
  }

  clearWarningDisplay() {
    this.warningDisplayed = false;
    this.countdown = "";
    clearInterval(this.timerId);
  }

  checkExistingCounters() {
    if (this.secondsTillExpiry > this.warningDisplayFromSeconds) {
      this.clearWarningDisplay();
    }
  }

  getSecondsTillExpiry(expiry_date_utc: string): number {
    let now = Date.now();
    let expiry = Date.parse(expiry_date_utc) - 30000; // Remove 30 seconds to provide a safe window to extend
    let diffSeconds = Math.floor((expiry - now) / 1000);

    return diffSeconds;
  }

  displayExpiryWarning(seconds: number): void {
    if (!this.warningDisplayed) {
      this.warningDisplayed = true;

      this.timerId = setInterval(() => {
        --seconds;
        this.countdown = seconds.toString();
        if (seconds == 0) {
          this.clearWarningDisplay();
          this.redirectToLoggedOut();
        }
      }, 1000);
    }
  }

  async onStayLoggedInClicked() {
    this.clearWarningDisplay();
    await this.sessionExtend();
  }

  async onLogOutClicked() {
    this.clearWarningDisplay();
    try {
      await this.sessionAuthenticationService.sessionEnd();
      this.redirectToUserLoggedOut();
    } catch {
      this.redirectToLoggedOut();
    }
  }

  redirectToLoggedOut(): void {
    window.sessionStorage.clear();
    this.routerService.redirectToLoggedOut();
  }

  redirectToUserLoggedOut(): void {
    window.sessionStorage.clear();
    this.routerService.redirectToUserLoggedOut();
  }

  persistSessionToken(result): void {
    this.sessionStorage.set("sessionToken", result.sessionToken);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
