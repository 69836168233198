import { Component, Inject, ViewChild, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { RouterService } from "src/app/services/router.service";
import { InvitationConfirmation } from "src/store/states/invitee-spa.state";
import { CheckboxComponent } from "../../common/form-fields/checkbox/checkbox.component";
import { scrollToTop } from "src/app/utils/common/common";
import { SESSION_STORAGE, StorageService } from "ngx-webstorage-service";
import { InviteeDetails } from "src/app/utils/invitee-details";

@Component({
  selector: "app-photo-id-two",
  templateUrl: "./photo-id-two.component.html",
})
export class PhotoIdTwoComponent implements OnInit {
  breadcrumbRoute = "/invitee/poa";
  breadcrumbText = "Back";
  invitationConfirmation: InvitationConfirmation;
  selectedCheckboxes = [];
  validationErrorMessage = "";

  @ViewChild("passport") passport: CheckboxComponent;
  @ViewChild("drivingLicence") drivingLicence: CheckboxComponent;
  @ViewChild("residencePermit") residencePermit: CheckboxComponent;
  @ViewChild("armedForces") armedForces: CheckboxComponent;
  @ViewChild("passProofOfAgeCard") passProofOfAgeCard: CheckboxComponent;
  @ViewChild("noDocs") noDocs: CheckboxComponent;

  constructor(
    private title: Title,
    private routerService: RouterService,
    private inviteeDetails: InviteeDetails,
    @Inject(SESSION_STORAGE) private sessionStorage: StorageService,
  ) {}
  photoIdOne: string = undefined;

  summaryErrorMessages = [];

  async ngOnInit(): Promise<void> {
    this.title.setTitle(
      "Which 2 photo IDs do you want to use to verify your identity?",
    );
    this.invitationConfirmation = this.inviteeDetails.getInviteeDetails();
  }

  setAnswers(): void {
    this.selectedCheckboxes = [];

    if (this.passport.isChecked) {
      this.selectedCheckboxes.push("Passport");
    }
    if (
      this.invitationConfirmation.poaType != "DrivingLicence" &&
      this.drivingLicence.isChecked
    ) {
      this.selectedCheckboxes.push("DrivingLicence");
    }
    if (this.residencePermit.isChecked) {
      this.selectedCheckboxes.push("ResidencePermit");
    }
    if (this.armedForces.isChecked) {
      this.selectedCheckboxes.push("ArmedForces");
    }
    if (this.passProofOfAgeCard.isChecked) {
      this.selectedCheckboxes.push("PassProofOfAgeCard");
    }
    if (this.noDocs.isChecked) {
      this.selectedCheckboxes.push("NoDocs");
    }
  }

  areAnswersValid(): boolean {
    const noDocs = this.selectedCheckboxes.includes("NoDocs");

    if (noDocs) {
      return true;
    }

    if (this.selectedCheckboxes.length == 2 && !noDocs) {
      return true;
    }

    if (this.selectedCheckboxes.length < 2) {
      this.validationErrorMessage = "Select 2 photo IDs";
    } else if (this.selectedCheckboxes.length > 2) {
      this.validationErrorMessage = "You must select 2 photo IDs";
    }

    return false;
  }

  onContinueButtonClicked(): void {
    this.setAnswers();

    if (!this.areAnswersValid()) {
      scrollToTop();
      return;
    }

    if (this.selectedCheckboxes.includes("NoDocs")) {
      this.routerService.redirectToNoPhotoId();
    } else {
      this.invitationConfirmation.photoOneId = this.selectedCheckboxes[0];
      this.invitationConfirmation.photoTwoId = this.selectedCheckboxes[1];
      this.inviteeDetails.setInviteeDetails(this.invitationConfirmation);
      this.routerService.redirectToProfilePhotoUpload();
    }
  }
}
