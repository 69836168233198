import { Injectable } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { Subscription, filter } from "rxjs";

declare let gtag: Function;

@Injectable()
export class AfCIDCookieService {
  GA_COOKIE_NAME = "_ga";
  GA_ACCOUNT = "G-74W66ZH6X2";
  ACFID_COOKIE_NAME = "nhsuk-cookie-consent";
  AFCID_COOKIE_EXPIRY = 365;
  AFCID_COOKIE_PATH = "/";

  HEAD_GA_SCRIPT_ID = "ga-script";
  HEAD_GA_SCRIPT_SPECIFICS_ID = "ga-script-specifics";
  subscription: Subscription;

  constructor(
    private router: Router,
    private cookieService: CookieService,
  ) {}

  getCookieConsent() {
    let cookie = undefined;
    const cookieExists = this.cookieService.check(this.ACFID_COOKIE_NAME);
    if (cookieExists) {
      cookie = this.cookieService.get(this.ACFID_COOKIE_NAME);
    }
    return cookie;
  }

  setCookieConsent(consent: boolean): void {
    this.cookieService.set(
      this.ACFID_COOKIE_NAME,
      "consented=" + String(consent),
      {
        expires: this.AFCID_COOKIE_EXPIRY,
        path: this.AFCID_COOKIE_PATH,
      },
    );
  }

  isLocalhost(): boolean {
    return location.hostname == "localhost";
  }

  setUpAnalytics() {
    if (this.isLocalhost()) {
      console.log("No analytics on local");
      return;
    }

    let gaScript = document.createElement("script");
    gaScript.id = this.HEAD_GA_SCRIPT_ID;
    gaScript.async = true;
    gaScript.src =
      "https://www.googletagmanager.com/gtag/js?id=" + this.GA_ACCOUNT;
    document.head.appendChild(gaScript);

    let gaScriptSpecifics = document.createElement("script");
    gaScriptSpecifics.id = this.HEAD_GA_SCRIPT_SPECIFICS_ID;
    gaScriptSpecifics.text = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
      `;
    document.head.appendChild(gaScriptSpecifics);

    this.subscription = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        gtag("config", this.GA_ACCOUNT, {
          page_path: event.urlAfterRedirects,
        });
      });
  }

  hasGivenConsent(cookie: any): boolean {
    if (cookie != undefined) {
      const consent = cookie.split("consented=")[1];
      if (consent == "true") {
        return true;
      }
    }
    return false;
  }

  removeAnalytics() {
    // Remove scripts from head
    const elementScript = document.getElementById(this.HEAD_GA_SCRIPT_ID);
    const elementScriptSpecifics = document.getElementById(
      this.HEAD_GA_SCRIPT_SPECIFICS_ID,
    );
    if (elementScript != null) {
      elementScript.remove();
    }
    if (elementScriptSpecifics != null) {
      elementScriptSpecifics.remove();
    }

    const scriptSpecificsCookie =
      this.GA_COOKIE_NAME + "_" + this.GA_ACCOUNT.split("-")[1];

    this.expireCookie(this.GA_COOKIE_NAME);
    this.expireCookie(scriptSpecificsCookie);
    this.clearSubscription();
  }

  clearSubscription(): void {
    if (this.subscription != undefined) {
      this.subscription.unsubscribe();
    }
  }

  getSecondLevelDomainWithTld(): string {
    const parts = location.hostname.split(".");
    let secondLevelDomainWithTld = parts.slice(-3).join(".");
    if (secondLevelDomainWithTld != "localhost") {
      secondLevelDomainWithTld = "." + secondLevelDomainWithTld;
    }

    return secondLevelDomainWithTld;
  }

  expireCookie(name: string) {
    const domain = this.getSecondLevelDomainWithTld();
    document.cookie =
      name +
      "=; Path=/; Domain=" +
      domain +
      "; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  }
}
