import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { AfCIDCookieService } from "src/app/services/acfid-cookie.service";

@Component({
  selector: "app-cookies-preference",
  templateUrl: "./cookies-preference.component.html",
})
export class CookiesPreferenceComponent implements OnInit {
  cookiesConsentGiven: boolean = false;

  constructor(
    private title: Title,
    private afcidCookieService: AfCIDCookieService,
  ) {}

  ngOnInit(): void {
    this.title.setTitle("Cookie policy");
    this.getCookieSetting();
  }

  getCookieSetting(): void {
    const cookie = this.afcidCookieService.getCookieConsent();

    if (cookie != undefined) {
      // get whether consented or not.
      const consentGiven = cookie.split("consented=")[1];
      if (consentGiven == "true") {
        this.cookiesConsentGiven = true;
      }
    }
  }

  updateConsentRadioValue(selected: boolean): void {
    this.cookiesConsentGiven = selected;
  }

  saveCookieSettingsClick(): void {
    const consentGiven = this.cookiesConsentGiven;
    this.afcidCookieService.setCookieConsent(consentGiven);

    if (!consentGiven) {
      // remove GA cookie
      this.afcidCookieService.removeAnalytics();
    } else {
      // set GA cookie
      this.afcidCookieService.setUpAnalytics();
    }

    this.redirectToCookieSaved();
  }

  redirectToCookieSaved(): void {
    window.location.href = "/cookies-saved";
  }
}
