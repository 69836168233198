/* istanbul ignore file */
import {
  initialState,
  IdCheckerRejectionSPAState,
} from "../states/id-checker-spa.state";
import * as fromFeature from "../reducers";
import { createSelector } from "@ngrx/store";

export const getIdCheckerRejectionSPAState = createSelector(
  fromFeature.getSPAState,
  (state: fromFeature.SpaState) => state.idCheckerSpa,
);

export const getIdCheckerRejection = createSelector(
  getIdCheckerRejectionSPAState,
  (state: IdCheckerRejectionSPAState) =>
    state ? state.rejection : initialState.rejection,
);
