import { IdCheckInternalNotesDetails } from "./idCheckInternalNotesDetails";

export class IdCheckInternalNotes {
  noteId: string;
  note: string;
  noteCreatedByUserName: string;
  noteLastUpdatedByUserName: string;
  noteCreatedUtc: string;
  noteUpdatedUtc: string;
  noteVisible: boolean;
  constructor(internalNotes: IdCheckInternalNotesDetails) {
    this.noteId = internalNotes.note_id;
    this.note = internalNotes.note;
    this.noteCreatedByUserName = internalNotes.note_created_by_user_name;
    this.noteLastUpdatedByUserName =
      internalNotes.note_last_updated_by_user_name;
    this.noteUpdatedUtc = internalNotes.note_updated_utc;
    this.noteCreatedUtc = internalNotes.note_created_utc;
    this.noteVisible = internalNotes.note_visible;
  }
}
