import { HttpClient, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import {
  FinalInvitationDuplicateCheckDetails,
  InvitationDuplicateCheckDetails,
} from "../models/invitation-duplicate-check-details";
import { InvitationDuplicateCheckResponse } from "../models/invitation-duplicate-check-response";
import { EnvironmentConfigService } from "./environment-config.service";
import { SessionAuthenticationService } from "./session-authentication.service";
import { Portal } from "../models/session-token";

@Injectable()
export class DuplicateCheckService {
  constructor(
    private http: HttpClient,
    private environmentConfigService: EnvironmentConfigService,
    private sessionAuthenticationService: SessionAuthenticationService,
  ) {}

  postDuplicateCheck(
    invitationId: string,
    email: string,
    title: string,
    givenName: string,
    middleNames: string,
    familyName: string,
    dateOfBirth: string,
    niNumber: string,
  ): Observable<HttpResponse<InvitationDuplicateCheckResponse>> {
    const checkDetails = this.mapDuplicateCheckDetails(
      invitationId,
      email,
      title,
      givenName,
      middleNames,
      familyName,
      dateOfBirth,
      niNumber,
    );
    return this.http.post<HttpResponse<InvitationDuplicateCheckResponse>>(
      `${
        this.environmentConfigService.getConfig().idBridgeApiUrl
      }/invitation/duplicate_check`,
      checkDetails,
      {
        observe: "body",
        responseType: "json",
        headers: this.sessionAuthenticationService.applyCsrfHeader(),
      },
    );
  }

  private mapDuplicateCheckDetails(
    invitationId: string,
    email: string,
    title: string,
    givenName: string,
    middleNames: string,
    familyName: string,
    dateOfBirth: string,
    niNumber: string,
  ): InvitationDuplicateCheckDetails {
    return {
      invitation_id: invitationId,
      email,
      title: title,
      given_name: givenName,
      middle_names: middleNames,
      family_name: familyName,
      date_of_birth: dateOfBirth,
      ni_number: niNumber,
    };
  }

  postFinalDuplicateCheck(
    invitationId: string,
    photoIdOneNo: string,
    photoIdOneIssue: string,
    photoIdOneType: string,
    photoIdTwoNo: string,
    photoIdTwoIssue: string,
    photoIdTwoType: string,
  ): Observable<string> {
    const checkDetails = this.mapFinalDuplicateCheckDetails(
      invitationId,
      photoIdOneNo,
      photoIdOneIssue,
      photoIdOneType,
      photoIdTwoNo,
      photoIdTwoIssue,
      photoIdTwoType,
    );
    return this.http.post<string>(
      `${
        this.environmentConfigService.getConfig().idBridgeApiUrl
      }/invitation/final_duplicate_check`,
      checkDetails,
      {
        headers: this.sessionAuthenticationService.applyAuthorizationHeader(
          Portal.Checker,
        ),
      },
    );
  }

  private mapFinalDuplicateCheckDetails(
    invitationId: string,
    photoIdOneNo: string,
    photoIdOneIssue: string,
    photoIdOneType: string,
    photoIdTwoNo: string,
    photoIdTwoIssue: string,
    photoIdTwoType: string,
  ): FinalInvitationDuplicateCheckDetails {
    return {
      invitation_id: invitationId,
      photo_id_one_no: photoIdOneNo,
      photo_id_one_issue: photoIdOneIssue,
      photo_id_one_type: photoIdOneType,
      photo_id_two_no: photoIdTwoNo,
      photo_id_two_issue: photoIdTwoIssue,
      photo_id_two_type: photoIdTwoType,
    };
  }
}
