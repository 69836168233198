import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "invitationStatusClassPipe" })
export class InvitationStatusClassPipe implements PipeTransform {
  private classMapping = {
    2: "nhsuk-tag--white",
    52: "nhsuk-tag--green",
    3: "nhsuk-tag--red",
    53: "nhsuk-tag--red",
    29: "nhsuk-tag--yellow",
    31: "nhsuk-tag--yellow",
    32: "nhsuk-tag--white",
    33: "nhsuk-tag--yellow",
    34: "nhsuk-tag--yellow",
    37: "nhsuk-tag--red",
    38: "nhsuk-tag--purple",
    50: "nhsuk-tag--white",
    51: "nhsuk-tag--white",
    1: "nhsuk-tag--white",
    35: "nhsuk-tag--white",
    4: "nhsuk-tag--white",
    5: "nhsuk-tag--green",
  };

  transform(value: number): string {
    return this.classMapping[value];
  }
}

@Pipe({ name: "invitationStatusRawClassPipe" })
export class InvitationStatusRawClassPipe implements PipeTransform {
  private classMapping = {
    2: "nhsuk-tag--white",
    3: "nhsuk-tag--red",
    29: "nhsuk-tag--yellow",
    31: "nhsuk-tag--yellow",
    32: "nhsuk-tag--white",
    33: "nhsuk-tag--yellow",
    34: "nhsuk-tag--red",
    37: "nhsuk-tag--red",
    38: "nhsuk-tag--purple",
    1: "nhsuk-tag--white",
    35: "nhsuk-tag--white",
    4: "nhsuk-tag--white",
    5: "nhsuk-tag--green",

    50: "nhsuk-tag--white",
    51: "nhsuk-tag--blue",
    52: "nhsuk-tag--green",
    53: "nhsuk-tag--red",
  };

  transform(value: number): string {
    return this.classMapping[value];
  }
}
