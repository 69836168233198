<breadcrumb-link
  [breadcrumbRoute]="breadcrumbRoute"
  [breadcrumbText]="breadcrumbText"
></breadcrumb-link>

<div class="nhsuk-grid-row">
  <div *ngIf="invitationConfirmation" class="nhsuk-grid-column-two-thirds">
    <h1 autofocus tabindex="-1" class="nhsuk-heading-xl">
      Enter your date of birth
    </h1>
    <error-summary
      [errorSummaryMessages]="summaryErrorMessages"
    ></error-summary>
    <div class="nhsuk-form-group">
      <date-of-birth
        aria-label="enter date of birth"
        #dateOfBirthInput
        [id]="'date-of-birth'"
        [dateOfBirthInput]="invitationConfirmation.dob"
        (dateOfBirthOutput)="updateDOBAnswer($event)"
        (dateOfBirthValidOutput)="updateDOBValid($event)"
        (dateOfBirthErrorMessageOutput)="updateDOBErrorMessage($event)"
      ></date-of-birth>
    </div>
    <div class="nhsuk-u-padding-bottom-3"></div>
    <button
      class="nhsuk-button nhsuk-u-margin-bottom-5"
      (click)="submitAnswer()"
      id="continue"
      data-cy="continue-button"
    >
      Continue
    </button>
  </div>
</div>
