<div class="nhsuk-grid-row" *ngIf="displayServiceBanner">
  <div class="nhsuk-grid-column-two-thirds">
    <div class="nhsuk-warning-callout">
      <h3 class="nhsuk-warning-callout__label">
        Service unavailable<span class="nhsuk-u-visually-hidden">:</span>
      </h3>
      <p>
        The Apply for Care Identity Service is currently offline. Unfortunately
        we do not have timescales for full restoration of the service.
      </p>
      <p>
        Please contact your RA to discuss an alternative registration method
        with them.
      </p>
      <p>Please accept our apologies for any inconvenience this may cause.</p>
    </div>
  </div>
</div>

<div *ngIf="!identityLoginStatus">
  <div class="nhsuk-grid-row">
    <div class="nhsuk-grid-column-two-thirds">
      <h1 autofocus tabindex="-1">Log in</h1>
      <div *ngIf="successfulLogout">
        <div
          class="nhsuk-card nhsuk-card--confirmation nhsuk-u-margin-top-0 nhsuk-u-margin-bottom-4"
        >
          <div class="nhsuk-card__content">
            <h2 class="nhsuk-card__title nhsuk-u-margin-bottom-4">
              You have been logged out
            </h2>
          </div>
        </div>
      </div>

      <p>
        Use Windows Hello, your Security Key or Smartcard with the right
        permissions or roles to log in and use the Apply for Care ID service.
      </p>

      <p>
        If you cannot change your roles or permissions, contact your manager
        or<a
          href="https://digital.nhs.uk/services/registration-authorities-and-smartcards/primary-care-service-provider-contact-details"
        >
          NHS Trust's registration authority</a
        >.
      </p>
      <button
        id="login-button"
        type="button"
        [ngClass]="{ 'spinner-button': identityLoginButtonClicked }"
        [disabled]="identityLoginButtonClicked"
        (click)="onIdentityLoginButtonClick()"
        class="nhsuk-button nhsuk-u-margin-bottom-7"
      >
        Log in
      </button>
    </div>
  </div>
</div>

<div *ngIf="identityLoginStatus == 'NoAuth'">
  <div class="nhsuk-grid-row">
    <div class="nhsuk-grid-column-two-thirds">
      <h1 autofocus tabindex="-1">Access denied</h1>

      <p>You do not have permission to view this page.</p>
      <button
        id="login-button"
        type="button"
        [ngClass]="{ 'spinner-button': identityLoginButtonClicked }"
        [disabled]="identityLoginButtonClicked"
        (click)="onIdentityLoginButtonClick()"
        class="nhsuk-button nhsuk-u-margin-bottom-7"
        tabindex="3"
      >
        Log in
      </button>
      <p>
        If you do not have the correct permissions to view this page, contact
        your manager or NHS Trust's registration to gain access.
      </p>
    </div>
  </div>
</div>

<div *ngIf="identityLoginStatus == 'LoggedOut'">
  <div class="nhsuk-grid-row">
    <div class="nhsuk-grid-column-two-thirds">
      <h1 autofocus tabindex="-1">You have been logged out</h1>

      <p>To keep the system and data secure, you have been logged out.</p>
      <p>This happens if you are inactive for 15 minutes.</p>
      <button
        id="login-button"
        type="button"
        [ngClass]="{ 'spinner-button': identityLoginButtonClicked }"
        [disabled]="identityLoginButtonClicked"
        (click)="onIdentityLoginButtonClick()"
        class="nhsuk-button nhsuk-u-margin-bottom-7"
        tabindex="3"
      >
        Log in
      </button>
    </div>
  </div>
</div>
<div *ngIf="identityLoginStatus == 'Unknown'">
  <div class="nhsuk-grid-row">
    <div class="nhsuk-grid-column-two-thirds">
      <h1 autofocus tabindex="-1">
        Sorry, there is a problem with the service
      </h1>

      <p>Please try again.</p>

      <button
        id="login-button"
        type="button"
        [ngClass]="{ 'spinner-button': identityLoginButtonClicked }"
        [disabled]="identityLoginButtonClicked"
        (click)="onIdentityLoginButtonClick()"
        class="nhsuk-button nhsuk-u-margin-bottom-7"
        tabindex="3"
      >
        Log in
      </button>
    </div>
  </div>
</div>

<div *ngIf="identityLoginStatus == 'Unavailable'">
  <div class="nhsuk-grid-row">
    <div class="nhsuk-grid-column-two-thirds">
      <h1 autofocus tabindex="-1">The service is currently offline</h1>

      <p>
        The service is only available to a limited number of internal users.
      </p>

      <button
        id="login-button"
        type="button"
        [ngClass]="{ 'spinner-button': identityLoginButtonClicked }"
        [disabled]="identityLoginButtonClicked"
        (click)="onIdentityLoginButtonClick()"
        class="nhsuk-button nhsuk-u-margin-bottom-7"
        tabindex="3"
      >
        Log in
      </button>
    </div>
  </div>
</div>
