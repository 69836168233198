import { Component, Inject, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { SESSION_STORAGE, StorageService } from "ngx-webstorage-service";
import { BatchCsvFileError } from "src/app/models/batch-csv-file-error";
import { ErrorSummaryMessage } from "src/app/models/error-summary-message";

@Component({
  selector: "app-file-contains-errors",
  templateUrl: "./file-contains-errors.component.html",
})
export class FileContainsErrorsComponent implements OnInit {
  fileErrors: BatchCsvFileError[];
  summaryErrorMessages = [];
  static readonly dictionary: { [key: string]: string } = {
    "First Name": "Enter their given name",
    "Last Name": "Enter their family name",
    "Email Address": "Enter their email address",
    "Mobile Number": "Enter their mobile number",
    "Capture delivery address (True/False)":
      "Select whether the applicant needs to provide a delivery address",
  };

  constructor(
    @Inject(SESSION_STORAGE) private sessionStorage: StorageService,
    private title: Title,
  ) {}

  ngOnInit(): void {
    this.title.setTitle("Multiple applications could not be sent");
    this.fileErrors = this.sessionStorage.get("fileErrors");
    this.populateErrorTable();
    this.updateErrorSummary();
  }

  mapFieldToColumn(field: string): string {
    switch (field) {
      case "First Name": {
        return "A";
      }
      case "Last Name": {
        return "B";
      }
      case "Email Address": {
        return "C";
      }
      case "Mobile Number": {
        return "D";
      }
      case "Notes (optional)": {
        return "E";
      }
      case "Capture delivery address (True/False)": {
        return "F";
      }
      default: {
        return "Column not recognised";
      }
    }
  }

  populateErrorTable(): void {
    this.fileErrors.forEach((fileError) => {
      if (fileError.error == "CustomError") {
        fileError.column = "-";
        fileError.error = fileError.value.toString();
      } else {
        this.populatePetlErrorRow(fileError);
      }
    });
  }

  populatePetlErrorRow(fileError): void {
    fileError.column = this.mapFieldToColumn(fileError.field);

    if (fileError.name === "__header__") {
      fileError.error =
        "The selected file contains incorrect headings. Refer to the template.";
    } else if (fileError.error == "AssertionError") {
      if (fileError.value == null || fileError.value == "") {
        fileError.error = this.getEmptyFieldErrorMessage(
          fileError.field,
          fileError.column,
        );
      } else if (fileError.field == null) {
        fileError.error = `Unknown column contains an invalid value.`;
      } else {
        fileError.error = `${fileError.field}, contains an invalid value.`;
      }
    } else {
      fileError.error = `Incorrect file please refer to the template.`;
    }
  }

  getEmptyFieldErrorMessage(field: string, column: string): string {
    const dictionary = FileContainsErrorsComponent.dictionary;
    if (field in dictionary) {
      return dictionary[field];
    } else {
      return `Column ${column}, ${field}, is missing from the file. Refer to the template.`;
    }
  }

  updateErrorSummary() {
    const updatedErrorSummary = Array<ErrorSummaryMessage>();

    updatedErrorSummary.push({
      message: "Your file contains errors",
      id: "error-table",
    });

    this.summaryErrorMessages = updatedErrorSummary;
  }
}
