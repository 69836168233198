<div class="nhsuk-grid-row">
  <div class="nhsuk-grid-column-two-thirds">
    <breadcrumb-link
      *ngIf="displayBackLink"
      [breadcrumbRoute]="breadcrumbRoute"
      [breadcrumbText]="breadcrumbText"
    ></breadcrumb-link>

    <div class="nhsuk-form-group">
      <error-summary
        [errorSummaryMessages]="summaryErrorMessages"
      ></error-summary>
      <fieldset class="nhsuk-fieldset">
        <legend
          class="nhsuk-fieldset__legend nhsuk-fieldset__legend--xl nhsuk-u-margin-bottom-7"
        >
          <h1 autofocus tabindex="-1" class="nhsuk-fieldset__heading">
            Which proof of address document do you want to use?
          </h1>
        </legend>

        <p>You can provide a paper or online statement.</p>
        <p>
          You'll need to capture an image of this document when using the mobile
          app.
        </p>

        <details class="nhsuk-details nhsuk-u-margin-bottom-6">
          <summary class="nhsuk-details__summary">
            <span class="nhsuk-details__summary-text">
              More information about what we will accept
            </span>
          </summary>
          <div class="nhsuk-details__text">
            <p>We accept:</p>

            <ul>
              <li>both paper and online statements​</li>
              <li>
                statements from joint accounts if you are one of the named
                account holders
              </li>
              <li>proof of address documents from outside of the UK</li>
            </ul>
          </div>
        </details>

        <div class="nhsuk-radios" id="poa-type">
          <div class="nhsuk-radios__item">
            <input
              #bankAccount
              data-cy="bank-account"
              class="nhsuk-radios__input"
              aria-controls="yes"
              aria-expanded="false"
              type="radio"
              (change)="updatePoaRadioValue('BankAccount')"
              id="bank-account"
              name="poa"
            />
            <label class="nhsuk-label nhsuk-radios__label" for="bank-account">
              Bank account or credit card statement, from last 6 months
            </label>
          </div>

          <div class="nhsuk-radios__item">
            <input
              #utilityBill
              data-cy="utility-bill"
              class="nhsuk-radios__input"
              aria-controls="yes"
              aria-expanded="false"
              type="radio"
              (change)="updatePoaRadioValue('UtilityBill')"
              id="utility-bill"
              name="poa"
            />
            <label class="nhsuk-label nhsuk-radios__label" for="utility-bill">
              Utility bill, from last 6 months
            </label>
          </div>

          <div class="nhsuk-radios__item">
            <input
              #mortgageStatement
              data-cy="mortgage-statement"
              class="nhsuk-radios__input"
              aria-controls="yes"
              aria-expanded="false"
              type="radio"
              (change)="updatePoaRadioValue('MortgageStatement')"
              id="mortgage-statement"
              name="poa"
            />
            <label
              class="nhsuk-label nhsuk-radios__label"
              for="mortgage-statement"
            >
              Mortgage statement, from last 12 months
            </label>
          </div>

          <div class="nhsuk-radios__item">
            <input
              #councilTax
              data-cy="council-tax"
              class="nhsuk-radios__input"
              aria-controls="yes"
              aria-expanded="false"
              type="radio"
              (change)="updatePoaRadioValue('CouncilTax')"
              id="council-tax"
              name="poa"
            />
            <label class="nhsuk-label nhsuk-radios__label" for="council-tax">
              Council tax statement, from last 12 months
            </label>
          </div>

          <div class="nhsuk-radios__item">
            <input
              #tenancyAgreement
              data-cy="tenancy-agreement"
              class="nhsuk-radios__input"
              aria-controls="yes"
              aria-expanded="false"
              type="radio"
              (change)="updatePoaRadioValue('TenancyAgreement')"
              id="tenancy-agreement"
              name="poa"
            />
            <label
              class="nhsuk-label nhsuk-radios__label"
              for="tenancy-agreement"
            >
              Tenancy agreement from the last 12 months
            </label>
          </div>
          <div class="nhsuk-radios__item">
            <input
              #drivingLicence
              data-cy="driving-license"
              class="nhsuk-radios__input"
              aria-controls="yes"
              aria-expanded="false"
              type="radio"
              (change)="updatePoaRadioValue('DrivingLicence')"
              id="driving-license"
              name="poa"
            />
            <label
              class="nhsuk-label nhsuk-radios__label"
              for="driving-license"
            >
              UK driving licence (full or provisional)
            </label>
            <div class="nhsuk-hint nhsuk-radios__hint" id="poa-driving-hint">
              If you use your driving licence as a proof of address you cannot
              use it as a photo ID
            </div>
          </div>

          <div class="nhsuk-radios__divider">or</div>

          <div class="nhsuk-radios__item">
            <input
              #none
              data-cy="none"
              class="nhsuk-radios__input"
              aria-controls="yes"
              aria-expanded="false"
              type="radio"
              (change)="updatePoaRadioValue('None')"
              id="none"
              name="poa"
            />
            <label class="nhsuk-label nhsuk-radios__label" for="none">
              None of the above
            </label>
          </div>
        </div>
      </fieldset>
    </div>

    <button
      (click)="onContinueButtonClicked()"
      type="submit"
      class="nhsuk-button"
      data-cy="continue-button"
    >
      Continue
    </button>
  </div>
</div>
