import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { SessionAuthenticationService } from "./session-authentication.service";
import { EnvironmentConfigService } from "./environment-config.service";
import { Portal } from "src/app/models/session-token";

@Injectable()
export class GrantUserService {
  constructor(
    private http: HttpClient,
    private environmentConfigService: EnvironmentConfigService,
    private sessionAuthenticationService: SessionAuthenticationService,
  ) {}

  postGrantUser(
    invitationId: string,
    photoIdOneNo: string,
    photoIdOneDateOfIssue: string,
    photoIdOneIssue: string,
    photoIdTwoNo: string,
    photoIdTwoDateOfIssue: string,
    photoIdTwoIssue: string,
    poaNumber: string,
    poaDateOfIssue: string,
    poaIssue: string,
    poaDocumentType: string,
    smartCard: string,
    idCheckedBy: string,
    idCheckedByUserId: string,
    photoIdOneType: string,
    photoIdTwoType: string,
    approvalNote: string,
  ): Promise<any> {
    const invitationDetails = {
      invitation_id: invitationId,
      photo_id_one_no: photoIdOneNo,
      photo_id_one_date_of_issue: photoIdOneDateOfIssue,
      photo_id_one_issue: photoIdOneIssue,
      photo_id_two_no: photoIdTwoNo,
      photo_id_two_date_of_issue: photoIdTwoDateOfIssue,
      photo_id_two_issue: photoIdTwoIssue,
      poa_no: poaNumber,
      poa_date_of_issue: poaDateOfIssue,
      poa_issue: poaIssue,
      poa_document_type: poaDocumentType,
      smart_card: smartCard,
      id_checked_by: idCheckedBy,
      id_checked_by_user_id: idCheckedByUserId,
      photo_id_one_type: photoIdOneType,
      photo_id_two_type: photoIdTwoType,
      approval_note: approvalNote,
    };

    return new Promise((resolve, reject) => {
      this.http
        .post(
          `${
            this.environmentConfigService.getConfig().idBridgeApiUrl
          }/invitation/grant_user`,
          invitationDetails,
          {
            headers: this.sessionAuthenticationService.applyAuthorizationHeader(
              Portal.Checker,
            ),
            observe: "response",
          },
        )
        .subscribe(
          (res) => {
            resolve(res);
          },
          (error) => {
            reject(error);
          },
        );
    });
  }
}
