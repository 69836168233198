export class ConfirmDetailsEvidence {
  number: ConfirmDetailsEvidenceNumber;
  docType: ConfirmDetailsEvidenceDocType;
  issue: ConfirmDetailsEvidenceIssueCountry;
  issueDate: ConfirmDetailsEvidenceIssueDate;

  constructor(idPrefix: string) {
    this.number = new ConfirmDetailsEvidenceNumber(idPrefix);
    this.docType = new ConfirmDetailsEvidenceDocType(idPrefix);
    this.issue = new ConfirmDetailsEvidenceIssueCountry(idPrefix);
    this.issueDate = new ConfirmDetailsEvidenceIssueDate(idPrefix);
  }

  isValid(): boolean {
    return [
      this.number.valid,
      this.docType.valid,
      this.issue.valid,
      this.issueDate.valid,
    ].every(Boolean);
  }

  getEvidence(): ConfirmDetailsEvidenceField[] {
    return [this.number, this.docType, this.issue, this.issueDate];
  }
}

export abstract class ConfirmDetailsEvidenceField {
  errorId: string;
  invitationKey: string;
  value: string;
  errorMessage: string;
  valid: boolean;

  constructor(idPrefix: string, idSuffix: string, keySuffix: string) {
    this.errorId = `${idPrefix}-${idSuffix}`;
    this.invitationKey = `${kebabToCamel(idPrefix)}${keySuffix}`;
  }
}

export class ConfirmDetailsEvidenceNumber extends ConfirmDetailsEvidenceField {
  constructor(idPrefix: string) {
    super(idPrefix, "number", "No");
    this.value = "";
  }
}

export class ConfirmDetailsEvidenceDocType extends ConfirmDetailsEvidenceField {
  constructor(idPrefix: string) {
    super(
      idPrefix,
      "document-type",
      `${idPrefix === "poa" ? "Document" : ""}Type`,
    );
  }
}

export class ConfirmDetailsEvidenceIssueCountry extends ConfirmDetailsEvidenceField {
  constructor(idPrefix: string) {
    super(idPrefix, "issue", "Issue");
  }
}

export class ConfirmDetailsEvidenceIssueDate extends ConfirmDetailsEvidenceField {
  constructor(idPrefix: string) {
    super(idPrefix, "issue-date", "DateOfIssue");
  }
}

function kebabToCamel(text: string) {
  return text.replace(/-\w/g, (x) => `${x[1].toUpperCase()}`);
}

export type ConfirmDetailsEvidenceFieldType =
  | "number"
  | "issueDate"
  | "issue"
  | "docType";
