import { Injectable } from "@angular/core";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { EnvironmentConfigService } from "./environment-config.service";

import { SessionAuthenticationService } from "./session-authentication.service";
import { Observable } from "rxjs";

@Injectable()
export class PoaValidateService {
  constructor(
    private http: HttpClient,
    private environmentConfigService: EnvironmentConfigService,
    private sessionAuthenticationService: SessionAuthenticationService,
  ) {}

  private static mapPoaValidateRequest(
    invitationId: string,
    title: string,
    givenName: string,
    middleNames: string,
    familyName: string,
    dob: string,
    tuAddress: string,
    deliveryAddress: string,
    preferredName: string,
    poaType: string,
    photoOneId: string,
    photoTwoId: string,
    profilePhotoBase64: string,
    profilePhotoType: string,
  ) {
    return {
      invitation_id: invitationId,
      title: title,
      given_name: givenName,
      middle_names: middleNames,
      family_name: familyName,
      dob: dob,
      tu_address: tuAddress,
      delivery_address: deliveryAddress,
      preferred_name: preferredName,
      poa_type: poaType,
      photo_one_id: photoOneId,
      photo_two_id: photoTwoId,
      profile_photo_base64: profilePhotoBase64,
      profile_photo_type: profilePhotoType,
    };
  }

  postPoaValidate(
    invitationId: string,
    title: string,
    givenName: string,
    middleNames: string,
    familyName: string,
    dob: string,
    tuAddress: string,
    deliveryAddress: string,
    preferredName: string,
    poaType: string,
    photoOneId: string,
    photoTwoId: string,
    profilePhotoBase64: string,
    profilePhotoType: string,
  ): Promise<any> {
    const invitationDetails = PoaValidateService.mapPoaValidateRequest(
      invitationId,
      title,
      givenName,
      middleNames,
      familyName,
      dob,
      tuAddress,
      deliveryAddress,
      preferredName,
      poaType,
      photoOneId,
      photoTwoId,
      profilePhotoBase64,
      profilePhotoType,
    );

    return new Promise((resolve, reject) => {
      this.http
        .post(
          `${
            this.environmentConfigService.getConfig().idBridgeApiUrl
          }/poa/validate`,
          invitationDetails,
          {
            headers: this.sessionAuthenticationService.applyCsrfHeader(),
            observe: "response",
          },
        )
        .subscribe(
          (res) => {
            resolve(res);
          },
          (error) => {
            reject(error);
          },
        );
    });
  }

  postTuAddressCheck(
    invitationId: string,
    tuBuildingName: string,
    tuAddressLineOne: string,
    tuTownCity: string,
    tuPostcode: string,
    tuAddress: string,
  ): Observable<HttpResponse<boolean>> {
    return this.http.post<boolean>(
      `${
        this.environmentConfigService.getConfig().idBridgeApiUrl
      }/poa/addressCheck`,
      {
        invitation_id: invitationId,
        tu_building_name: tuBuildingName,
        tu_address_line_one: tuAddressLineOne,
        tu_town_city: tuTownCity,
        tu_postcode: tuPostcode,
        tu_address: tuAddress,
      },
      {
        headers: this.sessionAuthenticationService.applyCsrfHeader(),
        observe: "response",
      },
    );
  }
}
