<div class="nhsuk-grid-row">
  <div *ngIf="invitationConfirmation" class="nhsuk-grid-column-two-thirds">
    <breadcrumb-link
      [breadcrumbRoute]="breadcrumbRoute"
      [breadcrumbText]="breadcrumbText"
    ></breadcrumb-link>
    <error-summary
      [errorSummaryMessages]="summaryErrorMessages"
    ></error-summary>
    <h1 class="nhsuk-label-wrapper">
      <label
        class="nhsuk-label nhsuk-label--xl"
        for="ni-number-input"
        autofocus
        tabindex="-1"
      >
        Enter your National Insurance number
      </label>
    </h1>
    <p>You can find your National Insurance number on:</p>
    <ul>
      <li>your payslip</li>
      <li>your P60</li>
      <li>letters about your tax, pension or benefits</li>
    </ul>
    <text-input
      #niNumberInput
      [id]="'ni-number-input'"
      size="nhsuk-input--width-10"
      hint="Your National Insurance number contains 3 letters and 6 numbers, for example: “QQ 12 34 56 C”."
      [errorMessage]="niNumberErrorMessage"
      [removeSpaces]="true"
      [value]="invitationConfirmation.niNumber"
      [uppercase]="true"
      (questionAnswer)="updateNiNumberAnswer($event)"
      [cypressValue]="'ni-number-input'"
    >
    </text-input>
    <div class="nhsuk-u-padding-bottom-3"></div>
    <button
      class="nhsuk-button nhsuk-u-margin-bottom-5"
      (click)="submitAnswer()"
      id="continue"
      data-cy="continue-button"
    >
      Continue
    </button>
    <p>
      <a href="/invitee/no-continue/no-ni"
        >I don't have a National Insurance Number</a
      >
    </p>
  </div>
</div>
