import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { RouterService } from "src/app/services/router.service";
import { combineLatest, map } from "rxjs";
import { UserSettings } from "src/app/models/userSettings";
import { UserSettingsService } from "src/app/services/user-settings.service";

@Component({
  selector: "app-before-you-start",
  templateUrl: "./before-you-start.component.html",
  styleUrls: [],
})
export class BeforeYouStartComponent implements OnInit {
  constructor(
    private routerService: RouterService,
    private title: Title,
    private userSettings: UserSettingsService,
  ) {}
  loading: boolean;
  settings: UserSettings;
  supportEmailVerified: boolean = false;
  contactEmailVerified: boolean = false;

  async ngOnInit(): Promise<void> {
    this.title.setTitle("Before you start");
    await this.getSettings();
  }

  async getSettings() {
    combineLatest([this.userSettings.getSettings()])
      .pipe(map((x) => x[0]))
      .subscribe({
        next: (response) => {
          this.settings = this.userSettings.mapUserSettings(response["body"]);
          this.supportEmailVerified = this.settings.supportEmailVerified;
          this.contactEmailVerified = this.settings.contactEmailVerified;
          this.loading = false;
        },
        error: (error) => {
          this.routerService.handleErrorAuthRoutes(error.status);
        },
      });
  }
}
